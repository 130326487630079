import GetApiData from "Configration/Utilities/getApiData/GetApiData";
import { AlertMessage } from "Framework/Components/Widgets";
import React, { useState } from "react";
import QRCode from "react-qr-code";
import { getSessionStorage } from "Service/auth/auth";
import { project } from "Service/Endpoints/endpoints";
// import { apiCalling } from "Service/Utilities/apiCalling";
import BizClass from "../QRCodes.module.scss";

/* ************************************* Code For cellTemplate start ***************************** */
const cellTemplate = (params) => {
  console.log(params.data);
  return (
    <div className={BizClass.QrCode}>
      <div className={BizClass.QrCodeDiv}>
        <QRCode value={`${params.data && params.data.EQUIPMENTNUMBER ? params.data.EQUIPMENTNUMBER : ""}`} size={80} />
      </div>
    </div>
  );
};
/* ************************************* Code For cellTemplate End ***************************** */

function QRcodesLogic() {
  const [QrCodeData, setQrCodeData] = useState([]);
  const [isLoadingQRListData, setIsLoadinQRListData] = useState(false);
  const [selectedProjectData, setSelectedProjectData] = useState([]);
  const setAlertMessage = AlertMessage();
  const user = getSessionStorage("userData");
  const [gridApi, setGridApi] = useState();
  const [searchText, setSearchText] = useState("");

  /* ************************************* Code For GridColumDef start ***************************** */
  const [QRCodeColumDef] = useState([
    {
      field: "#",
      headerName: "Sr. No.",
      width: 300,
      valueGetter: "node.rowIndex + 1",
      cellRenderer: (node) => {
        return node.value ? <p>{node.value}</p> : "";
      },
      cellStyle: { textAlign: "center" },
    },
    {
      field: "ASSETNAME",
      headerName: "Asset Name",
      width: 300,
      cellRenderer: (node) => {
        return node.value ? <p>{node.value}</p> : "";
      },
      cellStyle: { textAlign: "center", verticalAlign: "middle" },
    },
    { field: "ASSETCODE", headerName: "Asset Code", width: 300},

    { field: "#", headerName: "QR Code", width: 300, cellRenderer: cellTemplate, cellStyle: { textAlign: "center" } },
  ]);
  /* ************************************* Code For GridColumDef end ***************************** */

  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  const onChangeSearchText = (val) => {
    setSearchText(val);
    gridApi.setQuickFilter(val);
    gridApi.refreshCells();
  };

  const cleareSerchText = (data) => {
    if (data) {
      if (searchText && searchText.toLowerCase().includes("#")) {
        onChangeSearchText("");
      }
    }
  };

  /* ************************************* Code For Api Calling Start ***************************** */
  // get QRListData
  // const QRCodeListData = async (selectedProjectData) => {
  //   try {
  //     setIsLoadinQRListData(true);
  //     const formData = {
  //       main: {
  //         divisionid:
  //           user && user.UserProfileID
  //             ? user.UserProfileID.toString() === "100301" || user.UserProfileID.toString() === "100302"
  //               ? selectedProjectData && selectedProjectData.DIVISIONIDs
  //                 ? selectedProjectData.DIVISIONIDs
  //                 : ""
  //               : ""
  //             : "",
  //         profileid: user && user.UserProfileID ? user.UserProfileID : null,
  //         projectid: selectedProjectData && selectedProjectData.PROJECTID ? selectedProjectData.PROJECTID : "",
  //       },
  //     };
  //     const result = await apiCalling(formData, project.projectList.GetKPCLAssetQrCodesSelect);
  //     setIsLoadinQRListData(false);
  //     console.log(result, "result Qr code");
  //     if (result && result.responseCode === 1) {
  //       if (result && result.responseData && result.responseData.Table) {
  //         setQrCodeData(result.responseData.Table);
  //       }
  //     } else {
  //       setAlertMessage({
  //         type: "error",
  //         message: result.responseMessage,
  //       });
  //       setQrCodeData([]);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //     setAlertMessage({
  //       type: "error",
  //       message: error,
  //     });
  //     setQrCodeData([]);
  //   }
  // };
  const QRCodeListData = (ProjectData) => {
    setSelectedProjectData(ProjectData);
    const formData = {
      main: {
        divisionid: ProjectData && ProjectData.DIVISIONIDs ? ProjectData.DIVISIONIDs : "",
        profileid: user && user.UserProfileID ? user.UserProfileID : null,
        projectid: ProjectData && ProjectData.PROJECTID ? ProjectData.PROJECTID : "",
      },
    };
    const request = {
      setDatalist: setQrCodeData,
      setLoad: setIsLoadinQRListData,
      requestData: formData,
      apiPath: project.projectList.GetKPCLAssetQrCodesSelect,
      setAlert: setAlertMessage,
      fun: cleareSerchText,
    };
    GetApiData(request);
  };
  /* ************************************* Code For Api Calling end ***************************** */

  return {
    QrCodeData,
    isLoadingQRListData,
    QRCodeColumDef,
    QRCodeListData,
    selectedProjectData,
    onGridReady,
    onChangeSearchText,
    searchText,
  };
}

export default QRcodesLogic;
