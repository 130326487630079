// import React, { useEffect, useState } from "react";
// import { LOGIN_VIEW, SIGNUP_VIEW } from "Configration/Utilities/Constants/Constants";
// import LoginForm from "./Views/LoginForm/LoginForm";
// import SignUpForm from "../SignUp/SignUpForm";
// import BizClass from "./Login.module.scss";

// import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS

// function Login() {
//   const [activeFormView, setActiveFormView] = useState(LOGIN_VIEW);

//   useEffect(() => {
//     document.title = "Karnataka Power Corporation Limited";
//   }, []);

//   return (
//     <div className={`container-fluid ${BizClass.MainSection}`}>
//       <div className={`row ${BizClass.BannerSection}`}>
//         <div className="col-md-6">
//           <img src={`${process.env.PUBLIC_URL}App_logo.svg`} alt="Banner" />
//           <div className={BizClass.ClientText}>
//             <h4>Karnataka Power Corporation Limited</h4>
//             <p>Business Application</p>
//           </div>
//         </div>
//       </div>
//       <div className={`row ${BizClass.FormSection}`}>
//         <div className={`d-flex justify-content-end align-items-center mr-5 ${BizClass.ViewChangeSection}`}>
//           {activeFormView === LOGIN_VIEW && (
//             <>
//               <p className="m-0">Don&apos;t have an account?</p>
//               <button type="button" className="btn btn-link" onClick={() => setActiveFormView(SIGNUP_VIEW)}>
//                 Sign up
//               </button>
//             </>
//           )}
//           {activeFormView !== LOGIN_VIEW && (
//             <>
//               <p className="m-0">Already have an account?</p>
//               <button type="button" className="btn btn-link" onClick={() => setActiveFormView(LOGIN_VIEW)}>
//                 Log In
//               </button>
//             </>
//           )}
//         </div>

//         <div className={`col-md-6 ${BizClass.SubFormSection}`}>
//           <div className={`card border-0 ${BizClass.FormBox}`}>
//             <div className={BizClass.ClientLogoBox}>
//               <img src={`${process.env.PUBLIC_URL}favicon.ico`} alt="Client Logo" className="img-fluid" />
//             </div>

//             <div className={BizClass.ClientNameBox}>
//               <h3>Karnataka Power Corporation Limited</h3>
//             </div>
//             <hr className={`mt-4 ${BizClass.SeperatorTxtBox}`} />
//             {activeFormView === LOGIN_VIEW && <LoginForm setActiveFormView={setActiveFormView} />}
//             {activeFormView !== LOGIN_VIEW && <SignUpForm activeFormView={activeFormView} setActiveFormView={setActiveFormView} />}
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default Login;





import React, { useEffect, useState } from "react";
import { LOGIN_VIEW, SIGNUP_VIEW } from "Configration/Utilities/Constants/Constants";
import LoginForm from "./Views/LoginForm/LoginForm";
import SignUpForm from "../SignUp/SignUpForm";
import BizClass from "./Login.module.scss";

// import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS

function Login() {
  const [activeFormView, setActiveFormView] = useState(LOGIN_VIEW);

  useEffect(() => {
    document.title = "Karnataka Power Corporation Limited";
  }, []);

  return (
    <div className={`container-fluid row ${BizClass.MainSection}`}>
      <div className={`col-12 col-lg-6 ${BizClass.BannerSection}`}>
        <div className="col-12 col-md-6">
          <img src={`${process.env.PUBLIC_URL}App_logo.svg`} alt="Banner" className="img-fluid" />
          <div className={BizClass.ClientText}>
            <h4>Karnataka Power Corporation Limited</h4>
            <p>Business Application</p>
          </div>
        </div>
      </div>
      <div className={`col-12 col-lg-6 ${BizClass.FormSection}`}>
        <div className={`col-12 ${BizClass.ViewChangeSection}`}>
          {activeFormView === LOGIN_VIEW && (
            <>
              <p className="mb-2">Don&apos;t have an account?</p>
              <button type="button" className="btn btn-link" onClick={() => setActiveFormView(SIGNUP_VIEW)}>
                Sign up
              </button>
            </>
          )}
          {activeFormView !== LOGIN_VIEW && (
            <>
              <p className="mb-2">Already have an account?</p>
              <button type="button" className="btn btn-link" onClick={() => setActiveFormView(LOGIN_VIEW)}>
                Log In
              </button>
            </>
          )}
        </div>

        <div className={`col-12 ${BizClass.SubFormSection}`}>
          <div className={`card border-0 ${BizClass.FormBox}`}>
            <div className={BizClass.ClientLogoBox}>
              <img src={`${process.env.PUBLIC_URL}favicon.ico`} alt="Client Logo" className="img-fluid" />
            </div>

            <div className={BizClass.ClientNameBox}>
              <h3>Karnataka Power Corporation Limited</h3>
            </div>
            <hr className={`mt-4 ${BizClass.SeperatorTxtBox}`} />
            {activeFormView === LOGIN_VIEW && <LoginForm setActiveFormView={setActiveFormView} />}
            {activeFormView !== LOGIN_VIEW && <SignUpForm activeFormView={activeFormView} setActiveFormView={setActiveFormView} />}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;


