import React, { useEffect, useRef } from "react";
import { DataGrid, PageBar } from "Framework/Components/Layout";
import { Loader } from "Framework/Components/Widgets";
import moment from "moment";
import BizClass from "./AssetsHistory.module.scss";
import AssetHistoryLogic from "./Logic/AssetHistoryLogic";

function AssetHistory() {
  const {
    getAssetHistiryListData,
    isLoadingAssetsHistory,
    assetsHistoryList,
    formValues,
    updateFormState,
    assetHistoryColumDef,
    onGridReady,
    onChangeSearchText,
    searchText,
  } = AssetHistoryLogic();
  const assetHistoryRef = useRef(null);

  const exportToCSV = () => {
    if (assetsHistoryList && assetsHistoryList.length !== 0) {
      const headers = Object.keys(assetsHistoryList[0]);
      const csvContent = "data:text/csv;charset=utf-8," +
        headers.join(",") + "\n" +
        assetsHistoryList.map(row => headers.map(header => row[header]).join(",")).join("\n");
      const encodedUri = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "AssetHistory.csv");
      document.body.appendChild(link);
      link.click();
    }
    else {
      alert('No Data to Export!')
    }

  };




  useEffect(() => {
    getAssetHistiryListData();
  }, []);

  return (
    <div className={BizClass.AssetHistory}>
      <PageBar>
        <span>From Date : </span>
        <PageBar.Input
          type="Date"
          name="txtFromDate"
          max={moment().format("YYYY-MM-DD")}
          value={formValues.txtFromDate}
          onChange={(e) => {
            updateFormState(e.target.value, e.target.name);
          }}
        />
        <span>To Date : </span>
        <PageBar.Input
          type="Date"
          name="txtToDate"
          max={moment().format("YYYY-MM-DD")}
          value={formValues.txtToDate}
          onChange={(e) => {
            updateFormState(e.target.value, e.target.name);
          }}
        />

        <PageBar.Search onClick={() => console.log("")} onChange={(e) => onChangeSearchText(e.target.value)} value={searchText} />
        <PageBar.Button onClick={() => getAssetHistiryListData()}>Refresh</PageBar.Button>
        {/* <ReactHTMLTableToExcel
          id="csvBtn"
          table="assetHistory"
          className="btn btn-primary"
          filename="Logsheet"
          sheet="Logsheet"
          buttonText="Download as XLS" /> */}
        <PageBar.Button onClick={exportToCSV}>Export to CSV</PageBar.Button>
      </PageBar>
      <div id="assetHistory">
        <DataGrid
          ref={assetHistoryRef}
          rowData={assetsHistoryList}
          columnDefs={assetHistoryColumDef}
          onGridReady={onGridReady}
          loader={isLoadingAssetsHistory ? <Loader /> : null}
          animateRows={true}
          showOpenedGroup={true}
          autoGroupColumnDef={{
            headerName: "Assset Name",
            minWidth: 340,
          }}
        />
      </div>
    </div>
  );
}

export default AssetHistory;
