import { useEffect, useState } from "react";
import { getSessionStorage } from "Service/auth/auth";
import { project } from "Service/Endpoints/endpoints";
import { AlertMessage } from "Framework/Components/Widgets";
import GetApiData from "Configration/Utilities/getApiData/GetApiData";

function GrievancesLogic() {
    const setAlertMessage = AlertMessage();
    const user = getSessionStorage("userData");
    const selectedProjectData = getSessionStorage("selctedProject");
    const [grievancesList, setGrievancesList] = useState([]);
    const [isLoadingGrievancesList, setisLoadingGrievancesList] = useState(false);
   
    const [grievancesGridApi, setGrievancesGridApi] = useState();
  
    // toggle state
    const [isShowAddGrievancesPopUp, setIsShowAddGrievancesPopUp] = useState(false);
    const [searchText, setSearchText] = useState("");

    /* ************************************* Code For GridColumDef start ***************************** */
    const formatDateTime = (dateTimeString) => {
      const options = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      };
      return new Date(dateTimeString).toLocaleDateString("en-US", options);
    };
  
    const [GrievancesColumDef] = useState([
      { headerName: "Sr. No.", field: "#", width: 80, pinned: "left", valueGetter: "node.rowIndex + 1" },
      { field: "EMP_ID", headerName: "Employee ID", width: 180, },
      { field: "DIVISION_ID", headerName: "Division ID", width: 240 },
      { field: "ENTRY_DATE", headerName: "Date and Time", width: 240, valueGetter: (params) => formatDateTime(params.data.ENTRY_DATE) },
      { field: "GRIV_DESCRIPTION", headerName: "Description", width: 210 },
      { field: "REMARKS", headerName: "Remarks", width: 140 },
    ]);
  
    /* ************************************* Code For GridColumDef end ***************************** */
  
    /* ************************************* Code For toggle popup start ***************************** */
  
    const toggleAddObservationPopUp = () => {
        setIsShowAddGrievancesPopUp(!isShowAddGrievancesPopUp);
    };
  
    /* ************************************* Code For toggle popup end ***************************** */
  
    /* ************************************* Code For OnGridRady Function Start ***************************** */
  
    const onObservationGridReady = (params) => {
      setGrievancesGridApi(params.api);
    };
  
    /* ************************************* Code For OnGridRady Function end ***************************** */
    const onChangeSearchText = (val) => {
        setSearchText(val);
        grievancesGridApi.setQuickFilter(val);
        grievancesGridApi.refreshCells();
      };
    
      const cleareSerchText = (data) => {
        if (data) {
          if (searchText && searchText.toLowerCase().includes("#")) {
            onChangeSearchText("");
          }
        }
      };
   
    /* ************************************* Code For Api Calling Start ***************************** */
  
    const getGrievancesListing = async () => {
      const formData = {
        main: {
          employee_ID: user && user.UserName ? user.UserName : "",
          division_ID: user && user.DIVISION_ID ? user.DIVISION_ID : null,
          project_ID: user && user.PROJECT_ID ? user.PROJECT_ID : "",
        },
      };
      const request = {
        setDatalist: setGrievancesList,
        setLoad: setisLoadingGrievancesList,
        requestData: formData,
        apiPath: project.projectList.GetGrievances,
        setAlert: setAlertMessage,
        fun: cleareSerchText,
      };
      GetApiData(request);
    };
  
    
    useEffect(() => {
      getGrievancesListing();
    }, []);
  
    return {
        grievancesList,
        isLoadingGrievancesList,
        GrievancesColumDef,
      toggleAddObservationPopUp,
      isShowAddGrievancesPopUp,
      setGrievancesList,
      getGrievancesListing,
      onObservationGridReady,
      grievancesGridApi,
      onChangeSearchText,
      searchText
    };
  }
  
  export default GrievancesLogic;