import { DataGrid, PageBar } from "Framework/Components/Layout";
import { Loader } from "Framework/Components/Widgets";
import React, { useEffect } from "react";
import QRcodesLogic from "./Logic/QRcodesLogic";
import BizClass from "./QRCodes.module.scss";

function QRCodes(props) {
  const { selectedProjectData } = props;
  const { QrCodeData, QRCodeColumDef, isLoadingQRListData, QRCodeListData, onGridReady, onChangeSearchText, searchText } = QRcodesLogic();

  useEffect(() => {
    QRCodeListData(selectedProjectData);
  }, [selectedProjectData]);

  const exportToCSV = () => {
    if (QrCodeData && QrCodeData.length !== 0) {
      const headers = Object.keys(QrCodeData[0]);

      const csvContent = "data:text/csv;charset=utf-8," +
        headers.join(",") + "\n" +
        QrCodeData.map(row => headers.map(header => row[header]).join(",")).join("\n");
      const encodedUri = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "QRCodes.csv");
      document.body.appendChild(link);
      link.click();
    }
    else {
      alert('No Data to Export!')
    }

  };

  const print = () => {
    window.print();
  };


  return (
    <div className={BizClass.QRCodesListing}>
      <PageBar>
        <PageBar.Search onChange={(e) => onChangeSearchText(e.target.value)} value={searchText} onClick={() => QRCodeListData(selectedProjectData)} />
        <PageBar.Button varient="primary" onClick={exportToCSV} style={{ margin: '5px' }}>
          Export to CSV
        </PageBar.Button>
        <PageBar.Button varient="primary" onClick={print} style={{ margin: '5px' }}>
          Print
        </PageBar.Button>
      </PageBar>
      <DataGrid id="dataGrid" rowData={QrCodeData} rowHeight={100} columnDefs={QRCodeColumDef} loader={isLoadingQRListData ? <Loader /> : null} onGridReady={onGridReady} />
    </div>
  );
}

export default QRCodes;
