import React, { useState } from "react";
import { RiUser3Fill } from "react-icons/ri";
import { AiTwotoneLock } from "react-icons/ai";
import { VscEye, VscEyeClosed } from "react-icons/vsc";
import classNames from "classnames";
import LoginFormLogic from "../../Logic/LoginFormLogic/LoginFormLogic";
import BizClass from "./LoginForm.module.scss";
import ReCAPTCHA from "react-google-recaptcha";



function LoginForm() {
  const { searchByHandleKeyDown, formValues, updateFormState, handleSave, isLoading, validationFormError } = LoginFormLogic();
  const [revealPassword, setRevealPassword] = useState(false);
  const togglePassword = () => {
    setRevealPassword(!revealPassword);
  };

  return (
    <div className="col-12 d-flex justify-content-center">
      <div className={BizClass.InputBox}>
        <div className={BizClass.InputGroup}>
          <div className={BizClass.LabelBox}>
            <label>UserName</label>
          </div>
          <div className={classNames(BizClass.InputGroupBox, validationFormError["txtUsername"] ? BizClass.InputGroupBoxError : null)}>
            <div className={BizClass.SubInputGroup}>
              <RiUser3Fill className={BizClass.BoxIcon} />
              <input type="text" name="txtUsername" value={formValues.txtUsername} onChange={(e) => updateFormState(e.target.name, e.target.value)} />
            </div>
            <span>{validationFormError["txtUsername"]}</span>
          </div>
        </div>
        <div className={BizClass.InputGroup}>
          <div className={BizClass.LabelBox}>
            <label>Password</label>
            <p>Forgot your password ?</p>
          </div>
          <div className={classNames(BizClass.InputGroupBox, validationFormError["txtPassword"] ? BizClass.InputGroupBoxError : null)}>
            <div className={BizClass.SubInputGroup}>
              <AiTwotoneLock className={BizClass.BoxIcon} />
              <input
                type={revealPassword ? "text" : "password"}
                name="txtPassword"
                value={formValues.txtPassword}
                onKeyDown={(e) => searchByHandleKeyDown(e, handleSave)}
                onChange={(e) => updateFormState(e.target.name, e.target.value.split(" ").join(""))}
              />
              {revealPassword ? (
                <VscEyeClosed className={BizClass.PassBoxIconClosed} onClick={() => togglePassword()} />
              ) : (
                <VscEye className={BizClass.PassBoxIcon} onClick={() => togglePassword()} />
              )}
            </div>
            <span>{validationFormError["txtPassword"]}</span>
          </div>
        </div>


        <div className={BizClass.captach}>
          <ReCAPTCHA
            sitekey="6LdvdEEoAAAAAMrtlK3RFl3QP260lpdZqTN7ueUk"
            onChange={(value) => updateFormState("captcha", value)}
          />
        </div>
        <button type="button" className={classNames(BizClass.ButtonWithLoader, isLoading ? BizClass.loading : null)} onClick={() => handleSave()}>
          <span className={BizClass.ButtonText}>Login</span>
          <span className={BizClass.ButtonLoader} />
        </button>

        <div className="d-flex justify-content-center">
          <div>Version 1.2</div>
        </div>
      </div>
    </div>
  );
}

export default LoginForm;
