import React, { useEffect, useState } from "react";
import { Loader } from "Framework/Components/Widgets";
import { VIEWMODE_CHILD, VIEWMODE_PARENT } from "Configration/Utilities/Constants/Constants";
import { MdArrowBack } from "react-icons/md";
import { FaWrench } from "react-icons/fa";
import { AiOutlineInfoCircle } from "react-icons/ai";
import QRCode from "react-qr-code";
import { DataGrid, PageBar } from "Framework/Components/Layout";
import Form from "Framework/Components/Layout/FormGroup/FormGroup";
import moment from "moment";
import AssetsDetailsLogic from "./Logic/AssetsDetailsLogic";
import BizClass from "./AssetsDetails.module.scss";
import GetApiData from "Configration/Utilities/getApiData/GetApiData";
import { project } from "Service/Endpoints/endpoints";
import { AlertMessage } from "Framework/Components/Widgets";
import axios from "../../../../Configration/axios/axios";
import Modal from "Framework/Components/Layout/Modal/Modal";
import { Button } from "Framework/Components/Widgets";
import { FaFileUpload } from 'react-icons/fa';
// import { getSessionStorage } from "Service/auth/auth";



function AssetsDetails({ toggleAssetDetailsView, assetsDetailseViewData, selectedProjectData, getAssetListingData }) {
  const {
    AssetDetailsParantListData,
    AssetDetailsChildListData,
    isLoadingAssetsDetailsData,
    observationList,
    observationListLoading,
    getAssetsDetailsListData,
    observationListingData,
    ListViewColumDef,
    assetStatusList,
    isLoadingAssetStatusList,
    getAssetSatusDropDownList,
    updateState,
    assetStatus,
    getObservationFeedDetails,
    observationFeedList,
    isLoadingObservationFeedList,
  } = AssetsDetailsLogic();

  const [selectedFiles, setSelectedFiles] = useState([]);
  const setAlertMessage = AlertMessage();
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [isNoImagesSelected, setIsNoImagesSelected] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [uploadAttempts, setUploadAttempts] = useState(0);
  const [isServerBusy, setServerBusy] = useState(false);
  const [serverMessage, setServerMessage] = useState("");

  useEffect(() => {
    getAssetSatusDropDownList(1001, selectedProjectData);
    if (assetsDetailseViewData) {
      getAssetsDetailsListData(assetsDetailseViewData, VIEWMODE_PARENT);
      observationListingData(assetsDetailseViewData);
      getObservationFeedDetails(assetsDetailseViewData);
    }


  }, []);

  const addFiles = (event, index) => {
    const files = event.target.files;
    setUploadedFiles((prevUploadedFiles) => {
      const newUploadedFiles = [...prevUploadedFiles];
      newUploadedFiles[index] = files[0];
      return newUploadedFiles;
    });
  };
  const removeFile = (indexToRemove) => {
    setUploadedFiles((prevUploadedFiles) => {
      const updatedFiles = [...prevUploadedFiles];
      updatedFiles[indexToRemove] = null;
      return updatedFiles;
    });
  };


  const uploadedData = async (files) => {
    setIsUploadModalOpen(false);
    console.log("files", files)
    const maxFileCount = 5;

    const validFiles = files.filter((file) => file !== null);
    console.log("files", validFiles);
    if (files.length > maxFileCount) {
      alert(`You can only upload a maximum of ${maxFileCount} files.`);
      return;
    }




    if (validFiles.length === 0) {
      setIsUploadModalOpen(true);
      setIsNoImagesSelected(true);
      return;
    }
    setSelectedFiles((prevFiles) => [...prevFiles, ...files]);
    const requestData = {
      childAssetID: 98000,
      doc_1: "",
      doc_2: "",
      doc_3: "",
      doc_4: "",
      doc_5: "",

    };
    const readFileAsync = (file) => {
      return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.onload = (event) => {
          resolve(event.target.result.split(',')[1]);
        };
        fileReader.onerror = (error) => {
          reject(error);
        };
        fileReader.readAsDataURL(file);
      });
    };


    try {
      setIsUploading(true);
      for (let i = 0; i < maxFileCount; i++) {
        const docFieldName = `doc_${i + 1}`;
        if (files[i]) {
          console.log("docFieldName", docFieldName)

          const imageObjectURL = await readFileAsync(files[i]);
          requestData[docFieldName] = imageObjectURL;
          console.log("requestData[docFieldName]", requestData[docFieldName])
        }
      }
      console.log("requestData:", requestData);

      const response = await axios.post(`${project.projectList.UploadDocs}`, requestData, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
      });
      if (response.status === 200) {
        setIsSuccessModalOpen(true);
        // console.log("Data Sent successfully");
        console.log("response", response)
        if (response.data.responseMessage === 'success!') {
          setSuccessMessage("Images Uploaded SuccessFully")
          setUploadAttempts(0);
        }
        else {
          setSuccessMessage("Could not upload the images, please try again after sometime.")
          setUploadAttempts(uploadAttempts + 1);
        }
        setSelectedFiles([]);
      } else {
        console.error(`Error: Request failed with status ${response.status}`);
        setUploadAttempts(uploadAttempts + 1);
      }
      setIsUploading(false);
    } catch (error) {
      console.error('Error:', error);
      setIsUploading(false);
      setUploadAttempts(uploadAttempts + 1);
      console.log("uploadAttempts", uploadAttempts)
    }

    if (uploadAttempts >= 3) {
      setServerBusy(true);
      setServerMessage("Server busy. Please try again after sometime.");
    }

  };



  const openEditModal = () => {
    setIsUploadModalOpen(true);

  };

  const closeEditModal = () => {
    setIsUploadModalOpen(false);
    setIsSuccessModalOpen(false);
    setIsNoImagesSelected(false);
    setServerBusy(false)
  };




  console.log(observationFeedList, isLoadingObservationFeedList);
  return (
    <div className={BizClass.MainSection}>
      <div className={BizClass.Header}>
        <button type="button" onClick={() => toggleAssetDetailsView()}>
          <MdArrowBack />
          Back to Assets
        </button>
        <h4>
          <p>{selectedProjectData && selectedProjectData.PROJECTNAME.split(" ")[0].toUpperCase().slice(0, 2)}</p>
          <span>{selectedProjectData && selectedProjectData.PROJECTNAME.split(" ")[0].toUpperCase()} </span>
          {" / "}
          {selectedProjectData && selectedProjectData.PROJECTNAME}
          {" > "}
          {`${AssetDetailsParantListData && AssetDetailsParantListData.length > 0 && AssetDetailsParantListData[0].ASSETNAME}`}
        </h4>
      </div>
      {isLoadingAssetsDetailsData && <Loader />}
      <div className={BizClass.Body}>
        <div className={BizClass.AssetDetail}>
          <div className={BizClass.Content}>
            <div className={BizClass.QrBox}>
              <div className={BizClass.QrCode}>
                <div className={BizClass.QrCodeDiv}>
                  <QRCode
                    value={`${AssetDetailsParantListData && AssetDetailsParantListData.length > 0 && AssetDetailsParantListData[0].PEQUIPMENTNUMBER}`}
                    size={90}
                  />
                </div>
              </div>
              <p>{`${AssetDetailsParantListData && AssetDetailsParantListData.length > 0 && AssetDetailsParantListData[0].ASSETNAME}`}</p>
              <PageBar.Button
                varient="primary"
                onClick={() => {
                  getAssetsDetailsListData(assetsDetailseViewData, VIEWMODE_CHILD);
                }}
              >
                Get Children
              </PageBar.Button>
            </div>
            <h4>Basic Info</h4>
            {AssetDetailsParantListData && AssetDetailsParantListData.length > 0 ? (
              <div className={BizClass.ParantDataListData}>
                <p>
                  <span>ASSET CODE :</span>
                  {`${AssetDetailsParantListData[0].ASSETCODE}`}
                </p>
                <p>
                  <span>PROJECT NAME :</span>
                  {`${AssetDetailsParantListData[0].PROJECTNAME}`}
                </p>
                <p>
                  <span>ASSETS LOCATION :</span>
                  {`${AssetDetailsParantListData[0].FUNCTIONALLOCATION}`}
                </p>
                <p>
                  <span>DATE OF CREATION :</span>
                </p>
                <p>
                  <span>CURRENT STATUS :</span>

                  <Form>
                    <Form.CustomGroup column={1} controlwidth="240px">
                      <Form.InputGroup>
                        <Form.InputControl
                          control="select"
                          name="txtStatus"
                          options={assetStatusList}
                          isLoading={isLoadingAssetStatusList}
                          getOptionLabel={(option) => `${option.MASTERDISPLAYNAME}`}
                          getOptionValue={(option) => `${option}`}
                          onChange={(e) => {
                            updateState(e, AssetDetailsParantListData[0], getAssetListingData);
                          }}
                          value={
                            !assetStatus
                              ? assetStatusList.length > 0
                                ? assetStatusList.find((x) => {
                                  return x.MASTERDISPLAYNAME === AssetDetailsParantListData[0].MASTERDISPLAYNAME;
                                })
                                : null
                              : assetStatus
                          }
                        />
                      </Form.InputGroup>
                    </Form.CustomGroup>
                  </Form>
                </p>
              </div>
            ) : null}
            {AssetDetailsParantListData && AssetDetailsParantListData.length > 0 && <div className={BizClass.kfndlvkdnvd} />}
            {AssetDetailsChildListData && AssetDetailsChildListData.length > 0
              ? AssetDetailsChildListData.map((val, i) => {
                return (
                  <React.Fragment key={i + 1}>
                    <h4>Children</h4>
                    <div className={BizClass.ChildListData}>
                      <p>
                        <span>ASSET CODE :</span>
                        {`${val.ASSETCODE}`}
                      </p>
                      <p>
                        <span>PROJECT NAME :</span>
                        {`${val.PROJECTNAME}`}
                      </p>
                      <p>
                        <span>ASSETS LOCATION :</span>
                        {`${val.FUNCTIONALLOCATION}`}
                      </p>
                      <p>
                        <span>DATE OF CREATION :</span>
                      </p>
                      <p>
                        <span>CURRENT STATUS :</span>
                        {`${val.MASTERDISPLAYNAME}`}
                      </p>
                    </div>
                    <div className={BizClass.kfndlvkdnvd} />
                  </React.Fragment>
                );
              })
              : null}
          </div>
        </div>
        <div className={BizClass.CheckPoints}>
          <div className={BizClass.Header}>
            <PageBar className={BizClass.HeaderPageBar}>
              <p>Observation Check point</p>
            </PageBar>
          </div>
          <DataGrid rowData={observationList} columnDefs={ListViewColumDef} loader={observationListLoading ? <Loader /> : null} />
        </div>
        <div className={BizClass.Feeds}>
          <div className={BizClass.Header}>
            <p>
              <span>
                <FaWrench />
              </span>
              Observations Feed ({observationFeedList.length})
            </p>
            <div className={BizClass.StatusType}>
              {/* <label className={BizClass.customFileInput}>
                <input
                  type="file"
                  accept=".jpg, .jpeg, .png, .pdf"
                  multiple
                  onChange={handleFileInputChange}
                  className={BizClass.input}
                  style={{ display: 'none' }}
                />
                <span className={BizClass.labelText}>Upload Document</span>
              </label> */}


              <span className={BizClass.upload} onClick={openEditModal}>Upload Document</span>
              {/* <span className={BizClass.open}>Open</span>
              <span className={BizClass.Completed}>Completed</span> */}
            </div>
          </div>
          <div className={BizClass.Content}>
            {isLoadingObservationFeedList && <Loader />}
            {observationFeedList && observationFeedList.length > 0
              ? observationFeedList.map((item, index) => {
                return (
                  <div className={BizClass.ContentBox} key={index + 1}>
                    <p className={BizClass.ContentBox_Qut}>
                      {item.CHECKPOINT}
                      <span>
                        <AiOutlineInfoCircle />
                      </span>
                    </p>
                    <p>
                      ODESCRIPTION : <span>{item.ODESCRIPTION}</span>
                    </p>
                    <p>
                      OLABEL : <span>{item.OLABEL}</span>
                    </p>
                    <p>
                      ACTION : <span>{item.ACTION}</span>
                    </p>
                    <p>
                      STARTDATE : <span>{item.STARTDATE ? moment(item.STARTDATE).format("DD-MMM-YYYY") : ""}</span>
                    </p>
                  </div>
                );
              })
              : null}
          </div>
        </div>

        <div className={BizClass.AssetsDetailsDataView}>
          <div className={BizClass.ContantData} />
        </div>
      </div>



      {isUploadModalOpen && (
        <Modal
          varient="center"
          title="Upload Document"
          index={2}
          show={closeEditModal}
          width="754px"
          height="525px"
        >
          <Modal.Body>
            <div className={BizClass.fileUploadContainer}>
              <div className={BizClass.fileUploadRow}>
                <p className={BizClass.note}>Please Upload images of format jgp,jpeg,png of maximum size 2MB and pdf of maximum size 10MB</p>
                {Array.from({ length: 5 }).map((_, index) => (
                  <div key={index} style={{ display: "flex", alignItems: "center" }}>
                    <div className={BizClass.fileUploadCol}>
                      <label className={BizClass.customFileInput} htmlFor={`fileInput_${index}`}>
                        <input
                          type="file"
                          accept=".jpg, .jpeg, .png, .pdf"
                          multiple
                          id={`fileInput_${index}`}
                          className={BizClass.input}
                          style={{ display: 'none' }}
                          onChange={(e) => addFiles(e, index)}
                        />
                        {/* <span className={BizClass.labelText}> */}
                          <span className={BizClass.fileIcon}>
                            <FaFileUpload />
                          </span>
                          Upload Document {index + 1}
                        {/* </span> */}
                      </label>

                    </div>
                    {uploadedFiles[index] && (
                      <div className={BizClass.uploadedFileInfo}>
                        <p>Uploaded Image:
                          <span>{uploadedFiles[index].name}</span>
                          <span
                            className={BizClass.removeIcon}
                            onClick={() => removeFile(index)}
                          >
                            &#10006;
                          </span>
                        </p>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </Modal.Body>


          <Modal.Footer>
            <Button
              varient="primary"
              onClick={() => uploadedData(uploadedFiles)}
            >
              Upload
            </Button>
          </Modal.Footer>
        </Modal>
      )}

      {isUploading && <Loader />}
      {isSuccessModalOpen && (
        <Modal
          varient="center"
          title=""
          index={3}
          show={closeEditModal}
          width="410px"
          height="200px"
          onClose={() => setIsSuccessModalOpen(false)}
        >
          <Modal.Body>
            <div className={BizClass.successMessage}>{successMessage}</div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              varient="primary"
              onClick={() => setIsSuccessModalOpen(false)}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      )}

      {isNoImagesSelected && (
        <Modal
          varient="center"
          title="Upload Document"
          index={3}
          show={closeEditModal}
          width="400px"
          height="200px"
          onClose={() => setIsNoImagesSelected(false)}
        >
          <Modal.Body>
            <div className={BizClass.uploadMessage}>Please Upload atleast one Document</div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              varient="primary"
              onClick={() => setIsNoImagesSelected(false)}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      )}

      {isServerBusy && (
        <Modal
          varient="center"
          title="Server Busy"
          index={3}
          show={closeEditModal}
          width="400px"
          height="200px"
          onClose={() => setServerBusy(false)}
        >
          <Modal.Body>
            <div className={BizClass.uploadMessage}>{serverMessage}</div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              varient="primary"
              onClick={() => setServerBusy(false)}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      )}


    </div>
  );
}

export default AssetsDetails;
