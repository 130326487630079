import { apiCalling } from "Service/Utilities/apiCalling";

async function GetApiData(request) {
  const { setDatalist, setLoad, requestData, apiPath, setAlert, fun, message = false } = request;
  debugger;
  try {
    setLoad(true);
    const result = await apiCalling(requestData, apiPath);
    setLoad(false);
    if (result.responseCode === 1) {
      if (
        result.responseData &&
        Object.values(result.responseData) &&
        Object.values(result.responseData)[0] &&
        Object.values(result.responseData)[0].length > 0
      ) {
        setDatalist(Object.values(result.responseData)[0]);
        console.log(Object.values(result.responseData)[0]);
        if (fun) {
          fun(Object.values(result.responseData)[0]);
        }
      } else {
        setDatalist([]);
      }
      if (message) {
        if (fun) {
          fun();
        }
        setAlert({ type: "success", message: result.responseMessage });
      }
    } else {
      setDatalist([]);
      setAlert({ type: "error", message: result.responseMessage });
      console.log(result.responseMessage);
    }
  } catch (error) {
    setAlert({ type: "error", message: error });
    console.log(error);
  }
}

export default GetApiData;
