import React, { useEffect } from "react";
import {
  // MASTERDATA_GROUP,
  MASTERDATA_INPUTTYPE,
  MASTERDATA_REFERENCETYPE,
  MASTERDATA_SAFTYCHECKPONIT,
  MASTERDATA_STATUSTYPE,
  REFERENCETYPE_TEXT,
  REFERENCETYPE_VALUE,
} from "Configration/Utilities/Constants/Constants";
import Form from "Framework/Components/Layout/FormGroup/FormGroup";
import Modal from "Framework/Components/Layout/Modal/Modal";
import { Button } from "Framework/Components/Widgets";
import { AiFillEdit } from "react-icons/ai";
import ObservationsDetailsLogic from "../../Logic/ObservationsDetailsLogic";
import AddAssets from "../AddAsset/AddAssets";
import BizClass from "./AddObservations.module.scss";
import AddSchedulePopUp from "../AddSchedule/AddSchedule";
import AddAssignedToPopUp from "../AssignToPopUp/AssignTo";

function AddObservations({ toggleAddObservationPopUp, selectedProjectData, getObservationListing }) {
  const {
    isReferanceDataLoad,
    referecneType,
    issafetyCheckListDataLoad,
    safetyCheckList,
    isGroupDataLoad,
    group,
    isInputTypeDataLoad,
    inputType,
    isStatusTypeDataLoad,
    StatusType,
    getMAsterObservationListing,
    toggleAddAsset,
    isActiveAddAssetPopUp,
    toggleAddSchedual,
    isActiveSchedualPopUp,
    updateFormState,
    formValues,
    saveObservation,
    formValidationError,
    // selectedAssets,
    setSelectedProjectData,
    assetsListData,
    ListViewColumDef,
    isLoadingAssetsData,
    onAssetGridReady,
    SaveSelectadAssetsRowData,
    updateScheduleState,
    scheduleFormValues,
    saveAddSchedule,
    handleScheduleValidation,
    scheduleFormValidationError,
    characterLimit,
    listOfMonth,
    listOfWeekDay,
    listofMonthlyWeek,
    isLoadingAddNewObservations,
    weekNameDay,
    isActiveAssignedToPopUp,
    toggleAssignedTo,
    TeamMemberListViewColumDef,
    getTeamsListingData,
    divsionList,
    isLoadingDivsion,
    selectedDivision,
    setSelectedDivision,
    teamsListMembersData,
    isLoadingTeamsMemberData,
    onTeamMemberGridReady,
    SaveSelectedAssignedToData,
    setTeamsListMembersData,
    GetBatchMaster,
  } = ObservationsDetailsLogic();

  useEffect(() => {
    // getMAsterObservationListing(MASTERDATA_GROUP);
    getMAsterObservationListing(MASTERDATA_INPUTTYPE);
    getMAsterObservationListing(MASTERDATA_REFERENCETYPE);
    getMAsterObservationListing(MASTERDATA_SAFTYCHECKPONIT);
    getMAsterObservationListing(MASTERDATA_STATUSTYPE);
    GetBatchMaster();
  }, []);

  return (
    <>
      {isActiveAddAssetPopUp && (
        <AddAssets
          toggleAddAsset={toggleAddAsset}
          selectedProjectData={selectedProjectData}
          setSelectedProjectData={setSelectedProjectData}
          assetsListData={assetsListData}
          ListViewColumDef={ListViewColumDef}
          isLoadingAssetsData={isLoadingAssetsData}
          onAssetGridReady={onAssetGridReady}
          SaveSelectadAssetsRowData={SaveSelectadAssetsRowData}
        />
      )}
      {isActiveAssignedToPopUp && (
        <AddAssignedToPopUp
          toggleAssignedTo={toggleAssignedTo}
          TeamMemberListViewColumDef={TeamMemberListViewColumDef}
          getTeamsListingData={getTeamsListingData}
          divsionList={divsionList}
          isLoadingDivsion={isLoadingDivsion}
          selectedProjectData={selectedProjectData}
          setSelectedDivision={setSelectedDivision}
          teamsListMembersData={teamsListMembersData}
          isLoadingTeamsMemberData={isLoadingTeamsMemberData}
          onTeamMemberGridReady={onTeamMemberGridReady}
          SaveSelectedAssignedToData={SaveSelectedAssignedToData}
          updateFormState={updateFormState}
          formValues={formValues}
          setTeamsListMembersData={setTeamsListMembersData}
          selectedDivision={selectedDivision}
        />
      )}
      {isActiveSchedualPopUp && (
        <AddSchedulePopUp
          toggleAddSchedual={toggleAddSchedual}
          updateScheduleState={updateScheduleState}
          scheduleFormValues={scheduleFormValues}
          scheduleFormValidationError={scheduleFormValidationError}
          saveAddSchedule={saveAddSchedule}
          handleScheduleValidation={handleScheduleValidation}
          listOfMonth={listOfMonth}
          listOfWeekDay={listOfWeekDay}
          listofMonthlyWeek={listofMonthlyWeek}
          assetEmployee={formValues.txtAssignedTo}
        />
      )}
      <Modal title="New Observation" varient="center" show={toggleAddObservationPopUp}>
        <Modal.Body>
          <Form>
            <Form.Group column={3} controlwidth="240px">
              <Form.InputGroup label="Checkpoint Name" req="true" errorMsg={formValidationError["txtObservaionCheckPoint"]}>
                <Form.InputControl
                  control="input"
                  name="txtObservaionCheckPoint"
                  maxLength="500"
                  onChange={(e) => {
                    updateFormState(e.target.value, e.target.name);
                  }}
                  value={formValues.txtObservaionCheckPoint}
                />
              </Form.InputGroup>
              <Form.InputGroup label="Description" req="false" errorMsg={formValidationError["txtDescription"]}>
                <Form.InputControl
                  control="input"
                  name="txtDescription"
                  maxLength="200"
                  onChange={(e) => {
                    updateFormState(e.target.value, e.target.name);
                  }}
                  value={formValues.txtDescription}
                />
              </Form.InputGroup>
              <Form.CustomGroup column={2} row={6}>
                <Form.InputGroup errorMsg={formValidationError["txtAssets"]}>
                  <div className={BizClass.AddressControl}>
                    <div className={BizClass.Header}>
                      <h4>{formValues.txtAssets && formValues.txtAssets.length > 0 ? `Asset (${formValues.txtAssets[0].ASSETCODE})` : "Select Asset"}</h4>
                      <button type="button" onClick={() => toggleAddAsset()}>
                        <AiFillEdit />
                      </button>
                    </div>
                    <span>
                      <b>{formValues.txtAssets && formValues.txtAssets.length > 0 ? `${formValues.txtAssets[0].ASSETNAME}` : ""}</b>
                    </span>
                  </div>
                </Form.InputGroup>
                <Form.InputGroup errorMsg={formValidationError["txtAssignedTo"]} req="true">
                  <div className={BizClass.AddressControl}>
                    <div className={BizClass.Header}>
                      <h4>Assigned To</h4>
                      <button
                        type="button"
                        onClick={() => {
                          toggleAssignedTo();
                        }}
                      >
                        <AiFillEdit />
                      </button>
                    </div>
                    <span>
                      <b>
                        {formValues.txtAssignedTo && formValues.txtAssignedTo.length > 0
                          ? formValues.txtAssignedTo.map((data) => data.EMPLOYEENAME).join(", ")
                          : ""}
                      </b>
                    </span>
                  </div>
                </Form.InputGroup>
                <Form.InputGroup errorMsg={formValidationError["txtSchedule"]}>
                  <div className={BizClass.AddressControl}>
                    <div className={BizClass.Header}>
                      <h4>Add Schedule</h4>
                      <button type="button" onClick={() => toggleAddSchedual()}>
                        <AiFillEdit />
                      </button>
                    </div>
                    {scheduleFormValues.txtDaily || scheduleFormValues.txtWeekly || scheduleFormValues.txtMonthly ? (
                      <>
                        {scheduleFormValues.txtDaily && (
                          <>
                            <span>
                              {scheduleFormValues.txtDaily && scheduleFormValues.txtDayilyRepeatEvery ? (
                                <p>
                                  <b>{`${scheduleFormValues.txtDayilyRepeatEvery} `}</b>
                                </p>
                              ) : null}
                              {scheduleFormValues.txtDaily && scheduleFormValues.txtDayilyTime ? (
                                <p>
                                  <b>{`${scheduleFormValues.txtDayilyTime} `}</b>
                                </p>
                              ) : null}
                            </span>
                            <span>
                              {scheduleFormValues.txtStartOn ? (
                                <p>
                                  <b>{`start on : ${scheduleFormValues.txtStartOn} `}</b>
                                </p>
                              ) : null}
                              {scheduleFormValues.txtUntil ? (
                                <p>
                                  <b>{`Until : ${scheduleFormValues.txtUntil}`} </b>
                                </p>
                              ) : null}
                            </span>
                          </>
                        )}
                        {scheduleFormValues.txtWeekly && (
                          <>
                            <span>
                              {scheduleFormValues.txtWeekly && scheduleFormValues.txtWeeklyRepeatEvery ? (
                                <p>
                                  <b>{`repeate  every :${scheduleFormValues.txtWeeklyRepeatEvery}  `}</b>
                                </p>
                              ) : null}
                              {scheduleFormValues.txtWeekly && weekNameDay ? (
                                <p>
                                  <b>{`Day :${weekNameDay} `}</b>
                                </p>
                              ) : null}
                            </span>
                            <span>
                              {scheduleFormValues.txtStartOn ? (
                                <p>
                                  <b>{`start on : ${scheduleFormValues.txtStartOn} `}</b>
                                </p>
                              ) : null}
                              {scheduleFormValues.txtUntil ? (
                                <p>
                                  <b>{`Until : ${scheduleFormValues.txtUntil}`}</b>
                                </p>
                              ) : null}
                            </span>
                            <span>
                              {scheduleFormValues.txtWeekly && scheduleFormValues.txtWeeklyTime ? (
                                <p>
                                  <b> {`Time :${scheduleFormValues.txtWeeklyTime} `}</b>
                                </p>
                              ) : null}
                            </span>
                          </>
                        )}

                        {scheduleFormValues.txtMonthly && (
                          <>
                            <span>
                              {scheduleFormValues.txtMonthly && scheduleFormValues.txtMonthlyNumericRepeatEveryMonth ? (
                                <p>
                                  <b>{`repeate  every :${scheduleFormValues.txtMonthlyNumericRepeatEveryMonth.label} `}</b>
                                </p>
                              ) : null}
                              {scheduleFormValues.txtMonthly && scheduleFormValues.txtMonthlyNumericMonthWeek ? (
                                <p>
                                  <b>{`Month of week :${scheduleFormValues.txtMonthlyNumericMonthWeek.label} `}</b>
                                </p>
                              ) : null}
                            </span>
                            <span>
                              {scheduleFormValues.txtMonthlyNumericWeekDay ? (
                                <p>
                                  <b>{`Day: ${scheduleFormValues.txtMonthlyNumericWeekDay.label} `}</b>
                                </p>
                              ) : null}
                              {scheduleFormValues.txtStartOn ? (
                                <p>
                                  <b>{`start on : ${scheduleFormValues.txtStartOn}`}</b>
                                </p>
                              ) : null}
                            </span>

                            <span>
                              {scheduleFormValues.txtUntil ? (
                                <p>
                                  <b>{`Until : ${scheduleFormValues.txtUntil}`}</b>
                                </p>
                              ) : null}
                              {scheduleFormValues.txtMonthly && scheduleFormValues.txtWeeklyTime ? (
                                <p>
                                  <b>{`Time :${scheduleFormValues.txtWeeklyTime} `}</b>
                                </p>
                              ) : null}
                            </span>
                          </>
                        )}
                      </>
                    ) : (
                      <span />
                    )}
                  </div>
                </Form.InputGroup>
                <Form.CustomGroup columntemplate="max-content auto">
                  <Form.InputGroup label="Shift">
                    <Form.InputControl
                      control="switch"
                      name="txtShift"
                      checked={formValues.txtShift}
                      onChange={(e) => {
                        updateFormState(e.target.checked, e.target.name);
                      }}
                    />
                  </Form.InputGroup>
                </Form.CustomGroup>
                <Form.CustomGroup columntemplate="max-content auto">
                  <Form.InputGroup label="Availabel in SCADA">
                    <Form.InputControl
                      control="switch"
                      name="txtAvailabelInSCADA"
                      checked={formValues.txtAvailabelInSCADA}
                      onChange={(e) => {
                        updateFormState(e.target.checked, e.target.name);
                      }}
                    />
                  </Form.InputGroup>
                </Form.CustomGroup>
              </Form.CustomGroup>
              <Form.InputGroup label="Group" req={formValues.txtShift ? "true" : ""} errorMsg={formValidationError["txtGroup"]}>
                <Form.InputControl
                  control="creatableselect"
                  name="txtGroup"
                  options={group}
                  isLoading={isGroupDataLoad}
                  getOptionLabel={(option) => {
                    return `${option.BATCHDESC}`;
                  }}
                  getOptionValue={(option) => `${option}`}
                  getNewOptionData={(label) => ({ BATCHDESC: label, BATCHID: 0, isNew: true })}
                  onChange={(e) => {
                    updateFormState(e, "txtGroup");
                  }}
                  value={formValues.txtGroup}
                />
              </Form.InputGroup>
              <Form.InputGroup label="Safety Checklist" req="true" errorMsg={formValidationError["txtsafetyCheckList"]}>
                <Form.InputControl
                  control="select"
                  name="txtsafetyCheckList"
                  options={safetyCheckList}
                  isLoading={issafetyCheckListDataLoad}
                  getOptionLabel={(option) => `${option.MASTERDISPLAYNAME}`}
                  getOptionValue={(option) => `${option}`}
                  onChange={(e) => {
                    updateFormState(e, "txtsafetyCheckList");
                  }}
                  value={formValues.txtsafetyCheckList}
                />
              </Form.InputGroup>
              <Form.InputGroup label="Reference Type" req="true" errorMsg={formValidationError["txtReferenceType"]}>
                <Form.InputControl
                  control="select"
                  name="txtReferenceType"
                  options={referecneType}
                  isLoading={isReferanceDataLoad}
                  getOptionLabel={(option) => `${option.MASTERDISPLAYNAME}`}
                  getOptionValue={(option) => `${option}`}
                  onChange={(e) => {
                    updateFormState(e, "txtReferenceType");
                  }}
                  value={formValues.txtReferenceType}
                />
              </Form.InputGroup>
              {formValues && formValues.txtReferenceType && formValues.txtReferenceType.MASTERVALUEID ? (
                formValues.txtReferenceType.MASTERVALUEID.toString() === REFERENCETYPE_VALUE ? (
                  <>
                    <Form.InputGroup column={1} label="Min" req="true" errorMsg={formValidationError["txtMin"]}>
                      <Form.InputControl
                        control="input"
                        name="txtMin"
                        onChange={(e) => {
                          updateFormState(e.target.value, e.target.name);
                        }}
                        value={formValues.txtMin}
                      />
                    </Form.InputGroup>
                    <Form.InputGroup column={1} label="Max" req="true" errorMsg={formValidationError["txtMax"]}>
                      <Form.InputControl
                        control="input"
                        name="txtMax"
                        onChange={(e) => {
                          updateFormState(e.target.value, e.target.name);
                        }}
                        value={formValues.txtMax}
                      />
                    </Form.InputGroup>
                    <Form.InputGroup column={1} label="label" req="true" errorMsg={formValidationError["txtlabel"]}>
                      <Form.InputControl
                        control="input"
                        name="txtlabel"
                        onChange={(e) => {
                          updateFormState(e.target.value, e.target.name);
                        }}
                        value={formValues.txtlabel}
                      />
                    </Form.InputGroup>
                  </>
                ) : null
              ) : null}
              {formValues && formValues.txtReferenceType && formValues.txtReferenceType.MASTERVALUEID ? (
                formValues.txtReferenceType.MASTERVALUEID.toString() === REFERENCETYPE_TEXT ? (
                  <Form.InputGroup column={1} label="Reference Text" req="true" errorMsg={formValidationError["txtReferenceText"]}>
                    <Form.InputControl
                      control="input"
                      name="txtReferenceText"
                      onChange={(e) => {
                        updateFormState(e.target.value, e.target.name);
                      }}
                      value={formValues.txtReferenceText}
                    />
                  </Form.InputGroup>
                ) : null
              ) : null}
              <Form.InputGroup label="Input Type" req="true" errorMsg={formValidationError["txtInputType"]}>
                <Form.InputControl
                  control="select"
                  name="txtInputType"
                  options={inputType}
                  isLoading={isInputTypeDataLoad}
                  getOptionLabel={(option) => `${option.MASTERDISPLAYNAME}`}
                  getOptionValue={(option) => `${option}`}
                  onChange={(e) => {
                    updateFormState(e, "txtInputType");
                  }}
                  value={formValues.txtInputType}
                />
              </Form.InputGroup>
              <Form.InputGroup label="Status Type" req="true" errorMsg={formValidationError["txtStatusType"]}>
                <Form.InputControl
                  control="select"
                  name="txtStatusType"
                  options={StatusType}
                  isLoading={isStatusTypeDataLoad}
                  getOptionLabel={(option) => `${option.MASTERDISPLAYNAME ? option.MASTERDISPLAYNAME : option.label}`}
                  getOptionValue={(option) => `${option}`}
                  onChange={(e) => {
                    updateFormState(e, "txtStatusType");
                  }}
                  value={formValues.txtStatusType}
                />
              </Form.InputGroup>
              <Form.InputGroup label="Actions" columnstart={1} column={3} row={6} req="true" errorMsg={formValidationError["txtAction"]}>
                <Form.InputControl
                  control="textarea"
                  name="txtAction"
                  maxLength="500"
                  autoComplete="off"
                  onChange={(e) => {
                    updateFormState(e.target.value, e.target.name);
                  }}
                  row={6}
                  value={formValues.txtAction}
                />
              </Form.InputGroup>
              <p className={BizClass.CounterRemark}>
                {formValues.txtAction && formValues.txtAction.length ? formValues.txtAction.length : 0} / {characterLimit}
              </p>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            varient="secondary"
            onClick={() => {
              saveObservation(getObservationListing);
            }}
            trigger={isLoadingAddNewObservations}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default AddObservations;
