import React, { useState, useEffect } from "react";
import { AlertMessage } from "Framework/Components/Widgets";
import { getSessionStorage } from "Service/auth/auth";
import { project } from "Service/Endpoints/endpoints";
import GetApiData from "Configration/Utilities/getApiData/GetApiData";
// import axios from 'axios';
import axios from "../../../../../Configration/axios/axios";






function AssetsLogics() {
    const setAlertMessage = AlertMessage();
    const user = getSessionStorage("userData");
    const selectedProjectData = getSessionStorage("selctedProject");
    const [assetsListData, setAssetsListData] = useState([]);
    const [isLoadingAssetsData, setIsLoadingAssetsData] = useState(false);
    const [assetsDetailseViewData, setAssetDetailsViewData] = useState(null);
    const [isActiveAssetViewPopUp, setIsActiveAssetviewPopUp] = useState(false);
    const [selectedAssets, setSelectedAssets] = useState([]);
    const [isLoadingAssetStatusData, setIsLoadingAssetStatusData] = useState(false);

    const [gridApi, setGridApi] = useState();
    const [searchText, setSearchText] = useState("");
    const [isActiveWorkAssignmentPopUp, setIsActiveWorkAssignmentPopUp] = useState(false);
    const getAssetListingData = () => {
        // setSelectedProjectData(ProjectData);
        const formData = {
            main: {
                divisionid: selectedProjectData && selectedProjectData.DIVISIONIDs ? selectedProjectData.DIVISIONIDs : "",
                profileid: user && user.UserProfileID ? user.UserProfileID : null,
                projectid: selectedProjectData && selectedProjectData.PROJECTID ? selectedProjectData.PROJECTID : "",
            },
        };
        console.log("formData", formData)
        const request = {
            setDatalist: setAssetsListData,
            setLoad: setIsLoadingAssetStatusData,
            requestData: formData,
            apiPath: project.projectList.GetKPCLAssetSelect,
            setAlert: setAlertMessage,
            fun: cleareSerchText,
        };
        GetApiData(request);
    };
    const toggleAssetDetailsView = (data) => {
        setIsActiveAssetviewPopUp(!isActiveAssetViewPopUp);
        if (data) {
            setAssetDetailsViewData(data);
        }
    };
    /* ************************************* Code For toggle popUp end ***************************** */

    const onGridReady = (params) => {
        setGridApi(params.api);
    };

    const onChangeSearchText = (val) => {
        setSearchText(val);
        gridApi.setQuickFilter(val);
        gridApi.refreshCells();
    };

    /* ************************************* Code For Api calling Start ***************************** */
    // getAssetListingData

    const cleareSerchText = (data) => {
        if (data) {
            if (searchText && searchText.toLowerCase().includes("#")) {
                onChangeSearchText("");
            }
        }
    };

    const toggleAddWorkAssignmentpopup = () => {
        setIsActiveWorkAssignmentPopUp(!isActiveWorkAssignmentPopUp);
      };

   
    // const setGender = (assetName, status, assetCode, equipmentNumber, statusValue) => {
    //     const updatedAssets = [...selectedAssets];
    //     const existingAssetIndex = updatedAssets.findIndex(
    //         (asset) => asset.assetName === assetName
    //     );

    //     if (existingAssetIndex !== -1) {
    //         updatedAssets[existingAssetIndex] = { assetName, status, assetCode, equipmentNumber, statusValue };
    //     } else {
    //         updatedAssets.push({ assetName, status, assetCode, equipmentNumber, statusValue });
    //     }

    //     setSelectedAssets(updatedAssets);
    // };

    const setGender = (assetName, status, assetCode, equipmentNumber, statusValue) => {
        const assetIndex = selectedAssets.findIndex(
            (asset) => asset.assetName === assetName
        );
    
        if (assetIndex !== -1) {
            const updatedAssets = [...selectedAssets];
            updatedAssets[assetIndex] = {
                assetName,
                status,
                assetCode,
                equipmentNumber,
                statusValue,
            };
            setSelectedAssets(updatedAssets);
        } else {
            setSelectedAssets((prevAssets) => [
                ...prevAssets,
                {
                    assetName,
                    status,
                    assetCode,
                    equipmentNumber,
                    statusValue,
                },
            ]);
        }
    };
    

    const assetStatusChanged = async () => {
        const updatedAssets = [...selectedAssets];
        console.log("updatedAssets from assetStatusLogic", updatedAssets);
        const formData = {
            asset_IDs: updatedAssets.map((asset) => String(asset.assetCode)),
            asset_Staus: updatedAssets.map((asset) => String(asset.status)),
        };
        console.log("formData", formData);
        try {
            const response = await axios.post(`${project.projectList.SetAssetStatus}`, formData, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            console.log("API Response:", response.data);
            if (response.status === 200) {
                alert('Status Saved Successfully!');
                setIsActiveWorkAssignmentPopUp(!isActiveWorkAssignmentPopUp);

            } else {
                console.error("Unexpected response status:", response.status);
            }
        } catch (error) {
            console.error("Error in assetStatusChanged:", error);
        }
    };

   



    return {
        isLoadingAssetsData,
        assetsListData,
        getAssetListingData,
        selectedProjectData,
        toggleAssetDetailsView,
        assetsDetailseViewData,
        isActiveAssetViewPopUp,
        searchText,
        onGridReady,
        onChangeSearchText,
        assetStatusChanged,
        isLoadingAssetStatusData,
        setIsLoadingAssetStatusData,
        setGender,
        // ListViewColumDef,
        setSelectedAssets,
        selectedAssets,
        toggleAddWorkAssignmentpopup,
        isActiveWorkAssignmentPopUp
    };
}

export default AssetsLogics;
