import React, { useEffect, useState, useRef } from "react";
import { Form, FormGroup, FormControl, Dropdown } from "react-bootstrap";
import axios from "../../../../Configration/axios/axios";
import { DataGrid, PageBar } from "Framework/Components/Layout";
import { project } from "Service/Endpoints/endpoints";
import { getSessionStorage } from "Service/auth/auth";
import BizClass from "./CaptureLogSheetsExcel.module.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import Multiselect from "multiselect-react-dropdown";
import { Loader, Splitter } from "Framework/Components/Widgets";
import { ImEye } from "react-icons/im";
import Modal from "Framework/Components/Layout/Modal/Modal";
import { Button } from "Framework/Components/Widgets";
import { MdArrowBack } from "react-icons/md";

const LogSheetForm = () => {
  const [logSheetData, setLogSheetData] = useState([]);
  const [formData, setFormData] = useState({});
  const [readingUnit, setReadingUnit] = useState("yes");
  const [uniqueQuestions, setUniqueQuestions] = useState(new Set());
  const [displayedQuestions, setDisplayedQuestions] = useState([]);
  const displayedQuestionsRef = useRef(new Set());
  const [loading, setisloading] = useState(false);
  const [logSheetName, setLogSheetName] = useState({});
  const [tableData, setTableData] = useState([]);
  const [selectedLogSheet, setSelectedLogSheet] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [logSheetDetailFetched, setLogSheetDetailFetched] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [logsheetId, setLogSheetId] = useState("");
  const [hour, setHour] = useState([]);
  const [Dropdown, setdropdown] = useState([]);
  const [sheetContents, setSheetContents] = useState([]);
    const [selectedLogSheetName,setSelectedLogSheetName]=useState("");

  const formRef = useRef();

  const fetchLogSheets = async (logsheet) => {
    console.log("triggered");
    const formData = {
      sheetID: logsheet,
      fromDate: formatDate(),
      toDate: formatDate(),
    };
    try {
      const response = await axios.post(`${project.projectList.GetLogSheetReport_By_LogsheetID}`, formData);

      if (response.status !== 200) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const jsonData = response.data.responseDynamic;
      const parsedData = JSON.parse(jsonData);

      if (parsedData !== null) {
        const jsonLogs = parsedData.Table.map((item) => item.JSONLOG);
        const contents = jsonLogs.map((jsonString) => JSON.parse(jsonString));
        setSheetContents(contents);

        const sheetName = parsedData.Table.map((item) => item.LOGSHEET_NAME);
        const hourId = parsedData.Table.map((item) => item.HRID);

        setHour(hourId);
        setdropdown(sheetName);
      } else {
        console.error("No data available in the response:", parsedData);
      }
    } catch (error) {
      console.error("Error fetching log sheets:", error);
      throw error;
    }
  };

  const openLogSheet = async (logsheet) => {
    const logSheetsData = {
      sheetId: logsheet,
    };

    try {
      const response = await axios.post(`${project.projectList.GetLogSheetDetailBySheetID}`, logSheetsData);

      const data = response.data.responseDynamic;
      const jsonData = JSON.parse(data);
      const readingsJson = JSON.parse(
        jsonData.Table[0].LOG_JSON || (jsonData.Table[0].READINGS_JSON ? jsonData.Table[0].READINGS_JSON : jsonData[1].READINGS_JSON),
      );
      // const readingsJson = JSON.parse(jsonData.Table[0].READINGS_JSON ? jsonData.Table[0].READINGS_JSON : jsonData[1].READINGS_JSON);
      // console.log("readingsJson", readingsJson);
      // setLogSheetData(readingsJson)

      if (jsonData.Table[0].LOG_JSON) {
        console.log("readingsJson", readingsJson);
        setLogSheetName(readingsJson);
        console.log("logSheetName", logSheetName);
        setLogSheetData(readingsJson.questions);
      } else {
        console.log("readingsJson", readingsJson);
        setLogSheetName(readingsJson);
        setLogSheetData(readingsJson);
      }
    } catch (error) {
      console.error("Error fetching log sheet data:", error);
    }
  };
  const cellTemplate = (params) => {
    const style = {
      display: "flex",
      fontSize: "16px",
      background: "transparent",
      border: "0px solid transparent",
      padding: "4px 0px 5px 0px",
      cursor: "pointer",
    };

    const onTeamMemberListClick = () => {
      if (params) {
        params.setSelectedLogSheet(params.data);

        setLogSheetId(params.data.LOG_SHEET_ID);
        setSelectedLogSheetName(params.data.LOG_SHEET_NAME);
        setIsModalOpen(true);

        openLogSheet(params.data.LOG_SHEET_ID);
        fetchLogSheets(params.data.LOG_SHEET_ID);
      }
    };

    useEffect(() => {}, [isModalOpen]); // Run the effect only when isModalOpen changes

    return (
      <div style={{ display: "flex", gap: "8px", justifyContent: "center", margin: "3px" }}>
        <button type="button" title="Capture LogSheets" onClick={() => onTeamMemberListClick()} style={style}>
          <ImEye style={{ color: "rgb(52, 73, 94)" }} />
        </button>
      </div>
    );
  };

  const [unit, setUnit] = useState({
    unitID: "",
    unitName: "",
  });

  const [designation, setDesignation] = useState("");

  const selectedProjectData = getSessionStorage("selctedProject");
  console.log("selectedProjectData", selectedProjectData);
  const user = getSessionStorage("userData");
  console.log("user", user);

  const [unitInfo, setUnitInfo] = useState([]);
  const [selectedValues, setSelectedValues] = useState([]);
  const [deisgnationselectedValues, setDesignationSelectedValues] = useState([]);
  const [MemberListViewColumDef] = useState([
    { field: "LOG_SHEET_ID", headerName: "LogSheet ID", width: 120 },
    { field: "LOG_SHEET_NAME", headerName: "LogSheet Name", width: 320 },
    {
      headerName: "Action",
      field: "#",
      width: 120,
      height: 120,
      pinned: "left",
      cellRenderer: cellTemplate,
      cellRendererParams: {
        setSelectedLogSheet,
      },
    },
  ]);

  const designationOptions = [
    {
      value: 1,
      lable: "JE",
    },
    {
      value: 2,
      lable: "AE",
    },
    {
      value: 3,
      lable: "AEE",
    },
    {
      value: 4,
      lable: "EE",
    },
    {
      value: 5,
      lable: "SE",
    },
    {
      value: 6,
      lable: "CE",
    },
    {
      value: 7,
      lable: "ED",
    },
    {
      value: 8,
      lable: "TD",
    },
    {
      value: 9,
      lable: "MD",
    },
    {
      value: 10,
      lable: "DHR",
    },
  ];

  const onSelect = (selectedList, selectedItem) => {
    setSelectedValues(selectedList);
  };

  const onRemove = (selectedList, removedItem) => {
    setSelectedValues(selectedList);
  };

  const options = unitInfo.map((info) => ({
    value: info.unitID,
    label: info.unitName,
  }));

  const onDesignationSelect = (selectedList, selectedItem) => {
    setDesignationSelectedValues(selectedList);
  };

  const onDesignationRemove = (selectedList, removedItem) => {
    setDesignationSelectedValues(selectedList);
  };

  useEffect(() => {
    const unitInfo = async () => {
      try {
        const formData = {
          projectID: selectedProjectData.PROJECTID,
        };
        const response = await axios.post(`${project.projectList.GetUnittInfo}`, formData);
        console.log("Unit response", response.data.responseDynamic);
        setUnitInfo(response.data.responseDynamic);
      } catch (error) {
        console.error("Error fetching unit information:", error);
      }
    };
    unitInfo();
    return () => {};
  }, [selectedProjectData.PROJECTID]);

  let questionCounter = 0;
  useEffect(() => {
    setDisplayedQuestions([]);
    displayedQuestionsRef.current.clear();
  }, [logSheetData]);

  const handleQuestionDisplay = (questionId) => {
    if (!displayedQuestionsRef.current.has(questionId)) {
      displayedQuestionsRef.current.add(questionId);
      setDisplayedQuestions((prevDisplayedQuestions) => [...prevDisplayedQuestions, questionId]);
      return true;
    }
    return false;
  };

  let previousId = null;

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  console.log("logSheetData", logSheetData);
  const handleReadingUnitChange = (event) => {
    setReadingUnit(event.target.value);
  };

  const closeEditModal = () => {
    setIsModalOpen(false);
  };

  // useEffect(() => {
  //     // if (unit.length !== 0 && designation.length !== 0) {
  //     const formData = {
  //         divisionID: 0,
  //         unitID: parseInt(unit, 10),
  //         projectID: parseInt(selectedProjectData.PROJECTID, 10),
  //         employeeLevel: parseInt(designation, 10),
  //     };

  //     console.log("formData", formData);

  //     axios.post(`${project.projectList.GetLogSheetDetail}`, formData)
  //         .then((response) => {
  //             console.log("response", response.data);
  //             const dynamicData = JSON.parse(response.data.responseDynamic);
  //             setTableData(dynamicData.Table);
  //             setLogSheetDetailFetched(true);
  //         })
  //         .catch((error) => {
  //             console.error("Error", error);
  //         });
  //     // } else {
  //     //     console.error("Invalid unit or designation");
  //     // }
  // }, []);

  useEffect(() => {
    if (unit && designation) {
      const formData = {
        divisionID: 0,
        unitID: parseInt(unit.unitID, 10),
        projectID: parseInt(selectedProjectData.PROJECTID, 10),
        employeeLevel: parseInt(designation, 10),
      };

      console.log("formData", formData);

      axios
        .post(`${project.projectList.GetLogSheetDetail}`, formData)
        .then((response) => {
          console.log("response", response.data);
          const dynamicData = JSON.parse(response.data.responseDynamic);
          setTableData(dynamicData.Table);
          setLogSheetDetailFetched(true);
        })
        .catch((error) => {
          console.error("Error", error);
        });
    }
  }, [unit, designation, selectedProjectData.PROJECTID]);

  const handleSubmit = async (event) => {
    console.log("unit.unitID",unit.unitID)

    event.preventDefault();
    setIsLoading(true);

    try {
      // const formData = new FormData(formRef.current);

      // Prepare basic data for enteredValues
      const unitID = unit.unitID.split(",")[0];

      let enteredValues = {
        hr_id: "1",
        unit_id: parseInt(unitID) || 0,
        fk_user_id: user.UserName || 0,
        fk_logsheet_id: logsheetId,
        readings_avlb: readingUnit === "no" ? "no" : "yes",
        readings_remarks: "Remarks",
      };

      // Transform logSheetData into desired structure
      const transformedData = logSheetData.map((logSheet, index) => ({
        log_sheet_id: logSheetName.LOG_SHEET_ID || 0,
        unit: String(logSheetName.Unit) || "",
        log_sheet_name: logSheetName.LogSheetName || "",
        questionText: logSheet.questionText || "",
        readingsCount: logSheet.readingsCount || 0,
        options: logSheet.options.map((option, optionIndex) => ({
          optionText: option.optionText || "",
          validationRequired: option.validationRequired || "",
          minValue: option.minValue || "",
          maxValue: option.maxValue || "",
          optionValue: document.getElementById(`optionValue_${index}_${optionIndex}`).value || "0", // Use formData to get option values
          suboptionsRequired: option.suboptionsRequired || "",
          suboptionCount: option.suboptionCount,
          subOptions: option.subOptions.map((suboption, suboptionIndex) => ({
            suboptionText: suboption.suboptionText || "",
            suboptionValidation: suboption.suboptionValidation || "",
            suboptionMinValue: suboption.suboptionMinValue || "",
            suboptionMaxValue: suboption.suboptionMaxValue || "",
            suboptionValue: document.getElementById(`question_${index}_${optionIndex}_${suboptionIndex}`)?.value || "0", // Use formData to get suboption values
          })),
        })),
      }));

      enteredValues.readings_json = transformedData;

      console.log("enteredValues", enteredValues);

      // Perform Axios POST request
      const response = await axios.post(`${project.projectList.InsertLogSheetReadingDetail}`, enteredValues);

      console.log("response", response);

      if (response.data.responseMessage === "Success") {
        alert("Readings have been successfully submitted");
        setIsModalOpen(false);
        setIsLoading(false);
      } else {
        alert("Failed to Submit the Form, Please try again");
      }
    } catch (error) {
      console.log("Error submitting form:", error);
      alert("Failed to submit form. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const validation = (event) => {
    const enteredValue = event.target.value.trim();
    const minValue = parseFloat(event.target.min);
    const maxValue = parseFloat(event.target.max);

    const inputField = event.target;
    const errorMessageContainer = inputField.nextElementSibling || inputField.nextSibling;
    if (isNaN(enteredValue)) {
      return;
    }

    if (enteredValue < minValue || enteredValue > maxValue) {
      alert("Entered value is not within the range");
      inputField.style.borderColor = "red";
      inputField.style.borderWidth = "2px";
      if (errorMessageContainer) {
        errorMessageContainer.style.color = "red";
      }
    }
  };

  const handleUnitChange = (event) => {
    const selectedOption = event.target.selectedOptions[0];
    const unitID = selectedOption.getAttribute("data-unit-id");
    const unitName = selectedOption.getAttribute("data-unit-name");

    setUnit({
      unitID: unitID,
      unitName: unitName,
    });
  };
  const handleDesignationChange = (event) => {
    setDesignation(event.target.value);
  };

  const formatDate = () => {
    const date = new Date();
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const today = new Date();
  const dayNames = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
  const day = dayNames[today.getDay()];

  // Format date as "dd-MM-yyyy"
  const formattedDate = `${today.getDate()}-${today.getMonth() + 1}-${today.getFullYear()}`;

  const title = `KARNATAKA POWER CORPORATION LIMITED. RAICHUR THERMAL POWER STATION.`;

  return (
    <div className={BizClass.PageStart}>
      <Splitter varient="column" template="1.1fr 9px 1fr">
        <div className={BizClass.Card}>
          <PageBar className={BizClass.CustomPageBar}>
            <span>LogSheet List</span>
          </PageBar>
          <div className={BizClass.Card}>
            <div className="container">
              <FormGroup>
                <Form.Label htmlFor="unit" className="py-2" required>
                  Unit ID:
                </Form.Label>
                {/* <select id="unit" name="unit" className="form-control" onChange={handleUnitChange}>
                  <option value="">Select Unit ID</option>
                  {unitInfo.map((type) => (
                    <option key={type.unitID} value={type.unitID}>
                      {type.unitName}
                    </option>
                  ))}
                </select> */}
                <select id="unit" name="unit" className="form-control" onChange={handleUnitChange}>
                  <option value="">Select Unit ID</option>
                  {unitInfo.map((type) => (
                    <option key={type.unitID} value={type.unitID} data-unit-id={type.unitID} data-unit-name={type.unitName}>
                      {type.unitName}
                    </option>
                  ))}
                </select>
              </FormGroup>

              <FormGroup>
                <Form.Label htmlFor="logSheetName" className="py-2" required>
                  Designation:
                </Form.Label>
                <select id="designation" name="designation" className="form-control" onChange={handleDesignationChange}>
                  <option value="">Select Designation ID</option>
                  {designationOptions.map((type) => (
                    <option key={type.value} value={type.value}>
                      {type.lable}
                    </option>
                  ))}
                </select>
              </FormGroup>
            </div>
          </div>
        </div>
        <div className={BizClass.Card}>
          <PageBar className={BizClass.CustomPageBar}>
            <span>LogSheet List</span>
          </PageBar>
          <DataGrid rowData={tableData} columnDefs={MemberListViewColumDef} />
        </div>
      </Splitter>

      {isModalOpen && (
        <Modal varient="center" title="Capture Logsheet Excel" index={2} show={closeEditModal} width="100%" height="100%">
          <Modal.Body>
            <>
              {isLoading && <Loader />}
              <div className="project-title-container py-3 px-3">
                <div className="d-flex justify-content-between">
                  <div className="d-flex justify-content-start font-weight-bold" style={{ fontWeight: "bold" }}>
                    {title}
                  </div>
                  <div style={{ fontWeight: "bold" }}>Date: {formattedDate}</div>
                </div>
                <div className="d-flex justify-content-between">
                  <div className="project-title" style={{ fontWeight: "bold" }}>
                    Unit No: {unit.unitName}
                  </div>
                  <div className="project-title" style={{ fontWeight: "bold" }}>
                   {selectedLogSheetName}
                  </div>
                  <div className="date-day" style={{ fontWeight: "bold" }}>
                    Day: {day}
                  </div>
                </div>
              </div>
              <table className="table table-bordered table-responsive-xl" id="wrapper">
                {/* <thead>
                                <tr>
                                    <th style={{ width: '30px' }}>Time in Hours</th>
                                    {logSheetData.map((logSheet, index) => (
                                        logSheet.options.length > 0 && (
                                            <th key={index} colSpan={logSheet.options.length} style={{ width: '50px' }}>
                                                {logSheet.questionText}
                                            </th>
                                        )

                                    ))}
                                </tr>

                                {logSheetData.map((logSheet, index) => (
                                    <>
                                        <tr key={index}>
                                            {logSheet.options.some(option => option.subOptions) && (
                                                logSheet.options.map((option, optionIndex) => (
                                                    <React.Fragment key={optionIndex}>
                                                        {option.subOptions && option.subOptions.map((subOption, subOptionIndex) => (
                                                            <th key={subOptionIndex} style={{ width: '30px' }}>
                                                                {subOption.suboptionText}
                                                            </th>
                                                        ))}
                                                    </React.Fragment>
                                                ))
                                            )}
                                        </tr>
                                        <tr>
                                            {
                                                logSheet.options.map((option, optionIndex) => (
                                                    <th key={optionIndex} style={{ width: '30px' }}>
                                                        {option.optionText}
                                                    </th>
                                                ))
                                            }

                                        </tr>
                                        <tr>
                                            {
                                                logSheet.options.map((option, optionIndex) => (
                                                    <th key={optionIndex} style={{ width: '30px' }}>
                                                        <span style={{ fontWeight: 'bold' }}>Min:</span>{option.minValue} <br />
                                                        <span style={{ fontWeight: 'bold' }}>Max:</span>{option.maxValue}
                                                    </th>
                                                ))
                                            }
                                        </tr>
                                    </> 



                                ))}
                            </thead> */}

                <thead>
                  <tr>
                    <th style={{ width: "30px", background: "#8098b5", color: "white" }}>Time in Hours</th>
                    {logSheetData.map((logSheet, index) =>
                      logSheet.options.map((option, optionIndex) => (
                        <th style={{ background: "#8098b5", color: "white" }} key={`header_${index}_${optionIndex}`} colSpan={option.subOptions.length}>
                          {logSheet.questionText}
                        </th>
                      )),
                    )}
                    <th style={{ background: "#8098b5", color: "white" }}>Action</th>
                  </tr>

                  <tr>
                    <th style={{ background: "#8098b5", color: "white" }}></th>
                    {logSheetData.map(
                      (logSheet, index) =>
                        logSheet.options.length > 0 &&
                        logSheet.options.map((option, optionIndex) => (
                          <th style={{ background: "#8098b5", color: "white" }} key={`${index}_${optionIndex}`} colSpan={option.subOptions.length}>
                            {option.optionText !== "" ? option.optionText : " "}
                          </th>
                        )),
                    )}
                    <th style={{ background: "#8098b5", color: "white" }}></th>
                  </tr>

                  <tr style={{ background: "#8098b5", color: "white" }}>
                    <th style={{ background: "#8098b5", color: "white" }}></th>
                    {logSheetData.map(
                      (logSheet, index) =>
                        logSheet.options.length > 0 &&
                        logSheet.options.map((option, optionIndex) => (
                          <React.Fragment key={`option_${index}_${optionIndex}`}>
                            {option.subOptions ? (
                              option.subOptions.map((suboption, suboptionIndex) => (
                                <React.Fragment key={`suboption_${index}_${optionIndex}_${suboptionIndex}`}>
                                  {suboption.suboptionText !== "" ? (
                                    <th style={{ background: "#8098b5", color: "white" }} colSpan={suboption.length}>
                                      {suboption.suboptionText}
                                    </th>
                                  ) : (
                                    <th style={{ background: "#8098b5", color: "white" }} colSpan={suboption.length}></th>
                                  )}
                                </React.Fragment>
                              ))
                            ) : (
                              <th style={{ background: "#8098b5", color: "white" }}></th> // Render an empty cell if subOptions is undefined or empty
                            )}
                          </React.Fragment>
                        )),
                    )}
                    <th style={{ background: "#8098b5", color: "white" }}></th>
                  </tr>

                  <tr>
                    <th style={{ background: "#8098b5", color: "white" }}></th>
                    {/* Render suboption names and min-max values */}
                    {logSheetData.map(
                      (logSheet, index) =>
                        logSheet.options.length > 0 &&
                        logSheet.options.map((option, optionIndex) => (
                          <React.Fragment key={`${index}_${optionIndex}`}>
                            {option.subOptions &&
                              option.subOptions.map((subOption, subOptionIndex) => (
                                <React.Fragment key={`${index}_${optionIndex}_${subOptionIndex}`}>
                                  <th style={{ background: "#8098b5", color: "white" }}>
                                    <span style={{ fontWeight: "bold", background: "#8098b5", color: "white" }}>Min:</span>{" "}
                                    {subOption.suboptionMinValue !== "" ? subOption.suboptionMinValue : option.minValue} <br />
                                    <span style={{ fontWeight: "bold", background: "#8098b5", color: "white" }}>Max:</span>{" "}
                                    {subOption.suboptionMaxValue !== "" ? subOption.suboptionMaxValue : option.maxValue}
                                  </th>
                                </React.Fragment>
                              ))}
                          </React.Fragment>
                        )),
                    )}
                    <th style={{ background: "#8098b5", color: "white" }}></th>
                  </tr>
                </thead>

                {/* <tbody>
                                {[...Array(24)].map((_, rowIndex) => (
                                    <tr key={rowIndex}>
                                        <td>{rowIndex + 1}</td>
                                        {logSheetData.map((logsheet, index) => (
                                            logsheet.options.map((option, optionIndex) => (
                                                option.subOptions.map((subOption, subOptionIndex) => {
                                                    const minValue = option.minValue !== "" ? option.minValue : subOption.suboptionMinValue; // Example: Minimum allowed value
                                                    const maxValue = option.maxValue !== "" ? option.maxValue : subOption.suboptionMaxValue; // Example: Maximum allowed value

                                                    return (
                                                        <td key={`${index}-${optionIndex}-${subOptionIndex}`} style={{ width: '30px' }}>
                                                            <input
                                                                type="text"
                                                                style={{
                                                                    border: '1px solid #ccc',
                                                                    width: '100%',
                                                                    padding: '5px',
                                                                    boxSizing: 'border-box',
                                                                }}
                                                            />
                                                        </td>
                                                    );
                                                })
                                            ))
                                        ))}
                                        <td><button className='btn btn-primary'>Submit</button></td>
                                    </tr>
                                ))}
                            </tbody> */}

                <tbody>
                  {sheetContents.length > 0 ? (
                    <>
                      {hour && (
                        <>
                          {sheetContents.map((question, questionIndex) => (
                            <tr key={questionIndex}>
                              <td style={{ width: "30px" }}>{questionIndex + 1}</td>
                              {question.map((option, optionIndex) => (
                                <React.Fragment key={optionIndex}>
                                  {option.options.map((subOption, subOptionIndex) => (
                                    <td
                                      key={`${optionIndex}_${subOptionIndex}`}
                                      style={{
                                        width: "30px",
                                        border: `${
                                          subOption.optionValue > subOption.maxValue || subOption.optionValue < subOption.minValue
                                            ? "2px solid red"
                                            : "1px solid rgb(204, 204, 204);"
                                        }`,
                                      }}
                                    >
                                      <input
                                        type="text"
                                        name={`input_${questionIndex}_${optionIndex}_${subOptionIndex}`}
                                        style={{
                                          border: "none",
                                          width: "100%",
                                        }}
                                        value={subOption.optionValue}
                                        readOnly
                                      />
                                    </td>
                                  ))}
                                </React.Fragment>
                              ))}
                            </tr>
                          ))}
                        </>
                      )}

                      {[...Array(24 - sheetContents.length)].map((_, rowIndex) => (
                        <tr key={rowIndex}>
                          <td>{sheetContents.length + rowIndex + 1}</td>
                          {logSheetData.map((logsheet, index) =>
                            logsheet.options.map((option, optionIndex) =>
                              option.subOptions.map((subOption, subOptionIndex) => {
                                const minValue = option.minValue !== "" ? option.minValue : subOption.suboptionMinValue;
                                const maxValue = option.maxValue !== "" ? option.maxValue : subOption.suboptionMaxValue;

                                return (
                                  <td key={`${index}-${optionIndex}-${subOptionIndex}`} style={{ width: "30px" }}>
                                    <form ref={formRef} onSubmit={handleSubmit}>
                                      {rowIndex === 0 ? (
                                        <input
                                          type="text"
                                          id={`optionValue_${index}_${optionIndex}`}
                                          name={`optionValue_${index}_${optionIndex}`}
                                          min={minValue}
                                          max={maxValue}
                                          onBlur={validation}
                                          required
                                          style={{
                                            border: "1px solid #ccc",
                                            width: "100%",
                                            padding: "5px",
                                            boxSizing: "border-box",
                                          }}
                                        />
                                      ) : (
                                        <input
                                          type="text"
                                          id={`optionValue_${index}_${optionIndex}`}
                                          name={`optionValue_${index}_${optionIndex}`}
                                          min={minValue}
                                          max={maxValue}
                                          onBlur={validation}
                                          required
                                          style={{
                                            border: "1px solid #ccc",
                                            width: "100%",
                                            padding: "5px",
                                            boxSizing: "border-box",
                                          }}
                                          readOnly
                                        />
                                      )}
                                    </form>
                                  </td>
                                );
                              }),
                            ),
                          )}
                          <td>
                            {" "}
                            {rowIndex === 0 && (
                              <button
                                type="submit"
                                className="btn btn-primary"
                                onClick={(event) => {
                                  event.preventDefault();
                                  handleSubmit(event);
                                }}
                              >
                                Submit
                              </button>
                            )}
                          </td>
                        </tr>
                      ))}
                    </>
                  ) : (
                    [...Array(24)].map((_, rowIndex) => (
                      <tr key={rowIndex}>
                        <td>{rowIndex + 1}</td>
                        {logSheetData.map((logsheet, index) =>
                          logsheet.options.map((option, optionIndex) =>
                            option.subOptions.map((subOption, subOptionIndex) => {
                              const minValue = option.minValue !== "" ? option.minValue : subOption.suboptionMinValue;
                              const maxValue = option.maxValue !== "" ? option.maxValue : subOption.suboptionMaxValue;

                              return (
                                <td key={`${index}-${optionIndex}-${subOptionIndex}`} style={{ width: "30px" }}>
                                  <form ref={formRef} onSubmit={handleSubmit}>
                                    {rowIndex === 0 ? (
                                      <input
                                        type="text"
                                        id={`optionValue_${index}_${optionIndex}`}
                                        name={`optionValue_${index}_${optionIndex}`}
                                        min={minValue}
                                        max={maxValue}
                                        onBlur={validation}
                                        style={{
                                          border: "1px solid #ccc",
                                          width: "100%",
                                          padding: "5px",
                                          boxSizing: "border-box",
                                        }}
                                      />
                                    ) : (
                                      <input
                                        type="text"
                                        id={`optionValue_${index}_${optionIndex}`}
                                        name={`optionValue_${index}_${optionIndex}`}
                                        min={minValue}
                                        max={maxValue}
                                        onBlur={validation}
                                        style={{
                                          border: "1px solid #ccc",
                                          width: "100%",
                                          padding: "5px",
                                          boxSizing: "border-box",
                                        }}
                                        readOnly
                                      />
                                    )}
                                  </form>
                                </td>
                              );
                            }),
                          ),
                        )}
                        <td>
                          {rowIndex === 0 && (
                            <button
                              type="submit"
                              className="btn btn-primary"
                              onClick={(event) => {
                                event.preventDefault();
                                handleSubmit(event);
                              }}
                            >
                              Submit
                            </button>
                          )}
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </>
          </Modal.Body>
        </Modal>
      )}
    </div>
  );
};

export default LogSheetForm;
