// ************************** Athentication Pages Endpoints ************************** //

export const auth = {
  login: {
    userLogin: "KPCL/UserLogin",
  },
  logout: {
    SessionOut: "KPCL/SessionOut",
  },

  signUp: {
    getUserAuthenticated: "KPCL/UserAuthentication",
    otpValidatation: "KPCL/OTPValidate",
    userRegistration: "KPCL/UserRegistered",
  },
};

// ************************** project Pages Endpoints ************************** //

export const project = {
  projectList: {
    getProjectListData: "KPCL/ProjectView",
    GetKPCLTeam: "KPCL/GetKPCLTeam",
    GetKPCLAssetSelect: "KPCL/GetKPCLAssetSelect",
    GetAssetType: "KPCL/GetAssetType",
    GetKPCLDivisionDetail: "KPCL/GetKPCLDivisionDetail",
    ObservationSelect: "KPCL/ObservationSelect",
    GetAssetEmpSelect: "KPCL/GetAssetEmpSelect",
    GetMasterDataBinding: "KPCL/GetMasterDataBinding",
    GetObservationAssetSelect: "KPCL/GetObservationAssetSelect",
    GetKPCLAssetQrCodesSelect: "KPCL/GetKPCLAssetQrCodesSelect",
    AddObservationDetail: "KPCL/AddObservationDetail",
    GetEmployeeAssetSelect: "KPCL/GetEmployeeAssetSelect",
    GetAssetObservationHistory: "KPCL/GetAssetObservationHistory",
    AddAssetEmployeeAssignment: "KPCL/AddAssetEmployeeAssignment",
    GetAssetHistorySelect: "KPCL/GetAssetHistorySelect",
    GetAssetEmployeeAssignment: "KPCL/GetAssetEmployeeAssignment",
    GetObservationPendingSelect: "KPCL/GetObservationPendingSelect",
    SetObservationApproveReject: "KPCL/SetObservationApproveReject",
    SetKPCLStatusUpdate: "KPCL/SetKPCLStatusUpdate",
    GetAlertsEvents: "KPCL/GetAlertsEvents",
    GetEmployeeAssetAppSelect: "KPCL/GetEmployeeAssetAppSelect",
    GetBatchMaster: "KPCL/GetBatchMaster",
    GetObservationStatusReport: "KPCL/GetObservationStatusReport",
    GetObservationDivisionStatusReport: "KPCL/GetObservationDivisionStatusReport",
    GetDefectDetails: "KPCL/Get_DefectDetails_By_DivisionID",
    GetEmployeeIDDetails: "KPCL/Get_EmpLst_By_ProjectID_DivisionID",
    GetPTWDetails: "KPCL/GetPTWDetails",
    UploadDocs: "api/Filedoc/UploadDocs",
    AddAssetEmployeeObservationAssignment: "KPCL/AddAssetEmployeeAssignmentObservationWise",
    SetAssetStatus: "KPCL/SetAssetStaus",
    UpdateAsset: "KPCL/UpdateAsset",
    EmployeeGrievances: "KPCL/Employee_Grievances",
    GetGrievances: "KPCL/Get_Grievances",
    TransferEmployee: "KPCL/Transfer_Employee",
    EditObservation: "KPCL/EditObservation",
    AddBulkObservationDetail: "KPCL/AddBulkObservationDetail",
    InsertLogSheetDetail: "KPCL/InsertLogSheetDetail",
    InsertLogSheetReadingDetail: "KPCL/InsertLogSheetReadingDetail",
    GetLogSheetDetailBySheetID: "KPCL/GetLogSheetDetailBySheetID",
    GetLogSheetDetail: "KPCL/GetLogSheetDetail",
    GetLogSheetReport_By_LogsheetID: "KPCL/GetLogSheetReport_By_LogsheetID",
    GetDivisions_By_ProjectID: "KPCL/GetDivisions_By_ProjectID",
    Get_All_Divisions_By_ProjectID: "KPCL/Get_All_Divisions_By_ProjectID",
    GetUnittInfo: "KPCL/GetUnittInfo",
    Update_Questions: "KPCL/Update_Questions",
    Get_Questions_BySheetID: "KPCL/Get_Questions_BySheetID",
    GetLogSheetRecordBySheetID: "KPCL/GetLogSheetRecordBySheetID",
    RaiseDefect: "KPCL/RaiseDefect",
    GetPriorityTypeInfo: "KPCL/GetPriorityTypeInfo",
    GetDefectTypeInfo: "KPCL/GetDefectTypeInfo",
    GetAttendingDivInfo: "KPCL/GetAttendingDivInfo",
    GetChildAsset: "KPCL/GetChildAsset",
    GetChildAssetForWeb: "KPCL/GetChildAssetForWeb",
    GetDefectByEmpId: "KPCL/GetDefectByEmpId",
    GetReasons: "KPCL/GetReasons",
    GetFile_select: "KPCL/GetFile_select",
    GetDefectByEmpIdForWeb: "KPCL/GetDefectByEmpIdForWeb",
    EditDefects: "KPCL/EditDefects",
    Divert_Defect: "KPCL/Divert_Defect",
    RaiseMultiDivisionDefect: "KPCL/RaiseMultiDivisionDefect",
    PostSingleFile: "KPCL/PostSingleFile/PostSingleFile",
    Update_Defect_By_Acknowledge: "KPCL/Update_Defect_By_Acknowledge",
    AcknowledgeDefectUpdate_ForWeb: "KPCL/AcknowledgeDefectUpdate_ForWeb",
    Insert_AttendedInfo: "KPCL/Insert_AttendedInfo",
    GENERATE_PTW: "KPCL/GENERATE_PTW",
    GetAttendInfo: "KPCL/GetAttendInfo",
    Update_AMOH: "KPCL/Update_AMOH",
    Update_DefectStatus: "KPCL/Update_DefectStatus",
    Get_WorkTypes: "KPCL/Get_WorkTypes",
    PTW_ACKNOWLWDGE_FORWEB: "KPCL/PTW_ACKNOWLWDGE_FORWEB",
    INSERT_PTW_EHI_RECORDS: "KPCL/INSERT_PTW_EHI_RECORDS",
    IssuePTW: "KPCL/IssuePTW",
    RejectPTW: "KPCL/RejectPTW",
    Initiate_PTW: "KPCL/Initiate_PTW",
    GetEHI_DETAILS_BY_EHI_PTW_FORWEB: "KPCL/GetEHI_DETAILS_BY_EHI_PTW_FORWEB",
    Update_EHI_FORWEB: "KPCL/Update_EHI_FORWEB",
    PTW_TRANSFER: "KPCL/PTW_TRANSFER",
    Get_All_Divisions_By_ProjectID: "KPCL/Get_All_Divisions_By_ProjectID",
    GetDashBoardReport_ForWeb: "KPCL/GetDashBoardReport_ForWeb",
    GetPTWDashBoardReport_ForWeb: "KPCL/GetPTWDashBoardReport_ForWeb",
    Get_EmpLst_By_ProjectID_DivisionID: "KPCL/Get_EmpLst_By_ProjectID_DivisionID",
    GetDefectDetailsbyDefectId: "KPCL/GetDefectDetails",
    Accept_Reject_PTW_FORWEB: "KPCL/Accept_Reject_PTW_FORWEB",
    ReturnPTW: "KPCL/ReturnPTW",
    GetPTWStatus_ForWEB: "KPCL/GetPTWStatus_ForWEB",
    UploadImages: "/api/Filedoc/UploadImages",
    DownloadSingleFile: "KPCL/DownloadSingleFile",
    PTW_TRANSFER_APPROVAL_FORWEB: "KPCL/PTW_TRANSFER_APPROVAL_FORWEB",
    PTW_ISO_TEST_OPERATION_FORWEB: "KPCL/PTW_ISO_TEST_OPERATION_FORWEB",
    PTW_ISO_NORMALIZATION_OPERATION_FORWEB: "KPCL/PTW_ISO_NORMALIZATION_OPERATION_FORWEB",
    GET_NORMALIZATIONREQUEST_INFO_FORWEB: "KPCL/GET_NORMALIZATIONREQUEST_INFO_FORWEB",
    PTW_CANCEL_FORWEB: "KPCL/PTW_CANCEL_FORWEB",
    PTW_EHI_WORKCOMPLETED_FORWEB: "KPCL/PTW_EHI_WORKCOMPLETED_FORWEB",
    PTW_EHI_WORKPERMITED_FORWEB: "KPCL/PTW_EHI_WORKPERMITED_FORWEB",
    WorkDoneDetails_ForWeb: "KPCL/WorkDoneDetails_ForWeb",
    GetDefectWorkDoneDetails_ForWeb: "KPCL/GetDefectWorkDoneDetails_ForWeb",
    PTW_TRANSFER_ACCEPTANCE_FORWEB: "KPCL/PTW_TRANSFER_ACCEPTANCE_FORWEB",
    PTW_TRANSFER_REJECTED_FORWEB: "KPCL/PTW_TRANSFER_REJECTED_FORWEB",
    AttendDefectApproval_ForWeb: "KPCL/AttendDefectApproval_ForWeb",
    GET_PTW_ISO_VALUES_FORWEB: "KPCL/GET_PTW_ISO_VALUES_FORWEB",
    filesDownload: "/api/Filedoc/filesDownload",
    GetDefectInfoByDefectID_ForWeb: "KPCL/GetDefectInfoByDefectID_ForWeb",
    GET_PTW_DETAIL_BY_EMPID_FORWEB: "KPCL/GET_PTW_DETAIL_BY_EMPID_FORWEB",
    GET_PTW_DETAIL_BY_DEFECTID_PTWID_FORWEB: "KPCL/GET_PTW_DETAIL_BY_DEFECTID_PTWID_FORWEB"
  },
};

// ************************** setup Pages Endpoints ************************** //
export const setup = {
  MenuManagement: {
    getMenuList: "KPCL/MenuMasterSelect",
  },
  ProfileManagement: {
    getProfileList: "KPCL/GetUserProfile",
    GetUserProfileMenu: "KPCL/GetUserProfileMenu",
    UserProfileMenuAssign: "KPCL/UserProfileMenuAssign",
  },
  UserManagement: {
    getUser: "KPCL/GetUser"
  }
};
