import React from "react";
import { MemoryRouter as Router, Routes, Route } from "react-router-dom";
import PageAuthenticator from "Configration/PageAuthenticator/PageAuthenticator";
import Dashboard from "../../Components/Modules/Common/Dashboard/";
import UserManagement from "Components/Modules/Setup/UserManagement/UserManagement";
import ProfileManagement from "Components/Modules/Setup/ProfileManagement/ProfileManagement";
import MenuManagement from "Components/Modules/Setup/MenuManagement/MenuManagement";
import ProjectsList from "Components/Modules/Projects/ProjectsList/ProjectsList";
import Login from "Components/Modules/Common/Login/Login";
import Page from "./Page/Page";

function PageRouter() {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<PageAuthenticator />} />
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/welcome" element={<Page component="" title="Welcome to KBA" />} />
        <Route exact path="/dashboard" element={<Page component={<Dashboard />} title="Dashboard" />} />
        <Route exact path="/projects" element={<Page component={<ProjectsList />} title="Projects" />} />
        <Route exact path="/MenuManagement" element={<Page component={<MenuManagement />} title="Menu Management" />} />
        <Route exact path="/ProfileManagement" element={<Page component={<ProfileManagement />} title="Profile Management" />} />
        <Route exact path="/UserManagement" element={<Page component={<UserManagement />} title="User Management" />} />
        <Route path="*" element={<Page component={<Dashboard />} title="Dashboard" />} />
      </Routes>
    </Router>
  );
}

export default PageRouter;
