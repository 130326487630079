import React, { useState } from "react";
import { AlertMessage } from "Framework/Components/Widgets";
import { ImEye } from "react-icons/im";
import { IoMdCreate } from "react-icons/io";

import { getSessionStorage } from "Service/auth/auth";
import { project } from "Service/Endpoints/endpoints";
// import { apiCalling } from "Service/Utilities/apiCalling";
import GetApiData from "Configration/Utilities/getApiData/GetApiData";

/* ************************************* Code For Assets Grid Action Start ***************************** */

const cellTemplate = (params) => {
  const toggleAssetsDetailsViewPopup = () => {
    if (params) {
      params.toggleAssetDetailsView(params.data);
    }
  };
  const style = {
    display: "flex",
    fontSize: "16px",
    background: "transparent",
    border: "0px solid transparent",
    padding: "4px 0px 5px 0px",
    cursor: "pointer",
  };

  return (
    <div style={{ display: "flex", gap: "8px", justifyContent: "center" }}>
      <button
        type="button"
        style={style}
        title="View Detail"
        onClick={() => {
          toggleAssetsDetailsViewPopup();
        }}
      >
        <ImEye style={{ color: "rgb(52, 73, 94)" }} />
      </button>
    </div>
  );
};

const cellEditTemplate=(params)=>{
  const toggleAssetsDetailsEditPopup = () => {
    if (params) {
      params.toggleAssetDetailsEdit(params.data);
    }
  };
  const style = {
    display: "flex",
    fontSize: "16px",
    background: "transparent",
    border: "0px solid transparent",
    padding: "4px 0px 5px 0px",
    cursor: "pointer",
  };

  return (
    <div style={{ display: "flex", gap: "8px", justifyContent: "center" }}>
      <button
        type="button"
        style={style}
        title="View Detail"
        onClick={() => {
          toggleAssetsDetailsEditPopup();
        }}
      >
        <IoMdCreate style={{ color: "rgb(52, 73, 94)" }} />
      </button>
    </div>
  );
}

/* ************************************* Code For Assets  Grid Action cellTemplate  Start ***************************** */

function AssetsLogics() {
  const setAlertMessage = AlertMessage();
  const user = getSessionStorage("userData");
  const selectedProjectData = getSessionStorage("selctedProject");
  // const [selectedProjectData, setSelectedProjectData] = useState(null);

  // grid data State
  const [assetsListData, setAssetsListData] = useState([]);
  const [isLoadingAssetsData, setIsLoadingAssetsData] = useState(false);

  // asset details view state
  const [assetsDetailseViewData, setAssetDetailsViewData] = useState(null);
  const [isActiveAssetViewPopUp, setIsActiveAssetviewPopUp] = useState(false);

  const [assetsDetailsEditData, setAssetDetailsEditData]=useState(null);
  const [isActiveAssetEditPopUp, setIsActiveAssetEditPopUp]=useState(false);

  const [gridApi, setGridApi] = useState();
  const [searchText, setSearchText] = useState("");

  /* ************************************* Code For toggle popUp Start ***************************** */
  const toggleAssetDetailsView = (data) => {
    setIsActiveAssetviewPopUp(!isActiveAssetViewPopUp);
    if (data) {
      setAssetDetailsViewData(data);
    }
  };

  const toggleAssetDetailsEdit=(data)=>{
    setIsActiveAssetEditPopUp(!isActiveAssetEditPopUp);
    if (data) {
      setAssetDetailsEditData(data);
    }
  }
  /* ************************************* Code For toggle popUp end ***************************** */

  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  const onChangeSearchText = (val) => {
    setSearchText(val);
    gridApi.setQuickFilter(val);
    gridApi.refreshCells();
  };

  /* ************************************* Code For Api calling Start ***************************** */
  // getAssetListingData

  const cleareSerchText = (data) => {
    if (data) {
      if (searchText && searchText.toLowerCase().includes("#")) {
        onChangeSearchText("");
      }
    }
  };

  const getAssetListingData = () => {
    // setSelectedProjectData(ProjectData);
    const formData = {
      main: {
        divisionid: selectedProjectData && selectedProjectData.DIVISIONIDs ? selectedProjectData.DIVISIONIDs : "",
        profileid: user && user.UserProfileID ? user.UserProfileID : null,
        projectid: selectedProjectData && selectedProjectData.PROJECTID ? selectedProjectData.PROJECTID : "",
      },
    };
    const request = {
      setDatalist: setAssetsListData,
      setLoad: setIsLoadingAssetsData,
      requestData: formData,
      apiPath: project.projectList.GetKPCLAssetSelect,
      setAlert: setAlertMessage,
      fun: cleareSerchText,
    };
    console.log("assets Rewuest", request);
    console.log("setDatalist",setAssetsListData);
    console.log("setLoad",setIsLoadingAssetsData)
    GetApiData(request);
  };

  

  // const getAssetListingData = async (ProjectData) => {
  //   setSelectedProjectData(ProjectData);
  //   try {
  //     setIsLoadingAssetsData(true);
  //     const formData = {
  //       main: {
  //         divisionid:
  //           user && user.UserProfileID
  //             ? user.UserProfileID.toString() === "100301" || user.UserProfileID.toString() === "100302"
  //               ? ProjectData && ProjectData.DIVISIONIDs
  //                 ? ProjectData.DIVISIONIDs
  //                 : ""
  //               : ""
  //             : "",
  //         profileid: user && user.UserProfileID ? user.UserProfileID : null,
  //         projectid: ProjectData && ProjectData.PROJECTID ? ProjectData.PROJECTID : "",
  //       },
  //     };
  //     const result = await apiCalling(formData, project.projectList.GetKPCLAssetSelect);
  //     setIsLoadingAssetsData(false);
  //     console.log(result);
  //     if (result && result.responseCode === 1) {
  //       if (result && result.responseData && result.responseData.Table) {
  //         setAssetsListData(result.responseData.Table);
  //       }
  //     } else {
  //       setAlertMessage({
  //         type: "error",
  //         message: result.responseMessage,
  //       });
  //       setAssetsListData([]);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //     setAlertMessage({
  //       type: "error",
  //       message: error,
  //     });
  //     setAssetsListData([]);
  //   }
  // };
  /* ************************************* Code For Api calling end ***************************** */

  const [ListViewColumDef] = useState([
    { headerName: "Sr. No.", field: "#", width: 80, pinned: "left", valueGetter: "node.rowIndex + 1" },
    {
      field: "#",
      headerName: "Action",
      width: 80,
      cellRenderer: cellTemplate,
      cellRendererParams: {
        toggleAssetDetailsView,
      },
    },
    { field: "ASSETNAME", headerName: "Assets Name", width: 240 },
    { field: "ASSETCODE", headerName: "Assets Code", width: 180 },
    { field: "DIVISIONNAME", headerName: "Division Name", width: 200 },
    { field: "FUNCTIONALLOCATION", headerName: "Functional Locations", width: 240 },
    {field:"LOCATION", headerName:"Floor Location", width:180},
    { field: "PEQUIPMENTNUMBER", headerName: "Equipment Number", width: 180 },
    { field:"ASSETLOCALID", headerName:"Asset Local Id", width:180},
    { field:"MANUFACTURER", headerName:"Manufacturer", width:180},
    { field:"ASSET_STATUS", headerName:"Asset Status", width:180},
    
  ]);

  /* ************************************* Code For Assets Details Binding end ***************************** */

  return {
    isLoadingAssetsData,
    ListViewColumDef,
    assetsListData,
    getAssetListingData,
    selectedProjectData,
    toggleAssetDetailsView,
    assetsDetailseViewData,
    isActiveAssetViewPopUp,
    assetsDetailsEditData,
    isActiveAssetEditPopUp,
    toggleAssetDetailsEdit,
    searchText,
    onGridReady,
    onChangeSearchText,
  };
}

export default AssetsLogics;
