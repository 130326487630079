import React, { useEffect, useState } from "react";
import BizClass from "../Grievances/Grievances.module.scss"
import { DataGrid, PageBar } from "Framework/Components/Layout";
import { getSessionStorage } from "Service/auth/auth";
import PMlistLogic from "./Logic/PMlistLogic";
import { Loader } from "Framework/Components/Widgets";

function PMlist() {
  const { pmList,
    isLoadingpmList,
    pmListColumDef,
    toggleAddObservationPopUp,
    isShowAddGrievancesPopUp,
    getpmListing,
    onObservationGridReady,
    onChangeSearchText,
    searchText,
    pmlistContents,
    filterPmList

  } = PMlistLogic();
  const user = getSessionStorage("userData");
  console.log("user", user);

  const exportToCSV = () => {
    if (pmlistContents && pmlistContents.length !== 0) {
      const headers = Object.keys(pmlistContents[0]);

      const csvContent = "data:text/csv;charset=utf-8," +
        headers.join(",") + "\n" +
        pmlistContents.map(row => headers.map(header => row[header]).join(",")).join("\n");
      const encodedUri = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "PlannedMaintenanceList.csv");
      document.body.appendChild(link);
      link.click();
    }
    else {
      alert('No Data to Export!')
    }

  };
  //  var pmlistContents=[];

  //   useEffect(() => {
  //     const filterPmList = () => {
  //       // const pmlistData = pmList.filter((list) => {
  //       //   return list.ASSET_STATUS === 'MAINTENANCE';
  //       // });
  //       // console.log('pmlistData', pmlistData);
  //       // pmlistContents.push(pmlistData);
  //       console.log("pmList",pmList)
  //       pmList.forEach((list)=>{
  //         if(list.ASSET_STATUS==='MAINTENANCE'){
  //           pmlistContents.push(list)
  //         }
  //       })

  //     };
  //     console.log("pmlistContents",pmlistContents)

  //     filterPmList()


  //   },[]);

  useEffect(() => {
    filterPmList()

  }, [])



  return (
    <div className={BizClass.ObservationsGrid}>
      <PageBar className={BizClass.Span}>
        <div> <p>Planned Maintenance List</p></div>

        <div className={BizClass.pageContents}>
          <div style={{ margin: '7px' }}> <PageBar.Search
            onChange={(e) => onChangeSearchText(e.target.value)}
            value={searchText}
            onClick={() => getpmListing()}


          /></div>
          <PageBar.Button varient="primary" onClick={exportToCSV} style={{margin:'7px'}}>
            Export to CSV
          </PageBar.Button>


        </div>
      </PageBar>
      <DataGrid rowData={pmlistContents} columnDefs={pmListColumDef} loader={isLoadingpmList ? <Loader /> : null} onGridReady={onObservationGridReady} className={BizClass.ObservationsGridContainer} />



    </div>
  );
}

export default PMlist;
