import { OBSERVATION_APPROVAL, OBSERVATION_REJECT } from "Configration/Utilities/Constants/Constants";
import Modal from "Framework/Components/Layout/Modal/Modal";
import { Button, Loader } from "Framework/Components/Widgets";
import moment from "moment";
import React, { useEffect } from "react";
import BizClass from "../SkipApprovals.module.scss";
import AssignPopup from "./AssignPopup";

function SkipApprovalPopUp(props) {
  const {
    toggleSkipApprovalPopUp,
    PopUpData,
    toggleAssignPopUp,
    isActiveAssinPopUp,
    getDivisionDetailsList,
    isLoadingDivisionList,
    divisionList,
    formValues,
    updateFormState,
    employeeList,
    isLoadingEmployeeList,
    employeeAssetColumDef,
    onEmpAssetGridReady,
    SaveSelectadAssetsRowData,
    isLoadingReject,
    isLoadingApproval,
    AssetsGridList,
    isloadingAssetsGrid,
    GetObservationAssetSelect,
    clearFormValue,
    ObsevationReject,
    ObsevationApprove,
  } = props;

  useEffect(() => {
    GetObservationAssetSelect(PopUpData.OWNEREMPLOYEEID);
    console.log(PopUpData);
    clearFormValue();
  }, []);

  return (
    <>
      {isActiveAssinPopUp && (
        <AssignPopup
          toggleAssignPopUp={toggleAssignPopUp}
          getDivisionDetailsList={getDivisionDetailsList}
          isLoadingDivisionList={isLoadingDivisionList}
          divisionList={divisionList}
          updateFormState={updateFormState}
          formValues={formValues}
          employeeList={employeeList}
          isLoadingEmployeeList={isLoadingEmployeeList}
          employeeAssetColumDef={employeeAssetColumDef}
          onEmpAssetGridReady={onEmpAssetGridReady}
          SaveSelectadAssetsRowData={SaveSelectadAssetsRowData}
        />
      )}

      <Modal varient="center" title="Approval" show={toggleSkipApprovalPopUp} width="40vw" onlyfooter="true">
        <Modal.Body>
          <>
            {isloadingAssetsGrid && <Loader />}
            <div className={BizClass.popUpContant}>
              <p>
                <b>{PopUpData ? `${PopUpData.FROMEMPLOYEE} (${PopUpData.OWNEREMPLOYEEID}) skipped their observations for some reason` : ""}</b>
              </p>
              <div className={BizClass.StatusBox}>
                <p className={BizClass.Status}>{PopUpData.STATUS}</p>
              </div>
              <div>
                <span className={BizClass.AssetName}>Asset List</span>
                <div className={BizClass.AssetContant}>
                  {AssetsGridList && AssetsGridList.length > 0
                    ? AssetsGridList.map((asset, i) => {
                        return <p>{`${i + 1}. ${asset.ASSETNAME} (${asset.ASSETID})`}</p>;
                      })
                    : null}
                </div>
              </div>
              <div className={BizClass.FoterContaint}>
                <p>
                  Reason : <span>{PopUpData.REASON ? PopUpData.REASON : ""}</span>
                </p>
                <p>
                  Date : <span>{PopUpData ? moment(PopUpData.SCHEDULEDATE).format("DD-MMM-YYYY") : ""}</span>
                </p>
                {formValues && formValues.txtEmployee && formValues.txtEmployee.length > 0 ? (
                  <p>
                    To Employee :<span>{formValues.txtEmployee[0].EMPLOYEENAME}</span>
                  </p>
                ) : null}
              </div>
            </div>
          </>
        </Modal.Body>
        <Modal.Footer>
          <Button type="button" varient="danger" trigger={isLoadingReject ? "true" : ""} onClick={() => ObsevationReject(OBSERVATION_REJECT, PopUpData)}>
            Reject
          </Button>
          <Button
            type="button"
            varient=""
            className={BizClass.AssignBtn}
            onClick={() => {
              toggleAssignPopUp();
            }}
          >
            Assign
          </Button>

          <Button
            type="button"
            varient="grey"
            onClick={() => {
              toggleSkipApprovalPopUp();
            }}
          >
            Cancel
          </Button>

          <Button
            type="button"
            varient="secondary"
            onClick={() => ObsevationApprove(OBSERVATION_APPROVAL, PopUpData)}
            trigger={isLoadingApproval ? "true" : ""}
          >
            Approve
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default SkipApprovalPopUp;
