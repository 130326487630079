import Form from "Framework/Components/Layout/FormGroup/FormGroup";
import Modal from "Framework/Components/Layout/Modal/Modal";
import { Button } from "Framework/Components/Widgets";
import moment from "moment";
import React from "react";
import BizClass from "./AddSchedule.module.scss";

function AddSchedulePopUp(props) {
  const {
    scheduleFormValues,
    updateScheduleState,
    toggleAddSchedual,
    saveAddSchedule,
    scheduleFormValidationError,
    listOfMonth,
    listOfWeekDay,
    listofMonthlyWeek,
    assetEmployee,
  } = props;
  console.log(moment().add(1, "days"));
  return (
    <Modal varient="center" index={2} show={toggleAddSchedual} title="Set Recurrence" width="754px" height="400px">
      <Modal.Body>
        <Form>
          <Form.Group>
            <Form.CustomGroup column={2} columntemplate="repeat(6, max-content)">
              <Form.InputGroup label="Daily:">
                <Form.InputControl
                  name="txtDaily"
                  control="radio"
                  onChange={(e) => {
                    updateScheduleState(e.target.checked, e.target.name);
                  }}
                  checked={scheduleFormValues.txtDaily}
                />
              </Form.InputGroup>
              <Form.InputGroup label="Weekly:">
                <Form.InputControl
                  name="txtWeekly"
                  control="radio"
                  onChange={(e) => {
                    updateScheduleState(e.target.checked, e.target.name);
                  }}
                  checked={scheduleFormValues.txtWeekly}
                />
              </Form.InputGroup>
              <Form.InputGroup label="Monthly: ">
                <Form.InputControl
                  name="txtMonthly"
                  control="radio"
                  onChange={(e) => {
                    updateScheduleState(e.target.checked, e.target.name);
                  }}
                  checked={scheduleFormValues.txtMonthly}
                />
              </Form.InputGroup>
            </Form.CustomGroup>
            {scheduleFormValues && scheduleFormValues.txtDaily ? (
              <Form.CustomGroup column={2} columntemplate="repeat(6, max-content)">
                <Form.InputGroup label="Repeat Every" errorMsg={scheduleFormValidationError["txtDayilyRepeatEvery"]}>
                  <Form.InputControl
                    name="txtDayilyRepeatEvery"
                    control="input"
                    onChange={(e) => {
                      updateScheduleState(e.target.value, e.target.name);
                    }}
                    value={scheduleFormValues.txtDayilyRepeatEvery}
                  />
                </Form.InputGroup>
                <Form.InputGroup label="days at" errorMsg={scheduleFormValidationError["txtDayilyTime"]}>
                  <Form.InputControl
                    control="input"
                    type="Time"
                    name="txtDayilyTime"
                    onChange={(e) => {
                      updateScheduleState(e.target.value, e.target.name);
                    }}
                    value={scheduleFormValues.txtDayilyTime}
                    disabled={assetEmployee.length > 1}
                  />
                </Form.InputGroup>
              </Form.CustomGroup>
            ) : null}
            {scheduleFormValues && scheduleFormValues.txtWeekly ? (
              <Form.CustomGroup column={2} columntemplate="repeat(6, max-content)">
                <Form.InputGroup label="Repeat every" errorMsg={scheduleFormValidationError["txtWeeklyRepeatEvery"]}>
                  <Form.InputControl
                    control="input"
                    type="text"
                    name="txtWeeklyRepeatEvery"
                    onChange={(e) => {
                      updateScheduleState(e.target.value, e.target.name);
                    }}
                    value={scheduleFormValues.txtWeeklyRepeatEvery}
                  />
                </Form.InputGroup>
                <Form.InputGroup label="Week at" errorMsg={scheduleFormValidationError["txtWeeklyTime"]}>
                  <Form.InputControl
                    control="input"
                    type="Time"
                    name="txtWeeklyTime"
                    onChange={(e) => {
                      updateScheduleState(e.target.value, e.target.name);
                    }}
                    value={scheduleFormValues.txtWeeklyTime}
                    disabled={assetEmployee.length > 1}
                  />
                </Form.InputGroup>
                <br />
                <Form.InputGroup label="Monday">
                  <Form.InputControl
                    name="Monday"
                    control="checkbox"
                    onChange={() => {
                      updateScheduleState(1, "txtweeklyDay");
                    }}
                    checked={scheduleFormValues.txtweeklyDay === 1}
                  />
                </Form.InputGroup>
                <Form.InputGroup label="Tuesday">
                  <Form.InputControl
                    name="Tuesday"
                    control="checkbox"
                    onChange={() => {
                      updateScheduleState(2, "txtweeklyDay");
                    }}
                    checked={scheduleFormValues.txtweeklyDay === 2}
                  />
                </Form.InputGroup>
                <Form.InputGroup label="Wednesday">
                  <Form.InputControl
                    name="Wednesday"
                    control="checkbox"
                    onChange={() => {
                      updateScheduleState(3, "txtweeklyDay");
                    }}
                    checked={scheduleFormValues.txtweeklyDay === 3}
                  />
                </Form.InputGroup>
                <Form.InputGroup label="Thursday">
                  <Form.InputControl
                    name="Thursday"
                    control="checkbox"
                    onChange={() => {
                      updateScheduleState(4, "txtweeklyDay");
                    }}
                    checked={scheduleFormValues.txtweeklyDay === 4}
                  />
                </Form.InputGroup>
                <Form.InputGroup label="Friday">
                  <Form.InputControl
                    name="Friday"
                    control="checkbox"
                    onChange={() => {
                      updateScheduleState(5, "txtweeklyDay");
                    }}
                    checked={scheduleFormValues.txtweeklyDay === 5}
                  />
                </Form.InputGroup>
                <Form.InputGroup label="Saturday">
                  <Form.InputControl
                    name="Saturday"
                    control="checkbox"
                    onChange={() => {
                      updateScheduleState(6, "txtweeklyDay");
                    }}
                    checked={scheduleFormValues.txtweeklyDay === 6}
                  />
                </Form.InputGroup>
                <Form.InputGroup label="Sunday">
                  <Form.InputControl
                    name="Sunday"
                    control="checkbox"
                    onChange={() => {
                      updateScheduleState(7, "txtweeklyDay");
                    }}
                    checked={scheduleFormValues.txtweeklyDay === 7}
                  />
                </Form.InputGroup>
              </Form.CustomGroup>
            ) : null}

            {scheduleFormValues && scheduleFormValues.txtMonthly ? (
              <span>
                Repeate every
                <span className={BizClass.RepeatEvery}>
                  <Form.InputGroup req={true} errorMsg={scheduleFormValidationError["txtMonthlyNumericRepeatEveryMonth"]}>
                    <Form.InputControl
                      name="txtMonthlyNumericRepeatEveryMonth"
                      control="select"
                      options={listOfMonth}
                      onChange={(e) => {
                        updateScheduleState(e, "txtMonthlyNumericRepeatEveryMonth");
                      }}
                      value={scheduleFormValues.txtMonthlyNumericRepeatEveryMonth}
                    />
                  </Form.InputGroup>
                </span>
                month in
                <span className={BizClass.MonthlyDay}>
                  <Form.InputGroup req={true} errorMsg={scheduleFormValidationError["txtMonthlyNumericMonthWeek"]}>
                    <Form.InputControl
                      name="txtMonthlyNumericMonthWeek"
                      control="select"
                      options={listofMonthlyWeek}
                      onChange={(e) => {
                        updateScheduleState(e, "txtMonthlyNumericMonthWeek");
                      }}
                      value={scheduleFormValues.txtMonthlyNumericMonthWeek}
                    />
                  </Form.InputGroup>
                </span>
                week on
                <span className={BizClass.MonthlyDay}>
                  <Form.InputGroup req={true} errorMsg={scheduleFormValidationError["txtMonthlyNumericWeekDay"]}>
                    <Form.InputControl
                      name="txtMonthlyNumericWeekDay"
                      control="select"
                      options={listOfWeekDay}
                      onChange={(e) => {
                        updateScheduleState(e, "txtMonthlyNumericWeekDay");
                      }}
                      value={scheduleFormValues.txtMonthlyNumericWeekDay}
                    />
                  </Form.InputGroup>
                </span>
                <span className={BizClass.Time}>
                  at
                  <Form.InputGroup errorMsg={scheduleFormValidationError["txtMonthlyNumericTime"]}>
                    <Form.InputControl
                      control="input"
                      type="Time"
                      name="txtMonthlyNumericTime"
                      onChange={(e) => {
                        updateScheduleState(e.target.value, e.target.name);
                      }}
                      value={scheduleFormValues.txtMonthlyNumericTime}
                      disabled={assetEmployee.length > 1}
                    />
                  </Form.InputGroup>
                </span>
              </span>
            ) : null}
          </Form.Group>
        </Form>
        <Form>
          <hr />
          <Form.Group column={2}>
            <Form.InputGroup label="Start On :" column={1} req="true" errorMsg={scheduleFormValidationError["txtStartOn"]}>
              <Form.InputControl
                name="txtStartOn"
                control="input"
                type="Date"
                min={moment().add(1, "days").format("YYYY-MM-DD")}
                onChange={(e) => {
                  updateScheduleState(e.target.value, e.target.name);
                }}
                value={scheduleFormValues.txtStartOn}
              />
            </Form.InputGroup>
            <Form.InputGroup label="Until :" column={1}>
              <Form.InputControl
                name="txtUntil"
                control="input"
                type="Date"
                min={
                  scheduleFormValues.txtStartOn
                    ? moment(scheduleFormValues.txtStartOn).add(1, "days").format("YYYY-MM-DD")
                    : moment().add(2, "days").format("YYYY-MM-DD")
                }
                onChange={(e) => {
                  updateScheduleState(e.target.value, e.target.name);
                }}
                value={scheduleFormValues.txtUntil}
              />
            </Form.InputGroup>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button varient="secondary" onClick={() => saveAddSchedule(toggleAddSchedual)}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default AddSchedulePopUp;
