import React, { useState, useEffect, useRef } from "react";
import { DataGrid, PageBar } from "Framework/Components/Layout";
import { Loader } from "Framework/Components/Widgets";
import Bizclass from "../LogSheetReport/LogSheetReport.module.scss";
import { Form, FormGroup } from "react-bootstrap";
import Modal from "Framework/Components/Layout/Modal/Modal";
import axios from "../../../../Configration/axios/axios";
import { project } from "Service/Endpoints/endpoints";
import { getSessionStorage } from "Service/auth/auth";
import { MdArrowBack } from "react-icons/md";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import * as XLSX from "xlsx";

function LogSheetReport() {
  const [selectedFromDate, setSelectedFromDate] = useState("");
  const [selectedToDate, setSelectedToDate] = useState(getTodayDate());
  const [logSheets, setLogSheets] = useState([]);
  const [logSheetData, setLogSheetData] = useState([]);
  const [selectedLogSheet, setSelectedLogSheet] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [hour, setHour] = useState([]);
  const [Dropdown, setdropdown] = useState([]);
  const [sheetContents, setSheetContents] = useState([]);
  const [selectedHourIndex, setSelectedHourIndex] = useState(null);
  const uniqueQuestions = new Set();
  const uniqueOptions = new Set();
  const [tableData, setTableData] = useState([]);
  const [unitInfo, setUnitInfo] = useState([]);
  const [selectedLogSheetName, setSelectedLogSheetName] = useState("");

  const designationOptions = [
    {
      value: 1,
      lable: "JE",
    },
    {
      value: 2,
      lable: "AE",
    },
    {
      value: 3,
      lable: "AEE",
    },
    {
      value: 4,
      lable: "EE",
    },
    {
      value: 5,
      lable: "SE",
    },
    {
      value: 6,
      lable: "CE",
    },
    {
      value: 7,
      lable: "ED",
    },
    {
      value: 8,
      lable: "TD",
    },
    {
      value: 9,
      lable: "MD",
    },
    {
      value: 10,
      lable: "DHR",
    },
  ];
  const selectedProjectData = getSessionStorage("selctedProject");
  console.log("selectedProjectData", selectedProjectData);
  const [unit, setUnit] = useState({
    unitID: "",
    unitName: "",
  });
  const [designation, setDesignation] = useState("");
  const [logSheetDetailFetched, setLogSheetDetailFetched] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const contentRef = useRef();

  useEffect(() => {
    const unitInfo = async () => {
      try {
        const formData = {
          projectID: selectedProjectData.PROJECTID,
        };
        const response = await axios.post(`${project.projectList.GetUnittInfo}`, formData);
        console.log("Unit response", response.data.responseDynamic);
        setUnitInfo(response.data.responseDynamic);
      } catch (error) {
        console.error("Error fetching unit information:", error);
      }
    };
    unitInfo();
    return () => {};
  }, [selectedProjectData.PROJECTID]);
  const showDetails = (sheetName) => {
    if (selectedFromDate !== "") {
      const allSheets = [].concat(...sheetContents);
      const details = allSheets.find((logSheet) => logSheet.log_sheet_name === sheetName);
      if (details) {
        const questions = details.questions;
        console.log("questions", questions);
        setIsModalOpen(true);
        setModalTitle(sheetName);
      }
    } else {
      alert("Please select From Date first.");
    }
  };

  useEffect(() => {
    console.log("questions", sheetContents);
    sheetContents.map((question) => {
      console.log("questionText", question.questionText);
    });
  }, [sheetContents]);

  console.log("sheetContents", sheetContents);
  const handleUnitChange = (event) => {
    const selectedOption = event.target.selectedOptions[0];
    const unitID = selectedOption.getAttribute("data-unit-id");
    const unitName = selectedOption.getAttribute("data-unit-name");

    setUnit({
      unitID: unitID,
      unitName: unitName,
    });
  };

  const handleDesignationChange = (event) => {
    setDesignation(event.target.value);
  };

  useEffect(() => {
    if (!logSheetDetailFetched && unit.length !== 0 && designation.length !== 0) {
      const formData = {
        divisionID: 0,
        unitID: parseInt(unit.unitID, 10),
        projectID: parseInt(selectedProjectData.PROJECTID, 10),
        employeeLevel: parseInt(designation, 10),
      };

      console.log("formData", formData);

      axios
        .post(`${project.projectList.GetLogSheetDetail}`, formData)
        .then((response) => {
          console.log("response", response.data);
          const dynamicData = JSON.parse(response.data.responseDynamic);
          setTableData(dynamicData.Table);
          setLogSheetDetailFetched(true);
        })
        .catch((error) => {
          console.error("Error", error);
        });
    } else {
      console.error("Invalid unit or designation");
    }
  }, [unit, designation, selectedProjectData]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const fetchLogSheets = async (logsheetId, fromDate, toDate) => {
    try {
      const formData = {
        sheetID: logsheetId,
        fromDate: formatDate(fromDate),
        toDate: formatDate(toDate),
      };

      const response = await axios.post(`${project.projectList.GetLogSheetReport_By_LogsheetID}`, formData);

      if (response.status !== 200) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const jsonData = response.data.responseDynamic;
      const parsedData = JSON.parse(jsonData);

      if (parsedData !== null) {
        const jsonLogs = parsedData.Table.map((item) => item.JSONLOG);
        const contents = jsonLogs.map((jsonString) => JSON.parse(jsonString));
        setSheetContents(contents);

        const sheetName = parsedData.Table.map((item) => item.LOGSHEET_NAME);
        const hourId = parsedData.Table.map((item) => item.HRID);

        setHour(hourId);
        setdropdown(sheetName);
      } else {
        console.error("No data available in the response:", parsedData);
      }
    } catch (error) {
      console.error("Error fetching log sheets:", error);
      throw error;
    }
  };

  const closeEditModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      const logSheetsData = {
        sheetId: 82,
      };

      try {
        const response = await axios.post(`${project.projectList.GetLogSheetDetailBySheetID}`, logSheetsData);

        const data = response.data.responseDynamic;
        const jsonData = JSON.parse(data);
        const readingsJson = JSON.parse(jsonData.Table[0].LOG_JSON);

        console.log(readingsJson);
        setLogSheetData(readingsJson);
      } catch (error) {
        console.error("Error fetching log sheet data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (selectedFromDate && selectedToDate && selectedLogSheet) {
      fetchLogSheets(selectedLogSheet, selectedFromDate, selectedToDate)
        .then((data) => {
          setLogSheets(data);
        })
        .catch((error) => {
          console.error("Error fetching log sheets:", error);
        });
    }
  }, [selectedLogSheet, selectedFromDate, selectedToDate]);

  const handleFromDateChange = (event) => {
    setSelectedFromDate(event.target.value);
    fetchLogSheets(selectedLogSheet, selectedToDate, event.target.value)
      .then((data) => {
        setLogSheets(data);
      })
      .catch((error) => {
        console.error("Error fetching log sheets:", error);
      });
  };

  const handleToDateChange = (event) => {
    setSelectedToDate(event.target.value);
    fetchLogSheets(selectedLogSheet, event.target.value, selectedFromDate)
      .then((data) => {
        setLogSheets(data);
      })
      .catch((error) => {
        console.error("Error fetching log sheets:", error);
      });
  };

  const handleLogSheetChange = (event) => {
    const selectedOption = event.target.selectedOptions[0];
    const unitID = selectedOption.getAttribute("data-sheet-id");
    const unitName = selectedOption.getAttribute("data-sheet-name");
    fetchLogSheets(unitID, selectedFromDate, selectedToDate);
    setSelectedLogSheet(unitID);
    setSelectedLogSheetName(unitName);
    if (selectedFromDate !== "") {
      setIsModalOpen(!!unitID);
    }
    if (selectedFromDate === "") {
      setSelectedLogSheet("");
    }
  };

  const handleRefresh = () => {
    fetchLogSheets(selectedLogSheet, selectedFromDate, selectedToDate)
      .then((data) => {
        setLogSheets(data);
      })
      .catch((error) => {
        console.error("Error fetching log sheets:", error);
      });
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const showModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const generateTableHeaders = (question, uniqueHeaders) => {
    const headers = [];

    if (!uniqueHeaders.has(question.question_text)) {
      headers.push(
        <th key={`${question.question_id}_question`} scope="col" rowSpan="2">
          {question.question_text}
        </th>,
      );
      uniqueHeaders.add(question.question_text);
    }

    if (question.options && question.options.length > 0) {
      question.options.forEach((option, index) => {
        const key = `${question.question_id}_${option.option_id}`;
        headers.push(
          <th key={key} scope="col">
            {option.option_text}
          </th>,
        );
      });
    }

    return headers;
  };

  // const exportToCSV = (event) => {
  //     event.preventDefault();
  //     const table = document.getElementById('wrapper');
  //     if (!table) {
  //         console.error('Table not found!');
  //         return;
  //     }
  //     console.log('Table:', table);
  //     let csvContent = 'data:text/csv;charset=utf-8,';
  //     for (const row of table.rows) {
  //         const rowData = [];
  //         for (const cell of row.cells) {
  //             rowData.push(cell.textContent.trim());
  //         }
  //         csvContent += rowData.join(',') + '\n';
  //     }
  //     console.log('CSV Content:', csvContent);
  //     const encodedUri = encodeURI(csvContent);
  //     const link = document.createElement('a');
  //     link.setAttribute('href', encodedUri);
  //     link.setAttribute('download', 'table_data.csv');
  //     document.body.appendChild(link);
  //     link.click();
  //     document.body.removeChild(link);
  // };

  // const exportToCSV = (event) => {
  //     event.preventDefault();
  //     var tableElement = document.getElementById("wrapper");
  //     console.log("tableElement", tableElement);
  //     var sourceData = "data:text/csv;charset=utf-8,";
  //     var i = 0;
  //     while (row = tableElement.rows[i]) {
  //         sourceData += ([
  //             row.cells[0].innerText,
  //             row.cells[1].innerText,
  //             row.cells[2].innerText,
  //             row.cells[3].innerText
  //         ]).join(",") + "\r\n";
  //         i++;
  //     }
  //     window.location.href = encodeURI(sourceData);
  // }

  const generateTableRow = (question) => {
    const row = [];

    if (question.options && question.options.length > 0) {
      question.options.forEach((option, index) => {
        const key = `${question.question_id}_${option.option_id}`;
        row.push(<td key={key}>{`${option.min_value} / ${option.max_value}`}</td>);
      });
    }

    return row;
  };

  const handlePrint = () => {
    event.preventDefault();
    const printWindow = window.open("", "", "height=600,width=800");
    const printTable = document.getElementById("wrapper").outerHTML;
    printWindow.document.open();
    printWindow.document.write(`
      <html>
        <head>
          <title>Print Table</title>
          <style>
            @media print {
              .print-hide { display: none; }
              .print-table { width: 100%; border-collapse: collapse; }
              .print-table th, .print-table td {
                border: 1px solid #000;
                padding: 8px;
                text-align: left;
              }
            }
          </style>
        </head>
        <body>
          ${printTable}
        </body>
      </html>
    `);
    printWindow.document.close();
    printWindow.focus();
    printWindow.print();
  };

  const uniqueHeaders = new Set();
  const uniqueLogSheets = Array.from(new Set(Dropdown));
  const uniqueHours = Array.from(new Set(hour));

  let tableHeaders = [];
  logSheetData.forEach((question) => {
    const headers = generateTableHeaders(question, uniqueHeaders);
    tableHeaders = tableHeaders.concat(headers);
  });

  const tableRows = logSheetData.map((question) => generateTableRow(question));
  const { questions } = sheetContents;

  const today = new Date();
  const dayNames = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
  const day = dayNames[today.getDay()];

  // Format date as "dd-MM-yyyy"
  const formattedDate = `${today.getDate()}-${today.getMonth() + 1}-${today.getFullYear()}`;

  const title = `KARNATAKA POWER CORPORATION LIMITED. RAICHUR THERMAL POWER STATION.`;

  const exportToExcel = (event) => {
    event.preventDefault();
    const workbook = XLSX.utils.book_new();
    
    // Get the table element
    const table = document.getElementById("wrapper");

    // Extract headers and sub-headers
    const headers = [];
    const subHeaders = [];
    let maxCols = 0;

    // Extract headers and sub-headers
    table.querySelectorAll("thead tr").forEach((tr, rowIndex) => {
        const headerRow = [];
        const subHeaderRow = [];
        let headerColIndex = 0;

        tr.querySelectorAll("th").forEach(th => {
            const text = th.innerText.trim();
            const colspan = parseInt(th.getAttribute("colspan")) || 1;
            if (rowIndex === 0) {
                // For the first row, add main headers
                for (let i = 0; i < colspan; i++) {
                    headerRow[headerColIndex++] = text;
                }
            } else {
                // For other rows, add sub-headers
                for (let i = 0; i < colspan; i++) {
                    subHeaderRow[headerColIndex++] = text;
                }
            }
        });

        if (headerRow.length > 0) {
            headers.push(headerRow);
        }
        if (subHeaderRow.length > 0) {
            subHeaders.push(subHeaderRow);
        }

        // Track maximum columns for row length correction
        maxCols = Math.max(maxCols, headerRow.length);
    });

    // Flatten headers and sub-headers into a single row if multiple header rows are present
    const flatHeaders = [];
    const flatSubHeaders = [];

    for (let i = 0; i < maxCols; i++) {
        const mainHeader = headers.map(row => row[i] || '').join(' ');
        const subHeader = subHeaders.map(row => row[i] || '').join(' ');
        flatHeaders.push(mainHeader);
        flatSubHeaders.push(subHeader);
    }

    // Extract rows
    const rows = [];
    table.querySelectorAll("tbody tr").forEach(tr => {
        const row = [];
        let colIndex = 0;

        tr.querySelectorAll("td").forEach(td => {
            const colspan = parseInt(td.getAttribute("colspan")) || 1;
            const input = td.querySelector("input");
            const cellValue = input ? input.value.trim() : td.innerText.trim();
            
            for (let i = 0; i < colspan; i++) {
                row[colIndex++] = cellValue;
            }
        });

        // Fill empty cells if needed
        while (row.length < maxCols) {
            row.push("");
        }

        rows.push(row);
    });

    // Ensure all rows have the same length as the headers
    const correctedRows = rows.map(row => {
        while (row.length < maxCols) {
            row.push(""); // Add empty cells if necessary
        }
        return row;
    });

    // Create worksheet
    const wsData = [flatHeaders, flatSubHeaders, ...correctedRows];
    const worksheet = XLSX.utils.aoa_to_sheet(wsData);

    // Add worksheet to workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, "Logsheet");

    // Generate Excel file and download it
    XLSX.writeFile(workbook, "Logsheet.xlsx");
};



  return (
    <>
      <div className={Bizclass.PageStart}>
        <div className={Bizclass.Card}>
          <PageBar className={Bizclass.CustomPageBar}>
            <span>LogSheet Report</span>
            <PageBar.Button onClick={handleRefresh}>Refresh</PageBar.Button>
          </PageBar>

          <div className="container">
            <div className="container">
              <FormGroup>
                <Form.Label htmlFor="unit" className="py-2" required>
                  Unit ID:
                </Form.Label>
                {/* <select id="unit" name="unit" className="form-control" onChange={handleUnitChange}>
                  <option value="">Select Unit ID</option>
                  {unitInfo.map((type) => (
                    <option key={type.unitID} value={type.unitID}>
                      {type.unitName}
                    </option>
                  ))}
                </select> */}
                <select id="unit" name="unit" className="form-control" onChange={handleUnitChange}>
                  <option value="">Select Unit ID</option>
                  {unitInfo.map((type) => (
                    <option key={type.unitID} value={type.unitID} data-unit-id={type.unitID} data-unit-name={type.unitName}>
                      {type.unitName}
                    </option>
                  ))}
                </select>
              </FormGroup>

              <FormGroup>
                <Form.Label htmlFor="logSheetName" className="py-2" required>
                  Designation:
                </Form.Label>
                <select id="designation" name="designation" className="form-control" onChange={handleDesignationChange}>
                  <option value="">Select Designation ID</option>
                  {designationOptions.map((type) => (
                    <option key={type.value} value={type.value}>
                      {type.lable}
                    </option>
                  ))}
                </select>
              </FormGroup>
              <div className="d-flex my-3">
                <div className={Bizclass.Input_Group_Fild}>
                  <span>From Date :</span>
                  <PageBar.Input type="date" name="txtFromDate" value={selectedFromDate} onChange={handleFromDateChange} />
                </div>
                <div className={Bizclass.Input_Group_Fild}>
                  <span>To Date :</span>
                  <PageBar.Input type="date" name="txtToDate" value={selectedToDate} onChange={handleToDateChange} />
                </div>
              </div>
              <FormGroup>
                <Form.Label htmlFor="unit" className="py-2" required>
                  LogSheet
                </Form.Label>
                <select
                  id="logsheetName"
                  name="logsheetName"
                  className="form-control"
                  onChange={(event) => {
                    handleLogSheetChange(event);
                    showDetails(event.target.value);
                  }}
                  value={selectedLogSheet}
                >
                  <option value="">Select LogSheet Name</option>
                  {tableData.map((type) => (
                    <option key={type.LOG_SHEET_ID} data-sheet-id={type.LOG_SHEET_ID} data-sheet-name={type.LOG_SHEET_NAME}>
                      {type.LOG_SHEET_NAME}
                    </option>
                  ))}
                </select>
              </FormGroup>
            </div>
          </div>

          {isModalOpen && (
            <Modal varient="center" title={modalTitle} index={2} show={closeEditModal} width="100%" height="100%">
              <Modal.Header>
                <div className="d-flex justify-content-between">
                  <div>
                    <MdArrowBack />
                    <a onClick={closeEditModal} style={{ cursor: "pointer" }}>
                      Back
                    </a>
                  </div>
                </div>
              </Modal.Header>
              <Modal.Body>
                <div id="wrapper">
                  <div className="project-title-container py-3 px-3" >
                    <div className="d-flex justify-content-between">
                      <div className="d-flex justify-content-start font-weight-bold" style={{ fontWeight: "bold" }}>
                        {title}
                      </div>
                      <div style={{ fontWeight: "bold" }}>Date: {formattedDate}</div>
                    </div>
                    <div className="d-flex justify-content-between">
                      <div className="project-title" style={{ fontWeight: "bold" }}>
                        Unit No: {unit.unitName}
                      </div>
                      <div className="project-title" style={{ fontWeight: "bold" }}>
                        {selectedLogSheetName}
                      </div>
                      <div className="date-day" style={{ fontWeight: "bold" }}>
                        Day: {day}
                      </div>
                    </div>
                  </div>
                  <table className="table table-bordered table-responsive-xl" >
                    <thead>
                      <tr>
                        <th style={{ width: "30px" }}>Time in Hours</th>
                        {sheetContents.map((question, questionIndex) => (
                          <React.Fragment key={questionIndex}>
                            {question.map((ques, quesIndex) => {
                              if (!uniqueQuestions.has(ques.questionText)) {
                                uniqueQuestions.add(ques.questionText);
                                return (
                                  <th key={quesIndex} colSpan={ques.options.length} style={{ width: "50px" }}>
                                    {ques.questionText}
                                  </th>
                                );
                              }
                              return null;
                            })}
                          </React.Fragment>
                        ))}
                      </tr>

                      {sheetContents.map((question, questionIndex) => (
                        <React.Fragment key={questionIndex}>
                          <tr>
                            {questionIndex === 0 && question.some((option) => option.subOptions) && (
                              <>
                                <td style={{ width: "30px" }}></td>
                                {question.map((option, optionIndex) => (
                                  <React.Fragment key={optionIndex}>
                                    {option.subOptions &&
                                      option.subOptions.map((subOption, subOptionIndex) => (
                                        <td key={subOptionIndex} style={{ width: "30px" }}>
                                          {subOption.suboptionText}
                                        </td>
                                      ))}
                                  </React.Fragment>
                                ))}
                              </>
                            )}
                          </tr>

                          <tr>
                            {questionIndex === 0 && (
                              <>
                                <td style={{ width: "30px" }}></td>
                                {question.map((ques, quesIndex) => (
                                  <React.Fragment key={quesIndex}>
                                    {ques.options.map((option, optionIndex) => (
                                      <td key={optionIndex} style={{ width: "30px" }}>
                                        {option.optionText}
                                      </td>
                                    ))}
                                  </React.Fragment>
                                ))}
                              </>
                            )}
                          </tr>

                          <tr>
                            {questionIndex === 0 && (
                              <>
                                <td style={{ width: "30px" }}></td>
                                {question.map((option, optionIndex) => (
                                  <React.Fragment key={optionIndex}>
                                    {option.options.map((subOption, subOptionIndex) => (
                                      <td key={subOptionIndex} style={{ width: "30px" }}>
                                        <span style={{ fontWeight: "bold" }}>Min:</span>
                                        {subOption.minValue} <br /> <span style={{ fontWeight: "bold" }}>Max:</span>
                                        {subOption.maxValue}
                                      </td>
                                    ))}
                                  </React.Fragment>
                                ))}
                              </>
                            )}
                          </tr>
                        </React.Fragment>
                      ))}
                    </thead>
                    <tbody>
                      {/* {hour &&
                        sheetContents.map((question, questionIndex) => (
                          <tr key={questionIndex}>
                            <td style={{ width: "30px" }}>{hour[questionIndex]}</td>
                            {question.map((option, optionIndex) => (
                              <React.Fragment key={optionIndex}>
                                {option.options.map((subOption, subOptionIndex) =>
                                  subOption.subOptions.map((subvalue, subValueIndex) => {
                                    // Check if subvalue.suboptionValue is greater than the max value
                                    const isValueExceeded = parseFloat(subvalue.suboptionValue) > parseFloat(subOption.maxValue);
                                    return (
                                      <td
                                        key={`${optionIndex}_${subOptionIndex}_${subValueIndex}`}
                                        style={{
                                          width: "30px",
                                          border: isValueExceeded ? "2px solid red" : "1px solid #dee2e6", // Set border color to red if value exceeds max value
                                        }}
                                      >
                                        <input
                                          type="text"
                                          name={`input_${questionIndex}_${optionIndex}_${subOptionIndex}_${subValueIndex}`}
                                          style={{
                                            border: "none",
                                            width: "100%",
                                            color: isValueExceeded ? "red" : "inherit", // Set text color to red if value exceeds max value
                                          }}
                                          value={option.optionValue}
                                          readOnly
                                        />
                                      </td>
                                    );
                                  }),
                                )}
                              </React.Fragment>
                            ))}
                          </tr>
                        ))} */}

{hour &&
      sheetContents.map((question, questionIndex) => (
        <tr key={questionIndex}>
          <td style={{ width: "30px" }}>{hour[questionIndex]}</td>
          {question.map((option, optionIndex) => (
            <React.Fragment key={optionIndex}>
              {option.options.map((subOption, subOptionIndex) => {
                const isValueExceeded = parseFloat(subOption.subOptions[0].suboptionValue) > parseFloat(subOption.maxValue);
                return (
                  <td
                    key={`${optionIndex}_${subOptionIndex}`}
                    style={{
                      width: "30px",
                      border: isValueExceeded ? "2px solid red" : "1px solid #dee2e6", // Set border color to red if value exceeds max value
                    }}
                  >
                    <input
                      type="text"
                      name={`input_${questionIndex}_${optionIndex}_${subOptionIndex}`}
                      style={{
                        border: "none",
                        width: "100%",
                        color: isValueExceeded ? "red" : "inherit", // Set text color to red if value exceeds max value
                      }}
                      value={subOption.optionValue}
                      readOnly
                    />
                  </td>
                );
              })}
            </React.Fragment>
          ))}
        </tr>
      ))}
                    </tbody>
                  </table>
                </div>
              </Modal.Body>

              <Modal.Footer>
                <button className="btn btn-primary" onClick={handlePrint}>
                  Print
                </button>
                <button className="btn btn-primary" onClick={exportToExcel}>
                  Export to Excel
                </button>
                {/* <button className="btn btn-primary" onClick={(event) => { exportToCSV(event) }}>Export to CSV</button> */}
              </Modal.Footer>
            </Modal>
          )}
        </div>
      </div>
    </>
  );
}
const getTodayDate = () => {
  const today = new Date();
  const year = today.getFullYear();
  let month = (today.getMonth() + 1).toString().padStart(2, "0");
  let day = today.getDate().toString().padStart(2, "0");
  return `${year}-${month}-${day}`;
};

function getInitialTableData() {
  const rowCount = 24;
  const tableData = [];

  for (let i = 1; i <= rowCount; i++) {
    const row = [i];
    for (let j = 1; j <= 17; j++) {
      row.push({ value: "", editable: j === 1 });
    }
    tableData.push(row);
  }

  return tableData;
}

export default LogSheetReport;
