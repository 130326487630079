import GetApiData from "Configration/Utilities/getApiData/GetApiData";
import { AlertMessage } from "Framework/Components/Widgets";
import moment from "moment";
import { useState } from "react";
import { getSessionStorage } from "Service/auth/auth";
import { project } from "Service/Endpoints/endpoints";

function AlertsEventLogic() {
  const setAlertMessage = AlertMessage();
  const user = getSessionStorage("userData");
  const selectedProjectData = getSessionStorage("selctedProject");
  // const [selectedProjectData, setSelectedProjectData] = useState([]);
  const [alertsEventList, setAlertsEventList] = useState([]);
  const [isLoadingAlertsEventList, setIsLoadingAlertsEventList] = useState([]);
  const [gridApi, setGridApi] = useState();
  const [searchText, setSearchText] = useState("");

  const [ListViewColumDef] = useState([
    { headerName: "Sr. No.", field: "#", width: 80, valueGetter: "node.rowIndex + 1" },
    { headerName: "Title", field: "TITLE", width: 160 },
    { field: "DESCRIPTION", headerName: "Assets Name", width: 160 },
    { field: "STATUS", headerName: "Status", width: 120 },
    {
      field: "DUEON",
      headerName: "Due On",
      width: 160,
      cellRenderer: (node) => {
        return node.value ? moment(node.value).format("DD-MM-YYYY") : "";
      },
    },
    {
      field: "CLOSEDON",
      headerName: "Closed On",
      width: 160,
      cellRenderer: (node) => {
        return node.value ? moment(node.value).format("DD-MM-YYYY") : "";
      },
    },
    {
      field: "EMPLOYEENAME",
      headerName: "Employees",
      width: 160,
      cellRenderer: (node) => {
        return node.value ? `${node.value} (${node.data.EMPLOYEEID})` : "";
      },
    },
  ]);

  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  const onChangeSearchText = (val) => {
    setSearchText(val);
    gridApi.setQuickFilter(val);
    gridApi.refreshCells();
  };

  const cleareSerchText = (data) => {
    if (data) {
      if (searchText && searchText.toLowerCase().includes("#")) {
        onChangeSearchText("");
      }
    }
  };

  const getAlertsEventList = async () => {
    // setSelectedProjectData(ProjectData);
    const formData = {
      main: {
        divisionid: selectedProjectData && selectedProjectData.DIVISIONIDs ? selectedProjectData.DIVISIONIDs : "",
        profileid: user && user.UserProfileID ? user.UserProfileID : null,
        projectid: selectedProjectData && selectedProjectData.PROJECTID ? selectedProjectData.PROJECTID : "",
      },
    };

    const request = {
      setDatalist: setAlertsEventList,
      setLoad: setIsLoadingAlertsEventList,
      requestData: formData,
      apiPath: project.projectList.GetAlertsEvents,
      setAlert: setAlertMessage,
      fun: cleareSerchText,
    };
    GetApiData(request);
  };

  console.log(alertsEventList, isLoadingAlertsEventList);

  return {
    getAlertsEventList,
    alertsEventList,
    isLoadingAlertsEventList,
    ListViewColumDef,
    searchText,
    onGridReady,
    onChangeSearchText,
  };
}

export default AlertsEventLogic;
