import React, { useEffect, useState, useRef } from 'react';
import { Form, FormGroup, FormControl, Dropdown } from 'react-bootstrap';
import axios from "../../../../Configration/axios/axios";
import { DataGrid, PageBar } from "Framework/Components/Layout";
import { project } from "Service/Endpoints/endpoints";
import { getSessionStorage } from "Service/auth/auth";
import BizClass from "./LogSheetList.module.scss";
import 'bootstrap/dist/css/bootstrap.min.css';
import Multiselect from 'multiselect-react-dropdown';
import { Loader, Splitter } from "Framework/Components/Widgets";
import { ImEye } from "react-icons/im";
import Modal from "Framework/Components/Layout/Modal/Modal";
import { Button } from "Framework/Components/Widgets";
import { MdArrowBack } from "react-icons/md";


const LogSheetForm = () => {
    const [logSheetData, setLogSheetData] = useState([]);
    const [formData, setFormData] = useState({});
    const [readingUnit, setReadingUnit] = useState('yes');
    const [uniqueQuestions, setUniqueQuestions] = useState(new Set());
    const [displayedQuestions, setDisplayedQuestions] = useState([]);
    const displayedQuestionsRef = useRef(new Set());
    const [loading, setisloading] = useState(false);
    const [logSheetName, setLogSheetName] = useState({});
    const [tableData, setTableData] = useState([]);
    const [selectedLogSheet, setSelectedLogSheet] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [logSheetDetailFetched, setLogSheetDetailFetched] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [logsheetId, setLogSheetId] = useState("")
    const formRef = useRef();


    const openLogSheet = async (logsheet) => {
        const logSheetsData = {
            sheetId: logsheet
        };

        try {
            const response = await axios.post(
                `${project.projectList.GetLogSheetDetailBySheetID}`,
                logSheetsData
            );

            const data = response.data.responseDynamic;
            const jsonData = JSON.parse(data);
            const readingsJson = JSON.parse(jsonData.Table[0].LOG_JSON || jsonData.Table[0].READINGS_JSON);
            if (jsonData.Table[0].LOG_JSON) {
                console.log("readingsJson", readingsJson);
                setLogSheetName(readingsJson)
                console.log("logSheetName", logSheetName)
                setLogSheetData(readingsJson.questions)

            }
            else {
                console.log("readingsJson", readingsJson);
                setLogSheetName(readingsJson)
                setLogSheetData(readingsJson)
            }
        } catch (error) {
            console.error('Error fetching log sheet data:', error);
        }
    };
    const cellTemplate = (params) => {
        const style = {
            display: "flex",
            fontSize: "16px",
            background: "transparent",
            border: "0px solid transparent",
            padding: "4px 0px 5px 0px",
            cursor: "pointer",
        };

        const onTeamMemberListClick = () => {
            if (params) {
                params.setSelectedLogSheet(params.data);

                setLogSheetId(params.data.LOG_SHEET_ID)
                setIsModalOpen(true)

                openLogSheet(params.data.LOG_SHEET_ID);

            }
        };


        useEffect(() => {

        }, [isModalOpen]); // Run the effect only when isModalOpen changes


        return (
            <div style={{ display: "flex", gap: "8px", justifyContent: "center", margin: '3px' }}>
                <button type="button" title="Capture LogSheets" onClick={() => onTeamMemberListClick()} style={style}>
                    <ImEye style={{ color: "rgb(52, 73, 94)" }} />
                </button>
            </div>
        );
    };

    const [unit, setUnit] = useState("");
    const [designation, setDesignation] = useState("");

    const selectedProjectData = getSessionStorage("selctedProject");
    console.log("selectedProjectData", selectedProjectData)
    const user = getSessionStorage("userData");
    console.log("user", user)

    const [unitInfo, setUnitInfo] = useState([]);
    const [selectedValues, setSelectedValues] = useState([]);
    const [deisgnationselectedValues, setDesignationSelectedValues] = useState([]);
    const [MemberListViewColumDef] = useState([
        { field: "LOG_SHEET_ID", headerName: "LogSheet ID", width: 120 },
        { field: "LOG_SHEET_NAME", headerName: "LogSheet Name", width: 320 },
        {
            headerName: "Action",
            field: "#",
            width: 120,
            height: 120,
            pinned: "left",
            cellRenderer: cellTemplate,
            cellRendererParams: {
                setSelectedLogSheet,
            },
        },
    ]);

    const designationOptions = [
        {
            value: 1,
            lable: "JE"
        },
        {
            value: 2,
            lable: "AE"
        },
        {
            value: 3,
            lable: "AEE"
        },
        {
            value: 4,
            lable: "EE"
        },
        {
            value: 5,
            lable: "SE"
        },
        {
            value: 6,
            lable: "CE"
        },
        {
            value: 7,
            lable: "ED"
        },
        {
            value: 8,
            lable: "TD"
        },
        {
            value: 9,
            lable: "MD"
        },
        {
            value: 10,
            lable: "DHR"
        },
    ]

    const onSelect = (selectedList, selectedItem) => {
        setSelectedValues(selectedList);
    };

    const onRemove = (selectedList, removedItem) => {
        setSelectedValues(selectedList);
    };

    const options = unitInfo.map((info) => ({
        value: info.unitID,
        label: info.unitName,
    }));

    const onDesignationSelect = (selectedList, selectedItem) => {
        setDesignationSelectedValues(selectedList);
    };

    const onDesignationRemove = (selectedList, removedItem) => {
        setDesignationSelectedValues(selectedList);
    };

    useEffect(() => {
        const unitInfo = async () => {
            try {
                const formData = {
                    projectID: selectedProjectData.PROJECTID
                };
                const response = await axios.post(`${project.projectList.GetUnittInfo}`, formData);
                console.log("Unit response", response.data.responseDynamic);
                setUnitInfo(response.data.responseDynamic);
            } catch (error) {
                console.error("Error fetching unit information:", error);
            }
        };
        unitInfo();
        return () => {
        };
    }, [selectedProjectData.PROJECTID]);

    let questionCounter = 0;
    useEffect(() => {
        setDisplayedQuestions([]);
        displayedQuestionsRef.current.clear();
    }, [logSheetData]);

    const handleQuestionDisplay = (questionId) => {
        if (!displayedQuestionsRef.current.has(questionId)) {
            displayedQuestionsRef.current.add(questionId);
            setDisplayedQuestions((prevDisplayedQuestions) => [...prevDisplayedQuestions, questionId]);
            return true;
        }
        return false;
    };

    let previousId = null;

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value,
        }));
    };

    console.log("logSheetData", logSheetData)
    const handleReadingUnitChange = (event) => {
        setReadingUnit(event.target.value);
    };

    const closeEditModal = () => {
        setIsModalOpen(false);
    };

    // useEffect(() => {
    //     // if (unit.length !== 0 && designation.length !== 0) {
    //     const formData = {
    //         divisionID: 0,
    //         unitID: parseInt(unit, 10),
    //         projectID: parseInt(selectedProjectData.PROJECTID, 10),
    //         employeeLevel: parseInt(designation, 10),
    //     };

    //     console.log("formData", formData);

    //     axios.post(`${project.projectList.GetLogSheetDetail}`, formData)
    //         .then((response) => {
    //             console.log("response", response.data);
    //             const dynamicData = JSON.parse(response.data.responseDynamic);
    //             setTableData(dynamicData.Table);
    //             setLogSheetDetailFetched(true);
    //         })
    //         .catch((error) => {
    //             console.error("Error", error);
    //         });
    //     // } else {
    //     //     console.error("Invalid unit or designation");
    //     // }
    // }, []);

    useEffect(() => {
        if (unit && designation) {
            const formData = {
                divisionID: 0,
                unitID: parseInt(unit, 10),
                projectID: parseInt(selectedProjectData.PROJECTID, 10),
                employeeLevel: parseInt(designation, 10),
            };
    
            console.log("formData", formData);
    
            axios.post(`${project.projectList.GetLogSheetDetail}`, formData)
                .then((response) => {
                    console.log("response", response.data);
                    const dynamicData = JSON.parse(response.data.responseDynamic);
                    setTableData(dynamicData.Table);
                    setLogSheetDetailFetched(true);
                })
                .catch((error) => {
                    console.error("Error", error);
                });
        }
    }, [unit, designation, selectedProjectData.PROJECTID]);
    

    const handleSubmit = (event) => {
        event.preventDefault();
        const formData = new FormData(formRef.current);
        const currentDate = new Date();
        currentDate.setHours(currentDate.getHours() + 1);
        setIsLoading(true);
        let enteredValues;

        if (logSheetName.HR_ID !== null && logSheetName.HR_ID !== currentDate.getHours()) {
            enteredValues = {
                hr_id: currentDate.getHours() || 0,
                unit_id: unit || 0,
                fk_user_id: user.UserName || 0,
                fk_logsheet_id: logsheetId,
                readings_avlb: readingUnit === 'no' ? 'no' : 'yes',
                readings_remarks: readingUnit === 'no' ? formData.get('no_reading_remarks') : formData.get('readings_remarks'),
            };

            if (readingUnit === 'no') {
                const transformedData = logSheetData.map((logSheet, logsheetIndex) => ({
                    questionText: logSheet.questionText || '',
                    readingsCount: logSheet.readingsCount || 0,
                    options: logSheet.options.map((option, optionIndex) => ({
                        optionText: option.optionText || '',
                        validationRequired: option.validationRequired || '',
                        minValue: option.minValue || '',
                        maxValue: option.maxValue || '',
                        optionValue: '0',
                        suboptionsRequired: option.suboptionsRequired || '',
                        suboptionCount: 0, // You may need to set an appropriate default value
                        subOptions: option.subOptions.map((suboption) => ({
                            suboptionText: suboption.suboptionText || '',
                            suboptionValidation: suboption.suboptionValidation || '',
                            suboptionMinValue: suboption.suboptionMinValue || '',
                            suboptionMaxValue: suboption.suboptionMaxValue || '',
                            suboptionValue: 0,
                        })),
                    })),
                }));

                enteredValues.readings_json = transformedData;
            } else if (readingUnit === 'yes') {
                const transformedData = logSheetData.map((logSheet, logsheetIndex) => ({
                    log_sheet_id: logSheetName.LOG_SHEET_ID || 0,
                    unit: String(logSheetName.Unit) || '',
                    log_sheet_name: logSheetName.LogSheetName || '',
                    questionText: logSheet.questionText || '',
                    readingsCount: logSheet.readingsCount || 0,
                    options: logSheet.options.map((option, optionIndex) => ({
                        optionText: option.optionText || '',
                        validationRequired: option.validationRequired || '',
                        minValue: option.minValue || '',
                        maxValue: option.maxValue || '',
                        optionValue: formData.get(`question_${logsheetIndex}_${optionIndex}`) || '0',
                        suboptionsRequired: option.suboptionsRequired || '',
                        suboptionCount: option.suboptionCount,
                        subOptions: option.subOptions.map((suboption, suboptionIndex) => ({
                            suboptionText: suboption.suboptionText || '',
                            suboptionValidation: suboption.suboptionValidation || '',
                            suboptionMinValue: suboption.suboptionMinValue || '',
                            suboptionMaxValue: suboption.suboptionMaxValue || '',
                            suboptionValue: formData.get(`question_${logsheetIndex}_${optionIndex}_sub_${suboptionIndex}`) || '0',
                        })),
                    })),
                }));

                enteredValues.readings_json = transformedData;
            } else {
                alert('Please fix the validation errors before submitting the form.');
                setIsLoading(false);
                return;
            }
        } else {
            alert("Data has already been entered for this Hour");
            setIsLoading(false);
            return; // Add return statement to exit the function
        }

        console.log("enteredValues", enteredValues);

        axios.post(`${project.projectList.InsertLogSheetReadingDetail}`, enteredValues)
            .then(response => {
                console.log("response", response)
                if (response.data.responseMessage === "Success") {
                    alert("Readings have been successfully submitted");
                }
                else {
                    alert("Failed to Submit the Form, Please try again")
                }
            })
            .catch(error => {
                console.error('Error submitting form:', error);
                alert('Failed to submit form. Please try again.');
            }).finally(() => {
                setIsLoading(false);
            });
    };


    const validation = (event) => {
        const enteredValue = event.target.value.trim();
        const minValue = parseFloat(event.target.min);
        const maxValue = parseFloat(event.target.max);

        const inputField = event.target;
        const errorMessageContainer = inputField.nextElementSibling || inputField.nextSibling;
        if (isNaN(enteredValue)) {
            return;
        }

        if (enteredValue < minValue || enteredValue > maxValue) {
            alert('Entered value is not within the range');
            inputField.style.borderColor = 'red';
            inputField.style.borderWidth = '2px';
            if (errorMessageContainer) {
                errorMessageContainer.style.color = 'red';
            }
        }
    };

    const handleUnitChange = (event) => {
        setUnit(event.target.value)
    }

    const handleDesignationChange = (event) => {
        setDesignation(event.target.value)
    }
    return (
        <div className={BizClass.PageStart}>
            <Splitter varient="column" template="1.1fr 9px 1fr">
                <div className={BizClass.Card}>
                    <PageBar className={BizClass.CustomPageBar}>
                        <span>LogSheet List</span>
                    </PageBar>
                    <div className={BizClass.Card}>
                        <div className="container">
                            <FormGroup>
                                <Form.Label htmlFor="unit" className='py-2' required>Unit ID:</Form.Label>
                                <select
                                    id="unit"
                                    name="unit"
                                    className="form-control"
                                    onChange={handleUnitChange}
                                >
                                    <option value="">Select Unit ID</option>
                                    {unitInfo.map((type) => (
                                        <option key={type.unitID} value={type.unitID}>
                                            {type.unitName}
                                        </option>
                                    ))}
                                </select>
                            </FormGroup>

                            <FormGroup>
                                <Form.Label htmlFor="logSheetName" className='py-2' required>Designation:</Form.Label>
                                <select
                                    id="designation"
                                    name="designation"
                                    className="form-control"
                                    onChange={handleDesignationChange}

                                >
                                    <option value="">Select Designation ID</option>
                                    {designationOptions.map((type) => (
                                        <option key={type.value} value={type.value}>
                                            {type.lable}
                                        </option>
                                    ))}
                                </select>
                            </FormGroup>
                        </div>
                    </div>
                </div>
                <div className={BizClass.Card}>
                    <PageBar className={BizClass.CustomPageBar}>
                        <span>LogSheet List</span>

                    </PageBar>
                    <DataGrid
                        rowData={tableData}
                        columnDefs={MemberListViewColumDef}
                    />
                </div>
            </Splitter>

            {isModalOpen && (
                <Modal varient="center"
                    title="Capture LogSheets"
                    index={2}
                    show={closeEditModal}
                    width="100%"
                    height="100%" >
                    <Modal.Body>
                        <PageBar style={{ display: 'flex', justifyContent: 'start' }}>
                            {/* <span>LogSheet Name - {logSheetName.LogSheetName}</span> */}
                            <MdArrowBack /> <a onClick={closeEditModal} style={{ cursor: 'pointer' }}>Back</a>
                        </PageBar>
                        {isLoading === true ? <Loader /> : null}
                        <div className='container py-2 my-2' style={{ backgroundColor: '#f5f5f5', borderRadius: '15px' }}>
                            <label className='py-2 mx-4'>Readings Unit:</label>
                            <div className='mx-4 container'>
                                <input
                                    type="radio"
                                    id="reading_unit_no"
                                    name="readingUnit"
                                    value="no"
                                    checked={readingUnit === 'no'}
                                    onChange={handleReadingUnitChange}
                                    className="m-1"
                                />
                                <label htmlFor="reading_unit_no">No</label>

                                <input
                                    type="radio"
                                    id="reading_unit_yes"
                                    name="readingUnit"
                                    value="yes"
                                    checked={readingUnit === 'yes'}
                                    onChange={handleReadingUnitChange}
                                    className="m-1"
                                />
                                <label htmlFor="reading_unit_yes">Yes</label>
                            </div>
                        </div>

                        <form ref={formRef} onSubmit={handleSubmit} className="my-4 mx-4">

                            {/* {readingUnit === 'yes' && logSheetData.length > 0 && (
                                            <>
                                                {logSheetData.map((logSheet, index) => {
                                                    return (
                                                        <div key={index} className="my-3 px-3 py-3 container" style={{ backgroundColor: '#f5f5f5', borderRadius: '15px', marginBottom: '20px' }}>
                                                            {logSheet.options.length && (
                                                                <div className="mb-3">
                                                                    <p style={{ fontWeight: 'bold' }}>
                                                                        {logSheet.questionText}
                                                                    </p>
                                                                </div>
                                                            )}
                                                            {logSheet.readings_count && (
                                                                <div className="mb-3 row">
                                                                    {logSheet.options.map((option, optionIndex) => (
                                                                        <React.Fragment key={optionIndex}>
                                                                            {option.suboptionsRequired === "No" && (<div className="col">
                                                                                <label className="form-label" style={{ fontWeight: 'bold' }}>{option.optionText} (Min {option.minValue} - Max {option.maxValue})</label>
                                                                                <input
                                                                                    type="number"
                                                                                    name={`question_${index}_${optionIndex}`}
                                                                                    className="form-control"
                                                                                    min={option.minValue}
                                                                                    max={option.maxValue}
                                                                                    required
                                                                                    onBlur={validation}
                                                                                    placeholder='Please Enter a Value'
                                                                                />
                                                                            </div>)}

                                                                            {option.suboptionsRequired === "Yes" && option.subOptions && (
                                                                                <div className="col">
                                                                                    <label className='form-label' style={{ fontWeight: 'bold' }}>{option.optionText}</label><br />
                                                                                    {option.subOptions.map((suboption, suboptionIndex) => (
                                                                                        <React.Fragment key={suboptionIndex}>

                                                                                            <label className="form-label">{suboption.suboptionText} (Min {suboption.suboptionMinValue} - Max {suboption.suboptionMaxValue})</label>
                                                                                            <input
                                                                                                type="number"
                                                                                                name={`question_${index}_${optionIndex}_sub_${suboptionIndex}`}
                                                                                                className="form-control"
                                                                                                min={suboption.suboptionMinValue}
                                                                                                max={suboption.suboptionMaxValue}
                                                                                                required
                                                                                                onBlur={validation}
                                                                                                placeholder='Please Enter a Value'

                                                                                            />
                                                                                        </React.Fragment>
                                                                                    ))}
                                                                                </div>
                                                                            )}
                                                                        </React.Fragment>
                                                                    ))}
                                                                </div>
                                                            )}
                                                            <div className='errorMessageContainer'></div>

                                                        </div>
                                                    );
                                                })}
                                                <div className='container'>
                                                    <div className="d-flex flex-column col-12 col-lg-6 pb-3">
                                                        <label htmlFor="readings_remarks" className='py-2'>Reading Remarks</label>
                                                        <textarea id="readings_remarks" name="readings_remarks" rows="4" cols="50"></textarea>
                                                    </div>
                                                </div>
                                            </>
                                        )} */}
                            {readingUnit === 'yes' && logSheetData.length > 0 && (
                                <>
                                    {logSheetData.map((logSheet, index) => {
                                        return (
                                            <div key={index} className="my-3 px-3 py-3 container" style={{ backgroundColor: '#f5f5f5', borderRadius: '15px', marginBottom: '20px' }}>
                                                {logSheet.options.length > 0 && (
                                                    <div className="mb-3">
                                                        <p style={{ fontWeight: 'bold' }}>
                                                            {logSheet.questionText}
                                                        </p>
                                                    </div>
                                                )}

                                                <div className="mb-3 row">
                                                    {logSheet.options.map((option, optionIndex) => (
                                                        <div key={optionIndex} className="col">
                                                            <p style={{ fontWeight: 'bold' }}>Min-{(option.minValue)}</p> <p style={{ fontWeight: 'bold' }}>Max-{(option.maxValue)}</p>

                                                            {/* <p>Previous Reading : {option.optionValue}</p> */}

                                                            {option.subOptions && option.subOptions.length > 0 && (
                                                                <>

                                                                    <label className="form-label" style={{ fontWeight: 'bold', fontSize: 'larger' }}>{option.optionText}</label><br />
                                                                    {option.subOptions.map((suboption, suboptionIndex) => (
                                                                        <React.Fragment key={suboptionIndex}>
                                                                            <p style={{ fontWeight: 'bold', color: 'green' }}>Previous Reading : {suboption.suboptionValue}</p>
                                                                            <label className="form-label" style={{ fontSize: 'larger' }}><h2>{suboption.suboptionText}</h2></label>
                                                                            <input
                                                                                type="text"
                                                                                name={`question_${index}_${optionIndex}_sub_${suboptionIndex}`}
                                                                                className="form-control"
                                                                                min={option.minValue}
                                                                                max={option.maxValue}
                                                                                required
                                                                                onBlur={validation}
                                                                                placeholder='Please Enter a Value'
                                                                            />
                                                                        </React.Fragment>
                                                                    ))}
                                                                </>
                                                            )}

                                                            {!option.subOptions && option && (
                                                                <>
                                                                    <label className="form-label" style={{ fontWeight: 'bold', fontSize: 'larger' }}><h2>{option.optionText}</h2></label>
                                                                    <input
                                                                        type="number"
                                                                        name={`question_${index}_${optionIndex}`}
                                                                        className="form-control"
                                                                        min={option.minValue}
                                                                        max={option.maxValue}
                                                                        required
                                                                        onBlur={validation}
                                                                        placeholder='Please Enter a Value'
                                                                    />
                                                                </>
                                                            )}

                                                        </div>
                                                    ))}
                                                </div>


                                            </div>
                                        );
                                    })}
                                    <div className='container'>
                                        <div className="d-flex flex-column col-12 col-lg-6 pb-3">
                                            <label htmlFor="readings_remarks" className='py-2'>Reading Remarks</label>
                                            <textarea id="readings_remarks" name="readings_remarks" rows="4" cols="50"></textarea>
                                        </div>
                                    </div>
                                </>
                            )}




                            {readingUnit === 'no' && logSheetData.length > 0 && (
                                <>
                                    {logSheetData.map((logSheet, index) => (
                                        <div key={index} className="mb-3" hidden>
                                            <label className="form-label">{`Question ${logSheet.question_number}: ${logSheet.question_text}`}</label>
                                            {logSheet.options && (
                                                <div className="mb-3">
                                                    <label className="form-label">Options:</label>
                                                    <select
                                                        name={`question_${logSheet.question_id}`}
                                                        className="form-control"
                                                    >
                                                        {logSheet.options.map((option, optionIndex) => (
                                                            <option key={optionIndex} value={option.option_text}>{option.option_text}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                            )}
                                            {!logSheet.options && (
                                                <div className="mb-3">
                                                    <label className="form-label">{logSheet.option_text}</label>
                                                    <input
                                                        type="number"
                                                        name={`question_${logSheet.question_id}`}
                                                        className="form-control"
                                                        min={logSheet.min_value}
                                                        max={logSheet.max_value}
                                                        placeholder='Please Enter Values'
                                                    />
                                                    <div className="errorMessageContainer"></div>
                                                </div>
                                            )}
                                        </div>
                                    ))}
                                    <div className='container'>
                                        <div className="d-flex flex-column col-12 col-lg-6 pb-3">
                                            <label htmlFor="no_reading_remarks">Reading Remarks</label>
                                            <textarea id="no_reading_remarks" name="no_reading_remarks" rows="4" cols="50"></textarea>
                                        </div>
                                    </div>
                                </>
                            )}

                            <button type="submit" className="btn btn-primary" onClick={(event) => { event.preventDefault(); handleSubmit(event); }}>
                                Submit
                            </button>

                        </form>
                    </Modal.Body>


                </Modal>
            )}
        </div>
    );

};

export default LogSheetForm;
