import { useState } from "react";
import { AlertMessage } from "Framework/Components/Widgets/Notification/NotificationProvider";
import { COMPANY_EMPLOYEE, SIGNUP_OTPVALIDATE_VIEW, SIGNUP_VIEW, SIGNUP_ONBOARDINGMSG_VIEW } from "Configration/Utilities/Constants/Constants";
import { loginApiCalling } from "Service/Utilities/apiCalling";
import { auth } from "Service/Endpoints/endpoints";

function SignUpFormLogic() {
  const setAlertMessage = AlertMessage();

  const [formValues, setFormValues] = useState({
    txtEmployeeTypeID: COMPANY_EMPLOYEE,
    txtEmployeeID: "",
    txtMobileNumber: "",
    txtotp: "",
    txtUsername: "",
    txtPassword: "",
    txtConfirmPassword: "",
  });
  const [receivedOTP, setReceivedOTP] = useState("");

  const searchByHandleKeyDown = (e, func, func2) => {
    if (e.keyCode === 13) {
      func(func2);
    }
  };

  // ************************** Code For Sign Up First Form Start *************************** //

  const [userAuthenticationFormError, setUserAuthenticationFormError] = useState({});
  const [isLoadinguserAuthentication, setIsLoadinguserAuthentication] = useState(false);

  const userAuthenticationValidateField = (name, value) => {
    let errorsMsg = "";
    if (name === "txtEmployeeID") {
      if (!value || typeof value === "undefined") {
        errorsMsg = "Employee ID Cannot be empty";
      }
    }
    return errorsMsg;
  };

  const userAuthenticationHandleValidation = () => {
    try {
      const errors = {};
      let formIsValid = true;
      errors["txtEmployeeID"] = userAuthenticationValidateField("txtEmployeeID", formValues.txtEmployeeID);
      if (Object.values(errors).join("").toString()) {
        formIsValid = false;
      }
      setUserAuthenticationFormError(errors);
      return formIsValid;
    } catch (error) {
      setAlertMessage({ open: true, type: "error", msg: "Something went wrong!" });
      return false;
    }
  };

  const updateFormState = (name, value) => {
    if (!isLoadinguserAuthentication) {
      userAuthenticationFormError[name] = userAuthenticationValidateField(name, value);
      setFormValues((values) => ({
        ...values,
        [name]: value,
      }));
    }
  };

  const userAuthenticationHandleSave = async (setActivePageView) => {
    if (!userAuthenticationHandleValidation()) {
      return;
    }
    try {
      setIsLoadinguserAuthentication(true);
      const formData = {
        main: {
          empid: formValues && formValues.txtEmployeeID ? Number(formValues.txtEmployeeID) : null,
          businessrelation: formValues && formValues.txtEmployeeTypeID ? formValues.txtEmployeeTypeID : null,
          // mobileNo: "",
          // otpNumber: 0,
        },
      };
      const result = await loginApiCalling(formData, auth.signUp.getUserAuthenticated);
      setIsLoadinguserAuthentication(false);
      if (result && result.responseCode === 1) {
        if (!(result.responseData && result.responseData.MobileNo)) {
          setAlertMessage({
            type: "error",
            message: result.responseMessage,
          });
          return;
        }
        setFormValues((values) => ({
          ...values,
          txtMobileNumber: result.responseData && result.responseData.MobileNo,
        }));
        setActivePageView(SIGNUP_OTPVALIDATE_VIEW);
        console.log(result && result.responseData && result.responseData.OtpNumber, "Received OTP");
        setReceivedOTP(result.responseData.OtpNumber);
      } else {
        setAlertMessage({
          type: "error",
          message: result.responseMessage,
        });
        setFormValues((values) => ({
          ...values,
          txtMobileNumber: "",
        }));
      }
    } catch (error) {
      console.log(error);
      setAlertMessage({
        type: "error",
        message: error,
      });
      setFormValues((values) => ({
        ...values,
        txtMobileNumber: "",
      }));
    }
  };

  // ************************** Code For Sign Up First Form End ***************************** //

  // ************************** Code For Sign Up Second Form Start *************************** //

  const [otptimer, setOtpTimer] = useState(60);
  const [otpAuthenticationFormError, setOtpAuthenticationFormError] = useState({});
  const [isLoadingOtpAuthentication, setIsLoadingOtpAuthentication] = useState(false);

  const otpAuthenticationValidateField = (name, value) => {
    let errorsMsg = "";
    if (name === "txtotp") {
      if (!value || typeof value === "undefined") {
        errorsMsg = "One Time Password Cannot be empty";
      }
    }
    return errorsMsg;
  };

  const otpAuthenticationHandleValidation = () => {
    try {
      const errors = {};
      let formIsValid = true;
      errors["txtotp"] = otpAuthenticationValidateField("txtotp", formValues.txtotp);
      if (Object.values(errors).join("").toString()) {
        formIsValid = false;
      }
      setOtpAuthenticationFormError(errors);
      return formIsValid;
    } catch (error) {
      setAlertMessage({ open: true, type: "error", msg: "Something went wrong!" });
      return false;
    }
  };

  const updateOTPFormState = (name, value) => {
    if (!isLoadingOtpAuthentication) {
      otpAuthenticationFormError[name] = otpAuthenticationValidateField(name, value);
      setFormValues((values) => ({
        ...values,
        [name]: value,
      }));
    }
  };

  const otpAuthenticationHandleSave = async (setActivePageView) => {
    if (!otpAuthenticationHandleValidation()) {
      return;
    }
    try {
      setIsLoadingOtpAuthentication(true);
      const formData = {
        main: {
          empid: formValues && formValues.txtEmployeeID ? Number(formValues.txtEmployeeID) : null,
          businessrelation: formValues && formValues.txtEmployeeTypeID ? formValues.txtEmployeeTypeID : null,
          otpNumber: formValues && formValues.txtotp ? Number(formValues.txtotp) : null,
        },
      };
      const result = await loginApiCalling(formData, auth.signUp.otpValidatation);
      setIsLoadingOtpAuthentication(false);
      if (result && result.responseCode === 1) {
        setActivePageView(SIGNUP_ONBOARDINGMSG_VIEW);
      } else {
        setAlertMessage({
          type: "error",
          message: result.responseMessage,
        });
        setFormValues((values) => ({
          ...values,
        }));
      }
    } catch (error) {
      console.log(error);
      setAlertMessage({
        type: "error",
        message: error,
      });
      setFormValues((values) => ({
        ...values,
      }));
    }
  };

  const resetEmployeeData = (setActivePageView) => {
    setFormValues(() => ({
      txtEmployeeTypeID: COMPANY_EMPLOYEE,
      txtEmployeeID: "",
      txtMobileNumber: "",
      txtotp: "",
    }));
    setActivePageView(SIGNUP_VIEW);
    otpAuthenticationFormError["txtotp"] = otpAuthenticationValidateField("txtotp", " ");
  };

  const requestOtpAgain = async () => {
    try {
      setIsLoadinguserAuthentication(true);
      const formData = {
        main: {
          empid: formValues && formValues.txtEmployeeID ? Number(formValues.txtEmployeeID) : null,
          businessrelation: formValues && formValues.txtEmployeeTypeID ? formValues.txtEmployeeTypeID : null,
          mobileNo: "",
          otpNumber: 0,
        },
      };
      const result = await loginApiCalling(formData, auth.signUp.getUserAuthenticated);
      setIsLoadinguserAuthentication(false);
      if (result && result.responseCode === 1) {
        if (!(result.responseData && result.responseData.MobileNo)) {
          setAlertMessage({
            type: "error",
            message: result.responseMessage,
          });
          return;
        }
        setFormValues((values) => ({
          ...values,
          txtMobileNumber: result.responseData && result.responseData.MobileNo,
        }));
        setReceivedOTP(result.responseData.OtpNumber);
        setAlertMessage({
          type: "Success",
          message: "Successfully OTP Requested",
        });
        if (!otptimer) {
          setOtpTimer(60);
        }
      } else {
        setAlertMessage({
          type: "error",
          message: result.responseMessage,
        });
        setFormValues((values) => ({
          ...values,
          txtMobileNumber: "",
        }));
      }
    } catch (error) {
      console.log(error);
      setAlertMessage({
        type: "error",
        message: error,
      });
    }
  };

  // ************************** Code For Sign Up Second Form End ***************************** //

  return {
    searchByHandleKeyDown,
    formValues,
    updateFormState,
    userAuthenticationFormError,
    userAuthenticationHandleSave,
    isLoadinguserAuthentication,
    otptimer,
    setOtpTimer,
    updateOTPFormState,
    requestOtpAgain,
    otpAuthenticationFormError,
    isLoadingOtpAuthentication,
    otpAuthenticationHandleSave,
    resetEmployeeData,
    receivedOTP,
  };
}

export default SignUpFormLogic;
