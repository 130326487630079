import { DataGrid, PageBar } from "Framework/Components/Layout";
import { Loader } from "Framework/Components/Widgets";
import React, { useEffect } from "react";
import { IoMdAdd } from "react-icons/io";
import WorkAssignmentLogic from "./Logic/WorkObservationAssignmentLogic";
import AddWorkAssignment from "./view/model/AddWorkObservationAssignment";
import BizClass from "./WorkObservationAssignment.module.scss";

function WorkAssignment(props) {
  const { selectedProjectData } = props;
  const {
    getWorkingAssignmentList,
    AssingmentColumDef,
    workAssignmentList,
    isLoadingWorkAssignment,
    isActiveWorkAssignmentPopUp,
    toggleAddWorkAssignmentpopup,
    updateFormState,
    formValues,
    formValidationError,
    divisionList,
    isLoadingDivisionList,
    getDivisionDetailsList,
    employeeList,
    toEmployeeList,
    isLoadingEmployeeList,
    employeeAssetList,
    isLoadingEmployeeAssetList,
    employeeAssetColumDef,
    onEmpAssetGridReady,
    SaveWorkAssignment,
    isLoadingAddEmployeeAssign,
    clearForm,
    onChangeSearchText,
    searchText,
    onGridReady,
  } = WorkAssignmentLogic();

  const exportToCSV = () => {
    if (workAssignmentList && workAssignmentList.length !== 0) {
      const headers = Object.keys(workAssignmentList[0]);
      console.log("workAssignmentList",workAssignmentList)

      const csvContent = "data:text/csv;charset=utf-8," +
        headers.join(",") + "\n" +
        workAssignmentList.map(row => headers.map(header => row[header]).join(",")).join("\n");
      const encodedUri = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "WorkObservationAssignment.csv");
      document.body.appendChild(link);
      link.click();
    }
    else {
      alert('No Data to Export!')
    }

  };


  useEffect(() => {
    getWorkingAssignmentList(selectedProjectData);
  }, [selectedProjectData]);

  return (
    <div className={BizClass.WorkAssignmentGrid}>
      {isActiveWorkAssignmentPopUp && (
        <AddWorkAssignment
          toggleAddWorkAssignmentpopup={toggleAddWorkAssignmentpopup}
          updateFormState={updateFormState}
          formValues={formValues}
          divisionList={divisionList}
          isLoadingDivisionList={isLoadingDivisionList}
          getDivisionDetailsList={getDivisionDetailsList}
          employeeList={employeeList}
          isLoadingEmployeeList={isLoadingEmployeeList}
          employeeAssetList={employeeAssetList}
          isLoadingEmployeeAssetList={isLoadingEmployeeAssetList}
          employeeAssetColumDef={employeeAssetColumDef}
          onEmpAssetGridReady={onEmpAssetGridReady}
          SaveWorkAssignment={SaveWorkAssignment}
          formValidationError={formValidationError}
          toEmployeeList={toEmployeeList}
          isLoadingAddEmployeeAssign={isLoadingAddEmployeeAssign}
          clearForm={clearForm}
        />
      )}
      <PageBar>
        <PageBar.Search onChange={(e) => onChangeSearchText(e.target.value)} value={searchText} onClick={() => getWorkingAssignmentList(selectedProjectData)} />
        <PageBar.Button onClick={() => toggleAddWorkAssignmentpopup()}>
          <IoMdAdd /> <span>Add Observation Assignment</span>
        </PageBar.Button>
        <PageBar.Button varient="primary" onClick={exportToCSV} style={{margin:'5px'}}>
                            Export to CSV
                        </PageBar.Button>
      </PageBar>
      <DataGrid rowData={workAssignmentList} columnDefs={AssingmentColumDef} loader={isLoadingWorkAssignment ? <Loader /> : null} onGridReady={onGridReady} />
    </div>
  );
}

export default WorkAssignment;
