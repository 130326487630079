import { DataGrid, PageBar } from "Framework/Components/Layout";
import { Loader } from "Framework/Components/Widgets";
import React, { useEffect } from "react";
import SkipApprovalsLogic from "./Logic/SkipApprovalsLogic";

import BizClass from "./SkipApprovals.module.scss";
import SkipApprovalPopUp from "./View/SkipApprovalPopUp";

function SkipApprovals(props) {
  const { selectedProjectData } = props;
  const {
    getSkipApprovalList,
    skipApprovalsListData,
    isLoadingSkipApprovalsList,
    skipAppovalColumDef,
    toggleSkipApprovalPopUp,
    isActivePopUp,
    toggleAssignPopUp,
    isActiveAssinPopUp,
    getDivisionDetailsList,
    isLoadingDivisionList,
    divisionList,
    formValues,
    updateFormState,
    employeeList,
    isLoadingEmployeeList,
    employeeAssetColumDef,
    onEmpAssetGridReady,
    SaveSelectadAssetsRowData,
    isLoadingReject,
    isLoadingApproval,
    AssetsGridList,
    isloadingAssetsGrid,
    GetObservationAssetSelect,
    clearFormValue,
    ObsevationReject,
    ObsevationApprove,
    onChangeSearchText,
    searchText,
    onGridReady,
  } = SkipApprovalsLogic();

  const exportToCSV = () => {
    if (skipApprovalsListData && skipApprovalsListData.length !== 0) {
      const headers = Object.keys(skipApprovalsListData[0]);

      const csvContent = "data:text/csv;charset=utf-8," +
        headers.join(",") + "\n" +
        skipApprovalsListData.map(row => headers.map(header => row[header]).join(",")).join("\n");
      const encodedUri = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "SkipApprovals.csv");
      document.body.appendChild(link);
      link.click();
    }
    else {
      alert('No Data to Export!')
    }

  };

  useEffect(() => {
    if (selectedProjectData) {
      getSkipApprovalList(selectedProjectData);
    }
    console.log(skipApprovalsListData);
  }, [selectedProjectData]);

  return (
    <>
      {isActivePopUp.isOpen && (
        <SkipApprovalPopUp
          toggleSkipApprovalPopUp={toggleSkipApprovalPopUp}
          PopUpData={isActivePopUp.popUpData}
          toggleAssignPopUp={toggleAssignPopUp}
          isActiveAssinPopUp={isActiveAssinPopUp}
          getDivisionDetailsList={getDivisionDetailsList}
          isLoadingDivisionList={isLoadingDivisionList}
          divisionList={divisionList}
          updateFormState={updateFormState}
          formValues={formValues}
          employeeList={employeeList}
          isLoadingEmployeeList={isLoadingEmployeeList}
          employeeAssetColumDef={employeeAssetColumDef}
          onEmpAssetGridReady={onEmpAssetGridReady}
          SaveSelectadAssetsRowData={SaveSelectadAssetsRowData}
          isLoadingReject={isLoadingReject}
          isLoadingApproval={isLoadingApproval}
          AssetsGridList={AssetsGridList}
          isloadingAssetsGrid={isloadingAssetsGrid}
          GetObservationAssetSelect={GetObservationAssetSelect}
          clearFormValue={clearFormValue}
          ObsevationReject={ObsevationReject}
          ObsevationApprove={ObsevationApprove}
        />
      )}
      <div className={BizClass.SkipApprovals}>
        <PageBar>
          <PageBar.Search onChange={(e) => onChangeSearchText(e.target.value)} value={searchText} onClick={() => getSkipApprovalList(selectedProjectData)} />
          <PageBar.Button varient="primary" onClick={exportToCSV} style={{margin:'5px'}}>
                            Export to CSV
                        </PageBar.Button>
        </PageBar>
        <DataGrid
          rowData={skipApprovalsListData}
          columnDefs={skipAppovalColumDef}
          loader={isLoadingSkipApprovalsList ? <Loader /> : null}
          onGridReady={onGridReady}
        />
      </div>
    </>
  );
}

export default SkipApprovals;
