import { PageBar } from "Framework/Components/Layout";
import React, { useEffect } from "react";
import { AiOutlineUnorderedList } from "react-icons/ai";
import { TbLayoutDashboard } from "react-icons/tb";
import { Loader } from "Framework/Components/Widgets";
import ProjectDetail from "../ProjectDetail/ProjectDetail";
import ProjectsLogic from "./Logic/ProjectsListLogic";
import ProjectsBoardView from "./Views/Layout/ProjectsBoardView/ProjectsBoardView";
import ProjectsListView from "./Views/Layout/ProjectsListView/ProjectsListView";
import BizClass from "./ProjectsList.module.scss";

function ProjectsList() {
  const {
    activeViewID,
    setActiveViewID,
    ListViewColumDef,
    detailModalActive,
    selectedProjectData,
    toggleDetailModal,
    getProjectListData,
    isLoading,
    onGridReady,
    projectItemSearch,
    filteredProjectListData,
    projectListData,
    onSearchList,
  } = ProjectsLogic();

  useEffect(() => {
    if (detailModalActive === false) {
      document.title = "Projects | Karnataka Power Corporation Limited";
    }
  }, [detailModalActive]);

  return (
    <>
      {detailModalActive && <ProjectDetail selectedProjectData={selectedProjectData} toggleDetailModal={toggleDetailModal} />}
      <div className={BizClass.MainSection}>
        <PageBar className={BizClass.PageTitleBarBox}>
          <div className={BizClass.PageTitleBar}>
            <ul className={BizClass.TabBar}>
              <li>
                <button type="button" className={activeViewID === 1 && BizClass.Active} onClick={() => setActiveViewID(1)}>
                  <AiOutlineUnorderedList />
                  List
                </button>
              </li>
              <li>
                <button type="button" className={activeViewID === 2 && BizClass.Active} onClick={() => setActiveViewID(2)}>
                  <TbLayoutDashboard />
                  Board
                </button>
              </li>
            </ul>
          </div>
          <PageBar.Search value={projectItemSearch} onChange={(e) => onSearchList(e.target.value)} onClick={() => getProjectListData()} />
        </PageBar>
        <div className={activeViewID === 1 ? BizClass.ActiveView : BizClass.SectionView}>
          {isLoading && <Loader />}
          <ProjectsListView
            // ListViewColumDef={ListViewColumDef}
            // filteredProjectListData={projectListData}
            // onGridReady={onGridReady}
            // toggleDetailModal={toggleDetailModal}
            toggleDetailModal={toggleDetailModal} filteredProjectListData={filteredProjectListData}
          />
        </div>
        <div className={activeViewID === 2 ? BizClass.ActiveView : BizClass.SectionView}>
          {isLoading && <Loader />}
          <ProjectsBoardView toggleDetailModal={toggleDetailModal} filteredProjectListData={filteredProjectListData} />
        </div>
      </div>
    </>
  );
}

export default ProjectsList;
