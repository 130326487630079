import React, { useEffect, useState } from "react";
import { PageBar } from "Framework/Components/Layout";
import BizClass from "./AssetStatus.module.scss";
import AssetsLogics from "./Logic/AssetStatusLogic";
import AssetsDetails from "../AssetsDetails/AssetsDetails";
import { Button } from "Framework/Components/Widgets";
import { getSessionStorage } from "Service/auth/auth";
import { project } from "Service/Endpoints/endpoints";
import GetApiData from "Configration/Utilities/getApiData/GetApiData";
import { AlertMessage } from "Framework/Components/Widgets";
import { DataGrid } from "Framework/Components/Layout";
import { Loader } from "Framework/Components/Widgets";
import Modal from "Framework/Components/Layout/Modal/Modal";



function Assets({ selectedProjectData }) {
    console.log("selectedProjectData", selectedProjectData);
    const [visible, setVisible] = useState(true)
    const setAlertMessage = AlertMessage();
    const user = getSessionStorage("userData");
    const {
        assetsListData,
        getAssetListingData,
        toggleAssetDetailsView,
        assetsDetailseViewData,
        isActiveAssetViewPopUp,
        setGender,
        onChangeSearchText,
        assetStatusChanged,
        selectedAssets,
        isLoadingAssetStatusData,
        onGridReady,
        searchText,
        isActiveWorkAssignmentPopUp,
        toggleAddWorkAssignmentpopup
    } = AssetsLogics();

    useEffect(() => {
        getAssetListingData();
    }, []);

    const exportToCSV = () => {
        // Check if assetsListData is empty or undefined
        if (!assetsListData || assetsListData.length === 0) {
            console.log("No data available to export.");
            return;
        }
    
        // Define headers
        const headers = ["ASSETNAME", "ACTION"];
    
        // Generate CSV rows
        const csvRows = assetsListData.map(row => [
            row.ASSETNAME,
            getActionText(row)
        ]);
    
        // Create a CSV content string
        let csvContent = headers.join(",") + "\n";
        csvContent += csvRows.map(row => row.join(",")).join("\n");
    
        // Create a Blob object containing the CSV data
        const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8" });
    
        // Create a download link
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.setAttribute("download", "AssetStatus.csv");
    
        // Simulate a click on the link to trigger the download
        document.body.appendChild(link);
        link.click();
    
        // Clean up
        document.body.removeChild(link);
    };
    
    
    
    // Function to get action text based on the selected radio buttons
    const getActionText = (row) => {
        // Logic to determine the action text based on the selected radio button
        if (row.active) {
            return "Active";
        } else if (row.inactive) {
            return "Inactive";
        } else if (row.maintenance) {
            return "Maintenance";
        } else {
            return "";
        }
    };
    
    



    const cellTemplate = (params) => {
        const { data } = params;
        console.log("tableData", data);

        return (
            <div >
                <input
                    type="radio"
                    value={data.ASSET_STATUS}
                    name={`status_${data.ASSETCODE}`}
                    defaultChecked={data.ASSET_STATUS === 'ACTIVE'}
                    onChange={() => setGender(data.ASSETNAME, 1, data.ASSETCODE, data.PEQUIPMENTNUMBER, 'active')}
                    style={{ margin: '7px' }}
                />Active

                <input
                    type="radio"
                    value={data.ASSET_STATUS}
                    name={`status_${data.ASSETCODE}`}
                    defaultChecked={data.ASSET_STATUS === 'INACTIVE'}
                    onChange={() => setGender(data.ASSETNAME, 0, data.ASSETCODE, data.PEQUIPMENTNUMBER, 'inactive')}
                    style={{ margin: '7px' }}
                />InActive

                <input
                    type="radio"
                    value={data.ASSET_STATUS}
                    name={`status_${data.ASSETCODE}`}
                    defaultChecked={data.ASSET_STATUS === 'MAINTENANCE'}
                    onChange={() => setGender(data.ASSETNAME, 2, data.ASSETCODE, data.PEQUIPMENTNUMBER, 'maintenance')}
                    style={{ margin: '7px' }}
                />Maintenance

            </div>
        );
    };






    const [ListViewColumDef] = useState([
        { headerName: "Sr. No.", field: "#", width: 80, pinned: "left", valueGetter: "node.rowIndex + 1" },
        { field: "ASSETNAME", headerName: "Assets Name", width: 440 },
        {
            field: "#",
            headerName: "Action",
            width: 340,
            cellRendererFramework: cellTemplate,
        },
    ]);

    const handleSave = () => {
        if (selectedAssets.length > 0) {
            const changedAssetsMessage = selectedAssets.map((asset) => (
                `${asset.assetName} - Status: ${asset.statusValue}`
            )).join(", ");

            if (selectedAssets.length > 3) {
                const confirmResult = window.confirm(`You have changed more than 3 assets. Do you want to proceed?\n\n${changedAssetsMessage}`);

                if (confirmResult) {
                    assetStatusChanged();
                    console.log("Updated Assets:", selectedAssets);
                    alert(`Asset Status Changed:  ${changedAssetsMessage}`);
                } else {
                    console.log("Save canceled by the user");
                }
            } else {
                assetStatusChanged();
                console.log("Updated Assets:", selectedAssets);
                alert(`Asset Status Changed: ${changedAssetsMessage}`);
            }
        }
    };



    const scrollToSaveButton = () => {
        const saveButtonAnchor = document.getElementById("saveButtonAnchor");
        if (saveButtonAnchor) {
            saveButtonAnchor.scrollIntoView({ behavior: "smooth" });
        }
    };




    return (
        <>
            <div className={BizClass.AssetsGrid}>
                <PageBar className={BizClass.CustomPageBar}>
                    <span>Assets Status</span>
                    <div className="d-flex"><PageBar.Search onChange={(e) => onChangeSearchText(e.target.value)} value={searchText} onClick={() => getAssetListingData()} style={{ marginBottom: '2px' }} />
                        <PageBar.Button varient="primary" onClick={exportToCSV} style={{ margin: '2px' }}>
                            Export to CSV
                        </PageBar.Button><PageBar.Button onClick={scrollToSaveButton} style={{ margin: '2px' }}>
                            Scroll To Bottom
                        </PageBar.Button>
                    </div>

                </PageBar>




                <DataGrid rowData={assetsListData} columnDefs={ListViewColumDef} loader={isLoadingAssetStatusData ? <Loader /> : null} onGridReady={onGridReady} style={{ marginTop: '-150px' }} />

                <div id="saveButtonAnchor"></div>

                <div style={{ margin: '20px' }}>
                    <Button varient="secondary" onClick={() => toggleAddWorkAssignmentpopup()}>
                        Save
                    </Button>
                </div>


                {isActiveWorkAssignmentPopUp && <Modal varient="center" title="Assets Changed" show={toggleAddWorkAssignmentpopup} width="500px" height="300px">
                    <Modal.Body>
                        <div>{selectedAssets.length > 0 && (
                            <div>
                                {selectedAssets.map((asset, index) => (
                                    <div key={index}>
                                        <strong>{asset.assetName}</strong> - Status: {asset.statusValue}
                                    </div>
                                ))}
                            </div>
                        )}</div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            varient="secondary"
                            onClick={() => {
                                assetStatusChanged();
                            }}
                        >
                            Save
                        </Button>
                    </Modal.Footer>
                </Modal>}

            </div>
        </>
    );
}

export default Assets;
