import { DataGrid, PageBar } from "Framework/Components/Layout";
import Modal from "Framework/Components/Layout/Modal/Modal";
import { Button, Loader } from "Framework/Components/Widgets";
import React, { useEffect } from "react";
import BizClass from "../SkipApprovals.module.scss";

function AssignPopup(props) {
  const {
    toggleAssignPopUp,
    getDivisionDetailsList,
    isLoadingDivisionList,
    divisionList,
    updateFormState,
    formValues,
    employeeList,
    isLoadingEmployeeList,
    employeeAssetColumDef,
    onEmpAssetGridReady,
    SaveSelectadAssetsRowData,
  } = props;

  useEffect(() => {
    getDivisionDetailsList();
  }, []);

  return (
    <Modal varient="half" title="Assign" show={toggleAssignPopUp} index={2}>
      <Modal.Body>
        <div className={BizClass.AssignPopUp}>
          <PageBar>
            <span>Select Devision : </span>
            <PageBar.Select
              name="txtDivision"
              options={divisionList}
              isLoading={isLoadingDivisionList}
              getOptionLabel={(option) => `${option.DIVISIONNAME}`}
              getOptionValue={(option) => `${option}`}
              onChange={(e) => {
                updateFormState(e, "txtDivision");
              }}
              value={formValues.txtDivision}
            />
          </PageBar>
          <div className={BizClass.AssetsGrid}>
            <DataGrid
              rowData={employeeList}
              columnDefs={employeeAssetColumDef}
              loader={isLoadingEmployeeList ? <Loader /> : null}
              onGridReady={onEmpAssetGridReady}
              rowSelection="single"
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          type="button"
          varient="secondary"
          onClick={() => {
            SaveSelectadAssetsRowData();
          }}
        >
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default AssignPopup;
