import React, { useEffect, useState } from "react";
import { DataGrid, PageBar } from "Framework/Components/Layout";
import { Loader, Splitter } from "Framework/Components/Widgets";
import TeamsLogic from "./Logic/TeamsLogic";
import BizClass from "./Teams.module.scss";
import { IoMdCreate } from "react-icons/io";
import Modal from "Framework/Components/Layout/Modal/Modal";
import { Button } from "Framework/Components/Widgets";
import Form from "Framework/Components/Layout/FormGroup/FormGroup";
import axios from "../../../../Configration/axios/axios";
import { project } from "Service/Endpoints/endpoints";
import { getSessionStorage } from "Service/auth/auth";


function Teams({ selectedProjectData }) {
  const {
    ListViewColumDef,
    teamsListData,
    isLoadingTeamsData,
    setSelectedProjectData,
    getTeamsListingData,
    teamsListMembersData,
    isLoadingTeamsMemberData,
    MemberListViewColumDef,
    // getTeamsMembersListingData,
    filteredTeamsListData,
    divisionList,
    isLoadingDivisionList,
    getDivisionDetailsList,
    selectedDivision,
    onGridReady,
  } = TeamsLogic();

  const [editedRowData, setEditedRowData] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedUserName, setselectedUserName] = useState('');
  const [selectedEmployeeCode, setselectedEmployeeCode] = useState('');
  const [selectedDivisionName, setselectedDivisionName] = useState('');
  const [selectedRoleName, setselectedRoleName] = useState('');
  const user = getSessionStorage("userData");
  console.log("user", user.ProfileName)

  useEffect(() => {
    setSelectedProjectData(selectedProjectData);
  }, [selectedProjectData]);

  console.log("filteredTeamsListData", filteredTeamsListData)
  useEffect(() => {
    if (editedRowData) {
      setselectedUserName(editedRowData.EMPLOYEENAME);
      setselectedDivisionName(editedRowData.DIVISIONNAME);
      setselectedEmployeeCode(editedRowData.EMPLOYEEID);
      setselectedRoleName(editedRowData.DESIGNATIONNAME);

    }
  }, [editedRowData]);

  const exportToCSV = () => {
    if (teamsListMembersData && teamsListMembersData.length !== 0) {
      const headers = Object.keys(teamsListMembersData[0]);

      const csvContent = "data:text/csv;charset=utf-8," +
        headers.join(",") + "\n" +
        teamsListMembersData.map(row => headers.map(header => row[header]).join(",")).join("\n");
      const encodedUri = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "TeamsList.csv");
      document.body.appendChild(link);
      link.click();
    }
    else {
      alert('No Data to Export!')
    }

  };


  const EditCellRenderer = (props) => {
    // Assuming user.ProfileName contains the user's role information
    const isAdmin = user && user.ProfileName === "Super Admin";

    return (
      <div>
        {isAdmin && (
          <IoMdCreate
            onClick={() => {
              console.log("Edit clicked for row with data:", props.data);
              setEditedRowData(props.data);
              setIsEditModalOpen(true);
            }}
          />
        )}
      </div>
    );
  };




  // Assuming user.ProfileName contains the user's role information
  const isAdmin = user && user.ProfileName === "Super Admin";

  const updatedColumnDefs = [
    isAdmin
      ? {
        headerName: "Edit",
        cellRendererFramework: EditCellRenderer,
        minWidth: 100,
        maxWidth: 100,
        sortable: false,
        filter: false,
      }
      : null,
    ...MemberListViewColumDef,

  ].filter(Boolean);


  const closeEditModal = () => {
    setIsEditModalOpen(false);
  };

  const saveChanges = async () => {
    console.log(
      selectedUserName,
      selectedEmployeeCode,
      selectedDivisionName,
      selectedRoleName,
    );
    setIsEditModalOpen(false);
    const divisionID = selectedDivisionName.value;
    console.log("divisionID", divisionID);

    const formData = {
      employeeID: selectedEmployeeCode,
      divisionID: divisionID,
      designationID: 0,
    };
    console.log("formdata", formData)

    axios.post(`${project.projectList.TransferEmployee}`, formData)
      .then((response) => {
        if (response.status === 200) {
          alert('Team changed');
        } else {
          alert('Error');
        }
      })
      .catch((error) => {
        // Handle errors
        console.error("Error fetching data:", error);
      });
  };


  const openEditModal = (rowData) => {
    setEditedRowData(rowData);
    setIsEditModalOpen(true);

  };

  const handleInputChange = (e, field) => {
    const newValue = e.target.value;

    switch (field) {
      case "EMPLOYEENAME":
        setselectedUserName(newValue);
        break;
      case "EMPLOYEEID":
        setselectedEmployeeCode(newValue);
        break;
      // case "DIVISIONNAME":
      //   // Extract the selected division name from the event
      //   const selectedDivisionName = e.target.options[e.target.selectedIndex].text;
      //   setselectedDivisionName(selectedDivisionName);
      //   break;
      case "DESIGNATIONNAME":
        setselectedRoleName(newValue);
        break;
      default:
        break;
    }
  };

  const formValue = (e, field) => {
    if (field) {
      console.log("formvalue e", e);
      const newValue = e;
      console.log("newValue", newValue);
      setselectedDivisionName(newValue);
    }
  };

  console.log("teamsListData", teamsListData)
  console.log("teamsListMembersData", teamsListMembersData)

  return (
    <div className={BizClass.PageStart}>
      <Splitter varient="column" template="1.1fr 9px 1fr">
        <div className={BizClass.Card}>
          <PageBar className={BizClass.CustomPageBar}>
            <span>Project Team</span>
            <PageBar.Button onClick={() => getTeamsListingData()}>Refresh</PageBar.Button>
           
          </PageBar>
          <DataGrid
            rowData={teamsListData}
            onGridReady={onGridReady}
            columnDefs={ListViewColumDef}
            loader={isLoadingTeamsData ? <Loader /> : null}
            getRowStyle={(data) => {
              if (data.data.IsSelected) {
                return { background: "#ffc176" };
              }
              return { background: "" };
            }}
          />
        </div>
        <div className={BizClass.Card}>
          <PageBar className={BizClass.CustomPageBar}>
            <span>
              {selectedDivision &&
                selectedDivision.DIVISIONNAME &&
                selectedDivision.DIVISIONID &&
                `${selectedDivision.DIVISIONNAME} (${selectedDivision.DIVISIONID})`}
            </span>
            <PageBar.Button varient="primary" onClick={exportToCSV}>
              Export to CSV
            </PageBar.Button>
          </PageBar>
          <DataGrid
            rowData={teamsListMembersData}
            filteredTeamsListData={filteredTeamsListData}
            // getTeamsMembersListingData={getTeamsMembersListingData}
            columnDefs={updatedColumnDefs}
            loader={isLoadingTeamsMemberData ? <Loader /> : null}
          />
        </div>
      </Splitter>


      {isEditModalOpen && (
        <Modal
          varient="center"
          title="Edit Teams"
          index={2}
          show={closeEditModal}
          width="754px"
          height="400px"
        >
          <Modal.Body>
            <Form>
              <Form.Group column={2} controlwidth="240px">
                <Form.InputGroup label="User Name">
                  <Form.InputControl
                    control="input"
                    name="txtUserName"
                    maxLength="200"
                    value={selectedUserName}
                    onChange={(e) => handleInputChange(e, "EMPLOYEENAME")}
                  />
                </Form.InputGroup>
                <Form.InputGroup label="Employee Code">
                  <Form.InputControl
                    control="input"
                    name="txtEmployeeCode"
                    maxLength="200"
                    value={selectedEmployeeCode}
                    onChange={(e) => handleInputChange(e, "EMPLOYEEID")}
                  />

                </Form.InputGroup>
                <Form.InputGroup label="Division Name">
                  <Form.InputControl
                    control="select"
                    name="txtDivision"
                    options={filteredTeamsListData.map(option => ({
                      value: option.DIVISIONID,
                      label: option.DIVISIONNAME
                    }))}
                    defaultValue={selectedDivisionName}
                    onChange={(e) => {
                      console.log("e", e);
                      formValue(e, "DIVISIONNAME");
                    }}
                  />
                </Form.InputGroup>

                <Form.InputGroup label="Role Name">
                  <Form.InputControl
                    control="input"
                    name="txtRoleName"
                    maxLength="200"
                    value={selectedRoleName}
                    onChange={(e) => handleInputChange(e, "DESIGNATIONNAME")}
                  />

                </Form.InputGroup>
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button
              varient="primary"
              onClick={() => saveChanges()}
            >
              Save
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </div>


  );
}

export default Teams;
