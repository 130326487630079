import { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { AlertMessage } from "Framework/Components/Widgets/Notification/NotificationProvider";
import { loginApiCalling } from "Service/Utilities/apiCalling";
import { auth } from "Service/Endpoints/endpoints";
import { useNavigate } from "react-router-dom";
import { setSessionStorage } from "Service/auth/auth";
import { LOGIN_APP_TYPE } from "Configration/Utilities/Constants/Constants";

function LoginFormLogic() {
  const navigate = useNavigate();
  const setAlertMessage = AlertMessage();

  const [formValues, setFormValues] = useState({
    txtUsername: "",
    txtPassword: "",
    captcha: "", // Add a captcha field to your formValues
  });

  const searchByHandleKeyDown = (e, func) => {
    if (e.keyCode === 13) {
      func();
    }
  };

  const [validationFormError, setValidationFormError] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const validateField = (name, value) => {
    let errorsMsg = "";
    if (name === "txtUsername") {
      if (!value || typeof value === "undefined") {
        errorsMsg = "Username Cannot be empty";
      }
    } else if (name === "txtPassword") {
      if (!value || typeof value === "undefined") {
        errorsMsg = "Password Cannot be empty";
      }
    }
    return errorsMsg;
  };

  const handleValidation = () => {
    try {
      const errors = {};
      let formIsValid = true;
      errors["txtUsername"] = validateField("txtUsername", formValues.txtUsername);
      errors["txtPassword"] = validateField("txtPassword", formValues.txtPassword);
      if (Object.values(errors).join("").toString()) {
        formIsValid = false;
      }
      setValidationFormError(errors);
      return formIsValid;
    } catch (error) {
      setAlertMessage({ open: true, type: "error", msg: "Something went wrong!" });
      return false;
    }
  };

  const updateFormState = (name, value) => {
    if (!isLoading) {
      validationFormError[name] = validateField(name, value);
      setFormValues((values) => ({
        ...values,
        [name]: value,
      }));
    }
  };

  const handleSave = async () => {
    if (!handleValidation()) {
      return;
    }

    // Check if the CAPTCHA has been completed
    if (!formValues.captcha) {
      setAlertMessage({
        type: "error",
        message: "Please complete the CAPTCHA.",
      });
      return;
    }

    try {
      setIsLoading(true);
      const formData = {
        main: {
          appAccessUsername: formValues && formValues.txtUsername ? formValues.txtUsername : null,
          appAccessPWD: formValues && formValues.txtPassword ? formValues.txtPassword : null,
          appType: LOGIN_APP_TYPE,
          recaptchaValue: formValues.captcha,
        },
      };
      const result = await loginApiCalling(formData, auth.login.userLogin);
      setIsLoading(false);
      if (result && result.responseCode === 1) {
        if (!result.responseData) {
          setAlertMessage({
            type: "error",
            message: result.responseMessage,
          });
          return;
        }
        const user = {
          ...result.responseData,
        };
        setSessionStorage("userData", user);
        navigate("/welcome");
        console.log(result && result.responseData, "User Data Received");
      } else {
        setAlertMessage({
          type: "error",
          message: result.responseMessage,
        });
        setFormValues((values) => ({
          ...values,
          txtMobileNumber: "",
        }));
      }
    } catch (error) {
        console.log(error);
        setAlertMessage({
          type: "error",
          message: error,
        });
        setFormValues((values) => ({
          ...values,
          txtMobileNumber: "",
        }));
    }
  };

  return {
    searchByHandleKeyDown,
    formValues,
    updateFormState,
    handleSave,
    isLoading,
    validationFormError,
  };
}

export default LoginFormLogic;
