import GetApiData from "Configration/Utilities/getApiData/GetApiData";
import { AlertMessage } from "Framework/Components/Widgets";
import { useEffect, useState } from "react";
import { getSessionStorage } from "Service/auth/auth";
import { project } from "Service/Endpoints/endpoints";
// import { apiCalling } from "Service/Utilities/apiCalling";

function ObservationsLogic() {
  const setAlertMessage = AlertMessage();
  const user = getSessionStorage("userData");
  const selectedProjectData = getSessionStorage("selctedProject");
  // const [selectedProjectData, setSelectedProjectData] = useState(null);

  // observationGrid State
  const [observationsList, setObservationsList] = useState([]);
  const [isLoadingObeservationList, setisLoadingObeservationList] = useState(false);
  const [searchText, setSearchText] = useState("");

  // grid api state
  const [ObservationGridApi, setObservationGridApi] = useState();

  // toggle state
  const [isShowAddObservationPopUp, setIsShowAddObservationPopUp] = useState(false);

  /* ************************************* Code For GridColumDef start ***************************** */

  const [ObservationColumDef] = useState([
    { field: "ASSETDESC", headerName: "Asset Desc.", width: 180, rowGroup: true, hide: true },
    { field: "ACTION", headerName: "Action", width: 240 },
    { field: "CHECKPOINT", headerName: "Check Point", width: 210 },
    { field: "DAYOFMONTH", headerName: "Day Of Month", width: 140 },
    { field: "DAYOFWEEK", headerName: "Day Of Week", width: 140 },
    { field: "EQUIPMENTNUMBER", headerName: "Equipment Number", width: 160 },
    { field: "FREQUENCY", headerName: "Frequency", width: 120 },
    { field: "GROUPNAME", headerName: "Group Name", width: 120 },
    { field: "INPUTTYPE", headerName: "Input Type", width: 180 },
    { field: "INSCADA", headerName: "Inscada", width: 90 },
    { field: "MONTHLYMAXCOUNT", headerName: "Monthly Max Count", width: 160 },
    { field: "OBSERVATIONTIME", headerName: "Observation Time", width: 140 },
    { field: "ODESCRIPTION", headerName: "Observation Desc.", width: 210 },
    { field: "ODURATION", headerName: "Observation Duration", width: 180 },
    { field: "OLABEL", headerName: "Observation label", width: 160 },
    { field: "OVSERVATIONHEADERID", headerName: "Observation Id", width: 130 },
    { field: "PROJECTDIVISIONID", headerName: "Project Division Id", width: 140 },
    { field: "REFERENCETYPE", headerName: "Reference Type", width: 140 },
    { field: "REF_MAX", headerName: "Ref Max", width: 140 },
    { field: "REF_MIN", headerName: "Ref Min", width: 140 },
    { field: "REPEATTYPE", headerName: "Repeat Type", width: 180 },
    { field: "SAFETYCHECKLIST", headerName: "Safety Check List", width: 180 },
    { field: "STATUSTYPE", headerName: "Status Type", width: 180 },
    { field: "WEEKLYMAXCOUNT", headerName: "Weekly Max Count", width: 180 },
    { field: "WEEKOFMONTH", headerName: "Week of Month", width: 140 },
  ]);

  /* ************************************* Code For GridColumDef end ***************************** */

  /* ************************************* Code For toggle popup start ***************************** */

  const toggleAddObservationPopUp = () => {
    setIsShowAddObservationPopUp(!isShowAddObservationPopUp);
  };

  /* ************************************* Code For toggle popup end ***************************** */

  /* ************************************* Code For OnGridRady Function Start ***************************** */

  const onObservationGridReady = (params) => {
    setObservationGridApi(params.api);
  };

  /* ************************************* Code For OnGridRady Function end ***************************** */

  const onChangeSearchText = (val) => {
    setSearchText(val);
    ObservationGridApi.setQuickFilter(val);
    ObservationGridApi.refreshCells();
  };

  const cleareSerchText = (data) => {
    if (data) {
      if (searchText && searchText.toLowerCase().includes("#")) {
        onChangeSearchText("");
      }
    }
  };
  /* ************************************* Code For Api Calling Start ***************************** */

  const getObservationListing = async () => {
    const formData = {
      main: {
        divisionid: selectedProjectData && selectedProjectData.DIVISIONIDs ? selectedProjectData.DIVISIONIDs : "",
        profileid: user && user.UserProfileID ? user.UserProfileID : null,
        projectid: selectedProjectData && selectedProjectData.PROJECTID ? selectedProjectData.PROJECTID : "",
      },
    };
    const request = {
      setDatalist: setObservationsList,
      setLoad: setisLoadingObeservationList,
      requestData: formData,
      apiPath: project.projectList.ObservationSelect,
      setAlert: setAlertMessage,
      fun: cleareSerchText,
    };
    GetApiData(request);
  };

  // const getObservationListing = async () => {
  //   try {
  //     setisLoadingObeservationList(true);
  //     const formData = {
  //       main: {
  //         divisionid:
  //           user && user.UserProfileID
  //             ? user.UserProfileID.toString() === "100301" || user.UserProfileID.toString() === "100302"
  //               ? selectedProjectData && selectedProjectData.DIVISIONIDs
  //                 ? selectedProjectData.DIVISIONIDs
  //                 : ""
  //               : ""
  //             : "",
  //         profileid: user && user.UserProfileID ? user.UserProfileID : null,
  //         projectid: selectedProjectData && selectedProjectData.PROJECTID ? selectedProjectData.PROJECTID : "",
  //       },
  //     };
  //     const result = await apiCalling(formData, project.projectList.ObservationSelect);

  //     setisLoadingObeservationList(false);
  //     if (result && result.responseCode === 1) {
  //       if (result && result.responseData && result.responseData.Table && result.responseData.Table.length > 0) {
  //         setObservationsList(result.responseData.Table);

  //         console.log(result.responseData.Table);
  //       }
  //     } else {
  //       setAlertMessage({
  //         type: "error",
  //         message: result.responseMessage,
  //       });
  //       setObservationsList([]);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //     setAlertMessage({
  //       type: "error",
  //       message: error,
  //     });
  //     setObservationsList([]);
  //   }
  // };
  /* ************************************* Code For Api Calling end ***************************** */

  useEffect(() => {
    getObservationListing();
  }, []);

  return {
    observationsList,
    isLoadingObeservationList,
    ObservationColumDef,
    toggleAddObservationPopUp,
    isShowAddObservationPopUp,
    setObservationsList,
    getObservationListing,
    onObservationGridReady,
    ObservationGridApi,
    onChangeSearchText,
    searchText,
  };
}

export default ObservationsLogic;
