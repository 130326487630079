import React, { useEffect, useState } from "react";
import BizClass from "./Aoh.module.scss"
import { DataGrid, PageBar } from "Framework/Components/Layout";
import { getSessionStorage } from "Service/auth/auth";
import { Loader } from "Framework/Components/Widgets";
import axios from "../../../../Configration/axios/axios";
import { project } from "Service/Endpoints/endpoints";
import Modal from "Framework/Components/Layout/Modal/Modal";
import Form from "Framework/Components/Layout/FormGroup/FormGroup";
import { ImEye } from "react-icons/im";
import { Button } from "Framework/Components/Widgets";



function PMlist() {
    const user = getSessionStorage("userData");
    const [isloadingDefectList, setisloadingDefectList] = useState(false);
    const [aohdata, setaohData] = useState([]);
    const [gridApi, setGridApi] = useState();
    const [searchText, setSearchText] = useState("");
    const [selectedDefectDetail, setSelectedDefectDetail] = useState([]);
    const [isDefectDetailModalOpen, setisDefectDetailModalOpen] = useState(false);


    console.log("user", user);
    const fetchData = () => {
        setisloadingDefectList(true);
        axios.get(`${project.projectList.GetDefectByEmpIdForWeb}?EmpID=${user.UserName}`).then((response) => {
            const responseData = response.data.responseDynamic;
            const aohData = responseData.filter((list) => list.defectStatusText === "AOH");
            console.log("responseData", aohData);
            setaohData(aohData)
        }).catch((error) => {

            console.error("Error fetching data:", error);
        }).finally(() => {
            setisloadingDefectList(false);
        });
    };

    const onGridReady = (params) => {
        setGridApi(params.api);
    };
    const onChangeSearchText = (val) => {
        setSearchText(val);
        gridApi.setQuickFilter(val);
        gridApi.refreshCells();
    };

    const cleareSerchText = (data) => {
        if (data) {
            if (searchText && searchText.toLowerCase().includes("#")) {
                onChangeSearchText("");
            }
        }
    };

    const cellTemplate = (params) => {
        const style = {
            display: "flex",
            fontSize: "16px",
            background: "transparent",
            border: "0px solid transparent",
            padding: "4px 0px 5px 0px",
            cursor: "pointer",
        };

        const onTeamMemberListClick = () => {
            if (params) {
                setSelectedDefectDetail(params.data);
                setisDefectDetailModalOpen(true);
            }
        };
        return (
            <div style={{ display: "flex", gap: "8px", justifyContent: "center" }}>
                <button type="button" style={style} title="View Detail" onClick={() => onTeamMemberListClick()}>
                    <ImEye style={{ color: "rgb(52, 73, 94)" }} />
                </button>
            </div>
        );
    }

    const [ListViewColumDef] = useState([
        { headerName: "Sr. No.", field: "#", width: 80, valueGetter: "node.rowIndex + 1" },
        { field: "defectId", headerName: "Defect ID", width: 120 },
        { field: "defectStatusText", headerName: "Status", width: 100 },
        { field: "unitname", headerName: "Unit Name", width: 150 },
        { field: "childasseT_ID", headerName: "Asset Id", width: 100 },
        { field: "childasseT_NAME", headerName: "Asset Name", width: 250 },
        { field: "prioritypE_NAME", headerName: "Priority", width: 150 },
        { field: "defectloggedbY_EMPLOYEENAME", headerName: "Defect Logged By", width: 150 },
        { field: 'defectloggedon', headerName: "Defect Logged On", width: 150 },
        { field: "attendingdivname", headerName: "Attending Division", width: 150 },
        {
            headerName: "Action",
            field: "#",
            width: 80,
            pinned: "left",
            cellRenderer: cellTemplate,
            cellRendererParams: {
                setSelectedDefectDetail,
            },
        },
    ]);

    const closeDefectDetailsModal = () => {
        setisDefectDetailModalOpen(false)
    }

    useEffect(() => {
        fetchData();
    }, [])

    return (
        <div className={BizClass.PageStart}>
            <div className={BizClass.Card}>
                <PageBar className={BizClass.CustomPageBar}>
                    <span>AOH List</span>
                    <PageBar.Button onClick={() => fetchData()} style={{ margin: '3px' }}>Refresh</PageBar.Button>
                </PageBar>
                <DataGrid rowData={aohdata}
                    columnDefs={ListViewColumDef}
                    loader={isloadingDefectList ? <Loader /> : null}
                    onGridReady={onGridReady} />
                {isDefectDetailModalOpen && selectedDefectDetail && (
                    <Modal varient="center"
                        title="Defect Details"
                        index={2}
                        show={closeDefectDetailsModal}
                        width="1000px"
                        height="800px">
                        <Modal.Body>
                            <div>
                                <Form>
                                    <>
                                        <Form.Group column={2} controlwidth="240px">
                                            <Form.InputGroup label="" column={2}>
                                                <h6>Defect Details</h6>
                                            </Form.InputGroup>
                                            <Form.InputGroup label="" column={2}>
                                            </Form.InputGroup>
                                            <Form.InputGroup label="Defect ID">
                                                <Form.InputControl
                                                    control="input"
                                                    name="txtDefectId"
                                                    maxLength="200"
                                                    value={selectedDefectDetail.defectId}
                                                    readOnly
                                                />
                                            </Form.InputGroup>
                                            <Form.InputGroup label="Status">
                                                <Form.InputControl
                                                    control="input"
                                                    name="txtStatus"
                                                    maxLength="200"
                                                    value={selectedDefectDetail.defectStatusText}
                                                    readOnly
                                                />
                                            </Form.InputGroup>
                                            <Form.InputGroup label="Asset ID">
                                                <Form.InputControl
                                                    control="input"
                                                    name="txtAssetId"
                                                    maxLength="200"
                                                    value={selectedDefectDetail.childasseT_ID}
                                                    readOnly
                                                />
                                            </Form.InputGroup>
                                            <Form.InputGroup label="Asset Name">
                                                <Form.InputControl
                                                    control="input"
                                                    name="txtAssetName"
                                                    maxLength="200"
                                                    value={selectedDefectDetail.childasseT_NAME}
                                                    readOnly
                                                />
                                            </Form.InputGroup>
                                            <Form.InputGroup label="Unit ID">
                                                <Form.InputControl
                                                    control="input"
                                                    name="frequencyType"
                                                    maxLength="200"
                                                    value={selectedDefectDetail.unitname}
                                                    readOnly
                                                />
                                            </Form.InputGroup>

                                            <Form.InputGroup label="Priority">
                                                <Form.InputControl
                                                    control="input"
                                                    name="txtPriority"
                                                    maxLength="200"
                                                    value={selectedDefectDetail.prioritypE_NAME}
                                                    readOnly
                                                />
                                            </Form.InputGroup>
                                            <Form.InputGroup label="Defect Type">
                                                <Form.InputControl
                                                    control="input"
                                                    name="txtPriority"
                                                    maxLength="200"
                                                    value={selectedDefectDetail.defecttypE_NAME}
                                                    readOnly
                                                />
                                            </Form.InputGroup>
                                            <Form.InputGroup label="Description">
                                                <Form.InputControl
                                                    control="input"
                                                    name="txtDescription"
                                                    maxLength="200"
                                                    value={selectedDefectDetail.description}
                                                    readOnly
                                                />
                                            </Form.InputGroup>

                                            <Form.InputGroup label="Defect Logged By">
                                                <Form.InputControl
                                                    control="input"
                                                    name="txtLoggedBy"
                                                    maxLength="200"
                                                    value={`${selectedDefectDetail.defectloggedbY_EMPLOYEENAME} (${selectedDefectDetail.defectloggedbY_EMPLOYEEID})`}

                                                    readOnly
                                                />
                                            </Form.InputGroup>

                                            <Form.InputGroup label="Defect Logged Division">
                                                <Form.InputControl
                                                    control="input"
                                                    name="txtLoggedBy"
                                                    maxLength="200"
                                                    value={selectedDefectDetail.defecT_LOGGEDBY_DIVNAME}
                                                    readOnly
                                                />
                                            </Form.InputGroup>

                                            <Form.InputGroup label="Defect Logged Date and Time">
                                                <Form.InputControl
                                                    control="input"
                                                    name="txtLoggedDate"
                                                    maxLength="200"
                                                    value={selectedDefectDetail.defectloggedon}
                                                    readOnly
                                                />
                                            </Form.InputGroup>
                                            <Form.InputGroup label="Attending Division">
                                                <Form.InputControl
                                                    control="input"
                                                    name="txtAttendingDivisionName"
                                                    maxLength="200"
                                                    value={selectedDefectDetail.attendingdivname}
                                                    readOnly
                                                />
                                            </Form.InputGroup>

                                            <Form.InputGroup label="Defect Document" className="d-flex">
                                                <Button>Defect Documents</Button>

                                            </Form.InputGroup>

                                            <Form.InputGroup label="" column={2}>
                                            </Form.InputGroup>

                                            <Form.InputGroup label="" column={2}>
                                                <h6>Acknowledge Details</h6>
                                            </Form.InputGroup>

                                            <Form.InputGroup label="" column={2}>
                                            </Form.InputGroup>
                                            <Form.InputGroup label="Acknowledged Employee Name">
                                                <Form.InputControl
                                                    control="input"
                                                    name="txtDescription"
                                                    maxLength="200"
                                                    value={`${selectedDefectDetail.acknowledgedbY_EMPLOYEENAME} (${selectedDefectDetail.acknowledgeD_BY})`}
                                                    readOnly
                                                />
                                            </Form.InputGroup>

                                            {/* <Form.InputGroup label="Acknowledged Employee Code">
                      <Form.InputControl
                        control="input"
                        name="txtDescription"
                        maxLength="200"
                        value={selectedDefectDetail.acknowledgeD_BY}
                        readOnly
                      />
                    </Form.InputGroup> */}

                                            <Form.InputGroup label="Acknowledged Division">
                                                <Form.InputControl
                                                    control="input"
                                                    name="txtDescription"
                                                    maxLength="200"
                                                    value={selectedDefectDetail.acknowledgE_BY_DIV_NAME}
                                                    readOnly
                                                />
                                            </Form.InputGroup>

                                            <Form.InputGroup label="Acknowledged Date and Time">
                                                <Form.InputControl
                                                    control="input"
                                                    name="txtDescription"
                                                    maxLength="200"
                                                    value={selectedDefectDetail.acknowldegetime}
                                                    readOnly
                                                />
                                            </Form.InputGroup>
                                            <Form.InputGroup label="" column={2}>
                                            </Form.InputGroup>

                                            <Form.InputGroup label="" column={2}>
                                                <h6>Assignee Details</h6>
                                            </Form.InputGroup>

                                            <Form.InputGroup label="" column={2}>
                                            </Form.InputGroup>
                                            <Form.InputGroup label="Assigned By Employee Name">
                                                <Form.InputControl
                                                    control="input"
                                                    name="txtDescription"
                                                    maxLength="200"
                                                    value={`${selectedDefectDetail.workassigneD_BY_EMP_NAME} (${selectedDefectDetail.workassigneD_BY_EMP})`}
                                                    readOnly
                                                />
                                            </Form.InputGroup>

                                            {/* <Form.InputGroup label="Assigned By Employee Code">
                          <Form.InputControl
                            control="input"
                            name="txtDescription"
                            maxLength="200"
                            value={selectedDefectDetail.defectloggedbY_EMPLOYEEID}
                            readOnly
                          />
                        </Form.InputGroup> */}

                                            <Form.InputGroup label="Assigned By Employee Division">
                                                <Form.InputControl
                                                    control="input"
                                                    name="txtDescription"
                                                    maxLength="200"
                                                    value={selectedDefectDetail.workassigneD_BY_DIVISIONNAME}
                                                    readOnly
                                                />
                                            </Form.InputGroup>

                                            <Form.InputGroup label="Assigned to Employee Name">
                                                <Form.InputControl
                                                    control="input"
                                                    name="txtDescription"
                                                    maxLength="200"
                                                    value={`${selectedDefectDetail.workassignedtO_EMPLOYEENAME}  (${selectedDefectDetail.workassignedtO_EMPLOYEEID})`}
                                                    readOnly
                                                />
                                            </Form.InputGroup>

                                            {/* <Form.InputGroup label="Assigned to Employee Code">
                          <Form.InputControl
                            control="input"
                            name="txtDescription"
                            maxLength="200"
                            value={selectedDefectDetail.defectloggedbY_EMPLOYEEID}
                            readOnly
                          />
                        </Form.InputGroup> */}

                                            <Form.InputGroup label="Assigned to Employee Division">
                                                <Form.InputControl
                                                    control="input"
                                                    name="txtDescription"
                                                    maxLength="200"
                                                    value={selectedDefectDetail.workassigneD_TO_DIVISIONNAME}
                                                    readOnly
                                                />
                                            </Form.InputGroup>

                                            <Form.InputGroup label="Assigner Remarks">
                                                <Form.InputControl
                                                    control="input"
                                                    name="txtDescription"
                                                    maxLength="200"
                                                    value={selectedDefectDetail.assigneE_REMARKS}
                                                    readOnly
                                                />
                                            </Form.InputGroup>

                                            <Form.InputGroup label="" column={2}>
                                            </Form.InputGroup>

                                            <Form.InputGroup label="" column={2}>
                                                <h6>AOH Details</h6>
                                            </Form.InputGroup>

                                            <Form.InputGroup label="" column={2}>
                                            </Form.InputGroup>
                                            <Form.InputGroup label="AOH Status">
                                                <Form.InputControl
                                                    control="input"
                                                    name="aoh"
                                                    maxLength="200"
                                                    value={selectedDefectDetail.aoH_STATUS}
                                                    readOnly
                                                />
                                            </Form.InputGroup>
                                            <Form.InputGroup label="AOH Status Remarks">
                                                <Form.InputControl
                                                    control="input"
                                                    name="aoh"
                                                    maxLength="200"
                                                    value={selectedDefectDetail.amoH_REMARKS}
                                                    readOnly
                                                />
                                            </Form.InputGroup>








                                        </Form.Group>
                                    </>
                                </Form>
                            </div>
                        </Modal.Body>
                    </Modal>
                )}
            </div>
        </div>
    );
}

export default PMlist;
