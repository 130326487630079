import React, { useEffect, useState } from "react";
import { project } from "Service/Endpoints/endpoints";
import { PageBar } from "Framework/Components/Layout";
import { DataGrid } from "Framework/Components/Layout";
import axios from "../../../../Configration/axios/axios";
import { getSessionStorage } from "Service/auth/auth";
import BizClass from "./DefectList.module.scss";
import { Loader } from "Framework/Components/Widgets";
import { IoMdAdd } from "react-icons/io";
import { IoMdCreate } from "react-icons/io";
import Modal from "Framework/Components/Layout/Modal/Modal";
import Form from "Framework/Components/Layout/FormGroup/FormGroup";
import { Button } from "Framework/Components/Widgets";
import { AlertMessage } from "Framework/Components/Widgets";
import GetApiData from "Configration/Utilities/getApiData/GetApiData";
import { ImEye } from "react-icons/im";
import 'bootstrap/dist/css/bootstrap.min.css';
import Multiselect from 'multiselect-react-dropdown';
import { Buffer } from "buffer";
import Select from 'react-select';
import {
  PROJECT_DETAIL_PTW_LIST,
} from "Configration/Utilities/Constants/Constants";
import PTWList from "../PTW/ptw"
import ProjectDetailLogic from "../ProjectDetail/Logic/ProjectDetailLogic";


const pako = require("pako");


const MyComponent = () => {
  const { activeProjectDetailsTab, setActiveProjectDetailsTab } = ProjectDetailLogic();

  const [data, setData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchText, setSearchText] = useState("");
  const setAlertMessage = AlertMessage();
  const [selectedDefectDetail, setSelectedDefectDetail] = useState([]);
  const [isloadingDefectList, setisloadingDefectList] = useState(false);
  const selectedProjectData = getSessionStorage("selctedProject");
  const user = getSessionStorage("userData");
  const [unitValue, setUnitValue] = useState([]);
  const [formunitValue, setformunitValue] = useState([]);
  const [childAsset, setChildAsset] = useState([]);
  const [divisionName, setdivisionName] = useState([]);
  const [reason, setReason] = useState([]);
  const [priority, setpriority] = useState([]);
  const [defectType, setdefectType] = useState([]);
  const [description, setdescription] = useState('');
  const [searchDivision, setSearchDivision] = useState([]);
  const [searchEquipment, setSearchEquipment] = useState([]);
  const [searchPtw, setsearchPtw] = useState([]);
  const [isolation, setisolation] = useState([]);
  const [line, setline] = useState([]);
  const [gridApi, setGridApi] = useState();
  const [selectedFromDate, setselectedFromDate] = useState([]);
  const [selectedToDate, setselectedToDate] = useState([]);
  const [priorityData, setpriorityData] = useState({});
  const [defectData, setDefectData] = useState({});
  const [divisionList, setDivisionList] = useState([]);
  const [isLoadingDivisionList, setIsLoadingDivisionList] = useState(false);
  const [isDefectDetailModalOpen, setisDefectDetailModalOpen] = useState(false);
  const [isImage, setIsImage] = useState(null);
  const [showDivertAssignButtons, sethandleAcknowledge] = useState(false);
  const [ishandleDivert, setHandleDivert] = useState(false)
  const [ishandleDivertModal, setHandleDivertModal] = useState(false)
  const [assignDivert, setAssignDivert] = useState(false)
  const [handleAssignModal, setHandleAssignModal] = useState(false)
  const [assigneeRemarks, setAssigneeRemarks] = useState(false)
  const [isHandleGeneral, setHandleGeneral] = useState(false)
  const [isHandleAOH, setHandleAOH] = useState(false)
  const [isHandleMOH, setHandleMOH] = useState(false)
  const [isHandlePTW, setHandlePTW] = useState(false)
  const [isHandleHazardoes, setHandleHazardoes] = useState(false)
  const [isEquipment, setisEquipment] = useState(false)
  const [isIsolation, setisIsolation] = useState(false)

  const [editedRowData, setEditedRowData] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);


  const [selectedDefectId, setselectedDefectId] = useState([]);
  const [selectedDefectLoggedOn, setselectedDefectLoggedOn] = useState([]);
  const [selectedDefectStatus, setselectedDefectStatus] = useState([]);
  const [selectedDefectUnitName, setselectedDefectUnitName] = useState([]);
  const [selectedDefectAssetId, setselectedDefectAssetId] = useState([]);
  const [selectedDefectPriority, setselectedDefectPriority] = useState([]);
  const [selectedDefectLoggedBy, setselectedDefectLoggedBy] = useState([]);
  const [selectedDefectAttendedBy, setselectedDefectAttendedBy] = useState([]);
  const [frequencyOptions, setUnitOptions] = useState([])
  const [reasonsDropDown, setReasonsDropdown] = useState({})
  const [empIdDefectDetails, setEmpIDDefectDetails] = useState([]);
  const [documents, setDocuments] = useState(false);



  const [isolationInputs, setIsolationInputs] = useState(['']);
  const [equipmentInputs, setEquipmentInputs] = useState(['']);
  const [hazardosInputs, setHazardosInputs] = useState(['']);
  const [assetDetails, setAssetDetails] = useState([]);

  const [attendingDivision, setAttendingDivisionName] = useState({});
  const [teamsListMembersData, setTeamsListMembersData] = useState([]);
  const [isLoadingTeamsMemberData, setIsLoadingTeamsMemberData] = useState(false);
  const [isImag, setImage] = useState([]);


  const [attendData, setAttenedData] = useState([])


  console.log("teamsListMembersData", teamsListMembersData)

  const getTeamsDetailListing = () => {
    const formData = {
      main: {
        divisionid: user.DIVISION_ID.toString(),
        profileid: parseInt(user.UserProfileID),
        projectid: user.PROJECT_ID,
      },
    };

    const request = {
      setDatalist: setTeamsListMembersData,
      setLoad: setIsLoadingTeamsMemberData,
      requestData: formData,
      apiPath: project.projectList.GetKPCLTeam,
      setAlert: setAlertMessage,
      fun: null,
    };
    GetApiData(request);
  };



  const handleIsolationInput = () => {
    setIsolationInputs([...isolationInputs, '']);
  };

  const handleEquipmentInput = () => {
    setEquipmentInputs([...equipmentInputs, '']);
  };

  const handleHazardosInput = () => {
    setHazardosInputs([...hazardosInputs, '']);
  };

  useEffect(() => {
    if (editedRowData) {
      setselectedDefectId(editedRowData.defectId);
      setselectedDefectLoggedOn(editedRowData.defectLoggedOn);
      setselectedDefectStatus(editedRowData.statusOfDefect);
      setselectedDefectUnitName(editedRowData.unitname);

      setselectedDefectAssetId(editedRowData.childasseT_ID);
      setselectedDefectPriority(editedRowData.priority);
      setselectedDefectLoggedBy(editedRowData.defectloggedbY_EMPLOYEENAME);
      setselectedDefectAttendedBy(editedRowData.attendingdivisionid);

    }
  }, [editedRowData]);

  const getAttendInfo = (defectId) => {
    const formData = {
      defectid: defectId
    }
    axios.post(`${project.projectList.GetAttendInfo}`, formData).then((response) => {
      console.log("Attended Response", response)
      const attendedData = JSON.parse(response.data.responseDynamic);
      console.log("attendedData", attendedData)
      setAttenedData(attendedData)
      console.log("attendData", attendData)
    })
  }




  const cellTemplate = (params) => {
    const style = {
      display: "flex",
      fontSize: "16px",
      background: "transparent",
      border: "0px solid transparent",
      padding: "4px 0px 5px 0px",
      cursor: "pointer",
    };

    const onTeamMemberListClick = () => {
      if (params) {
        setSelectedDefectDetail(params.data);
        getAttendInfo(params.data.defectId);
        getPTWStatus(params.data.defectId)
        setisDefectDetailModalOpen(true);
        getWorkDoneDetails(params.data.defectId);
        // getIndividualDefectDetails(params.data.defectId);
      }
    };
    return (
      <div style={{ display: "flex", gap: "8px", justifyContent: "center" }}>
        <button type="button" style={style} title="View Detail" onClick={() => onTeamMemberListClick()}>
          <ImEye style={{ color: "rgb(52, 73, 94)" }} />
        </button>
      </div>
    );
  }

  const EditCellRenderer = (props) => {
    return (
      <div>
        <IoMdCreate
          onClick={() => {
            console.log("Edit clicked for row with data:", props.data);
            setEditedRowData(props.data);
            setIsEditModalOpen(true);
          }}
        />
      </div>
    );
  };

  // const [getIndividualDefectData, setIndividualDefectData] = useState([])
  // const getIndividualDefectDetails = (defectId) => {
  //   const formData = {
  //     DefectID: defectId
  //   }
  //   axios.get(`${project.projectList.GetDefectInfoByDefectID_ForWeb}`, formData).then((response) => {
  //     const data = response.data.responseDynamic
  //     const jsonData = JSON.parse(data)
  //     const defectData = jsonData.Table[0]
  //     setIndividualDefectData(defectData)
  //     console.log("getIndividualDefectDetails", defectData)
  //   })


  // }

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };


  const [ListViewColumDef] = useState([
    { headerName: "Sr. No.", field: "#", width: 80, valueGetter: "node.rowIndex + 1" },
    { field: "defectId", headerName: "Defect ID", width: 120 },
    { field: "defectStatusText", headerName: "Status", width: 100 },
    { field: "unitname", headerName: "Unit Name", width: 150 },
    { field: "childasseT_ID", headerName: "Asset Id", width: 100 },
    { field: "childasseT_NAME", headerName: "Asset Name", width: 250 },
    { field: "prioritypE_NAME", headerName: "Priority", width: 150 },
    { field: "defectloggedbY_EMPLOYEENAME", headerName: "Defect Logged By", width: 150 },
    { field: 'defectloggedon', headerName: "Defect Logged On", width: 150 },
    { field: "attendingdivname", headerName: "Attending Division", width: 180 },
    {
      headerName: "Action",
      field: "#",
      width: 80,
      pinned: "left",
      cellRenderer: cellTemplate,
      cellRendererParams: {
        setSelectedDefectDetail,
      },
    },


  ]);




  // const frequencyOptions = ["Unit-1", "Unit-2", "Unit-3", "Unit-4", "Unit-5", "Unit-6", "Unit-7", "Unit-8"];
  const reasonOptions = ["Appreance", "Breakdown", "Comfort", "Component Failure"];

  console.log("selectedProjectData", selectedProjectData);

  const fetchData = () => {
    // Fetch ProjectID and DivisionID from sessionStorage

    setisloadingDefectList(true);
    // const formData = {
    //   employeeID: user.UserName,
    //   flg: 1
    // }
    // axios
    //   .post(`${project.projectList.GetDefectByEmpId}`, formData)
    //   .then((response) => {
    //     const responseData = response.data.responseDynamic;
    //     console.log("responseData", responseData);
    //     if (Array.isArray(responseData)) {
    //       setData(responseData);
    //     } else {
    //       console.error("Response data is not an array:", responseData);
    //     }
    //   })
    //   .catch((error) => {

    //     console.error("Error fetching data:", error);
    //   }).finally(() => {
    //     setisloadingDefectList(false);
    //   });
    axios.get(`${project.projectList.GetDefectByEmpIdForWeb}?EmpID=${user.UserName}`).then((response) => {
      const responseData = response.data.responseDynamic;
      console.log("responseData", responseData);
      const filteredRows = responseData.filter(row => row.vieW_STATUS !== 0);
      setData(filteredRows)
    }).catch((error) => {

      console.error("Error fetching data:", error);
    }).finally(() => {
      setisloadingDefectList(false);
    });
  };

  const exportToCSV = () => {
    const headers = Object.keys(data[0]);

    const csvContent = "data:text/csv;charset=utf-8," +
      headers.join(",") + "\n" +
      data.map(row => headers.map(header => row[header]).join(",")).join("\n");
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "DefectList.csv");
    document.body.appendChild(link);
    link.click();
  };

  const toggeMOdal = () => {
    setIsModalOpen(true)
  }

  const toggleAddDefectPopUp = () => {
    setIsModalOpen(false)
  }

  const handleInputChange = (e, field) => {
    const newValue = e.target.value;
    switch (field) {
      case "Description":
        setdescription(newValue);
        break;
      case "Image":
        setFile(e.target.files[0]);
        break;
      default:
        break;
    }
  };



  const onGridReady = (params) => {
    setGridApi(params.api);
  };
  const onChangeSearchText = (val) => {
    setSearchText(val);
    gridApi.setQuickFilter(val);
    gridApi.refreshCells();
  };

  const cleareSerchText = (data) => {
    if (data) {
      if (searchText && searchText.toLowerCase().includes("#")) {
        onChangeSearchText("");
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const GetPriorityTypeInfo = () => {
    axios.get(`${project.projectList.GetPriorityTypeInfo}`).then((response) => {
      console.log("Priorityresponse", response)
      const priority = response.data.responseDynamic;
      setpriorityData(priority);
      console.log("priorityData", priorityData)

    })
  }

  const GetDefectTypeInfo = () => {
    axios.get(`${project.projectList.GetDefectTypeInfo}`).then((response) => {
      console.log("Defectresponse", response);
      const defect = response.data.responseDynamic;
      setDefectData(defect);
    })
  }

  const getDivisionDetailsList = async () => {
    const formData = {
      main: {
        divisionid: selectedProjectData && selectedProjectData.DIVISIONIDs ? selectedProjectData.DIVISIONIDs : "",
        profileid: user && user.UserProfileID ? user.UserProfileID : null,
        projectid: selectedProjectData && selectedProjectData.PROJECTID ? selectedProjectData.PROJECTID : "",
      },
    };

    const request = {
      setDatalist: setDivisionList,
      setLoad: setIsLoadingDivisionList,
      requestData: formData,
      apiPath: project.projectList.GetKPCLDivisionDetail,
      setAlert: setAlertMessage,
      fun: null,
    };
    GetApiData(request);
  };

  useEffect(() => {
    GetPriorityTypeInfo();
    GetDefectTypeInfo();
    getDivisionDetailsList();
  }, []);

  console.log("divisionList", divisionList)
  const getAssetListingData = () => {
    const formData = {
      main: {
        DivisionID: selectedProjectData.DIVISIONIDs
      },
    };
    console.log("formData", formData)
    const request = {
      setDatalist: setData,
      setLoad: setisloadingDefectList,
      requestData: formData,
      apiPath: project.projectList.GetDefectDetails,
      setAlert: setAlertMessage,
      fun: cleareSerchText,
    };
    GetApiData(request);
  };


  const updateFormState = (e, field) => {
    if (field && e !== null) {
      const newValue = e;
      console.log("e", e)
      setformunitValue(newValue);
      onChangeSearchText(newValue.value)
    }

  };

  const handleSearchInputChange = (e, field) => {
    const newValue = e.target.value;

    switch (field) {
      case "DIVISION":
        setSearchDivision(newValue);
        onChangeSearchText(newValue)
        break;
      case "EQUIPMENTNUMBER":
        setSearchEquipment(newValue);
        onChangeSearchText(newValue)
        break;
      case "PTWSTATUS":
        setsearchPtw(newValue);
        onChangeSearchText(newValue)
        break;
      case "LINE":
        setline(newValue);
        onChangeSearchText(newValue)
        break;
      case "FROMDATE":
        setselectedFromDate(newValue);
        onChangeSearchText(newValue)
        break;
      case "TODATE":
        setselectedToDate(newValue);
        onChangeSearchText(newValue)
        break;
      case "ISOLATION":
        setisolation(newValue);
        onChangeSearchText(newValue)
        break;
      default:
        break;
    }
  };


  const addDefectState = (e, field) => {
    const newValue = e;
    switch (field) {
      case "frequencyType":
        setUnitValue(newValue);
        break;
      case "DivisionName":
        setdivisionName(newValue);
        break;
      case "Reason":
        setReason(newValue);
        break;
      case "Priority":
        setpriority(newValue);
        break;
      case "DefectType":
        setdefectType(newValue);
        break;
      default:
        break;
    }
  }

  const closeEditModal = () => {
    setIsEditModalOpen(false);
  };

  const [file, setFile] = useState()
  // const saveChanges = async () => {
  //   console.log("divisionName", divisionName)
  //   const formData = {
  //     defectIDD: 0,
  //     unitID: unitValue.value,
  //     priorityID: priority.value,
  //     defectTypeID: defectType.value,
  //     description: description,
  //     projectID: selectedProjectData.PROJECTID,
  //     divisionID: parseInt(selectedProjectData.DIVISIONIDs),
  //     reason: reason.label.toString(),
  //     childasset: childAsset.value.toString(),
  //     loggeddBy: parseInt(user.UserName),
  //     status: 1,
  //     attendingdivisionid: divisionName.map((name) => JSON.stringify(name.divisionID))
  //   }
  //   console.log("formData", formData)

  //   const imageData = {
  //     FileDetails: file,
  //     DefectID: selectedDefectDetail.defectId,
  //     Remarks: description,
  //     ATTENDEDBY: user.UserName
  //   }

  //   try {
  //     const response = await axios.post(
  //       `${project.projectList.RaiseMultiDivisionDefect}`,
  //       formData,
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //       }
  //     );

  //     const imageResponse = await axios.post(`${project.projectList.PostSingleFile}`, imageData, {
  //       headers: {
  //         "Content-Type": "multipart/form-data",
  //       },
  //     })
  //     console.log("imageResponse", imageResponse)
  //     console.log("API Response:", response.data);
  //     if (response.data.responseCode === "1") {
  //       alert("Defect Raised Successfully!")
  //       setIsModalOpen(false)
  //       fetchData();
  //     } else {
  //       alert("Failed to Submit Data, Please try again !")
  //     }
  //   } catch (error) {
  //     console.error("Error in saveChanges:", error);
  //   }
  // };

  const [raiseDefectLoader, setRaiseDefectLoader] = useState(false)
  const [generateDefectId, setGenerateDefectId] = useState(null)
  const [generatePTWDirectly, setGeneratePTWDirectly] = useState(null)
  const saveChanges = async () => {
    console.log("divisionName", divisionName);
    setRaiseDefectLoader(true)

    if (defectType.value === 8 && ptwaction) {
      if (!unitValue || !priority || !defectType || !childAsset || !reason || !description || !divisionName || !selectedEmployee || !ptwFromDate || !ptwToDate || selectedWorkType.length === 0 || !ptwRemarks) {
        alert("Please fill in all required fields.");
        setRaiseDefectLoader(false)
        return;
      }
    }
    else {
      if (!unitValue || !priority || !defectType || !childAsset || !reason || !description || !divisionName) {
        alert("Please fill in all required fields.");
        setRaiseDefectLoader(false)
        return;
      }
    }






    // Check if divisionName is an array and contains at least one element
    if (!Array.isArray(divisionName) || divisionName.length === 0) {
      alert("Please select at least one attending division.");
      setRaiseDefectLoader(false)
      return;
    }

    if (defectType.value === 8 && ptwaction) {
      const formData = {
        defectIDD: 0,
        unitID: unitValue.value,
        priorityID: priority.value,
        defectTypeID: defectType.value,
        description: description,
        projectID: selectedProjectData.PROJECTID,
        divisionID: parseInt(selectedProjectData.DIVISIONIDs),
        reason: reason.label.toString(),
        childasset: childAsset.value.toString(),
        loggeddBy: parseInt(user.UserName),
        status: 1,
        viewStatus: defectType.value === 8 ? 0 : 1,
        attendingdivisionid: divisionName.map((name) => name.DIVISIONID),
      };
      try {
        const response = await axios.post(
          `${project.projectList.RaiseMultiDivisionDefect}`,
          formData,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (response.data.responseMessage === "Success") {
          // alert(`Defect ID : ${response.data.responseCode} Raised Successfully!`);
          setGenerateDefectId(response.data.responseCode);
          const defectId = response.data.responseCode

          const ptwformData = {
            ptW_IDD: "ptw",
            defecT_IDD: response.data.responseCode,
            issuinG_AUTHORITY: parseInt(user.UserName),
            issuinG_DIVISION: user.PROJECTDIVISIONID,
            froM_DATE: ptwFromDate,
            tO_DATEE: ptwToDate,
            worK_TYPE: selectedWorkType,
            remarks: ptwRemarks,
            attendinG_DIVISIONS: ptwdivisionName.map(data => parseInt(data.DIVISIONID)),
            employeeid: selectedEmployee,
            visibilitY_STATUS: 0,
          };


          axios.post(`${project.projectList.GENERATE_PTW}`, ptwformData).then((response) => {
            console.log("response", response)
            if (response.data.responseCode === "1") {
              setRaiseDefectLoader(false)
              setPTWButtons(true);
              setPTWID(response.data.responseMessage);
              setGeneratePTWDirectly(response.data.responseMessage)
              const PTWID = response.data.responseMessage
              const formData = {
                ptwid: response.data.responseMessage,
                defectid: parseInt(defectId),
                intiateD_FROM: parseInt(user.UserName),
                intiateD_TO: selectedEmployee
              };

              // axios.post(`${project.projectList.Initiate_PTW}`, formData).then((response) => {
              //   if (response.data.responseCode === "1") {
              //     alert(`PTW ID: ${PTWID} Initiated Successfully`);
              //     setRaiseDefectLoader(false)
              //     setIsModalOpen(false);
              //     fetchData();
              //   } else {
              //     alert("Failed to submit, please try again");
              //   }
              // });
            } else {
              alert("Failed to Submit please try again");
            }
          });

        } else {
          console.log("error")
        }


      } catch (error) {
        console.error("Error in saveChanges:", error);
      }

    } else {
      const formData = {
        defectIDD: 0,
        unitID: unitValue.value,
        priorityID: priority.value,
        defectTypeID: defectType.value,
        description: description,
        projectID: selectedProjectData.PROJECTID,
        divisionID: parseInt(selectedProjectData.DIVISIONIDs),
        reason: reason.label.toString(),
        childasset: childAsset.value.toString(),
        loggeddBy: parseInt(user.UserName),
        status: 1,
        viewStatus: 1,
        attendingdivisionid: divisionName.map((name) => name.DIVISIONID),
      };


      console.log("formData", formData);



      try {
        const response = await axios.post(
          `${project.projectList.RaiseMultiDivisionDefect}`,
          formData,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );



        if (response.data.responseMessage === "Success") {
          alert(`Defect ID : ${response.data.responseCode} Raised Successfully!`);
          // const filename = file.split('\\').pop();


          const formData = new FormData();

          // let fileName = file;
          // var image = fileName.name.replace(/[\s.-](?!(png|jpg)$)/g, "_");
          // fileName = image;

          // Append to formData
          formData.append("FileDetails", file);
          formData.append("DefectID", response.data.responseCode)
          formData.append("Remarks", description)
          formData.append("ATTENDEDBY", user.UserName)
          const imageData = {
            FileDetails: formData,
            DefectID: JSON.stringify(response.data.responseCode),
            Remarks: JSON.stringify(description),
            ATTENDEDBY: user.UserName,
          };

          console.log("imageData", formData)
          const imageResponse = await axios.post(
            `${project.projectList.UploadImages}`,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );
          console.log("imageResponse", imageResponse)
          setRaiseDefectLoader(false)
          setIsModalOpen(false);
          fetchData();
        } else {
          alert("Failed to Submit Data. Please try again!");
        }
      } catch (error) {
        console.error("Error in saveChanges:", error);
      }

    }

  };

  const handleMaintenanceInitiate = () => {
    if (hazardoesRemarks.length === 0 || isolationRemarks.length === 0 || equipmentRemarks.length === 0) {
      const proceed = window.confirm("Do you want to proceed without entering data for Hazrdoes, Isolation, and Equipment?");
      if (!proceed) {
        return;
      }
    }


    const formData = {
      ptwid: ptwID,
      defectid: generateDefectId,
      intiateD_FROM: parseInt(user.UserName),
      intiateD_TO: selectedEmployee
    };

    axios.post(`${project.projectList.Initiate_PTW}`, formData).then((response) => {
      if (response.data.responseCode === "1") {
        alert(`PTW ID: ${ptwID} Initiated Successfully`);
        setHandlePTW(false);
        setAssigneeRemarks(false);
        setisDefectDetailModalOpen(false);
        setRaiseDefectLoader(false)
        setIsModalOpen(false);
        fetchData();
      } else {
        alert("Failed to submit, please try again");
      }
    });
  };

  const handleAcknowledge = () => {
    const formData = {
      empId: parseInt(user.UserName),
      defectID: selectedDefectDetail.defectId,
    }

    axios.post(`${project.projectList.AcknowledgeDefectUpdate_ForWeb}`, formData).then((response) => {
      console.log("response", response)
      if (response.data.responseCode === '1') {
        alert("Acknowledged!")
        sethandleAcknowledge(true)
        setisDefectDetailModalOpen(false)
        fetchData();
      }
    })

    const empData = {
      EmpID: user.UserName
    }

    axios.get(`${project.projectList.GetDefectByEmpIdForWeb}`, empData).then((response) => {
      console.log("EMPID response", response);
      const data = response.data.responseDynamic;
      setEmpIDDefectDetails(data);
    })
  }

  const handleDivert = () => {
    setHandleDivertModal(true)
  }

  const handleAssign = () => {
    setHandleAssignModal(true)
  }

  const closeDivertModal = () => {
    setHandleDivertModal(false);
  }

  const closeAssignModal = () => {
    setHandleAssignModal(false);
  }

  const handleAssigneeRemarks = () => {
    setAssigneeRemarks(true)
  }

  const closeAssigneeRemarksModal = () => {
    setAssigneeRemarks(false)
  }

  const handleGeneral = () => {
    setHandleGeneral(true)
  }

  const closeGeneralModal = () => {
    setHandleGeneral(false)
  }

  const handleAOH = () => {
    setHandleAOH(true)
  }

  const closeAOHModal = () => {
    setHandleAOH(false)
  }

  const handleMOH = () => {
    setHandleMOH(true)
  }

  const closeMOHModal = () => {
    setHandleMOH(false)
  }

  const handlePTW = () => {
    setHandlePTW(true)
  }

  const closePTWModal = () => {
    setHandlePTW(false)
    setPTWID(null);
    setptwdivisionName([])
  }

  const handleHazardoes = () => {
    setHandleHazardoes(true)
    var numericPart = ptwID.match(/\d+/);
    getEHIDetailsforHazardoes(parseInt(numericPart))
  }

  const closeHazardoesModal = () => {
    setHandleHazardoes(false)
    setHazardosInputs([])

  }

  const [equipmentView, setEquipmentView] = useState([])
  const getEHIDeatilsforEquipment = (ptw) => {

    axios.get(`${project.projectList.GetEHI_DETAILS_BY_EHI_PTW_FORWEB}?EHIID=2&PTWID=${ptw}`).then((response) => {
      console.log("EHI equipment response", response)
      const data = response.data.responseDynamic
      const jsonData = JSON.parse(data)
      console.log("equipment jsonData", jsonData)
      setEquipmentView(jsonData)
    })
  }

  const handleEquipment = () => {
    setisEquipment(true)
    var numericPart = ptwID.match(/\d+/);
    getEHIDeatilsforEquipment(parseInt(numericPart))

  }
  const closeEquipmentModal = () => {
    setisEquipment(false)
    setEquipmentInputs([])
  }


  const handleIsolation = () => {
    setisIsolation(true)
    var numericPart = ptwID.match(/\d+/);
    getEHIDetailsforIsolation(parseInt(numericPart))
  }

  const handleMaintenanceIsolation = () => {
    setisIsolation(true)
    var numericPart = ptwID.match(/\d+/);
    getEHIDetailsforIsolation(parseInt(numericPart))
  }
  const closeIsolationModal = () => {
    setisIsolation(false)
    setIsolationInputs([])
  }

  const closeDefectDetailsModal = () => {
    setisDefectDetailModalOpen(false)
  }

  const [editRemarksChange, setEditRemarksChange] = useState('')
  const [editPriorityChange, setEditPriorityChange] = useState([])
  const [editdefectType, setEditDefectType] = useState([])

  const handleRemarksEditChanges = (value, field) => {
    switch (field) {
      case "Priority":
        setEditPriorityChange(value);
        break;
      case "Reason":
        setEditRemarksChange(value);
        break;
      case "DefectType":
        setEditDefectType(value)

    }


  }

  const [editChangesLoader, setEditChangesLoader] = useState(false);

  const handleEditChanges = () => {
    setEditChangesLoader(true)
    if (!editPriorityChange || !editdefectType || !editDefectDiscription || !editRemarksChange) {
      alert("Please fill in all required fields.");
      setEditChangesLoader(false)
      return;
    }

    const formData = {
      defectId: selectedDefectDetail.defectId,
      unitId: selectedDefectDetail.unitId,
      defectLoggedBy: selectedDefectDetail.defectloggedbY_EMPLOYEEID,
      priority: editPriorityChange.value,
      defectType: editdefectType.value,
      defectDescription: editDefectDiscription,
      projectId: user.PROJECT_ID,
      loggedByDivisionID: selectedDefectDetail.attendingdivisionid,
      reasonOfDefect: editRemarksChange.label,
      statusOfDefect: selectedDefectDetail.statusOfDefect,
      childAsset: parseInt(selectedDefectDetail.childasseT_ID)
    };

    axios.post(`${project.projectList.EditDefects}`, formData).then((response) => {
      console.log("Edit Response", response);
      if (response.data.responseCode === "1") {
        alert("Defect Edited Successfully !")
        setEditChangesLoader(false)
        setIsEditModalOpen(false)
        setisDefectDetailModalOpen(false)
        fetchData();
      } else {
        alert("Failed to submit please try again!")
        setEditChangesLoader(false)
      }
    });
  };

  const [attendingDivertDivisioname, setAttendingDivertDivisionName] = useState([])

  const getDivertAttendingDivision = () => {

    axios.get(`${project.projectList.GetAttendingDivInfo}?projectId=${user.PROJECT_ID}`).then((response) => {
      console.log("attending division name", response)
      const attendingDivisionName = response.data.responseDynamic;
      setAttendingDivertDivisionName(attendingDivisionName)
      console.log("attendingDivision", attendingDivision)
    })
  }

  useEffect(() => {
    getDivertAttendingDivision();
  }, [])


  const attendingDivisionDetails = () => {



    // N for Maintenance and Y is for Operations

    axios.get(`${project.projectList.Get_All_Divisions_By_ProjectID}?ProjectID=${user.PROJECT_ID}&DivisionType=N`).then(async (response) => {
      console.log("Response", response);
      if (response && response.status === 200) {
        const result = await response.data;
        if (result.responseCode.toString() === "1") {
          const buff = Buffer.from(result.responseDynamic ? result.responseDynamic : "", "base64");
          if (buff.length !== 0) {
            const data = JSON.parse(pako.inflate(buff, { to: "string" }));
            console.log("issuing data", data.Table)

            setAttendingDivisionName(data.Table)
            return { responseCode: 1, responseData: data, responseMessage: result.responseMessage };
          }
          return { responseCode: 1, responseData: [], responseMessage: result.responseMessage };
        }
        return { responseCode: Number(result.responseCode), responseData: null, responseMessage: result.responseMessage };
      }
      return { responseCode: 0, responseData: null, responseMessage: "" };
    })
      .catch((error) => {
        console.error("Error", error);
      });
  }


  const [issuingDivision, setIssuingDivision] = useState([])

  const getIssuingDivisionDeatils = () => {

    axios.get(`${project.projectList.Get_All_Divisions_By_ProjectID}?ProjectID=${user.PROJECT_ID}&DivisionType=Y`).then(async (response) => {
      console.log("Response", response);
      if (response && response.status === 200) {
        const result = await response.data;
        if (result.responseCode.toString() === "1") {
          const buff = Buffer.from(result.responseDynamic ? result.responseDynamic : "", "base64");
          if (buff.length !== 0) {
            const data = JSON.parse(pako.inflate(buff, { to: "string" }));
            console.log("issuing data", data.Table)

            setIssuingDivision(data.Table)
            return { responseCode: 1, responseData: data, responseMessage: result.responseMessage };
          }
          return { responseCode: 1, responseData: [], responseMessage: result.responseMessage };
        }
        return { responseCode: Number(result.responseCode), responseData: null, responseMessage: result.responseMessage };
      }
      return { responseCode: 0, responseData: null, responseMessage: "" };
    })
      .catch((error) => {
        console.error("Error", error);
      });
  }

  useEffect(() => {
    getIssuingDivisionDeatils()

  }, [])

  const childAssetDetails = (unit) => {
    axios.get(`${project.projectList.GetChildAssetForWeb}?projectId=${user.PROJECT_ID}&PageNumber=0&UnitID=${unit}`)
      .then((response) => {
        console.log("Child Asset response", response);
        const data = response.data.responseDynamic;
        const childAsset = JSON.parse(data)
        console.log("childAsset", childAsset)
        setAssetDetails(childAsset);
      })
      .catch((error) => {
        console.error("Error fetching child asset details:", error);
      });
  };


  const unitDetails = () => {
    const formData = {
      projectID: user.PROJECT_ID
    }
    axios.post(`${project.projectList.GetUnittInfo}`, formData).then((response) => {
      console.log("unit response", response)
      const frequencyOptions = response.data.responseDynamic;
      setUnitOptions(frequencyOptions)
    })
  }

  const getReasonDetails = () => {
    axios.get(`${project.projectList.GetReasons}`).then((response) => {
      console.log("Reasons response", response)
      const reasons = response.data.responseDynamic
      const jsonData = JSON.parse(reasons);
      console.log("reasonsData", jsonData.Table)
      setReasonsDropdown(jsonData.Table)
    })
  }

  useEffect(() => {
    attendingDivisionDetails();
    unitDetails();
    getReasonDetails();
    getTeamsDetailListing();
  }, []);

  useEffect(() => {
    if (unitValue.value) {
      childAssetDetails(unitValue.value)
    }
  }, [unitValue.value]);

  const handleDocuments = () => {
    const formData = {
      defectId: JSON.stringify(selectedDefectDetail.defectId)
    };

    axios.get(`${project.projectList.filesDownload}?FileName=${selectedDefectDetail.defectId}`)
      .then((response) => {
        console.log("Handle response", response);
        setImage(response.data.responseDynamic)
      })
      .catch((error) => {
        console.error("Error fetching documents:", error);
      });
  };

  // const handleDocuments = (defect) => {
  //   if (!documents) { // Check if documents is already set to true
  //     setDocuments(true);
  //     handleViewDocuments(defect);
  //   }
  // }

  const handleViewDocuments = () => {
    setDocuments(true)
    handleDocuments()
  }

  const closeDocumentsModal = () => {
    setDocuments(false);
  }

  const handleEdit = () => {

    setIsEditModalOpen(true)
  }
  const [divertDivisionName, setDivertDivisionValue] = useState([]);
  const [divertRemarksValue, setDivertRemarksValue] = useState('')
  const addValueDivert = (e, field) => {
    switch (field) {
      case "DIVISIONNAME":
        setDivertDivisionValue(e.value);
        break;
      case "REMARKS":
        setDivertRemarksValue(e.target.value);
        break;

      default:
        break;
    }
  }

  const [addDivertLoader, setAddDivertLoader] = useState(false)
  const addDivert = () => {
    if (!divertRemarksValue || !divertDivisionName) {
      alert("Please Select Division Name and Enter Remarks")
      setAddDivertLoader(false)
      return;
    }


    const formData = {
      defectID: selectedDefectDetail.defectId,
      attndDivID:

        divertDivisionName.map((name) => JSON.stringify(name.divisionID))

      ,
      mobileNumber: selectedProjectData.PHEADMOBILENO,
      remarks: divertRemarksValue,
      divisionheadid: selectedDefectDetail.attendingdivisionid.toString(),
      employeeid: user.UserName
    }
    axios.post(`${project.projectList.Divert_Defect}`, formData).then((response) => {
      console.log("response", response)
      if (response.data.responseCode === "1") {
        alert(`Defect ID: ${selectedDefectDetail.defectId} has been Diverted`)
        setAddDivertLoader(false)
        setHandleDivertModal(false);
        setisDefectDetailModalOpen(false)
        fetchData()
      }
      else {
        alert("Failed to Submit Form,Please Submit Again.")
        setAddDivertLoader(false)
      }
    })

  }

  const [assignEmployeeId, setassignEmployeeId] = useState([]);
  const [assignRemarksValue, setassignRemarksValue] = useState('')
  const [assigneeRemarksBtn, setAssigneeRemarksBtn] = useState(false)

  const addAssign = (e, field) => {
    switch (field) {
      case "EMPLOYEEID":
        setassignEmployeeId(e.value);
        break;
      case "REMARKS":
        setassignRemarksValue(e.target.value);
        break;

      default:
        break;
    }
  }


  const addEmployeeAssign = () => {
    if (assignEmployeeId.length == 0 || !assignRemarksValue) {
      alert("Please Select Employee and enter remarks");
      return;
    }
    const formData = {
      defectID: selectedDefectDetail.defectId,
      acknowledgedd: "Y",
      acknoledgeDate: new Date(),
      workAssignedTo: JSON.stringify(assignEmployeeId),
      workAssignedBy: user.UserName,
      remarks: assignRemarksValue
    }
    axios.post(`${project.projectList.Update_Defect_By_Acknowledge}`, formData).then((response) => {
      console.log("response", response)
      if (response.data.responseCode === '1') {
        alert("Assigned Successfully!")
        setAssigneeRemarksBtn(true)
        setIsModalOpen(false)
        setassignRemarksValue('')
        setisDefectDetailModalOpen(false)
        setHandleAssignModal(false)
        fetchData();
      }
      else {
        alert("Failed to Submit, Please Try Again")
      }
    })
  }

  const onDivisionsSelect = (selectedList, selectedItem) => {
    ptwEmployeeBasedOnDivision(selectedList)
    setdivisionName(selectedList);
  };

  const onDivisionRemove = (selectedList, removedItem) => {
    setdivisionName(selectedList);
  };


  const onPTWEmployeeSelect = (selectedList, selectedItem) => {
    setSelectedEmployee(selectedList);
  };

  const onPTWEmployeeRemove = (selectedList, removedItem) => {
    setSelectedEmployee(selectedList);
  };


  const onPTWDivisionsSelect = (selectedList, selectedItem) => {
    setptwdivisionName(selectedList);
    ptwEmployeeBasedOnDivision(selectedList)
  };

  const [ptwEmployeeList, setPTWEmployeeList] = useState([])

  const ptwEmployeeBasedOnDivision = (selectedList) => {
    const formData = selectedList.reduce((total, data) => total + parseInt(data.DIVISIONID, 10), 0)

    console.log("ptw formData", formData)
    axios.get(`${project.projectList.Get_EmpLst_By_ProjectID_DivisionID}?ProjectID=${user.PROJECT_ID}&DivisionID=${formData}`).then((response) => {
      console.log("PTW Division response", response);
      const data = response.data.responseDynamic
      setPTWEmployeeList(data)
    })
  }

  const onPTWDivisionRemove = (selectedList, removedItem) => {
    setptwdivisionName(selectedList);
  };


  const onDivertDivisoinSelect = (selectedList, selectedItem) => {
    setDivertDivisionValue(selectedList);
  };

  const onDivertDivisionRemove = (selectedList, removedItem) => {
    setDivertDivisionValue(selectedList);
  };


  const [generalRemarks, setgeneralRemarks] = useState('')
  const [generalImage, setgeneralImage] = useState()


  const handleGeneralData = (e, field) => {
    console.log("e", e)
    switch (field) {
      case "Remarks":
        setgeneralRemarks(e.target.value);
        break;
      // case "Image":
      //   setgeneralImage(e.target.value);
      //   break;
      default:
        break;
    }
  }

  const handleGeneralRemraks = () => {
    if (!generalRemarks || !generalImage) {
      alert("Please provide both remarks and image.");
      return;
    }
    // var filename = generalImage.replace(/^.*\\/, "");
    const formData = {
      defectid: selectedDefectDetail.defectId,
      attendedby: user.UserName,
      attendinG_DATE: new Date(),
      completionN_DATE: new Date(),
      supportingG_DOCUMENT_NAME: generalImage.toString(),
      remarks: generalRemarks.toString()
    }

    axios.post(`${project.projectList.Insert_AttendedInfo}`, formData).then((response) => {
      console.log("response", response);
      if (response.data.responseCode === "1") {
        console.log("generalImage", generalImage)

        const formData = new FormData();
        var file = generalImage.name;
        var fileName = file.replace(/[\s.-](?!(png|jpg)$)/g, "_");

        // Append to formData
        formData.append("FileDetails", generalImage);
        formData.append("DefectID", selectedDefectDetail.defectId);
        formData.append("Remarks", generalRemarks);
        formData.append("ATTENDEDBY", user.UserName);


        console.log("imageData", formData);


        const imageResponse = axios.post(
          `${project.projectList.UploadImages}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        console.log("imageResponse", imageResponse)
        alert("General Attended Successfully!")
        setHandleGeneral(false)
        setAssigneeRemarks(false)
        setgeneralRemarks('')
        setisDefectDetailModalOpen(false)
        fetchData()
      }
      else {
        alert("Failed to Submit, Please Submit Again")
      }
    })

  }

  const [ptwdivisionName, setptwdivisionName] = useState([])
  const [ptwRemarks, setptwRemarks] = useState([])
  const [ptwFromDate, setptwFromDate] = useState(null)
  const [ptwToDate, setptwToDate] = useState(null)
  const [workTypes, setWorkTypes] = useState([])
  const [selectedWorkType, setSelectedWorkType] = useState([])
  const [selectedEmployee, setSelectedEmployee] = useState([])

  const getWorkType = () => {
    axios.get(`${project.projectList.Get_WorkTypes}`).then((response) => {
      console.log("Response", response)
      const workTypes = response.data.responseDynamic;
      setWorkTypes(workTypes)
    })
  }

  useEffect(() => {
    getWorkType()
  }, [])

  const [teamsMembersData, setTeamMembersData] = useState([]);
  const [teamsLoading, setTeamLoading] = useState(false);

  const getEmployeeDetails = (ptwdivisionName) => {
    const divisionIDs = ptwdivisionName.map((data) => data.divisionID).join(',');
    const formData = {
      main: {
        divisionid: divisionIDs,
        profileid: parseInt(user.UserProfileID),
        projectid: user.PROJECT_ID,
      },
    };

    const request = {
      setDatalist: setTeamMembersData,
      setLoad: setTeamLoading,
      requestData: formData,
      apiPath: project.projectList.GetKPCLTeam,
      setAlert: setAlertMessage,
      fun: null,
    };
    GetApiData(request);
  }

  useEffect(() => {
    if (ptwdivisionName !== null) {
      getEmployeeDetails(ptwdivisionName);
    }
  }, [ptwdivisionName]);

  useEffect(() => {
    console.log("teamsMembersData", teamsMembersData);
  }, [teamsMembersData]);

  const [issuingPTWData, setissuingPTWData] = useState({})
  const [ptwButtons, setPTWButtons] = useState(false)
  const [ptwID, setPTWID] = useState(null)
  const generatePTW = () => {
    if (!ptwFromDate || !ptwToDate || !selectedWorkType || !ptwRemarks || !ptwdivisionName || ptwdivisionName.length === 0) {
      alert("Please fill in all required fields.");
      return;
    }

    // const formData = {
    //   ptW_IDD: "ptw",
    //   defecT_IDD: selectedDefectDetail.defectId,
    //   issuinG_AUTHORITY: parseInt(user.UserName),
    //   issuinG_DIVISION: user.PROJECTDIVISIONID,
    //   froM_DATE: ptwFromDate,
    //   tO_DATEE: ptwToDate,
    //   worK_TYPE: selectedWorkType,
    //   remarks: ptwRemarks,
    //   attendinG_DIVISIONS: ptwdivisionName.map((data) => parseInt(data.DIVISIONID)),
    //   employeeid: selectedEmployee,
    //   visibilitY_STATUS: 0,
    // };


    const formData = {
      ptW_IDD: "ptw",
      defecT_IDD: selectedDefectDetail.defectId,
      issuinG_AUTHORITY: parseInt(user.UserName),
      issuinG_DIVISION: user.PROJECTDIVISIONID,
      froM_DATE: ptwFromDate,
      tO_DATEE: ptwToDate,
      worK_TYPE: selectedWorkType,
      remarks: ptwRemarks,
      attendinG_DIVISIONS: ptwdivisionName.map(data => parseInt(data.DIVISIONID)),
      employeeid: selectedEmployee,
      visibilitY_STATUS: 0,
    };


    axios.post(`${project.projectList.GENERATE_PTW}`, formData).then((response) => {
      console.log("response", response)
      if (response.data.responseCode === "1") {
        // alert(`PTW ID : ${response.data.responseMessage} Created Successfully!`);
        setPTWButtons(true);
        setPTWID(response.data.responseMessage);
        setissuingPTWData(response.data.responseDynamic);
      } else {
        alert("Failed to Submit please try again");
      }
    });
  };


  const [aohRemarks, setAOHRemarks] = useState('')
  const updateAOHRemarks = (e) => {
    setAOHRemarks(e.target.value)
  }

  const upDateAOH = () => {
    if (!aohRemarks) {
      alert("Please provide remarks");
      return;
    }
    const formData = {
      defectID: selectedDefectDetail.defectId,
      defStatus: selectedDefectDetail.statusOfDefect,
      aoh: 1,
      moh: 0,
      remarks: aohRemarks
    }
    axios.post(`${project.projectList.Update_AMOH}`, formData).then((response) => {
      console.log("response", response)
      if (response.data.responseCode === "1") {
        alert("AOH Raised Successfully!")
        setHandleAOH(false)
        setAssigneeRemarks(false)
        setAOHRemarks('')
        setisDefectDetailModalOpen(false)
        fetchData()
      }
      else {
        alert("Failed to Submit Please Try Again !")
      }
    })
  }


  const [mohRemarks, setMOHRemarks] = useState('')
  const updateMOHRemarks = (e) => {
    setMOHRemarks(e.target.value)
  }

  const upDateMOH = () => {
    if (!mohRemarks) {
      alert("Please provide remarks");
      return;
    }
    const formData = {
      defectID: selectedDefectDetail.defectId,
      defStatus: selectedDefectDetail.statusOfDefect,
      aoh: 0,
      moh: 1,
      remarks: mohRemarks
    }
    axios.post(`${project.projectList.Update_AMOH}`, formData).then((response) => {
      console.log("response", response)
      if (response.data.responseCode === "1") {
        alert("MOH Raised Successfully!")
        setHandleMOH(false)
        setAssigneeRemarks(false)
        setMOHRemarks('')
        setisDefectDetailModalOpen(false)
        fetchData()
      }
      else {
        alert("Failed to Submit Please Try Again !")
      }
    })
  }


  const updateDefectStatus = () => {
    if (!closeDefectRemarks) {
      alert("Please Enter Remarks");
      return;
    }
    const formData = {
      defectID: selectedDefectDetail.defectId,
      empID: parseInt(user.UserName),
      remarks: closeDefectRemarks,
    }
    axios.post(`${project.projectList.Update_DefectStatus}`, formData).then((response) => {
      console.log("response", response);
      if (response.data.responseCode === "1") {
        alert("Defect has been Closed Successfully !")
        setcloseDefect(false)
        setcloseDefectRemarks('')
        setisDefectDetailModalOpen(false)
        fetchData()

      }
      else {
        alert("Failed to Submit, Please try again")
      }
    })
  }

  const [duration, setDuration] = useState(null);

  const calculateDuration = (from, to) => {
    if (from && to) {
      const fromDate = new Date(from);
      const toDate = new Date(to);

      // Check if fromDate and toDate are the same
      if (fromDate.toDateString() === toDate.toDateString()) {
        // If they are the same, set duration to 1
        setDuration(1);
      } else {
        // Calculate the difference in milliseconds
        const differenceInTime = toDate.getTime() - fromDate.getTime();

        // Convert milliseconds to days
        const differenceInDays = Math.ceil(differenceInTime / (1000 * 3600 * 24));

        setDuration(differenceInDays);
      }
    } else {
      setDuration(null);
    }
  };




  const handlePTWInputChange = (e, field) => {
    switch (field) {
      case "Remarks":
        setptwRemarks(e.target.value);
        break;
      case "FROMDATE":
        setptwFromDate(e.target.value);
        calculateDuration(e.target.value, null);
        break;
      case "TODATE":
        setptwToDate(e.target.value);
        calculateDuration(ptwFromDate, e.target.value);
        break;
      case "WORKTYPE":
        setSelectedWorkType(e.value);
        break;
      case "EMPLOYEECODE":
        setSelectedEmployee(e.value);
        break;
      default:
        break;
    }

  }


  const [attendedDocuments, setAttendedDocuments] = useState(false);
  const [attendedImage, setAttenedImage] = useState([]);

  const closeAttenedDocumentsModal = () => {
    setAttendedDocuments(false)
  }

  const handleAttenedDocument = (image) => {
    const baseURL = "http://localhost:9000/";
    const imageURL = baseURL + image;
    setAttenedImage(imageURL)
    setAttendedDocuments(true)
  }


  const [hazardoesRemarks, setHazardoesRemarks] = useState(Array.from({ length: hazardosInputs.length }, () => ''));
  const handleHazardoesChange = (e, index) => {
    const updatedRemarks = [...hazardoesRemarks];
    updatedRemarks[index] = e.target.value;
    setHazardoesRemarks(updatedRemarks);
  }


  const saveHazardoes = () => {
    console.log("hazardoesRemarks", hazardoesRemarks);
    if (!hazardoesRemarks || hazardoesRemarks.every(remark => !remark || remark.trim() === '')) {
      alert("Please Provide Hazards Data");
      return;
    }


    var numericPart = ptwID.match(/\d+/);
    const formData = {
      id: 0,
      defecT_ID: selectedDefectDetail.defectId,
      ptW_ID: parseInt(numericPart[0]),
      ptW_EHI_VALUES_ID: hazardoesRemarks,
      ehI_VALUES: 3
    };
    console.log("equipmentRemarks", formData);
    axios.post(`${project.projectList.INSERT_PTW_EHI_RECORDS}`, formData).then((response) => {
      console.log("Equipment response", response);
      if (response.data.responseCode === "1") {
        alert("Hazards added Successfully");
        setHandleHazardoes(false);
      } else {
        alert("Failed to save data, please try again");
      }
    });
  };



  const [equipmentRemarks, setEquipmentRemarks] = useState(Array.from({ length: equipmentInputs.length }, () => ''));
  const handleEquipmentChange = (e, index) => {
    const updatedRemarks = [...equipmentRemarks];
    updatedRemarks[index] = e.target.value;
    setEquipmentRemarks(updatedRemarks);
  }

  const saveEquipment = () => {
    if (!equipmentRemarks || !equipmentRemarks.every(remark => remark && remark.trim() !== '')) {
      alert("Please Provide Equipment Data");
      return;
    }

    var numericPart = ptwID.match(/\d+/);
    const formData = {
      id: 0,
      defecT_ID: selectedDefectDetail.defectId,
      ptW_ID: parseInt(numericPart[0]),
      ptW_EHI_VALUES_ID: equipmentRemarks,
      ehI_VALUES: 2
    }
    console.log("equipmentRemarks", formData)
    axios.post(`${project.projectList.INSERT_PTW_EHI_RECORDS}`, formData).then((response) => {
      console.log("Equipment response", response)
      if (response.data.responseCode === "1") {
        alert("Protective Equipments Added Successfully")
        setisEquipment(false)
      }
      else {
        alert("Failed to save data, please try again")
      }
    })

  }


  const [isolationRemarks, setIsolationRemarks] = useState(Array.from({ length: isolationInputs.length }, () => ''));
  const handleIsolationChange = (e, index) => {
    const updatedRemarks = [...isolationRemarks];
    updatedRemarks[index] = e.target.value;
    setIsolationRemarks(updatedRemarks);
  }

  const saveIsolation = () => {
    console.log("isolationRemarks", isolationRemarks)
    if (!isolationRemarks || isolationRemarks.every(remark => !remark || remark.trim() === '')) {
      alert("Please Provide Isolation Data");
      return;
    }
    var numericPart = ptwID.match(/\d+/);
    const formData = {
      id: 0,
      defecT_ID: selectedDefectDetail.defectId,
      ptW_ID: parseInt(numericPart[0]),
      ptW_EHI_VALUES_ID: isolationRemarks,
      ehI_VALUES: 1
    }
    console.log("equipmentRemarks", formData)
    axios.post(`${project.projectList.INSERT_PTW_EHI_RECORDS}`, formData).then((response) => {
      console.log("Equipment response", response)
      if (response.data.responseCode === "1") {
        alert("Isolations added Successfully")
        setisIsolation(false)
      }
      else {
        alert("Failed to save data, please try again")
      }
    })

  }


  const handleInitiate = () => {
    if (hazardoesRemarks.length === 0 || isolationRemarks.length === 0 || equipmentRemarks.length === 0) {
      const proceed = window.confirm("Do you want to proceed without entering data for Hazrdoes, Isolation, and Equipment?");
      if (!proceed) {
        return;
      }
    }


    const formData = {
      ptwid: ptwID,
      defectid: selectedDefectDetail.defectId,
      intiateD_FROM: parseInt(user.UserName),
      intiateD_TO: selectedEmployee
    };

    axios.post(`${project.projectList.Initiate_PTW}`, formData).then((response) => {
      if (response.data.responseCode === "1") {
        alert(`PTW ID: ${ptwID} Initiated Successfully`);
        setHandlePTW(false);
        setAssigneeRemarks(false);
        setisDefectDetailModalOpen(false);
        fetchData();
      } else {
        alert("Failed to submit, please try again");
      }
    });
  };

  const [editDefectDiscription, setEditDefectDiscription] = useState('')

  const handleDefectDescriptionChange = (value) => {
    setEditDefectDiscription(value)
  }

  const filteredOptions = teamsListMembersData.filter(option => option.DESIGNATIONID >= user.EMPLOYEELEVEL);
  console.log("filteredOptions", filteredOptions)
  const assignoptions = filteredOptions.map(option => ({
    label: option.EMPLOYEENAME,
    value: option.EMPLOYEEID
  }))


  const [closeDefect, setcloseDefect] = useState(false)
  const [closeDefectRemarks, setcloseDefectRemarks] = useState('')
  const handleCloseDefectModal = () => {
    setcloseDefect(true)

  }
  const [restrictions, setrestrictions] = useState('')
  const handleCloseDefectData = (e, field) => {
    switch (field) {
      case "Remarks":
        setcloseDefectRemarks(e.target.value);
        break;
      case "Restrictions":
        setrestrictions(e.target.value);
        break;
      default:
        break;
    }


  }

  const closeCloseDefectModal = () => {
    setcloseDefect(false)
  }

  const addMultiplePTW = (e) => {
    e.preventDefault();
    setHandlePTW(true)
  }

  const handleRemoveHazardoesField = (index) => {
    setHazardosInputs((prevRemarks) => prevRemarks.filter((_, i) => i !== index));
  }
  const handleRemoveEquipmentField = (index) => {
    setEquipmentInputs((prevRemarks) => prevRemarks.filter((_, i) => i !== index));
  }
  const handleRemoveIsolationField = (index) => {
    setIsolationInputs((prevInputs) => prevInputs.filter((_, i) => i !== index));
  }

  const [ptwDefectStatus, setPTWDefectStatus] = useState({});

  const getPTWStatus = (defect) => {
    axios.get(`${project.projectList.GetPTWStatus_ForWEB}?DefectID=${defect}`).then((response) => {
      console.log("defect ptw response", response);
      const data = JSON.parse(response.data.responseDynamic)
      console.log("ptw defect response", data.Table)
      const ptwStatus = data.Table.map((data) => data.PTW_STATUS).join(',')
      console.log("ptwStatus", ptwStatus)
      setPTWDefectStatus(ptwStatus)
    })
  }

  const addDefectChildasset = (value) => {

    setChildAsset(value);


  }

  const [isolationData, setIsolationData] = useState([])

  const getEHIDetailsforIsolation = (ptw) => {
    axios.get(`${project.projectList.GetEHI_DETAILS_BY_EHI_PTW_FORWEB}?EHIID=1&PTWID=${ptw}`).then((response) => {
      console.log("EHI Isolation response", response)
      const hazardos = response.data.responseDynamic
      const haxardosData = JSON.parse(hazardos);
      console.log("haxardosData", haxardosData)
      setIsolationData(haxardosData)
    })
  }


  const [hazardoesData, setHazardosData] = useState([]);

  const getEHIDetailsforHazardoes = (ptw) => {

    axios.get(`${project.projectList.GetEHI_DETAILS_BY_EHI_PTW_FORWEB}?EHIID=3&PTWID=${ptw}`).then((response) => {
      console.log("EHI Hazardoes response", response)
      const hazardos = response.data.responseDynamic
      const haxardosData = JSON.parse(hazardos);
      console.log("haxardosData", haxardosData)
      setHazardosData(haxardosData)
    })


  }

  const [workDoneDetails, setWorkDoneDetails] = useState([])
  const getWorkDoneDetails = (defect) => {
    axios.get(`${project.projectList.GetDefectWorkDoneDetails_ForWeb}?DefectID=${defect}`).then((response) => {
      console.log("getWorkDoneDetails", response)
      const workDoneDetails = response.data.responseDynamic
      console.log("workDoneDetails", workDoneDetails)
      const details = JSON.parse(workDoneDetails)
      console.log("details", details.Table)
      setWorkDoneDetails(details.Table)
    })
  }

  const [attendModal, setAttendModal] = useState(false)
  const openAttendModal = () => {
    setAttendModal(true)
  }

  const closeAttendDefectModal = () => {
    setAttendModal(false)
  }

  const [details, setDetails] = useState('')
  const [attendRestrictions, setAttendRestrictions] = useState('')
  const attendDefectRequest = (e) => {
    e.preventDefault();
    const formData = {
      defectID: selectedDefectDetail.defectId,
      employeeID: parseInt(user.UserName),
      workDone_Details: details,
      restrictions: attendRestrictions
    }
    axios.post(`${project.projectList.WorkDoneDetails_ForWeb}`, formData).then((response) => {
      console.log("Response", response)
      if (response.data.responseCode === "1") {
        alert("Defect Attended Successfully!")
        setAttendModal(false)
        setisDefectDetailModalOpen(false)
        fetchData();
      }
      else {
        alert("Failed to Submit, please try again !")
      }
    })

  }

  const handleAttendApproval = () => {
    // const formData = {
    //   defectID: selectedDefectDetail.defectId,
    //   approvedByEmpId: JSON.parse(user.UserName),
    //   approvalSentByEmpID: JSON.parse(selectedDefectDetail.workdonE_EMPID),
    // }
    // axios.post(`${project.projectList.AttendDefectApproval_ForWeb}`, formData).then((response) => {
    //   console.log("response", response)
    //   if (response.data.responseCode === "1") {
    //     alert("Attend defect approved successfully !")
    //     setisDefectDetailModalOpen(false)
    //     fetchData();
    //   }
    //   else {
    //     alert("Failed to submit, please try agian !")
    //   }
    // })
    setapproveAttendModal(true)
  }

  const [approveAttendModal, setapproveAttendModal] = useState(false)
  // const handleApproveAttendDefectModal = () => {
  //   setapproveAttendModal(true)
  // }
  const closeApproveAttendDefectModal = () => {
    setapproveAttendModal(false)
  }


  const [approvalAttendDefectRemarks, setApprovalAttendDefectRemarks] = useState('')
  const approveattendDefectRequest = (e) => {
    e.preventDefault();
    const formData = {
      defectID: selectedDefectDetail.defectId,
      approvedByEmpId: JSON.parse(user.UserName),
      approvalSentByEmpID: JSON.parse(selectedDefectDetail.workdonE_EMPID),
      remarks: approvalAttendDefectRemarks
    }
    axios.post(`${project.projectList.AttendDefectApproval_ForWeb}`, formData).then((response) => {
      console.log("response", response)
      if (response.data.responseCode === "1") {
        alert("Attend defect approved successfully !")
        setapproveAttendModal(false)
        setisDefectDetailModalOpen(false)
        fetchData();
      }
      else {
        alert("Failed to submit, please try agian !")
      }
    })

  }



  const handleAttendedDocument = () => {
    setAttendedDocuments(true);
    handleDocuments()
  }

  const [QRCodeColumDef] = useState([
    {
      field: "#",
      headerName: "Sr. No.",
      width: 300,
      valueGetter: "node.rowIndex + 1",
      cellRenderer: (node) => {
        return node.value ? <p>{node.value}</p> : "";
      },
      cellStyle: { textAlign: "center" },
    },
    {
      field: "path",
      headerName: "Image",
      width: 300,
      cellRenderer: (node) => {
        return node.value ? <p>{node.value}</p> : "";
      },
      cellStyle: { textAlign: "center", verticalAlign: "middle" },
    },
  ]);

  const currentDate = new Date().toISOString().split('T')[0];

  const [defectaction, setdefectactionToBePerformed] = useState(false)
  const defectactionToBePerformed = () => {
    setdefectactionToBePerformed(true)
  }

  const [ptwaction, setptwactionToBePerformed] = useState(false)
  const ptwactionToBePerformed = () => {
    setptwactionToBePerformed(true)
  }

  return (
    <div className={BizClass.PageStart}>
      <div className={BizClass.Card}>
        <PageBar className={BizClass.CustomPageBar}>
          <span>Defect List</span>
          <div className="d-flex">
            <PageBar.Button onClick={toggeMOdal} style={{ margin: '3px' }}><IoMdAdd /> <span>Add Defect</span></PageBar.Button>
            <PageBar.Search onChange={(e) => onChangeSearchText(e.target.value)} value={searchText} onClick={() => getAssetListingData()} style={{ marginBottom: '2px' }} />
            <PageBar.Button onClick={() => fetchData()} style={{ margin: '3px' }}>Refresh</PageBar.Button>
            <PageBar.Button onClick={exportToCSV} style={{ margin: '3px' }}>
              Export to CSV
            </PageBar.Button>
          </div>

        </PageBar>

        <div>
          <Form>
            <Form.Group column={3} controlwidth="240px">

              <Form.InputGroup label="Unit Name">
                <Form.InputControl
                  control="select"
                  name="frequencyType"
                  options={frequencyOptions.map((option) => ({
                    label: option.unitName,
                    value: option.unitName,
                  }))}
                  defaultValue={formunitValue}
                  onChange={(e) => {
                    const selectedValue = e ? e : [];
                    updateFormState(selectedValue, "frequencyType");
                  }}
                />
              </Form.InputGroup>

              <Form.InputGroup label="Division Name">
                <Form.InputControl
                  control="input"
                  name="division"
                  maxLength="200"
                  value={searchDivision}
                  onChange={(e) => handleSearchInputChange(e, "DIVISION")}

                />
              </Form.InputGroup>

              <Form.InputGroup label="Asset ID">
                <Form.InputControl
                  control="input"
                  name="equipmentNumber"
                  maxLength="200"
                  value={searchEquipment}
                  onChange={(e) => handleSearchInputChange(e, "EQUIPMENTNUMBER")}

                />
              </Form.InputGroup>

              <Form.InputGroup label="Defect Status">
                <Form.InputControl
                  control="input"
                  name="ptwstatus"
                  maxLength="200"
                  value={searchPtw}
                  onChange={(e) => handleSearchInputChange(e, "PTWSTATUS")}

                />
              </Form.InputGroup>

              <Form.InputGroup label="From Date">
                <PageBar.Input
                  type="date"
                  name="txtFromDate"
                  maxLength="200"
                  value={selectedFromDate}
                  onChange={(e) => handleSearchInputChange(e, "FROMDATE")}
                />
              </Form.InputGroup>

              <Form.InputGroup label="To Date">
                <PageBar.Input
                  type="date"
                  name="txtToDate"
                  maxLength="200"
                  value={selectedToDate}
                  onChange={(e) => handleSearchInputChange(e, "TODATE")}

                />
              </Form.InputGroup>

            </Form.Group>
          </Form>
        </div>
        <DataGrid
          rowData={data}
          columnDefs={ListViewColumDef}
          loader={isloadingDefectList ? <Loader /> : null}
          onGridReady={onGridReady}
        />
      </div>

      {isEditModalOpen && (
        <Modal
          varient="center"
          title="Edit DefectList"
          index={2}
          show={closeEditModal}
          width="780px"
          height="500px"
        >
          <Modal.Body>
            {editChangesLoader && <Loader />}
            <Form>
              <Form.Group column={2} controlwidth="240px">
                <Form.InputGroup label="Defect ID">
                  <Form.InputControl
                    control="input"
                    name="txtUserName"
                    maxLength="200"
                    value={selectedDefectDetail.defectId}
                    readOnly
                  />
                </Form.InputGroup>
                <Form.InputGroup label="Status of Defect">
                  <Form.InputControl
                    control="input"
                    name="txtRoleName"
                    maxLength="200"
                    value={selectedDefectDetail.defectStatusText}
                    readOnly
                  />

                </Form.InputGroup>
                {/* <Form.InputGroup label="Defect Logged On">
                  <Form.InputControl
                    control="input"
                    name="txtEmployeeCode"
                    maxLength="200"
                    value={selectedDefectLoggedOn}
                    readOnly
                  />

                </Form.InputGroup> */}


                <Form.InputGroup label="Asset Name">
                  <Form.InputControl
                    control="input"
                    name="txtRoleName"
                    maxLength="200"
                    value={selectedDefectDetail.childasseT_NAME}
                    readOnly
                  />

                </Form.InputGroup>


                <Form.InputGroup label="Asset ID">
                  <Form.InputControl
                    control="input"
                    name="txtRoleName"
                    maxLength="200"
                    value={selectedDefectDetail.childasseT_ID}
                    readOnly
                  />

                </Form.InputGroup>

                <Form.InputGroup label="Unit Name">
                  <Form.InputControl
                    control="input"
                    name="txtRoleName"
                    maxLength="200"
                    value={selectedDefectDetail.unitname}
                    readOnly
                  />

                </Form.InputGroup>

                <Form.InputGroup label="Defect Logged By">
                  <Form.InputControl
                    control="input"
                    name="txtRoleName"
                    maxLength="200"
                    value={selectedDefectDetail.defectloggedbY_EMPLOYEENAME}
                    readOnly
                  />

                </Form.InputGroup>

                <Form.InputGroup label="Defect Logged Date and Time">
                  <Form.InputControl
                    control="input"
                    name="txtLoggedDate"
                    maxLength="200"
                    value={selectedDefectDetail.defectloggedon}
                    readOnly
                  />
                </Form.InputGroup>

                <Form.InputGroup label="Priority *">
                  {/* <Form.InputControl
                    control="input"
                    name="txtRoleName"
                    maxLength="200"
                    value={selectedDefectDetail.prioritypE_NAME}
                    readOnly
                  /> */}
                  <Form.InputControl
                    control="select"
                    name="txtPriority"

                    options={priorityData.map((priority) => ({
                      label: priority.prioritypE_NAME,
                      value: priority.id,
                    }))}
                    maxLength="900"
                    value={editPriorityChange}
                    onChange={(e) => handleRemarksEditChanges(e, "Priority")}
                  />

                </Form.InputGroup>

                <Form.InputGroup label="Defect Type *">
                  {/* <Form.InputControl
                    control="input"
                    name="txtRoleName"
                    maxLength="200"
                    value={selectedDefectDetail.defecttypE_NAME}
                    readOnly
                  /> */}

                  <Form.InputControl
                    control="select"
                    name="txtDefectType"
                    options={defectData.map((defect) => ({
                      label: defect.defecttypE_NAME,
                      value: defect.id,
                    }))}
                    maxLength="900"
                    value={editdefectType}
                    onChange={(e) => handleRemarksEditChanges(e, "DefectType")}
                  />

                </Form.InputGroup>



                <Form.InputGroup label="Reason For Defect *">
                  {/* <Form.InputControl
                    control="input"
                    name="txtRoleName"
                    maxLength="200"

                    onChange={(e) => { handleRemarksEditChanges(e.target.value) }}
                  /> */}

                  <Form.InputControl
                    control="select"
                    name="Reason"
                    options={reasonsDropDown.map((option, index) => ({
                      label: option.REASON,
                      value: option.ID
                    }))}
                    onChange={(e) => {
                      handleRemarksEditChanges(e, "Reason");
                    }}
                  />
                </Form.InputGroup>


                <Form.InputGroup label="Defect Description *">
                  <Form.InputControl
                    control="textarea"
                    name="txtDescription"
                    maxLength="900"
                    style={{ height: '100px' }}
                    placeholder="Please Enter Description"
                    defaultValue={selectedDefectDetail.description}
                    value={editDefectDiscription}
                    onChange={(e) => { handleDefectDescriptionChange(e.target.value) }}
                  />
                </Form.InputGroup>




              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button
              varient="primary"
              onClick={handleEditChanges}
            >
              Save
            </Button>
          </Modal.Footer>
        </Modal>
      )}

      {isModalOpen && (
        <Modal
          varient="center"
          title="Add Defect"
          index={2}
          show={toggleAddDefectPopUp}
          width="900px"
          height="500px"
        >
          <Modal.Body>
            {raiseDefectLoader && <Loader />}
            <Form>
              <Form.Group column={2} controlwidth="240px">
                <Form.InputGroup label="Project Name">
                  <Form.InputControl
                    control="input"
                    name="txtProjectName"
                    maxLength="200"
                    value={selectedProjectData.PROJECTNAME}
                    readOnly
                  />
                </Form.InputGroup>
                <Form.InputGroup label="Division Name">
                  <Form.InputControl
                    control="input"
                    name="txtDivisionName"
                    maxLength="200"
                    value={user.DIVISION_NAME}
                    readOnly
                  />
                </Form.InputGroup>
                <Form.InputGroup label="Unit Name *">
                  <Form.InputControl
                    control="select"
                    name="frequencyType"
                    options={frequencyOptions.map((option, index) => ({
                      label: option.unitName,
                      value: option.unitID,
                    }))}

                    onChange={(e) => {
                      addDefectState(e ? e : [], "frequencyType");

                    }}
                  />
                </Form.InputGroup>
                <Form.InputGroup label="Child Asset *">
                  {/* <Form.InputControl
                    control="select"
                    name="txtChildAsset"
                    options={assetDetails.map((option, index) => ({
                      label: option.CHILDASSETNAME,
                      value: option.EQUIPMENTNUMBER
                    }))}
                    maxLength="900"
                    value={childAsset}
                    onChange={(e) => addDefectState(e, "ChildAsset")}
                  /> */}
                  <Select
                    options={assetDetails.map((option, index) => ({
                      label: option.CHILDASSETNAME,
                      value: option.EQUIPMENTNUMBER
                    }))}
                    // value={childAsset}
                    value={childAsset}
                    onChange={(selectedOption) => addDefectChildasset(selectedOption)}
                    isSearchable={true}
                  />

                </Form.InputGroup>
                <Form.InputGroup label="Attending Division Name *">
                  <Multiselect
                    options={attendingDivision}
                    selectedValues={divisionName}
                    onSelect={onDivisionsSelect}
                    onRemove={onDivisionRemove}
                    showCheckbox="true"
                    displayValue="DIVISIONNAME"
                    placeholder="Select Division (Max 5)"
                  />
                </Form.InputGroup>
                <Form.InputGroup label="Reason *">
                  <Form.InputControl
                    control="select"
                    name="Reason"
                    options={reasonsDropDown.map((option, index) => ({
                      label: option.REASON,
                      value: option.ID
                    }))}
                    onChange={(e) => {
                      addDefectState(e ? e : [], "Reason");

                    }}
                  />
                </Form.InputGroup>
                <Form.InputGroup label="Priority *">
                  <Form.InputControl
                    control="select"
                    name="txtPriority"

                    options={priorityData.map((priority) => ({
                      label: priority.prioritypE_NAME,
                      value: priority.id,
                    }))}
                    maxLength="900"
                    value={priority}
                    onChange={(e) => addDefectState(e ? e : [], "Priority")}
                  />
                </Form.InputGroup>
                <Form.InputGroup label="Defect Type *">
                  <Form.InputControl
                    control="select"
                    name="txtDefectType"
                    options={defectData.map((defect) => ({
                      label: defect.defecttypE_NAME,
                      value: defect.id,
                    }))}
                    maxLength="900"
                    value={defectType}
                    onChange={(e) => addDefectState(e ? e : [], "DefectType")}
                  />
                </Form.InputGroup>
                <Form.InputGroup label="Description *">
                  <Form.InputControl
                    control="textarea"
                    name="txtDescription"
                    maxLength="900"
                    style={{ height: '100px' }}
                    value={description}
                    placeholder="Please Enter Description"
                    onChange={(e) => handleInputChange(e, "Description")}
                  />
                </Form.InputGroup>
                <Form.InputGroup label="Attach File">
                  <input
                    type="file"
                    // accept="image/*"
                    accept=".jpg, .png, .jpeg"

                    onChange={(e) => setFile(e.target.files[0])}
                    className="form-control-file"
                  />
                </Form.InputGroup>
                {defectType.value === 8 && ptwaction && (
                  <>
                    <Form.InputGroup label="PTW Issuing Division *">

                      {/* <Multiselect
  options={attendingDivision}
  selectedValues={ptwdivisionName}
  onSelect={onPTWDivisionsSelect}
  onRemove={onPTWDivisionRemove}
  displayValue="divisionName"
/> */}

                      <Multiselect
                        options={issuingDivision}
                        selectedValues={ptwdivisionName}
                        onSelect={onPTWDivisionsSelect}
                        onRemove={onPTWDivisionRemove}
                        displayValue="DIVISIONNAME"
                      />
                    </Form.InputGroup>
                    <Form.InputGroup label="PTW Issuing Authority *">
                      <Form.InputControl
                        control="select"
                        name="txtAssetId"
                        maxLength="200"
                        options={ptwEmployeeList.map((option, index) => ({
                          label: option.employeename,
                          value: option.employeeid
                        }))}
                        defaultValue={selectedEmployee}
                        onChange={(e) => handlePTWInputChange(e ? e : [], "EMPLOYEECODE")}
                      />

                      {/* <Multiselect
                    options={ptwEmployeeList}
                    selectedValues={selectedEmployee}
                    onSelect={onPTWEmployeeSelect}
                    onRemove={onPTWEmployeeRemove}
                    displayValue="employeename"
                  /> */}
                    </Form.InputGroup>

                    <Form.InputGroup label="From Date *">
                      <PageBar.Input
                        type="date"
                        name="txtFromDate"
                        maxLength="200"
                        value={ptwFromDate}
                        min={currentDate}
                        onChange={(e) => handlePTWInputChange(e, "FROMDATE")}
                      />
                    </Form.InputGroup>
                    <Form.InputGroup label="To Date *">
                      <PageBar.Input
                        type="date"
                        name="txtToDate"
                        maxLength="200"
                        value={ptwToDate}
                        min={currentDate}
                        onChange={(e) => handlePTWInputChange(e, "TODATE")}
                      />
                    </Form.InputGroup>
                    <Form.InputGroup label="Duration">
                      {duration !== null ? `${duration} days` : '0 days'}
                    </Form.InputGroup>
                    <Form.InputGroup label="Work Type *">
                      <Form.InputControl
                        control="select"
                        name="txtDescription"
                        options={workTypes.map((option, index) => ({
                          label: option.workname,
                          value: option.id,
                        }))}
                        maxLength="900"
                        defaultValue={selectedWorkType}
                        onChange={(e) => handlePTWInputChange(e, "WORKTYPE")}
                      />
                    </Form.InputGroup>
                    <Form.InputGroup label="Remarks *">
                      <Form.InputControl
                        control="textarea"
                        name="txtRemarks"
                        maxLength="900"
                        placeHolder="Please Enter Remarks"
                        style={{ height: '100px' }}
                        onChange={(e) => handlePTWInputChange(e, "Remarks")}
                      />
                    </Form.InputGroup>
                  </>
                )}
              </Form.Group>
            </Form>
            {ptwButtons && (<div className="d-flex">
              <PageBar.Button onClick={handleHazardoes} style={{ margin: '5px' }}>Hazards Prevailing at work</PageBar.Button>
              <PageBar.Button onClick={handleEquipment} style={{ margin: '5px' }}>Protective Equipment</PageBar.Button>
              <PageBar.Button onClick={handleMaintenanceIsolation} style={{ margin: '5px' }}>Isolation Required</PageBar.Button>
            </div>)}



          </Modal.Body>
          <Modal.Footer>
            {defectType.value === 8 && (
              <>
                <Button varient="primary" onClick={() => defectactionToBePerformed()} style={{ display: (defectaction || ptwaction) ? 'none' : 'inline' }}>
                  Initiate Defect
                </Button>

                <Button varient="primary" onClick={() => ptwactionToBePerformed()} style={{ display: (defectaction || ptwaction) ? 'none' : 'inline' }}>
                  Initiate PTW
                </Button>
              </>
            )}
            {defectaction === true && (
              <Button varient="primary" onClick={() => saveChanges()}>
                Raise Defect
              </Button>
            )}

            {ptwaction === true && (<Button onClick={saveChanges} style={{ display: ptwButtons === true ? 'none' : 'inline' }}>Next</Button>)}
            {ptwButtons && (
              <Button onClick={handleMaintenanceInitiate}>Initiate PTW</Button>
            )}

            {/* {ptwaction === true && (
              <Button varient="primary" onClick={() => saveChanges()}>
                Raise PTW
              </Button>
            )} */}

            {defectType.value !== 8 && (
              <Button varient="primary" onClick={() => saveChanges()}>
                Raise Defect
              </Button>
            )}
          </Modal.Footer>
        </Modal>
      )}

      {isDefectDetailModalOpen && selectedDefectDetail && (
        <Modal varient="center"
          title={selectedDefectDetail.attendingdivname !== user.DIVISION_NAME ? 'Defect Details' : (selectedDefectDetail.acknowledged === "N" ? 'Acknowledge' : ' Defect Divert / Assign')}
          index={2}
          show={closeDefectDetailsModal}
          width="1000px"
          height="800px">
          <Modal.Body>
            <div>
              <Form>
                {selectedDefectDetail.acknowledged === "N" && (
                  <>
                    <Form.Group column={2} controlwidth="240px">

                      <Form.InputGroup label="" column={2}>
                        <h6>Defect Details</h6>
                      </Form.InputGroup>


                      <Form.InputGroup label="" column={2}>
                      </Form.InputGroup>
                      <Form.InputGroup label="Defect ID">
                        <Form.InputControl
                          control="input"
                          name="txtDefectId"
                          maxLength="200"
                          value={selectedDefectDetail.defectId}
                          readOnly
                        />
                      </Form.InputGroup>
                      <Form.InputGroup label="Status">
                        <Form.InputControl
                          control="input"
                          name="txtStatus"
                          maxLength="200"
                          value={(selectedDefectDetail.acknowledged === 'N' && selectedDefectDetail.defectStatusText === 'OPEN') ? 'DEFECT RAISED' : selectedDefectDetail.defectStatusText === 'OPEN' ? 'ACKNOWLEDGED' : selectedDefectDetail.defectStatusText}
                          readOnly
                        />
                      </Form.InputGroup>
                      <Form.InputGroup label="Asset ID">
                        <Form.InputControl
                          control="input"
                          name="txtAssetId"
                          maxLength="200"
                          value={selectedDefectDetail.childasseT_ID}
                          readOnly
                        />
                      </Form.InputGroup>
                      <Form.InputGroup label="Asset Name">
                        <Form.InputControl
                          control="input"
                          name="txtAssetName"
                          maxLength="200"
                          value={selectedDefectDetail.childasseT_NAME}
                          readOnly
                        />
                      </Form.InputGroup>
                      <Form.InputGroup label="Unit ID">
                        <Form.InputControl
                          control="input"
                          name="frequencyType"
                          maxLength="200"
                          value={selectedDefectDetail.unitname}
                          readOnly
                        />
                      </Form.InputGroup>

                      <Form.InputGroup label="Priority">
                        <Form.InputControl
                          control="input"
                          name="txtPriority"
                          maxLength="200"
                          value={selectedDefectDetail.prioritypE_NAME}
                          readOnly
                        />
                      </Form.InputGroup>
                      <Form.InputGroup label="Defect Type">
                        <Form.InputControl
                          control="input"
                          name="txtPriority"
                          maxLength="200"
                          value={selectedDefectDetail.defecttypE_NAME}
                          readOnly
                        />
                      </Form.InputGroup>
                      <Form.InputGroup label="Description">
                        <Form.InputControl
                          control="input"
                          name="txtDescription"
                          maxLength="200"
                          value={selectedDefectDetail.description}
                          readOnly
                        />
                      </Form.InputGroup>

                      <Form.InputGroup label="Defect Logged By">
                        <Form.InputControl
                          control="input"
                          name="txtLoggedBy"
                          maxLength="200"
                          value={`${selectedDefectDetail.defectloggedbY_EMPLOYEENAME} (${selectedDefectDetail.defectloggedbY_EMPLOYEEID})`}

                          readOnly
                        />
                      </Form.InputGroup>
                      {/* <Form.InputGroup label="Defect Logged Employee Code">
                      <Form.InputControl
                        control="input"
                        name="txtLoggedBy"
                        maxLength="200"
                        value={selectedDefectDetail.defectloggedbY_EMPLOYEEID}
                        readOnly
                      />
                    </Form.InputGroup> */}
                      <Form.InputGroup label="Defect Logged Division">
                        <Form.InputControl
                          control="input"
                          name="txtLoggedBy"
                          maxLength="200"
                          value={selectedDefectDetail.defecT_LOGGEDBY_DIVNAME}
                          readOnly
                        />
                      </Form.InputGroup>

                      <Form.InputGroup label="Defect Logged Date and Time">
                        <Form.InputControl
                          control="input"
                          name="txtLoggedDate"
                          maxLength="200"
                          value={selectedDefectDetail.defectloggedon}
                          readOnly
                        />
                      </Form.InputGroup>
                      <Form.InputGroup label="Attending Division">
                        <Form.InputControl
                          control="input"
                          name="txtAttendingDivisionName"
                          maxLength="200"
                          value={selectedDefectDetail.attendingdivname}
                          readOnly
                        />
                      </Form.InputGroup>

                      <Form.InputGroup label="Defect Document" className="d-flex">
                        <Button onClick={handleViewDocuments}>Defect Documents</Button>

                      </Form.InputGroup>



                      {(selectedDefectDetail.defectStatusText === 'DIVERT' && (
                        <>

                          <Form.InputGroup label="" column={2}>
                          </Form.InputGroup>
                          <Form.InputGroup label="" column={2}>
                            <h6>Acknowledge Details</h6>
                          </Form.InputGroup>

                          <Form.InputGroup label="" column={2}>
                          </Form.InputGroup>
                          <Form.InputGroup label="Acknowledged Employee Name">
                            <Form.InputControl
                              control="input"
                              name="txtDescription"
                              maxLength="200"
                              value={`${selectedDefectDetail.acknowledgedbY_EMPLOYEENAME} (${selectedDefectDetail.acknowledgeD_BY})`}
                              readOnly
                            />
                          </Form.InputGroup>

                          {/* <Form.InputGroup label="Acknowledged Employee Code">
                      <Form.InputControl
                        control="input"
                        name="txtDescription"
                        maxLength="200"
                        value={selectedDefectDetail.acknowledgeD_BY}
                        readOnly
                      />
                    </Form.InputGroup> */}

                          <Form.InputGroup label="Acknowledged Division">
                            <Form.InputControl
                              control="input"
                              name="txtDescription"
                              maxLength="200"
                              value={selectedDefectDetail.acknowledgE_BY_DIV_NAME}
                              readOnly
                            />
                          </Form.InputGroup>

                          <Form.InputGroup label="Acknowledged Date and Time">
                            <Form.InputControl
                              control="input"
                              name="txtDescription"
                              maxLength="200"
                              value={selectedDefectDetail.acknowldegetime}
                              readOnly
                            />
                          </Form.InputGroup>
                          <Form.InputGroup label="" column={2}>
                          </Form.InputGroup>
                          <Form.InputGroup label="" column={2}>
                            <h6>Divert Details</h6>
                          </Form.InputGroup>

                          <Form.InputGroup label="" column={2}>
                          </Form.InputGroup>
                          <Form.InputGroup label="Diverted By Employee Name">
                            <Form.InputControl
                              control="input"
                              name="txtDescription"
                              maxLength="200"
                              value={`${selectedDefectDetail.diverteD_BY_EMPNAME} (${selectedDefectDetail.diverteD_TO_EMPID})`}
                              readOnly
                            />
                          </Form.InputGroup>


                          <Form.InputGroup label="Diverted to Division">
                            <Form.InputControl
                              control="input"
                              name="txtDescription"
                              maxLength="200"
                              value={selectedDefectDetail.diverteD_TO_DIV_NAME}
                              readOnly
                            />
                          </Form.InputGroup>
                          <Form.InputGroup label="Diverted Date Time">
                            <Form.InputControl
                              control="input"
                              name="txtDescription"
                              maxLength="200"
                              value={selectedDefectDetail.diverteD_DATETIME}
                              readOnly
                            />
                          </Form.InputGroup>

                          <Form.InputGroup label="" column={2}>
                          </Form.InputGroup>

                        </>

                      ))}



                    </Form.Group>
                  </>
                )}
                {selectedDefectDetail.acknowledged === "Y" && (
                  <>
                    <Form.Group column={2} controlwidth="340px">
                      <Form.InputGroup label="" column={2}>
                        <h6>Defect Details</h6>
                      </Form.InputGroup>
                      <Form.InputGroup label="" column={2}>
                      </Form.InputGroup>

                      <Form.InputGroup label="Defect ID">
                        <Form.InputControl
                          control="input"
                          name="txtDefectId"
                          maxLength="200"
                          value={selectedDefectDetail.defectId}
                          readOnly
                        />
                      </Form.InputGroup>
                      <Form.InputGroup label="Status">
                        <Form.InputControl
                          control="input"
                          name="txtStatus"
                          maxLength="200"
                          value={(selectedDefectDetail.acknowledged === 'N' && selectedDefectDetail.defectStatusText === 'OPEN') ? 'DEFECT RAISED' : selectedDefectDetail.defectStatusText === 'OPEN' ? 'ACKNOWLEDGED' : selectedDefectDetail.defectStatusText
                          }
                          readOnly
                        />
                      </Form.InputGroup>

                      <Form.InputGroup label="Asset ID">
                        <Form.InputControl
                          control="input"
                          name="txtAssetId"
                          maxLength="200"
                          value={selectedDefectDetail.childasseT_ID}
                          readOnly
                        />
                      </Form.InputGroup>
                      <Form.InputGroup label="Asset Name">
                        <Form.InputControl
                          control="input"
                          name="txtAssetName"
                          maxLength="200"
                          value={selectedDefectDetail.childasseT_NAME}
                          readOnly
                        />
                      </Form.InputGroup>
                      <Form.InputGroup label="Unit ID">
                        <Form.InputControl
                          control="input"
                          name="frequencyType"
                          maxLength="200"
                          value={selectedDefectDetail.unitname}
                          readOnly
                        />
                      </Form.InputGroup>

                      <Form.InputGroup label="Priority">
                        <Form.InputControl
                          control="input"
                          name="txtPriority"
                          maxLength="200"
                          value={selectedDefectDetail.prioritypE_NAME}
                          readOnly
                        />
                      </Form.InputGroup>
                      <Form.InputGroup label="Defect Type">
                        <Form.InputControl
                          control="input"
                          name="txtPriority"
                          maxLength="200"
                          value={selectedDefectDetail.defecttypE_NAME}
                          readOnly
                        />
                      </Form.InputGroup>
                      <Form.InputGroup label="Description">
                        <Form.InputControl
                          control="input"
                          name="txtDescription"
                          maxLength="200"
                          value={selectedDefectDetail.description}
                          readOnly
                        />
                      </Form.InputGroup>

                      <Form.InputGroup label="Defect Logged By">
                        <Form.InputControl
                          control="input"
                          name="txtLoggedBy"
                          maxLength="200"
                          value={`${selectedDefectDetail.defectloggedbY_EMPLOYEENAME} (${selectedDefectDetail.defectloggedbY_EMPLOYEEID})`}
                          readOnly
                        />
                      </Form.InputGroup>
                      {/* <Form.InputGroup label="Defect Logged Employee Code">
                      <Form.InputControl
                        control="input"
                        name="txtLoggedBy"
                        maxLength="200"
                        value={selectedDefectDetail.defectloggedbY_EMPLOYEEID}
                        readOnly
                      />
                    </Form.InputGroup> */}
                      <Form.InputGroup label="Defect Logged Division">
                        <Form.InputControl
                          control="input"
                          name="txtLoggedBy"
                          maxLength="200"
                          value={selectedDefectDetail.defecT_LOGGEDBY_DIVNAME}
                          readOnly
                        />
                      </Form.InputGroup>

                      <Form.InputGroup label="Defect Logged Date and Time">
                        <Form.InputControl
                          control="input"
                          name="txtLoggedDate"
                          maxLength="200"
                          value={selectedDefectDetail.defectloggedon}
                          readOnly
                        />
                      </Form.InputGroup>
                      <Form.InputGroup label="Attending Division">
                        <Form.InputControl
                          control="input"
                          name="txtAttendingDivisionName"
                          maxLength="200"
                          value={selectedDefectDetail.attendingdivname}
                          readOnly
                        />
                      </Form.InputGroup>

                      <Form.InputGroup label="Defect Document" className="d-flex">
                        <Button onClick={handleViewDocuments}>Defect Documents</Button>

                      </Form.InputGroup>

                      <Form.InputGroup label="" column={2}>
                      </Form.InputGroup>

                      <Form.InputGroup label="" column={2}>
                        <h6>Acknowledge Details</h6>
                      </Form.InputGroup>

                      <Form.InputGroup label="" column={2}>
                      </Form.InputGroup>
                      <Form.InputGroup label="Acknowledged Employee Name">
                        <Form.InputControl
                          control="input"
                          name="txtDescription"
                          maxLength="200"
                          value={`${selectedDefectDetail.acknowledgedbY_EMPLOYEENAME} (${selectedDefectDetail.acknowledgeD_BY})`}
                          readOnly
                        />
                      </Form.InputGroup>

                      {/* <Form.InputGroup label="Acknowledged Employee Code">
                      <Form.InputControl
                        control="input"
                        name="txtDescription"
                        maxLength="200"
                        value={selectedDefectDetail.acknowledgeD_BY}
                        readOnly
                      />
                    </Form.InputGroup> */}

                      <Form.InputGroup label="Acknowledged Division">
                        <Form.InputControl
                          control="input"
                          name="txtDescription"
                          maxLength="200"
                          value={selectedDefectDetail.acknowledgE_BY_DIV_NAME}
                          readOnly
                        />
                      </Form.InputGroup>

                      <Form.InputGroup label="Acknowledged Date and Time">
                        <Form.InputControl
                          control="input"
                          name="txtDescription"
                          maxLength="200"
                          value={selectedDefectDetail.acknowldegetime}
                          readOnly
                        />
                      </Form.InputGroup>
                      <Form.InputGroup label="" column={2}>
                      </Form.InputGroup>




                      {(selectedDefectDetail.defectStatusText === 'ASSIGN' || selectedDefectDetail.defectStatusText === 'PTW' || selectedDefectDetail.defectStatusText === 'ATTENDED' || selectedDefectDetail.defectStatusText === 'AOH' || selectedDefectDetail.defectStatusText === 'MOH' || selectedDefectDetail.defectStatusText === 'CLOSED') && (
                        <>
                          <Form.InputGroup label="" column={2}>
                            <h6>Assignee Details</h6>
                          </Form.InputGroup>

                          <Form.InputGroup label="" column={2}>
                          </Form.InputGroup>
                          <Form.InputGroup label="Assigned By Employee Name">
                            <Form.InputControl
                              control="input"
                              name="txtDescription"
                              maxLength="200"
                              value={`${selectedDefectDetail.workassigneD_BY_EMP_NAME} (${selectedDefectDetail.workassigneD_BY_EMP})`}
                              readOnly
                            />
                          </Form.InputGroup>

                          {/* <Form.InputGroup label="Assigned By Employee Code">
                          <Form.InputControl
                            control="input"
                            name="txtDescription"
                            maxLength="200"
                            value={selectedDefectDetail.defectloggedbY_EMPLOYEEID}
                            readOnly
                          />
                        </Form.InputGroup> */}

                          <Form.InputGroup label="Assigned By Employee Division">
                            <Form.InputControl
                              control="input"
                              name="txtDescription"
                              maxLength="200"
                              value={selectedDefectDetail.workassigneD_BY_DIVISIONNAME}
                              readOnly
                            />
                          </Form.InputGroup>

                          <Form.InputGroup label="Assigned to Employee Name">
                            <Form.InputControl
                              control="input"
                              name="txtDescription"
                              maxLength="200"
                              value={`${selectedDefectDetail.workassignedtO_EMPLOYEENAME}  (${selectedDefectDetail.workassignedtO_EMPLOYEEID})`}
                              readOnly
                            />
                          </Form.InputGroup>

                          {/* <Form.InputGroup label="Assigned to Employee Code">
                          <Form.InputControl
                            control="input"
                            name="txtDescription"
                            maxLength="200"
                            value={selectedDefectDetail.defectloggedbY_EMPLOYEEID}
                            readOnly
                          />
                        </Form.InputGroup> */}

                          <Form.InputGroup label="Assigned to Employee Division">
                            <Form.InputControl
                              control="input"
                              name="txtDescription"
                              maxLength="200"
                              value={selectedDefectDetail.workassigneD_TO_DIVISIONNAME}
                              readOnly
                            />
                          </Form.InputGroup>

                          <Form.InputGroup label="Assigner Remarks">
                            <Form.InputControl
                              control="input"
                              name="txtDescription"
                              maxLength="200"
                              value={selectedDefectDetail.assigneE_REMARKS}
                              readOnly
                            />
                          </Form.InputGroup>

                          <Form.InputGroup label="" column={2}>
                          </Form.InputGroup>
                        </>
                      )}

                      {(selectedDefectDetail.defectStatusText === 'DIVERT' && (
                        <>
                          <Form.InputGroup label="" column={2}>
                            <h6>Divert Details</h6>
                          </Form.InputGroup>

                          <Form.InputGroup label="" column={2}>
                          </Form.InputGroup>
                          <Form.InputGroup label="Diverted By Employee Name">
                            <Form.InputControl
                              control="input"
                              name="txtDescription"
                              maxLength="200"
                              value={`${selectedDefectDetail.diverteD_BY_EMPNAME} (${selectedDefectDetail.diverteD_TO_EMPID})`}
                              readOnly
                            />
                          </Form.InputGroup>


                          <Form.InputGroup label="Diverted to Division">
                            <Form.InputControl
                              control="input"
                              name="txtDescription"
                              maxLength="200"
                              value={selectedDefectDetail.diverteD_TO_DIV_NAME}
                              readOnly
                            />
                          </Form.InputGroup>
                          <Form.InputGroup label="Diverted Date Time">
                            <Form.InputControl
                              control="input"
                              name="txtDescription"
                              maxLength="200"
                              value={selectedDefectDetail.diverteD_DATETIME}
                              readOnly
                            />
                          </Form.InputGroup>

                          <Form.InputGroup label="" column={2}>
                          </Form.InputGroup>

                        </>

                      ))}

                      {attendData.map((data, index) => (
                        <>
                          <Form.InputGroup label="" column={2}>
                            <h6>Attended Details</h6>
                          </Form.InputGroup>

                          <Form.InputGroup label="" column={2}>
                          </Form.InputGroup>
                          <Form.InputGroup key={index} label="Attended By Employee Name">
                            <Form.InputControl
                              control="input"
                              name={`txtDescription${index}`}
                              maxLength="200"
                              value={`${selectedDefectDetail.attendedbY_EMPLOYEENAME} (${data.ATTENDEDBY})`}
                              readOnly
                            />
                          </Form.InputGroup>
                          {/* <Form.InputGroup key={index} label="Attended By Employee Code">
                          <Form.InputControl
                            control="input"
                            name={`txtDescription${index}`}
                            maxLength="200"
                            value={data.ATTENDEDBY}
                            readOnly
                          />
                        </Form.InputGroup> */}
                          <Form.InputGroup key={index} label="Attended Time">
                            <Form.InputControl
                              control="input"
                              name={`txtDescription${index}`}
                              maxLength="200"
                              value={formatDate(data.ATTENDING_TIME)}
                              readOnly
                            />
                          </Form.InputGroup>
                          <Form.InputGroup label="Attended Remarks">
                            <Form.InputControl control="input" value={selectedDefectDetail.attendeD_REMARKS} readOnly />
                          </Form.InputGroup>
                          <Form.InputGroup key={index} label="Attended Document">
                            <Button onClick={handleAttendedDocument}>Attended Document</Button>
                          </Form.InputGroup>




                        </>
                      ))}

                      {selectedDefectDetail.defectStatusText === "AOH" && (
                        <>
                          <Form.InputGroup label="" column={2}>
                            <h6>AOH Details</h6>
                          </Form.InputGroup>

                          <Form.InputGroup label="" column={2}>
                          </Form.InputGroup>
                          <Form.InputGroup label="AOH Status">
                            <Form.InputControl
                              control="input"
                              name="aoh"
                              maxLength="200"
                              value={selectedDefectDetail.aoH_STATUS}
                              readOnly
                            />
                          </Form.InputGroup>
                          <Form.InputGroup label="AOH Status Remarks">
                            <Form.InputControl
                              control="input"
                              name="aoh"
                              maxLength="200"
                              value={selectedDefectDetail.amoH_REMARKS}
                              readOnly
                            />
                          </Form.InputGroup>
                        </>
                      )}


                      {selectedDefectDetail.defectStatusText === "MOH" && (
                        <>
                          <Form.InputGroup label="" column={2}>
                            <h6>MOH Details</h6>
                          </Form.InputGroup>

                          <Form.InputGroup label="" column={2}>
                          </Form.InputGroup>
                          <Form.InputGroup label="MOH Status">
                            <Form.InputControl
                              control="input"
                              name="Moh"
                              maxLength="200"
                              value={selectedDefectDetail.moH_STATUS}
                              readOnly
                            />
                          </Form.InputGroup>
                          <Form.InputGroup label="MOH Status Remarks">
                            <Form.InputControl
                              control="input"
                              name="Moh"
                              maxLength="200"
                              value={selectedDefectDetail.amoH_REMARKS}
                              readOnly
                            />
                          </Form.InputGroup>

                        </>
                      )}

                      {selectedDefectDetail.ptW_ID !== 0 && (
                        <>
                          <Form.InputGroup label="" column={2}>
                            <h6>PTW Details</h6>
                          </Form.InputGroup>
                          <Form.InputGroup label="" column={2}>
                          </Form.InputGroup>
                          <Form.InputGroup label="PTW ID">
                            <Form.InputControl
                              control="input"
                              name="txtRemarks"
                              maxLength="900"
                              value={selectedDefectDetail.ptW_ID}
                              readOnly
                            />
                          </Form.InputGroup>

                          <Form.InputGroup label="PTW Status">
                            <Form.InputControl
                              control="input"
                              name="txtRemarks"
                              maxLength="200"
                              value={selectedDefectDetail.statuS_PTW}
                              readOnly
                            />
                          </Form.InputGroup>








                          {selectedDefectDetail.issueD_DATETIME !== null && (
                            <>
                              <Form.InputGroup label="PTW Issued Date Time">
                                <Form.InputControl
                                  control="input"
                                  name="txtRemarks"
                                  maxLength="200"
                                  value={selectedDefectDetail.issueD_DATETIME}
                                  readOnly

                                />
                              </Form.InputGroup>

                              <Form.InputGroup label="" column={2}>
                              </Form.InputGroup>
                            </>

                          )}



                          {selectedDefectDetail.cancelleD_TIMESTAMP !== null && (
                            <Form.InputGroup label="PTW Cancelled Date Time">
                              <Form.InputControl
                                control="input"
                                name="txtRemarks"
                                maxLength="200"
                                value={selectedDefectDetail.cancelleD_TIMESTAMP}
                                readOnly

                              />
                            </Form.InputGroup>
                          )}



                          {/* <button
                            type="button"

                            onClick={() => setActiveProjectDetailsTab(PROJECT_DETAIL_PTW_LIST)}
                          >

                            <p className="m-0">PTW List</p>
                          </button> */}






























                        </>
                      )}

                      {selectedDefectDetail.workdonE_EMPID !== null && (
                        <>

                          <Form.InputGroup label="" column={2}>
                            <h6>Attend Defect Details</h6>
                          </Form.InputGroup>
                          <Form.InputGroup label="" column={2}>
                          </Form.InputGroup>
                          <Form.InputGroup label="Attend Defect by Employee">
                            <Form.InputControl
                              control="input"
                              name="txtDefectId"
                              maxLength="200"
                              value={`${selectedDefectDetail.workdonE_EMPNAME}(${selectedDefectDetail.workdonE_EMPID})`}
                              readOnly
                            />
                          </Form.InputGroup>
                          <Form.InputGroup label="Attend Defect Details">
                            <Form.InputControl
                              control="input"
                              name="txtDefectId"
                              maxLength="200"
                              value={selectedDefectDetail.workdonE_DETAILS}
                              readOnly
                            />
                          </Form.InputGroup>
                          <Form.InputGroup label="Attend Defect Restrictions if any">
                            <Form.InputControl
                              control="input"
                              name="txtDefectId"
                              maxLength="200"
                              value={selectedDefectDetail.restrictionS_IF_ANY}
                              readOnly
                            />
                          </Form.InputGroup>

                          <Form.InputGroup label="Attend Defect Date Time">
                            <Form.InputControl
                              control="input"
                              name="txtDefectId"
                              maxLength="200"
                              value={selectedDefectDetail.workdonE_DATE}
                              readOnly
                            />
                          </Form.InputGroup>

                        </>
                      )}

                      {selectedDefectDetail.attenD_DEFECT_APPROVED_BY_EMP !== null && (
                        <>
                          <Form.InputGroup label="" column={2}>
                            <h6>Approved Details</h6>
                          </Form.InputGroup>
                          <Form.InputGroup label="" column={2}>
                          </Form.InputGroup>
                          <Form.InputGroup label="Approve Attend Defect by Employee">
                            <Form.InputControl
                              control="input"
                              name="txtDefectId"
                              maxLength="200"
                              value={`${selectedDefectDetail.attenD_DEFECT_APPROVED_BY_EMPNAME} (${selectedDefectDetail.attenD_DEFECT_APPROVED_BY_EMP})`}
                              readOnly
                            />
                          </Form.InputGroup>



                          <Form.InputGroup label="Approve Attend Defect Date Time">
                            <Form.InputControl
                              control="input"
                              name="txtDefectId"
                              maxLength="200"
                              value={selectedDefectDetail.attenD_DEFECT_APPROVAL_DATETIME}
                              readOnly
                            />
                          </Form.InputGroup>

                        </>
                      )}
                      {selectedDefectDetail.defectStatusText === "CLOSED" && (
                        <>
                          <Form.InputGroup label="" column={2}>
                            <h6>Defect Closed Details</h6>
                          </Form.InputGroup>

                          <Form.InputGroup label="" column={2}>
                          </Form.InputGroup>

                          <Form.InputGroup label="Defect Closed By">
                            <Form.InputControl
                              control="input"
                              name="aoh"
                              maxLength="200"
                              value={`${selectedDefectDetail.defectclosedbY_EMPNAME} (${selectedDefectDetail.defecT_CLOSED_BY})`}
                              readOnly
                            />
                          </Form.InputGroup>

                          <Form.InputGroup label="Defect Closed Date">
                            <Form.InputControl
                              control="input"
                              name="aoh"
                              maxLength="200"
                              value={selectedDefectDetail.defecT_CLOSED_DATE}
                              readOnly
                            />
                          </Form.InputGroup>
                          <Form.InputGroup label="Defect Closed Remarks">
                            <Form.InputControl
                              control="input"
                              name="aoh"
                              maxLength="200"
                              value={selectedDefectDetail.defecT_CLOSED_REMARKS}
                              readOnly
                            />
                          </Form.InputGroup>
                        </>


                      )}


                    </Form.Group>
                  </>
                )}
              </Form>
              {selectedDefectDetail.defectStatusText === 'PTW' && selectedDefectDetail.workassignedtO_EMPLOYEEID === parseInt(user.UserName) && ptwDefectStatus !== "CANCELLED" && (
                <button onClick={(e) => addMultiplePTW(e)} style={{ display: selectedDefectDetail.workdonE_EMPID === null ? 'inline' : 'none' }} className="btn btn-primary mx-3 my-3">Add PTW</button>
              )}

              {/* <PageBar.Button style={{ margin: '5px' }} onClick={handleViewDocuments}>View Documents</PageBar.Button> */}
              {selectedDefectDetail.defectStatusText !== "CLOSED" && (
                <div>
                  {selectedDefectDetail.acknowledged === "N" && selectedDefectDetail.defectloggedbY_EMPLOYEEID === parseInt(user.UserName) && (
                    <PageBar.Button style={{ margin: '5px' }} onClick={handleEdit}>Edit</PageBar.Button>
                  )}

                  {(selectedDefectDetail.attendingdivname === user.DIVISION_NAME || (selectedDefectDetail.diverT_FLG === 1 && selectedDefectDetail.diverteD_TO_DIV_NAME === user.DIVISION_NAME)) && (<PageBar.Button onClick={handleAcknowledge} style={{ margin: '5px', display: selectedDefectDetail.acknowledged === 'Y' ? 'none' : 'inline' }} >Acknowledge</PageBar.Button>)}


                  {selectedDefectDetail.acknowledged === "Y" && selectedDefectDetail.defectStatusText !== 'ATTENDED' && selectedDefectDetail.defectStatusText !== 'PTW' && selectedDefectDetail.defectStatusText !== 'AOH' && selectedDefectDetail.defectStatusText !== 'MOH' && (
                    <span style={{ margin: '20px' }}>
                      <PageBar.Button onClick={handleDivert} style={{ margin: '5px', display: selectedDefectDetail.defectStatusText === "ASSIGN" ? "none" : "inline" }}>Divert</PageBar.Button>
                      <PageBar.Button onClick={handleAssign} style={{ display: selectedDefectDetail.defectStatusText === "ASSIGN" ? "none" : "inline" }}>Assign</PageBar.Button>
                    </span>

                  )}
                  {(selectedDefectDetail.acknowledged === "Y"
                    && (parseInt(selectedDefectDetail.diverteD_TO_DIV_ID) === user.PROJECTDIVISIONID || selectedDefectDetail.workassignedtO_EMPLOYEEID === parseInt(user.UserName))
                    && selectedDefectDetail.defectStatusText !== 'ATTENDED'
                    && (selectedDefectDetail.defectStatusText === 'ASSIGN' || selectedDefectDetail.defectStatusText === 'DIVERT' || selectedDefectDetail.defectStatusText === 'AOH' || selectedDefectDetail.defectStatusText === 'MOH')
                    && selectedDefectDetail.defectStatusText !== 'PTW'
                    && (
                      <PageBar.Button onClick={handleAssigneeRemarks}>Assignee Actions</PageBar.Button>
                    ))
                  }
                  {(ptwDefectStatus === "CANCELLED" || selectedDefectDetail.defectStatusText === 'ATTENDED' || selectedDefectDetail.defectStatusText === 'AOH' || selectedDefectDetail.defectStatusText === 'MOH') && (selectedDefectDetail.defectloggedbY_EMPLOYEEID === parseInt(user.UserName) || selectedDefectDetail.attenD_DEFECT_APPROVED_BY_EMP === user.UserName) && selectedDefectDetail.workdonE_EMPID !== null && (
                    <PageBar.Button onClick={handleCloseDefectModal} style={{ margin: '5px' }}>Close Defect</PageBar.Button>
                  )}
                </div>
              )
              }

              {/* {selectedDefectDetail.attendingdivname !== user.DIVISION_NAME && (
                <div>
                  {selectedDefectDetail.acknowledged === "N" && (
                    <PageBar.Button style={{ margin: '5px' }} onClick={handleEdit}>Edit</PageBar.Button>
                  )}

                </div>

              )} */}



              {ishandleDivertModal && (
                <Modal varient="center"
                  title="Divert Details"
                  index={2}
                  show={closeDivertModal}
                  width="500px"
                  height="300px">
                  <Modal.Body>
                    {addDivertLoader && <Loader />}
                    <Form>
                      <Form.Group controlwidth="240px">
                        <Form.InputGroup label="Select Division">
                          {/* <Form.InputControl
                            control="select"
                            name="txtDivisionName"
                            options={attendingDivertDivisioname.map((option, index) => ({
                              label: option.divisionName,
                              value: option.divisionID,
                            }))}
                            maxLength="900"
                            defaultValue={divertDivisionName}
                            onChange={(e) => addValueDivert(e ? e : [], "DIVISIONNAME")}
                          /> */}

                          <Multiselect
                            options={attendingDivertDivisioname}
                            selectedValues={divertDivisionName}
                            onSelect={onDivertDivisoinSelect}
                            onRemove={onDivertDivisionRemove}
                            displayValue="divisionName"
                          />
                        </Form.InputGroup>

                        <Form.InputGroup label="Remarks">
                          <Form.InputControl
                            control="textarea"
                            style={{ height: '100px' }}
                            name="txtRemarks"
                            maxLength="200"
                            placeHolder="Please Enter Remarks"
                            value={divertRemarksValue}
                            onChange={(e) => addValueDivert(e, "REMARKS")}
                          />
                        </Form.InputGroup>
                      </Form.Group>
                    </Form>
                    <Modal.Footer>
                      <div style={{ margin: '20px' }}>
                        <Button varient="primary" onClick={addDivert}>
                          Divert
                        </Button>
                      </div>
                    </Modal.Footer>
                  </Modal.Body>
                </Modal>
              )}

              {handleAssignModal && (
                <Modal varient="center"
                  title="Assign Details"
                  index={2}
                  show={closeAssignModal}
                  width="500px"
                  height="300px">
                  <Modal.Body>
                    <Form>
                      <Form.Group controlwidth="240px">
                        <Form.InputGroup label="Employee Details">

                          <Form.InputControl
                            control="select"
                            name="txtDivisionName"
                            // options={teamsListMembersData.map((option, index) => ({

                            //     {
                            //     user.EMPLOYEELEVEL >= option.EMPLOYEELEVEL && (
                            //       label: option.EMPLOYEENAME,
                            //       value: option.EMPLOYEEID,

                            //       )}

                            // }))}
                            options={assignoptions}


                            maxLength="900"
                            defaultValue={assignEmployeeId}
                            onChange={(e) => addAssign(e ? e : [], "EMPLOYEEID")}
                          />
                        </Form.InputGroup>

                        <Form.InputGroup label="Remarks">
                          <Form.InputControl
                            control="textarea"
                            style={{ height: '100px' }}
                            name="txtRemarks"
                            maxLength="200"
                            placeHolder="Please Enter Remarks"
                            value={assignRemarksValue}
                            onChange={(e) => addAssign(e, "REMARKS")}
                          />
                        </Form.InputGroup>
                      </Form.Group>
                    </Form>
                    <Modal.Footer>
                      <div style={{ margin: '20px' }}>
                        <Button varient="primary" onClick={addEmployeeAssign}>
                          Assign
                        </Button>
                      </div>
                    </Modal.Footer>
                  </Modal.Body>

                </Modal>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            {(selectedDefectDetail.defectStatusText === 'AOH' || selectedDefectDetail.defectStatusText === 'MOH' || selectedDefectDetail.defectStatusText === 'ATTENDED') && selectedDefectDetail.workassignedtO_EMPLOYEEID === parseInt(user.UserName) && (
              <Button onClick={openAttendModal} style={{ display: selectedDefectDetail.workdonE_EMPID !== null ? 'none' : 'inline' }}>Attend Defect</Button>
            )}
            {user.EMPLOYEELEVEL === 5 && selectedDefectDetail.workdonE_EMPID !== null && (selectedDefectDetail.defectStatusText === 'AOH' || selectedDefectDetail.defectStatusText === 'MOH' || selectedDefectDetail.defectStatusText === 'PTW' || selectedDefectDetail.defectStatusText === 'ATTENDED') && selectedDefectDetail.attenD_DEFECT_APPROVED_BY_EMP === null && (
              <Button onClick={handleAttendApproval}>Approve Attend Defect</Button>
            )}

            {(ptwDefectStatus === "CANCELLED") && selectedDefectDetail.workassignedtO_EMPLOYEEID === parseInt(user.UserName) && (
              <Button onClick={openAttendModal} style={{ display: selectedDefectDetail.workdonE_EMPID !== null ? 'none' : 'inline' }} >Attend Defect</Button>
            )}
            {/* {
              ptwDefectStatus === "CANCELLED" &&
              (
                <Button onClick={handleAttendApproval}>Approve Attend Defect</Button>
              )} */}
          </Modal.Footer>
        </Modal>
      )}

      {documents && (
        <Modal varient="center"
          title="Defect Documents"
          index={2}
          show={closeDocumentsModal}
          width="900px"
          height="700px">
          <Modal.Body>
            {/* {isImag.map((data, index) => (
              <img key={index} src={data.path} alt="image" width="400px" height="400px" />
            ))} */}

            {/* <DataGrid rowData={isImag} rowHeight={100} columnDefs={QRCodeColumDef}/> */}
            <table style={{ borderCollapse: 'collapse', border: '1px solid black' }}>
              <thead>
                <tr>
                  <th style={{ border: '1px solid black', padding: '8px' }}>Sl No.</th>
                  {/* <th style={{ border: '1px solid black', padding: '8px' }}>Path</th> */}
                  <th style={{ border: '1px solid black', padding: '8px' }}>Image Uploaded</th>
                </tr>
              </thead>
              <tbody>
                {isImag.map((data, index) => (
                  <tr key={index} style={{ height: 'auto', verticalAlign: 'top' }}>
                    <td style={{ border: '1px solid black', padding: '8px' }}>{index + 1}</td>
                    <td style={{ border: '1px solid black', padding: '8px' }}>
                      <a href={data.path} target="_blank" rel="noopener noreferrer" style={{ cursor: 'pointer !important' }}>
                        <img src={data.path} alt="image" width="200px" height="200px" style={{ cursor: 'pointer !important' }} />
                      </a>
                    </td>

                    {/* <td style={{ border: '1px solid black', padding: '8px' }}>
                      <img src={data.path} alt="image" width="200px" height="200px" />
                    </td> */}
                  </tr>
                ))}
              </tbody>
            </table>


          </Modal.Body>
        </Modal>
      )}


      {attendedDocuments && (
        <Modal varient="center"
          title="Attended Documents"
          index={2}
          show={closeAttenedDocumentsModal}
          width="900px"
          height="700px">
          <Modal.Body>
            {/* {isImag.map((data, index) => (
              <img key={index} src={data.path} alt="image" width="400px" height="400px" />
            ))} */}

            {/* <DataGrid rowData={isImag} rowHeight={100} columnDefs={QRCodeColumDef}/> */}
            <table style={{ borderCollapse: 'collapse', border: '1px solid black' }}>
              <thead>
                <tr>
                  <th style={{ border: '1px solid black', padding: '8px' }}>Sl No.</th>
                  {/* <th style={{ border: '1px solid black', padding: '8px' }}>Path</th> */}
                  <th style={{ border: '1px solid black', padding: '8px' }}>Image Uploaded</th>
                </tr>
              </thead>
              <tbody>
                {isImag.map((data, index) => (
                  <tr key={index} style={{ height: 'auto', verticalAlign: 'top' }}>
                    <td style={{ border: '1px solid black', padding: '8px' }}>{index + 1}</td>
                    <td style={{ border: '1px solid black', padding: '8px', cursor: 'pointer !important' }} >
                      <a href={data.path} target="_blank" rel="noopener noreferrer" style={{ cursor: 'pointer !important' }}> <img src={data.path} alt="image" width="200px" height="200px" style={{ cursor: 'pointer !important' }} /></a>
                    </td>
                    {/* <td style={{ border: '1px solid black', padding: '8px' }}>
                      <img src={data.path} alt="image" width="200px" height="200px" />
                    </td> */}
                  </tr>
                ))}
              </tbody>
            </table>


          </Modal.Body>
        </Modal>
      )}

      {assigneeRemarks && (
        <Modal varient="center"
          title="Assignee Actions"
          index={2}
          show={closeAssigneeRemarksModal}
          width="1000px"
          height="800px">
          <Modal.Body>
            <Form>
              <Form.Group column={2} controlwidth="240px">
                <Form.InputGroup label="" column={2}>
                  <h6>Defect Details</h6>
                </Form.InputGroup>
                <Form.InputGroup label="" column={2}>
                </Form.InputGroup>
                <Form.InputGroup label="Defect ID">
                  <Form.InputControl
                    control="input"
                    name="txtDefectId"
                    maxLength="200"
                    value={selectedDefectDetail.defectId}
                    readOnly
                  />
                </Form.InputGroup>
                <Form.InputGroup label="Status">
                  <Form.InputControl
                    control="input"
                    name="txtStatus"
                    maxLength="200"
                    value={selectedDefectDetail.defectStatusText}
                    readOnly
                  />
                </Form.InputGroup>
                <Form.InputGroup label="Asset ID">
                  <Form.InputControl
                    control="input"
                    name="txtAssetId"
                    maxLength="200"
                    value={selectedDefectDetail.childasseT_ID}
                    readOnly
                  />
                </Form.InputGroup>
                <Form.InputGroup label="Asset Name">
                  <Form.InputControl
                    control="input"
                    name="txtAssetName"
                    maxLength="200"
                    value={selectedDefectDetail.childasseT_NAME}
                    readOnly
                  />
                </Form.InputGroup>
                <Form.InputGroup label="Unit ID">
                  <Form.InputControl
                    control="input"
                    name="frequencyType"
                    maxLength="200"
                    value={selectedDefectDetail.unitname}
                    readOnly
                  />
                </Form.InputGroup>
                <Form.InputGroup label="Priority">
                  <Form.InputControl
                    control="input"
                    name="txtPriority"
                    maxLength="200"
                    value={selectedDefectDetail.prioritypE_NAME}
                    readOnly
                  />
                </Form.InputGroup>
                <Form.InputGroup label="Defect Type">
                  <Form.InputControl
                    control="input"
                    name="txtPriority"
                    maxLength="200"
                    value={selectedDefectDetail.defecttypE_NAME}
                    readOnly
                  />
                </Form.InputGroup>
                <Form.InputGroup label="Description">
                  <Form.InputControl
                    control="input"
                    name="txtDescription"
                    maxLength="200"
                    value={selectedDefectDetail.description}
                    readOnly
                  />
                </Form.InputGroup>
                <Form.InputGroup label="Defect Document" className="d-flex">
                  <Button onClick={handleViewDocuments}>Defect Documents</Button>
                </Form.InputGroup>
                <Form.InputGroup label="Defect Logged By">
                  <Form.InputControl
                    control="input"
                    name="txtLoggedBy"
                    maxLength="200"
                    value={`${selectedDefectDetail.defectloggedbY_EMPLOYEENAME} (${selectedDefectDetail.defectloggedbY_EMPLOYEEID})`}
                    readOnly
                  />
                </Form.InputGroup>

                {/* <Form.InputGroup label="Defect Logged Employee Code">
                  <Form.InputControl
                    control="input"
                    name="txtLoggedBy"
                    maxLength="200"
                    value={selectedDefectDetail.defectloggedbY_EMPLOYEEID}
                    readOnly
                  />
                </Form.InputGroup> */}
                <Form.InputGroup label="Defect Logged Division">
                  <Form.InputControl
                    control="input"
                    name="txtLoggedBy"
                    maxLength="200"
                    value={selectedDefectDetail.defecT_LOGGEDBY_DIVNAME}
                    readOnly
                  />
                </Form.InputGroup>
                <Form.InputGroup label="Defect Logged Date and Time">
                  <Form.InputControl
                    control="input"
                    name="txtLoggedDate"
                    maxLength="200"
                    value={selectedDefectDetail.defectloggedon}
                    readOnly
                  />
                </Form.InputGroup>
                <Form.InputGroup label="Attending Division">
                  <Form.InputControl
                    control="input"
                    name="txtAttendingDivisionName"
                    maxLength="200"
                    value={selectedDefectDetail.attendingdivname}
                    readOnly
                  />
                </Form.InputGroup>
                <Form.InputGroup label="" column={2}>
                </Form.InputGroup>
                <Form.InputGroup label="" column={2}>
                  <h6>Acknowledge Details</h6>
                </Form.InputGroup>
                <Form.InputGroup label="" column={2}>
                </Form.InputGroup>
                <Form.InputGroup label="Acknowledged Employee Name">
                  <Form.InputControl
                    control="input"
                    name="txtDescription"
                    maxLength="200"
                    value={`${selectedDefectDetail.acknowledgedbY_EMPLOYEENAME} (${selectedDefectDetail.acknowledgeD_BY})`}
                    readOnly
                  />
                </Form.InputGroup>

                {/* <Form.InputGroup label="Acknowledged Employee Code">
                  <Form.InputControl
                    control="input"
                    name="txtDescription"
                    maxLength="200"
                    value={selectedDefectDetail.acknowledgeD_BY}
                    readOnly
                  />
                </Form.InputGroup> */}

                <Form.InputGroup label="Acknowledged Employee Division">
                  <Form.InputControl
                    control="input"
                    name="txtDescription"
                    maxLength="200"
                    value={selectedDefectDetail.acknowledgE_BY_DIV_NAME}
                    readOnly
                  />
                </Form.InputGroup>
                <Form.InputGroup label="Acknowledged Date and Time">
                  <Form.InputControl
                    control="input"
                    name="txtDescription"
                    maxLength="200"
                    value={selectedDefectDetail.acknowldegetime}
                    readOnly
                  />
                </Form.InputGroup>
                {selectedDefectDetail.defectStatusText === 'ASSIGN' && (
                  <>
                    <Form.InputGroup label="" column={2}>
                    </Form.InputGroup>
                    <Form.InputGroup label="" column={2}>
                      <h6>Assignee Details</h6>
                    </Form.InputGroup>
                    <Form.InputGroup label="" column={2}>
                    </Form.InputGroup>
                    <Form.InputGroup label="Assigned By Employee Name">
                      <Form.InputControl
                        control="input"
                        name="txtDescription"
                        maxLength="200"
                        value={`${selectedDefectDetail.workassigneD_BY_EMP_NAME} (${selectedDefectDetail.workassigneD_BY_EMP})`}
                        readOnly
                      />
                    </Form.InputGroup>
                    <Form.InputGroup label="Assigned By Employee Division">
                      <Form.InputControl
                        control="input"
                        name="txtDescription"
                        maxLength="200"
                        value={selectedDefectDetail.workassigneD_BY_DIVISIONNAME}
                        readOnly
                      />
                    </Form.InputGroup>
                    <Form.InputGroup label="Assigned to Employee Name">
                      <Form.InputControl
                        control="input"
                        name="txtDescription"
                        maxLength="200"
                        value={`${selectedDefectDetail.workassignedtO_EMPLOYEENAME}  (${selectedDefectDetail.workassignedtO_EMPLOYEEID})`}
                        readOnly
                      />
                    </Form.InputGroup>
                    <Form.InputGroup label="Assigned to Employee Division">
                      <Form.InputControl
                        control="input"
                        name="txtDescription"
                        maxLength="200"
                        value={selectedDefectDetail.workassigneD_TO_DIVISIONNAME}
                        readOnly
                      />
                    </Form.InputGroup>
                    <Form.InputGroup label="Assigner Remarks">
                      <Form.InputControl
                        control="input"
                        name="txtDescription"
                        maxLength="200"
                        value={selectedDefectDetail.assigneE_REMARKS}
                        readOnly
                      />
                    </Form.InputGroup></>
                )}
                {selectedDefectDetail.defectStatusText === 'DIVERT' && (
                  <>
                    <Form.InputGroup label="" column={2}>
                      <h6>Divert Details</h6>
                    </Form.InputGroup>
                    <Form.InputGroup label="" column={2}>
                    </Form.InputGroup>
                    <Form.InputGroup label="Diverted By Employee Name">
                      <Form.InputControl
                        control="input"
                        name="txtDescription"
                        maxLength="200"
                        value={`${selectedDefectDetail.diverteD_BY_EMPNAME} (${selectedDefectDetail.diverteD_TO_EMPID})`}
                        readOnly
                      />
                    </Form.InputGroup>
                    <Form.InputGroup label="Diverted to Division">
                      <Form.InputControl
                        control="input"
                        name="txtDescription"
                        maxLength="200"
                        value={selectedDefectDetail.diverteD_TO_DIV_NAME}
                        readOnly
                      />
                    </Form.InputGroup>
                    <Form.InputGroup label="Diverted Date Time">
                      <Form.InputControl
                        control="input"
                        name="txtDescription"
                        maxLength="200"
                        value={selectedDefectDetail.diverteD_DATETIME}
                        readOnly
                      />
                    </Form.InputGroup>
                  </>
                )}
              </Form.Group>
            </Form>
            {/* {selectedDefectDetail.defectStatusText === 'ATTENDED' ?
              <div className="d-flex"><PageBar.Button>Close Defect</PageBar.Button></div> : <div className="d-flex">
                <PageBar.Button onClick={handleGeneral} style={{ margin: '5px' }}>General</PageBar.Button>
                <PageBar.Button onClick={handleAOH} style={{ margin: '5px' }}>AOH</PageBar.Button>
                <PageBar.Button onClick={handleMOH} style={{ margin: '5px' }}>MOH</PageBar.Button>
                <PageBar.Button onClick={handlePTW} style={{ margin: '5px' }}>PTW</PageBar.Button>
              </div>
            } */}

            <div className="d-flex">
              <PageBar.Button onClick={handleGeneral} style={{ margin: '5px' }}>Attend without PTW</PageBar.Button>
              <PageBar.Button onClick={handleAOH} style={{ margin: '5px' }}>AOH</PageBar.Button>
              <PageBar.Button onClick={handleMOH} style={{ margin: '5px' }}>MOH</PageBar.Button>
              <PageBar.Button onClick={handlePTW} style={{ margin: '5px' }}>Attend with PTW</PageBar.Button>
            </div>
          </Modal.Body>
        </Modal>
      )}

      {isHandleGeneral && (
        <Modal varient="center"
          title="Attend without PTW"
          index={2}
          show={closeGeneralModal}
          width="500px"
          height="300px">
          <Modal.Body>
            <Form>
              <Form.Group controlwidth="440px">
                <Form.InputGroup label="Defect ID">
                  <Form.InputControl
                    control="input"
                    name="txtDefectId"
                    maxLength="200"
                    value={selectedDefectDetail.defectId}
                    readOnly
                  />
                </Form.InputGroup>
                <Form.InputGroup label="Remarks">
                  <Form.InputControl
                    control="textarea"
                    name="txtRemarks"
                    maxLength="900"
                    value={generalRemarks}
                    placeHolder="Please Enter Remarks"
                    onChange={(e) => handleGeneralData(e, 'Remarks')}
                    style={{ height: '100px' }}
                  />
                </Form.InputGroup>
                <Form.InputGroup label="Attach File">
                  <input
                    type="file"
                    accept=".jpg, .png"
                    onChange={(e) => setgeneralImage(e.target.files[0])}
                    className="form-control-file"
                  />
                </Form.InputGroup>
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button varient="primary" onClick={handleGeneralRemraks}>
              Attended
            </Button>
          </Modal.Footer>
        </Modal>
      )}

      {isHandleAOH && (
        <Modal varient="center"
          title="AOH"
          index={2}
          show={closeAOHModal}
          width="500px"
          height="300px">
          <Modal.Body>
            <Form>
              <Form.Group controlwidth="440px">
                <Form.InputGroup label="Defect ID">
                  <Form.InputControl
                    control="input"
                    name="txtDivisionName"
                    maxLength="900"
                    value={selectedDefectDetail.defectId}
                    readOnly
                  />
                </Form.InputGroup>

                <Form.InputGroup label="Remarks">
                  <Form.InputControl
                    control="textarea"
                    name="txtRemarks"
                    maxLength="900"
                    placeHolder="Please Enter Remarks"
                    style={{ height: '100px' }}
                    onChange={(e) => updateAOHRemarks(e)}
                  />
                </Form.InputGroup>
              </Form.Group>
            </Form>
            <Modal.Footer>
              <div style={{ margin: '20px' }}>
                <Button varient="primary" onClick={upDateAOH}>
                  Attended
                </Button>
              </div>
            </Modal.Footer>
          </Modal.Body>
        </Modal>
      )}

      {isHandleMOH && (
        <Modal varient="center"
          title="MOH"
          index={2}
          show={closeMOHModal}
          width="500px"
          height="300px">
          <Modal.Body>
            <Form>
              <Form.Group controlwidth="240px">
                <Form.InputGroup label="Defect ID">
                  <Form.InputControl
                    control="input"
                    name="txtDivisionName"
                    maxLength="900"
                    value={selectedDefectDetail.defectId}
                    readOnly
                  />
                </Form.InputGroup>

                <Form.InputGroup label="Remarks">
                  <Form.InputControl
                    control="textarea"
                    name="txtRemarks"
                    maxLength="900"
                    placeHolder="Please Enter Remarks"
                    style={{ height: '100px' }}
                    onChange={(e) => updateMOHRemarks(e)}

                  />
                </Form.InputGroup>
              </Form.Group>
            </Form>
            <Modal.Footer>
              <div style={{ margin: '20px' }}>
                <Button varient="primary" onClick={upDateMOH}>
                  Attended
                </Button>
              </div>
            </Modal.Footer>
          </Modal.Body>
        </Modal>
      )}

      {isHandlePTW && (
        <Modal varient="center"
          title="Initiate PTW"
          index={2}
          show={closePTWModal}
          width="1000px"
          height="800px">
          <Modal.Body>
            <Form>
              <Form.Group column={2} controlwidth="240px">
                <Form.InputGroup label="" column={2}>
                  <h6>Defect Details</h6>
                </Form.InputGroup>
                <Form.InputGroup label="" column={2}>
                </Form.InputGroup>
                <Form.InputGroup label="Defect ID">
                  <Form.InputControl
                    control="input"
                    name="txtDefectId"
                    maxLength="200"
                    value={selectedDefectDetail.defectId}
                    readOnly
                  />
                </Form.InputGroup>
                <Form.InputGroup label="Status">
                  <Form.InputControl
                    control="input"
                    name="txtStatus"
                    maxLength="200"
                    value={(selectedDefectDetail.acknowledged === 'N' && selectedDefectDetail.defectStatusText === 'OPEN') ? 'DEFECT RAISED' : selectedDefectDetail.defectStatusText === 'OPEN' ? 'ACKNOWLEDGED' : selectedDefectDetail.defectStatusText}
                    readOnly
                  />
                </Form.InputGroup>
                <Form.InputGroup label="Asset ID">
                  <Form.InputControl
                    control="input"
                    name="txtAssetId"
                    maxLength="200"
                    value={selectedDefectDetail.childasseT_ID}
                    readOnly
                  />
                </Form.InputGroup>
                <Form.InputGroup label="Asset Name">
                  <Form.InputControl
                    control="input"
                    name="txtAssetName"
                    maxLength="200"
                    value={selectedDefectDetail.childasseT_NAME}
                    readOnly
                  />
                </Form.InputGroup>
                <Form.InputGroup label="Unit ID">
                  <Form.InputControl
                    control="input"
                    name="frequencyType"
                    maxLength="200"
                    value={selectedDefectDetail.unitname}
                    readOnly
                  />
                </Form.InputGroup>
                <Form.InputGroup label="Priority">
                  <Form.InputControl
                    control="input"
                    name="txtPriority"
                    maxLength="200"
                    value={selectedDefectDetail.prioritypE_NAME}
                    readOnly
                  />
                </Form.InputGroup>
                <Form.InputGroup label="Defect Type">
                  <Form.InputControl
                    control="input"
                    name="txtPriority"
                    maxLength="200"
                    value={selectedDefectDetail.defecttypE_NAME}
                    readOnly
                  />
                </Form.InputGroup>
                <Form.InputGroup label="Description">
                  <Form.InputControl
                    control="input"
                    name="txtDescription"
                    maxLength="200"
                    value={selectedDefectDetail.description}
                    readOnly
                  />
                </Form.InputGroup>
                <Form.InputGroup label="Defect Logged By">
                  <Form.InputControl
                    control="input"
                    name="txtLoggedBy"
                    maxLength="200"
                    value={`${selectedDefectDetail.defectloggedbY_EMPLOYEENAME} (${selectedDefectDetail.defectloggedbY_EMPLOYEEID})`}
                    readOnly
                  />
                </Form.InputGroup>
                {/* <Form.InputGroup label="Defect Logged Employee Code">
                  <Form.InputControl
                    control="input"
                    name="txtLoggedBy"
                    maxLength="200"
                    value={selectedDefectDetail.defectloggedbY_EMPLOYEEID}
                    readOnly
                  />
                </Form.InputGroup> */}
                <Form.InputGroup label="Defect Logged Division">
                  <Form.InputControl
                    control="input"
                    name="txtLoggedBy"
                    maxLength="200"
                    value={selectedDefectDetail.defecT_LOGGEDBY_DIVNAME}
                    readOnly
                  />
                </Form.InputGroup>
                <Form.InputGroup label="Defect Logged Date and Time">
                  <Form.InputControl
                    control="input"
                    name="txtLoggedDate"
                    maxLength="200"
                    value={selectedDefectDetail.defectloggedon}
                    readOnly
                  />
                </Form.InputGroup>
                <Form.InputGroup label="Attending Division Name">
                  <Form.InputControl
                    control="input"
                    name="txtAttendingDivisionName"
                    maxLength="200"
                    value={selectedDefectDetail.attendingdivname}
                    readOnly
                  />
                </Form.InputGroup>
                <Form.InputGroup label="" column={2}>
                  <h6>Acknowledge Details</h6>
                </Form.InputGroup>
                <Form.InputGroup label="" column={2}>
                </Form.InputGroup>
                <Form.InputGroup label="Acknowledged Employee Name">
                  <Form.InputControl
                    control="input"
                    name="txtDescription"
                    maxLength="200"
                    value={`${selectedDefectDetail.acknowledgedbY_EMPLOYEENAME} (${selectedDefectDetail.acknowledgeD_BY})`}
                    readOnly
                  />
                </Form.InputGroup>
                <Form.InputGroup label="Acknowledged Employee Division">
                  <Form.InputControl
                    control="input"
                    name="txtDescription"
                    maxLength="200"
                    value={selectedDefectDetail.acknowledgE_BY_DIV_NAME}
                    readOnly
                  />
                </Form.InputGroup>
                <Form.InputGroup label="Acknowledged Date and Time">
                  <Form.InputControl
                    control="input"
                    name="txtDescription"
                    maxLength="200"
                    value={selectedDefectDetail.acknowldegetime}
                    readOnly
                  />
                </Form.InputGroup>
                {selectedDefectDetail.defectStatusText === 'ASSIGN' && (
                  <>
                    <Form.InputGroup label="" column={2}>
                    </Form.InputGroup>
                    <Form.InputGroup label="" column={2}>
                      <h6>Assignee Details</h6>
                    </Form.InputGroup>
                    <Form.InputGroup label="" column={2}>
                    </Form.InputGroup>
                    <Form.InputGroup label="Assigned By Employee Name">
                      <Form.InputControl
                        control="input"
                        name="txtDescription"
                        maxLength="200"
                        value={`${selectedDefectDetail.workassigneD_BY_EMP_NAME} (${selectedDefectDetail.workassigneD_BY_EMP})`}
                        readOnly
                      />
                    </Form.InputGroup>
                    <Form.InputGroup label="Assigned By Employee Division">
                      <Form.InputControl
                        control="input"
                        name="txtDescription"
                        maxLength="200"
                        value={selectedDefectDetail.workassigneD_BY_DIVISIONNAME}
                        readOnly
                      />
                    </Form.InputGroup>
                    <Form.InputGroup label="Assigned to Employee Name">
                      <Form.InputControl
                        control="input"
                        name="txtDescription"
                        maxLength="200"
                        value={`${selectedDefectDetail.workassignedtO_EMPLOYEENAME}  (${selectedDefectDetail.workassignedtO_EMPLOYEEID})`}
                        readOnly
                      />
                    </Form.InputGroup>
                    <Form.InputGroup label="Assigned to Employee Division">
                      <Form.InputControl
                        control="input"
                        name="txtDescription"
                        maxLength="200"
                        value={selectedDefectDetail.workassigneD_TO_DIVISIONNAME}
                        readOnly
                      />
                    </Form.InputGroup>
                    <Form.InputGroup label="Assigner Remarks">
                      <Form.InputControl
                        control="input"
                        name="txtDescription"
                        maxLength="200"
                        value={selectedDefectDetail.assigneE_REMARKS}
                        readOnly
                      />
                    </Form.InputGroup>
                  </>
                )}
                {(selectedDefectDetail.defectStatusText === 'DIVERT' && (
                  <>
                    <Form.InputGroup label="" column={2}>
                    </Form.InputGroup>
                    <Form.InputGroup label="" column={2}>
                      <h6>Divert Details</h6>
                    </Form.InputGroup>
                    <Form.InputGroup label="" column={2}>
                    </Form.InputGroup>
                    <Form.InputGroup label="Diverted By Employee Name">
                      <Form.InputControl
                        control="input"
                        name="txtDescription"
                        maxLength="200"
                        value={`${selectedDefectDetail.diverteD_BY_EMPNAME} (${selectedDefectDetail.diverteD_TO_EMPID})`}
                        readOnly
                      />
                    </Form.InputGroup>
                    <Form.InputGroup label="Diverted to Division">
                      <Form.InputControl
                        control="input"
                        name="txtDescription"
                        maxLength="200"
                        value={selectedDefectDetail.diverteD_TO_DIV_NAME}
                        readOnly
                      />
                    </Form.InputGroup>

                    <Form.InputGroup label="Diverted Date Time">
                      <Form.InputControl
                        control="input"
                        name="txtDescription"
                        maxLength="200"
                        value={selectedDefectDetail.diverteD_DATETIME}
                        readOnly
                      />
                    </Form.InputGroup>
                  </>
                ))}
                {/* {ptwID.length !== '' && (
                  <Form.InputGroup label="PTW ID">
                    <Form.InputControl
                      control="input"
                      name="txtDescription"
                      maxLength="200"
                      value={ptwID}
                      readOnly
                    />
                  </Form.InputGroup>
                )} */}
                <Form.InputGroup label="" column={2}>
                </Form.InputGroup>
                <Form.InputGroup label="" column={2}>
                  <h6>Initiate PTW</h6>
                </Form.InputGroup>
                <Form.InputGroup label="" column={2}>
                </Form.InputGroup>
                <Form.InputGroup label="PTW Issuing Division *">

                  {/* <Multiselect
                    options={attendingDivision}
                    selectedValues={ptwdivisionName}
                    onSelect={onPTWDivisionsSelect}
                    onRemove={onPTWDivisionRemove}
                    displayValue="divisionName"
                  /> */}

                  <Multiselect
                    options={issuingDivision}
                    selectedValues={ptwdivisionName}
                    onSelect={onPTWDivisionsSelect}
                    onRemove={onPTWDivisionRemove}
                    displayValue="DIVISIONNAME"
                  />
                </Form.InputGroup>

                <Form.InputGroup label="PTW Issuing Authority *">
                  <Form.InputControl
                    control="select"
                    name="txtAssetId"
                    maxLength="200"
                    options={ptwEmployeeList.map((option, index) => ({
                      label: option.employeename,
                      value: option.employeeid
                    }))}
                    defaultValue={selectedEmployee}
                    onChange={(e) => handlePTWInputChange(e ? e : [], "EMPLOYEECODE")}
                  />

                  {/* <Multiselect
                    options={ptwEmployeeList}
                    selectedValues={selectedEmployee}
                    onSelect={onPTWEmployeeSelect}
                    onRemove={onPTWEmployeeRemove}
                    displayValue="employeename"
                  /> */}
                </Form.InputGroup>

                <Form.InputGroup label="From Date *">
                  <PageBar.Input
                    type="date"
                    name="txtFromDate"
                    maxLength="200"
                    value={ptwFromDate}
                    min={currentDate}
                    onChange={(e) => handlePTWInputChange(e, "FROMDATE")}
                  />
                </Form.InputGroup>
                <Form.InputGroup label="To Date *">
                  <PageBar.Input
                    type="date"
                    name="txtToDate"
                    maxLength="200"
                    value={ptwToDate}
                    min={currentDate}
                    onChange={(e) => handlePTWInputChange(e, "TODATE")}
                  />
                </Form.InputGroup>
                <Form.InputGroup label="Duration">
                  {duration !== null ? `${duration} days` : '0 days'}
                </Form.InputGroup>
                <Form.InputGroup label="Work Type *">
                  <Form.InputControl
                    control="select"
                    name="txtDescription"
                    options={workTypes.map((option, index) => ({
                      label: option.workname,
                      value: option.id,
                    }))}
                    maxLength="900"
                    defaultValue={selectedWorkType}
                    onChange={(e) => handlePTWInputChange(e, "WORKTYPE")}
                  />
                </Form.InputGroup>
                <Form.InputGroup label="Remarks *">
                  <Form.InputControl
                    control="textarea"
                    name="txtRemarks"
                    maxLength="900"
                    placeHolder="Please Enter Remarks"
                    style={{ height: '100px' }}
                    onChange={(e) => handlePTWInputChange(e, "Remarks")}
                  />
                </Form.InputGroup>
                {/* {ptwID !== null && (
                  <>
                    <Form.InputGroup label="" column={2}>
                    </Form.InputGroup>
                    <Form.InputGroup label="" column={2}>
                      <h6>PTW Raised Details</h6>
                    </Form.InputGroup>
                    <Form.InputGroup label="" column={2}>
                    </Form.InputGroup>
                    <Form.InputGroup label="PTW ID" style={{ display: ptwID !== null ? 'inline' : 'none' }}>
                      <Form.InputControl
                        control="input"
                        name="txtRemarks"
                        maxLength="900"
                        value={ptwID}
                        style={{ display: ptwID !== null ? 'inline' : 'none' }}
                        readOnly
                      />
                    </Form.InputGroup>
                    <Form.InputGroup label="PTW Raised To Employee Name">
                      <Form.InputControl
                        control="input"
                        name="txtRemarks"
                        maxLength="200"
                        value={`${issuingPTWData.issueD_TO_EMPNAME} (${issuingPTWData.issueD_TO_EMPID})`}
                        readOnly
                      />
                    </Form.InputGroup>
                    <Form.InputGroup label="PTW Raised To Employee Division">
                      <Form.InputControl
                        control="input"
                        name="txtRemarks"
                        maxLength="200"
                        value={issuingPTWData.issueD_TO_DIVNAME}
                        readOnly
                      />
                    </Form.InputGroup>
                  </>
                )} */}
              </Form.Group>
            </Form>

            {ptwButtons && (<div className="d-flex">
              <PageBar.Button onClick={handleHazardoes} style={{ margin: '5px' }}>Hazards Prevailing at work</PageBar.Button>
              <PageBar.Button onClick={handleEquipment} style={{ margin: '5px' }}>Protective Equipment</PageBar.Button>
              <PageBar.Button onClick={handleIsolation} style={{ margin: '5px' }}>Isolation Required</PageBar.Button>
            </div>)}
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={generatePTW} style={{ display: ptwButtons === true ? 'none' : 'inline' }}>Next</Button>
            {ptwButtons && (
              <Button onClick={handleInitiate}>Initiate PTW</Button>
            )}
          </Modal.Footer>
        </Modal>
      )}

      {isHandleHazardoes && (
        <Modal varient="center"
          title="Hazards Prevailing at work"
          index={2}
          show={closeHazardoesModal}
          width="500px"
          height="300px">
          <Modal.Body>
            <Form>
              <Form.Group controlwidth="240px">
                {hazardoesData.map((input, index) => (
                  <Form.InputGroup key={index} label={`Hazards ${index + 1}`}>
                    <Form.InputControl
                      control="input"
                      name={`txtDivisionName${index}`}
                      maxLength="200"
                      value={input.EHIVALUE}
                    />
                  </Form.InputGroup>
                ))}

                {hazardosInputs.map((input, index) => (
                  <Form.InputGroup key={index} label={`Enter Hazards ${hazardoesData.length + index + 1}`}>
                    <Form.InputControl
                      control="input"
                      name={`txtDivisionName${index}`}
                      maxLength="200"
                      onChange={(e) => handleHazardoesChange(e, index)}
                    />
                    <Button onClick={() => handleRemoveHazardoesField(index)} className="m-2">-</Button>
                  </Form.InputGroup>
                ))}
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={handleHazardosInput}>+</Button>
            <Button onClick={saveHazardoes}>Save</Button>
          </Modal.Footer>
        </Modal>
      )}

      {isEquipment && (
        <Modal varient="center"
          title="Protective Equipment"
          index={2}
          show={closeEquipmentModal}
          width="500px"
          height="300px">
          <Modal.Body>
            <Form>
              <Form.Group controlwidth="240px">
                {equipmentView.map((input, index) => (
                  <Form.InputGroup key={index} label={`Equipment ${index + 1}`}>
                    <Form.InputControl
                      control="input"
                      name={`txtDivisionName${index}`}
                      maxLength="200"
                      value={input.EHIVALUE}
                    />
                  </Form.InputGroup>
                ))}
                {equipmentInputs.map((input, index) => (
                  <Form.InputGroup key={index} label={`Enter Equipment ${equipmentView.length + index + 1}`}>
                    <Form.InputControl
                      control="input"
                      name={`txtDivisionName${index}`}
                      maxLength="200"
                      onChange={(e) => handleEquipmentChange(e, index)}
                    />
                    <Button onClick={() => handleRemoveEquipmentField(index)} className="m-2">-</Button>
                  </Form.InputGroup>
                ))}
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={handleEquipmentInput}>+</Button>
            <Button onClick={saveEquipment}>Save</Button>
          </Modal.Footer>
        </Modal>
      )}

      {/* {isIsolation && (
        <Modal
          varient="center"
          title="Isolation"
          index={2}
          show={closeIsolationModal}
          width="500px"
          height="300px"
        >
          <Modal.Body>
            <Form>
              <Form.Group controlwidth="240px">
                {isolationInputs.map((input, index) => (
                  <>
                    <Form.InputGroup key={index} label={`Enter Isolation ${index + 1}`}>
                      <Form.InputControl
                        control="input"
                        name={`txtDivisionName${index}`}
                        maxLength="200"
                        onChange={(e) => handleIsolationChange(e, index)}
                      />
                    </Form.InputGroup>
                    <Button onClick={() => handleRemoveIsolationField(index)} className="m-2">-</Button>
                  </>
                ))}


              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={handleIsolationInput}>+</Button>
            <Button onClick={saveIsolation}>Save</Button>
          </Modal.Footer>
        </Modal>
      )} */}


      {isIsolation && (
        <Modal
          varient="center"
          title="Isolations"
          index={2}
          show={closeIsolationModal}
          width="500px"
          height="300px"
        >
          <Modal.Body>
            <Form>
              <Form.Group controlwidth="240px">
                {isolationData.map((data, index) => (

                  <Form.InputGroup key={index} label={`Isolation ${index + 1}`}>
                    <Form.InputControl
                      control="input"
                      name={`txtDivisionName${index}`}
                      maxLength="900"
                      value={data.EHIVALUE}
                    />
                  </Form.InputGroup>
                ))}
                {isolationInputs.map((input, index) => (

                  <Form.InputGroup key={index} label={`Enter Isolation ${isolationData.length + index + 1}`}>
                    <Form.InputControl
                      control="input"
                      name={`txtDivisionName${index}`}
                      maxLength="900"
                      style={{ width: '200px' }}
                      onChange={(e) => handleIsolationChange(e, index)}
                    />
                    <Button onClick={() => handleRemoveIsolationField(index)} className="m-2">-</Button>
                  </Form.InputGroup>
                ))}
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={handleIsolationInput}>+</Button>
            <Button onClick={saveIsolation}>Save</Button>
          </Modal.Footer>
        </Modal>
      )}


      {closeDefect && (
        <Modal varient="center"
          title="Close Defect"
          index={2}
          show={closeCloseDefectModal}
          width="800px"
          height="600px">
          <Modal.Body>
            <Form>
              <Form.Group controlwidth="440px">
                <Form.InputGroup label="Defect ID">
                  <Form.InputControl
                    control="input"
                    name="txtDefectId"
                    maxLength="200"
                    value={selectedDefectDetail.defectId}
                    readOnly
                  />
                </Form.InputGroup>

                {workDoneDetails.map((data, index) => {
                  return (
                    <>
                      <Form.InputGroup label="Details of Work Done">
                        <Form.InputControl
                          control="input"
                          name="txtRemarks"
                          maxLength="200"
                          value={data.WORKDONE_DETAILS}
                          readOnly
                        />
                      </Form.InputGroup>
                      <Form.InputGroup label="Restrictions If any">
                        <Form.InputControl
                          control="input"
                          name="aoh"
                          maxLength="200"
                          value={data.RESTRICTIONS}
                          readOnly
                        />
                      </Form.InputGroup>
                    </>
                  );
                })}




                <Form.InputGroup label="Remarks">
                  <Form.InputControl
                    control="textarea"
                    name="txtDefectId"
                    maxLength="200"
                    style={{ height: '100px' }}
                    placeholder="Please Enter Remarks"
                    value={closeDefectRemarks}
                    onChange={(e) => setcloseDefectRemarks(e.target.value)}

                  />
                </Form.InputGroup>

              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={updateDefectStatus}>Close Defect</Button>
          </Modal.Footer>
        </Modal>
      )}

      {attendModal && (
        <Modal varient="center"
          title="Attend Defect"
          index={2}
          show={closeAttendDefectModal}
          width="700px"
          height="500px">
          <Modal.Body>
            <Form>

              <Form.Group controlwidth="340px">

                <Form.InputGroup label="Defect ID">
                  <Form.InputControl
                    control="input"
                    name="txtAssetId"
                    maxLength="200"
                    value={selectedDefectDetail.defectId}
                    readOnly
                  />
                </Form.InputGroup>

                <Form.InputGroup label="Details of work done">
                  <Form.InputControl
                    control="textarea"
                    name="txtAssetId"
                    maxLength="900"
                    value={details}
                    placeholder="Please Enter Details of Work Done"
                    style={{ height: '100px' }}
                    onChange={(e) => setDetails(e.target.value)}
                  />
                </Form.InputGroup>



                <Form.InputGroup label="Restrictions if any">
                  <Form.InputControl
                    control="textarea"
                    name="txtDefectId"
                    maxLength="900"
                    value={attendRestrictions}
                    style={{ height: '100px' }}
                    placeholder="Please Enter Restrictions if any"
                    onChange={(e) => setAttendRestrictions(e.target.value)}
                  />
                </Form.InputGroup>
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-primary" onClick={(e) => attendDefectRequest(e)}>Attend Defect</button>
          </Modal.Footer>
        </Modal>
      )}


      {approveAttendModal && (
        <Modal varient="center"
          title="Approve Attend Defect"
          index={2}
          show={closeApproveAttendDefectModal}
          width="700px"
          height="500px">
          <Modal.Body>
            <Form>

              <Form.Group controlwidth="340px">

                <Form.InputGroup label="Defect ID">
                  <Form.InputControl
                    control="input"
                    name="txtAssetId"
                    maxLength="200"
                    value={selectedDefectDetail.defectId}
                    readOnly
                  />
                </Form.InputGroup>
                <Form.InputGroup label="PTW ID">
                  <Form.InputControl
                    control="input"
                    name="txtAssetId"
                    maxLength="200"
                    value={selectedDefectDetail.ptW_ID}
                    readOnly
                  />
                </Form.InputGroup>

                <Form.InputGroup label="Details of work done">
                  <Form.InputControl
                    control="input"
                    name="txtAssetId"
                    maxLength="200"
                    value={selectedDefectDetail.workdonE_DETAILS}
                    readOnly
                  />
                </Form.InputGroup>
                <Form.InputGroup label="Restrictions if any">
                  <Form.InputControl
                    control="input"
                    name="txtAssetId"
                    maxLength="200"
                    value={selectedDefectDetail.restrictionS_IF_ANY}
                    readOnly
                  />
                </Form.InputGroup>

                <Form.InputGroup label="Remarks">
                  <Form.InputControl
                    control="textarea"
                    name="txtDefectId"
                    maxLength="900"
                    value={approvalAttendDefectRemarks}
                    style={{ height: '100px' }}
                    placeholder="Please Enter Remarks"
                    onChange={(e) => setApprovalAttendDefectRemarks(e.target.value)}
                  />
                </Form.InputGroup>
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-primary" onClick={(e) => approveattendDefectRequest(e)}>Approve Attend Defect</button>
          </Modal.Footer>
        </Modal>
      )}


    </div>
  );
};

export default MyComponent;
