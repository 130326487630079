import React, { useState, useEffect } from "react";
import GetApiData from "Configration/Utilities/getApiData/GetApiData";
import { AlertMessage } from "Framework/Components/Widgets";
import { getSessionStorage } from "Service/auth/auth";
import { project } from "Service/Endpoints/endpoints";
import moment from "moment/moment";

const cellTemplate = (params) => {
  const togglePopUp = (e) => {
    e.preventDefault();
    params.toggleObservetionDetailsePopUp(params.data);
  };

  return (
    <a href="/#" onClick={(e) => togglePopUp(e)}>
      {params.value ? `${params.value} (${params.data.DIVISIONID})` : ""}
    </a>
  );
};

function DashboardLogics() {
  const SelectedProject = getSessionStorage("selctedProject");
  const user = getSessionStorage("userData");
  const setAlertMessage = AlertMessage();
  const [reportList, setReportList] = useState([]);
  const [isLoadingreport, setIsLoadingReport] = useState(false);
  const [gridApi, setGridApi] = useState();
  const [divisionReportGridApi, setDivisionReportGridApi] = useState();
  const [searchText, setSearchText] = useState("");
  const [searchDivisionReportText, setSearchDivisionReportText] = useState("");
  const [isActivePopUp, setIsActivePopUp] = useState({
    isOpen: false,
    popUpData: null,
  });
  const [reportDivisionList, setreportDivisionList] = useState([]);
  const [isLoadingReportDivision, setIsLoadingReportDivision] = useState(false);
  const [reportFormValues, serReportFormValue] = useState({
    txtFromDate: moment().subtract(1, "days").format("YYYY-MM-DD"),
    txtToDate: moment().subtract(1, "days").format("YYYY-MM-DD"),
  });
  const [divsionReportFormValues, serDivisionReportFormValue] = useState({
    txtFromDate: moment().subtract(1, "days").format("YYYY-MM-DD"),
    txtToDate: moment().subtract(1, "days").format("YYYY-MM-DD"),
  });

  const toggleObservetionDetailsePopUp = (data) => {
    setIsActivePopUp((Values) => ({
      isOpen: !Values.isOpen,
      popUpData: data,
    }));
  };

  const [ListViewColumDef] = useState([
    // {
    //   headerName: "Project Name",
    //   field: "#",
    //   width: 400,
    //   cellRenderer: cellTemplate,
    //   cellRendererParams: {
    //     SelectedProject,
    //   },
    // },
    { valueGetter: "node.rowIndex + 1", field: "#", headerName: "Sr No.", width: 80 },
    {
      headerName: "Division Name",
      field: "DIVISIONNAME",
      width: 160,
      cellRenderer: cellTemplate,
      cellRendererParams: {
        toggleObservetionDetailsePopUp,
      },
    },
    { headerName: "Total No Of Officers Count ", field: "OFFICERSCOUNT", width: 220 },
    { headerName: "Total Observation", field: "TOTAL", width: 160 },
    { headerName: "Compalete Observation", field: "CLOSED", width: 200 },
    { headerName: "Panding", field: "PENDING", width: 110 },
    { headerName: "Observation Compalete Percentage", field: "OBSERVATIONCOMPLETEPERCENTAGE", width: 280 },
    { headerName: "Total skipped", field: "TOTALSKIPPED", width: 170 },
  ]);

  const [ObservationDetailsListViewColumDef] = useState([
    { valueGetter: "node.rowIndex + 1", field: "#", headerName: "Sr No.", width: 80 },
    {
      headerName: "Division Name",
      field: "EMPLOYEENAME",
      width: 200,
      cellRenderer: (node) => {
        return node && node.value ? `${node.value} (${node.data.EMPLOYEEID})` : "";
      },
    },
    { headerName: "Total Observation", field: "TOTAL", width: 160 },
    { headerName: "Compalete Observation", field: "CLOSED", width: 200 },
    { headerName: "Panding", field: "PENDING", width: 110 },
    { headerName: "Observation Compalete Percentage", field: "OBSERVATIONCOMPLETEPERCENTAGE", width: 280 },
    { headerName: "Total skipped", field: "TOTALSKIPPED", width: 170 },
  ]);

  const onGridReady = (params) => {
    setGridApi(params.api);
  };
  const onDivisionReportGridReady = (params) => {
    setDivisionReportGridApi(params.api);
  };

  const onChangeSearchText = (val) => {
    setSearchText(val);
    gridApi.setQuickFilter(val);
    gridApi.refreshCells();
  };

  const onChangeDivisionReportSearchText = (val) => {
    setSearchDivisionReportText(val);
    divisionReportGridApi.setQuickFilter(val);
    divisionReportGridApi.refreshCells();
  };

  const updateReportStateValue = (name, value) => {
    console.log(name, value);
    serReportFormValue((Values) => ({
      ...Values,
      [name]: value,
    }));
  };

  const updateDivisionReportStateValue = (name, value) => {
    serDivisionReportFormValue((Values) => ({
      ...Values,
      [name]: value,
    }));
  };

  const cleareSerchvalue = (data) => {
    if (data) {
      if (searchText && searchText.toLowerCase().includes("#")) {
        onChangeSearchText("");
      }
    }
  };

  const getObeservationReportData = () => {
    const formData = {
      main: {
        divisionid: SelectedProject && SelectedProject.DIVISIONIDs ? SelectedProject.DIVISIONIDs : "",
        profileid: user && user.UserProfileID ? user.UserProfileID : null,
        projectid: SelectedProject && SelectedProject.PROJECTID ? SelectedProject.PROJECTID : "",
        fromdate: moment(reportFormValues.txtFromDate).format("DD-MM-YYYY"),
        todate: moment(reportFormValues.txtToDate).format("DD-MM-YYYY"),
      },
    };
    const request = {
      setDatalist: setReportList,
      setLoad: setIsLoadingReport,
      requestData: formData,
      apiPath: project.projectList.GetObservationStatusReport,
      setAlert: setAlertMessage,
      fun: cleareSerchvalue,
    };
    GetApiData(request);
  };

  const getObeservationReportDivsionDetailsList = () => {
    const formData = {
      main: {
        divisionid: isActivePopUp.popUpData && isActivePopUp.popUpData.DIVISIONID ? isActivePopUp.popUpData.DIVISIONID.toString() : "",
        projectid: SelectedProject && SelectedProject.PROJECTID ? SelectedProject.PROJECTID : "",
        fromdate: moment(divsionReportFormValues.txtFromDate).format("DD-MM-YYYY"),
        todate: moment(divsionReportFormValues.txtToDate).format("DD-MM-YYYY"),
      },
    };
    const request = {
      setDatalist: setreportDivisionList,
      setLoad: setIsLoadingReportDivision,
      requestData: formData,
      apiPath: project.projectList.GetObservationDivisionStatusReport,
      setAlert: setAlertMessage,
      fun: null,
    };
    GetApiData(request);
  };

  useEffect(() => {
    getObeservationReportData();
  }, []);

  const ObserVationDetailsProps = {
    reportDivisionList,
    isLoadingReportDivision,
    getObeservationReportDivsionDetailsList,
    ObservationDetailsListViewColumDef,
    updateDivisionReportStateValue,
    divsionReportFormValues,
    onChangeDivisionReportSearchText,
    searchDivisionReportText,
    onDivisionReportGridReady,
  };

  return {
    reportList,
    isLoadingreport,
    searchText,
    onGridReady,
    onChangeSearchText,
    getObeservationReportData,
    ListViewColumDef,
    SelectedProject,
    toggleObservetionDetailsePopUp,
    isActivePopUp,
    ObserVationDetailsProps,
    updateReportStateValue,
    reportFormValues,
  };
}

export default DashboardLogics;
