import React, { useState, useEffect, useMemo } from "react";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import { getSessionStorage } from "Service/auth/auth";
import { AiFillSetting, AiOutlineFundProjectionScreen } from "react-icons/ai";
import { GoGraph } from "react-icons/go";
import { RiRoadMapLine, RiTeamLine, RiFileUnknowFill } from "react-icons/ri";
import { VscProject } from "react-icons/vsc";
import { MdOutlineAdminPanelSettings } from "react-icons/md";
import BizClass from "./Sidebar.module.scss";

function Sidebar() {
  const navigate = useNavigate();
  const [collapsed, setCollapsed] = useState(true);
  const [subMenuList, setSubMenuList] = useState([]);
  const [menuNodes, setMenuNodes] = useState([]);
  const [activeMenuId, setActiveMenuId] = useState(0);
  const [activeSubMenuId, setActiveSubMenuId] = useState(0);

  const toggleMenu = (menu) => {
    debugger;
    setActiveMenuId(menu.id);
    if (menu.submenu && menu.submenu.length > 0) {
      setSubMenuList(menu.submenu);
      setCollapsed(false);
    } else {
      setSubMenuList([]);
      setCollapsed(true);
      navigate(menu.url && menu.url);
      setActiveSubMenuId(0);
    }
  };

  const onClickSubMenu = (submenu) => {
    debugger;
    setActiveSubMenuId(submenu.id);
    navigate(submenu.url);
    setCollapsed(true);
  };

  const onHomeMenuClick = () => {
    navigate("/welcome");
    setCollapsed(true);
    setActiveMenuId(0);
    setActiveSubMenuId(0);
  };

  const [menus, setMenues] = useState();
  useMemo(async () => {
    const user = getSessionStorage("userData");
    console.log(user, "userData");
    setMenues(user.userMenuMaster);
  }, []);

  let menuNodesData = [];

  useEffect(() => {
    if (menus) {
      menuNodesData = [];
      const parent = menus.filter((x) => x.UnderMenuID === "0");
      console.log(parent, "parent");
      parent.forEach((m, i) => {
        const newView = { id: i + 1, name: m.MenuName, url: m.ReactURL, submenu: [] };
        menus.forEach((menu, j) => {
          if (menu.UnderMenuID === m.MenuMasterID) {
            newView.submenu.push({
              id: `${i + 1}-${j + 1}`,
              name: menu.MenuName,
              url: menu.ReactURL,
            });
          }
        });
        menuNodesData.push(newView);
      });
      setMenuNodes(menuNodesData);
    }
  }, [menus]);

  useEffect(() => {
    if (menuNodes) {
      console.log(menuNodes, "menuNodes");
    }
  }, [menuNodes]);

  const menuIconWithSwitch = (parameter) => {
    switch (parameter.toLowerCase()) {
      case "dashboard":
        return <GoGraph />;
      case "map tracker":
        return <RiRoadMapLine />;
      case "hrms":
        return <RiTeamLine />;
      case "reports":
        return <AiOutlineFundProjectionScreen />;
      case "projects":
        return <VscProject />;
      case "admin":
        return <MdOutlineAdminPanelSettings />;
      case "settings":
        return <AiFillSetting />;
      default:
        return <RiFileUnknowFill />;
    }
  };

  return (
    <div className={BizClass.Box}>
      <div className={BizClass.MainBox}>
        <div className={BizClass.ClientLogo}>
          <button type="button" onClick={() => onHomeMenuClick()}>
            <img src={`${process.env.PUBLIC_URL}App_logo.svg`} alt="Client Logo" />
          </button>
        </div>
        <ul>
          {menuNodes &&
            menuNodes.map((data) => {
              if (data.name !== "HRMS") {
                return (
                  <li key={data.id}>
                    <button type="button" className={activeMenuId === data.id ? BizClass.Active : null} onClick={() => toggleMenu(data)}>
                      {menuIconWithSwitch(data.name)}
                      <span>{data.name}</span>
                    </button>
                  </li>
                );
              }

            })}
        </ul>
      </div>
      {collapsed === false && subMenuList && subMenuList.length > 0 ? (
        <>
          <div className={collapsed === false ? BizClass.SubBox : classNames(BizClass.SubBox, BizClass.CollapsedBar)}>
            <ul>
              {subMenuList.map((data) => {
                // if (data.id === '3-4' || data.id === '3-5') {
                //   return (
                //     <li key={data.id}>
                //       <button type="button" className={activeSubMenuId === data.id ? BizClass.Active : null} onClick={() => onClickSubMenu(data)}>
                //         {data.name}
                //       </button>
                //     </li>
                //   );
                // }
                if (data.name !== 'Right Management') {
                  return (
                    <li key={data.id}>
                      <button type="button" className={activeSubMenuId === data.id ? BizClass.Active : null} onClick={() => onClickSubMenu(data)}>
                        {data.name}
                      </button>
                    </li>
                  );
                }

              })}
              {/* {subMenuList.map((data) => {
                return (
                  <li key={data.id}>
                    <button type="button" className={activeSubMenuId === data.id ? BizClass.Active : null} onClick={() => onClickSubMenu(data)}>
                      {data.name}
                    </button>
                  </li>
                );
              })} */}
            </ul>
          </div>
          <div role="presentation" className={BizClass.BackDrop} onClick={() => setCollapsed(true)} onKeyDown={() => setCollapsed(true)} />
        </>
      ) : null}
    </div>
  );
}

export default Sidebar;
