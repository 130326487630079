import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Sidebar from "Components/Modules/Common/Sidebar/Sidebar";
import Header from "../../../Components/Modules/Common/Header/Header";
import { checkAuthExist } from "Service/auth/auth";
import { useNavigate } from "react-router-dom";
//import BizClass from "./Page.module.scss";

function Page(props) {
  const { title, component } = props;
  const navigate = useNavigate();

  useEffect(() => {
    document.title = title ? `${title} | Karnataka Power Corporation Limited` : "Karnataka Power Corporation Limited";
  }, [title]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (!checkAuthExist()) {
        navigate("/login");
      }
    }, 10);
    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <Header pagetitle={title} />
      <div >
        <Sidebar />
        <div >{component}</div>
      </div>
    </>
  );
}

export default Page;

Page.propTypes = {
  title: PropTypes.string.isRequired,
  component: PropTypes.node.isRequired,
};
