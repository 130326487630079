import { AlertMessage } from "Framework/Components/Widgets";
import React, { useState } from "react";
import { project } from "Service/Endpoints/endpoints";
import { getSessionStorage } from "Service/auth/auth";
// import { apiCalling } from "Service/Utilities/apiCalling";
import moment from "moment";
import { ImEye } from "react-icons/im";
import { OBSERVATION_APPROVAL, OBSERVATION_REJECT, PENDING_STATUS } from "Configration/Utilities/Constants/Constants";
import GetApiData from "Configration/Utilities/getApiData/GetApiData";

const cellTemplate = (params) => {
  const style = {
    display: "flex",
    fontSize: "16px",
    background: "transparent",
    border: "0px solid transparent",
    padding: "4px 0px 5px 0px",
    cursor: "pointer",
  };

  const toggleAssetPopUp = () => {
    params.toggleSkipApprovalPopUp(params.data);
  };

  return (
    <div style={{ display: "flex", gap: "8px", justifyContent: "center" }}>
      {params.data.STATUSCODE === PENDING_STATUS && (
        <button type="button" style={style} title="View Detail" onClick={() => toggleAssetPopUp()}>
          <ImEye style={{ color: "rgb(52, 73, 94)" }} />
        </button>
      )}
    </div>
  );
};

function SkipApprovalsLogic() {
  const setAlertMessage = AlertMessage();
  const [selectedProjectData, setSelectedProjectData] = useState(null);
  const user = getSessionStorage("userData");

  // get Division Detail List state
  const [divisionList, setDivisionList] = useState([]);
  const [isLoadingDivisionList, setIsLoadingDivisionList] = useState(false);

  // get employeeAsset List state
  const [employeeList, setEmployeeList] = useState([]);
  const [isLoadingEmployeeList, setIsLoadingEmployeeList] = useState(false);

  // getWorkingAssignment State
  const [skipApprovalsListData, setSkipApprovalsListData] = useState([]);
  const [isLoadingSkipApprovalsList, setIsLoadingSkipApprovalsList] = useState(false);

  // ObsevationApprovalReject Loading state
  const [isLoadingApproval, setIsLoadingApproval] = useState(false);
  const [isLoadingReject, setIsLoadingReject] = useState(false);

  // form value State
  const [formValues, setFormValues] = useState({
    txtDivision: null,
    txtEmployee: [],
  });
  const [AssetsGridList, setAssetGridList] = useState([]);
  const [isloadingAssetsGrid, setIsLoadingAssetGrid] = useState([]);

  // get employee GRID API state
  const [empAssetGridApi, setEmpAssetGridApi] = useState([]);

  // toggle popUp state
  const [isActivePopUp, setIsActivePopUp] = useState({
    isOpen: false,
    popUpData: null,
  });
  const [isActiveAssinPopUp, setIsActiveAssinPopUp] = useState(false);
  const [gridApi, setGridApi] = useState();
  const [searchText, setSearchText] = useState("");

  /* ************************************* Code For toggle popUp Start ***************************** */

  const toggleSkipApprovalPopUp = (data) => {
    setIsActivePopUp((values) => ({
      isOpen: !values.isOpen,
      popUpData: data,
    }));
  };

  const toggleAssignPopUp = () => {
    setIsActiveAssinPopUp(!isActiveAssinPopUp);
  };

  /* ************************************* Code For toggle popUp end ***************************** */

  /* ************************************* Code For GridColumDef start ***************************** */

  const [skipAppovalColumDef] = useState([
    {
      field: "#",
      headerName: "Action",
      width: 80,
      pinned: "left",
      cellRenderer: cellTemplate,
      cellRendererParams: {
        toggleSkipApprovalPopUp,
      },
    },
    { field: "#", headerName: "Sr No.", pinned: "left", valueGetter: "node.rowIndex + 1", width: 80 },
    {
      field: "FROMEMPLOYEE",
      headerName: "From Employee",
      width: 180,
      cellRenderer: (node) => {
        return node.value ? `${node.value} (${node.data.OWNEREMPLOYEEID})` : "";
      },
    },
    {
      field: "TOEMPLOYEE",
      headerName: "To Employee ",
      width: 180,
      cellRenderer: (node) => {
        return node.value ? `${node.value} (${node.data.ASSIGNEDEMPCODE})` : "";
      },
    },
    { field: "STATUS", headerName: "Status", width: 180 },
    {
      field: "INSERTDATE",
      headerName: "From Date",
      width: 180,
      cellRenderer: (node) => {
        return node.value ? moment(node.value).format("DD-MM-YYYY") : "";
      },
    },
    {
      field: "INSERTDATE",
      headerName: "To Date",
      width: 180,
      cellRenderer: (node) => {
        return node.value ? moment(node.value).format("DD-MM-YYYY") : "";
      },
    },
  ]);

  const [employeeAssetColumDef] = useState([
    { field: "#", headerName: "Action", width: 80, checkboxSelection: true, showDisabledCheckboxes: true },
    { field: "EMPLOYEENAME", headerName: "Employee Name", width: 220 },
    { field: "EMPLOYEEID", headerName: "Employee Id", width: 140 },
  ]);

  /* ************************************* Code For GridColumDef end ***************************** */

  /* ************************************* Code For OnGridRady Function Start ***************************** */

  const onEmpAssetGridReady = (params) => {
    setEmpAssetGridApi(params.api);
  };

  /* ************************************* Code For OnGridRady Function End ***************************** */

  /* ************************************* Code For Clear Form Start ***************************** */

  const clearFormValue = () => {
    setFormValues({
      txtDivision: null,
      txtEmployee: [],
    });
  };

  /* ************************************* Code For Clear Form  End ***************************** */

  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  const onChangeSearchText = (val) => {
    setSearchText(val);
    gridApi.setQuickFilter(val);
    gridApi.refreshCells();
  };

  const cleareSerchText = (data) => {
    if (data) {
      if (searchText && searchText.toLowerCase().includes("#")) {
        onChangeSearchText("");
      }
    }
  };

  /* ************************************* Code For Api Calling Start ***************************** */

  // getWorkingAssignMent List
  // const getSkipApprovalList = async (ProjectData) => {
  //   debugger;
  //   setSelectedProjectData(ProjectData);
  //   try {
  //     setIsLoadingSkipApprovalsList(true);
  //     const formData = {
  //       main: {
  //         divisionid:
  //           user && user.UserProfileID
  //             ? user.UserProfileID.toString() === "100301" || user.UserProfileID.toString() === "100302"
  //               ? ProjectData && ProjectData.DIVISIONIDs
  //                 ? ProjectData.DIVISIONIDs
  //                 : ""
  //               : ""
  //             : "",
  //         profileid: user && user.UserProfileID ? user.UserProfileID : null,
  //         projectid: ProjectData && ProjectData.PROJECTID ? ProjectData.PROJECTID : "",
  //       },
  //     };
  //     const result = await apiCalling(formData, project.projectList.GetObservationPendingSelect);
  //     setIsLoadingSkipApprovalsList(false);
  //     console.log(result, project.projectList.GetObservationPendingSelect);
  //     if (result && result.responseCode === 1) {
  //       if (result.responseData && result.responseData.Table && result.responseData.Table.length > 0) {
  //         const jsonstate = [];
  //         result.responseData.Table.forEach((data) => {
  //           let isExist = false;
  //           if (jsonstate.length > 0) {
  //             isExist = jsonstate.some((x) => x.CREATEDBY === data.CREATEDBY);
  //           }
  //           if (!isExist) {
  //             const newView = { ...data, Assets: [] };
  //             const findData = result.responseData.Table.filter((x) => x.CREATEDBY === data.CREATEDBY);
  //             if (findData) {
  //               newView.Assets.push(...findData);
  //             }
  //             console.log(newView);
  //             jsonstate.push(newView);
  //           }
  //         });
  //         setSkipApprovalsListData(jsonstate);
  //         console.log(jsonstate);
  //       } else {
  //         setSkipApprovalsListData([]);
  //       }
  //     } else {
  //       setAlertMessage({
  //         type: "error",
  //         message: result.responseMessage,
  //       });
  //       setSkipApprovalsListData([]);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //     setAlertMessage({
  //       type: "error",
  //       message: error,
  //     });
  //     setSkipApprovalsListData([]);
  //   }
  // };

  const getJsonState = (responseData) => {
    setSkipApprovalsListData(responseData);
  };

  const getSkipApprovalList = async (ProjectData) => {
    setSelectedProjectData(ProjectData);
    const formData = {
      main: {
        divisionid: ProjectData && ProjectData.DIVISIONIDs ? ProjectData.DIVISIONIDs : "",
        profileid: user && user.UserProfileID ? user.UserProfileID : null,
        projectid: ProjectData && ProjectData.PROJECTID ? ProjectData.PROJECTID : "",
      },
    };
    const request = {
      setDatalist: getJsonState,
      setLoad: setIsLoadingSkipApprovalsList,
      requestData: formData,
      apiPath: project.projectList.GetObservationPendingSelect,
      setAlert: setAlertMessage,
      fun: cleareSerchText,
    };
    GetApiData(request);
  };

  // get Divisionlist

  const getDivisionDetailsList = async () => {
    const formData = {
      main: {
        divisionid: selectedProjectData && selectedProjectData.DIVISIONIDs ? selectedProjectData.DIVISIONIDs : "",
        profileid: user && user.UserProfileID ? user.UserProfileID : null,
        projectid: selectedProjectData && selectedProjectData.PROJECTID ? selectedProjectData.PROJECTID : "",
      },
    };
    const request = {
      setDatalist: setDivisionList,
      setLoad: setIsLoadingDivisionList,
      requestData: formData,
      apiPath: project.projectList.GetKPCLDivisionDetail,
      setAlert: setAlertMessage,
      fun: null,
    };
    GetApiData(request);
  };

  // const getDivisionDetailsList = async () => {
  //   try {
  //     setIsLoadingDivisionList(true);
  //     const formData = {
  //       main: {
  //         divisionid:
  //           user && user.UserProfileID
  //             ? user.UserProfileID.toString() === "100301" || user.UserProfileID.toString() === "100302"
  //               ? selectedProjectData && selectedProjectData.DIVISIONIDs
  //                 ? selectedProjectData.DIVISIONIDs
  //                 : ""
  //               : ""
  //             : "",
  //         profileid: user && user.UserProfileID ? user.UserProfileID : null,
  //         projectid: selectedProjectData && selectedProjectData.PROJECTID ? selectedProjectData.PROJECTID : "",
  //       },
  //     };
  //     const result = await apiCalling(formData, project.projectList.GetKPCLDivisionDetail);
  //     setIsLoadingDivisionList(false);
  //     console.log(result);
  //     if (result && result.responseCode === 1) {
  //       if (result && result.responseData && result.responseData.Table) {
  //         setDivisionList(result.responseData.Table);
  //       }
  //     } else {
  //       setAlertMessage({
  //         type: "error",
  //         message: result.responseMessage,
  //       });
  //       setDivisionList([]);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //     setAlertMessage({
  //       type: "error",
  //       message: error,
  //     });
  //     setDivisionList([]);
  //   }
  // };

  // get EmployeeList

  const getEmployeeList = async (selectedDivision) => {
    const formData = {
      main: {
        divisionid: selectedDivision && selectedDivision.DIVISIONID ? selectedDivision.DIVISIONID.toString() : "",
        profileid: user && user.UserProfileID ? user.UserProfileID : null,
        projectid: selectedDivision && selectedDivision.PROJECTID ? selectedDivision.PROJECTID : "",
      },
    };
    const request = {
      setDatalist: setEmployeeList,
      setLoad: setIsLoadingEmployeeList,
      requestData: formData,
      apiPath: project.projectList.GetKPCLTeam,
      setAlert: setAlertMessage,
      fun: null,
    };
    GetApiData(request);
  };

  // const getEmployeeList = async (selectedDivision) => {
  //   try {
  //     setIsLoadingEmployeeList(true);
  //     const formData = {
  //       main: {
  //         divisionid: selectedDivision && selectedDivision.DIVISIONID ? selectedDivision.DIVISIONID.toString() : "",
  //         profileid: user && user.UserProfileID ? user.UserProfileID : null,
  //         projectid: selectedDivision && selectedDivision.PROJECTID ? selectedDivision.PROJECTID : "",
  //       },
  //     };
  //     const result = await apiCalling(formData, project.projectList.GetKPCLTeam);
  //     setIsLoadingEmployeeList(false);
  //     console.log(result);
  //     if (result && result.responseCode === 1) {
  //       if (result && result.responseData && result.responseData.Table) {
  //         setEmployeeList(result.responseData.Table);
  //       }
  //     } else {
  //       setAlertMessage({
  //         type: "error",
  //         message: result.responseMessage,
  //       });
  //       setEmployeeList([]);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //     setAlertMessage({
  //       type: "error",
  //       message: error,
  //     });
  //     setEmployeeList([]);
  //   }
  // };

  // get EmployeeList

  // const ObsevationApprovalReject = async (mode, data) => {
  //   debugger;
  //   try {
  //     if (mode === OBSERVATION_REJECT) {
  //       setIsLoadingReject(true);
  //     }
  //     if (mode === OBSERVATION_REJECT) {
  //       setIsLoadingApproval(true);
  //     }
  //     // const equipNumber = data.Assets.map((x) => {
  //     //   return x.ASSETCODE;
  //     // });
  //     const formData = {
  //       main: {
  //         observationskipid: data && data.OBSERVATIONSKIPID ? data.OBSERVATIONSKIPID : null,
  //         viewmode: mode,
  //         empassignedid: formValues && formValues.txtEmployee && formValues.txtEmployee.length > 0 ? formValues.txtEmployee[0].EMPLOYEEID : 0,
  //         insertuserid: user && user.UserProfileID ? user.UserProfileID : null,
  //       },
  //     };
  //     const result = await apiCalling(formData, project.projectList.SetObservationApproveReject);
  //     if (mode === OBSERVATION_REJECT) {
  //       setIsLoadingReject(false);
  //     }
  //     if (mode === OBSERVATION_REJECT) {
  //       setIsLoadingApproval(false);
  //     }
  //     console.log(result, project.projectList.SetObservationApproveReject);
  //     if (result && result.responseCode === 1) {
  //       setFormValues({
  //         txtDivision: null,
  //         txtEmployee: [],
  //       });
  //       setEmployeeList([]);
  //       toggleSkipApprovalPopUp();
  //       getSkipApprovalList(selectedProjectData);
  //       setAlertMessage({
  //         type: "success",
  //         message: result.responseMessage,
  //       });
  //     } else {
  //       setAlertMessage({
  //         type: "error",
  //         message: result.responseMessage,
  //       });
  //     }
  //   } catch (error) {
  //     console.log(error);
  //     setAlertMessage({
  //       type: "error",
  //       message: error,
  //     });
  //   }
  // };

  const dateToSpecificFormat = (date, format) => {
    try {
      const d = new Date(date);
      const convertedDate = moment(d).format(format);
      return convertedDate;
    } catch {
      return null;
    }
  };

  const ObsevationReject = async (mode, data) => {
    let setLodingState;

    switch (mode) {
      case OBSERVATION_REJECT:
        setLodingState = setIsLoadingReject;

        break;
      case OBSERVATION_APPROVAL:
        setLodingState = setIsLoadingApproval;

        break;
      default:
        break;
    }

    const ActionsFunctions = () => {
      setFormValues({
        txtDivision: null,
        txtEmployee: [],
      });
      setEmployeeList([]);
      toggleSkipApprovalPopUp();
      getSkipApprovalList(selectedProjectData);
    };
    const setFun = (data) => {
      console.log(data);
    };

    const formData = {
      main: {
        observationskipid: data && data.OBSERVATIONSKIPID ? data.OBSERVATIONSKIPID : null,
        viewmode: mode,
        empassignedid: formValues && formValues.txtEmployee && formValues.txtEmployee.length > 0 ? formValues.txtEmployee[0].EMPLOYEEID : 0,
        insertuserid: user && user.UserProfileID ? user.UserProfileID : null,
      },
    };

    const request = {
      setDatalist: setFun,
      setLoad: setLodingState,
      requestData: formData,
      apiPath: project.projectList.SetObservationApproveReject,
      setAlert: setAlertMessage,
      fun: ActionsFunctions,
      message: true,
    };
    GetApiData(request);
  };

  const ObsevationApprove = async (mode, data) => {
    debugger;
    let setLodingState;

    console.log(AssetsGridList, "FormValues");
    switch (mode) {
      case OBSERVATION_REJECT:
        setLodingState = setIsLoadingReject;
        break;
      case OBSERVATION_APPROVAL:
        setLodingState = setIsLoadingApproval;
        break;
      default:
        break;
    }

    const ActionsFunctions = () => {
      setFormValues({
        txtDivision: null,
        txtEmployee: [],
      });
      setEmployeeList([]);
      toggleSkipApprovalPopUp();
      getSkipApprovalList(selectedProjectData);
    };

    const setFun = (data) => {
      console.log(data);
    };

    const assetsList =
      AssetsGridList &&
      AssetsGridList.map((data) => {
        return data.ASSETID;
      }).join(",");

    console.log(assetsList, "hello");

    const formData = {
      main: {
        assetemployeeassignmentid: 0,
        viewmode: "APPROVE",
        equipmentnumber: assetsList && assetsList,
        employeeid: formValues && formValues.txtEmployee && formValues.txtEmployee.length > 0 ? formValues.txtEmployee[0].EMPLOYEEID : 0,
        lastemployee: data && data.OWNEREMPLOYEEID ? data.OWNEREMPLOYEEID : 0,
        startdate: dateToSpecificFormat(new Date(), "DD-MM-YYYY"),
        enddate: dateToSpecificFormat(new Date(), "DD-MM-YYYY"),
        isactive: 1,
        ispermanent: 0,
        isskippedassigned: 0,
        observationskipid: data && data.OBSERVATIONSKIPID ? data.OBSERVATIONSKIPID : null,
        inseruserid: user && user.UserProfileID ? user.UserProfileID : null,
      },
    };

    const request = {
      setDatalist: setFun,
      setLoad: setLodingState,
      requestData: formData,
      apiPath: "KPCL/SkipAssignmentOnSkip",
      setAlert: setAlertMessage,
      fun: ActionsFunctions,
      message: true,
    };
    GetApiData(request);
  };

  // getSkipapprovalAssets

  const GetObservationAssetSelect = async (empId) => {
    setAssetGridList([]);
    const formData = {
      main: {
        employeeid: empId,
      },
    };
    const request = {
      setDatalist: setAssetGridList,
      setLoad: setIsLoadingAssetGrid,
      requestData: formData,
      apiPath: project.projectList.GetEmployeeAssetAppSelect,
      setAlert: setAlertMessage,
      fun: null,
    };
    GetApiData(request);
  };

  /* ************************************* Code For Api Calling end ***************************** */

  /* ************************************* Code For  Update State Start ***************************** */

  const updateFormState = (value, name) => {
    console.log(formValues);
    setFormValues((values) => ({
      ...values,
      [name]: value,
    }));

    if (name === "txtDivision") {
      if (!value || typeof value === "undefined" || value === null) {
        setEmployeeList([]);
      } else if (value && value.DIVISIONID) {
        getEmployeeList(value);
      }
    }
  };

  /* ************************************* Code For Update State End ***************************** */

  /* ************************************* Code For getSelectedRowData start ***************************** */

  // Code for Assets checking Checked data

  const getSelectedRowData = () => {
    const selectedNodes = empAssetGridApi.getSelectedNodes();
    const selectedData = selectedNodes.map((node) => node.data);
    return selectedData;
  };

  const SaveSelectadAssetsRowData = () => {
    if (empAssetGridApi) {
      const checkedItem = getSelectedRowData();
      if (checkedItem.length > 0) {
        updateFormState(checkedItem, "txtEmployee");
        toggleAssignPopUp();
      }
    }
  };

  /* ************************************* Code For getSelectedRowData  End ***************************** */

  return {
    getSkipApprovalList,
    selectedProjectData,
    skipApprovalsListData,
    isLoadingSkipApprovalsList,
    skipAppovalColumDef,
    toggleSkipApprovalPopUp,
    isActivePopUp,
    toggleAssignPopUp,
    isActiveAssinPopUp,
    getDivisionDetailsList,
    divisionList,
    isLoadingDivisionList,
    formValues,
    updateFormState,
    employeeList,
    isLoadingEmployeeList,
    employeeAssetColumDef,
    onEmpAssetGridReady,
    SaveSelectadAssetsRowData,
    empAssetGridApi,
    isLoadingReject,
    isLoadingApproval,
    AssetsGridList,
    isloadingAssetsGrid,
    GetObservationAssetSelect,
    clearFormValue,
    ObsevationReject,
    ObsevationApprove,
    onChangeSearchText,
    searchText,
    onGridReady,
  };
}

export default SkipApprovalsLogic;
