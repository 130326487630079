import React from "react";
import { ImEye } from "react-icons/im";
import styles from "./ProjectsListView.module.scss";

function ProjectsListView({ filteredProjectListData, toggleDetailModal }) {
  return (
    <div>
      <table className={styles.tablestyle}>
        <thead className={styles.tableHeading}>
          <tr>
            <th>Action</th>
            <th>Project Name</th>
            <th>Mobile Number</th>
            <th>Address</th>
          </tr>
        </thead>
        <tbody className={styles.tableBody} style={{ 'cursor': 'pointer' }}>
          {filteredProjectListData &&
            filteredProjectListData.map((data, i) => (
              <tr key={i} onClick={() => toggleDetailModal(true, data)}>
                <td>
                  <button className={styles.action} 
                  // style={{'display':'flex', 'flexDirection':'column', 'justifyContent':'center'}} 
                  style={{
                    backgroundColor: '#3498db',   
                    color: '#fff',                
                    padding: '5px 10px',        
                    borderRadius: '5px',          
                    border: 'none',               
                    cursor: 'pointer',           
                    fontSize: '12px',            
                    fontWeight: 'bold',
                    justifyContent:'center !important'            
                  }}
                  >
                    {/* <ImEye style={{ color: "rgb(52, 73, 94)", fontSize: "24px" }}  /> */}
                    Click here!
                  </button>

                </td>
                <td>{data && data.PROJECTNAME}</td>
                <td>{data && data.PHEADMOBILENO}</td>
                <td>{data && data.PADDRESS}</td>
              </tr>
            ))}
        </tbody>

      </table>
    </div>

  );


}

export default ProjectsListView;
