import { useEffect, useState } from "react";
import { getSessionStorage } from "Service/auth/auth";
import { project } from "Service/Endpoints/endpoints";
import { AlertMessage } from "Framework/Components/Widgets";
import GetApiData from "Configration/Utilities/getApiData/GetApiData";

function pmListLogic() {
    const setAlertMessage = AlertMessage();
    const user = getSessionStorage("userData");
    const selectedProjectData = getSessionStorage("selctedProject");
    const [pmList, setpmList] = useState([]);
    const [isLoadingpmList, setisLoadingpmList] = useState(false);
    const[pmlistContents, setPmListContents]=useState([]);
    const [pmListGridApi, setpmListGridApi] = useState();
  
    // toggle state
    const [isShowAddpmListPopUp, setIsShowAddpmListPopUp] = useState(false);
    const [searchText, setSearchText] = useState("");

    /* ************************************* Code For GridColumDef start ***************************** */
   
  
    // const [pmListColumDef] = useState([
    //     { headerName: "Sr. No.", field: "#", width: 80, pinned: "left", valueGetter: "node.rowIndex + 1" },
    //   { field: "ASSETNAME", headerName: "Asset Name", width: 180, },
    //   { field: "ASSETCODE", headerName: "Assets Code", width: 180 },
    // { field: "DIVISIONNAME", headerName: "Division Name", width: 200 },
    // { field: "FUNCTIONALLOCATION", headerName: "Functional Locations", width: 240 },
    // {field:"LOCATION", headerName:"Floor Location", width:180},
    // { field: "PEQUIPMENTNUMBER", headerName: "Equipment Number", width: 180 },
    // { field:"ASSETLOCALID", headerName:"Asset Local Id", width:180},
    // { field:"MANUFACTURER", headerName:"Manufacturer", width:180},
    // { field:"ASSET_STATUS", headerName:"Asset Status", width:180},
    // ]);

    let pmListColumDef;

    if (selectedProjectData.PROJECTID === 1 || selectedProjectData.PROJECTID === 2 || selectedProjectData.PROJECTID === 30) {
      pmListColumDef = [
        { headerName: "Sr. No.", field: "#", width: 80, pinned: "left", valueGetter: "node.rowIndex + 1" },
        { field: "ASSETNAME", headerName: "Asset Name", width: 180 },
        { field: "ASSETCODE", headerName: "Assets Code", width: 180 },
        { field: "DIVISIONNAME", headerName: "Division Name", width: 200 },
        { field: "FUNCTIONALLOCATION", headerName: "Functional Locations", width: 240 },
        // { field: "LOCATION", headerName: "Floor Location", width: 180 },
        { field: "PEQUIPMENTNUMBER", headerName: "Equipment Number", width: 180 },
        { field: "ASSETLOCALID", headerName: "Asset Local Id", width: 180 },
        { field: "MANUFACTURER", headerName: "Manufacturer", width: 180 },
        { field: "ASSET_STATUS", headerName: "Asset Status", width: 180 },
      ];
    } else {
      pmListColumDef = [
        { headerName: "Sr. No.", field: "#", width: 80, pinned: "left", valueGetter: "node.rowIndex + 1" },
        { field: "ASSETNAME", headerName: "Asset Name", width: 180 },
        { field: "ASSETCODE", headerName: "Assets Code", width: 180 },
        { field: "DIVISIONNAME", headerName: "Division Name", width: 200 },
        { field: "FUNCTIONALLOCATION", headerName: "Functional Locations", width: 240 },
        { field: "LOCATION", headerName: "Floor Location", width: 180 },
        { field: "PEQUIPMENTNUMBER", headerName: "Equipment Number", width: 180 },
        { field: "ASSETLOCALID", headerName: "Asset Local Id", width: 180 },
        { field: "MANUFACTURER", headerName: "Manufacturer", width: 180 },
        { field: "ASSET_STATUS", headerName: "Asset Status", width: 180 },
      ];
    }
    
  
    /* ************************************* Code For GridColumDef end ***************************** */
  
    /* ************************************* Code For toggle popup start ***************************** */
  
    const toggleAddObservationPopUp = () => {
        setIsShowAddpmListPopUp(!isShowAddpmListPopUp);
    };
  
    /* ************************************* Code For toggle popup end ***************************** */
  
    /* ************************************* Code For OnGridRady Function Start ***************************** */
  
    const onObservationGridReady = (params) => {
      setpmListGridApi(params.api);
    };
  
    /* ************************************* Code For OnGridRady Function end ***************************** */
    const onChangeSearchText = (val) => {
        setSearchText(val);
        pmListGridApi.setQuickFilter(val);
        pmListGridApi.refreshCells();
      };
    
      const cleareSerchText = (data) => {
        if (data) {
          if (searchText && searchText.toLowerCase().includes("#")) {
            onChangeSearchText("");
          }
        }
      };
   
    /* ************************************* Code For Api Calling Start ***************************** */
  
    const getpmListing = () => {
      // setSelectedProjectData(ProjectData);
      const formData = {
          main: {
              divisionid: selectedProjectData && selectedProjectData.DIVISIONIDs ? selectedProjectData.DIVISIONIDs : "",
              profileid: user && user.UserProfileID ? user.UserProfileID : null,
              projectid: selectedProjectData && selectedProjectData.PROJECTID ? selectedProjectData.PROJECTID : "",
          },
      };
      console.log("formData", formData)
      const request = {
          setDatalist: setpmList,
          setLoad: setisLoadingpmList,
          requestData: formData,
          apiPath: project.projectList.GetKPCLAssetSelect,
          setAlert: setAlertMessage,
          fun: cleareSerchText,
      };
      GetApiData(request);
  };

  // const filterPmList = () => {
  //   pmList.forEach((list)=>{
  //     if(list.ASSET_STATUS==='MAINTENANCE'){
  //       setPmListContents(list);
  //     }
  //   })

  // };
  // console.log("pmlistContents",pmlistContents)
  const filterPmList = () => {
    const filteredList = pmList.filter((list) => list.ASSET_STATUS === 'MAINTENANCE');
    console.log("filteredList",filteredList)
    setPmListContents(filteredList);
  };
  

 
 
  useEffect(() => {
    getpmListing(); 
  },[]);
  
    return {
        pmList,
        isLoadingpmList,
        pmListColumDef,
      toggleAddObservationPopUp,
      isShowAddpmListPopUp,
      setpmList,
      getpmListing,
      onObservationGridReady,
      pmListGridApi,
      onChangeSearchText,
      searchText,
      pmlistContents,
      setPmListContents,
      filterPmList
    };
  }
  
  export default pmListLogic;