import { AlertMessage } from "Framework/Components/Widgets";
import moment from "moment";
import { useState } from "react";
import { getSessionStorage } from "Service/auth/auth";
import { project } from "Service/Endpoints/endpoints";
import { apiCalling } from "Service/Utilities/apiCalling";

function AssetHistoryLogic() {
  const setAlertMessage = AlertMessage();
  const user = getSessionStorage("userData");
  // const [selectedProjectData, setSelectedProjectData] = useState(null);
  const selectedProjectData = getSessionStorage("selctedProject");

  // code for  Grid State
  const [assetsHistoryList, setAssetsHistoryList] = useState(null);
  const [isLoadingAssetsHistory, setIsLoadingAssetsHistory] = useState(false);
  const [gridApi, setGridApi] = useState();
  const [searchText, setSearchText] = useState("");

  // code for form value state
  const [formValues, setFormValues] = useState({
    txtFromDate: moment().subtract(10, "days").format("YYYY-MM-DD"),
    txtToDate: moment().format("YYYY-MM-DD"),
  });

  /* ************************************* Code For GridColumDef start ***************************** */

  const [assetHistoryColumDef] = useState([
    
    { field: "ASSETNAME", headerName: "Assset Name", width: 340, rowGroup: true, hide: true },

    {
      field: "ACTIONDATE",
      headerName: "Date",
      width: 110,
      cellRenderer: (node) => {
        return node.value ? moment(node.value).format("DD-MM-YYYY") : "";
      },
    },
    { field: "ACTION", headerName: "Action", width: 140 },
    { field: "ASSETCODE", headerName: "Asset Code", width: 160 },
    { field: "DIVISIONID", headerName: "Division Id", width: 120 },
    { field: "DIVISIONNAME", headerName: "Division Name", width: 160 },
    { field: "FROMEMPLOYEEID", headerName: "Equipment Number", width: 160 },
    { field: "FUNCTIONALLOCATION", headerName: "Functional Location", width: 310 },
    { field: "MASTERDISPLAYNAME", headerName: "Status", width: 180 },
    { field: "PEQUIPMENTNUMBER", headerName: "P Equipment Number", width: 180 },
  ]);

  /* ************************************* Code For GridColumDef end ***************************** */

  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  const onChangeSearchText = (val) => {
    setSearchText(val);
    gridApi.setQuickFilter(val);
    gridApi.refreshCells();
  };

  /* ************************************* Code For Api Calling Start ***************************** */

  const getAssetHistiryListData = async () => {
    // setSelectedProjectData(ProjectData);
    try {
      setIsLoadingAssetsHistory(true);
      const formData = {
        main: {
          divisionid:
            user && user.UserProfileID
              ? user.UserProfileID.toString() === "100301" || user.UserProfileID.toString() === "100302"
                ? selectedProjectData && selectedProjectData.DIVISIONIDs
                  ? selectedProjectData.DIVISIONIDs
                  : ""
                : ""
              : "",
          profileid: user && user.UserProfileID ? user.UserProfileID : null,
          projectid: selectedProjectData && selectedProjectData.PROJECTID ? selectedProjectData.PROJECTID : "",
          fromdate: formValues.txtFromDate ? moment(formValues.txtFromDate).format("DD-MM-YYYY") : moment().subtract(10, "days").format("DD-MM-YYYY"),
          todate: formValues.txtToDate ? moment(formValues.txtToDate).format("DD-MM-YYYY") : moment().format("DD-MM-YYYY"),
        },
      };
      const result = await apiCalling(formData, project.projectList.GetAssetHistorySelect);
      setIsLoadingAssetsHistory(false);
      console.log(result, project.projectList.GetAssetHistorySelect);
      if (result && result.responseCode === 1) {
        if (result && result.responseData && result.responseData.Table) {
          setAssetsHistoryList(result.responseData.Table);
          if (searchText) {
            onChangeSearchText("");
          }
        }
      } else {
        setAlertMessage({
          type: "error",
          message: result.responseMessage,
        });
        setAssetsHistoryList([]);
      }
    } catch (error) {
      console.log(error);
      setAlertMessage({
        type: "error",
        message: error,
      });
      setAssetsHistoryList([]);
    }
  };

  /* ************************************* Code For Api Calling end ***************************** */

  /* ************************************* Code For  Update State Start ***************************** */
  const updateFormState = (value, name) => {
    console.log(formValues);
    setFormValues((values) => ({
      ...values,
      [name]: value,
    }));
  };


  console.log("assetsHistoryList",assetsHistoryList)
  /* ************************************* Code For  Update State Start ***************************** */

  return {
    selectedProjectData,
    getAssetHistiryListData,
    assetsHistoryList,
    isLoadingAssetsHistory,
    formValues,
    updateFormState,
    assetHistoryColumDef,
    onGridReady,
    onChangeSearchText,
    searchText,
  };
}

export default AssetHistoryLogic;
