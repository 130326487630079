import React from "react";
import { DataGrid, PageBar } from "Framework/Components/Layout";
import { Loader } from "Framework/Components/Widgets";
import BizClass from "./Dashboard.module.scss";
import DashboardLogics from "./Logic/DashboardLogics";
import ObservationReportDetails from "./View/Modal/ObservationReportDetails";

function Dashboard() {
  const {
    reportList,
    isLoadingreport,
    searchText,
    onGridReady,
    onChangeSearchText,
    getObeservationReportData,
    ListViewColumDef,
    SelectedProject,
    toggleObservetionDetailsePopUp,
    isActivePopUp,
    ObserVationDetailsProps,
    updateReportStateValue,
    reportFormValues,
  } = DashboardLogics();

  return (
    <>
      {isActivePopUp.isOpen && (
        <ObservationReportDetails
          toggleObservetionDetailsePopUp={toggleObservetionDetailsePopUp}
          popUpData={isActivePopUp.popUpData}
          ObserVationDetailsProps={ObserVationDetailsProps}
        />
      )}
      <div className={BizClass.DashboardPages}>
        <PageBar className={BizClass.CustomPageBar}>
          <span>Observation Status Report Of {SelectedProject && SelectedProject.PROJECTNAME ? SelectedProject.PROJECTNAME : ""}</span>

          <div style={{ display: "flex" }}>
            <div className={BizClass.Input_Group_Fild}>
              <span>From Data :</span>
              <PageBar.Input
                type="date"
                name="txtFromDate"
                onChange={(e) => updateReportStateValue(e.target.name, e.target.value)}
                value={reportFormValues.txtFromDate}
              />
            </div>
            <div className={BizClass.Input_Group_Fild}>
              <span>To Data :</span>
              <PageBar.Input
                type="date"
                name="txtToDate"
                onChange={(e) => updateReportStateValue(e.target.name, e.target.value)}
                value={reportFormValues.txtToDate}
              />
            </div>
            <PageBar.Button onClick={() => getObeservationReportData()}>Refresh</PageBar.Button>
          </div>

          <PageBar.Search onChange={(e) => onChangeSearchText(e.target.value)} value={searchText} />
        </PageBar>
        <DataGrid rowData={reportList} columnDefs={ListViewColumDef} loader={isLoadingreport ? <Loader /> : null} onGridReady={onGridReady} />
      </div>
    </>
  );
}

export default Dashboard;
