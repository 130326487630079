import axios from "Configration/axios/axios";
import { Buffer } from "buffer";
import publicIp from "public-ip";
import { checkAuthExist, getSessionStorage } from "Service/auth/auth";

const pako = require("pako");

export const loginApiCalling = async (requestApiData, apiPath, header) => {
  debugger;
  try {
    const ip = await publicIp.v4();
    const requestData = {
      ...requestApiData.main,
      objCommon: {
        ...requestApiData.objCommon,
        insertedUserID: "0",
        insertedIPAddress: ip,
        dateShort: "yyyy-MM-dd",
        dateLong: "yyyy-MM-dd HH:mm:ss",
      },
    };

    const response = await axios.post(apiPath, requestData, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "",
        ...header,
      },
    });

    if (response && response.status === 200) {
      const result = await response.data;
      if (result.responseCode.toString() === "1") {
        const buff = Buffer.from(result.responseDynamic ? result.responseDynamic : "", "base64");
        if (buff.length !== 0) {
          const data = JSON.parse(pako.inflate(buff, { to: "string" }));
          return { responseCode: 1, responseData: data, responseMessage: result.responseMessage };
        }
        return { responseCode: 1, responseData: [], responseMessage: result.responseMessage };
      }
      return { responseCode: Number(result.responseCode), responseData: null, responseMessage: result.responseMessage };
    }
    return { responseCode: 0, responseData: null, responseMessage: "" };
  } catch (error) {
    if (error && error.response && error.response.status === 401) {
      return { responseCode: 401, responseData: null, responseMessage: "Something went wrong" };
    }
    return {
      responseCode: 0,
      responseData: null,
      responseMessage: error && error.response && error.response.data && error.response.data.responseMessage ? error.response.data.responseMessage : "",
    };
  }
};

export const apiCalling = async (requestApiData, apiPath, header) => {
  debugger;
  try {
    if (!checkAuthExist()) {
      return { responseCode: 401, responseData: null, responseMessage: "Session Expired" };
    }
    const ip = await publicIp.v4();
    const user = getSessionStorage("userData");
    const requestData = {
      ...requestApiData.main,
      objCommon: {
        ...requestApiData.objCommon,
        insertedUserID: user && user.AppAccessUID ? user.AppAccessUID : "0",
        insertedIPAddress: ip,
        dateShort: "yyyy-MM-dd",
        dateLong: "yyyy-MM-dd HH:mm:ss",
      },
    };

    const response = await axios.post(apiPath, requestData, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: user && user.token && user.token.Token ? user.token.Token : "",
        UserName: user && user.UserName ? user.UserName : "",
        ...header,
      },
    });

    if (response && response.status === 200) {
      const result = await response.data;
      if (result.responseCode.toString() === "1") {
        const buff = Buffer.from(result.responseDynamic ? result.responseDynamic : "", "base64");
        if (buff.length !== 0) {
          const data = JSON.parse(pako.inflate(buff, { to: "string" }));
          return { responseCode: 1, responseData: data, responseMessage: result.responseMessage };
        }
        return { responseCode: 1, responseData: [], responseMessage: result.responseMessage };
      }
      return { responseCode: Number(result.responseCode), responseData: null, responseMessage: result.responseMessage };
    }
    return { responseCode: 0, responseData: null, responseMessage: "" };
  } catch (error) {
    if (!checkAuthExist() && error && error.response && error.response.status === 401) {
      return { responseCode: 401, responseData: null, responseMessage: "Something went wrong" };
    }
    console.log(error);
    return {
      responseCode: 0,
      responseData: null,
      responseMessage: error && error.response && error.response.data && error.response.statusText ? error.response.statusText : "Something went wrong",
    };
  }
};
