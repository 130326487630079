// import React from "react";
// import { DataGrid, PageBar } from "Framework/Components/Layout";
// import { Loader } from "Framework/Components/Widgets";
// import MenuManagementLogic from "./Logic/MenuManagementLogic";
// import BizClass from "./MenuManagement.module.scss";

// function MenuManagement() {
//   const {
//     GridColumnDefs,
//     getMenuListData,
//     treeMenuListData,
//     isLoading,
//     onGridReady,
//     menuItemSearch,
//     onSearchList
//   } = MenuManagementLogic();

//   console.log("treeMenuListData", treeMenuListData);
//   console.log("GridColumnDefs", GridColumnDefs);
//   console.log("isLoading", isLoading);


//   // return (
//   //   <div className={BizClass.PageStart}>
//   //     <div className={BizClass.Card}>
//   //     {/* <PageBar>
//   //       <PageBar.Search
//   //         value={menuItemSearch}
//   //         onChange={(e) => onSearchList(e.target.value)}
//   //         onClick={() => getMenuListData()}
//   //       />
//   //       <PageBar.Button>Add</PageBar.Button>
//   //     </PageBar> */}
//   //     {/* <DataGrid
//   //       columnDefs={GridColumnDefs}
//   //       rowData={treeMenuListData}
//   //       loader={false}
//   //       autoGroupColumnDef={{
//   //         headerName: "Menu Name",
//   //         minWidth: 220,
//   //         cellRendererParams: {
//   //           innerRenderer: (params) => {
//   //             return params.data.MenuName;
//   //           },
//   //         },
//   //       }}
//   //       treeData="true"
//   //       animateRows="true"
//   //       groupDefaultExpanded={-1}
//   //       onGridReady={onGridReady}
//   //       getDataPath={(data) => {
//   //         return data.orgHierarchy;
//   //       }}
//   //     /> */}

//   //     <DataGrid
//   //     columnDefs={GridColumnDefs}
//   //     rowData={treeMenuListData}
//   //     />
//   //     {/* <Footer /> */}
//   //     </div>
//   //   </div>
//   // );

// // ...

// return (
//   <div className={BizClass.PageStart}>
//     <div className={BizClass.Card}>
//       {/* Uncomment the PageBar and Footer if needed */}
//       {/* <PageBar>
//         <PageBar.Search
//           value={menuItemSearch}
//           onChange={(e) => onSearchList(e.target.value)}
//           onClick={() => getMenuListData()}
//         />
//         <PageBar.Button>Add</PageBar.Button>
//       </PageBar> */}

//       {/* Ensure proper configuration of AG-Grid */}
//       <DataGrid
//         columnDefs={GridColumnDefs}
//         rowData={treeMenuListData}
//         loader={isLoading ? <Loader /> : null}
//         // autoGroupColumnDef={{
//         //   headerName: "Menu Name",
//         //   minWidth: 220,
//         //   cellRendererParams: {
//         //     innerRenderer: (params) => {
//         //       return params.data.MenuName;
//         //     },
//         //   },
//         // }}
//         // treeData={true}
//         // animateRows={true}
//         // groupDefaultExpanded={-1}
//         // onGridReady={onGridReady}
//         // getDataPath={(data) => {
//         //   return data.orgHierarchy;
//         // }}
//       />

//       {/* Uncomment the Footer if needed */}
//       {/* <Footer /> */}
//     </div>
//   </div>
// );

// // ...

// }



// export default MenuManagement;




import React from "react";
import { DataGrid } from "Framework/Components/Layout";
import { Loader } from "Framework/Components/Widgets";
import MenuManagementLogic from "./Logic/MenuManagementLogic";
import BizClass from "./MenuManagement.module.scss";

function MenuManagement() {
  console.log("MenuManagement component rendered");
  const {
    GridColumnDefs,
    getMenuListData,
    treeMenuListData,
    isLoading,
    onGridReady,
    menuItemSearch,
    onSearchList
  } = MenuManagementLogic();

  console.log("treeMenuListData", treeMenuListData);
  console.log("GridColumnDefs", GridColumnDefs);

  // Check if data is loading
  if (isLoading) {
    return <Loader />;
  }

  // Check if data and column definitions are available
  if (!treeMenuListData || !GridColumnDefs) {
    return <div>No data or column definitions available</div>;
  }

  return (
    <div className={BizClass.PageStart}>
      <div className={BizClass.Card}>
        <div className={BizClass.tableHeight}>
          {isLoading ? <Loader /> : (
            <DataGrid
              columnDefs={GridColumnDefs}
              rowData={treeMenuListData}
            />
          )}
        </div>
      </div>
    </div>
  );


}

export default MenuManagement;
