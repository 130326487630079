import { useEffect, useState } from "react";
import {
  // MASTERDATA_GROUP,
  MASTERDATA_INPUTTYPE,
  MASTERDATA_REFERENCETYPE,
  MASTERDATA_SAFTYCHECKPONIT,
  MASTERDATA_STATUSTYPE,
  MASTERNAME,
  REFERENCETYPE_TEXT,
  REFERENCETYPE_VALUE,
} from "Configration/Utilities/Constants/Constants";
import GetApiData from "Configration/Utilities/getApiData/GetApiData";
import { AlertMessage } from "Framework/Components/Widgets";
import moment from "moment";
// import { ActionMeta, OnChangeValue } from "react-select";
import { getSessionStorage } from "Service/auth/auth";
import { project } from "Service/Endpoints/endpoints";
// import { apiCalling } from "Service/Utilities/apiCalling";

function ObservationsDetailsLogic() {
  const setAlertMessage = AlertMessage();
  const user = getSessionStorage("userData");
  const [selectedProjectData, setSelectedProjectData] = useState(null);
  const [characterLimit] = useState(500);
  const [weekNameDay, setWeekNameDay] = useState("");

  /* ************************************* Code For MasterData Dropdown State Start ***************************** */

  const [isReferanceDataLoad, setIsReferanceDataLoad] = useState(false);
  const [referecneType, setReferecneType] = useState([]);

  const [issafetyCheckListDataLoad, setIssafetyCheckListDataLoad] = useState(false);
  const [safetyCheckList, setsafetyCheckList] = useState([]);

  const [isGroupDataLoad, setIsGroupDataLoad] = useState(false);
  const [group, setGroup] = useState([]);

  const [isInputTypeDataLoad, setIsInputTypeDataLoad] = useState(false);
  const [inputType, setInputType] = useState([]);

  const [isStatusTypeDataLoad, setIsStatusTypeDataLoad] = useState(false);
  const [StatusType, setStatusType] = useState([]);

  /* ************************************* Code For MasterData Dropdown State end ***************************** */

  /* ************************************* Code For Assets , team & TeamMemberList  State start  ***************************** */
  const [isLoadingAssetsData, setIsLoadingAssetsData] = useState(false);
  const [assetsListData, setAssetsListData] = useState([]);

  /* ************************************* Code For Assets , team & TeamMemberList  State end ***************************** */

  /* ************************************* Code For GridApi State start ***************************** */

  const [assetGridApi, setAssetGridApi] = useState();
  const [teamMemberGridApi, setTeamMemberGridApi] = useState();

  /* ************************************* Code For GridApi State end ***************************** */

  /* ************************************* Code For PopUp State start ***************************** */

  const [isActiveAddAssetPopUp, setIsActiveAddAssetPopUp] = useState(false);
  const [isActiveSchedualPopUp, setIsActiveSchedualPopUp] = useState(false);
  const [isActiveAssignedToPopUp, setIsActiveAssignedToPopUp] = useState(false);

  /* ************************************* Code For PopUp State end ***************************** */

  const [divsionList, setDivsionList] = useState([]);
  const [isLoadingDivsion, setIsLoadingDivsion] = useState(false);
  const [selectedDivision, setSelectedDivision] = useState([]);
  const [teamsListMembersData, setTeamsListMembersData] = useState([]);
  const [isLoadingTeamsMemberData, setIsLoadingTeamsMemberData] = useState(false);

  // /* ************************************* Code For formValue && schedule State start ***************************** */

  const [formValues, setFormValues] = useState({
    txtObservaionCheckPoint: "",
    txtDescription: "",
    txtGroup: null,
    txtsafetyCheckList: null,
    txtReferenceType: null,
    txtInputType: null,
    txtStatusType: null,
    txtAction: "",
    txtAvailabelInSCADA: false,
    txtMin: "",
    txtMax: "",
    txtlabel: "",
    txtReferenceText: "",
    txtAssets: [],
    txtAssignedTo: [],
    txtSchedule: "",
    txtShift: false,
  });

  const [scheduleFormValues, setScheduleFormValue] = useState({
    txtDaily: false,
    txtWeekly: false,
    txtMonthly: false,
    txtweeklyDay: "",
    txtMonthlyWeekDay: "",
    txtDayilyRepeatEvery: "",
    txtDayilyTime: "",
    txtWeeklyRepeatEvery: "",
    txtWeeklyTime: "",
    txtMonthlyNumericWeekDay: null,
    txtMonthlyNumericRepeatEveryMonth: null,
    txtMonthlyNumericMonthWeek: null,
    txtMonthlyNumericWeek: null,
    txtMonthlyNumericTime: null,
    txtStartOn: "",
    txtUntil: "",
  });

  /* ************************************* Code For formValue && schedule State end ***************************** */

  /* ************************************* Code For formValue && schedule Validation State start ***************************** */

  const [formValidationError, setFormValidationError] = useState({});
  const [scheduleFormValidationError, setScheduleFormValidationError] = useState({});

  /* ************************************* Code For formValue && schedule Validation State end ***************************** */

  /* ************************************* Code For NewlyAddedObservation State start ***************************** */

  const [newAddedObservations, setNewAddedObservations] = useState([]);
  const [isLoadingAddNewObservations, setIsLoadingAddNewObservations] = useState(false);

  /* ************************************* Code For NewlyAddedObservation State end ***************************** */

  /* ************************************* Code For GridColumDef start ***************************** */

  const [ListViewColumDef] = useState([
    { headerName: "", field: "#", width: 80, pinned: "left", checkboxSelection: true, showDisabledCheckboxes: true },
    { headerName: "Sr. No.", field: "#", width: 80, pinned: "left", valueGetter: "node.rowIndex + 1" },
    { field: "ASSETCODE", headerName: "Assets Code", width: 200 },
    { field: "ASSETNAME", headerName: "Assets Name", width: 380 },
  ]);

  const [TeamMemberListViewColumDef] = useState([
    {
      headerName: "Action",
      field: "#",
      width: 80,
      pinned: "left",
      checkboxSelection: true,
      showDisabledCheckboxes: true,
    },
    { field: "EMPLOYEENAME", headerName: "User Name", width: 240 },
  ]);

  /* ************************************* Code For GridColumDef end ***************************** */

  /* ************************************* Code For  Schedule DropDown Start  ***************************** */
  const [listOfMonth] = useState([
    { label: 1, value: 1 },
    { label: 2, value: 2 },
    { label: 3, value: 3 },
    { label: 4, value: 4 },
    { label: 5, value: 5 },
    { label: 6, value: 6 },
    { label: 7, value: 7 },
    { label: 8, value: 8 },
    { label: 9, value: 9 },
    { label: 10, value: 10 },
    { label: 11, value: 11 },
    { label: 12, value: 12 },
  ]);

  const [listOfWeekDay] = useState([
    { label: "Monday", value: 1 },
    { label: "Tuesday", value: 2 },
    { label: "Wednesday", value: 3 },
    { label: "Thursday", value: 4 },
    { label: "Friday", value: 5 },
    { label: "Saturday", value: 6 },
    { label: "Sunday", value: 7 },
  ]);

  const [listofMonthlyWeek] = useState([
    { label: "1st", value: 1 },
    { label: "2nd", value: 2 },
    { label: "3rd", value: 3 },
    { label: "4th", value: 4 },
    { label: "5th", value: 5 },
  ]);
  /* ************************************* Code For Schedule DropDown end  ***************************** */

  /* ************************************* Code For Date Format Start   ***************************** */
  const dateToSpecificFormat = (date, format) => {
    try {
      const d = new Date(date);
      const convertedDate = moment(d).format(format);
      return convertedDate;
    } catch {
      return null;
    }
  };
  /* ************************************* Code For Date Format Start   ***************************** */

  /* *************************************  Code For clear Form value  Start  ***************************** */
  const clearFormValue = () => {
    setFormValues({
      txtObservaionCheckPoint: "",
      txtDescription: "",
      txtGroup: null,
      txtsafetyCheckList: null,
      txtReferenceType: null,
      txtInputType: null,
      txtStatusType: null,
      txtAction: "",
      txtAvailabelInSCADA: false,
      txtMin: "",
      txtMax: "",
      txtlabel: "",
      txtReferenceText: "",
      txtAssets: [],
      txtAssignedTo: [],
      txtSchedule: "",
      txtShift: false,
    });
    setScheduleFormValue({
      txtDaily: false,
      txtWeekly: false,
      txtMonthly: false,
      txtweeklyDay: "",
      txtMonthlyWeekDay: "",
      txtDayilyRepeatEvery: "",
      txtDayilyTime: "",
      txtWeeklyRepeatEvery: "",
      txtWeeklyTime: "",
      txtMonthlyNumericRepeatEveryMonth: null,
      txtMonthlyNumericMonthWeek: null,
      txtMonthlyNumericWeek: null,
      txtMonthlyNumericTime: null,
      txtStartOn: "",
      txtUntil: "",
    });
    // setAssetEmployee([]);
  };
  /* *************************************  Code For clear Form value End  ***************************** */

  /* ************************************* Code For toggle popup start ***************************** */

  const toggleAddAsset = () => {
    setIsActiveAddAssetPopUp(!isActiveAddAssetPopUp);
  };

  const toggleAssignedTo = () => {
    setIsActiveAssignedToPopUp(!isActiveAssignedToPopUp);
  };

  const toggleAddSchedual = () => {
    if (formValues.txtAssignedTo.length >= 1) {
      setIsActiveSchedualPopUp(!isActiveSchedualPopUp);
    } else {
      setAlertMessage({
        type: "warning",
        message: "Please Assigned Employee !",
      });
    }
  };

  /* ************************************* Code For toggle popup end ***************************** */

  /* ************************************* Code For OnGridRady Function Start ***************************** */

  const onAssetGridReady = (params) => {
    setAssetGridApi(params.api);
  };
  const onTeamMemberGridReady = (params) => {
    setTeamMemberGridApi(params.api);
  };

  /* ************************************* Code For OnGridRady Function End ***************************** */

  /* ************************************* +++ Code ForApi apiCalling function Start +++ ***************************** */

  // master Data binding
  // function masterDataLoadingState(MasterDataId, value) {
  //   switch (MasterDataId) {
  //     case MASTERDATA_REFERENCETYPE:
  //       setIsReferanceDataLoad(value);
  //       break;
  //     case MASTERDATA_SAFTYCHECKPONIT:
  //       setIssafetyCheckListDataLoad(value);
  //       break;
  //     case MASTERDATA_GROUP:
  //       setIsGroupDataLoad(value);
  //       break;
  //     case MASTERDATA_INPUTTYPE:
  //       setIsInputTypeDataLoad(value);
  //       break;
  //     case MASTERDATA_STATUSTYPE:
  //       setIsStatusTypeDataLoad(value);
  //       break;
  //     default:
  //       break;
  //   }
  // }
  // function masterDropdaownDataState(MasterDataId, value) {
  //   switch (MasterDataId) {
  //     case MASTERDATA_REFERENCETYPE:
  //       setReferecneType(value);
  //       break;
  //     case MASTERDATA_SAFTYCHECKPONIT:
  //       setsafetyCheckList(value);
  //       break;
  //     case MASTERDATA_GROUP:
  //       setGroup(value);
  //       break;
  //     case MASTERDATA_INPUTTYPE:
  //       setInputType(value);
  //       break;
  //     case MASTERDATA_STATUSTYPE:
  //       setStatusType(value);
  //       break;
  //     default:
  //       break;
  //   }
  // }

  // const getMAsterObservationListing = async (MasterDataId) => {
  //   try {
  //     masterDataLoadingState(MasterDataId, true);

  //     const formData = {
  //       main: {
  //         filterID: MasterDataId,
  //         filterID1: 0,
  //         mastername: MASTERNAME,
  //         searchText: "#ALL",
  //         searchcriteria: "AW",
  //       },
  //     };

  //     const result = await apiCalling(formData, project.projectList.GetMasterDataBinding);
  //     masterDataLoadingState(MasterDataId, false);

  //     if (result && result.responseCode === 1) {
  //       if (result && result.responseData && result.responseData.Table && result.responseData.Table.length > 0) {
  //         console.log(result.responseData.Table, MasterDataId);
  //         masterDropdaownDataState(MasterDataId, result.responseData.Table);
  //       }
  //     } else {
  //       setAlertMessage({
  //         type: "error",
  //         message: result.responseMessage,
  //       });
  //       masterDropdaownDataState(MasterDataId, []);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //     setAlertMessage({
  //       type: "error",
  //       message: error,
  //     });
  //     masterDropdaownDataState(MasterDataId, []);
  //   }
  // };

  const getMAsterObservationListing = async (MasterDataId) => {
    const formData = {
      main: {
        filterID: MasterDataId,
        filterID1: 0,
        mastername: MASTERNAME,
        searchText: "#ALL",
        searchcriteria: "AW",
      },
    };

    // const filterFunctions = (data) =>{

    // }

    let setLodingState;
    let setDataState;
    switch (MasterDataId) {
      case MASTERDATA_REFERENCETYPE:
        setLodingState = setIsReferanceDataLoad;
        setDataState = setReferecneType;
        break;
      case MASTERDATA_SAFTYCHECKPONIT:
        setLodingState = setIssafetyCheckListDataLoad;
        setDataState = setsafetyCheckList;
        break;
      // case MASTERDATA_GROUP:
      //   setLodingState = setIsGroupDataLoad;
      //   setDataState = setGroup;
      //   break;
      case MASTERDATA_INPUTTYPE:
        setLodingState = setIsInputTypeDataLoad;
        setDataState = setInputType;
        break;
      case MASTERDATA_STATUSTYPE:
        setLodingState = setIsStatusTypeDataLoad;
        setDataState = setStatusType;
        break;
      default:
        break;
    }

    const request = {
      setDatalist: setDataState,
      setLoad: setLodingState,
      requestData: formData,
      apiPath: project.projectList.GetMasterDataBinding,
      setAlert: setAlertMessage,
      fun: null,
    };
    GetApiData(request);
  };

  // get GetBatchMaster

  const GetBatchMaster = () => {
    setSelectedDivision([]);
    const formData = {
      main: {
        viewmode: "ALL",
        batchid: 0,
      },
    };

    const request = {
      setDatalist: setGroup,
      setLoad: setIsGroupDataLoad,
      requestData: formData,
      apiPath: project.projectList.GetBatchMaster,
      setAlert: setAlertMessage,
      fun: null,
    };
    GetApiData(request);
  };

  // GetAssetList Grid binding api

  const getAssetListingData = async () => {
    const formData = {
      main: {
        divisionid: selectedProjectData && selectedProjectData.DIVISIONIDs ? selectedProjectData.DIVISIONIDs : "",
        profileid: user && user.UserProfileID ? user.UserProfileID : null,
        projectid: selectedProjectData && selectedProjectData.PROJECTID ? selectedProjectData.PROJECTID : "",
      },
    };
    const request = {
      setDatalist: setAssetsListData,
      setLoad: setIsLoadingAssetsData,
      requestData: formData,
      apiPath: project.projectList.GetKPCLAssetSelect,
      setAlert: setAlertMessage,
      fun: null,
    };
    GetApiData(request);
  };

  // const getAssetListingData = async () => {
  //   try {
  //     setIsLoadingAssetsData(true);
  //     const formData = {
  //       main: {
  //         divisionid:
  //           user && user.UserProfileID
  //             ? user.UserProfileID.toString() === "100301" || user.UserProfileID.toString() === "100302"
  //               ? selectedProjectData && selectedProjectData.DIVISIONIDs
  //                 ? selectedProjectData.DIVISIONIDs
  //                 : ""
  //               : ""
  //             : "",
  //         profileid: user && user.UserProfileID ? user.UserProfileID : null,
  //         projectid: selectedProjectData && selectedProjectData.PROJECTID ? selectedProjectData.PROJECTID : "",
  //       },
  //     };
  //     const result = await apiCalling(formData, project.projectList.GetKPCLAssetSelect);
  //     setIsLoadingAssetsData(false);
  //     console.log(result);
  //     if (result && result.responseCode === 1) {
  //       if (result && result.responseData && result.responseData.Table) {
  //         setAssetsListData(result.responseData.Table);
  //       }
  //     } else {
  //       setAlertMessage({
  //         type: "error",
  //         message: result.responseMessage,
  //       });
  //       setAssetsListData([]);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //     setAlertMessage({
  //       type: "error",
  //       message: error,
  //     });
  //     setAssetsListData([]);
  //   }
  // };

  function setAddObsevationFormData() {
    const setAddObservationData = {
      observationTime: "",
      frequencyType: 0,
      weekofmonthData: 0,
      dayOfWeekData: 0,
      startOnDate: "",
      olabelText: "",
      refMin: 0,
      refMax: 0,
      endDate: "",
    };

    if (scheduleFormValues.txtDaily) {
      setAddObservationData.frequencyType = 701;
      setAddObservationData.observationTime = scheduleFormValues.txtDayilyTime;
    } else if (scheduleFormValues.txtWeekly) {
      setAddObservationData.frequencyType = 702;
      setAddObservationData.observationTime = scheduleFormValues.txtWeeklyTime;
      setAddObservationData.weekofmonthData = scheduleFormValues.txtWeeklyRepeatEvery;
      setAddObservationData.dayOfWeekData = scheduleFormValues.txtweeklyDay;
    } else if (scheduleFormValues.txtMonthly) {
      setAddObservationData.frequencyType = 703;
      setAddObservationData.observationTime = scheduleFormValues.txtMonthlyNumericTime;
      setAddObservationData.weekofmonthData = scheduleFormValues.txtMonthlyNumericMonthWeek.value;
      setAddObservationData.dayOfWeekData = scheduleFormValues.txtMonthlyNumericWeekDay.value;
    }

    // startOnDate

    if (scheduleFormValues.txtStartOn) {
      setAddObservationData.startOnDate = dateToSpecificFormat(moment(scheduleFormValues.txtStartOn), "DD-MM-YYYY");
    } else {
      setAddObservationData.startOnDate = dateToSpecificFormat(moment().add(1, "days"), "DD-MM-YYYY");
    }

    // EndDate

    if (scheduleFormValues.txtUntil) {
      setAddObservationData.endDate = dateToSpecificFormat(moment(scheduleFormValues.txtUntil), "DD-MM-YYYY");
    } else if (scheduleFormValues.txtStartOn) {
      setAddObservationData.endDate = dateToSpecificFormat(moment(scheduleFormValues.txtStartOn).add(1, "days"), "DD-MM-YYYY");
    } else {
      setAddObservationData.endDate = dateToSpecificFormat(moment().add(2, "days"), "DD-MM-YYYY");
    }

    // olable , ref min , ref max

    if (formValues.txtReferenceType && formValues.txtReferenceType.MASTERVALUEID.toString() === REFERENCETYPE_VALUE) {
      setAddObservationData.olabelText = formValues.txtlabel;
      setAddObservationData.refMin = formValues.txtMin ? formValues.txtMin : 0;
      setAddObservationData.refMax = formValues.txtMax ? formValues.txtMax : 0;
    } else if (formValues.txtReferenceType && formValues.txtReferenceType.MASTERVALUEID.toString() === REFERENCETYPE_TEXT) {
      setAddObservationData.olabelText = formValues.txtReferenceText;
    }
    return setAddObservationData;
  }

  // Add new observations Api
  // const addNewObservations = async (getObservationListing) => {
  //   debugger;
  //   try {
  //     setIsLoadingAddNewObservations(true);
  //     const { observationTime, frequencyType, weekofmonthData, dayOfWeekData, startOnDate, olabelText, refMin, refMax, endDate } = setAddObsevationFormData();

  //     const formData = {
  //       main: {
  //         ovservationheaderid: 0,
  //         equipmentnumber: formValues.txtAssets.length > 0 ? formValues.txtAssets[0].ASSETCODE : 0,
  //         ogroup: formValues.txtGroup && formValues.txtGroup.MASTERVALUEID ? formValues.txtGroup.MASTERVALUEID : 0,
  //         checkpoint: formValues.txtObservaionCheckPoint ? formValues.txtObservaionCheckPoint : "",
  //         odescription: formValues.txtDescription ? formValues.txtDescription : "",
  //         reftype: formValues.txtReferenceType && formValues.txtReferenceType.MASTERVALUEID ? formValues.txtReferenceType.MASTERVALUEID : 0,
  //         reF_MIN: refMin,
  //         reF_MAX: refMax,
  //         olabel: olabelText,
  //         inputtype: formValues.txtInputType && formValues.txtInputType.MASTERVALUEID ? formValues.txtInputType.MASTERVALUEID : 0,
  //         frequency: frequencyType,
  //         months: scheduleFormValues.txtMonthly ? scheduleFormValues.txtMonthlyNumericRepeatEveryMonth.value : 0,
  //         weekofmonth: weekofmonthData,
  //         dayofweek: dayOfWeekData,
  //         repeattype: 0,
  //         repeatfrequency: scheduleFormValues.txtDaily ? scheduleFormValues.txtDayilyRepeatEvery : 0,
  //         observationtime: assetEmployee && assetEmployee.length === 1 ? observationTime : "",
  //         oduration: 0,
  //         weeklymaxcount: 0,
  //         monthlymaxcount: 0,
  //         inscada: formValues.txtAvailabelInSCADA ? 1 : 0,
  //         action: formValues.txtAction ? formValues.txtAction : 0,
  //         statustype: formValues.txtStatusType && formValues.txtStatusType.MASTERVALUEID ? formValues.txtStatusType.MASTERVALUEID : 0,
  //         safetychecklistid: formValues.txtsafetyCheckList && formValues.txtsafetyCheckList.MASTERVALUEID ? formValues.txtsafetyCheckList.MASTERVALUEID : 0,
  //         startdate: startOnDate.toString(),
  //         enddate: endDate.toString(),
  //       },
  //     };
  //     console.log(formData);
  //     const result = await apiCalling(formData, project.projectList.AddObservationDetail);
  //     setIsLoadingAddNewObservations(false);
  //     console.log(result);
  //     if (result && result.responseCode === 1) {
  //       setAlertMessage({
  //         type: "Success",
  //         message: result.responseMessage,
  //       });
  //       clearFormValue();
  //       getObservationListing();
  //     } else {
  //       setAlertMessage({
  //         type: "error",
  //         message: result.responseMessage,
  //       });
  //       setNewAddedObservations([]);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //     setAlertMessage({
  //       type: "error",
  //       message: error,
  //     });
  //     setNewAddedObservations([]);
  //   }
  // };

  let getObservationFun;
  const getListAndClearFormFun = () => {
    debugger;
    clearFormValue();
    getObservationFun();
  };

  const addNewObservations = (getObservationListing) => {
    debugger;
    getObservationFun = getObservationListing;

    const { observationTime, frequencyType, weekofmonthData, dayOfWeekData, startOnDate, olabelText, refMin, refMax, endDate } = setAddObsevationFormData();

    const formData = {
      main: {
        isshift: formValues.txtShift ? 1 : 0,
        noofemp: formValues.txtAssignedTo.length,
        employeeiD1:
          formValues.txtAssignedTo && formValues.txtAssignedTo.length > 0 && formValues.txtAssignedTo[0] && formValues.txtAssignedTo[0].EMPLOYEEID
            ? formValues.txtAssignedTo[0].EMPLOYEEID
            : 0,
        employeeiD2:
          formValues.txtAssignedTo && formValues.txtAssignedTo.length > 0 && formValues.txtAssignedTo[1] && formValues.txtAssignedTo[1].EMPLOYEEID
            ? formValues.txtAssignedTo[1].EMPLOYEEID
            : 0,
        employeeiD3:
          formValues.txtAssignedTo && formValues.txtAssignedTo.length > 0 && formValues.txtAssignedTo[2] && formValues.txtAssignedTo[2].EMPLOYEEID
            ? formValues.txtAssignedTo[2].EMPLOYEEID
            : 0,
        employeeiD4:
          formValues.txtAssignedTo && formValues.txtAssignedTo.length > 0 && formValues.txtAssignedTo[3] && formValues.txtAssignedTo[3].EMPLOYEEID
            ? formValues.txtAssignedTo[3].EMPLOYEEID
            : 0,
        ovservationheaderid: 0,
        equipmentnumber: formValues.txtAssets.length > 0 ? formValues.txtAssets[0].ASSETCODE : 0,
        ogroup: formValues.txtGroup && formValues.txtGroup.BATCHID !== null ? formValues.txtGroup.BATCHID : 0,
        ogroupdesc: formValues.txtGroup && formValues.txtGroup.isNew ? formValues.txtGroup.BATCHDESC : "",
        checkpoint: formValues.txtObservaionCheckPoint ? formValues.txtObservaionCheckPoint : "",
        odescription: formValues.txtDescription ? formValues.txtDescription : "",
        reftype: formValues.txtReferenceType && formValues.txtReferenceType.MASTERVALUEID ? formValues.txtReferenceType.MASTERVALUEID : 0,
        reF_MIN: refMin,
        reF_MAX: refMax,
        olabel: olabelText,
        inputtype: formValues.txtInputType && formValues.txtInputType.MASTERVALUEID ? formValues.txtInputType.MASTERVALUEID : 0,
        frequency: frequencyType,
        months: scheduleFormValues.txtMonthly ? scheduleFormValues.txtMonthlyNumericRepeatEveryMonth.value : 0,
        weekofmonth: weekofmonthData,
        dayofweek: dayOfWeekData,
        repeattype: 0,
        repeatfrequency: scheduleFormValues.txtDaily ? scheduleFormValues.txtDayilyRepeatEvery : 0,
        observationtime: formValues.txtAssignedTo && formValues.txtAssignedTo.length === 1 ? observationTime : "",
        oduration: 0,
        weeklymaxcount: 0,
        monthlymaxcount: 0,
        inscada: formValues.txtAvailabelInSCADA ? 1 : 0,
        action: formValues.txtAction ? formValues.txtAction : 0,
        statustype: formValues.txtStatusType && formValues.txtStatusType.MASTERVALUEID ? formValues.txtStatusType.MASTERVALUEID : 0,
        safetychecklistid: formValues.txtsafetyCheckList && formValues.txtsafetyCheckList.MASTERVALUEID ? formValues.txtsafetyCheckList.MASTERVALUEID : 0,
        startdate: startOnDate.toString(),
        enddate: endDate.toString(),
      },
    };

    const request = {
      setDatalist: setNewAddedObservations,
      setLoad: setIsLoadingAddNewObservations,
      requestData: formData,
      apiPath: project.projectList.AddObservationDetail,
      setAlert: setAlertMessage,
      fun: getListAndClearFormFun,
      message: true,
    };
    GetApiData(request);
  };

  // // get AssetsEmployee Api
  // const getAssetEmpSelect = (checkedItem) => {
  //   const formData = {
  //     main: {
  //       equipmentnumber: checkedItem && checkedItem.length > 0 ? checkedItem[0].ASSETCODE : 0,
  //     },
  //   };
  //   const responseData = (d) => {
  //     console.log(d);
  //   };
  //   const request = {
  //     setDatalist: setAssetEmployee,
  //     setLoad: responseData,
  //     requestData: formData,
  //     apiPath: project.projectList.GetAssetEmpSelect,
  //     setAlert: setAlertMessage,
  //     fun: null,
  //   };
  //   GetApiData(request);
  // };

  // get Devision List
  const getTeamsListingData = (projectData) => {
    setSelectedDivision([]);
    const formData = {
      main: {
        divisionid: projectData && projectData.DIVISIONIDs ? projectData.DIVISIONIDs : "",
        profileid: user && user.UserProfileID ? user.UserProfileID : null,
        projectid: projectData && projectData.PROJECTID ? projectData.PROJECTID : "",
      },
    };
    const autoSelectDropDown = (data) => {
      if (data.length === 1) {
        if (data && data[0]) {
          setSelectedDivision(data[0]);
        }
      }
    };

    const request = {
      setDatalist: setDivsionList,
      setLoad: setIsLoadingDivsion,
      requestData: formData,
      apiPath: project.projectList.GetKPCLDivisionDetail,
      setAlert: setAlertMessage,
      fun: autoSelectDropDown,
    };
    GetApiData(request);
  };

  const getTeamsDetailListing = () => {
    setTeamsListMembersData([]);

    setIsLoadingTeamsMemberData(true);
    const formData = {
      main: {
        divisionid: selectedDivision && selectedDivision.DIVISIONID ? selectedDivision.DIVISIONID.toString() : "",
        profileid: user && user.UserProfileID ? user.UserProfileID : null,
        projectid: selectedDivision && selectedDivision.PROJECTID ? selectedDivision.PROJECTID : "",
      },
    };

    const request = {
      setDatalist: setTeamsListMembersData,
      setLoad: setIsLoadingTeamsMemberData,
      requestData: formData,
      apiPath: project.projectList.GetKPCLTeam,
      setAlert: setAlertMessage,
      fun: null,
    };
    GetApiData(request);
  };

  // const getAssetEmpSelect = async (checkedItem) => {
  //   try {
  //     const formData = {
  //       main: {
  //         equipmentnumber: checkedItem ? checkedItem[0].ASSETCODE : 0,
  //       },
  //     };
  //     const result = await apiCalling(formData, project.projectList.GetAssetEmpSelect);
  //     if (result && result.responseCode === 1) {
  //       if (result && result.responseData && result.responseData.Table) {
  //         setAssetEmployee(result.responseData.Table);
  //       }
  //     } else {
  //       setAlertMessage({
  //         type: "error",
  //         message: result.responseMessage,
  //       });
  //       setAssetEmployee([]);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //     setAlertMessage({
  //       type: "error",
  //       message: error,
  //     });
  //     setAssetEmployee([]);
  //   }
  // };

  /* ************************************* +++ Code For Api apiCalling function End +++ ***************************** */

  // //////////////////////////////////////////////

  /* ************************************* Code For ValidateFild  && Update State Start ***************************** */

  const validateField = (name, value) => {
    let errorsMsg = "";
    if (name === "txtObservaionCheckPoint") {
      if (!value || typeof value === "undefined" || value === null) {
        errorsMsg = "Checkpoint Name can not be empty";
      } else {
        const regex = new RegExp(/^[a-zA-Z0-9 ?"-]+$/);
        if (!regex.test(value)) {
          errorsMsg = "Not valid ";
        }
      }
    } else if (name === "txtGroup") {
      if (formValues.txtShift) {
        if (!value || typeof value === "undefined" || value === null) {
          errorsMsg = "Please Select Group";
        }
      }
    } else if (name === "txtsafetyCheckList") {
      if (!value || typeof value === "undefined" || value === null) {
        errorsMsg = "Please Select safety CheckList ";
      }
    } else if (name === "txtReferenceType") {
      if (!value || typeof value === "undefined" || value === null) {
        errorsMsg = "Please Select ReferenceType ";
      }
    } else if (name === "txtMin") {
      if (
        formValues.txtReferenceType &&
        formValues.txtReferenceType.MASTERVALUEID &&
        formValues.txtReferenceType.MASTERVALUEID.toString() === REFERENCETYPE_VALUE
      ) {
        if (!value || typeof value === "undefined" || value === null) {
          errorsMsg = "Min can not be empty";
        } else {
          const regex = new RegExp(/^[0-9\b]+$/);
          if (!regex.test(value)) {
            errorsMsg = "Please enter number only";
          }
        }
      }
    } else if (name === "txtMax") {
      if (
        formValues.txtReferenceType &&
        formValues.txtReferenceType.MASTERVALUEID &&
        formValues.txtReferenceType.MASTERVALUEID.toString() === REFERENCETYPE_VALUE
      ) {
        if (!value || typeof value === "undefined" || value === null) {
          errorsMsg = "Max can not be empty";
        } else {
          const regex = new RegExp(/^[0-9\b]+$/);
          if (!regex.test(value)) {
            errorsMsg = "Please enter number only";
          }
        }
      }
    } else if (name === "txtlabel") {
      if (
        formValues.txtReferenceType &&
        formValues.txtReferenceType.MASTERVALUEID &&
        formValues.txtReferenceType.MASTERVALUEID.toString() === REFERENCETYPE_VALUE
      ) {
        if (!value || typeof value === "undefined" || value === null) {
          errorsMsg = "Label can not be empty";
        }
      }
    } else if (name === "txtReferenceText") {
      if (
        formValues.txtReferenceType &&
        formValues.txtReferenceType.MASTERVALUEID &&
        formValues.txtReferenceType.MASTERVALUEID.toString() === REFERENCETYPE_TEXT
      ) {
        if (!value || typeof value === "undefined" || value === null) {
          errorsMsg = "Reference Text can not be empty";
        }
      }
    } else if (name === "txtInputType") {
      if (!value || typeof value === "undefined" || value === null) {
        errorsMsg = "Please Select Input Type";
      }
    } else if (name === "txtStatusType") {
      if (!value || typeof value === "undefined" || value === null) {
        errorsMsg = "Please Select Status Type";
      }
    } else if (name === "txtAction") {
      if (!value || typeof value === "undefined" || value === null) {
        errorsMsg = "Action can not be empty";
      } else if (value && typeof value !== "undefined") {
        if (value.length > characterLimit) {
          errorsMsg = "limit only 200 char";
        }
      }
    } else if (name === "txtAssets") {
      console.log(value, name);
      if (!value || typeof value === "undefined" || value === null || value.length === 0) {
        errorsMsg = "Please Select Asset!";
      }
    } else if (name === "txtSchedule") {
      if (!value || typeof value === "undefined" || value === null) {
        errorsMsg = "Please Select Schedule!";
      }
    } else if (name === "txtAssignedTo") {
      if (!value || typeof value === "undefined" || value === null || value.length === 0) {
        errorsMsg = "Please Select Employee!";
      }
    }
    return errorsMsg;
  };

  const validateScheduleFilds = (name, value) => {
    let errorsMsg = "";
    if (name === "txtDayilyRepeatEvery") {
      if (scheduleFormValues.txtDaily) {
        if (!value || typeof value === "undefined" || value === null) {
          errorsMsg = "can not be empty";
        } else {
          const regex = new RegExp(/^[1-9]{1,2}$/);
          if (!regex.test(value)) {
            errorsMsg = "Not valid ";
          }
        }
      }
    } else if (name === "txtDayilyTime") {
      if (scheduleFormValues.txtDaily && formValues.txtAssignedTo.length === 1) {
        if (!value || typeof value === "undefined" || value === null) {
          errorsMsg = "Please Select Time !";
        }
      }
    } else if (name === "txtWeeklyRepeatEvery") {
      if (scheduleFormValues.txtWeekly) {
        if (!value || typeof value === "undefined" || value === null) {
          errorsMsg = "can not be empty";
        } else {
          const regex = new RegExp(/^[1-5]{1,1}$/);
          if (!regex.test(value)) {
            errorsMsg = "Not valid ";
          }
        }
      }
    } else if (name === "txtWeeklyTime") {
      if (scheduleFormValues.txtWeekly && formValues.txtAssignedTo.length === 1) {
        if (!value || typeof value === "undefined" || value === null) {
          errorsMsg = "Please Select Time !";
        }
      }
    } else if (name === "txtweeklyDay") {
      if (scheduleFormValues.txtWeekly) {
        if (!value || typeof value === "undefined" || value === null) {
          setAlertMessage({
            type: "warning",
            message: "Please Select Day",
          });
          errorsMsg = "Please Select Day";
        }
      }
    } else if (name === "txtMonthlyNumericRepeatEveryMonth") {
      if (scheduleFormValues.txtMonthly) {
        if (!value || typeof value === "undefined" || value === null) {
          errorsMsg = "Please Select Month !";
        }
      }
    } else if (name === "txtMonthlyNumericMonthWeek") {
      if (scheduleFormValues.txtMonthly) {
        if (!value || typeof value === "undefined" || value === null) {
          errorsMsg = "Please Select Week !";
        }
      }
    } else if (name === "txtMonthlyNumericWeekDay") {
      if (scheduleFormValues.txtMonthly) {
        if (!value || typeof value === "undefined" || value === null) {
          errorsMsg = "Please Select Day !";
        }
      }
    } else if (name === "txtMonthlyNumericTime") {
      if (scheduleFormValues.txtMonthly && formValues.txtAssignedTo.length === 1) {
        if (!value || typeof value === "undefined" || value === null) {
          errorsMsg = "Please Select Time !";
        }
      }
    } else if (name === "txtStartOn") {
      if (!value || typeof value === "undefined" || value === null) {
        errorsMsg = "Please Select Start Date ! ";
      }
    }
    return errorsMsg;
  };

  const updateFormState = (value, name) => {
    console.log(formValues);
    formValidationError[name] = validateField(name, value);
    setFormValues((values) => ({
      ...values,
      [name]: value,
    }));
    if (name === "txtShift") {
      setFormValues((values) => ({
        ...values,
        txtAssignedTo: [],
      }));
    }
  };

  const updateScheduleState = (value, name) => {
    console.log(scheduleFormValues);
    scheduleFormValidationError[name] = validateScheduleFilds(name, value);
    setScheduleFormValue((Values) => ({
      ...Values,
      [name]: value,
    }));

    if (name === "txtDaily") {
      setScheduleFormValue((Values) => ({
        ...Values,
        txtDaily: value,
        txtWeekly: false,
        txtMonthly: false,
        txtweeklyDay: "",
        txtMonthlyWeekDay: "",
        txtDayilyRepeatEvery: "",
        txtDayilyTime: "",
        txtWeeklyRepeatEvery: "",
        txtWeeklyTime: "",
        txtMonthlyNumericRepeatEveryMonth: null,
        txtMonthlyNumericMonthWeek: null,
        txtMonthlyNumericWeekDay: null,
        txtMonthlyNumericTime: null,
      }));
      updateFormState(name, "txtSchedule");
    }

    if (name === "txtWeekly") {
      setScheduleFormValue((Values) => ({
        ...Values,
        txtDaily: false,
        txtWeekly: value,
        txtMonthly: false,
        txtweeklyDay: "",
        txtMonthlyWeekDay: "",
        txtDayilyRepeatEvery: "",
        txtDayilyTime: "",
        txtWeeklyRepeatEvery: "",
        txtWeeklyTime: "",
        txtMonthlyNumericRepeatEveryMonth: null,
        txtMonthlyNumericMonthWeek: null,
        txtMonthlyNumericWeekDay: null,
        txtMonthlyNumericTime: null,
      }));
      updateFormState(name, "txtSchedule");
    }

    if (name === "txtMonthly") {
      setScheduleFormValue((Values) => ({
        ...Values,
        txtDaily: false,
        txtWeekly: false,
        txtMonthly: value,
        txtweeklyDay: "",
        txtweeklyDay: "",
        txtMonthlyWeekDay: "",
        txtDayilyRepeatEvery: "",
        txtDayilyTime: "",
        txtWeeklyRepeatEvery: "",
        txtWeeklyTime: "",
        txtMonthlyNumericRepeatEveryMonth: null,
        txtMonthlyNumericMonthWeek: null,
        txtMonthlyNumericWeekDay: null,
        txtMonthlyNumericTime: null,
      }));
      updateFormState(name, "txtSchedule");
    }
  };

  const handleValidation = () => {
    const errors = {};
    let formIsValid = true;
    errors["txtObservaionCheckPoint"] = validateField("txtObservaionCheckPoint", formValues.txtObservaionCheckPoint);
    errors["txtDescription"] = validateField("txtDescription", formValues.txtDescription);
    errors["txtGroup"] = validateField("txtGroup", formValues.txtGroup);
    errors["txtsafetyCheckList"] = validateField("txtsafetyCheckList", formValues.txtsafetyCheckList);
    errors["txtReferenceType"] = validateField("txtReferenceType", formValues.txtReferenceType);
    errors["txtMin"] = validateField("txtMin", formValues.txtMin);
    errors["txtMax"] = validateField("txtMax", formValues.txtMax);
    errors["txtlabel"] = validateField("txtlabel", formValues.txtlabel);
    errors["txtReferenceText"] = validateField("txtReferenceText", formValues.txtReferenceText);
    errors["txtInputType"] = validateField("txtInputType", formValues.txtInputType);
    errors["txtStatusType"] = validateField("txtStatusType", formValues.txtStatusType);
    errors["txtAction"] = validateField("txtAction", formValues.txtAction);
    errors["txtAssets"] = validateField("txtAssets", formValues.txtAssets);
    errors["txtSchedule"] = validateField("txtSchedule", formValues.txtSchedule);
    errors["txtAssignedTo"] = validateField("txtAssignedTo", formValues.txtAssignedTo);

    if (Object.values(errors).join("").toString()) {
      formIsValid = false;
    }
    setFormValidationError(errors);
    return formIsValid;
  };

  const handleScheduleValidation = () => {
    debugger;
    const errors = {};
    let formIsValid = true;
    errors["txtDayilyRepeatEvery"] = validateScheduleFilds("txtDayilyRepeatEvery", scheduleFormValues.txtDayilyRepeatEvery);
    errors["txtDayilyTime"] = validateScheduleFilds("txtDayilyTime", scheduleFormValues.txtDayilyTime);
    errors["txtWeeklyRepeatEvery"] = validateScheduleFilds("txtWeeklyRepeatEvery", scheduleFormValues.txtWeeklyRepeatEvery);
    errors["txtWeeklyTime"] = validateScheduleFilds("txtWeeklyTime", scheduleFormValues.txtWeeklyTime);
    errors["txtMonthlyNumericRepeatEveryMonth"] = validateScheduleFilds(
      "txtMonthlyNumericRepeatEveryMonth",
      scheduleFormValues.txtMonthlyNumericRepeatEveryMonth,
    );
    errors["txtMonthlyNumericMonthWeek"] = validateScheduleFilds("txtMonthlyNumericMonthWeek", scheduleFormValues.txtMonthlyNumericMonthWeek);
    errors["txtMonthlyNumericWeekDay"] = validateScheduleFilds("txtMonthlyNumericWeekDay", scheduleFormValues.txtMonthlyNumericWeekDay);
    errors["txtMonthlyNumericTime"] = validateScheduleFilds("txtMonthlyNumericTime", scheduleFormValues.txtMonthlyNumericTime);
    errors["txtweeklyDay"] = validateScheduleFilds("txtweeklyDay", scheduleFormValues.txtweeklyDay);
    errors["txtStartOn"] = validateScheduleFilds("txtStartOn", scheduleFormValues.txtStartOn);

    if (Object.values(errors).join("").toString()) {
      formIsValid = false;
    }
    setScheduleFormValidationError(errors);
    return formIsValid;
  };

  // //////////////////////////////////////////////

  /* ************************************* Code For ValidateFild  && Update State End ***************************** */

  /* ************************************* Code For getSelectedRowData start ***************************** */

  // Code for Assets checking Checked data
  const getSelectedRowData = () => {
    const selectedNodes = assetGridApi.getSelectedNodes();
    const selectedData = selectedNodes.map((node) => node.data);
    return selectedData;
  };

  const SaveSelectadAssetsRowData = (toggleAddAsset) => {
    if (assetGridApi) {
      const checkedItem = getSelectedRowData();
      if (checkedItem.length > 0) {
        setScheduleFormValue((Values) => ({
          ...Values,
          txtweeklyDay: "",
          txtMonthlyWeekDay: "",
          txtDayilyRepeatEvery: "",
          txtDayilyTime: "",
          txtWeeklyRepeatEvery: "",
          txtWeeklyTime: "",
          txtMonthlyNumericWeekDay: null,
          txtMonthlyNumericRepeatEveryMonth: null,
          txtMonthlyNumericMonthWeek: null,
          txtMonthlyNumericWeek: null,
          txtMonthlyNumericTime: null,
        }));
        updateFormState(checkedItem, "txtAssets");
        toggleAddAsset();
      }
    }
  };

  const getAssignedToSelectedRowData = () => {
    const selectedNodes = teamMemberGridApi.getSelectedNodes();
    const selectedData = selectedNodes.map((node) => node.data);
    return selectedData;
  };

  const SaveSelectedAssignedToData = () => {
    if (teamMemberGridApi) {
      const checkedItem = getAssignedToSelectedRowData();
      if (formValues.txtShift) {
        if (checkedItem.length > 1 && checkedItem.length < 5) {
          updateFormState(checkedItem, "txtAssignedTo");
          toggleAssignedTo();
        } else if (checkedItem.length < 2) {
          setAlertMessage({
            type: "warning",
            message: "Please Select Minimum 2 Employee !",
          });
        } else if (checkedItem.length > 4) {
          setAlertMessage({
            type: "warning",
            message: "Please Select Maximum 4 Employee !",
          });
        }
      } else if (checkedItem.length > 0) {
        updateFormState(checkedItem, "txtAssignedTo");
        toggleAssignedTo();
      }
    }
  };

  /* ************************************* Code For getSelectedRowData  End ***************************** */

  /* ************************************* Code For Schedule && Observations SaveHandaler Functions start ***************************** */

  const saveAddSchedule = (toggleAddSchedual) => {
    if (scheduleFormValues.txtDaily || scheduleFormValues.txtWeekly || scheduleFormValues.txtMonthly) {
      if (!handleScheduleValidation()) {
        return;
      }
      toggleAddSchedual();
    } else {
      setAlertMessage({
        type: "warning",
        message: "Please Enter Schedule Type",
      });
    }
  };

  const saveObservation = (getObservationListing) => {
    if (!handleValidation()) {
      return;
    }

    if (!handleScheduleValidation()) {
      setAlertMessage({
        type: "warning",
        message: "Please Enter Valid Schedule",
      });
    } else {
      addNewObservations(getObservationListing);
    }
  };

  /* ************************************* Code For Schedule && Observations SaveHandaler Functions End ***************************** */
  useEffect(() => {
    if (selectedDivision && selectedDivision.DIVISIONID) {
      getTeamsDetailListing();
    } else {
      setTeamsListMembersData([]);
    }
  }, [selectedDivision]);

  useEffect(() => {
    if (selectedProjectData) getAssetListingData();
  }, [selectedProjectData]);

  useEffect(() => {
    let WeekDayName = "";
    if (scheduleFormValues.txtweeklyDay) {
      switch (scheduleFormValues.txtweeklyDay) {
        case 1:
          WeekDayName = "Monday";
          break;
        case 2:
          WeekDayName = "Tuesday";
          break;
        case 3:
          WeekDayName = "Wednesday";
          break;
        case 4:
          WeekDayName = "Thursday";
          break;
        case 5:
          WeekDayName = "Friday";
          break;
        case 6:
          WeekDayName = "Saturday";
          break;
        case 7:
          WeekDayName = "Sunday";
          break;

        default:
          break;
      }
    }
    setWeekNameDay(WeekDayName);
  }, [scheduleFormValues.txtweeklyDay]);

  return {
    isReferanceDataLoad,
    referecneType,
    issafetyCheckListDataLoad,
    safetyCheckList,
    isGroupDataLoad,
    group,
    isInputTypeDataLoad,
    inputType,
    isStatusTypeDataLoad,
    StatusType,
    getMAsterObservationListing,
    toggleAddAsset,
    isActiveAddAssetPopUp,
    setSelectedProjectData,
    isLoadingAssetsData,
    ListViewColumDef,
    assetsListData,
    onAssetGridReady,
    toggleAddSchedual,
    isActiveSchedualPopUp,
    SaveSelectadAssetsRowData,
    updateFormState,
    formValues,
    updateScheduleState,
    scheduleFormValues,
    saveObservation,
    formValidationError,
    saveAddSchedule,
    scheduleFormValidationError,
    clearFormValue,
    newAddedObservations,
    isLoadingAddNewObservations,
    characterLimit,
    listOfMonth,
    listOfWeekDay,
    listofMonthlyWeek,
    weekNameDay,
    isActiveAssignedToPopUp,
    toggleAssignedTo,
    getTeamsListingData,
    TeamMemberListViewColumDef,
    divsionList,
    isLoadingDivsion,
    selectedDivision,
    setSelectedDivision,
    teamsListMembersData,
    isLoadingTeamsMemberData,
    onTeamMemberGridReady,
    SaveSelectedAssignedToData,
    setTeamsListMembersData,
    GetBatchMaster,
  };
}

export default ObservationsDetailsLogic;
