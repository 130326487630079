import { useState } from "react";
import { PROJECT_DETAIL_TEAMS } from "Configration/Utilities/Constants/Constants";

function ProjectDetailLogic() {
  const [activeProjectDetailsTab, setActiveProjectDetailsTab] = useState(PROJECT_DETAIL_TEAMS);

  return {
    activeProjectDetailsTab,
    setActiveProjectDetailsTab,
  };
}

export default ProjectDetailLogic;
