import React, { useCallback, useEffect, useRef } from "react";
import { FaUserAlt } from "react-icons/fa";
import classNames from "classnames";
import OtpInput from "react18-otp-input";
import Lottie from "lottie-react";
import Animation from "Framework/Assets/JSON/Success.json";
import { SIGNUP_VIEW, SIGNUP_ONBOARDINGMSG_VIEW, SIGNUP_OTPVALIDATE_VIEW, COMPANY_EMPLOYEE, LOGIN_VIEW } from "Configration/Utilities/Constants/Constants";
import SignUpFormLogic from "./Logic/SignUpFormLogic";
import BizClass from "./SignUpForm.module.scss";

function SignUpForm(props) {
  const { activeFormView, setActiveFormView } = props;

  const {
    formValues,
    searchByHandleKeyDown,
    updateFormState,
    userAuthenticationFormError,
    userAuthenticationHandleSave,
    isLoadinguserAuthentication,
    otptimer,
    requestOtpAgain,
    updateOTPFormState,
    otpAuthenticationFormError,
    isLoadingOtpAuthentication,
    otpAuthenticationHandleSave,
    resetEmployeeData,
    setOtpTimer,
    receivedOTP,
  } = SignUpFormLogic();

  return (
    <div className={BizClass.InputBox}>
      <div
        className={classNames(
          BizClass.FormTimeline,
          activeFormView === SIGNUP_VIEW ? BizClass.FirstStepActive : null,
          activeFormView === SIGNUP_OTPVALIDATE_VIEW ? BizClass.SecondStepActive : null,
          activeFormView === SIGNUP_ONBOARDINGMSG_VIEW ? BizClass.ThirdStepActive : null,
        )}
      >
        <div className={BizClass.FormTimelineBox}>
          <span>1</span>
        </div>
        <div className={BizClass.FormTimelineBox}>
          <span>2</span>
        </div>
        <div className={BizClass.FormTimelineBox}>
          <span>3</span>
        </div>
      </div>
      {activeFormView === SIGNUP_VIEW && (
        <SignUpFirstForm
          searchByHandleKeyDown={searchByHandleKeyDown}
          setActiveFormView={setActiveFormView}
          formValues={formValues}
          updateFormState={updateFormState}
          userAuthenticationFormError={userAuthenticationFormError}
          userAuthenticationHandleSave={userAuthenticationHandleSave}
          isLoadinguserAuthentication={isLoadinguserAuthentication}
        />
      )}
      {activeFormView === SIGNUP_OTPVALIDATE_VIEW && (
        <SignUpSecondForm
          searchByHandleKeyDown={searchByHandleKeyDown}
          setActiveFormView={setActiveFormView}
          formValues={formValues}
          updateFormState={updateOTPFormState}
          otptimer={otptimer}
          setOtpTimer={setOtpTimer}
          requestOtpAgain={requestOtpAgain}
          otpAuthenticationFormError={otpAuthenticationFormError}
          isLoadingOtpAuthentication={isLoadingOtpAuthentication}
          otpAuthenticationHandleSave={otpAuthenticationHandleSave}
          resetEmployeeData={resetEmployeeData}
          receivedOTP={receivedOTP}
        />
      )}
      {activeFormView === SIGNUP_ONBOARDINGMSG_VIEW && <SignUpFourthForm setActiveFormView={setActiveFormView} />}
    </div>
  );
}

export default SignUpForm;

function SignUpFirstForm(props) {
  const {
    setActiveFormView,
    searchByHandleKeyDown,
    formValues,
    updateFormState,
    userAuthenticationFormError,
    userAuthenticationHandleSave,
    isLoadinguserAuthentication,
  } = props;

  const firstFormInput = useRef();

  useEffect(() => {
    if (firstFormInput.current) {
      firstFormInput.current.focus();
    }
  }, []);

  return (
    <>
      <div className={BizClass.SelectGroup}>
        <button
          type="button"
          className={formValues.txtEmployeeTypeID === COMPANY_EMPLOYEE && BizClass.Active}
          onClick={() => updateFormState("txtEmployeeTypeID", COMPANY_EMPLOYEE)}
        >
          <div />
          <span>Employee</span>
        </button>
      </div>
      <div className={BizClass.InputGroup}>
        <div className={classNames(BizClass.LabelBox, userAuthenticationFormError["txtEmployeeID"] ? BizClass.LabelBoxError : null)}>
          <label>{formValues.txtEmployeeTypeID === COMPANY_EMPLOYEE ? "Employee ID" : "Contract Employee ID"}</label>
        </div>
        <div className={classNames(BizClass.InputGroupBox, userAuthenticationFormError["txtEmployeeID"] ? BizClass.InputGroupBoxError : null)}>
          <div className={BizClass.SubInputGroup}>
            <FaUserAlt className={BizClass.BoxIcon} />
            <input
              type="text"
              pattern="[0-9]*"
              name="txtEmployeeID"
              ref={firstFormInput}
              value={formValues.txtEmployeeID}
              onKeyDown={(e) => searchByHandleKeyDown(e, userAuthenticationHandleSave, setActiveFormView)}
              onChange={(e) => updateFormState(e.target.name, e.target.validity.valid ? e.target.value : "")}
            />
          </div>
          <span>{userAuthenticationFormError["txtEmployeeID"]}</span>
        </div>
      </div>
      <div>
        <button
          type="button"
          className={classNames(BizClass.ButtonWithLoader, isLoadinguserAuthentication && BizClass.loading)}
          onClick={() => userAuthenticationHandleSave(setActiveFormView)}
          disabled={formValues.txtEmployeeID.length === 0 && true}
        >
          <span className={BizClass.ButtonText}>Authenticate</span>
          <span className={BizClass.ButtonLoader} />
        </button>
      </div>
    </>
  );
}

function SignUpSecondForm(props) {
  const {
    setActiveFormView,
    otptimer,
    setOtpTimer,
    formValues,
    updateFormState,
    otpAuthenticationFormError,
    otpAuthenticationHandleSave,
    isLoadingOtpAuthentication,
    resetEmployeeData,
    requestOtpAgain,
    receivedOTP,
  } = props;

  const timeOutCallback = useCallback(() => setOtpTimer((currTimer) => currTimer - 1), []);

  useEffect(() => {
    if (otptimer > 0) {
      setTimeout(timeOutCallback, 1000);
    }
  }, [otptimer, timeOutCallback]);

  return (
    <>
      <div className={classNames(BizClass.OtpGroup, otpAuthenticationFormError["txtotp"] && BizClass.ErrorOtpGroup)}>
        <h3>Enter verification code ({receivedOTP})</h3>
        <p>
          An 6 digit code has been sent to <span>+91 {formValues && formValues.txtMobileNumber}</span>
        </p>
        <OtpInput
          inputStyle="inputStyle"
          placeholder="------"
          numInputs={6}
          isInputNum={true}
          shouldAutoFocus
          value={formValues.txtotp}
          onChange={(value) => updateFormState("txtotp", value)}
        />
        <div className={BizClass.ResendGroup}>
          <p>Didn&apos;t receive code ?</p>
          <button type="button" onClick={() => requestOtpAgain()} className={otptimer > 0 && BizClass.disabled}>
            Request again
          </button>
          <span>({otptimer})</span>
        </div>
        <div className={BizClass.ErrorMsgBox}>
          <span>{otpAuthenticationFormError["txtotp"]}</span>
        </div>
      </div>
      <div className={BizClass.ButtonGroup}>
        <button type="button" onClick={() => resetEmployeeData(setActiveFormView)}>
          Change
        </button>
        <button
          type="button"
          className={classNames(BizClass.ButtonWithLoader, isLoadingOtpAuthentication && BizClass.loading)}
          onClick={() => otpAuthenticationHandleSave(setActiveFormView)}
          disabled={formValues.txtotp.length !== 6 && true}
        >
          <span className={BizClass.ButtonText}>Authenticate</span>
          <span className={BizClass.ButtonLoader} />
        </button>
      </div>
    </>
  );
}

function SignUpFourthForm({ setActiveFormView }) {
  return (
    <div className={BizClass.MessageGroup}>
      <div className={BizClass.MessageAnim}>
        <Lottie animationData={Animation} loop={false} style={{ height: 220 }} />
      </div>
      <h3>Congratulations</h3>
      <p>You have completed your onboarding, now you can start using KBA!</p>
      <button type="button" onClick={() => setActiveFormView(LOGIN_VIEW)}>
        Login Now
      </button>
    </div>
  );
}
