import React, { useState, useEffect } from "react";
import { AlertMessage } from "Framework/Components/Widgets";
import { setup } from "Service/Endpoints/endpoints";
import { HiArrowCircleRight } from "react-icons/hi";
import { apiCalling } from "Service/Utilities/apiCalling";
import { MdOutlineAddCircleOutline, MdOutlineRemoveCircleOutline } from "react-icons/md";
import { VIEWMODE_ASSINGMENU, VIEWMODE_ASSINGNEDMENU, VIEWMODE_UNASSINGMENU } from "Configration/Utilities/Constants/Constants";

/* ************************************* Code For Profile Grid action Start ***************************** */

const cellTemplate = (params) => {
  const getProfileData = () => {
    if (params) {
      params.getUserProfileManu(params.data);
    }
  };

  const style = {
    display: "flex",
    fontSize: "16px",
    background: "transparent",
    border: "0px solid transparent",
    padding: "3px 0px 5px 0px",
  };

  return (
    <div style={{ display: "flex", gap: "8px" }}>
      <button
        type="button"
        style={style}
        title="View Menu"
        onClick={() => {
          getProfileData();
        }}
      >
        <HiArrowCircleRight style={{ color: "rgb(52, 73, 94)" }} />
      </button>
    </div>
  );
};

/* ************************************* Code For Profile Grid action end ***************************** */

/* ************************************* Code For UserManu Grid action Start ***************************** */

const cellUserManuTemplate = (params) => {
  const menuUnAssing = () => {
    console.log(params);
    if (params) {
      params.AssingUnAssigndManu(params.data, VIEWMODE_UNASSINGMENU, params.rowIndex);
      console.log(params.data, VIEWMODE_ASSINGMENU, params.rowIndex);
    }
  };

  const menuAssing = () => {
    if (params) {
      params.AssingUnAssigndManu(params.data, VIEWMODE_ASSINGMENU, params.rowIndex);
      console.log(params.data, VIEWMODE_ASSINGMENU, params.rowIndex);
    }
  };

  const style = {
    display: "flex",
    fontSize: "16px",
    background: "transparent",
    border: "0px solid transparent",
    padding: "3px 0px 5px 0px",
  };

  return (
    <div style={{ display: "flex", gap: "8px" }}>
      {params && params.data && params.data.ASSIGNMENTFLAG.toString() === "0" ? (
        <button
          type="button"
          style={style}
          title="Manu Assign"
          onClick={() => {
            menuAssing();
          }}
        >
          <MdOutlineAddCircleOutline style={{ color: "rgb(52, 73, 94)" }} />
        </button>
      ) : params && params.data && params.data.ASSIGNMENTFLAG.toString() === "1" ? (
        <button
          type="button"
          style={style}
          title="Manu UnAssign"
          onClick={() => {
            menuUnAssing();
          }}
        >
          <MdOutlineRemoveCircleOutline style={{ color: "rgb(52, 73, 94)" }} />
        </button>
      ) : null}
    </div>
  );
};

/* ************************************* Code For UserManu Grid action end ***************************** */

function ProfileManagementLogic() {
  /* ************************************* Code For Profile Grid Binding Start ***************************** */

  const setAlertMessage = AlertMessage();
  const [profileListData, setProfileListData] = useState([]);
  const [isLoadingProfileData, setIsLoadingProfileData] = useState(false);
  const [profileItemSearch, setProfileItemSearch] = useState("");
  const [isLoadingUserProfileData, setIsLoadingUserProfileData] = useState(false);
  const [userProfileItemSearch, setUserProfileItemSearch] = useState("");
  const [gridApi, setGridApi] = useState();
  const [userGridApi, setUserGridApi] = useState();
  const [selecteadRowId, setSelectedRowID] = useState(null);
  const [treeMenuListData, setTreeMenuListData] = useState([]);
  const [recentAddedMenuId, setRecentAddedMenuId] = useState(null);
  const [menuListData, setMenuListData] = useState([]);

  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  const onUserGridReady = (params) => {
    setUserGridApi(params.api);
  };

  const onChangeUserProfileList = (val) => {
    setUserProfileItemSearch(val);
    userGridApi.setQuickFilter(val);
  };

  const onChangeProfileList = (val) => {
    setProfileItemSearch(val);
    gridApi.setQuickFilter(val);
  };

  const getProfileListData = async () => {
    try {
      setIsLoadingProfileData(true);
      const formData = {
        main: {
          userProfileID: 0,
          brHeadTypeID: "",
          viewMode: "SELECT",
          searchText: "#ALL",
        },
      };
      const result = await apiCalling(formData, setup.ProfileManagement.getProfileList);
      setIsLoadingProfileData(false);
      if (result && result.responseCode === 1) {
        if (result && result.responseData && result.responseData.Table) {
          setProfileListData(result.responseData.Table);
          console.log(result && result.responseData && result.responseData.Table, " Data Received");
        }
      } else {
        setAlertMessage({
          type: "error",
          message: result.responseMessage,
        });
        setProfileListData([]);
      }
    } catch (error) {
      console.log(error);
      setAlertMessage({
        type: "error",
        message: error,
      });
      setProfileListData([]);
    }
  };

  useEffect(() => {
    getProfileListData();
  }, []);

  /* ************************************* Code For Profile Grid Binding End ******************************* */

  /* ************************************* Code For Profile Menu Grid Binding Start ***************************** */

  const setSelectedRowColor = (userProfileID) => {
    debugger;
    if (gridApi) {
      gridApi.forEachNode((rowNode) => {
        if (rowNode.data.USERPROFILEID === userProfileID) {
          const newData = {
            ...rowNode.data,
            IsSelected: true,
          };
          rowNode.setData(newData);
        } else {
          rowNode.data.IsSelected = false;
          rowNode.setData(rowNode.data);
        }
      });
    }
  };

  /* ************************************* Code For getUserProfileMamuList Start ***************************** */

  const getUserProfileManu = async (data) => {
    setSelectedRowColor(data.USERPROFILEID);
    setSelectedRowID(data);
    try {
      setIsLoadingUserProfileData(true);
      const formData = {
        main: {
          profileMenuID: 0,
          userProfileID: data && data.USERPROFILEID ? data.USERPROFILEID : "",
          viewMode: VIEWMODE_ASSINGNEDMENU,
          moduleCode: 0,
          menuMasterID: 0,
        },
      };
      const result = await apiCalling(formData, setup.ProfileManagement.UserProfileMenuAssign);
      setIsLoadingUserProfileData(false);
      if (result && result.responseCode === 1) {
        if (result && result.responseData && result.responseData.Table && result.responseData.Table.length > 0) {
          setMenuListData(result.responseData.Table);
          setTreeMenuListData(result.responseData.Table);
          console.log(result.responseData.Table);
        }
      } else {
        setAlertMessage({
          type: "error",
          message: result.responseMessage,
        });
        setMenuListData([]);
        setTreeMenuListData([]);
      }
    } catch (error) {
      console.log(error);
      setAlertMessage({
        type: "error",
        message: error,
      });
      setMenuListData([]);
      setTreeMenuListData([]);
    }
  };

  /* ************************************* Code For getUserProfileMamuList end ***************************** */

  /* ************************************* Code For Assing And UnAssing Menu Start ***************************** */

  const AssingUnAssigndManu = async (data, view, rowIndex) => {
    try {
      console.log(data, " this data is usingaaj", view, rowIndex);
      setIsLoadingUserProfileData(true);
      const formData = {
        main: {
          profileMenuID: data && data.PROFILEMENUID ? data.PROFILEMENUID : 0,
          userProfileID: data && data.USERPROFILEID ? data.USERPROFILEID : 0,
          viewMode: view,
          moduleCode: 0,
          menuMasterID: data && data.MenuMasterID ? data.MenuMasterID : 0,
        },
      };
      const result = await apiCalling(formData, setup.ProfileManagement.UserProfileMenuAssign);
      console.log(result, formData, "result for Assind / unAssind");
      setIsLoadingUserProfileData(false);
      if (result && result.responseCode === 1) {
        const UpdatedDataMenu = treeMenuListData;
        UpdatedDataMenu[rowIndex].ASSIGNMENTFLAG = view === VIEWMODE_UNASSINGMENU ? 0 : view === VIEWMODE_ASSINGMENU ? 1 : null;
        UpdatedDataMenu[rowIndex].IsNewlyAdded = true;
        setTreeMenuListData([]);
        setTreeMenuListData(UpdatedDataMenu);
        console.log(treeMenuListData);
        console.log(UpdatedDataMenu);
        if (userGridApi) {
          userGridApi.setRowData(treeMenuListData);
          setRecentAddedMenuId(null);
        }
        setAlertMessage({
          type: "success",
          message: result.responseMessage,
        });
      } else {
        setAlertMessage({
          type: "error",
          message: result.responseMessage,
        });
      }
    } catch (error) {
      console.log(error);
      setAlertMessage({
        type: "error",
        message: error,
      });
    }
  };

  /* ************************************* Code For Assing And UnAssing Menu end ***************************** */

  /* ************************************* Code For Manu Hierarchy  start ***************************** */

  const recHierarchy = (menu, parent) => {
    if (parent.UNDERMENUID.toString() !== 0) {
      const parentMenu = menuListData.find((x) => x.MENUMASTERID.toString() === parent.UNDERMENUID.toString());
      if (parentMenu !== null && parentMenu !== undefined) {
        menu.orgHierarchy.push(parentMenu.MENUMASTERID);
        recHierarchy(menu, parentMenu);
      }
    }
  };

  const buildHierarchy = () => {
    const menuTreeListData = [];
    menuListData.forEach((menu) => {
      const menuData = {
        ASSIGNMENTFLAG: menu.ASSIGNMENTFLAG,
        PROFILEMENUID: menu.PROFILEMENUID,
        USERPROFILEID: menu.USERPROFILEID,
        MenuMasterID: menu.MENUMASTERID,
        MenuName: menu.MENUNAME,
        ReactURL: menu.REACTURL,
        WebURL: menu.WEBURL,
        IsNewlyAdded: menu.IsNewlyAdded,
        orgHierarchy: [menu.MENUMASTERID],
        HasChild: menu.HASCHILD.toString(),
        APIURL: menu.APIURL,
        AppURL: menu.APPURL,
        MenuSequence: menu.MENUSEQUENCE,
        MenuType: menu.MENUTYPE,
        MenuTypeName: "",
        UnderMenuID: menu.UNDERMENUID,
        WPFURL: menu.WPFURL,
        WinURL: menu.WINURL,
      };

      if (menu.UNDERMENUID.toString() !== "0") {
        const parentMenu = menuListData.find((x) => x.MENUMASTERID.toString() === menu.UNDERMENUID.toString());
        if (parentMenu !== null && parentMenu !== undefined) {
          menuData.orgHierarchy.push(parentMenu.MENUMASTERID);
          recHierarchy(menuData, parentMenu);
        }
      }
      menuTreeListData.push(menuData);
    });

    menuTreeListData.forEach((menu) => {
      menu.orgHierarchy = menu.orgHierarchy.reverse();
    });
    setTreeMenuListData(menuTreeListData);
  };

  useEffect(() => {
    if (menuListData != null && menuListData.length > 0) {
      buildHierarchy();
    } else {
      setTreeMenuListData([]);
    }
  }, [menuListData]);

  useEffect(() => {
    console.log(treeMenuListData, "treeMenuListData");
    if (userGridApi && treeMenuListData.length > 0 && recentAddedMenuId) {
      userGridApi.setRowData(treeMenuListData);
      setRecentAddedMenuId(null);
    }
  }, [treeMenuListData]);
  /* ************************************* Code For Manu Hierarchy  end ***************************** */

  const getUserProfileSerchListData = () => {
    if (selecteadRowId) {
      getUserProfileManu(selecteadRowId);
    }
  };

  /* ************************************* Code For GridHeader start ******************************* */
  const ProfileGridColumnDefs = [
    {
      field: "#",
      headerName: "Action",
      width: 100,
      lockPosition: "1",
      pinned: "left",
      cellRenderer: cellTemplate,
      cellRendererParams: {
        getUserProfileManu,
      },
    },
    { field: "#", headerName: "Sr No.", width: 80, valueGetter: "node.rowIndex + 1" },
    { field: "PROFILENAME", headerName: "Profile Name", width: 180 },
    { field: "PROFILEDESCRIPTION", headerName: "Profile Description", width: 320 },
  ];

  const ProfileMenuGridColumnDefs = [
    {
      field: "#",
      headerName: "Action",
      width: 80,
      lockPosition: "1",
      pinned: "left",
      cellRenderer: cellUserManuTemplate,
      cellRendererParams: {
        AssingUnAssigndManu,
      },
    },
    { field: "ReactURL", headerName: "Web URl", width: 260 },
    { field: "APPURL", headerName: "App Url", width: 260 },
  ];

  /* ************************************* Code For GridHeader End ******************************* */

  /* ************************************* Code For User Profile Menu Grid Binding End ******************************* */

  return {
    ProfileGridColumnDefs,
    profileListData,
    isLoadingProfileData,
    profileItemSearch,
    onGridReady,
    getProfileListData,
    onChangeProfileList,
    ProfileMenuGridColumnDefs,
    getUserProfileManu,
    menuListData,
    treeMenuListData,
    isLoadingUserProfileData,
    userProfileItemSearch,
    onUserGridReady,
    onChangeUserProfileList,
    getUserProfileSerchListData,
  };
}

export default ProfileManagementLogic;
