import { useState } from "react";
import { VIEWMODE_CHILD, VIEWMODE_PARENT } from "Configration/Utilities/Constants/Constants";
import GetApiData from "Configration/Utilities/getApiData/GetApiData";
import { AlertMessage } from "Framework/Components/Widgets";
import { project } from "Service/Endpoints/endpoints";
import { apiCalling } from "Service/Utilities/apiCalling";

function AssetsDetailsLogic() {
  const setAlertMessage = AlertMessage();
  const [AssetDetailsParantListData, setAssetDetailsParantListData] = useState([]);
  const [observationList, setObservationList] = useState([]);
  const [observationListLoading, setObservationListLoading] = useState(false);
  const [AssetDetailsChildListData, setAssetDetailsChildListData] = useState([]);
  const [isLoadingAssetsDetailsData, setIsLoadingAssetsDetailsData] = useState(false);
  const [selectedProjectData, setSelectedProjectData] = useState(null);
  const [assetStatusList, setAssetStatusList] = useState([]);
  const [isLoadingAssetStatusList, setIsLoadingAssetStatusList] = useState(false);

  const [observationFeedList, setObservationFeedList] = useState([]);
  const [isLoadingObservationFeedList, setIsLoadingObservationFeedList] = useState(false);

  const [assetStatus, setAssetStatus] = useState(null);

  const [ListViewColumDef] = useState([
    { headerName: "SL.No.", field: "#", width: 80, pinned: "left", valueGetter: "node.rowIndex + 1" },
    { field: "EQUIPMENTNUMBER", headerName: "Equipment Number", width: 180 },
    { field: "ASSETDESC", headerName: "Asset Desk", width: 150 },
    { field: "GROUPNAME", headerName: "Group Name", width: 150 },
    { field: "CHECKPOINT", headerName: "Check Point", width: 200 },
    { field: "REFERENCETYPE", headerName: "Reference Type", width: 150 },
    { field: "REF_MIN", headerName: "Ref Min", width: 100 },
    { field: "REF_MAX", headerName: "Ref Max", width: 100 },
    { field: "OLABEL", headerName: "O Label", width: 100 },
    { field: "INPUTTYPE", headerName: "Input Type", width: 150 },
    { field: "FREQUENCY", headerName: "Frequency", width: 150 },
    // { field: "DAYOFMONTH", headerName: "Day Of Month", width: 150 },
    // { field: "WEEKOFMONTH", headerName: "Week Of Month", width: 150 },
    // { field: "DAYOFWEEK", headerName: "Day Of Week", width: 150 },
    // { field: "REPEATTYPE", headerName: "Repeat Type", width: 150 },
    { field: "REPEATFREQUENCY", headerName: "Repeat Frequency", width: 150 },
    { field: "OBSERVATIONTIME", headerName: "Observation Time", width: 150 },
    // { field: "ODURATION", headerName: "O Duration", width: 150 },
    // { field: "WEEKLYMAXCOUNT", headerName: "Weekluy Max Count", width: 150 },
    // { field: "MONTHLYMAXCOUNT", headerName: "Monthly Max Count", width: 150 },
    // { field: "INSCADA", headerName: "Inscada", width: 100 },
    { field: "ACTION", headerName: "Action", width: 200 },
    // { field: "PROJECTDIVISIONID", headerName: "Project Division Id", width: 200 },
    { field: "SAFETYCHECKLIST", headerName: "Safety Check List", width: 200 },
  ]);

  /* ************************************* Code For Assets Details Binding Start ***************************** */

  /* ************************************* Code For Api calling Start ***************************** */

  const getAssetsDetailsListData = (parmsData, view) => {
    const formData = {
      main: {
        viewMode: view,
        equipmentnumber: parmsData && parmsData.ASSETCODE ? parmsData.ASSETCODE : "",
        pequipmentnumber: parmsData && parmsData.PEQUIPMENTNUMBER ? parmsData.PEQUIPMENTNUMBER : "",
      },
    };
    const request = {
      setDatalist: view === VIEWMODE_PARENT ? setAssetDetailsParantListData : view === VIEWMODE_CHILD ? setAssetDetailsChildListData : null,
      setLoad: setIsLoadingAssetsDetailsData,
      requestData: formData,
      apiPath: project.projectList.GetAssetType,
      setAlert: setAlertMessage,
      fun: null,
    };
    GetApiData(request);
  };

  // const getAssetsDetailsListData = async (parmsData, view) => {
  //   try {
  //     debugger;
  //     setIsLoadingAssetsDetailsData(true);
  //     const formData = {
  //       main: {
  //         viewMode: view,
  //         equipmentnumber: parmsData && parmsData.ASSETCODE ? parmsData.ASSETCODE : "",
  //         pequipmentnumber: parmsData && parmsData.PEQUIPMENTNUMBER ? parmsData.PEQUIPMENTNUMBER : "",
  //       },
  //     };
  //     console.log(parmsData);
  //     const result = await apiCalling(formData, project.projectList.GetAssetType);
  //     setIsLoadingAssetsDetailsData(false);
  //     console.log("this data Assets Data", result, project.projectList.GetAssetType);
  //     if (result && result.responseCode === 1) {
  //       if (result && result.responseData && result.responseData.Table) {
  //         if (view === VIEWMODE_PARENT) {
  //           setAssetDetailsParantListData(result.responseData.Table);
  //         }
  //         if (view === VIEWMODE_CHILD) {
  //           setAssetDetailsChildListData(result.responseData.Table);
  //         }
  //       }
  //     } else {
  //       if (view === VIEWMODE_PARENT) {
  //         setAssetDetailsParantListData([]);
  //       }
  //       if (view === VIEWMODE_CHILD) {
  //         setAssetDetailsChildListData([]);
  //       }
  //       setAlertMessage({
  //         type: "error",
  //         message: result.responseMessage,
  //       });
  //     }
  //   } catch (error) {
  //     console.log(error);
  //     setAlertMessage({
  //       type: "error",
  //       message: error,
  //     });
  //     if (view === VIEWMODE_PARENT) {
  //       setAssetDetailsParantListData([]);
  //     }
  //     if (view === VIEWMODE_CHILD) {
  //       setAssetDetailsChildListData([]);
  //     }
  //   }
  // };

  const observationListingData = (parmsData) => {
    const formData = {
      main: {
        selectmode: "Header",
        equipmentnumber: parmsData && parmsData.ASSETCODE ? parmsData.ASSETCODE : "",
      },
    };
    const request = {
      setDatalist: setObservationList,
      setLoad: setObservationListLoading,
      requestData: formData,
      apiPath: project.projectList.GetObservationAssetSelect,
      setAlert: setAlertMessage,
      fun: null,
    };
    GetApiData(request);
  };

  // const observationListingData = async (parmsData) => {
  //   try {
  //     setObservationListLoading(true);
  //     const formData = {
  //       main: {
  //         selectmode: "Header",
  //         equipmentnumber: parmsData && parmsData.ASSETCODE ? parmsData.ASSETCODE : "",
  //       },
  //     };
  //     const result = await apiCalling(formData, project.projectList.GetObservationAssetSelect);
  //     setObservationListLoading(false);
  //     console.log(project.projectList.GetObservationAssetSelect, "===project.projectList.GetObservationSelect");
  //     console.log(result, "observationList===ListData");
  //     if (result && result.responseCode === 1) {
  //       if (result && result.responseData && result.responseData.Table) {
  //         setObservationList(result.responseData.Table);
  //       }
  //     } else {
  //       setAlertMessage({
  //         type: "error",
  //         message: result.responseMessage,
  //       });
  //       setObservationList([]);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //     setAlertMessage({
  //       type: "error",
  //       message: error,
  //     });
  //     setObservationList([]);
  //   }
  // };

  const getAssetSatusDropDownList = async (id, selectedProjectData) => {
    setSelectedProjectData(selectedProjectData);
    const formData = {
      main: {
        filterID: id,
        filterID1: 0,
        mastername: "APPMASTVAL",
        searchText: "#ALL",
        searchcriteria: "AW",
      },
    };
    const request = {
      setDatalist: setAssetStatusList,
      setLoad: setIsLoadingAssetStatusList,
      requestData: formData,
      apiPath: project.projectList.GetMasterDataBinding,
      setAlert: setAlertMessage,
      fun: null,
    };
    GetApiData(request);
  };

  // const getAssetSatusDropDownList = async (id, selectedProjectData) => {
  //   setSelectedProjectData(selectedProjectData);
  //   try {
  //     setIsLoadingAssetStatusList(true);
  //     const formData = {
  //       main: {
  //         filterID: id,
  //         filterID1: 0,
  //         mastername: "APPMASTVAL",
  //         searchText: "#ALL",
  //         searchcriteria: "AW",
  //       },
  //     };
  //     const result = await apiCalling(formData, project.projectList.GetMasterDataBinding);
  //     setIsLoadingAssetStatusList(false);
  //     console.log(result, project.projectList.GetMasterDataBinding);
  //     if (result && result.responseCode === 1) {
  //       if (result && result.responseData && result.responseData.Table) {
  //         setAssetStatusList(result.responseData.Table);
  //       }
  //     } else {
  //       setAlertMessage({
  //         type: "error",
  //         message: result.responseMessage,
  //       });
  //       setAssetStatusList([]);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //     setAlertMessage({
  //       type: "error",
  //       message: error,
  //     });
  //     setAssetStatusList([]);
  //   }
  // };

  const getObservationFeedDetails = (parmsData) => {
    const formData = {
      main: {
        viewMode: "ONEMONTH",
        equipmentnumber: parmsData && parmsData.ASSETCODE ? parmsData.ASSETCODE : "",
      },
    };
    const request = {
      setDatalist: setObservationFeedList,
      setLoad: setIsLoadingObservationFeedList,
      requestData: formData,
      apiPath: project.projectList.GetAssetObservationHistory,
      setAlert: setAlertMessage,
      fun: null,
    };
    GetApiData(request);
  };

  // const getObservationFeedDetails = async (parmsData) => {
  //   try {
  //     setIsLoadingObservationFeedList(true);
  //     const formData = {
  //       main: {
  //         viewMode: "ONEMONTH",
  //         equipmentnumber: parmsData && parmsData.ASSETCODE ? parmsData.ASSETCODE : "",
  //       },
  //     };
  //     const result = await apiCalling(formData, project.projectList.GetAssetObservationHistory);
  //     setIsLoadingObservationFeedList(false);
  //     console.log(result, project.projectList.GetAssetObservationHistory);
  //     if (result && result.responseCode === 1) {
  //       if (result && result.responseData && result.responseData.Table) {
  //         setObservationFeedList(result.responseData.Table);
  //       }
  //     } else {
  //       setAlertMessage({
  //         type: "error",
  //         message: result.responseMessage,
  //       });
  //       setObservationFeedList([]);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //     setAlertMessage({
  //       type: "error",
  //       message: error,
  //     });
  //     setObservationFeedList([]);
  //   }
  // };

  const assetUpdateStatus = async (status, data, getAssetListingData) => {
    try {
      setIsLoadingAssetsDetailsData(true);
      const formData = {
        main: {
          equipmentnumber: data && data.ASSETCODE ? data.ASSETCODE : 0,
          statusid: status && status.MASTERVALUEID ? status.MASTERVALUEID : 0,
          // employeeid: 0,
        },
      };
      const result = await apiCalling(formData, project.projectList.SetKPCLStatusUpdate);
      setIsLoadingAssetsDetailsData(false);
      console.log(result, project.projectList.SetKPCLStatusUpdate);
      if (result && result.responseCode === 1) {
        setAlertMessage({
          type: "success",
          message: result.responseMessage,
        });
        getAssetListingData(selectedProjectData);
      } else {
        setAlertMessage({
          type: "error",
          message: result.responseMessage,
        });
      }
    } catch (error) {
      console.log(error);
      setAlertMessage({
        type: "error",
        message: error,
      });
    }
  };

  /* ************************************* Code For Api calling end ***************************** */

  const updateState = (value, data, getAssetListingData) => {
    console.log(value, data);
    if (!value || typeof value === "undefined" || value === null) {
      console.log("null");
    } else {
      setAssetStatus(value);
      assetUpdateStatus(value, data, getAssetListingData);
    }
  };

  /* ************************************* Code For Assets Details Binding end ***************************** */

  return {
    observationList,
    observationListLoading,
    AssetDetailsParantListData,
    AssetDetailsChildListData,
    isLoadingAssetsDetailsData,
    observationListingData,
    getAssetsDetailsListData,
    ListViewColumDef,
    assetStatusList,
    isLoadingAssetStatusList,
    getAssetSatusDropDownList,
    updateState,
    assetStatus,
    getObservationFeedDetails,
    observationFeedList,
    isLoadingObservationFeedList,
  };
}

export default AssetsDetailsLogic;
