import React, { useEffect, useState } from "react";
import { AlertMessage } from "Framework/Components/Widgets";
import { project } from "Service/Endpoints/endpoints";
// import { apiCalling } from "Service/Utilities/apiCalling";
import { getSessionStorage } from "Service/auth/auth";
import { ImEye } from "react-icons/im";
import GetApiData from "Configration/Utilities/getApiData/GetApiData";

const cellTemplate = (params) => {
  const style = {
    display: "flex",
    fontSize: "16px",
    background: "transparent",
    border: "0px solid transparent",
    padding: "4px 0px 5px 0px",
    cursor: "pointer",
  };

  const onTeamMemberListClick = () => {
    if (params) {
      params.setSelectedDivision(params.data);
    }
  };

  return (
    <div style={{ display: "flex", gap: "8px", justifyContent: "center" }}>
      <button type="button" style={style} title="View Detail" onClick={() => onTeamMemberListClick()}>
        <ImEye style={{ color: "rgb(52, 73, 94)" }} />
      </button>
    </div>
  );
};

function TeamsLogic() {
  const setAlertMessage = AlertMessage();
  const [teamsListData, setTeamsListData] = useState([]);
  const [filteredTeamsListData, setFilteredTeamsListData] = useState([]);
  const [isLoadingTeamsData, setIsLoadingTeamsData] = useState(false);
  const [selectedProjectData, setSelectedProjectData] = useState(null);
  const [teamsListMembersData, setTeamsListMembersData] = useState([]);
  const [selectedDivision, setSelectedDivision] = useState([]);
  const [isLoadingTeamsMemberData, setIsLoadingTeamsMemberData] = useState(false);
  const [gridApi, setGridApi] = useState();
  const user = getSessionStorage("userData");
  const [divisionList, setDivisionList] = useState([]);
  const [isLoadingDivisionList, setIsLoadingDivisionList] = useState(false);


  const [ListViewColumDef] = useState([
    {
      headerName: "Action",
      field: "#",
      width: 80,
      pinned: "left",
      cellRenderer: cellTemplate,
      cellRendererParams: {
        setSelectedDivision,
      },
    },
    { headerName: "Sr. No.", field: "#", width: 80, valueGetter: "node.rowIndex + 1" },

    { field: "DIVISIONID", headerName: "Team ID", width: 120 },
    { field: "DIVISIONNAME", headerName: "Team Name", width: 180 },
    { field: "#", headerName: "Team Type", width: 180 },
  ]);

  const [MemberListViewColumDef] = useState([
    { headerName: "Sr. No.", field: "#", width: 80, valueGetter: "node.rowIndex + 1" },
    { field: "EMPLOYEENAME", headerName: "User Name", width: 220 },
    { field: "DIVISIONNAME", headerName: "Division Name", width: 220 },
    { field: "EMPLOYEEID", headerName: "Employee Code", width: 180 },
    { field: "DESIGNATIONID", headerName: "Role Name", width: 220 },
  ]);
  /* ************************************* Code For Teams Grid Binding start ******************************* */

  // const getTeamsListingData = async () => {
  //   try {
  //     setIsLoadingTeamsData(true);
  //     setTeamsListMembersData([]);
  //     const formData = {
  //       main: {
  //         divisionid: selectedProjectData && selectedProjectData.DIVISIONIDs ? selectedProjectData.DIVISIONIDs : "",
  //         profileid: user && user.UserProfileID ? user.UserProfileID : null,
  //         projectid: selectedProjectData && selectedProjectData.PROJECTID ? selectedProjectData.PROJECTID : "",
  //       },
  //     };
  //     const result = await apiCalling(formData, project.projectList.GetKPCLDivisionDetail);
  //     setIsLoadingTeamsData(false);
  //     if (result && result.responseCode === 1) {
  //       if (result && result.responseData && result.responseData.Table) {
  //         setFilteredTeamsListData(result.responseData.Table);
  //         if (result.responseData.Table.length > 0) {
  //           const uniqueDivisionName = [];
  //           result.responseData.Table.forEach((data) => {
  //             let isExist = false;
  //             if (uniqueDivisionName.length > 0) {
  //               isExist = uniqueDivisionName.some((x) => x.DIVISIONID.toString() === data.DIVISIONID.toString());
  //             }
  //             if (!isExist) {
  //               uniqueDivisionName.push(data);
  //             }
  //           });
  //           setTeamsListData(uniqueDivisionName);
  //         }
  //       }
  //     } else {
  //       setAlertMessage({
  //         type: "error",
  //         message: result.responseMessage,
  //       });
  //       setTeamsListData([]);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //     setAlertMessage({
  //       type: "error",
  //       message: error,
  //     });
  //     setTeamsListData([]);
  //   }
  // };

  const getUniqueDivisionNameData = (responseData) => {
    if (responseData.length > 0) {
      const uniqueDivisionName = [];
      responseData.forEach((data) => {
        let isExist = false;
        if (uniqueDivisionName.length > 0) {
          isExist = uniqueDivisionName.some((x) => x.DIVISIONID.toString() === data.DIVISIONID.toString());
        }
        if (!isExist) {
          uniqueDivisionName.push(data);
        }
      });
      setTeamsListData(uniqueDivisionName);
    }
  };

  const getTeamsListingData = () => {
    setTeamsListMembersData([]);
    const formData = {
      main: {
        divisionid: selectedProjectData && selectedProjectData.DIVISIONIDs ? selectedProjectData.DIVISIONIDs : "",
        profileid: user && user.UserProfileID ? user.UserProfileID : null,
        projectid: selectedProjectData && selectedProjectData.PROJECTID ? selectedProjectData.PROJECTID : "",
      },
    };

    const request = {
      setDatalist: setFilteredTeamsListData,
      setLoad: setIsLoadingTeamsData,
      requestData: formData,
      apiPath: project.projectList.GetKPCLDivisionDetail,
      setAlert: setAlertMessage,
      fun: getUniqueDivisionNameData,
    };
    GetApiData(request);
  };


  const getDivisionDetailsList = async () => {
    setDivisionList([]);
    const formData = {
      main: {
        divisionid: selectedProjectData && selectedProjectData.DIVISIONIDs ? selectedProjectData.DIVISIONIDs : "",
        profileid: user && user.UserProfileID ? user.UserProfileID : null,
        projectid: selectedProjectData && selectedProjectData.PROJECTID ? selectedProjectData.PROJECTID : "",
      },
    };

    const request = {
      setDatalist: setDivisionList,
      setLoad: setIsLoadingDivisionList,
      requestData: formData,
      apiPath: project.projectList.GetKPCLDivisionDetail,
      setAlert: setAlertMessage,
      fun: getUniqueDivisionNameData,
    };
    GetApiData(request);
  };

  // const getTeamsDetailListing = async () => {
  //   try {
  //     setTeamsListMembersData([]);
  //     setIsLoadingTeamsMemberData(true);
  //     const formData = {
  //       main: {
  //         divisionid: selectedDivision && selectedDivision.DIVISIONID ? selectedDivision.DIVISIONID.toString() : "",
  //         profileid: user && user.UserProfileID ? user.UserProfileID : null,
  //         projectid: selectedDivision && selectedDivision.PROJECTID ? selectedDivision.PROJECTID : "",
  //       },
  //     };
  //     console.log(formData);
  //     const result = await apiCalling(formData, project.projectList.GetKPCLTeam);
  //     setIsLoadingTeamsMemberData(false);
  //     console.log("temsDetails", result);
  //     if (result && result.responseCode === 1) {
  //       if (result && result.responseData && result.responseData.Table) {
  //         setTeamsListMembersData(result.responseData.Table);
  //       }
  //     } else {
  //       setAlertMessage({
  //         type: "error",
  //         message: result.responseMessage,
  //       });
  //       setTeamsListMembersData([]);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //     setAlertMessage({
  //       type: "error",
  //       message: error,
  //     });
  //     setTeamsListMembersData([]);
  //   }
  // };
  const getTeamsDetailListing = () => {
    setTeamsListMembersData([]);

    setIsLoadingTeamsMemberData(true);
    const formData = {
      main: {
        divisionid: selectedDivision && selectedDivision.DIVISIONID ? selectedDivision.DIVISIONID.toString() : "",
        profileid: user && user.UserProfileID ? user.UserProfileID : null,
        projectid: selectedDivision && selectedDivision.PROJECTID ? selectedDivision.PROJECTID : "",
      },
    };

    const request = {
      setDatalist: setTeamsListMembersData,
      setLoad: setIsLoadingTeamsMemberData,
      requestData: formData,
      apiPath: project.projectList.GetKPCLTeam,
      setAlert: setAlertMessage,
      fun: null,
    };
    GetApiData(request);
  };

  console.log("teamsListMembersData in teams logic", teamsListMembersData)

  const setSelectedRowColor = (divisionID) => {
    debugger;
    if (gridApi) {
      gridApi.forEachNode((rowNode) => {
        // console.log(rowNode);
        if (rowNode.data.DIVISIONID === divisionID) {
          const newData = {
            ...rowNode.data,
            IsSelected: true,
          };
          rowNode.setData(newData);
        } else {
          rowNode.data.IsSelected = false;
          rowNode.setData(rowNode.data);
        }
      });
    }
  };

  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  useEffect(() => {
    console.log("selectedDivision", selectedDivision)
    if (selectedDivision && selectedDivision.DIVISIONID) {
      getTeamsDetailListing();
      setSelectedRowColor(selectedDivision.DIVISIONID);
    }
  }, [selectedDivision]);

  useEffect(() => {
    if (selectedProjectData && selectedProjectData.PROJECTID) {
      getTeamsListingData();
    }
  }, [selectedProjectData]);

  /* ************************************* Code For User Profile Menu Grid Binding End ******************************* */

  return {
    ListViewColumDef,
    teamsListData,
    MemberListViewColumDef,
    isLoadingTeamsData,
    setSelectedProjectData,
    getTeamsListingData,
    teamsListMembersData,
    isLoadingTeamsMemberData,
    filteredTeamsListData,
    // getTeamsMembersListingData,
    selectedDivision,
    onGridReady,
    divisionList,
    isLoadingDivisionList,
    getDivisionDetailsList
  };
}

export default TeamsLogic;
