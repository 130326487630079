// ************************** Login Page Constants ************************** //

export const LOGIN_VIEW = "Login";
export const LOGIN_APP_TYPE = 1;

// ************************** Sign Up Page Constants ************************** //

export const SIGNUP_VIEW = "SignUP_OTPCREATION";
export const SIGNUP_OTPVALIDATE_VIEW = "SignUP_OTPVALIDATION";
export const SIGNUP_ONBOARDINGMSG_VIEW = "SignUP_CONTRATULATIONS";
export const COMPANY_EMPLOYEE = 108001;
export const CONTRACTUAL_EMPLOYEE = 108002;

// ************************** ProfileManagement Page Constants start  ************************** //

export const VIEWMODE_ASSINGMENU = "AssignMenu";
export const VIEWMODE_ASSINGNEDMENU = "AssignedMenu";
export const VIEWMODE_UNASSINGMENU = "UNAssignMenu";

// ************************** ProfileManagement Page Constants End  ************************** //

// ************************** Project Details Page Constants start ************************** //

export const PROJECT_DETAIL_DASHBOARD = "PROJECT_DETAIL_Dashboard";
export const PROJECT_DETAIL_STATUS_BOARD = "PROJECT_DETAIL_Status_Board";
export const PROJECT_DETAIL_ASSET_HISTORY = "PROJECT_DETAIL_Asset_History";
export const PROJECT_DETAIL_ASSETS = "PROJECT_DETAIL_Assets";
export const PROJECT_DETAIL_DEFECT_LIST = "PROJECT_DETAIL_Defect_List";
export const PROJECT_DETAIL_PLANNED_MAINTENANCE = "PROJECT_DETAIL_Planned_Maintenance";
export const PROJECT_DETAIL_PM_LIST = "PROJECT_DETAIL_PM_List";
export const PROJECT_DETAIL_PTW_LIST = "PROJECT_DETAIL_PTW_List";
export const PROJECT_DETAIL_OBSERVATIONS = "PROJECT_DETAIL_Observations";
export const PROJECT_DETAIL_TEAMS = "PROJECT_DETAIL_Teams";
export const PROJECT_DETAIL_ALERTSEVENTS = "PROJECT_DETAIL_AlertsEvents";
export const PROJECT_DETAIL_WORK_ASSIGNMENT = "PROJECT_DETAIL_Work_Assignment";
export const PROJECT_DETAIL_SKIP_APPROVALS = "PROJECT_DETAIL_Skip_Approvals";
export const PROJECT_DETAIL_QRCODES = "PROJECT_DETAIL_QRCodes";
export const PROJECT_DETAIL_GRIEVANCES = "PROJECT_DETAIL_Grievances";
export const PROJECT_DETAIL_WORK_OBSERVATION_ASSIGNMENT = "PROJECT_DETAIL_Work_Observation_Assignment";
export const PROJECT_DETAILS_ASSET_STATUS = "PROJECT_DETAIL_Asset_Status";
export const PROJECT_DETAIL_CREATE_LOG_SHEETS = "PROJECT_DETAIL_Create_Log_Sheets";
export const PROJECT_DETAIL_CAPTURE_LOG_SHEETS = "PROJECT_DETAIL_Capture_Log_Sheets";
export const PROJECT_DETAIL_CAPTURE_LOG_SHEETS_EXCEL = "PROJECT_DETAIL_Capture_Log_Sheets_Excel";
export const PROJECT_DETAIL_LOGSHEET_REPORT = "PROJECT_DETAIL_Logsheet_report";
export const PROJECT_DETAIL_LOGSHEET_LIST = "PROJECT_DETAIL_Logsheet_list";
export const PROJECT_DETAIL_HISTORY_READINGS = "PROJECT_DETAIL_History_Readings";
export const PROJECT_DETAIL_PERFORMANCE_DATA = "PROJECT_DETAIL_Performance_Data";
export const PROJECT_DAILY_PERFORMANCE_DATA = "PROJECT_DAILY_Performance_data";
export const PROJECT_DETAIL_AOH ="PROJECT_DETAIL_AOH";
export const PROJECT_DETAIL_MOH ="PROJECT_DETAIL_MOH";
// code for AssetDetails Constants
export const VIEWMODE_CHILD = "CHILD";
export const VIEWMODE_PARENT = "PARENT";

// code for  Observations MasteerData  Constants
export const MASTERNAME = "APPMASTVAL";
export const MASTERDATA_CURRENTSTATUS = 1001;
export const MASTERDATA_REFERENCETYPE = 1002;
export const MASTERDATA_FREQUENCY = 1007;
export const MASTERDATA_SAFTYCHECKPONIT = 1003;
export const MASTERDATA_GROUP = 1004;
export const MASTERDATA_INPUTTYPE = 1005;
export const MASTERDATA_STATUSTYPE = 1006;
export const ASSETSDATA = "Asset";
export const ASSETSMEMBERDATA = "AssetMember";

// code for  AddObservations Constants
export const REFERENCETYPE_VALUE = "201";
export const REFERENCETYPE_TEXT = "202";

// code for  AddObservations Constants
export const OBSERVATION_REJECT = "REJECT";
export const OBSERVATION_APPROVAL = "APPROVE";

export const PENDING_STATUS = 901;
export const REJECTED_STATUS = 902;
export const APPROVED_STATUS = 903;

// ************************** Project Details Page Constants End ************************** //
