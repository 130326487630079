import React, { useEffect,useState } from "react";
import { DataGrid, PageBar } from "Framework/Components/Layout";
import { Loader } from "Framework/Components/Widgets";
import BizClass from "./Assets.module.scss";
import AssetsLogics from "./logic/AssetsLogics";
import AssetsDetails from "../AssetsDetails/AssetsDetails";
import Modal from "Framework/Components/Layout/Modal/Modal";
import { Button } from "Framework/Components/Widgets";
import Form from "Framework/Components/Layout/FormGroup/FormGroup";
import { IoMdCreate } from "react-icons/io";
import { project } from "Service/Endpoints/endpoints";
import axios from "../../../../Configration/axios/axios"

function Assets({ selectedProjectData }) {
  const {
    ListViewColumDef,
    isLoadingAssetsData,
    assetsListData,
    getAssetListingData,
    toggleAssetDetailsView,
    assetsDetailseViewData,
    isActiveAssetViewPopUp,
    toggleAssetDetailsEdit,
    assetsDetailsEditData,
    isActiveAssetEditPopUp,
    searchText,
    onGridReady,
    onChangeSearchText,
  } = AssetsLogics();

  useEffect(() => {
    getAssetListingData();
  }, []);

  const [editedRowData, setEditedRowData] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const floorLocations = ["1", "2", "3", "4", "5","6","7","8","9","10","11","12","13","14","15","16","17","18","19","20"];
  const [selectedAssetName, setselectedAssetName] = useState('');
  const [selectedAssetCode, setselectedAssetCode] = useState('');
  const [selectedDivisionName, setselectedDivisionName] = useState('');
  const [selectedFunctionalLocations, setselectedFunctionalLocations] = useState('');
  const [selectedAssetStatus, setselectedAssetStatus] = useState('');
  const [selectedFloorLocations, setselectedFloorLocations]=useState([]);
  const [selectedEquipmentNumber, setselectedEquipmentNumber] = useState('');
  const [selectedManufacturer, setselectedManufacturer] = useState('');
  const [selectedAssetLocalId, setselectedAssetLocalId] = useState('');
  const [selectedStatusId, setselectedStatusId] = useState('');



useEffect(()=>{
  if(editedRowData){
    setselectedAssetName(editedRowData.ASSETNAME);
    setselectedAssetCode(editedRowData.ASSETCODE);
    setselectedDivisionName(editedRowData.DIVISIONNAME);
    setselectedFunctionalLocations(editedRowData.FUNCTIONALLOCATION);
    setselectedAssetStatus(editedRowData.MASTERDISPLAYNAME);
    setselectedFloorLocations(editedRowData.floorLocations);
    setselectedAssetLocalId(editedRowData.ASSETLOCALID);
    setselectedEquipmentNumber(editedRowData.PEQUIPMENTNUMBER);
    setselectedManufacturer(editedRowData.MANUFACTURER);
    setselectedStatusId(editedRowData.ASSET_STATUS);
  }
}, [editedRowData]);

const EditCellRenderer = (props) => {
 

  return (
    <div>
       
        <IoMdCreate
          onClick={() => {
            console.log("Edit clicked for row with data:", props.data);
            setEditedRowData(props.data);
            setIsEditModalOpen(true);
          }}
        />
       
    </div>
  );
};
 

const updatedColumnDefs = [
  {
    headerName: "Edit",
    cellRendererFramework: EditCellRenderer,
    minWidth: 100,
    maxWidth: 100,
    sortable: false,
    filter: false,
  },
  ...ListViewColumDef,
  
];

const handleInputChange = (e, field) => {
  const newValue = e.target.value;

  switch (field) {
    case "ASSETNAME":
      setselectedAssetName(newValue);
      break;
    case "ASSETCODE":
      setselectedAssetCode(newValue);
      break;
    case "DIVISIONNAME":
      setselectedDivisionName(newValue);
      break;
    case "FUNCTIONALLOCATION":
      setselectedFunctionalLocations(newValue);
      break;
    case "MASTERDISPLAYNAME":
      setselectedAssetStatus(newValue);
      break;
      case "PEQUIPMENTNUMBER":
        setselectedEquipmentNumber(newValue);
        break;
        case "ASSETLOCALID":
          setselectedAssetLocalId(newValue);
          break;
          case "MANUFACTURER":
            setselectedManufacturer(newValue);
            break;
            case "ASSET_STATUS":
              setselectedStatusId(newValue);
              break;
    default:
      break;
  }
};

// const formValue = (e, field) => {
//   if (field) {
//     console.log("formvalue e", e);
//     const newValue = e;
//     console.log("newValue", newValue);
//     setselectedFloorLocations(newValue);
//     console.log("selectedFloorLocations", selectedFloorLocations);
//   }
// };

const formValue = (e, field) => {
  if (field) {
    console.log("formvalue e", e);
    const newValue = e;
    console.log("newValue", newValue);

    // Use a callback function to update the state based on the previous state
    setselectedFloorLocations(newValue);

    console.log("selectedFloorLocations", selectedFloorLocations);
  }
};

const openEditModal = (rowData) => {
  setEditedRowData(rowData);
  setIsEditModalOpen(true);

};


const exportToCSV = () => {
  const headers = Object.keys(assetsListData[0]);

  const csvContent = "data:text/csv;charset=utf-8," +
    headers.join(",") + "\n" +
    assetsListData.map(row => headers.map(header => row[header]).join(",")).join("\n");
  const encodedUri = encodeURI(csvContent);
  const link = document.createElement("a");
  link.setAttribute("href", encodedUri);
  link.setAttribute("download", "assets.csv");
  document.body.appendChild(link);
  link.click();
};


const saveChanges = async () => {
  console.log("this.state.selectedFloorLocations", selectedFloorLocations);
  console.log(
    selectedAssetName,
    selectedAssetCode,
    selectedDivisionName,
    selectedFunctionalLocations,
    selectedAssetStatus,
    selectedFloorLocations,
    selectedEquipmentNumber,
    selectedManufacturer,
    selectedStatusId,
    selectedAssetLocalId
  );
  setIsEditModalOpen(false);
  var statusId;
  if(selectedStatusId==='ACTIVE'){
    statusId=1;
  }
  else{
    statusId=0;
  }
  const formData = {
    "equipmentnumber": selectedEquipmentNumber,
    "assetname": selectedAssetName,
    "assetcode": selectedAssetCode,
    "functionalloc": selectedFunctionalLocations,
    "manufacturer": selectedManufacturer,
    "statusid": statusId,
    "assetlocid": selectedAssetLocalId,
    "location": selectedFloorLocations
  };

  try {
    const response = await axios.post(
      `${project.projectList.UpdateAsset}`,
      formData,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    console.log("API Response:", response.data);
    if (response.status === 200) {
      alert("Assets Saved Successfully!");
    } else {
      console.error("Unexpected response status:", response.status);
    }
  } catch (error) {
    console.error("Error in saveChanges:", error);
  }
};





const closeEditModal = () => {
  setIsEditModalOpen(false);
};

  return (
    <>
      {isActiveAssetViewPopUp && (
        <AssetsDetails
          assetsDetailseViewData={assetsDetailseViewData}
          toggleAssetDetailsView={toggleAssetDetailsView}
          selectedProjectData={selectedProjectData}
          getAssetListingData={getAssetListingData}
        />
      )}
      <div className={BizClass.AssetsGrid}>
        <PageBar>
          <PageBar.Search onChange={(e) => onChangeSearchText(e.target.value)} value={searchText} onClick={() => getAssetListingData()} />
          <PageBar.Button varient="primary" onClick={exportToCSV}>
            Export to CSV
          </PageBar.Button>
        </PageBar>
        <DataGrid rowData={assetsListData} columnDefs={updatedColumnDefs} loader={isLoadingAssetsData ? <Loader /> : null} onGridReady={onGridReady} />
      </div>


      {isEditModalOpen && (
        <Modal
          varient="center"
          title="Edit Assets"
          index={2}
          show={closeEditModal}
          width="754px"
          height="400px"
        >
          <Modal.Body>
            <Form>
              <Form.Group column={2} controlwidth="240px">
                <Form.InputGroup label="Asset Name">
                  <Form.InputControl
                    control="input"
                    name="txtAssetName"
                    maxLength="200"
                    value={selectedAssetName}
                    onChange={(e) => handleInputChange(e, "ASSETNAME")}
                  />
                </Form.InputGroup>

                <Form.InputGroup label="Asset Code">
                  <Form.InputControl
                    control="input"
                    name="txtAssetCode"
                    maxLength="200"
                    readOnly
                    value={selectedAssetCode}
                    onChange={(e) => handleInputChange(e, "ASSETCODE")}
                  />
                </Form.InputGroup>
                <Form.InputGroup label="Division Name">
                  <Form.InputControl
                    control="input"
                    name="txtDivisionName"
                    maxLength="200"
                    readOnly
                    value={selectedDivisionName}
                    onChange={(e) => handleInputChange(e, "DIVISIONNAME")}
                  />

                </Form.InputGroup>




                <Form.InputGroup label="Equipment Number">
                  <Form.InputControl
                    control="input"
                    name="txtEquipmentNumber"
                    maxLength="200"
                    readOnly
                    value={selectedEquipmentNumber}
                    onChange={(e) => handleInputChange(e, "PEQUIPMENTNUMBER")}
                  />

                </Form.InputGroup>


                <Form.InputGroup label="Manufacturer">
                  <Form.InputControl
                    control="input"
                    name="txtManufacturer"
                    maxLength="200"
                    readOnly
                    value={selectedManufacturer}
                    onChange={(e) => handleInputChange(e, "MANUFACTURER")}
                  />

                </Form.InputGroup>


                <Form.InputGroup label="Asset Location Id">
                  <Form.InputControl
                    control="input"
                    name="txtAssetLocId"
                    maxLength="200"
                    readOnly
                    value={selectedAssetLocalId}
                    onChange={(e) => handleInputChange(e, "ASSETLOCALID")}
                  />

                </Form.InputGroup>


                <Form.InputGroup label="Asset Status Id">
                  <Form.InputControl
                    control="input"
                    name="txtAssetStatus"
                    maxLength="200"
                    
                    value={selectedStatusId}
                    onChange={(e) => handleInputChange(e, "ASSET_STATUS")}
                  />

                </Form.InputGroup>











               
                <Form.InputGroup label="Functional Locations">
                  <Form.InputControl
                    control="input"
                    name="txtFunctionalLocations"
                    value={selectedFunctionalLocations}
                    readOnly
                    onChange={(e) => {
                      handleInputChange(e, "FUNCTIONALLOCATION");
                    }}
                  />
                </Form.InputGroup>

                <Form.InputGroup label="Asset Status">
                  <Form.InputControl
                    control="input"
                    name="txtAssetStatus"
                    value={selectedAssetStatus}
                    onChange={(e) => {
                      handleInputChange(e, "MASTERDISPLAYNAME");
                    }}
                  />
                </Form.InputGroup>
                <Form.InputGroup label="Floor Locations">
                <Form.InputControl
  control="select"
  name="floorLocations"
  options={floorLocations.map((option) => ({
    label: option,
    value: option,
  }))}
  defaultValue={selectedFloorLocations}  
  onChange={(e) => {
    console.log("e", e);
    formValue(e.value, "floorLocations");  
  }}
/>

      </Form.InputGroup>


              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button
              varient="primary"
              onClick={() => saveChanges()}
            >
              Save
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
}

export default Assets;
