import React, { useState, useEffect } from "react";
import { AlertMessage } from "Framework/Components/Widgets";
import { setup } from "Service/Endpoints/endpoints";
import { apiCalling } from "Service/Utilities/apiCalling";
import { ImBin } from "react-icons/im";
import { AiFillEdit } from "react-icons/ai";
import { BsPlusSquareFill } from "react-icons/bs";

const cellTemplate = (params) => {
  const style = {
    display: "flex",
    fontSize: "15px",
    background: "transparent",
    border: "0px solid transparent",
    padding: "4px 0px 5px 0px",
  };

  return (
    <div style={{ display: "flex", gap: "8px" }}>
      <button type="button" style={style} title="Delete Menu">
        <ImBin style={{ color: "rgb(52, 73, 94)" }} />
      </button>
      <button type="button" style={style} title="Edit Menu">
        <AiFillEdit style={{ color: "rgb(52, 73, 94)" }} />
      </button>
      {params.data.HasChild === "1" && (
        <button type="button" style={style} title="Add Sub-Menu">
          <BsPlusSquareFill style={{ color: "rgb(52, 73, 94)" }} />
        </button>
      )}
    </div>
  );
};

function MenuManagementLogic() {
  /* ************************************* Code For User List Grid Binding Start ***************************** */

  const setAlertMessage = AlertMessage();
  const [menuListData, setMenuListData] = useState([]);
  const [treeMenuListData, setTreeMenuListData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [recentAddedMenuId, setRecentAddedMenuId] = useState(null);
  const [menuItemSearch, setMenuItemSearch] = useState("");
  const [gridApi, setGridApi] = useState();

  const GridColumnDefs = [
    { field: "#", headerName: "Action", width: 120, lockPosition: "1", pinned: "left", cellRenderer: cellTemplate },
    { field: "ReactURL", headerName: "Web URl", width: 260 },
    { field: "APPURL", headerName: "App Url", width: 260 },
    { field:"MENUNAME", headerName:"Menu Name", width:180},
  ];

  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  const getMenuListData = async () => {
    try {
      setIsLoading(true);
      const formData = {
        main: {
          menumasterid: 0,
          viewmode: "MENUMASTER",
          modulecode: 0,
          userid: 0,
        },
      };
      const result = await apiCalling(formData, setup.MenuManagement.getMenuList);
      setIsLoading(false);
      if (result && result.responseCode === 1) {
        if (result && result.responseData && result.responseData.Table) {
          setMenuListData(result.responseData.Table);
          setTreeMenuListData(result.responseData.Table);
          console.log("treeMenUListData",treeMenuListData);
          console.log(result && result.responseData && result.responseData.Table, " Data Received");
        }
      } else {
        setAlertMessage({
          type: "error",
          message: result.responseMessage,
        });
        setMenuListData([]);
        setTreeMenuListData([]);
      }
    } catch (error) {
      console.log(error);
      setAlertMessage({
        type: "error",
        message: error,
      });
      setMenuListData([]);
      setTreeMenuListData([]);
    }
  };

  const recHierarchy = (menu, parent) => {
    if (parent.UNDERMENUID.toString() !== 0) {
      const parentMenu = menuListData.find((x) => x.MENUMASTERID.toString() === parent.UNDERMENUID.toString());
      if (parentMenu !== null && parentMenu !== undefined) {
        menu.orgHierarchy.push(parentMenu.MENUMASTERID);
        recHierarchy(menu, parentMenu);
      }
    }
  };

  const buildHierarchy = () => {
    const menuTreeListData = [];
    menuListData.forEach((menu) => {
      const menuData = {
        MenuMasterID: menu.MENUMASTERID,
        MenuName: menu.MENUNAME,
        ReactURL: menu.REACTURL,
        WebURL: menu.WEBURL,
        IsNewlyAdded: menu.IsNewlyAdded,
        orgHierarchy: [menu.MENUMASTERID],
        HasChild: menu.HASCHILD.toString(),
        APIURL: menu.APIURL,
        AppURL: menu.APPURL,
        MenuSequence: menu.MENUSEQUENCE,
        MenuType: menu.MENUTYPE,
        MenuTypeName: "",
        UnderMenuID: menu.UNDERMENUID,
        WPFURL: menu.WPFURL,
        WinURL: menu.WINURL,
      };

      if (menu.UNDERMENUID.toString() !== "0") {
        const parentMenu = menuListData.find((x) => x.MENUMASTERID.toString() === menu.UNDERMENUID.toString());
        if (parentMenu !== null && parentMenu !== undefined) {
          menuData.orgHierarchy.push(parentMenu.MENUMASTERID);
          recHierarchy(menuData, parentMenu);
        }
      }
      menuTreeListData.push(menuData);
    });

    menuTreeListData.forEach((menu) => {
      menu.orgHierarchy = menu.orgHierarchy.reverse();
    });
    setTreeMenuListData(menuTreeListData);
  };

  const onSearchList = (val) => {
    setMenuItemSearch(val);
    gridApi.setQuickFilter(val);
  };

  useEffect(() => {
    getMenuListData();
  }, []);

  useEffect(() => {
    if (menuListData != null && menuListData.length > 0) {
      buildHierarchy();
    } else {
      setTreeMenuListData([]);
    }
  }, [menuListData]);

  useEffect(() => {
    debugger;
    if (gridApi && treeMenuListData.length > 0 && recentAddedMenuId) {
      gridApi.setRowData(treeMenuListData);
      setRecentAddedMenuId(null);
    }
  }, [treeMenuListData]);

  /* ************************************* Code For User List Grid Binding End ******************************* */

  return { GridColumnDefs, getMenuListData, menuListData, treeMenuListData, isLoading, gridApi, onGridReady, menuItemSearch, onSearchList };
}

export default MenuManagementLogic;
