import React, { useEffect, useState } from "react";
import { project } from "Service/Endpoints/endpoints";
import { DataGrid, PageBar } from "Framework/Components/Layout";
import axios from "../../../../Configration/axios/axios";
import { getSessionStorage } from "Service/auth/auth";
import BizClass from "./ptw.module.scss";
import { Loader } from "Framework/Components/Widgets";
import { AlertMessage } from "Framework/Components/Widgets";
import GetApiData from "Configration/Utilities/getApiData/GetApiData";
import Modal from "Framework/Components/Layout/Modal/Modal";
import Form from "Framework/Components/Layout/FormGroup/FormGroup";
import { Button } from "Framework/Components/Widgets";
import { ImEye } from "react-icons/im";
import { Buffer } from "buffer";

const pako = require("pako");


function MyComponent() {
    const setAlertMessage = AlertMessage();
    const user = getSessionStorage("userData");
    console.log("user", user)
    const [employeeID, setEmployeeID] = useState([]);
    const [data, setData] = useState([]);
    const [SelectedPTWDetail, setSelectedPTWDetail] = useState([]);
    const [PTWModalOpen, setPTWModalOpen] = useState(false);
    const [isolationsModal, setIsolationsModal] = useState(false)
    const [isolationInputs, setIsolationInputs] = useState(['']);
    const [isChecked, setIsChecked] = useState(Array.from({ length: isolationInputs.length }, () => false));
    const [remarks, setRemarks] = useState(Array.from({ length: isolationInputs.length }, () => ''));
    const [Uremarks, setUremarks] = useState(Array.from({ length: isolationInputs.length }, () => ''));
    const [showAdditionalInput, setShowAdditionalInput] = useState(false);
    const [additionalFields, setAdditionalFields] = useState([]);
    const [showAdditionalOptionalInput, setShowAdditionalOptionalInput] = useState(false);
    const [additionalOperationFields, setAdditionalOperationFields] = useState([]);

    const handleIsolationInput = () => {
        setShowAdditionalInput(true)
        // setIsolationInputs([...isolationInputs, '']);
        setAdditionalFields([...additionalFields, '']);
    };
    const handleOperationInput = () => {
        setShowAdditionalOptionalInput(true)
        // setIsolationInputs([...isolationInputs, '']);
        setAdditionalOperationFields([...additionalOperationFields, '']);
    };


    const cellTemplate = (params) => {
        const style = {
            display: "flex",
            fontSize: "16px",
            background: "transparent",
            border: "0px solid transparent",
            padding: "4px 0px 5px 0px",
            cursor: "pointer",
        };

        const onTeamMemberListClick = () => {
            if (params) {
                setSelectedPTWDetail(params.data);
                console.log("params.data", params.data)
                setPTWModalOpen(true);
                getEHIDetailsforIsolation(params.data.PTW_NUMBER);
                getEHIDetailsforHazardoes(params.data.PTW_NUMBER);
                getEHIDeatilsforEquipment(params.data.PTW_NUMBER);
                getDefectDetails(params.data.DEFECTID);
                getPTWDetailsByDefectIdPTWId(params.data.DEFECTID, params.data.PTW_NUMBER)
            }
        };
        return (
            <div style={{ display: "flex", gap: "8px", justifyContent: "center" }}>
                <button type="button" style={style} title="View Detail" onClick={() => onTeamMemberListClick()}>
                    <ImEye style={{ color: "rgb(52, 73, 94)" }} />
                </button>
            </div>
        );
    }

    const formatDate = (dateString) => {
        const parts = dateString.split(' ');
        return parts[0];
    };


    // const [ListViewColumDef] = useState([
    //     { headerName: "Sr. No.", field: "#", width: 80, valueGetter: "node.rowIndex + 1" },
    //     { field: 'defectid', headerName: 'Defect ID', width: 120 },
    //     { field: 'ptW_ID', headerName: 'PTW ID', width: 180 },
    //     { field: 'ptwstatus', headerName: 'PTW Status', width: 180 },
    //     { field: 'ptW_DESCRIPTION', headerName: 'PTW Description', width: 180 },
    //     { field: 'issuinG_EMPLOYEENAME', headerName: 'PTW Requested By', width: 180 },
    //     { field: 'divisionname', headerName: 'Requesting Division', width: 180 },
    //     { field: 'froM_DATE', headerName: 'From Date', width: 180, valueGetter: (params) => formatDate(params.data.froM_DATE) },
    //     { field: 'tO_DATE', headerName: 'To Date', width: 180, valueGetter: (params) => formatDate(params.data.tO_DATE) },
    //     // { field: 'issuinG_EMPLOYEENAME', headerName: 'Issuing Employee', width: 180 },
    //     {
    //         headerName: "Action",
    //         field: "#",
    //         width: 80,
    //         pinned: "left",
    //         cellRenderer: cellTemplate,
    //         cellRendererParams: {
    //             setSelectedPTWDetail,
    //         },
    //     },
    // ]);




    const [searchText, setSearchText] = useState("");
    const [gridApi, setGridApi] = useState();
    const [isLoadingPTWList, setisLoadingPTWList] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [searchDivision, setSearchDivision] = useState([]);
    const [searchEquipment, setSearchEquipment] = useState([]);
    const [searchPtw, setsearchPtw] = useState([]);
    const [isolation, setisolation] = useState([]);
    const [line, setline] = useState([]);
    const [formunitValue, setformunitValue] = useState([]);
    const frequencyOptions = ["Unit-1", "Unit-2", "Unit-3", "Unit-4", "Unit-5", "Unit-6", "Unit-7", "Unit-8"];
    const [selectedFromDate, setselectedFromDate] = useState([]);
    const [selectedToDate, setselectedToDate] = useState([]);



    // useEffect(() => {
    //     // Fetch ProjectID and DivisionID from sessionStorage
    //     const selectedProjectData = getSessionStorage("selctedProject");
    //     console.log("selectedProjectData", selectedProjectData);
    //     setisLoadingPTWList(true);
    //     // Make the initial API call to get Employee IDs
    //     axios
    //         .get(`${project.projectList.GetEmployeeIDDetails}?ProjectID=${selectedProjectData.PROJECTID}&DivisionID=${selectedProjectData.DIVISIONIDs}`)
    //         .then((response) => {
    //             // Assuming the response contains employeeIDs
    //             console.log("response", response)
    //             const data = response.data.responseDynamic;
    //             const employeeIds = data.map((element) => element.employeeid);
    //             console.log("Employee IDs", employeeIds);
    //             setEmployeeID(employeeIds);
    //         })
    //         .catch((error) => {
    //             // Handle errors
    //             console.error("Error fetching data:", error);
    //         }).finally(() => {
    //             // Set loader to false after the API call is completed
    //             setisLoadingPTWList(false);
    //         });
    // }, []); 

    const fetchPTWDataForEmployee = () => {
        setisLoadingPTWList(true);
        axios
            .get(`${project.projectList.GetPTWDetails}?employeeId=${user.UserName}`)
            .then((response) => {
                const ptwData = response.data.responseDynamic;
                setData(ptwData);
                console.log("ptwData", ptwData)
            })
            .catch((error) => {
                // Handle errors
                console.error(`Error fetching data for Employee ID:`, error);
            })
            .finally(() => {

                setisLoadingPTWList(false);
            });
    };

    useEffect(() => {
        let isMounted = true;

        if (isMounted) {
            fetchPTWDataForEmployee();
        }

        return () => {
            isMounted = false;
        };
    }, []);


    const exportToCSV = () => {
        if (data && data.length !== 0) {
            const headers = Object.keys(data[0]);

            const csvContent = "data:text/csv;charset=utf-8," +
                headers.join(",") + "\n" +
                data.map(row => headers.map(header => row[header]).join(",")).join("\n");
            const encodedUri = encodeURI(csvContent);
            const link = document.createElement("a");
            link.setAttribute("href", encodedUri);
            link.setAttribute("download", "PTWList.csv");
            document.body.appendChild(link);
            link.click();
        }
        else {
            alert('No Data to Export!')
        }
    };
    const fetchData = () => {
        setData([]);
        employeeID.forEach((id) => {
            fetchPTWDataForEmployee(id);
        });
    };
    const onGridReady = (params) => {
        setGridApi(params.api);
    };
    const onChangeSearchText = (val) => {
        setSearchText(val);
        gridApi.setQuickFilter(val);
        gridApi.refreshCells();
    };

    const cleareSerchText = (data) => {
        if (data) {
            if (searchText && searchText.toLowerCase().includes("#")) {
                onChangeSearchText("");
            }
        }
    };

    const handleSearchInputChange = (e, field) => {
        const newValue = e.target.value;

        switch (field) {
            case "DIVISION":
                setSearchDivision(newValue);
                onChangeSearchText(newValue)
                break;
            case "EQUIPMENTNUMBER":
                setSearchEquipment(newValue);
                onChangeSearchText(newValue)
                break;
            case "PTWSTATUS":
                setsearchPtw(newValue);
                onChangeSearchText(newValue)
                break;
            case "LINE":
                setline(newValue);
                onChangeSearchText(newValue)
                break;
            case "FROMDATE":
                setselectedFromDate(newValue);
                onChangeSearchText(newValue)
                break;
            case "TODATE":
                setselectedToDate(newValue);
                onChangeSearchText(newValue)
                break;
            case "ISOLATION":
                setisolation(newValue);
                onChangeSearchText(newValue)
                break;
            default:
                break;
        }
    };

    const getAssetListingData = () => {
        const formData = {
            main: {
                employeeId: employeeID
            },
        };
        console.log("formData", formData)
        const request = {
            setDatalist: setData,
            setLoad: setisLoadingPTWList,
            requestData: formData,
            apiPath: project.projectList.GetPTWDetails,
            setAlert: setAlertMessage,
            fun: cleareSerchText,
        };
        GetApiData(request);
    };

    const closePTWDetailsModal = () => {
        setPTWModalOpen(false)
    }

    const handleIsolations = () => {
        setIsolationsModal(true)
    }

    const closeIsolationsModal = () => {
        setIsolationsModal(false)

        setIsChecked([]);
        setRemarks([]);
        setAdditionalFields([]);
        setUremarks([]);
    }

    const handleRemoveField = (index) => {
        setAdditionalFields((prevFields) => prevFields.filter((_, i) => i !== index));
        setUremarks((prevRemarks) => prevRemarks.filter((_, i) => i !== index));
    };

    const [operationRemarks, setOperationRemarks] = useState(Array.from({ length: isolationInputs.length }, () => ''))

    const handleRemoveOperationField = (index) => {
        setAdditionalOperationFields((prevFields) => prevFields.filter((_, i) => i !== index));
        setOperationRemarks((prevRemarks) => prevRemarks.filter((_, i) => i !== index));
    }




    const [ptwActionButtons, setPtwActionsButtons] = useState(false)

    const handleAcknowledge = () => {
        const formData = {
            ptw_ID: `PTW${getPTWDataByDefectIdPTWId.ptW_ID}`,
            userID: parseInt(user.UserName),
            divisionID: user.PROJECTDIVISIONID
        };
        axios.post(`${project.projectList.PTW_ACKNOWLWDGE_FORWEB}`, formData).then((response) => {
            console.log("response", response)
            if (response.data.responseCode === "Yes") {
                alert("PTW Acknowleged Successfully!");
                setPtwActionsButtons(true)
                setPTWModalOpen(false)
                fetchPTWDataForEmployee()
                fetchPTWDataForEmployeeForWeb()
            }
            else {
                alert("Failed to Submit, please try again")
            }
        })
    }



    const handleCheckboxChange = (index) => {
        const newCheckedState = [...isChecked];
        newCheckedState[index] = !newCheckedState[index];
        setIsChecked(newCheckedState);
    };

    const handleRemarksChange = (e, index) => {
        const newRemarks = [...remarks];
        newRemarks[index] = e.target.value;
        setRemarks(newRemarks);
    };


    const handleEnterRemarksChange = (e, index) => {
        const newRemarks = [...Uremarks];
        newRemarks[index] = e.target.value;
        setUremarks(newRemarks);
    };

    const handleOperationRemarksChange = (e, index) => {
        const newRemarks = [...operationRemarks];
        newRemarks[index] = e.target.value;
        setOperationRemarks(newRemarks);
    }

    // const handleIsolationSave = () => {
    //     const data = [];
    //     for (let i = 0; i < isolationInputs.length; i++) {
    //         var numericPart = getPTWDataByDefectIdPTWId.ptW_ID.match(/\d+/);
    //         const isolationData = {
    //             ptwid: parseInt(numericPart[0]),
    //             defectidd: getPTWDataByDefectIdPTWId.defectid,
    //             ehI_VALUEID: 1,
    //             isChecked: isChecked[i] ? 1 : 0,
    //             remarks: remarks[i]
    //         };
    //         data.push(isolationData);
    //         if (!isChecked[i] && !remarks[i]) {
    //             alert(`Please enter remarks for Isolation ${i + 1}`);
    //             return;
    //         }
    //     }
    //     console.log("IsolationData", data);

    //     axios.post(`${project.projectList.Update_EHI_FORWEB}`, data).then((response) => {
    //         if (response.data.responseCode === "Yes") {
    //             alert("Isolation Updated Successfully")
    //         }
    //         else {
    //             alert("Failed to submit, please try again")
    //         }
    //     })
    // };




    // const handleIsolationSave = () => {
    //     const data = isolationData.map((isolation, index) => {
    //         const numericPart = getPTWDataByDefectIdPTWId.ptW_ID.match(/\d+/);
    //         if (!isChecked[index] && !remarks[index]) {
    //             alert(`Please enter remarks for Isolation ${index + 1}`);
    //             return;
    //         }
    //         return {
    //             ptwid: parseInt(numericPart[0]),
    //             defectidd: getPTWDataByDefectIdPTWId.defectid,
    //             ehI_VALUEID: 1,
    //             status: isChecked[index] ? 1 : 0,
    //             remarks: remarks[index],
    //         };

    //     });
    //     console.log("IsolationData", data);

    //     axios.post(`${project.projectList.Update_EHI_FORWEB}`, data)
    //         .then((response) => {
    //             if (response.data.responseCode === "Yes") {
    //                 alert("Isolation Updated Successfully");
    //             } else {
    //                 alert("Failed to submit, please try again");
    //             }
    //         })
    //         .catch(error => {
    //             console.error('Error:', error);
    //             alert("Failed to submit, please try again");
    //         });


    //     const isolationsdata = [];
    //     for (let i = 0; i < isolationInputs.length; i++) {
    //         var numericPart = getPTWDataByDefectIdPTWId.ptW_ID.match(/\d+/);
    //         const isolationData = {
    //             id: 0,
    //             defecT_ID: getPTWDataByDefectIdPTWId.defectid,
    //             ptW_ID: parseInt(numericPart[0]),
    //             ptW_EHI_VALUES_ID: [remarks[i]],
    //             ehI_VALUES: 1,

    //         };

    //         isolationsdata.push(isolationData);
    //         if (!isChecked[i] && !remarks[i]) {
    //             alert(`Please enter remarks for Isolation ${i + 1}`);
    //             return;
    //         }
    //     }
    //     console.log("IsolationData", isolationsdata);

    //     axios.post(`${project.projectList.INSERT_PTW_EHI_RECORDS}`, isolationsdata).then((response) => {
    //         if (response.data.responseCode === "Yes") {
    //             alert("Isolation Updated Successfully")
    //         }
    //         else {
    //             alert("Failed to submit, please try again")
    //         }
    //     })
    // };


    // const handleIsolationSave = () => {
    //     const data = isolationData.map((isolation, index) => {
    //         const numericPart = getPTWDataByDefectIdPTWId.ptW_ID.match(/\d+/);
    //         if (!isChecked[index] && !remarks[index]) {
    //             alert(`Please enter remarks for Isolation ${index + 1}`);
    //             return null; // Return null if remarks are missing
    //         }
    //         return {
    //             ptwid: parseInt(numericPart[0]),
    //             defectidd: getPTWDataByDefectIdPTWId.defectid,
    //             ehI_VALUEID: 1,
    //             status: isChecked[index] ? 1 : 0,
    //             remarks: remarks[index],
    //         };
    //     }).filter(Boolean); // Filter out null values

    //     console.log("IsolationData", data);

    //     axios.post(`${project.projectList.Update_EHI_FORWEB}`, data)
    //         .then((response) => {
    //             if (response.data.responseCode === "Yes") {
    //                 alert("Isolation Updated Successfully");
    //                 setIsolationsModal(false)
    //                 setPTWModalOpen(false)
    //                 fetchPTWDataForEmployee()
    //             } else {
    //                 alert("Failed to submit, please try again");
    //             }
    //         })
    //         .catch(error => {
    //             console.error('Error:', error);
    //             alert("Failed to submit, please try again");
    //         });

    //     const promises = additionalFields.map((input, i) => {
    //         const numericPart = getPTWDataByDefectIdPTWId.ptW_ID.match(/\d+/);
    //         if (!Uremarks[i]) {
    //             alert("Please enter Isolation Data");
    //             return;
    //         }
    //         return axios.post(`${project.projectList.INSERT_PTW_EHI_RECORDS}`, {
    //             id: 0,
    //             defecT_ID: getPTWDataByDefectIdPTWId.defectid,
    //             ptW_ID: parseInt(numericPart[0]),
    //             ptW_EHI_VALUES_ID: [Uremarks[i]],
    //             ehI_VALUES: 1,
    //         });
    //     });

    //     Promise.all(promises)
    //         .then(responses => {
    //             responses.forEach(response => {
    //                 if (response.data.responseCode === "1") {
    //                     alert("Newly Added Isolation Inserted Successfully");
    //                     setIsolationsModal(false)
    //                     setPTWModalOpen(false)
    //                     fetchPTWDataForEmployee()
    //                 } else {
    //                     alert("Failed to submit, please try again");
    //                 }
    //             });
    //         })
    //         .catch(error => {
    //             console.error('Error:', error);
    //             alert("Failed to submit, please try again");
    //         });
    // };


    const handleIsolationSave = () => {
        // Check if any required conditions are not satisfied
        if (isolationData.some((isolation, index) => !isChecked[index] && !remarks[index])) {
            alert("Please enter remarks for all isolations.");
            return;
        }
        if (additionalFields.some((input, i) => !Uremarks[i])) {
            alert("Please enter isolation data for all additional fields.");
            return;
        }

        // Prepare data for the first API call
        const isolationDataToSend = isolationData.map((isolation, index) => {
            const numericPart = getPTWDataByDefectIdPTWId.ptW_ID.match(/\d+/);
            return {
                ptwid: parseInt(numericPart[0]),
                defectidd: getPTWDataByDefectIdPTWId.defectid,
                ehI_VALUEID: 1,
                status: isChecked[index] ? 1 : 0,
                remarks: remarks[index],
            };
        });

        // First API call
        axios.post(`${project.projectList.Update_EHI_FORWEB}`, isolationDataToSend)
            .then((response) => {
                if (response.data.responseCode === "Yes") {
                    alert("Isolation Updated Successfully");
                    setIsolationsModal(false);
                    setPTWModalOpen(false);
                    fetchPTWDataForEmployee();
                    fetchPTWDataForEmployeeForWeb()
                } else {
                    alert("Failed to submit, please try again");
                }
            })
            .catch(error => {
                console.error('Error:', error);
                alert("Failed to submit, please try again");
            });

        // Prepare promises for the second API call
        const promises = additionalFields.map((input, i) => {
            const numericPart = getPTWDataByDefectIdPTWId.ptW_ID.match(/\d+/);
            return axios.post(`${project.projectList.INSERT_PTW_EHI_RECORDS}`, {
                id: 0,
                defecT_ID: getPTWDataByDefectIdPTWId.defectid,
                ptW_ID: parseInt(numericPart[0]),
                ptW_EHI_VALUES_ID: [Uremarks[i]],
                ehI_VALUES: 1,
            });
        });

        // Second API call
        Promise.all(promises)
            .then(responses => {
                responses.forEach(response => {
                    if (response.data.responseCode === "1") {
                        alert("Newly Added Isolation Inserted Successfully");
                        setIsolationsModal(false);
                        setPTWModalOpen(false);
                        fetchPTWDataForEmployee();
                        fetchPTWDataForEmployeeForWeb()
                    } else {
                        alert("Failed to submit, please try again");
                    }
                });
            })
            .catch(error => {
                console.error('Error:', error);
                alert("Failed to submit, please try again");
            });
    };


    const [hazardoesModal, setHazardoesModal] = useState(false);
    const [equipmentModal, setEquipmentModal] = useState(false);
    const [issue, setHandleIssue] = useState(false);
    const [issueRemarks, setissueRemarks] = useState('')
    const [rcaStatus, setRcaStatus] = useState([])
    const rcaData = [{ "option": "Yes", "value": 1 }, { "option": "No", "value": 0 }]


    const [reject, setHandleReject] = useState(false);
    const [rejectRemarks, setrejectRemarks] = useState('')
    const [rejectrcaStatus, setRejectRcaStatus] = useState([])
    const rejectrcaData = [{ "option": "Yes", "value": 1 }, { "option": "No", "value": 0 }]

    const handleHazardos = () => {
        setHazardoesModal(true)
    }

    const closeHazardoesModal = () => {
        setHazardoesModal(false)
    }

    const handleEquipment = () => {
        setEquipmentModal(true)
    }

    const [equipmentView, setEquipmentView] = useState([])
    const getEHIDeatilsforEquipment = (ptw) => {

        axios.get(`${project.projectList.GetEHI_DETAILS_BY_EHI_PTW_FORWEB}?EHIID=2&PTWID=${ptw}`).then((response) => {
            console.log("EHI equipment response", response)
            const data = response.data.responseDynamic
            const jsonData = JSON.parse(data)
            console.log("equipment jsonData", jsonData)
            setEquipmentView(jsonData)
        })
    }


    const closeEquipmentModal = () => {
        setEquipmentModal(false)
    }

    const handleIssue = () => {
        setHandleIssue(true)
    }
    const closeIssueModal = () => {
        setHandleIssue(false)
    }

    const handleReject = () => {
        setHandleReject(true)
    }

    const closeRejectModal = () => {
        setHandleReject(false)
    }

    const handleIssueData = (e, field) => {
        switch (field) {
            case "Remarks":
                setissueRemarks(e.target.value);
                break;
            case "RCASTATUS":
                setRcaStatus(e.value);
                break;
            default:
                break;

        }
    }

    const handleRejectData = (e, field) => {
        switch (field) {
            case "Remarks":
                setrejectRemarks(e.target.value);
                break;
            case "RCASTATUS":
                setRejectRcaStatus(e.value);
                break;
            default:
                break;

        }
    }

    const saveIssuePTW = (e) => {
        e.preventDefault()
        if (!issueRemarks) {
            alert("Please Enter Remarks");
            return;
        }
        const formData = {
            ptwID: `PTW${getPTWDataByDefectIdPTWId.ptW_ID}`,
            issuingAuthority: getPTWDataByDefectIdPTWId.issuingAuthority,
            attendingDivision: getPTWDataByDefectIdPTWId.issuingDivision,
            remarks: issueRemarks,
            employeeId: parseInt(user.UserName),
            defecT_IDD: getPTWDataByDefectIdPTWId.defectid,
            attenD_DIV: 0,
            diV_STATUS: 1

        }
        axios.post(`${project.projectList.IssuePTW}`, formData).then((response) => {
            console.log("response", response);
            if (response.data.responseCode === "Yes") {
                alert("PTW Issued Successfullly!")
                setHandleIssue(false)
                setPTWModalOpen(false)
                fetchPTWDataForEmployee()
                fetchPTWDataForEmployeeForWeb()
            }
            else {
                alert("Failed to submit please try again")
            }
        })
    }

    const saveRejectPTW = (event) => {
        event.preventDefault();
        if (!rejectRemarks) {
            alert("Please enter Remarks");
            return;
        }



        const formData = {
            ptwID: getPTWDataByDefectIdPTWId.ptW_ID,
            issuingAuthority: getPTWDataByDefectIdPTWId.issuingAuthority,
            attendingDivision: getPTWDataByDefectIdPTWId.issuingDivision,
            remarks: rejectRemarks,
            employeeId: parseInt(user.UserName),
            defecT_IDD: getPTWDataByDefectIdPTWId.defectid,
            attenD_DIV: 0,
            diV_STATUS: 0

        }
        axios.post(`${project.projectList.RejectPTW}`, formData).then((response) => {
            console.log("response", response);
            if (response.data.responseCode === "Yes") {
                alert("PTW Rejected Successfullly!")
                setHandleReject(false)
                setPTWModalOpen(false)
                fetchPTWDataForEmployee()
                fetchPTWDataForEmployeeForWeb()
            }
            else {
                alert("Failed to submit please try again")
            }
        })

    }

    const [hazardoesData, setHazardosData] = useState([]);

    const getEHIDetailsforHazardoes = (ptw) => {

        axios.get(`${project.projectList.GetEHI_DETAILS_BY_EHI_PTW_FORWEB}?EHIID=3&PTWID=${ptw}`).then((response) => {
            console.log("EHI Hazardoes response", response)
            const hazardos = response.data.responseDynamic
            const haxardosData = JSON.parse(hazardos);
            console.log("haxardosData", haxardosData)
            setHazardosData(haxardosData)
        })


    }

    const [isolationData, setIsolationData] = useState([])

    const getEHIDetailsforIsolation = (ptw) => {
        axios.get(`${project.projectList.GetEHI_DETAILS_BY_EHI_PTW_FORWEB}?EHIID=1&PTWID=${ptw}`).then((response) => {
            console.log("EHI Isolation response", response)
            const hazardos = response.data.responseDynamic
            const haxardosData = JSON.parse(hazardos);
            console.log("haxardosData", haxardosData)
            setIsolationData(haxardosData)
        })
    }


    const [equipmentDetail, setequipmentDetail] = useState([])
    const [acceptModal, setAcceptModal] = useState(false)
    const [rejectIsolation, setRejectIsolations] = useState(false)
    const [ptwTransfer, setPtwTransfer] = useState(false)


    const closeAcceptModal = () => {
        setAcceptModal(false)
    }

    const rejectIsolations = () => {
        setRejectIsolations(true)
    }

    const handlePtwTranfer = () => {
        setPtwTransfer(true)
    }

    const closePTWTransferModal = () => {
        setPtwTransfer(false)
    }

    const [teamsListMembersData, setTeamsListMembersData] = useState([]);
    const [isLoadingTeamsMemberData, setIsLoadingTeamsMemberData] = useState(false);


    const getTeamsDetailListing = () => {


        setIsLoadingTeamsMemberData(true);
        const formData = {
            main: {
                divisionid: user.DIVISION_ID.toString(),
                profileid: user && user.UserProfileID ? user.UserProfileID : null,
                projectid: user.PROJECT_ID.toString(),
            },
        };

        const request = {
            setDatalist: setTeamsListMembersData,
            setLoad: setIsLoadingTeamsMemberData,
            requestData: formData,
            apiPath: project.projectList.GetKPCLTeam,
            setAlert: setAlertMessage,
            fun: null,
        };
        GetApiData(request);
    };

    useEffect(() => {
        getTeamsDetailListing()
    }, [])

    const [selectedPtwTranferEmployee, setselectedPtwTranferEmployee] = useState([])
    const [ptwtransferRemarks, setptwtransferRemarks] = useState('')
    const [operation, setOperation] = useState(false)
    const [opeartionDivision, setOperationDivision] = useState([])
    const [attendingDivision, setAttendingDivisionName] = useState({});

    const handlePTWTranferEmployee = (e, field) => {
        // switch (field) {
        //     case "EMPLOYEE":
        //         const newValue = e ? e : null;
        //         setselectedPtwTranferEmployee(newValue);
        //         break;
        //     case "REMARKS":
        //         setptwtransferRemarks(e.target.value);
        //         break;
        //     default:
        //         break;

        // }
        setselectedPtwTranferEmployee(e)
    }

    const handlePTWTranferRemarks = (e, field) => {
        setptwtransferRemarks(e.target.value);
    }

    const savePTWtranfer = () => {
        if (!selectedPtwTranferEmployee || !ptwtransferRemarks) {
            alert("Please Select Employee and enter remarks");
            return;
        }
        const formData = {
            froM_EMP: parseInt(getPTWDataByDefectIdPTWId.issuinG_EMPLOYEEID),
            tO_EMP: selectedPtwTranferEmployee,
            ptW_ID: getPTWDataByDefectIdPTWId.ptW_NUMBER,
            defecT_ID: getPTWDataByDefectIdPTWId.defectid,
            remarks: ptwtransferRemarks,
            divisionid: user.PROJECTDIVISIONID
        }
        console.log("formData", formData)
        axios.post(`${project.projectList.PTW_TRANSFER}`, formData).then((response) => {
            console.log("response", response)
            if (response.data.responseCode === "Yes") {
                alert("PTW Transferred Successfully !")
                setPtwTransfer(false)
                setPTWModalOpen(false)
                fetchPTWDataForEmployee()
                fetchPTWDataForEmployeeForWeb()
            } else {
                alert("Failed to Submit, please try again")
            }
        })
    }
    const openOperationModal = () => {
        setOperation(true)
        getIsolationNormalizationData()
    }

    const closeOperationModal = () => {
        setOperation(false)

    }

    const handleOperationDivision = (e, field) => {
        if (e !== null) {
            setOperationDivision(e)
        }

    }

    const attendingDivisionDetails = () => {
        axios.get(`${project.projectList.GetAttendingDivInfo}?projectId=${user.PROJECT_ID}`).then((response) => {
            console.log("attending division name", response)
            const attendingDivisionName = response.data.responseDynamic;
            setAttendingDivisionName(attendingDivisionName)
        })
    }

    useEffect(() => {
        attendingDivisionDetails()
    }, [])

    const savePTWOperation = () => {
        // if (opeartionDivision.length === 0) {
        //     alert("Please select Division Name");
        //     return;
        // }
        // const formData = {
        //     divisionName: opeartionDivision
        // }
        // console.log("formData", formData)
    }

    const updateFormState = (e, field) => {
        if (field && e !== null) {
            const newValue = e;
            console.log("e", e)
            setformunitValue(newValue);
            onChangeSearchText(newValue.value)
        }
    };

    const [termsChecked, setTermsChecked] = useState(false)
    const [rejecttermsChecked, setrejectTermsChecked] = useState(false);


    const handleAcceptPTW = () => {
        setAcceptModal(true)
    }

    const [defectData, setDefectData] = useState({})
    const getDefectDetails = (defect) => {
        const formData = {
            defectId: defect
        }
        axios.post(`${project.projectList.GetDefectDetailsbyDefectId}`, formData).then((response) => {
            console.log("response", response);
            const data = response.data.responseDynamic;
            setDefectData(data)
        })
    }

    const [acceptButtons, setacceptButtons] = useState(false)
    const [showAcceptreject, setShowAcceptreject] = useState(true)

    const acceptPtwRequest = (e) => {
        e.preventDefault();
        const formData = {
            ptW_ID: getPTWDataByDefectIdPTWId.ptW_NUMBER,
            defecT_ID: getPTWDataByDefectIdPTWId.defectid,
            useR_ID: user.UserName,
            diV_ID: user.PROJECTDIVISIONID,
            accpeT_REJECT_STATUS: 1,
            remarks: acceptRemarks
        }
        console.log("formData", formData)
        axios.post(`${project.projectList.Accept_Reject_PTW_FORWEB}`, formData).then((response) => {
            console.log("response", response);
            if (response.data.responseCode === "Yes") {
                alert("PTW Received Successfully!")
                setacceptButtons(true)
                setShowAcceptreject(false)
                setAcceptModal(false)
                setPTWModalOpen(false)
                fetchPTWDataForEmployee()
                fetchPTWDataForEmployeeForWeb()
            }
            else {
                alert("Failed to Submit, please try again !")
            }
        })
    }

    const [acceptRemarks, setAcceptRemarks] = useState('')
    const handleAcceptData = (e) => {
        setAcceptRemarks(e.target.value)
    }

    const [rejectAcceptModal, setrejectAcceptModal] = useState(false)
    const handleAcceptRejectPTW = () => {
        setrejectAcceptModal(true)
    }
    const closeRejectAcceptModal = () => {
        setrejectAcceptModal(false)
    }
    const [rejectPTWtermsChecked, setRejectTermsChecked] = useState(false)
    const [returnModal, setReturnmodal] = useState(false);
    const closeReturnModal = () => {
        setReturnmodal(false)
    }
    const openReturnModal = () => {
        setReturnmodal(true)
    }

    const [returnRemarks, setreturnRemarks] = useState('')
    const handleReturnData = (e) => {
        setreturnRemarks(e.target.value)
    }

    const returnRequest = (e) => {
        e.preventDefault()
        if (!returnRemarks) {
            alert("Please Enter Remarks");
            return;
        }
        const formData = {
            ptwID: `PTW${getPTWDataByDefectIdPTWId.ptW_ID}`,
            issuingAuthority: 0,
            attendingDivision: 0,
            remarks: returnRemarks,
            employeeId: selectedEmployee,
            defecT_IDD: getPTWDataByDefectIdPTWId.defectid,
            attenD_DIV: 0,
            diV_STATUS: 1,
            returneD_BY_DIVID: parseInt(selectedDivision)
        }
        console.log("formdata", formData)
        axios.post(`${project.projectList.ReturnPTW}`, formData).then((response) => {
            console.log("response", response);
            if (response.data.responseCode === "Yes") {
                alert("PTW Returned Successfully !")
                setReturnmodal(false)
                setPTWModalOpen(false)
                fetchPTWDataForEmployee()
                fetchPTWDataForEmployeeForWeb()
            }
            else {
                alert("Failed to submit,Please try again !")
            }
        })
    }

    const rejectPtwRequest = (e) => {
        e.preventDefault()
        const formData = {
            ptW_ID: getPTWDataByDefectIdPTWId.ptW_NUMBER,
            defecT_ID: getPTWDataByDefectIdPTWId.defectid,
            useR_ID: user.UserName,
            diV_ID: user.PROJECTDIVISIONID,
            accpeT_REJECT_STATUS: 0,
            remarks: acceptRemarks
        }

        console.log("formData", formData)
        axios.post(`${project.projectList.Accept_Reject_PTW_FORWEB}`, formData).then((response) => {
            console.log("response", response);
            if (response.data.responseCode === "Yes") {
                alert("PTW Rejected Successfully!")
                setShowAcceptreject(false)
                setAcceptModal(false)
                setPTWModalOpen(false)
                fetchPTWDataForEmployee()
                fetchPTWDataForEmployeeForWeb()
                setrejectAcceptModal(false)
            }
            else {
                alert("Failed to Submit, please try again !")
            }

        })
    }
    const handleOperationSave = () => {
        if (!declare || isChecked.some(item => item) || !request || !testOperationRemarks) {
            alert("Please Check the required Fields !")
            return;
        }
        const formData = {
            defectid: getPTWDataByDefectIdPTWId.defectid,
            ptwid: getPTWDataByDefectIdPTWId.ptW_NUMBER,
            isO_REMARKS: testOperationRemarks,
            isO_CARRIED: request ? "Y" : "N",
            isO_DECLARE: declare ? "Y" : "N",
            isO_NORMALIZED_REQ: isolationData
                .filter((data, index) => isOperationChecked[index])
                .map(data => data.EHIVALUE),
            isO_NORMALIZED: "N",
            isO_PERMITTEDD: "N",
            requesT_BY_EMPID: parseInt(user.UserName),
            requesT_TO_EMPID: selectedTestOperationEmployee,
            requesT_TO_DIVID: parseInt(selectedTestOperationDivision)
        };

        axios.post(`${project.projectList.PTW_ISO_TEST_OPERATION_FORWEB}`, formData).then((response) => {
            console.log("response", response)
            if (response.data.responseCode === "Yes") {
                alert("Test Operation Details Submitted Successfully!");
                setoperationNormaliseAfterTransfer(false)
                setRequest('')
                setDeclare('')
                settestOperationRemarks('')
                setoperationNormalise(false)
                setShowSubmitButton(false)
            }
            else {
                alert("Failed to Submit, Please Submit again !")
            }
        })
        console.log("handleOperationSave", formData);
    };



    const handleEnterOperationRemarksChange = (e, index) => {
        const newRemarks = [...operationRemarks];
        newRemarks[index] = e.target.value;
        setOperationRemarks(newRemarks);
    }

    const [isOperationChecked, setIsOperationChecked] = useState(Array.from({ length: isolationInputs.length }, () => false))
    const handleCheckboxOperationChange = (index) => {
        const newCheckedState = [...isOperationChecked];
        newCheckedState[index] = !newCheckedState[index];
        setIsOperationChecked(newCheckedState);
    };

    const [isolationNormalisedInputs, setIsolationNormalisedInputs] = useState([''])
    const [isOperationNormalisedChecked, setIsOperationNormalisedChecked] = useState(Array.from({ length: isolationNormalisedInputs.length }, () => false))
    const handleCheckboxOperationNormalisedChange = (index) => {
        const newCheckedState = [...isOperationNormalisedChecked];
        newCheckedState[index] = !newCheckedState[index];
        setIsOperationNormalisedChecked(newCheckedState);
    };

    const [isolationNormalisedRestorationInputs, setIsolationNormalisedRestorationInputs] = useState([''])
    const [isOperationRestorationChecked, setisOperationRestorationChecked] = useState(Array.from({ length: isolationNormalisedInputs.length }, () => false))

    const handleCheckboxOperationNormalisedRestorationChange = (index) => {
        const newCheckedState = [...isOperationRestorationChecked];
        newCheckedState[index] = !newCheckedState[index];
        setisOperationRestorationChecked(newCheckedState);
    };


    const [operationNormalise, setoperationNormalise] = useState(false)
    const closeOperationNormaliseModal = () => {
        setoperationNormalise(false)
        setIsOperationChecked(new Array(isolationData.length).fill(false));
        console.log("operationNormalise", operationNormalise)
    }
    const openOperationAdditionModal = () => {
        setoperationNormalise(true)
        getTestOperationDetails()
    }

    const [operationNormaliseAfterTransfer, setoperationNormaliseAfterTransfer] = useState(false)
    const closeOperationNormaliseAfterTransferModal = () => {
        setoperationNormaliseAfterTransfer(false)
        setIsOperationChecked(new Array(isolationData.length).fill(false));
    }
    const openOperationAdditionAfterTransferModal = () => {
        setoperationNormaliseAfterTransfer(true)
        getTestOperationDetails()
    }

    const [testOperationPermitted, settestOperationPermitted] = useState(false)
    const [testOperationNotPermitted, settestOperationNotPermitted] = useState(false)
    const [request, setRequest] = useState(false)
    const [declare, setDeclare] = useState(false)

    const formatDates = (dateString) => {
        const date = new Date(dateString);
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    };


    const [HazatdoesListViewColumDef] = useState([
        { headerName: "Sr. No.", field: "#", width: 80, valueGetter: "node.rowIndex + 1" },
        { field: 'EHIVALUE', headerName: 'Hazardos Name', width: 180 },
        {
            field: 'ENTRY_DATE',
            headerName: 'Date',
            width: 180,
            valueGetter: (params) => formatDates(params.data.ENTRY_DATE)
        },
    ]);

    const [EquipmentListViewColumDef] = useState([
        { headerName: "Sr. No.", field: "#", width: 80, valueGetter: "node.rowIndex + 1" },
        { field: 'EHIVALUE', headerName: 'Equipment Name', width: 180 },
        { field: 'ENTRY_DATE', headerName: 'Date', width: 180, valueGetter: (params) => formatDates(params.data.ENTRY_DATE) },

    ]);

    const [IsolationListViewColumDef] = useState([
        { headerName: "Sr. No.", field: "#", width: 80, valueGetter: "node.rowIndex + 1" },
        { field: 'EHIVALUE', headerName: 'Isolation Name', width: 180 },
        { field: 'ENTRY_DATE', headerName: 'Date', width: 180, valueGetter: (params) => formatDates(params.data.ENTRY_DATE) },


    ]);

    const [returnPTWtermsChecked, setreturnPTWtermsChecked] = useState(false)

    function calculateDuration(fromDate, toDate) {
        console.log("fromdate", fromDate)
        console.log("toDate", toDate)
        // Calculate the difference in milliseconds
        // const differenceMs = toDateObj.getTime() - fromDateObj.getTime();

        // // Convert milliseconds to days
        // const differenceDays = differenceMs / (1000 * 60 * 60 * 24);

        // // Round the difference to the nearest integer
        // return Math.round(differenceDays);
    }



    const [testOperationRemarks, settestOperationRemarks] = useState('')
    const [cancelModal, setCancelModal] = useState(false)

    const openCancelModal = () => {
        setCancelModal(true);
    }

    const closeCancelModal = () => {
        setCancelModal(false);
    }

    const [showTransferButtons, setShowTransferButtons] = useState(false);
    const handleApproveTransfer = () => {
        setShowTransferButtons(true)
        const formData = {
            defectid: getPTWDataByDefectIdPTWId.defectid,
            ptwid: getPTWDataByDefectIdPTWId.ptW_NUMBER,
            approvaL_STATUS: 1,
            approveD_FROM: user.UserName
        }
        axios.post(`${project.projectList.PTW_TRANSFER_APPROVAL_FORWEB}`, formData).then((response) => {
            console.log("approval response", response)
            if (response.data.responseCode === "Yes") {
                alert("Approved Transferred of PTW Successfully")
                setPTWModalOpen(false)
                fetchPTWDataForEmployee()
                fetchPTWDataForEmployeeForWeb()
            }
            else {
                alert("Failed to Submit, Please Try Again!")
            }
        })
    }

    const handleCancelTransfer = () => {
        const formData = {
            defectid: getPTWDataByDefectIdPTWId.defectid,
            ptwid: getPTWDataByDefectIdPTWId.ptW_NUMBER,
            approvaL_STATUS: 0,
            approveD_FROM: user.UserName
        }
        axios.post(`${project.projectList.PTW_TRANSFER_APPROVAL_FORWEB}`, formData).then((response) => {
            console.log("approval response", response)
            if (response.data.responseCode === "Yes") {
                alert("Cancelled Transfer of PTW Successfully")
                setPTWModalOpen(false)
                fetchPTWDataForEmployee()
                fetchPTWDataForEmployeeForWeb()
            }
            else {
                alert("Failed to Submit, Please Try Again!")
            }
        })
    }

    const handleTestOperationPermitted = (e) => {
        e.preventDefault();
        const formData =
        {
            defectid: getPTWDataByDefectIdPTWId.defectid,
            ptwid: getPTWDataByDefectIdPTWId.ptW_NUMBER,
            isO_NORMALIZED: isolationsTobeNormalised.filter((data, index) => isOperationNormalisedChecked[index])
                .map(data => data.EHI_DESCRIPTION)
        }
        axios.post(`${project.projectList.PTW_ISO_NORMALIZATION_OPERATION_FORWEB}`, formData).then((response) => {
            console.log("response", response)
            if (response.data.responseCode === "Yes") {
                alert("Test Operation Permitted Successfully")
            }
            else {
                alert("Failed to submit, please try again")
            }
        })
    }

    const handleTestOperationCompleted = (e) => {
        e.preventDefault();
        // const formData = {
        //     operationCompleted: testOperationPermitted === true ? 1 : 0
        // }
        // console.log("handleTestOperationCompleted", formData)
        const formData = {
            ptwid: getPTWDataByDefectIdPTWId.ptW_NUMBER,
            defectid: getPTWDataByDefectIdPTWId.defectid,
            ehivalueid: 1,
            workcompleted: 1,
            workpermited: 0
        }

        axios.post(`${project.projectList.PTW_EHI_WORKCOMPLETED_FORWEB}`, formData).then((response) => {
            console.log("response", response)
            if (response.data.responseCode === "Yes") {
                alert("Test Operation Completed Successfully")
            }
            else {
                alert("Failed to Submit, please try again")
            }
        })
    }
    const [permittedToWork, setpermittedToWork] = useState(false)
    const handlePermittedToWork = (e) => {
        e.preventDefault();
        const formData = {
            ptwid: getPTWDataByDefectIdPTWId.ptW_NUMBER,
            defectid: getPTWDataByDefectIdPTWId.defectid,
            ehivalue:
                isolationsTobeNormalised.filter((data, index) => isOperationRestorationChecked[index])
                    .map(data => data.EHI_DESCRIPTION)
        }
        axios.post(`${project.projectList.PTW_EHI_WORKPERMITED_FORWEB}`, formData).then((response) => {
            console.log("response", response);
            if (response.data.responseCode === "Yes") {
                alert("Permitted to work!")
            }
            else {
                alert("Failed to submit, please try again!")
            }
        })
    }


    const [cancelRemarks, setcancelRemarks] = useState('')
    const handleCancelPTW = () => {
        const formData = {
            ptwid: getPTWDataByDefectIdPTWId.ptW_NUMBER,
            defectid: getPTWDataByDefectIdPTWId.defectid,
            canceL_FROM_EMP: user.UserName,
            canceL_FROM_DIVID: user.PROJECTDIVISIONID,
            canceL_REMARKS: cancelRemarks

        }
        axios.post(`${project.projectList.PTW_CANCEL_FORWEB}`, formData).then((response) => {
            console.log("response", response)
            if (response.data.responseCode === "Yes") {
                alert("PTW Cancelled Successfully!")
                setCancelModal(false);
                setPTWModalOpen(false)
                fetchPTWDataForEmployee();
                fetchPTWDataForEmployeeForWeb()
            }
            else {
                alert("Failed to Submit, please try again!")
            }
        })
    }

    const [isolationsNormalised, setIsolationNormalised] = useState(false)
    const handleIsolationsNormalised = () => {
        setIsolationNormalised(true)
    }
    const closeIsolationsNormalisedModal = () => {
        setIsolationNormalised(false)
    }

    const [isolationsTobeNormalised, setIsolationsTobeNormalised] = useState([])
    const getIsolationNormalizationData = () => {
        axios.get(`${project.projectList.GET_NORMALIZATIONREQUEST_INFO_FORWEB}?DefectID=${getPTWDataByDefectIdPTWId.defectid}&PtwID=${getPTWDataByDefectIdPTWId.ptW_NUMBER}`).then((response) => {
            console.log("Isolation response", response);
            const isolations = response.data.responseDynamic;
            const data = JSON.parse(isolations)
            console.log("isolations Data", data.Table)
            setIsolationsTobeNormalised(data.Table)
        })
    }

    const [ptwEmployeeList, setPTWEmployeeList] = useState([])


    const ptwEmployeeBasedOnDivision = (selectedList) => {
        const formData = parseInt(selectedList)

        console.log("ptw formData", selectedList)
        axios.get(`${project.projectList.Get_EmpLst_By_ProjectID_DivisionID}?ProjectID=${user.PROJECT_ID}&DivisionID=${formData}`).then((response) => {
            console.log("PTW Division response", response);
            const data = response.data.responseDynamic
            setPTWEmployeeList(data)
        })
    }

    const [issuingDivision, setIssuingDivision] = useState([])

    const getIssuingDivisionDeatils = () => {

        axios.get(`${project.projectList.Get_All_Divisions_By_ProjectID}?ProjectID=${user.PROJECT_ID}&DivisionType=Y`).then(async (response) => {
            console.log("Response", response);
            if (response && response.status === 200) {
                const result = await response.data;
                if (result.responseCode.toString() === "1") {
                    const buff = Buffer.from(result.responseDynamic ? result.responseDynamic : "", "base64");
                    if (buff.length !== 0) {
                        const data = JSON.parse(pako.inflate(buff, { to: "string" }));
                        console.log("issuing data", data.Table)

                        setIssuingDivision(data.Table)
                        return { responseCode: 1, responseData: data, responseMessage: result.responseMessage };
                    }
                    return { responseCode: 1, responseData: [], responseMessage: result.responseMessage };
                }
                return { responseCode: Number(result.responseCode), responseData: null, responseMessage: result.responseMessage };
            }
            return { responseCode: 0, responseData: null, responseMessage: "" };
        })
            .catch((error) => {
                console.error("Error", error);
            });
    }

    useEffect(() => {
        getIssuingDivisionDeatils()

    }, [])

    const [selectedDivision, setSelectedDivision] = useState([])
    const [selectedEmployee, setSelectedEmployee] = useState([])
    const handlePTWInputChange = (e, field) => {
        switch (field) {
            case "DIVISION":
                setSelectedDivision(e.value)
                ptwEmployeeBasedOnDivision(e.value)
                break;
            case "EMPLOYEE":
                setSelectedEmployee(e.value)
                break;
            default:
                break;
        }
    }

    const [attendModal, setAttendModal] = useState(false)
    const openAttendModal = () => {
        setAttendModal(true)
    }

    const closeAttendDefectModal = () => {
        setAttendModal(false)
    }

    const [details, setDetails] = useState('')
    const [attendRestrictions, setAttendRestrictions] = useState('')


    const attendDefectRequest = (e) => {
        e.preventDefault();
        const formData = {
            defectID: getPTWDataByDefectIdPTWId.defectid,
            employeeID: parseInt(user.UserName),
            workDone_Details: details,
            restrictions: attendRestrictions
        }
        axios.post(`${project.projectList.WorkDoneDetails_ForWeb}`, formData).then((response) => {
            console.log("Response", response)
            if (response.data.responseCode === "1") {
                alert("Defect Attended Successfully!")
                setAttendModal(false)
                setPTWModalOpen(false)
                fetchPTWDataForEmployee();
                fetchPTWDataForEmployeeForWeb()

            }
            else {
                alert("Failed to Submit, please try again !")
            }
        })

    }

    const handleAcceptTransferPTW = () => {
        const formData = {
            defectID: getPTWDataByDefectIdPTWId.defectid,
            ptwID: getPTWDataByDefectIdPTWId.ptW_NUMBER,
            acceptedEmpID: parseInt(user.UserName)
        }
        axios.post(`${project.projectList.PTW_TRANSFER_ACCEPTANCE_FORWEB}`, formData).then((response) => {
            console.log("response", response)
            if (response.data.responseCode === "Yes") {
                alert("PTW Transfer Accepted Successfully !")
                setPTWModalOpen(false)
                fetchPTWDataForEmployee();
                fetchPTWDataForEmployeeForWeb()
            }
            else {
                alert("Failed to submit, please try again !")
                setPTWModalOpen(false)
                fetchPTWDataForEmployee();
                fetchPTWDataForEmployeeForWeb()
            }
        })
    }

    const handleRejectTransferPTW = () => {
        const formData = {
            defectID: getPTWDataByDefectIdPTWId.defectid,
            ptwID: getPTWDataByDefectIdPTWId.ptW_NUMBER,
            rejectByEmpID: parseInt(user.UserName)
        }
        axios.post(`${project.projectList.PTW_TRANSFER_REJECTED_FORWEB}`, formData).then((response) => {
            console.log("response", response)
            if (response.data.responseCode === "Yes") {
                alert("PTW Transfer Rejected Successfully!")
                setPTWModalOpen(false)
                fetchPTWDataForEmployee();
                fetchPTWDataForEmployeeForWeb()
            }
            else {
                alert("Failed to submit,please try again!")
                setPTWModalOpen(false)
                fetchPTWDataForEmployee();
                fetchPTWDataForEmployeeForWeb()
            }
        })
    }

    const handleAttendApproval = () => {
        const formData = {
            defectID: getPTWDataByDefectIdPTWId.defectid,
            approvedByEmpId: JSON.parse(user.UserName),
            approvalSentByEmpID: JSON.parse(getPTWDataByDefectIdPTWId.workdonE_EMPID),
        }
        axios.post(`${project.projectList.AttendDefectApproval_ForWeb}`, formData).then((response) => {
            console.log("response", response)
            if (response.data.responseCode === "1") {
                alert("Attend defect approved successfully !")
                setPTWModalOpen(false)
                fetchPTWDataForEmployee();
                fetchPTWDataForEmployeeForWeb()
            }
            else {
                alert("Failed to submit, please try again !")
            }
        })
    }

    const [testOperationData, setTestOperationData] = useState([])
    const getTestOperationDetails = () => {
        const formData = {
            defectID: getPTWDataByDefectIdPTWId.defectid,
            ptwID: getPTWDataByDefectIdPTWId.ptW_NUMBER,
            ehiValues: isolationData
                .filter((data, index) => data.ISO_NORMALIZE_REQUEST === "Y")
                .map(data => data.EHIVALUE)
        }
        axios.post(`${project.projectList.GET_PTW_ISO_VALUES_FORWEB}`, formData).then((response) => {
            console.log("Test Operation response", response)
            const data = response.data.responseDynamic
            const jsonData = JSON.parse(data)
            // console.log("testoperationData", jsonData.Table)
            setTestOperationData(jsonData)
            // const jsonData = JSON.parse(data.Table)
            // console.log("jsonData",jsonData)
        })
    }

    const [getPTWDataByDefectIdPTWId, setgetPTWDetailsByDefectIdPTWId] = useState([])
    const getPTWDetailsByDefectIdPTWId = (defect, ptw) => {
        axios.get(`${project.projectList.GET_PTW_DETAIL_BY_DEFECTID_PTWID_FORWEB}?DefectID=${defect}&PtwID=${ptw}`)
            .then((response) => {
                const data = response.data.responseDynamic;
                // Since data[0] is already an object, you can directly use it
                const jsonData = data[0];
                console.log("getPTWDetailsByDefectIdPTWId", jsonData);
                setgetPTWDetailsByDefectIdPTWId(jsonData);
            })
            .catch((error) => {
                console.error("Error fetching PTW details:", error);
                // Handle errors here
            });
    }


    const [ptwData, setPtwData] = useState([])

    const fetchPTWDataForEmployeeForWeb = () => {
        setisLoadingPTWList(true);
        axios
            .get(`${project.projectList.GET_PTW_DETAIL_BY_EMPID_FORWEB}?EmpID=${user.UserName}`)
            .then((response) => {
                const ptwData = response.data.responseDynamic;
                const jsonData = JSON.parse(ptwData)
                const data = jsonData.Table
                setPtwData(data);
                console.log("ptwEmployyeeData", data)
            })
            .catch((error) => {
                console.error(`Error fetching data for Employee ID:`, error);
            })
            .finally(() => {

                setisLoadingPTWList(false);
            });
    };

    useEffect(() => {
        let isMounted = true;

        if (isMounted) {
            fetchPTWDataForEmployeeForWeb();
        }

        return () => {
            isMounted = false;
        };
    }, []);

    const [ListViewColumDef] = useState([
        { headerName: "Sr. No.", field: "#", width: 80, valueGetter: "node.rowIndex + 1" },
        { field: 'DEFECTID', headerName: 'Defect ID', width: 120 },
        { field: 'PTW_ID', headerName: 'PTW ID', width: 180 },
        { field: 'PTWSTATUS', headerName: 'PTW Status', width: 180 },
        { field: 'PTW_DESCRIPTION', headerName: 'PTW Description', width: 180 },
        { field: 'ISSUING_AUTHORITY_NAME', headerName: 'PTW Requested By', width: 180 },
        { field: 'ISSUING_DIVISION_NAME', headerName: 'Requesting Division', width: 180 },
        { field: 'FROM_DATE', headerName: 'From Date', width: 180, valueGetter: (params) => formatDate(params.data.FROM_DATE) },
        { field: 'TO_DATEE', headerName: 'To Date', width: 180, valueGetter: (params) => formatDate(params.data.TO_DATEE) },
        {
            headerName: "Action",
            field: "#",
            width: 80,
            pinned: "left",
            cellRenderer: cellTemplate,
            cellRendererParams: {
                setSelectedPTWDetail,
            },
        },
    ]);

    const [selectedTestOperationDivision, setselectedTestOperationDivision] = useState([])
    const [selectedTestOperationEmployee, setselectedTestOperationEmployee] = useState([])

    const handleTestOperationChange = (e, field) => {
        switch (field) {
            case "DIVISION":
                setselectedTestOperationDivision(e.value)
                ptwEmployeeBasedOnDivision(e.value)
                break;
            case "EMPLOYEE":
                setselectedTestOperationEmployee(e.value)
                break;
            default:
                break;
        }
    }

    const [showSubmitButton, setShowSubmitButton] = useState(true);

    return (
        <div className={BizClass.PageStart}>
            <div className={BizClass.Card}>
                <PageBar className={BizClass.CustomPageBar}>
                    <span>PTW List</span>
                    <div className="d-flex align-items-center">
                        <PageBar.Button onClick={fetchPTWDataForEmployeeForWeb} style={{ margin: '5px' }}>Refresh</PageBar.Button>
                        <PageBar.Search onChange={(e) => onChangeSearchText(e.target.value)} value={searchText} onClick={() => getAssetListingData()} style={{ marginBottom: '2px' }} />
                        <PageBar.Button varient="primary" onClick={exportToCSV} style={{ margin: '5px' }}>
                            Export to CSV
                        </PageBar.Button>
                    </div>
                </PageBar>
                <div>
                    <Form>
                        <Form.Group column={3} controlwidth="240px">

                            <Form.InputGroup label="Unit Name">
                                <Form.InputControl
                                    control="select"
                                    name="frequencyType"
                                    options={frequencyOptions.map((option) => ({
                                        label: option,
                                        value: option,
                                    }))}
                                    defaultValue={formunitValue}
                                    onChange={(e) => {
                                        const selectedValue = e ? e.value : [];
                                        updateFormState(selectedValue, "frequencyType");
                                    }}
                                />
                            </Form.InputGroup>


                            <Form.InputGroup label="Division Name">
                                <Form.InputControl
                                    control="input"
                                    name="division"
                                    maxLength="200"
                                    value={searchDivision}
                                    onChange={(e) => handleSearchInputChange(e, "DIVISION")}

                                />
                            </Form.InputGroup>



                            <Form.InputGroup label="PTW Status">
                                <Form.InputControl
                                    control="input"
                                    name="ptwstatus"
                                    maxLength="200"
                                    value={searchPtw}
                                    onChange={(e) => handleSearchInputChange(e, "PTWSTATUS")}

                                />
                            </Form.InputGroup>

                            <Form.InputGroup label="From Date">
                                <PageBar.Input
                                    type="date"
                                    name="txtFromDate"
                                    maxLength="200"
                                    value={selectedFromDate}
                                    onChange={(e) => handleSearchInputChange(e, "FROMDATE")}
                                />
                            </Form.InputGroup>

                            <Form.InputGroup label="To Date">
                                <PageBar.Input
                                    type="date"
                                    name="txtToDate"
                                    maxLength="200"
                                    value={selectedToDate}
                                    onChange={(e) => handleSearchInputChange(e, "TODATE")}

                                />
                            </Form.InputGroup>

                            {/* <Form.InputGroup label="Line">
                                <Form.InputControl
                                    control="input"
                                    name="line"
                                    maxLength="200"
                                    value={line}
                                    onChange={(e) => handleSearchInputChange(e, "LINE")}

                                />
                            </Form.InputGroup>

                            <Form.InputGroup label="Isolation">
                                <Form.InputControl
                                    control="input"
                                    name="isolation"
                                    maxLength="200"
                                    value={isolation}
                                    onChange={(e) => handleSearchInputChange(e, "ISOLATION")}

                                />
                            </Form.InputGroup> */}

                        </Form.Group>
                    </Form>
                </div>
                {/* <DataGrid
                    rowData={data}
                    columnDefs={ListViewColumDef}
                    loader={isLoadingPTWList ? <Loader /> : null}
                    onGridReady={onGridReady}
                /> */}
                <DataGrid
                    rowData={ptwData}
                    columnDefs={ListViewColumDef}
                    loader={isLoadingPTWList ? <Loader /> : null}
                    onGridReady={onGridReady}
                />
            </div>

            {PTWModalOpen && (
                <Modal varient="center"
                    title={getPTWDataByDefectIdPTWId.acknowledgE_STATUS === "N" ? 'Acknowledge PTW' : 'PTW Actions'}
                    // title="PTW Actions"
                    index={2}
                    show={closePTWDetailsModal}
                    width="1000px"
                    height="800px">
                    <Modal.Body>
                        <div>
                            <Form>
                                <Form.Group column={2} controlwidth="340px">
                                    <Form.InputGroup label="" column={2}>
                                        <h6>Defect Details</h6>
                                    </Form.InputGroup>
                                    <Form.InputGroup label="" column={2}>

                                    </Form.InputGroup>
                                    <Form.InputGroup label="Defect ID">
                                        <Form.InputControl
                                            control="input"
                                            name="txtDefectId"
                                            maxLength="200"
                                            value={getPTWDataByDefectIdPTWId.defectid}
                                            readOnly
                                        />
                                    </Form.InputGroup>
                                    <Form.InputGroup label="Defect Status">
                                        <Form.InputControl control="input" name="txtdefctid" maxLength="200" value={defectData.status} readOnly />

                                    </Form.InputGroup>

                                    <Form.InputGroup label="Asset ID">
                                        <Form.InputControl control="input" name="txtdefctid" maxLength="200" value={defectData.childAssetId} readOnly />
                                    </Form.InputGroup>

                                    <Form.InputGroup label="Asset Name">
                                        <Form.InputControl control="input" name="txtdefctid" maxLength="200" value={defectData.childAssetDescription} readOnly />
                                    </Form.InputGroup>

                                    <Form.InputGroup label="Unit Name">
                                        <Form.InputControl control="input" name="txtdefctid" maxLength="200" value={defectData.unitName} readOnly />
                                    </Form.InputGroup>

                                    <Form.InputGroup label="Priority">



                                        <Form.InputControl control="input" name="txtdefctid" maxLength="200" value={defectData.priorityTypeName} readOnly />
                                    </Form.InputGroup>

                                    <Form.InputGroup label="Defect Type">
                                        <Form.InputControl control="input" name="txtdefctid" maxLength="200" value={defectData.defecttypE_NAME} readOnly />
                                    </Form.InputGroup>

                                    <Form.InputGroup label="Reason">
                                        <Form.InputControl control="input" name="txtdefctid" maxLength="200" value={defectData.reasonfordefect} readOnly />
                                    </Form.InputGroup>

                                    <Form.InputGroup label="Description">
                                        <Form.InputControl control="input" name="txtdefctid" maxLength="200" value={defectData.description} readOnly />
                                    </Form.InputGroup>
                                    <Form.InputGroup label="Defect Logged By">
                                        <Form.InputControl control="input" name="txtdefctid" maxLength="200" value={`${defectData.employeeName} (${defectData.defectloggedby})`} readOnly />
                                    </Form.InputGroup>

                                    <Form.InputGroup label="Defect Logged On">
                                        <Form.InputControl control="input" name="txtdefctid" maxLength="200" value={formatDates(defectData.defectLoggedOn)} readOnly />
                                    </Form.InputGroup>


                                    <Form.InputGroup label="Attending Division">
                                        <Form.InputControl control="input" name="txtdefctid" maxLength="200" value={defectData.attendingDivisionName} readOnly />
                                    </Form.InputGroup>

                                    <Form.InputGroup label="" column={2}>
                                        <h6>PTW Details</h6>
                                    </Form.InputGroup>
                                    <Form.InputGroup label="" column={2}>
                                    </Form.InputGroup>

                                    <Form.InputGroup label="PTW ID">
                                        <Form.InputControl
                                            control="input"
                                            name="txtDefectId"
                                            maxLength="200"
                                            value={getPTWDataByDefectIdPTWId.ptW_ID}
                                            readOnly
                                        />
                                    </Form.InputGroup>

                                    <Form.InputGroup label="PTW Status">
                                        <Form.InputControl
                                            control="input"
                                            name="txtDefectId"
                                            maxLength="200"
                                            value={getPTWDataByDefectIdPTWId.ptwstatus}
                                            readOnly
                                        />


                                    </Form.InputGroup>

                                    <Form.InputGroup label="PTW Work Type">
                                        <Form.InputControl
                                            control="input"
                                            name="txtDefectId"
                                            maxLength="200"
                                            value={getPTWDataByDefectIdPTWId.worK_NAME}
                                            readOnly
                                        />


                                    </Form.InputGroup>
                                    <Form.InputGroup label="PTW Description">
                                        <Form.InputControl
                                            control="input"
                                            name="txtDefectId"
                                            maxLength="200"
                                            value={getPTWDataByDefectIdPTWId.ptW_DESCRIPTION}
                                            readOnly
                                        />


                                    </Form.InputGroup>

                                    <Form.InputGroup label="From Date">
                                        <Form.InputControl
                                            control="input"
                                            name="txtDefectId"
                                            maxLength="200"
                                            value={(getPTWDataByDefectIdPTWId.froM_DATE)}
                                            readOnly
                                        />


                                    </Form.InputGroup>

                                    <Form.InputGroup label="To Date">
                                        <Form.InputControl
                                            control="input"
                                            name="txtDefectId"
                                            maxLength="200"
                                            value={(getPTWDataByDefectIdPTWId.tO_DATE)}
                                            readOnly
                                        />


                                    </Form.InputGroup>

                                    <Form.InputGroup label="PTW Requested Date and Time">
                                        <Form.InputControl
                                            control="input"
                                            name="txtDefectId"
                                            maxLength="200"
                                            value={getPTWDataByDefectIdPTWId.ptW_ENTRY_DATETIME}
                                            readOnly
                                        />
                                    </Form.InputGroup>
                                    <Form.InputGroup label="PTW Requested By">
                                        <Form.InputControl
                                            control="input"
                                            name="txtDefectId"
                                            maxLength="200"
                                            value={`${getPTWDataByDefectIdPTWId.issuinG_EMPLOYEENAME} (${getPTWDataByDefectIdPTWId.issuingAuthority})`}
                                            readOnly
                                        />
                                    </Form.InputGroup>
                                    <Form.InputGroup label="PTW Requested By Division">
                                        <Form.InputControl
                                            control="input"
                                            name="txtDefectId"
                                            maxLength="200"
                                            value={getPTWDataByDefectIdPTWId.divisionname}
                                            readOnly
                                        />


                                    </Form.InputGroup>

                                    <Form.InputGroup label="PTW Requested To">
                                        <Form.InputControl
                                            control="input"
                                            name="txtDefectId"
                                            maxLength="200"
                                            value={`${getPTWDataByDefectIdPTWId.ptW_INITIATED_TO_EMPNAME
                                                } (${getPTWDataByDefectIdPTWId.ptW_INITIATED_TO_EMPID})`}
                                            readOnly
                                        />


                                    </Form.InputGroup>

                                    <Form.InputGroup label="PTW Requested To Division">
                                        <Form.InputControl
                                            control="input"
                                            name="txtDefectId"
                                            maxLength="200"
                                            value={getPTWDataByDefectIdPTWId.ptW_INITIATED_TO_DIVID}
                                            readOnly
                                        />


                                    </Form.InputGroup>


                                    {/* {(getPTWDataByDefectIdPTWId.transfrreD_TO_EMPNAME === null && (getPTWDataByDefectIdPTWId.ptwstatus === "ISSUED" || getPTWDataByDefectIdPTWId.ptwstatus === "ACCEPTED" || getPTWDataByDefectIdPTWId.ptwstatus === "TRANSFERRED")) ? (
                                        <Form.InputGroup label="PTW Holder" style={{ display: getPTWDataByDefectIdPTWId.transfeR_APPROVAL_STATUS === "APPROVED" ? 'none' : 'inline' }}>
                                            <Form.InputControl
                                                control="input"
                                                name="txtDefectId"
                                                maxLength="200"
                                                style={{ display: getPTWDataByDefectIdPTWId.transfeR_APPROVAL_STATUS === "APPROVED" ? 'none' : 'inline' }}
                                                value={`${getPTWDataByDefectIdPTWId.issuinG_EMPLOYEENAME} (${getPTWDataByDefectIdPTWId.issuinG_EMPLOYEEID})`}
                                                readOnly
                                            />
                                        </Form.InputGroup>
                                    ) : (<Form.InputGroup label="PTW Holder">
                                        <Form.InputControl
                                            control="input"
                                            name="txtDefectId"
                                            maxLength="200"
                                            style={{ display: getPTWDataByDefectIdPTWId.transfeR_APPROVAL_STATUS === "APPROVED" ? 'none' : 'inline' }}

                                            value=""
                                            readOnly
                                        />
                                    </Form.InputGroup>)}

                                    {getPTWDataByDefectIdPTWId.transfrreD_TO_EMPNAME !== null && getPTWDataByDefectIdPTWId.transfeR_APPROVAL_STATUS === "APPROVED" && (
                                        <Form.InputGroup label="PTW Holder">
                                            <Form.InputControl
                                                control="input"
                                                name="txtDefectId"
                                                maxLength="200"
                                                value={`${getPTWDataByDefectIdPTWId.transfrreD_TO_EMPNAME} (${getPTWDataByDefectIdPTWId.transfrreD_TO_EMPID})`}
                                                readOnly
                                            />
                                        </Form.InputGroup>
                                    )} */}

                                    <Form.InputGroup label="PTW Holder">
                                        {getPTWDataByDefectIdPTWId.transfeR_APPROVAL_STATUS === "APPROVED" ? (
                                            <>
                                                <Form.InputControl
                                                    control="input"
                                                    name="txtDefectId"
                                                    maxLength="200"
                                                    value={`${getPTWDataByDefectIdPTWId.transfrreD_TO_EMPNAME} (${getPTWDataByDefectIdPTWId.transfrreD_TO_EMPID})`}
                                                    readOnly
                                                />
                                            </>
                                        ) : (
                                            <Form.InputControl
                                                control="input"
                                                name="txtDefectId"
                                                maxLength="200"
                                                value={
                                                    (
                                                        getPTWDataByDefectIdPTWId.ptwstatus === "ACCEPTED" ||
                                                        getPTWDataByDefectIdPTWId.ptwstatus === "TRANSFERRED" || getPTWDataByDefectIdPTWId.ptwstatus === "TRANSFER ACCEPTED") ?
                                                        `${getPTWDataByDefectIdPTWId.issuinG_EMPLOYEENAME} (${getPTWDataByDefectIdPTWId.issuinG_EMPLOYEEID})` :
                                                        ""
                                                }
                                                readOnly
                                            />
                                        )}
                                    </Form.InputGroup>






                                    {getPTWDataByDefectIdPTWId.acknowledgE_STATUS === "Y" && (
                                        <>
                                            {/* <Form.InputGroup label="" column={2}>
                                            </Form.InputGroup> */}
                                            <Form.InputGroup label="" column={2}>
                                                <h6>Acknowledge PTW Details</h6>
                                            </Form.InputGroup>

                                            <Form.InputGroup label="" column={2}>
                                            </Form.InputGroup>


                                            <Form.InputGroup label="PTW Acknowledged By Employee">
                                                <Form.InputControl
                                                    control="input"
                                                    name="txtDefectId"
                                                    maxLength="200"
                                                    value={`${getPTWDataByDefectIdPTWId.acknowledgE_BY_EMPNAME} (${getPTWDataByDefectIdPTWId.acknowledgE_BY_EMPID})`}
                                                    readOnly
                                                />
                                            </Form.InputGroup>
                                            <Form.InputGroup label="PTW Acknowledged Division">
                                                <Form.InputControl
                                                    control="input"
                                                    name="txtDefectId"
                                                    maxLength="200"
                                                    value={getPTWDataByDefectIdPTWId.acknowledgeD_BY_DIVNAME}
                                                    readOnly
                                                />
                                            </Form.InputGroup>
                                            <Form.InputGroup label="PTW Acknowledged Date Time">
                                                <Form.InputControl
                                                    control="input"
                                                    name="txtDefectId"
                                                    maxLength="200"
                                                    value={getPTWDataByDefectIdPTWId.acknowledgE_TIME}

                                                    readOnly
                                                />
                                            </Form.InputGroup>
                                        </>

                                    )}

                                    {getPTWDataByDefectIdPTWId.issueD_TO_EMPID !== null && (
                                        <>
                                            <Form.InputGroup label="" column={2}>
                                            </Form.InputGroup>

                                            <Form.InputGroup label="" column={2}>
                                                <h6>PTW Issued Details</h6>
                                            </Form.InputGroup>
                                            <Form.InputGroup label="" column={2}>
                                            </Form.InputGroup>




                                            <Form.InputGroup label="PTW Issued By Employee">
                                                <Form.InputControl
                                                    control="input"
                                                    name="txtDefectId"
                                                    maxLength="200"
                                                    value={`${getPTWDataByDefectIdPTWId.issueD_TO_EMPNAME} (${getPTWDataByDefectIdPTWId.issueD_TO_EMPID})`}
                                                    readOnly
                                                />
                                            </Form.InputGroup>
                                            <Form.InputGroup label="PTW Issued Division">
                                                <Form.InputControl
                                                    control="input"
                                                    name="txtDefectId"
                                                    maxLength="200"
                                                    value={getPTWDataByDefectIdPTWId.issuinG_DIVNAME}
                                                    readOnly
                                                />
                                            </Form.InputGroup>
                                            <Form.InputGroup label="PTW Issued Date Time">
                                                <Form.InputControl
                                                    control="input"
                                                    name="txtDefectId"
                                                    maxLength="200"
                                                    value={getPTWDataByDefectIdPTWId.issueD_DATETIME}
                                                    readOnly
                                                />
                                            </Form.InputGroup>
                                            <Form.InputGroup label="PTW Issued Remarks">
                                                <Form.InputControl
                                                    control="input"
                                                    name="txtDefectId"
                                                    maxLength="200"
                                                    value={getPTWDataByDefectIdPTWId.issuE_REMARKS}
                                                    readOnly
                                                />
                                            </Form.InputGroup>
                                        </>

                                    )}

                                    {getPTWDataByDefectIdPTWId.rejecteD_EMPID !== null && (
                                        <>
                                            <Form.InputGroup label="" column={2}>
                                            </Form.InputGroup>
                                            <Form.InputGroup label="" column={2}>
                                                <h6>PTW Rejected Details</h6>
                                            </Form.InputGroup>
                                            <Form.InputGroup label="" column={2}>
                                            </Form.InputGroup>


                                            <Form.InputGroup label="PTW Rejected By">
                                                <Form.InputControl
                                                    control="input"
                                                    name="txtDefectId"
                                                    maxLength="200"
                                                    value={`${getPTWDataByDefectIdPTWId.rejecteD_EMPNAME} (${getPTWDataByDefectIdPTWId.rejecteD_EMPID})`}
                                                    readOnly
                                                />
                                            </Form.InputGroup>

                                            <Form.InputGroup label="PTW Rejected By Division">
                                                <Form.InputControl
                                                    control="input"
                                                    name="txtDefectId"
                                                    maxLength="200"
                                                    value={getPTWDataByDefectIdPTWId.rejecteD_DIVNAME}
                                                    readOnly
                                                />
                                            </Form.InputGroup>

                                            <Form.InputGroup label="PTW Rejected Date Time">
                                                <Form.InputControl
                                                    control="input"
                                                    name="txtDefectId"
                                                    maxLength="200"
                                                    value={getPTWDataByDefectIdPTWId.rejecteD_DATEENTRY}
                                                    readOnly
                                                />
                                            </Form.InputGroup>

                                            <Form.InputGroup label="PTW Rejected Remarks">
                                                <Form.InputControl
                                                    control="input"
                                                    name="txtDefectId"
                                                    maxLength="200"
                                                    value={getPTWDataByDefectIdPTWId.rejecteD_REMARKS}
                                                    readOnly
                                                />
                                            </Form.InputGroup>
                                        </>

                                    )}

                                    {getPTWDataByDefectIdPTWId.accepteD_EMPID !== null && (
                                        <>

                                            <Form.InputGroup label="" column={2}>
                                                <h6>PTW Accepted Details</h6>
                                            </Form.InputGroup>
                                            <Form.InputGroup label="" column={2}>
                                            </Form.InputGroup>

                                            <Form.InputGroup label="PTW Accepted By">
                                                <Form.InputControl
                                                    control="input"
                                                    name="txtDefectId"
                                                    maxLength="200"
                                                    value={`${getPTWDataByDefectIdPTWId.accepteD_EMPNAME} (${getPTWDataByDefectIdPTWId.accepteD_EMPID})`}
                                                    readOnly
                                                />
                                            </Form.InputGroup>

                                            <Form.InputGroup label="PTW Accepted By Division">
                                                <Form.InputControl
                                                    control="input"
                                                    name="txtDefectId"
                                                    maxLength="200"
                                                    value={getPTWDataByDefectIdPTWId.accepteD_DIVNAME}
                                                    readOnly
                                                />
                                            </Form.InputGroup>
                                            <Form.InputGroup label="PTW Accepted Date Time">
                                                <Form.InputControl
                                                    control="input"
                                                    name="txtDefectId"
                                                    maxLength="200"
                                                    value={getPTWDataByDefectIdPTWId.accepteD_DATEENTRY}
                                                    readOnly
                                                />
                                            </Form.InputGroup>
                                            <Form.InputGroup label="PTW Accepted Remarks">
                                                <Form.InputControl
                                                    control="input"
                                                    name="txtDefectId"
                                                    maxLength="200"
                                                    value={getPTWDataByDefectIdPTWId.accepteD_REMARKS}
                                                    readOnly
                                                />
                                            </Form.InputGroup>

                                        </>

                                    )}

                                    {(getPTWDataByDefectIdPTWId.transfrreD_TO_EMPNAME !== null) && (
                                        <>
                                            <Form.InputGroup label="" column={2}>
                                                <h6>PTW Transferred Details</h6>
                                            </Form.InputGroup>
                                            <Form.InputGroup label="" column={2}>
                                            </Form.InputGroup>
                                            <Form.InputGroup label="Transfer Requested to Employee">
                                                <Form.InputControl
                                                    control="input"
                                                    name="txtDefectId"
                                                    maxLength="200"
                                                    value={`${getPTWDataByDefectIdPTWId.transfrreD_TO_EMPNAME} (${getPTWDataByDefectIdPTWId.transfrreD_TO_EMPID})`}
                                                    readOnly
                                                />
                                            </Form.InputGroup>
                                            <Form.InputGroup label="Transfer Requested to Division">
                                                <Form.InputControl
                                                    control="input"
                                                    name="txtDefectId"
                                                    maxLength="200"
                                                    value={getPTWDataByDefectIdPTWId.transferreD_TO_DIVNAME}
                                                    readOnly
                                                />
                                            </Form.InputGroup>
                                            <Form.InputGroup label="Transfer Request Date Time">
                                                <Form.InputControl
                                                    control="input"
                                                    name="txtDefectId"
                                                    maxLength="200"
                                                    value={getPTWDataByDefectIdPTWId.transfrreD_DATE}
                                                    readOnly
                                                />
                                            </Form.InputGroup>

                                            <Form.InputGroup label="Transfer Request Remarks">
                                                <Form.InputControl
                                                    control="input"
                                                    name="txtDefectId"
                                                    maxLength="200"
                                                    value={getPTWDataByDefectIdPTWId.transfrreD_REMARKS}
                                                    readOnly
                                                />
                                            </Form.InputGroup>
                                        </>
                                    )}

                                    {getPTWDataByDefectIdPTWId.transfeR_ACCEPTANCE_STATUS === 1 && (
                                        <>
                                            <Form.InputGroup label="Transfer Accepted By">
                                                <Form.InputControl
                                                    control="input"
                                                    name="txtDefectId"
                                                    maxLength="200"
                                                    value={`${getPTWDataByDefectIdPTWId.transfeR_ACCEPTED_EMPNAME} (${getPTWDataByDefectIdPTWId.transfeR_ACCEPTED_FROM_EMP})`}
                                                    readOnly
                                                />
                                            </Form.InputGroup>

                                            <Form.InputGroup label="Transfer Accepted Date Time">
                                                <Form.InputControl
                                                    control="input"
                                                    name="txtDefectId"
                                                    maxLength="200"
                                                    value={getPTWDataByDefectIdPTWId.transfeR_ACCEPTANCE_DATE}
                                                    readOnly
                                                />
                                            </Form.InputGroup>
                                        </>

                                    )}

                                    {getPTWDataByDefectIdPTWId.transfeR_REJECT_STATUS !== 0 && (
                                        <>
                                            <Form.InputGroup label="Transfer Rejected By">
                                                <Form.InputControl
                                                    control="input"
                                                    name="txtDefectId"
                                                    maxLength="200"
                                                    value={`${getPTWDataByDefectIdPTWId.transfeR_REJECTED_EMPNAME} (${getPTWDataByDefectIdPTWId.transfeR_REJECTED_FROM_EMP})`}
                                                    readOnly
                                                />
                                            </Form.InputGroup>

                                            <Form.InputGroup label="Transfer Rejected Date Time">
                                                <Form.InputControl
                                                    control="input"
                                                    name="txtDefectId"
                                                    maxLength="200"
                                                    value={getPTWDataByDefectIdPTWId.transfeR_REJECTED_DATE}
                                                    readOnly
                                                />
                                            </Form.InputGroup>
                                        </>
                                    )}

                                    {getPTWDataByDefectIdPTWId.transfeR_APPROVAL_STATUS === "APPROVED" && (
                                        <>
                                            <Form.InputGroup label="Transfer Approved by Employee">
                                                <Form.InputControl
                                                    control="input"
                                                    name="txtDefectId"
                                                    maxLength="200"
                                                    value={`${getPTWDataByDefectIdPTWId.transfrR_APPROVAL_FROM_EMP_NAME} (${getPTWDataByDefectIdPTWId.transfrR_APPROVAL_FROM})`}
                                                    readOnly
                                                />
                                            </Form.InputGroup>
                                            {/* <Form.InputGroup label="Transfer Approved by Division">
                                                <Form.InputControl
                                                    control="input"
                                                    name="txtDefectId"
                                                    maxLength="200"
                                                    value={getPTWDataByDefectIdPTWId.transferreD_TO_DIVNAME}
                                                    readOnly
                                                />
                                            </Form.InputGroup> */}
                                            <Form.InputGroup label="Transfer Approved Date Time">
                                                <Form.InputControl
                                                    control="input"
                                                    name="txtDefectId"
                                                    maxLength="200"
                                                    value={getPTWDataByDefectIdPTWId.transferreD_APPROVAL_TIMESTAMP}
                                                    readOnly
                                                />
                                            </Form.InputGroup>



                                        </>
                                    )}

                                    {getPTWDataByDefectIdPTWId.tesT_OP_REQUEST_BY_EMPNAME !== null && (
                                        <>
                                            <Form.InputGroup label="" column={2}>
                                                <h6>PTW Test Operation Details</h6>
                                            </Form.InputGroup>
                                            <Form.InputGroup label="" column={2}>
                                            </Form.InputGroup>


                                            <Form.InputGroup label="Operation Requested By">
                                                <Form.InputControl
                                                    control="input"
                                                    name="txtRemarks"
                                                    maxLength="200"
                                                    value={`${getPTWDataByDefectIdPTWId.tesT_OP_REQUEST_BY_EMPNAME} (${getPTWDataByDefectIdPTWId.tesT_OP_REQUEST_BY_EMPID})`}
                                                    readOnly

                                                />
                                            </Form.InputGroup>



                                            <Form.InputGroup label="Operation Requested To">
                                                <Form.InputControl
                                                    control="input"
                                                    name="txtRemarks"
                                                    maxLength="200"
                                                    value={`${getPTWDataByDefectIdPTWId.tesT_OP_REQUEST_TO_EMPNAME} (${getPTWDataByDefectIdPTWId.tesT_OP_REQUEST_TO_EMPID})`}
                                                    readOnly

                                                />
                                            </Form.InputGroup>



                                            <Form.InputGroup label="Operation Permitted">
                                                <Form.InputControl
                                                    control="input"
                                                    name="txtRemarks"
                                                    maxLength="200"
                                                    value={getPTWDataByDefectIdPTWId.tesT_OP_ISO_PERMITTED === "Y" ? 'Permitted' : 'Pending'}
                                                    readOnly

                                                />
                                            </Form.InputGroup>

                                            <Form.InputGroup label="Operation Permitted Date Time">
                                                <Form.InputControl
                                                    control="input"
                                                    name="txtRemarks"
                                                    maxLength="200"
                                                    value={getPTWDataByDefectIdPTWId.isO_PERMITTED_ENTRY_DATE}
                                                    readOnly

                                                />
                                            </Form.InputGroup>


                                            <Form.InputGroup label="Operation Completed">
                                                <Form.InputControl
                                                    control="input"
                                                    name="txtRemarks"
                                                    maxLength="200"
                                                    value={getPTWDataByDefectIdPTWId.tesT_OP_WORK_COMPLETED === "Y" ? 'Completed' : 'Pending'}
                                                    readOnly

                                                />
                                            </Form.InputGroup>

                                            <Form.InputGroup label="Operation Completed Date Time">
                                                <Form.InputControl
                                                    control="input"
                                                    name="txtRemarks"
                                                    maxLength="200"
                                                    value={getPTWDataByDefectIdPTWId.isO_NORMALIZE_REQUEST_ENTRY_DATE}
                                                    readOnly

                                                />
                                            </Form.InputGroup>




                                            <Form.InputGroup label="Operation Restored">
                                                <Form.InputControl
                                                    control="input"
                                                    name="txtRemarks"
                                                    maxLength="200"
                                                    value={getPTWDataByDefectIdPTWId.tesT_OP_ISO_NORMALIZED === "Y" ? 'Restored' : 'Pending'}
                                                    readOnly

                                                />
                                            </Form.InputGroup>

                                            <Form.InputGroup label="Operation Restored Date Time">
                                                <Form.InputControl
                                                    control="input"
                                                    name="txtRemarks"
                                                    maxLength="200"
                                                    value={getPTWDataByDefectIdPTWId.isO_NORMALIZE_ENTRY_DATE}
                                                    readOnly

                                                />
                                            </Form.InputGroup>

                                        </>
                                    )}














                                    {getPTWDataByDefectIdPTWId.transfeR_APPROVAL_STATUS === "CANCELLED" && (
                                        <>

                                            <Form.InputGroup label="Transfer Cancelled by Employee">
                                                <Form.InputControl
                                                    control="input"
                                                    name="txtDefectId"
                                                    maxLength="200"
                                                    value=""
                                                    readOnly
                                                />
                                            </Form.InputGroup>
                                            <Form.InputGroup label="Transfer Cancelled by Division">
                                                <Form.InputControl
                                                    control="input"
                                                    name="txtDefectId"
                                                    maxLength="200"
                                                    value=""
                                                    readOnly
                                                />
                                            </Form.InputGroup>
                                            <Form.InputGroup label="Transfer Cancelled Date Time">
                                                <Form.InputControl
                                                    control="input"
                                                    name="txtDefectId"
                                                    maxLength="200"
                                                    value=""
                                                    readOnly
                                                />
                                            </Form.InputGroup>

                                        </>
                                    )}

                                    {getPTWDataByDefectIdPTWId.returneD_BY_EMPNAME !== null && (
                                        <>

                                            <Form.InputGroup label="" column={2}>
                                                <h6>PTW Return Details</h6>
                                            </Form.InputGroup>
                                            <Form.InputGroup label="" column={2}>
                                            </Form.InputGroup>

                                            <Form.InputGroup label="PTW Return To">
                                                <Form.InputControl
                                                    control="input"
                                                    name="txtDefectId"
                                                    maxLength="200"
                                                    value={`${getPTWDataByDefectIdPTWId.returneD_BY_EMPNAME} (${getPTWDataByDefectIdPTWId.returneD_BY_EMPID})`}
                                                    readOnly
                                                />
                                            </Form.InputGroup>

                                            <Form.InputGroup label="PTW Return To Division">
                                                <Form.InputControl
                                                    control="input"
                                                    name="txtDefectId"
                                                    maxLength="200"
                                                    value={getPTWDataByDefectIdPTWId.returneD_BY_DIVISION_NAME}
                                                    readOnly
                                                />
                                            </Form.InputGroup>
                                            <Form.InputGroup label="PTW Return Date Time">
                                                <Form.InputControl
                                                    control="input"
                                                    name="txtDefectId"
                                                    maxLength="200"
                                                    value={getPTWDataByDefectIdPTWId.returN_DATEENTRY}
                                                    readOnly
                                                />
                                            </Form.InputGroup>

                                            <Form.InputGroup label="PTW Return Remarks">
                                                <Form.InputControl
                                                    control="input"
                                                    name="txtDefectId"
                                                    maxLength="200"
                                                    value={getPTWDataByDefectIdPTWId.returN_REMARKS}
                                                    readOnly
                                                />
                                            </Form.InputGroup>

                                        </>

                                    )}

                                    {getPTWDataByDefectIdPTWId.ptwstatus === 'CANCELLED' && (
                                        <>
                                            <Form.InputGroup label="" column={2}>
                                                <h6>PTW Cancelled Details</h6>
                                            </Form.InputGroup>
                                            <Form.InputGroup label="" column={2}>
                                            </Form.InputGroup>
                                            <Form.InputGroup label="Cancelled by Employee">
                                                <Form.InputControl
                                                    control="input"
                                                    name="txtDefectId"
                                                    maxLength="200"
                                                    value={`${getPTWDataByDefectIdPTWId.cancelleD_FROM_EMPNAME} (${getPTWDataByDefectIdPTWId.canceleD_FROM_EMP})`}
                                                    readOnly
                                                />
                                            </Form.InputGroup>
                                            <Form.InputGroup label="Cancelled by Division">
                                                <Form.InputControl
                                                    control="input"
                                                    name="txtDefectId"
                                                    maxLength="200"
                                                    value={getPTWDataByDefectIdPTWId.cancelleD_FROM_DIVNAME}
                                                    readOnly
                                                />
                                            </Form.InputGroup>
                                            <Form.InputGroup label="Cancelled Date Time">
                                                <Form.InputControl
                                                    control="input"
                                                    name="txtDefectId"
                                                    maxLength="200"
                                                    value={getPTWDataByDefectIdPTWId.cancelleD_TIMESTAMP}
                                                    readOnly
                                                />
                                            </Form.InputGroup>
                                            <Form.InputGroup label="Cancelled Remarks">
                                                <Form.InputControl
                                                    control="input"
                                                    name="txtDefectId"
                                                    maxLength="200"
                                                    value={getPTWDataByDefectIdPTWId.canceleD_REMARKSS}
                                                    readOnly
                                                />
                                            </Form.InputGroup>
                                        </>
                                    )}

                                    {getPTWDataByDefectIdPTWId.workdonE_EMPID !== null && (
                                        <>
                                            <Form.InputGroup label="" column={2}>
                                                <h6> Attend Defect Details</h6>
                                            </Form.InputGroup>
                                            <Form.InputGroup label="" column={2}>
                                            </Form.InputGroup>
                                            <Form.InputGroup label="Attend Defect by Employee">
                                                <Form.InputControl
                                                    control="input"
                                                    name="txtDefectId"
                                                    maxLength="200"
                                                    value={`${getPTWDataByDefectIdPTWId.workdonE_EMPNAME}(${getPTWDataByDefectIdPTWId.workdonE_EMPID})`}
                                                    readOnly
                                                />
                                            </Form.InputGroup>
                                            <Form.InputGroup label="Attend Defect Details">
                                                <Form.InputControl
                                                    control="input"
                                                    name="txtDefectId"
                                                    maxLength="200"
                                                    value={getPTWDataByDefectIdPTWId.workdonE_DETAILS}
                                                    readOnly
                                                />
                                            </Form.InputGroup>
                                            <Form.InputGroup label="Attend Defect Restrictions if any">
                                                <Form.InputControl
                                                    control="input"
                                                    name="txtDefectId"
                                                    maxLength="200"
                                                    value={getPTWDataByDefectIdPTWId.restrictionS_IF_ANY}
                                                    readOnly
                                                />
                                            </Form.InputGroup>

                                            <Form.InputGroup label="Attend Defect Date Time">
                                                <Form.InputControl
                                                    control="input"
                                                    name="txtDefectId"
                                                    maxLength="200"
                                                    value={getPTWDataByDefectIdPTWId.workdonE_DATE}
                                                    readOnly
                                                />
                                            </Form.InputGroup>

                                        </>
                                    )}

                                    {getPTWDataByDefectIdPTWId.attenD_DEFECT_APPROVED_BY_EMP !== null && (
                                        <>
                                            <Form.InputGroup label="" column={2}>
                                                <h6>Approved Details</h6>
                                            </Form.InputGroup>
                                            <Form.InputGroup label="" column={2}>
                                            </Form.InputGroup>
                                            <Form.InputGroup label="Approve Attend Defect by Employee">
                                                <Form.InputControl
                                                    control="input"
                                                    name="txtDefectId"
                                                    maxLength="200"
                                                    value={`${getPTWDataByDefectIdPTWId.attenD_DEFECT_APPROVED_BY_EMPNAME}(${getPTWDataByDefectIdPTWId.attenD_DEFECT_APPROVED_BY_EMP})`}
                                                    readOnly
                                                />
                                            </Form.InputGroup>

                                            <Form.InputGroup label="Approve Attend Date Time">
                                                <Form.InputControl
                                                    control="input"
                                                    name="txtDefectId"
                                                    maxLength="200"
                                                    value={getPTWDataByDefectIdPTWId.attenD_DEFECT_APPROVAL_DATETIME}
                                                    readOnly
                                                />
                                            </Form.InputGroup>
                                        </>
                                    )}







                                </Form.Group>
                                <div className="d-flex">

                                    <button style={{ margin: '5px' }} className="btn btn-success" onClick={(event) => { event.preventDefault(); handleIsolations(); }}>Isolations {(getPTWDataByDefectIdPTWId.ptwstatus === 'INITIATED' ? 'Requested' : ((getPTWDataByDefectIdPTWId.ptwstatus === 'RETURNED' || getPTWDataByDefectIdPTWId.ptwstatus === 'CANCELLED') ? 'Normalised' : 'Done'))
                                    } ({isolationData.length})</button>
                                    <button style={{ margin: '5px' }} className="btn btn-success" onClick={(event) => { event.preventDefault(); handleHazardos(); }}>View Hazardoes ({hazardoesData.length})</button>
                                    <button style={{ margin: '5px' }} className="btn btn-success" onClick={(event) => { event.preventDefault(); handleEquipment(); }}>View Equipment ({equipmentView.length})</button><br />
                                    {/* {getPTWDataByDefectIdPTWId.ptwstatus === "RETURNED" && (
                                        <>
                                            <button style={{ margin: '5px' }} className="btn btn-success" onClick={(event) => { event.preventDefault(); handleIsolationsNormalised(); }}>Isolations Normalised</button><br />
                                        </>
                                    )} */}


                                </div>
                            </Form>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>
                        {getPTWDataByDefectIdPTWId.ptwstatus === "INITIATED" && getPTWDataByDefectIdPTWId.ptW_INITIATED_TO_EMPID === user.UserName && getPTWDataByDefectIdPTWId.acknowledgE_STATUS !== "Y" && (<Button style={{ margin: '5px', background: "#0052cc" }} onClick={(event) => { event.preventDefault(); handleAcknowledge(); }}>Acknowledge PTW request</Button>
                        )}

                        {getPTWDataByDefectIdPTWId.acknowledgE_STATUS === "Y" && getPTWDataByDefectIdPTWId.ptwstatus !== "ISSUED" && getPTWDataByDefectIdPTWId.ptwstatus !== "TRANSFERRED" && getPTWDataByDefectIdPTWId.ptW_INITIATED_TO_EMPID === user.UserName && getPTWDataByDefectIdPTWId.ptwstatus !== "RETURNED" && getPTWDataByDefectIdPTWId.ptwstatus !== "CANCELLED" && getPTWDataByDefectIdPTWId.ptwstatus !== "TRANSFER REQUEST" && getPTWDataByDefectIdPTWId.ptwstatus !== "TRANSFER ACCEPTED" && isChecked.some(item => item) && (<>
                            <Button style={{ display: getPTWDataByDefectIdPTWId.ptwstatus === "ACCEPTED" || getPTWDataByDefectIdPTWId.ptwstatus === "REJECTED" ? 'none' : 'inline' }} onClick={handleIssue}>Issue</Button>
                            <Button style={{ display: getPTWDataByDefectIdPTWId.ptwstatus === "ACCEPTED" || getPTWDataByDefectIdPTWId.ptwstatus === "REJECTED" ? 'none' : 'inline' }} onClick={handleReject}>Reject</Button>
                        </>)}
                        {(getPTWDataByDefectIdPTWId.ptwstatus === "ISSUED" && getPTWDataByDefectIdPTWId.issuinG_EMPLOYEEID === user.UserName && (getPTWDataByDefectIdPTWId.ptwstatus !== "ACCEPTED" && getPTWDataByDefectIdPTWId.ptwstatus !== "REJECTED")) && (
                            <>
                                <Button style={{ display: (getPTWDataByDefectIdPTWId.ptwstatus === "ACCEPTED" || getPTWDataByDefectIdPTWId.ptwstatus === "REJECTED") ? 'none' : 'inline' }} onClick={handleAcceptPTW}>Receipt</Button>
                                <Button style={{ display: (getPTWDataByDefectIdPTWId.ptwstatus === "ACCEPTED" || getPTWDataByDefectIdPTWId.ptwstatus === "REJECTED") ? 'none' : 'inline' }} onClick={handleAcceptRejectPTW}>Reject PTW</Button>
                            </>
                        )}

                        {(getPTWDataByDefectIdPTWId.transfrreD_TO_EMPID === user.UserName && getPTWDataByDefectIdPTWId.ptwstatus !== "RETURNED" && getPTWDataByDefectIdPTWId.ptwstatus !== "CANCELLED") && (
                            <>
                                <Button style={{ display: (getPTWDataByDefectIdPTWId.ptwstatus === "TRANSFER ACCEPTED" || getPTWDataByDefectIdPTWId.ptwstatus === "TRANSFER REJECTED" || getPTWDataByDefectIdPTWId.ptwstatus === "TRANSFERRED") ? 'none' : 'inline' }} onClick={handleAcceptTransferPTW}>Receipt Transfer</Button>
                                <Button style={{ display: (getPTWDataByDefectIdPTWId.ptwstatus === "TRANSFER ACCEPTED" || getPTWDataByDefectIdPTWId.ptwstatus === "TRANSFER REJECTED" || getPTWDataByDefectIdPTWId.ptwstatus === "TRANSFERRED") ? 'none' : 'inline' }} onClick={handleRejectTransferPTW}>Reject Transfer</Button>
                            </>
                        )}

                        {(getPTWDataByDefectIdPTWId.ptwstatus === "ACCEPTED" && getPTWDataByDefectIdPTWId.issuinG_EMPLOYEEID === user.UserName) && (
                            <>
                                <Button onClick={handlePtwTranfer}>PTW Transfer</Button>
                                <Button onClick={openOperationAdditionModal}>PTW Test Operation</Button>
                                <Button onClick={openReturnModal}>Return PTW</Button>
                            </>
                        )}

                        {(getPTWDataByDefectIdPTWId.transfeR_APPROVAL_STATUS === "APPROVED" && getPTWDataByDefectIdPTWId.transfrreD_TO_EMPID === user.UserName && (getPTWDataByDefectIdPTWId.ptwstatus !== "RETURNED" && getPTWDataByDefectIdPTWId.ptwstatus !== "CANCELLED")) && (
                            <>
                                <Button onClick={handlePtwTranfer}>PTW Transfer</Button>
                                <Button onClick={openOperationAdditionAfterTransferModal}>PTW Test Operation</Button>
                                <Button onClick={openReturnModal}>Return PTW</Button>
                            </>
                        )}

                        {((getPTWDataByDefectIdPTWId.ptwstatus === "ACCEPTED" && (getPTWDataByDefectIdPTWId.tesT_OP_REQUEST_TO_EMPID === user.UserName)) || (getPTWDataByDefectIdPTWId.ptwstatus === "TRANSFERRED" && getPTWDataByDefectIdPTWId.tesT_OP_REQUEST_TO_EMPID === user.UserName)) && (
                            <Button onClick={openOperationModal} style={{ display: (getPTWDataByDefectIdPTWId.tesT_OP_REQUEST_TO_EMPID === user.UserName) ? 'inline' : 'none' }}>PTW Test Operation</Button>

                        )}

                        {(getPTWDataByDefectIdPTWId.ptwstatus === "RETURNED") && (
                            <Button onClick={openCancelModal} style={{ display: getPTWDataByDefectIdPTWId.returneD_BY_EMPID === user.UserName ? 'inline' : 'none' }}>Close PTW</Button>

                        )}

                        {/* {(getPTWDataByDefectIdPTWId.ptwstatus === "CANCELLED") && (
                            <Button onClick={openAttendModal} style={{ display: (getPTWDataByDefectIdPTWId.transfrreD_TO_EMPID === user.UserName || getPTWDataByDefectIdPTWId.issuinG_EMPLOYEEID === user.UserName) && getPTWDataByDefectIdPTWId.workdonE_EMPID === null ? 'inline' : 'none' }}>Attend Defect</Button>

                        )} */}

                        {getPTWDataByDefectIdPTWId.ptwstatus === "TRANSFER ACCEPTED" && ((user.EMPLOYEELEVEL === 5 && user.DIVISION_NAME === getPTWDataByDefectIdPTWId.transferreD_TO_DIVNAME) || (user.EMPLOYEELEVEL === 6 && getPTWDataByDefectIdPTWId.transferreD_TO_DIVNAME === user.DIVISION_NAME)) && (
                            <>
                                <Button onClick={handleApproveTransfer} style={{ display: getPTWDataByDefectIdPTWId.transfeR_APPROVAL_STATUS === "APPROVED" ? 'none' : 'inline' }}>Approve Transfer</Button>
                            </>
                        )}
                        {/* {user.EMPLOYEELEVEL === 5 && getPTWDataByDefectIdPTWId.attenD_DEFECT_APPROVED_BY_EMP !== null &&
                            getPTWDataByDefectIdPTWId.ptwstatus === "CANCELLED" &&
                            ((user.PROJECTDIVISIONID === parseInt(getPTWDataByDefectIdPTWId.transfrreD_TO_DIVID)) ||
                                (user.PROJECTDIVISIONID === getPTWDataByDefectIdPTWId.issuingDivision)) && (
                                <Button onClick={handleAttendApproval}>Approve Attend Defect</Button>
                            )} */}




                    </Modal.Footer>
                </Modal>
            )}

            {hazardoesModal && hazardoesData.length > 0 && (
                <Modal varient="center"
                    title="View Hazardoes"
                    index={2}
                    show={closeHazardoesModal}
                    width="500px"
                    height="300px">

                    <Modal.Body>
                        <div>
                            <DataGrid rowData={hazardoesData}
                                columnDefs={HazatdoesListViewColumDef} />
                        </div>
                    </Modal.Body>
                </Modal>
            )}

            {equipmentModal && equipmentView.length > 0 && (
                <Modal varient="center"
                    title="View Equipment"
                    index={2}
                    show={closeEquipmentModal}
                    width="500px"
                    height="300px">
                    <Modal.Body>
                        <div>
                            <DataGrid rowData={equipmentView}
                                columnDefs={EquipmentListViewColumDef} />
                        </div>
                    </Modal.Body>
                </Modal>
            )}

            {isolationsModal && (
                <Modal varient="center"
                    title={`Isolations ${getPTWDataByDefectIdPTWId.ptwstatus === 'INITIATED' ? 'Requested' : 'Done'}`}
                    index={2}
                    show={closeIsolationsModal}
                    width="600px"
                    height="400px">
                    <Modal.Body>
                        <div>
                            {(getPTWDataByDefectIdPTWId.ptwstatus === "CANCELLED") && (
                                <DataGrid rowData={isolationData} columnDefs={IsolationListViewColumDef} />
                            )}

                            {getPTWDataByDefectIdPTWId.issuinG_EMPLOYEEID === user.UserName && getPTWDataByDefectIdPTWId.ptwstatus !== "CANCELLED" && (
                                <>
                                    <h6 className="my-2 mx-3">Requested Isolations :</h6>

                                    {
                                        isolationData.map((data, index) => (
                                            <div key={index} style={{ display: 'flex', alignItems: 'center' }} className="mx-2 my-2">
                                                {(getPTWDataByDefectIdPTWId.ptwstatus !== "CANCELLED") && (
                                                    <>
                                                        <ul>
                                                            <li> <p style={{ fontWeight: 'bold', marginRight: '20px' }} className="m-0">{data.EHIVALUE}</p></li>

                                                        </ul>


                                                    </>
                                                )}
                                            </div>
                                        ))
                                    }
                                </>
                            )}

                            {getPTWDataByDefectIdPTWId.issuinG_EMPLOYEEID !== user.UserName && (getPTWDataByDefectIdPTWId.ptwstatus !== "CANCELLED") && (
                                <>
                                    {
                                        isolationData.map((data, index) => (
                                            <div key={index} style={{ display: 'flex', alignItems: 'center' }} className="mx-2 my-2">
                                                {(getPTWDataByDefectIdPTWId.ptwstatus !== "CANCELLED") && (
                                                    <>
                                                        <p style={{ fontWeight: 'bold', marginRight: '10px' }} className="m-0">{data.EHIVALUE}</p>
                                                        <input
                                                            type="checkbox"
                                                            id={`txtDivisionNameCheckbox${index}`}
                                                            maxLength="200"
                                                            className="m-2"
                                                            onChange={() => handleCheckboxChange(index)}
                                                            checked={data.ISO_NORMALIZE_REQUEST === "Y" ? true : isChecked[index]}
                                                        />
                                                        <input
                                                            type="text"
                                                            maxLength="200"
                                                            placeholder="Please Enter Remarks"
                                                            value={remarks[index]}
                                                            onChange={(e) => handleRemarksChange(e, index)}

                                                        />
                                                    </>
                                                )}
                                            </div>
                                        ))
                                    }
                                </>


                            )}

                            {(getPTWDataByDefectIdPTWId.ptwstatus !== "CANCELLED") && (
                                <>
                                    <h6 className="my-2 mx-3" style={{ display: additionalFields.length > 0 ? 'inline' : 'none' }}>Additional Isolations</h6>
                                    {additionalFields.map((field, index) => (
                                        <div key={index + isolationInputs.length} style={{ display: 'flex', alignItems: 'center' }}>

                                            <p className="m-2"><strong>Isolation {index + isolationData.length + 1}</strong></p>
                                            <input
                                                type="text"
                                                maxLength="200"
                                                placeholder="Please Enter Isolation Details"
                                                value={Uremarks[index]}
                                                style={{ width: '350px', height: '30px' }}
                                                onChange={(e) => handleEnterRemarksChange(e, index)}
                                            />
                                            <Button onClick={() => handleRemoveField(index)} className="m-2">-</Button>
                                        </div>
                                    ))}
                                </>
                            )}
                            {(getPTWDataByDefectIdPTWId.ptwstatus === "CANCELLED") && (
                                <>
                                    {additionalFields.map((field, index) => (
                                        <div key={index + isolationInputs.length} style={{ display: 'flex', alignItems: 'center' }}>
                                            <p><strong>Isolation {isolationData.length + 1}</strong></p>
                                        </div>
                                    ))}
                                </>
                            )}
                        </div>

                        {(getPTWDataByDefectIdPTWId.ptwstatus !== "CANCELLED") && (getPTWDataByDefectIdPTWId.ptW_INITIATED_TO_EMPID === user.UserName || getPTWDataByDefectIdPTWId.issuinG_EMPLOYEEID === user.UserName || getPTWDataByDefectIdPTWId.transfrreD_TO_EMPID === user.UserName) && (
                            <>
                                <div className="d-flex flex-column justify-content-end px-2">
                                    <span>Note: Please Click on the Checkbox once Isolation is done.</span>
                                    <span>To Add new Isolations click on + button.</span>
                                </div></>
                        )}



                    </Modal.Body>
                    <Modal.Footer>
                        {(getPTWDataByDefectIdPTWId.ptwstatus !== "CANCELLED") && (getPTWDataByDefectIdPTWId.ptW_INITIATED_TO_EMPID === user.UserName || getPTWDataByDefectIdPTWId.issuinG_EMPLOYEEID === user.UserName || getPTWDataByDefectIdPTWId.transfrreD_TO_EMPID === user.UserName) && (
                            <>
                                <Button onClick={handleIsolationInput} >+</Button>
                                <Button onClick={handleIsolationSave}>Save</Button>
                            </>
                        )}
                    </Modal.Footer>
                </Modal>
            )}

            {issue && (
                <Modal varient="center"
                    title="Issue PTW"
                    index={2}
                    show={closeIssueModal}
                    width="1000px"
                    height="850px">
                    <Modal.Body>

                        <div>
                            <Form>
                                <Form.Group column={2} controlwidth="240px">

                                    <Form.InputGroup label="" column={2}>
                                        <h6>Defect Details</h6>
                                    </Form.InputGroup>
                                    <Form.InputGroup label="" column={2}>
                                    </Form.InputGroup>
                                    <Form.InputGroup label="Defect ID">
                                        <Form.InputControl
                                            control="input"
                                            name="txtDefectId"
                                            maxLength="200"
                                            value={getPTWDataByDefectIdPTWId.defectid}
                                            readOnly
                                        />


                                    </Form.InputGroup>
                                    <Form.InputGroup label="Defect Status">
                                        <Form.InputControl control="input" name="txtdefctid" maxLength="200" value={defectData.status} readOnly />

                                    </Form.InputGroup>

                                    <Form.InputGroup label="Asset ID">
                                        <Form.InputControl control="input" name="txtdefctid" maxLength="200" value={defectData.childAssetId} readOnly />
                                    </Form.InputGroup>

                                    <Form.InputGroup label="Asset Name">
                                        <Form.InputControl control="input" name="txtdefctid" maxLength="200" value={defectData.childAssetDescription} readOnly />
                                    </Form.InputGroup>

                                    <Form.InputGroup label="Unit Name">
                                        <Form.InputControl control="input" name="txtdefctid" maxLength="200" value={defectData.unitName} readOnly />
                                    </Form.InputGroup>

                                    <Form.InputGroup label="Priority">
                                        <Form.InputControl control="input" name="txtdefctid" maxLength="200" value={defectData.priorityTypeName} readOnly />
                                    </Form.InputGroup>

                                    <Form.InputGroup label="Defect Type">
                                        <Form.InputControl control="input" name="txtdefctid" maxLength="200" value={defectData.defecttypE_NAME} readOnly />
                                    </Form.InputGroup>

                                    <Form.InputGroup label="Reason">
                                        <Form.InputControl control="input" name="txtdefctid" maxLength="200" value={defectData.reasonfordefect} readOnly />
                                    </Form.InputGroup>

                                    <Form.InputGroup label="Description">
                                        <Form.InputControl control="input" name="txtdefctid" maxLength="200" value={defectData.description} readOnly />
                                    </Form.InputGroup>

                                    <Form.InputGroup label="Defect Logged By">
                                        <Form.InputControl control="input" name="txtdefctid" maxLength="200" value={`${defectData.employeeName} (${defectData.defectloggedby})`} readOnly />
                                    </Form.InputGroup>


                                    <Form.InputGroup label="Defect Logged On">
                                        <Form.InputControl control="input" name="txtdefctid" maxLength="200" value={formatDates(defectData.defectLoggedOn)} readOnly />
                                    </Form.InputGroup>


                                    <Form.InputGroup label="Attending Division">
                                        <Form.InputControl control="input" name="txtdefctid" maxLength="200" value={defectData.attendingDivisionName} readOnly />
                                    </Form.InputGroup>

                                    <Form.InputGroup label="" column={2}>
                                        <h6>PTW Details</h6>
                                    </Form.InputGroup>


                                    <Form.InputGroup label="" column={2}>
                                    </Form.InputGroup>

                                    <Form.InputGroup label="PTW ID">
                                        <Form.InputControl
                                            control="input"
                                            name="txtDefectId"
                                            maxLength="200"
                                            value={getPTWDataByDefectIdPTWId.ptW_ID}
                                            readOnly
                                        />


                                    </Form.InputGroup>

                                    <Form.InputGroup label="PTW Status">
                                        <Form.InputControl
                                            control="input"
                                            name="txtDefectId"
                                            maxLength="200"
                                            value={getPTWDataByDefectIdPTWId.ptwstatus}
                                            readOnly
                                        />


                                    </Form.InputGroup>
                                    <Form.InputGroup label="PTW Work Type">
                                        <Form.InputControl
                                            control="input"
                                            name="txtDefectId"
                                            maxLength="200"
                                            value={getPTWDataByDefectIdPTWId.worK_NAME}
                                            readOnly
                                        />


                                    </Form.InputGroup>
                                    <Form.InputGroup label="PTW Description">
                                        <Form.InputControl
                                            control="input"
                                            name="txtDefectId"
                                            maxLength="200"
                                            value={getPTWDataByDefectIdPTWId.ptW_DESCRIPTION}
                                            readOnly
                                        />


                                    </Form.InputGroup>
                                    <Form.InputGroup label="PTW Requested By">
                                        <Form.InputControl
                                            control="input"
                                            name="txtDefectId"
                                            maxLength="200"
                                            value={`${getPTWDataByDefectIdPTWId.issuinG_EMPLOYEENAME} (${getPTWDataByDefectIdPTWId.issuingAuthority})`}
                                            readOnly
                                        />


                                    </Form.InputGroup>
                                    <Form.InputGroup label="PTW Requested By Division">
                                        <Form.InputControl
                                            control="input"
                                            name="txtDefectId"
                                            maxLength="200"
                                            value={getPTWDataByDefectIdPTWId.divisionname}
                                            readOnly
                                        />


                                    </Form.InputGroup>
                                    <Form.InputGroup label="PTW Requested To">
                                        <Form.InputControl
                                            control="input"
                                            name="txtDefectId"
                                            maxLength="200"
                                            value={`${getPTWDataByDefectIdPTWId.ptW_INITIATED_TO_EMPNAME} (${getPTWDataByDefectIdPTWId.ptW_INITIATED_TO_EMPID})`}
                                            readOnly
                                        />


                                    </Form.InputGroup>
                                    <Form.InputGroup label="PTW Requested To Division">
                                        <Form.InputControl
                                            control="input"
                                            name="txtDefectId"
                                            maxLength="200"
                                            value={getPTWDataByDefectIdPTWId.ptW_INITIATED_TO_DIVID}
                                            readOnly
                                        />
                                    </Form.InputGroup>


                                    <Form.InputGroup label="From Date">
                                        <Form.InputControl
                                            control="input"
                                            name="txtDefectId"
                                            maxLength="200"
                                            value={(getPTWDataByDefectIdPTWId.froM_DATE)}
                                            readOnly
                                        />


                                    </Form.InputGroup>

                                    <Form.InputGroup label="To Date">
                                        <Form.InputControl
                                            control="input"
                                            name="txtDefectId"
                                            maxLength="200"
                                            value={(getPTWDataByDefectIdPTWId.tO_DATE)}
                                            readOnly
                                        />
                                    </Form.InputGroup>


                                    {getPTWDataByDefectIdPTWId.acknowledgE_STATUS === "Y" && (
                                        <>

                                            <Form.InputGroup label="" column={2}>
                                                <h6>Acknowledge PTW Details</h6>
                                            </Form.InputGroup>

                                            <Form.InputGroup label="" column={2}>
                                            </Form.InputGroup>


                                            <Form.InputGroup label="PTW Acknowledged By Employee">
                                                <Form.InputControl
                                                    control="input"
                                                    name="txtDefectId"
                                                    maxLength="200"
                                                    value={`${getPTWDataByDefectIdPTWId.acknowledgE_BY_EMPNAME} (${getPTWDataByDefectIdPTWId.acknowledgE_BY_EMPID})`}
                                                    readOnly
                                                />
                                            </Form.InputGroup>
                                            <Form.InputGroup label="PTW Acknowledged Division">
                                                <Form.InputControl
                                                    control="input"
                                                    name="txtDefectId"
                                                    maxLength="200"
                                                    value={getPTWDataByDefectIdPTWId.acknowledgeD_BY_DIVNAME}
                                                    readOnly
                                                />
                                            </Form.InputGroup>
                                            <Form.InputGroup label="PTW Acknowledged Date Time">
                                                <Form.InputControl
                                                    control="input"
                                                    name="txtDefectId"
                                                    maxLength="200"
                                                    value={getPTWDataByDefectIdPTWId.acknowledgE_TIME}
                                                    readOnly
                                                />
                                            </Form.InputGroup>
                                        </>

                                    )}

                                    {getPTWDataByDefectIdPTWId.issueD_TO_EMPID !== null && (
                                        <>
                                            <Form.InputGroup label="" column={2}>
                                            </Form.InputGroup>

                                            <Form.InputGroup label="" column={2}>  <h6>PTW Issued Details</h6>
                                            </Form.InputGroup>
                                            <Form.InputGroup label="" column={2}>
                                            </Form.InputGroup>




                                            <Form.InputGroup label="PTW Issued By Employee">
                                                <Form.InputControl
                                                    control="input"
                                                    name="txtDefectId"
                                                    maxLength="200"
                                                    value={`${getPTWDataByDefectIdPTWId.issueD_TO_EMPNAME} (${getPTWDataByDefectIdPTWId.issueD_TO_EMPID})`}
                                                    readOnly
                                                />
                                            </Form.InputGroup>
                                            <Form.InputGroup label="PTW Issued Division">
                                                <Form.InputControl
                                                    control="input"
                                                    name="txtDefectId"
                                                    maxLength="200"
                                                    value={getPTWDataByDefectIdPTWId.issuinG_DIVNAME}
                                                    readOnly
                                                />
                                            </Form.InputGroup>
                                            <Form.InputGroup label="PTW Issued Date Time">
                                                <Form.InputControl
                                                    control="input"
                                                    name="txtDefectId"
                                                    maxLength="200"
                                                    value={getPTWDataByDefectIdPTWId.issueD_DATETIME}
                                                    readOnly
                                                />
                                            </Form.InputGroup>
                                            <Form.InputGroup label="PTW Issued Remarks">
                                                <Form.InputControl
                                                    control="input"
                                                    name="txtDefectId"
                                                    maxLength="200"
                                                    value={getPTWDataByDefectIdPTWId.issuE_REMARKS}
                                                    readOnly
                                                />
                                            </Form.InputGroup>
                                        </>

                                    )}
                                    {getPTWDataByDefectIdPTWId.rejecteD_EMPID !== null && (
                                        <>
                                            <Form.InputGroup label="" column={2}>
                                            </Form.InputGroup>
                                            <Form.InputGroup label="" column={2}>    <h6>PTW Rejected Details</h6>
                                            </Form.InputGroup>
                                            <Form.InputGroup label="" column={2}>
                                            </Form.InputGroup>


                                            <Form.InputGroup label="PTW Rejected By">
                                                <Form.InputControl
                                                    control="input"
                                                    name="txtDefectId"
                                                    maxLength="200"
                                                    value={`${getPTWDataByDefectIdPTWId.rejecteD_EMPNAME} (${getPTWDataByDefectIdPTWId.rejecteD_EMPID})`}
                                                    readOnly
                                                />
                                            </Form.InputGroup>

                                            <Form.InputGroup label="PTW Rejected By Division">
                                                <Form.InputControl
                                                    control="input"
                                                    name="txtDefectId"
                                                    maxLength="200"
                                                    value={getPTWDataByDefectIdPTWId.rejecteD_DIVNAME}
                                                    readOnly
                                                />
                                            </Form.InputGroup>

                                            <Form.InputGroup label="PTW Rejected Date Time">
                                                <Form.InputControl
                                                    control="input"
                                                    name="txtDefectId"
                                                    maxLength="200"
                                                    value={getPTWDataByDefectIdPTWId.returN_DATEENTRY}
                                                    readOnly
                                                />
                                            </Form.InputGroup>
                                        </>

                                    )}

                                    {getPTWDataByDefectIdPTWId.accepteD_EMPID !== null && (
                                        <>
                                            <Form.InputGroup label="" column={2}>
                                            </Form.InputGroup>
                                            <Form.InputGroup label="" column={2}>    <h6>PTW Accepted Details</h6>
                                            </Form.InputGroup>
                                            <Form.InputGroup label="" column={2}>
                                            </Form.InputGroup>

                                            <Form.InputGroup label="PTW Accepted By">
                                                <Form.InputControl
                                                    control="input"
                                                    name="txtDefectId"
                                                    maxLength="200"
                                                    value={`${getPTWDataByDefectIdPTWId.accepteD_EMPNAME} (${getPTWDataByDefectIdPTWId.accepteD_EMPID})`}
                                                    readOnly
                                                />
                                            </Form.InputGroup>

                                            <Form.InputGroup label="PTW Accepted By Division">
                                                <Form.InputControl
                                                    control="input"
                                                    name="txtDefectId"
                                                    maxLength="200"
                                                    value={getPTWDataByDefectIdPTWId.accepteD_DIVNAME}
                                                    readOnly
                                                />
                                            </Form.InputGroup>
                                            <Form.InputGroup label="PTW Accepted Date Time">
                                                <Form.InputControl
                                                    control="input"
                                                    name="txtDefectId"
                                                    maxLength="200"
                                                    value={getPTWDataByDefectIdPTWId.accepteD_DATEENTRY}
                                                    readOnly
                                                />
                                            </Form.InputGroup>

                                        </>

                                    )}

                                    {getPTWDataByDefectIdPTWId.returneD_BY_EMPNAME !== null && (
                                        <>
                                            <Form.InputGroup label="" column={2}>
                                            </Form.InputGroup>
                                            <Form.InputGroup label="" column={2}>    <h6>PTW Return Details</h6>
                                            </Form.InputGroup>
                                            <Form.InputGroup label="" column={2}>
                                            </Form.InputGroup>

                                            <Form.InputGroup label="PTW Return By">
                                                <Form.InputControl
                                                    control="input"
                                                    name="txtDefectId"
                                                    maxLength="200"
                                                    value={`${getPTWDataByDefectIdPTWId.returneD_BY_EMPNAME} (${getPTWDataByDefectIdPTWId.returneD_BY_EMPID})`}
                                                    readOnly
                                                />
                                            </Form.InputGroup>

                                            <Form.InputGroup label="PTW Return By Division">
                                                <Form.InputControl
                                                    control="input"
                                                    name="txtDefectId"
                                                    maxLength="200"
                                                    value=""
                                                    readOnly
                                                />
                                            </Form.InputGroup>
                                            <Form.InputGroup label="PTW Return Date Time">
                                                <Form.InputControl
                                                    control="input"
                                                    name="txtDefectId"
                                                    maxLength="200"
                                                    value={getPTWDataByDefectIdPTWId.returN_DATEENTRY}
                                                    readOnly
                                                />
                                            </Form.InputGroup>

                                        </>

                                    )}

                                    {getPTWDataByDefectIdPTWId.ptwstatus === 'TRANSFERRED' && (

                                        <>
                                            <Form.InputGroup label="" column={2}>
                                            </Form.InputGroup>
                                            <Form.InputGroup label="" column={2}>    <h6>PTW Transferred Details</h6>
                                            </Form.InputGroup>
                                            <Form.InputGroup label="" column={2}>
                                            </Form.InputGroup>
                                            <Form.InputGroup label="Transferred to Employee">
                                                <Form.InputControl
                                                    control="input"
                                                    name="txtDefectId"
                                                    maxLength="200"
                                                    value={`${getPTWDataByDefectIdPTWId.transfrreD_TO_EMPNAME} (${getPTWDataByDefectIdPTWId.transfrreD_TO_EMPID})`}
                                                    readOnly
                                                />
                                            </Form.InputGroup>
                                            <Form.InputGroup label="Transferred to Division">
                                                <Form.InputControl
                                                    control="input"
                                                    name="txtDefectId"
                                                    maxLength="200"
                                                    value={getPTWDataByDefectIdPTWId.transferreD_TO_DIVNAME}
                                                    readOnly
                                                />
                                            </Form.InputGroup>
                                            <Form.InputGroup label="Transferred Date Time">
                                                <Form.InputControl
                                                    control="input"
                                                    name="txtDefectId"
                                                    maxLength="200"
                                                    value={getPTWDataByDefectIdPTWId.transfrreD_DATE}
                                                    readOnly
                                                />
                                            </Form.InputGroup>
                                        </>
                                    )}

                                    <Form.InputGroup label="" column={2}>
                                    </Form.InputGroup>

                                    <Form.InputGroup label="PTW Issue Remarks">
                                        <Form.InputControl
                                            control="textarea"
                                            name="txtDefectId"
                                            maxLength="200"
                                            value={issueRemarks}
                                            style={{ height: '100px' }}
                                            placeholder="Enter Remarks"
                                            onChange={(e) => handleIssueData(e, "Remarks")}

                                        />
                                    </Form.InputGroup>






                                </Form.Group>

                            </Form>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>
                        <button onClick={(e) => saveIssuePTW(e)} className="btn btn-primary">Issue PTW</button>
                    </Modal.Footer>
                </Modal>

            )}

            {reject && (
                <Modal varient="center"
                    title="Reject PTW"
                    index={2}
                    show={closeRejectModal}
                    width="1000px"
                    height="850px">
                    <Modal.Body>
                        <div>
                            <Form>
                                <Form.Group column={2} controlwidth="240px">
                                    <Form.InputGroup label="" column={2}>
                                        <h6>Defect Details</h6>
                                    </Form.InputGroup>
                                    <Form.InputGroup label="" column={2}>
                                    </Form.InputGroup>
                                    <Form.InputGroup label="Defect ID">
                                        <Form.InputControl
                                            control="input"
                                            name="txtDefectId"
                                            maxLength="200"
                                            value={getPTWDataByDefectIdPTWId.defectid}
                                            readOnly
                                        />
                                    </Form.InputGroup>
                                    <Form.InputGroup label="Defect Status">
                                        <Form.InputControl control="input" name="txtdefctid" maxLength="200" value={defectData.status} readOnly />
                                    </Form.InputGroup>
                                    <Form.InputGroup label="Asset ID">
                                        <Form.InputControl control="input" name="txtdefctid" maxLength="200" value={defectData.childAssetId} readOnly />
                                    </Form.InputGroup>
                                    <Form.InputGroup label="Asset Name">
                                        <Form.InputControl control="input" name="txtdefctid" maxLength="200" value={defectData.childAssetDescription} readOnly />
                                    </Form.InputGroup>
                                    <Form.InputGroup label="Unit Name">
                                        <Form.InputControl control="input" name="txtdefctid" maxLength="200" value={defectData.unitName} readOnly />
                                    </Form.InputGroup>
                                    <Form.InputGroup label="Priority">
                                        <Form.InputControl control="input" name="txtdefctid" maxLength="200" value={defectData.priorityTypeName} readOnly />
                                    </Form.InputGroup>
                                    <Form.InputGroup label="Defect Type">
                                        <Form.InputControl control="input" name="txtdefctid" maxLength="200" value={defectData.defecttypE_NAME} readOnly />
                                    </Form.InputGroup>
                                    <Form.InputGroup label="Reason">
                                        <Form.InputControl control="input" name="txtdefctid" maxLength="200" value={defectData.reasonfordefect} readOnly />
                                    </Form.InputGroup>
                                    <Form.InputGroup label="Description">
                                        <Form.InputControl control="input" name="txtdefctid" maxLength="200" value={defectData.description} readOnly />
                                    </Form.InputGroup>
                                    <Form.InputGroup label="Defect Logged By">
                                        <Form.InputControl control="input" name="txtdefctid" maxLength="200" value={`${defectData.employeeName} (${defectData.defectloggedby})`} readOnly />
                                    </Form.InputGroup>
                                    <Form.InputGroup label="Defect Logged On">
                                        <Form.InputControl control="input" name="txtdefctid" maxLength="200" value={formatDates(defectData.defectLoggedOn)} readOnly />
                                    </Form.InputGroup>
                                    <Form.InputGroup label="Attending Division">
                                        <Form.InputControl control="input" name="txtdefctid" maxLength="200" value={defectData.attendingDivisionName} readOnly />
                                    </Form.InputGroup>
                                    <Form.InputGroup label="PTW ID">
                                        <Form.InputControl
                                            control="input"
                                            name="txtDefectId"
                                            maxLength="200"
                                            value={getPTWDataByDefectIdPTWId.ptW_ID}
                                            readOnly
                                        />
                                    </Form.InputGroup>
                                    <Form.InputGroup label="PTW Status">
                                        <Form.InputControl
                                            control="input"
                                            name="txtDefectId"
                                            maxLength="200"
                                            value={getPTWDataByDefectIdPTWId.ptwstatus}
                                            readOnly
                                        />
                                    </Form.InputGroup>
                                    <Form.InputGroup label="PTW Work Type">
                                        <Form.InputControl
                                            control="input"
                                            name="txtDefectId"
                                            maxLength="200"
                                            value={getPTWDataByDefectIdPTWId.worK_NAME}
                                            readOnly
                                        />
                                    </Form.InputGroup>
                                    <Form.InputGroup label="PTW Description">
                                        <Form.InputControl
                                            control="input"
                                            name="txtDefectId"
                                            maxLength="200"
                                            value={getPTWDataByDefectIdPTWId.ptW_DESCRIPTION}
                                            readOnly
                                        />
                                    </Form.InputGroup>
                                    <Form.InputGroup label="PTW Requested By">
                                        <Form.InputControl
                                            control="input"
                                            name="txtDefectId"
                                            maxLength="200"
                                            value={`${getPTWDataByDefectIdPTWId.issuinG_EMPLOYEENAME} (${getPTWDataByDefectIdPTWId.issuingAuthority})`}
                                            readOnly
                                        />
                                    </Form.InputGroup>
                                    <Form.InputGroup label="PTW Requested By Division">
                                        <Form.InputControl
                                            control="input"
                                            name="txtDefectId"
                                            maxLength="200"
                                            value={getPTWDataByDefectIdPTWId.divisionname}
                                            readOnly
                                        />
                                    </Form.InputGroup>
                                    <Form.InputGroup label="PTW Requested To">
                                        <Form.InputControl
                                            control="input"
                                            name="txtDefectId"
                                            maxLength="200"
                                            value={`${getPTWDataByDefectIdPTWId.ptW_INITIATED_TO_EMPNAME} (${getPTWDataByDefectIdPTWId.ptW_INITIATED_TO_EMPID})`}
                                            readOnly
                                        />
                                    </Form.InputGroup>
                                    <Form.InputGroup label="PTW Requested To Division">
                                        <Form.InputControl
                                            control="input"
                                            name="txtDefectId"
                                            maxLength="200"
                                            value={getPTWDataByDefectIdPTWId.ptW_INITIATED_TO_DIVID}
                                            readOnly
                                        />
                                    </Form.InputGroup>
                                    <Form.InputGroup label="From Date">
                                        <Form.InputControl
                                            control="input"
                                            name="txtDefectId"
                                            maxLength="200"
                                            value={formatDate(getPTWDataByDefectIdPTWId.froM_DATE)}
                                            readOnly
                                        />
                                    </Form.InputGroup>
                                    <Form.InputGroup label="To Date">
                                        <Form.InputControl
                                            control="input"
                                            name="txtDefectId"
                                            maxLength="200"
                                            value={formatDate(getPTWDataByDefectIdPTWId.tO_DATE)}
                                            readOnly
                                        />
                                    </Form.InputGroup>
                                    {getPTWDataByDefectIdPTWId.acknowledgE_STATUS === "Y" && (
                                        <>
                                            <Form.InputGroup label="" column={2}>
                                                <h6>Acknowledge PTW Details</h6>
                                            </Form.InputGroup>
                                            <Form.InputGroup label="" column={2}>
                                            </Form.InputGroup>
                                            <Form.InputGroup label="PTW Acknowledged By Employee">
                                                <Form.InputControl
                                                    control="input"
                                                    name="txtDefectId"
                                                    maxLength="200"
                                                    value={`${getPTWDataByDefectIdPTWId.acknowledgE_BY_EMPNAME} (${getPTWDataByDefectIdPTWId.acknowledgE_BY_EMPID})`}
                                                    readOnly
                                                />
                                            </Form.InputGroup>
                                            <Form.InputGroup label="PTW Acknowledged Division">
                                                <Form.InputControl
                                                    control="input"
                                                    name="txtDefectId"
                                                    maxLength="200"
                                                    value={getPTWDataByDefectIdPTWId.acknowledgeD_BY_DIVNAME}
                                                    readOnly
                                                />
                                            </Form.InputGroup>
                                            <Form.InputGroup label="PTW Acknowledged Date Time">
                                                <Form.InputControl
                                                    control="input"
                                                    name="txtDefectId"
                                                    maxLength="200"
                                                    value={getPTWDataByDefectIdPTWId.acknowledgE_TIME}
                                                    readOnly
                                                />
                                            </Form.InputGroup>
                                        </>
                                    )}
                                    {getPTWDataByDefectIdPTWId.issueD_TO_EMPID !== null && (
                                        <>
                                            <Form.InputGroup label="" column={2}>
                                            </Form.InputGroup>
                                            <Form.InputGroup label="" column={2}>
                                                <h6>PTW Issued Details</h6>
                                            </Form.InputGroup>
                                            <Form.InputGroup label="" column={2}>
                                            </Form.InputGroup>
                                            <Form.InputGroup label="PTW Issued By Employee">
                                                <Form.InputControl
                                                    control="input"
                                                    name="txtDefectId"
                                                    maxLength="200"
                                                    value={`${getPTWDataByDefectIdPTWId.issueD_TO_EMPNAME} (${getPTWDataByDefectIdPTWId.issueD_TO_EMPID})`}
                                                    readOnly
                                                />
                                            </Form.InputGroup>
                                            <Form.InputGroup label="PTW Issued Division">
                                                <Form.InputControl
                                                    control="input"
                                                    name="txtDefectId"
                                                    maxLength="200"
                                                    value={getPTWDataByDefectIdPTWId.issuinG_DIVNAME}
                                                    readOnly
                                                />
                                            </Form.InputGroup>
                                            <Form.InputGroup label="PTW Issued Date Time">
                                                <Form.InputControl
                                                    control="input"
                                                    name="txtDefectId"
                                                    maxLength="200"
                                                    value={getPTWDataByDefectIdPTWId.issueD_DATETIME}
                                                    readOnly
                                                />
                                            </Form.InputGroup>
                                            <Form.InputGroup label="PTW Issued Remarks">
                                                <Form.InputControl
                                                    control="input"
                                                    name="txtDefectId"
                                                    maxLength="200"
                                                    value={getPTWDataByDefectIdPTWId.issuE_REMARKS}
                                                    readOnly
                                                />
                                            </Form.InputGroup>
                                        </>
                                    )}
                                    {getPTWDataByDefectIdPTWId.rejecteD_EMPID !== null && (
                                        <>
                                            <Form.InputGroup label="" column={2}>
                                            </Form.InputGroup>
                                            <Form.InputGroup label="" column={2}>
                                                <h6>PTW Rejected Details</h6>
                                            </Form.InputGroup>
                                            <Form.InputGroup label="" column={2}>
                                            </Form.InputGroup>
                                            <Form.InputGroup label="PTW Rejected By">
                                                <Form.InputControl
                                                    control="input"
                                                    name="txtDefectId"
                                                    maxLength="200"
                                                    value={`${getPTWDataByDefectIdPTWId.rejecteD_EMPNAME} (${getPTWDataByDefectIdPTWId.rejecteD_EMPID})`}
                                                    readOnly
                                                />
                                            </Form.InputGroup>
                                            <Form.InputGroup label="PTW Rejected By Division">
                                                <Form.InputControl
                                                    control="input"
                                                    name="txtDefectId"
                                                    maxLength="200"
                                                    value={getPTWDataByDefectIdPTWId.rejecteD_DIVNAME}
                                                    readOnly
                                                />
                                            </Form.InputGroup>
                                            <Form.InputGroup label="PTW Rejected Date Time">
                                                <Form.InputControl
                                                    control="input"
                                                    name="txtDefectId"
                                                    maxLength="200"
                                                    value={getPTWDataByDefectIdPTWId.returN_DATEENTRY}
                                                    readOnly
                                                />
                                            </Form.InputGroup>
                                        </>
                                    )}
                                    {getPTWDataByDefectIdPTWId.accepteD_EMPID !== null && (
                                        <>
                                            <Form.InputGroup label="" column={2}>
                                            </Form.InputGroup>
                                            <Form.InputGroup label="" column={2}>
                                                <h6>PTW Accepted Details</h6>
                                            </Form.InputGroup>
                                            <Form.InputGroup label="" column={2}>
                                            </Form.InputGroup>
                                            <Form.InputGroup label="PTW Accepted By">
                                                <Form.InputControl
                                                    control="input"
                                                    name="txtDefectId"
                                                    maxLength="200"
                                                    value={`${getPTWDataByDefectIdPTWId.accepteD_EMPNAME} (${getPTWDataByDefectIdPTWId.accepteD_EMPID})`}
                                                    readOnly
                                                />
                                            </Form.InputGroup>
                                            <Form.InputGroup label="PTW Accepted By Division">
                                                <Form.InputControl
                                                    control="input"
                                                    name="txtDefectId"
                                                    maxLength="200"
                                                    value={getPTWDataByDefectIdPTWId.accepteD_DIVNAME}
                                                    readOnly
                                                />
                                            </Form.InputGroup>
                                            <Form.InputGroup label="PTW Accepted Date Time">
                                                <Form.InputControl
                                                    control="input"
                                                    name="txtDefectId"
                                                    maxLength="200"
                                                    value={getPTWDataByDefectIdPTWId.accepteD_DATEENTRY}
                                                    readOnly
                                                />
                                            </Form.InputGroup>
                                        </>
                                    )}
                                    {getPTWDataByDefectIdPTWId.returneD_BY_EMPNAME !== null && (
                                        <>
                                            <Form.InputGroup label="" column={2}>
                                            </Form.InputGroup>
                                            <Form.InputGroup label="" column={2}>    <h6>PTW Return Details</h6>
                                            </Form.InputGroup>
                                            <Form.InputGroup label="" column={2}>
                                            </Form.InputGroup>
                                            <Form.InputGroup label="PTW Return By">
                                                <Form.InputControl
                                                    control="input"
                                                    name="txtDefectId"
                                                    maxLength="200"
                                                    value={`${getPTWDataByDefectIdPTWId.returneD_BY_EMPNAME} (${getPTWDataByDefectIdPTWId.returneD_BY_EMPID})`}
                                                    readOnly
                                                />
                                            </Form.InputGroup>
                                            <Form.InputGroup label="PTW Return By Division">
                                                <Form.InputControl
                                                    control="input"
                                                    name="txtDefectId"
                                                    maxLength="200"
                                                    value=""
                                                    readOnly
                                                />
                                            </Form.InputGroup>
                                            <Form.InputGroup label="PTW Return Date Time">
                                                <Form.InputControl
                                                    control="input"
                                                    name="txtDefectId"
                                                    maxLength="200"
                                                    value={getPTWDataByDefectIdPTWId.returN_DATEENTRY}
                                                    readOnly
                                                />
                                            </Form.InputGroup>
                                        </>
                                    )}

                                    {getPTWDataByDefectIdPTWId.ptwstatus === 'TRANSFERRED' && (
                                        <>
                                            <Form.InputGroup label="" column={2}>
                                            </Form.InputGroup>
                                            <Form.InputGroup label="" column={2}>    <h6>PTW Transferred Details</h6>
                                            </Form.InputGroup>
                                            <Form.InputGroup label="" column={2}>
                                            </Form.InputGroup>
                                            <Form.InputGroup label="Transferred to Employee">
                                                <Form.InputControl
                                                    control="input"
                                                    name="txtDefectId"
                                                    maxLength="200"
                                                    value={`${getPTWDataByDefectIdPTWId.transfrreD_TO_EMPNAME} (${getPTWDataByDefectIdPTWId.transfrreD_TO_EMPID})`}
                                                    readOnly
                                                />
                                            </Form.InputGroup>
                                            <Form.InputGroup label="Transferred to Division">
                                                <Form.InputControl
                                                    control="input"
                                                    name="txtDefectId"
                                                    maxLength="200"
                                                    value={getPTWDataByDefectIdPTWId.transferreD_TO_DIVNAME}
                                                    readOnly
                                                />
                                            </Form.InputGroup>
                                            <Form.InputGroup label="Transferred Date Time">
                                                <Form.InputControl
                                                    control="input"
                                                    name="txtDefectId"
                                                    maxLength="200"
                                                    value={getPTWDataByDefectIdPTWId.transfrreD_DATE}
                                                    readOnly
                                                />
                                            </Form.InputGroup>
                                        </>
                                    )}
                                    <Form.InputGroup label="" column={2}>
                                    </Form.InputGroup>
                                    <Form.InputGroup label="PTW Reject Remarks">
                                        <Form.InputControl
                                            control="textarea"
                                            name="txtDefectId"
                                            maxLength="200"
                                            style={{ height: '100px' }}
                                            value={rejectRemarks}
                                            placeholder="Enter Remarks"
                                            onChange={(e) => handleRejectData(e, "Remarks")}

                                        />
                                    </Form.InputGroup>

                                    {/* <Form.InputGroup label="RCA Status">
                                        <Form.InputControl
                                            control="select"
                                            name="txtDefectId"
                                            maxLength="200"
                                            options={rejectrcaData.map((option, index) => ({
                                                label: option.option,
                                                value: option.value
                                            }))}
                                            onChange={(e) => handleIssueData(e, "RCASTATUS")}

                                        />
                                    </Form.InputGroup> */}
                                </Form.Group>
                                <div className="d-flex"> <input type="checkbox" checked={rejecttermsChecked}
                                    onChange={(e) => setrejectTermsChecked(e.target.checked)} /> <p className="m-2">I hereby declare that all men and materials have been withdrawn and all tools and additional earths have been removed and the equipment is in fit condition to be charged. All isolations carried out by me are normalized.</p></div>
                            </Form>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-primary" onClick={(e) => saveRejectPTW(e)} disabled={!rejecttermsChecked}>Reject PTW</button>
                    </Modal.Footer>
                </Modal>
            )}

            {acceptModal && (
                <Modal varient="center"
                    title="Receipt PTW"
                    index={2}
                    show={closeAcceptModal}
                    width="780px"
                    height="580px">
                    <Modal.Body>
                        <Form>
                            <Form.Group controlwidth="340px">
                                {isolationData.length > 0 && (
                                    <Form.InputGroup label="Isolations Done">
                                        <ul>

                                            <>
                                                {
                                                    isolationData.map((data, index) => (
                                                        <li key={index}>{data.EHIVALUE}</li>
                                                    ))
                                                }
                                            </>



                                        </ul>
                                    </Form.InputGroup>
                                )}

                                {hazardoesData.length > 0 && (
                                    <Form.InputGroup label="Hazards Prevailing">
                                        <ul>
                                            {hazardoesData.map((data, index) => (
                                                <li key={index}>{data.EHIVALUE}</li>
                                            ))}
                                        </ul>
                                    </Form.InputGroup>
                                )}

                                {equipmentView.length > 0 && (
                                    <Form.InputGroup label="Safety Equipment required">
                                        <ul>
                                            {equipmentView.map((data, index) => (
                                                <li key={index}>{data.EHIVALUE}</li>
                                            ))}
                                        </ul>
                                    </Form.InputGroup>
                                )}




                                <Form.InputGroup label="PTW Receive Remarks">
                                    <Form.InputControl
                                        control="textarea"
                                        name="txtDefectId"
                                        maxLength="900"
                                        value={acceptRemarks}
                                        style={{ height: '100px' }}
                                        placeholder="Enter Remarks"
                                        onChange={(e) => handleAcceptData(e, "Remarks")}
                                    />
                                </Form.InputGroup>
                            </Form.Group>
                        </Form>

                        <div className="d-flex align-items-center mt-3 mx-3">
                            <input
                                type="checkbox"
                                id="termsCheckbox"
                                checked={termsChecked}
                                onChange={(e) => setTermsChecked(e.target.checked)}
                            />
                            <label htmlFor="termsCheckbox" className="m-2">
                                I hereby declare that I have satisfied myself that the above isolations are adequate for the work.
                                I also accept responsibility for carrying out work on said portions and that no attempt will
                                be made by me to carry out work on any other equipment. I also accept responsibility to make
                                isolations and precautions required for work.
                            </label>
                        </div>
                    </Modal.Body>

                    <Modal.Footer>
                        <button disabled={!termsChecked} className="btn btn-primary" onClick={(e) => acceptPtwRequest(e)}>Save</button>
                    </Modal.Footer>
                </Modal>
            )}

            {rejectAcceptModal && (
                <Modal varient="center"
                    title="Reject PTW"
                    index={2}
                    show={closeRejectAcceptModal}
                    width="700px"
                    height="500px">
                    <Modal.Body>
                        <Form>
                            <Form.Group controlwidth="340px">
                                {isolationData.length > 0 && (
                                    <Form.InputGroup label="Isolations Done">
                                        <ul>
                                            {isolationData.map((data, index) => (
                                                <li key={index}>{data.EHIVALUE}</li>
                                            ))}
                                        </ul>
                                    </Form.InputGroup>
                                )}

                                {hazardoesData.length > 0 && (
                                    <Form.InputGroup label="Hazards Prevailing">
                                        <ul>
                                            {hazardoesData.map((data, index) => (
                                                <li key={index}>{data.EHIVALUE}</li>
                                            ))}
                                        </ul>
                                    </Form.InputGroup>
                                )}

                                {equipmentView.length > 0 && (
                                    <Form.InputGroup label="Safety Equipment required">
                                        <ul>
                                            {equipmentView.map((data, index) => (
                                                <li key={index}>{data.EHIVALUE}</li>
                                            ))}
                                        </ul>
                                    </Form.InputGroup>
                                )}


                                <Form.InputGroup label="PTW Reject Remarks">
                                    <Form.InputControl
                                        control="textarea"
                                        name="txtDefectId"
                                        maxLength="900"
                                        value={acceptRemarks}
                                        style={{ height: '100px' }}
                                        placeholder="Enter Remarks"
                                        onChange={(e) => handleAcceptData(e, "Remarks")}
                                    />
                                </Form.InputGroup>
                            </Form.Group>
                        </Form>
                    </Modal.Body>

                    <Modal.Footer>
                        <button disabled={!rejectPTWtermsChecked} className="btn btn-primary" onClick={(e) => rejectPtwRequest(e)}>Reject PTW</button>
                    </Modal.Footer>
                </Modal>
            )}

            {ptwTransfer && (
                <Modal varient="center"
                    title="PTW Transfer"
                    index={2}
                    show={closePTWTransferModal}
                    width="600px"
                    height="400px">
                    <Modal.Body>
                        <Form>
                            <Form.Group controlwidth="240px">
                                <Form.InputGroup label="Employee">
                                    <Form.InputControl
                                        control="select"
                                        name="txtDefectId"
                                        maxLength="900"
                                        options={teamsListMembersData.map((option, index) => ({
                                            label: option.EMPLOYEENAME,
                                            value: option.EMPLOYEEID,
                                        }))}
                                        defaultValue={selectedPtwTranferEmployee}
                                        onChange={(e) => {
                                            console.log("e", e);
                                            handlePTWTranferEmployee(e ? e.value : [], "EMPLOYEE")
                                        }}

                                    />


                                </Form.InputGroup>

                                <Form.InputGroup label="Remarks">
                                    <Form.InputControl
                                        control="textarea"
                                        name="txtDefectId"
                                        maxLength="200"
                                        style={{ height: '100px' }}
                                        value={ptwtransferRemarks}
                                        placeholder="Please Enter Remarks"
                                        onChange={(e) => handlePTWTranferRemarks(e, "REMARKS")}
                                    />
                                </Form.InputGroup>
                            </Form.Group>
                        </Form>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={savePTWtranfer}> Tranfer PTW</Button>

                    </Modal.Footer>
                </Modal>

            )}

            {
                (getPTWDataByDefectIdPTWId.ptwstatus === "ACCEPTED") && (getPTWDataByDefectIdPTWId.issuinG_EMPLOYEEID === user.UserName) && (
                    <>
                        {operationNormalise && (
                            <Modal varient="center"
                                title="PTW Test Operation"
                                index={2}
                                show={closeOperationNormaliseModal}
                                width="1000px"
                                height="800px">
                                <Modal.Body>
                                    <>
                                        <div>
                                            <Form>
                                                <Form.Group controlwidth="340px">
                                                    <Form.InputGroup label="Test Operation Assign to Division">
                                                        <Form.InputControl
                                                            control="select"
                                                            name="txtAssetId"
                                                            maxLength="200"
                                                            options={issuingDivision.map((division, index) => ({
                                                                label: division.DIVISIONNAME,
                                                                value: division.DIVISIONID
                                                            }))}
                                                            defaultValue={selectedTestOperationDivision}
                                                            onChange={(e) => handleTestOperationChange(e ? e : [], "DIVISION")}
                                                        />
                                                    </Form.InputGroup>
                                                    <Form.InputGroup label="Test Operation Assign to Employee">
                                                        <Form.InputControl
                                                            control="select"
                                                            name="txtAssetId"
                                                            maxLength="200"
                                                            options={ptwEmployeeList.map((option, index) => ({
                                                                label: option.employeename,
                                                                value: option.employeeid
                                                            }))}
                                                            defaultValue={selectedTestOperationEmployee}
                                                            onChange={(e) => handleTestOperationChange(e ? e : [], "EMPLOYEE")}
                                                        />
                                                    </Form.InputGroup>
                                                </Form.Group>
                                            </Form>
                                        </div>
                                        <h6 className="mx-3 my-3" style={{ fontWeight: 'bold' }}>Test Operation to be Carried Out</h6>
                                        <span className="mx-3 mb-3">
                                            <input
                                                type="checkbox"
                                                id="termsCheckbox1"
                                                className="m-2"
                                                checked={request}
                                                onChange={(e) => setRequest(e.target.checked)}
                                            /> I request for the following test operation to be carried out
                                        </span>
                                        <Form>
                                            <Form.Group>
                                                <Form.InputGroup label="Remarks" className="mx-3" style={{ fontWeight: 'bold !important' }}>
                                                    <Form.InputControl
                                                        control="textarea"
                                                        name="txtDefectId"
                                                        maxLength="200"
                                                        style={{ height: '100px', width: '370px' }}
                                                        value={testOperationRemarks}
                                                        placeholder="Please Enter Remarks"
                                                        onChange={(e) => settestOperationRemarks(e.target.value)}
                                                    />
                                                </Form.InputGroup>
                                            </Form.Group>
                                        </Form>
                                        <hr />
                                        <span className="mx-3" style={{ fontWeight: 'bold' }}>Isolations to be Normalized:</span>
                                        {isolationData.map((data, index) => (
                                            <div className="d-flex mx-3 align-items-center" key={index}>
                                                <input
                                                    type="checkbox"
                                                    id={`txtDivisionNameCheckbox${index}`}
                                                    maxLength="200"
                                                    className="m-2"
                                                    onChange={() => handleCheckboxOperationChange(index)}
                                                    checked={isOperationChecked[index]}
                                                />
                                                <h5 className="mt-2 mx-2">{`${data.EHIVALUE}`}</h5>
                                            </div>
                                        ))}
                                        <hr />
                                        <div className="d-flex flex-column justify-content-end mt-3">
                                            <span className="mx-3" style={{ fontWeight: 'bold' }}>Declaration</span>
                                            <div className="my-3 mx-3">
                                                <div>
                                                    <input
                                                        type="checkbox"
                                                        id="termsCheckbox2"
                                                        className="m-2"
                                                        checked={declare}
                                                        onChange={(e) => setDeclare(e.target.checked)}
                                                    />
                                                    <label htmlFor="termsCheckbox2" className="form-check-label">
                                                        I declare that it is safe to carry out these test operations.
                                                    </label>
                                                </div>
                                            </div>
                                        </div>

                                        {testOperationData.length > 0 && (
                                            <div className="d-flex flex-column justify-content-end mt-3">
                                                <span className="mx-3" style={{ fontWeight: 'bold' }}>Test Operation Status</span>
                                                <div className="my-3 mx-3">
                                                    {testOperationData.some(data => data.ISO_NORMALIZED === "Y") && (
                                                        <div>
                                                            <label htmlFor="termsCheckbox1" className="form-check-label" style={{ fontWeight: 'bold' }}>
                                                                Test Operation Permitted
                                                            </label>
                                                            <input
                                                                type="checkbox"
                                                                id="termsCheckbox1"
                                                                className="m-2"
                                                                checked={testOperationData.some(data => data.ISO_NORMALIZED === "Y")}
                                                                readOnly
                                                            />
                                                        </div>
                                                    )}

                                                    {testOperationData.some(data => data.WORK_COMPLETED === "Y") && (
                                                        <div>
                                                            <label htmlFor="termsCheckbox2" className="form-check-label" style={{ fontWeight: 'bold' }}>
                                                                Test Operation Completed
                                                            </label>
                                                            <input
                                                                type="checkbox"
                                                                id="termsCheckbox2"
                                                                className="m-2"
                                                                checked={testOperationData.some(data => data.WORK_COMPLETED === "Y")}
                                                                readOnly
                                                            />
                                                        </div>
                                                    )}

                                                    {testOperationData.some(data => data.ISO_PERMITTED === "Y") && (
                                                        <div>
                                                            <label htmlFor="termsCheckbox3" className="form-check-label" style={{ fontWeight: 'bold' }}>
                                                                Isolation Restored and Permitted to continue the work
                                                            </label>
                                                            <input
                                                                type="checkbox"
                                                                id="termsCheckbox3"
                                                                className="m-2"
                                                                checked={testOperationData.some(data => data.ISO_PERMITTED === "Y")}
                                                                readOnly
                                                            />
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        )}


                                    </>
                                </Modal.Body>
                                <Modal.Footer>
                                    {(isolationData.some((data, index) => isOperationChecked[index])) && (
                                        <Button varient="primary" onClick={handleOperationSave}>Submit</Button>
                                    )}
                                </Modal.Footer>
                            </Modal>
                        )}


                    </>
                )
            }

            {operationNormaliseAfterTransfer && (
                <Modal varient="center"
                    title="PTW Test Operation"
                    index={2}
                    show={closeOperationNormaliseAfterTransferModal}
                    width="1000px"
                    height="800px">
                    <Modal.Body>
                        <>
                            <div>
                                <Form>
                                    <Form.Group controlwidth="340px">
                                        <Form.InputGroup label="Test Operation Assign to Division">
                                            <Form.InputControl
                                                control="select"
                                                name="txtAssetId"
                                                maxLength="200"
                                                options={issuingDivision.map((division, index) => ({
                                                    label: division.DIVISIONNAME,
                                                    value: division.DIVISIONID
                                                }))}
                                                defaultValue={selectedTestOperationDivision}
                                                onChange={(e) => handleTestOperationChange(e ? e : [], "DIVISION")}
                                            />
                                        </Form.InputGroup>
                                        <Form.InputGroup label="Test Operation Assign to Employee">
                                            <Form.InputControl
                                                control="select"
                                                name="txtAssetId"
                                                maxLength="200"
                                                options={ptwEmployeeList.map((option, index) => ({
                                                    label: option.employeename,
                                                    value: option.employeeid
                                                }))}
                                                defaultValue={selectedTestOperationEmployee}
                                                onChange={(e) => handleTestOperationChange(e ? e : [], "EMPLOYEE")}
                                            />
                                        </Form.InputGroup>
                                    </Form.Group>
                                </Form>
                            </div>
                            <h6 className="mx-3 my-3" style={{ fontWeight: 'bold' }}>Test Operation to be Carried Out</h6>
                            <span className="mx-3 mb-3">
                                <input
                                    type="checkbox"
                                    id="termsCheckbox1"
                                    className="m-2"
                                    checked={request}
                                    onChange={(e) => setRequest(e.target.checked)}
                                /> I request for the following test operation to be carried out
                            </span>
                            <Form>
                                <Form.Group>
                                    <Form.InputGroup label="Remarks" className="mx-3" style={{ fontWeight: 'bold !important' }}>
                                        <Form.InputControl
                                            control="textarea"
                                            name="txtDefectId"
                                            maxLength="200"
                                            style={{ height: '100px', width: '370px' }}
                                            value={testOperationRemarks}
                                            placeholder="Please Enter Remarks"
                                            onChange={(e) => settestOperationRemarks(e.target.value)}
                                        />
                                    </Form.InputGroup>
                                </Form.Group>
                            </Form>
                            <hr />
                            <span className="mx-3" style={{ fontWeight: 'bold' }}>Isolations to be Normalized:</span>
                            {isolationData.map((data, index) => (
                                <div className="d-flex mx-3 align-items-center" key={index}>
                                    <input
                                        type="checkbox"
                                        id={`txtDivisionNameCheckbox${index}`}
                                        maxLength="200"
                                        className="m-2"
                                        onChange={() => handleCheckboxOperationChange(index)}
                                        checked={isOperationChecked[index]}
                                    />
                                    <h5 className="mt-2 mx-2">{`${data.EHIVALUE}`}</h5>
                                </div>
                            ))}
                            <hr />
                            <div className="d-flex flex-column justify-content-end mt-3">
                                <span className="mx-3" style={{ fontWeight: 'bold' }}>Declaration</span>
                                <div className="my-3 mx-3">
                                    <div>
                                        <input
                                            type="checkbox"
                                            id="termsCheckbox2"
                                            className="m-2"
                                            checked={declare}
                                            onChange={(e) => setDeclare(e.target.checked)}
                                        />
                                        <label htmlFor="termsCheckbox2" className="form-check-label">
                                            I declare that it is safe to carry out these test operations.
                                        </label>
                                    </div>
                                </div>
                            </div>

                            {testOperationData.length > 0 && (
                                <div className="d-flex flex-column justify-content-end mt-3">
                                    <span className="mx-3" style={{ fontWeight: 'bold' }}>Test Operation Status</span>
                                    <div className="my-3 mx-3">
                                        {testOperationData.some(data => data.ISO_NORMALIZED === "Y") && (
                                            <div>
                                                <label htmlFor="termsCheckbox1" className="form-check-label" style={{ fontWeight: 'bold' }}>
                                                    Test Operation Permitted
                                                </label>
                                                <input
                                                    type="checkbox"
                                                    id="termsCheckbox1"
                                                    className="m-2"
                                                    checked={testOperationData.some(data => data.ISO_NORMALIZED === "Y")}
                                                    readOnly
                                                />
                                            </div>
                                        )}

                                        {testOperationData.some(data => data.WORK_COMPLETED === "Y") && (
                                            <div>
                                                <label htmlFor="termsCheckbox2" className="form-check-label" style={{ fontWeight: 'bold' }}>
                                                    Test Operation Completed
                                                </label>
                                                <input
                                                    type="checkbox"
                                                    id="termsCheckbox2"
                                                    className="m-2"
                                                    checked={testOperationData.some(data => data.WORK_COMPLETED === "Y")}
                                                    readOnly
                                                />
                                            </div>
                                        )}

                                        {testOperationData.some(data => data.ISO_PERMITTED === "Y") && (
                                            <div>
                                                <label htmlFor="termsCheckbox3" className="form-check-label" style={{ fontWeight: 'bold' }}>
                                                    Isolation Restored and Permitted to continue the work
                                                </label>
                                                <input
                                                    type="checkbox"
                                                    id="termsCheckbox3"
                                                    className="m-2"
                                                    checked={testOperationData.some(data => data.ISO_PERMITTED === "Y")}
                                                    readOnly
                                                />
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )}


                        </>
                    </Modal.Body>
                    <Modal.Footer>
                        {(isolationData.some((data, index) => isOperationChecked[index])) && (
                            <Button varient="primary" onClick={handleOperationSave}>Submit</Button>
                        )}
                    </Modal.Footer>
                </Modal>

            )}

            {operation && (
                <Modal varient="center"
                    title="PTW Test Operation"
                    index={2}
                    show={closeOperationModal}
                    width="1000px"
                    height="800px">
                    <Modal.Body>
                        <div>
                            <h6 className="my-2 mx-3" style={{ fontWeight: 'bold' }}>PTW Test Operation Requested</h6>
                            <div className="d-flex align-items-center mx-3">
                                {/* <pre>{JSON.stringify(isolationsTobeNormalised[0].REMARKS, null)}</pre> */}
                                {isolationsTobeNormalised && isolationsTobeNormalised[0] && (
                                    <div className="d-flex mx-3 align-items-center">
                                        <input
                                            type="textarea"
                                            id={`txtDivisionNameCheckbox`}
                                            maxLength="200"
                                            className="m-2"
                                            style={{ width: '600px', height: '50px' }}
                                            value={isolationsTobeNormalised[0].REMARKS}
                                            readOnly
                                        />
                                    </div>
                                )
                                }
                            </div>
                            <hr />
                            <div>
                                <span className="mx-3" style={{ fontWeight: 'bold' }}>Isolations Normalized:</span>
                                {isolationsTobeNormalised.map((data, index) => (
                                    <>
                                        <div className="d-flex mx-3 align-items-center">
                                            <input
                                                type="checkbox"
                                                id={`txtDivisionNameCheckbox${index}`}
                                                maxLength="200"
                                                className="m-2"
                                                onChange={() => handleCheckboxOperationNormalisedChange(index)}
                                                checked={isOperationNormalisedChecked[index]}
                                            />
                                            <h5 className="mt-2 mx-2" key={index}>{`${data.EHI_DESCRIPTION}`}</h5>
                                        </div>
                                    </>
                                ))
                                }
                                <span className="d-flex justify-content-end mx-3">
                                    <button className="btn btn-primary" disabled={!isOperationNormalisedChecked.some(checked => checked)} onClick={(e) => handleTestOperationPermitted(e)}>Test Operation Permitted</button>
                                </span>

                            </div>
                            <hr />
                            <div>
                                <span className="mx-3" style={{ fontWeight: 'bold' }}>Test Operation Details</span>

                                <div className="d-flex align-items-center mx-3">
                                    <input
                                        type="checkbox"
                                        id="termsCheckbox1"
                                        checked={testOperationPermitted}
                                        onChange={(e) => settestOperationPermitted(e.target.checked)}
                                    />
                                    <label htmlFor="termsCheckbox1" className="m-2">
                                        Test operations carried out as per above request. Permit holder is permitted to continue the said work under this permit after I have restored the isolations done before issue of permit.
                                    </label>
                                </div>
                                <div className="d-flex justify-content-end mx-3">
                                    <button className="btn btn-primary" disabled={!testOperationPermitted} onClick={(e) => handleTestOperationCompleted(e)}>Test Operation Completed</button>
                                </div>
                            </div>
                            <hr />
                            <div className="mb-5">
                                <h6 className="my-2 mx-3" style={{ fontWeight: 'bold' }}>Restoration of Isolation</h6>
                                <div>
                                    {/* <table className="table table-bordered table-striped table-responsive">
                                    <thead>
                                        <tr>
                                            <th>Isolation Name</th>
                                            <th>Normalize</th>
                                            <th>Normalized</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {isolationData.map((data, index) => (
                                            <tr key={index} style={{ height: '50px' }}>
                                                <td>{`Isolation ${index + 1} : ${data.EHIVALUE}`}</td>
                                                <td>
                                                    {getPTWDataByDefectIdPTWId.ptwstatus !== "RETURNED" && (
                                                        <>
                                                            <input
                                                                type="checkbox"
                                                                id={`txtDivisionNameCheckbox${index}`}
                                                                maxLength="200"
                                                                className="m-2"
                                                                onChange={() => handleCheckboxOperationChange(index)}
                                                                checked={isOperationChecked[index]}
                                                            />


                                                        </>
                                                    )}
                                                </td>
                                                <td>
                                                    <input
                                                        type="checkbox"
                                                        id={`txtDivisionNameCheckbox${index}`}
                                                        maxLength="200"
                                                        className="m-2"
                                                        onChange={() => handleCheckboxOperationChange(index)}
                                                        checked={isOperationChecked[index]}
                                                    />
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table> */}


                                    {isolationsTobeNormalised.map((data, index) => (
                                        <>
                                            <div className="d-flex mx-3 align-items-center">
                                                <input
                                                    type="checkbox"
                                                    id={`txtDivisionNameCheckbox${index}`}
                                                    maxLength="200"
                                                    className="m-2"
                                                    onChange={() => handleCheckboxOperationNormalisedRestorationChange(index)}
                                                    checked={isOperationRestorationChecked[index]}
                                                />
                                                <h5 className="mt-2 mx-2" key={index}>{`${data.EHI_DESCRIPTION}`}</h5>
                                            </div>
                                        </>
                                    ))
                                    }

                                    <div className="d-flex align-items-center mx-2 mt-4">
                                        <input
                                            type="checkbox"
                                            id="termsCheckbox1"
                                            checked={permittedToWork}
                                            onChange={(e) => setpermittedToWork(e.target.checked)}
                                        />
                                        <label htmlFor="termsCheckbox1" className="m-2">
                                            I have restored the above isolations PTW holder is permitted to continue the work                                    </label>
                                    </div>
                                    <div className="d-flex justify-content-end mx-3">
                                        <button className="btn btn-primary" disabled={!isOperationRestorationChecked.some(checked => checked) || !permittedToWork} onClick={(e) => handlePermittedToWork(e)}>Permitted to Work</button>
                                    </div>
                                </div>

                            </div>

                            {/* <div className="d-flex flex-column justify-content-end">
                            <div className="d-flex align-items-center mx-3">
                                <input
                                    type="checkbox"
                                    id="termsCheckbox1"
                                    checked={testOperationPermitted}
                                    onChange={(e) => settestOperationPermitted(e.target.checked)}
                                />
                                <label htmlFor="termsCheckbox1" className="m-2">
                                    Test operations carried out as per above request. Permit holder is permitted to continue the said work under this permit after I have restored the isolations done before issue of permit.
                                </label>
                            </div>
                            <p className="d-flex align-items-center justify-content-center mx-3">OR</p>
                            <div className="d-flex align-items-center mx-3">
                                <input
                                    type="checkbox"
                                    id="termsCheckbox2"
                                    checked={testOperationNotPermitted}
                                    onChange={(e) => settestOperationNotPermitted(e.target.checked)}
                                />
                                <label htmlFor="termsCheckbox2" className="m-2">No further work is permitted and permit is to be returned
                                </label>
                            </div>

                        </div> */}
                        </div>
                    </Modal.Body>
                    {/* <Modal.Footer>
                        <Button>Normalize</Button>
                        <Button onClick={handleOperationSave}>Test Operation Permitted</Button>
                        <Button onClick={handleOperationSave}>Test Operation Not Permitted</Button>
                    </Modal.Footer> */}
                </Modal>
            )
            }

            {
                returnModal && (
                    <Modal varient="center"
                        title="Return PTW"
                        index={2}
                        show={closeReturnModal}
                        width="700px"
                        height="500px">
                        <Modal.Body>
                            <Form>
                                <Form.Group controlwidth="340px">
                                    <Form.InputGroup label="PTW Return to Division">
                                        <Form.InputControl
                                            control="select"
                                            name="txtAssetId"
                                            maxLength="200"
                                            options={issuingDivision.map((division, index) => ({
                                                label: division.DIVISIONNAME,
                                                value: division.DIVISIONID
                                            }))}
                                            defaultValue={selectedDivision}
                                            onChange={(e) => handlePTWInputChange(e ? e : [], "DIVISION")}
                                        />
                                    </Form.InputGroup>

                                    <Form.InputGroup label="PTW Return to Employee">
                                        <Form.InputControl
                                            control="select"
                                            name="txtAssetId"
                                            maxLength="200"
                                            options={ptwEmployeeList.map((option, index) => ({
                                                label: option.employeename,
                                                value: option.employeeid
                                            }))}
                                            defaultValue={selectedEmployee}
                                            onChange={(e) => handlePTWInputChange(e ? e : [], "EMPLOYEE")}
                                        />


                                    </Form.InputGroup>

                                    <Form.InputGroup label="PTW Return Remarks">
                                        <Form.InputControl
                                            control="textarea"
                                            name="txtDefectId"
                                            maxLength="900"
                                            value={returnRemarks}
                                            style={{ height: '100px' }}
                                            placeholder="Please Enter Return Remarks"
                                            onChange={(e) => handleReturnData(e, "Remarks")}
                                        />
                                    </Form.InputGroup>
                                </Form.Group>
                            </Form>



                            <div className="d-flex align-items-center mt-3 mx-3">
                                <input
                                    type="checkbox"
                                    id="termsCheckbox"
                                    checked={returnPTWtermsChecked}
                                    onChange={(e) => setreturnPTWtermsChecked(e.target.checked)}
                                />
                                <label htmlFor="termsCheckbox" className="m-2">
                                    I hereby declare that all men & materials have been withdrawn and all tools & have been removed and the equipment is in fit condition to be charged. All isolations carried out by me are normalized.
                                </label>
                            </div>
                        </Modal.Body>

                        <Modal.Footer>
                            <button className="btn btn-primary" disabled={!returnPTWtermsChecked} onClick={(e) => returnRequest(e)}>Return PTW</button>
                        </Modal.Footer>
                    </Modal>
                )
            }

            {attendModal && (
                <Modal varient="center"
                    title="Attend Defect"
                    index={2}
                    show={closeAttendDefectModal}
                    width="700px"
                    height="500px">
                    <Modal.Body>
                        <Form>

                            <Form.Group controlwidth="340px">

                                <Form.InputGroup label="Defect ID">
                                    <Form.InputControl
                                        control="input"
                                        name="txtAssetId"
                                        maxLength="200"
                                        value={getPTWDataByDefectIdPTWId.defectid}
                                        readOnly
                                    />
                                </Form.InputGroup>
                                <Form.InputGroup label="PTW ID">
                                    <Form.InputControl
                                        control="input"
                                        name="txtAssetId"
                                        maxLength="200"
                                        value={getPTWDataByDefectIdPTWId.ptW_ID}
                                        readOnly
                                    />
                                </Form.InputGroup>


                                <Form.InputGroup label="Details of work done">
                                    <Form.InputControl
                                        control="textarea"
                                        name="txtAssetId"
                                        maxLength="900"
                                        value={details}
                                        placeholder="Please Enter Details of Work Done"
                                        style={{ height: '100px' }}
                                        onChange={(e) => setDetails(e.target.value)}
                                    />
                                </Form.InputGroup>


                                <Form.InputGroup label="Restrictions if any">
                                    <Form.InputControl
                                        control="textarea"
                                        name="txtDefectId"
                                        maxLength="900"
                                        value={attendRestrictions}
                                        style={{ height: '100px' }}
                                        placeholder="Please Enter Restrictions if any"
                                        onChange={(e) => setAttendRestrictions(e.target.value)}
                                    />
                                </Form.InputGroup>
                            </Form.Group>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-primary" onClick={(e) => attendDefectRequest(e)}>Attend Defect</button>
                    </Modal.Footer>
                </Modal>
            )}

            {
                cancelModal && (
                    <Modal varient="center"
                        title="Close PTW"
                        index={2}
                        show={closeCancelModal}
                        width="800px"
                        height="600px">

                        <Modal.Body>
                            <div>
                                <Form>
                                    <Form.Group controlwidth="340px">
                                        <Form.InputGroup label="PTW Cancelling Authority Name">
                                            <Form.InputControl
                                                control="input"
                                                name="txtDefectId"
                                                maxLength="900"
                                                value={user.EmployeeName}
                                                readOnly
                                            />
                                        </Form.InputGroup>
                                        <Form.InputGroup label="PTW Cancelling Authority Division">
                                            <Form.InputControl
                                                control="input"
                                                name="txtDefectId"
                                                maxLength="900"
                                                value={user.DIVISION_NAME}
                                                readOnly
                                            />
                                        </Form.InputGroup>
                                        <Form.InputGroup label="PTW Cancelling Remarks">
                                            <Form.InputControl
                                                control="textarea"
                                                name="txtDefectId"
                                                maxLength="900"
                                                style={{ height: '100px' }}
                                                value={cancelRemarks}
                                                onChange={(e) => setcancelRemarks(e.target.value)}
                                                placeholder="Please Enter remarks"
                                            />
                                        </Form.InputGroup>
                                    </Form.Group>
                                </Form>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button onClick={handleCancelPTW}>Save</Button>
                        </Modal.Footer>
                    </Modal>
                )
            }

        </div >
    );
}

export default MyComponent;
