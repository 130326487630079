import React, { useEffect } from "react";
import { TbLayoutDashboard } from "react-icons/tb";
import { MdArrowBack } from "react-icons/md";
import PropTypes from "prop-types";
import {
  PROJECT_DETAIL_ALERTSEVENTS,
  PROJECT_DETAIL_ASSETS,
  PROJECT_DETAIL_ASSET_HISTORY,
  PROJECT_DETAIL_DASHBOARD,
  PROJECT_DETAIL_DEFECT_LIST,
  PROJECT_DETAIL_GRIEVANCES,
  PROJECT_DETAIL_OBSERVATIONS,
  PROJECT_DETAIL_PLANNED_MAINTENANCE,
  PROJECT_DETAIL_PM_LIST,
  PROJECT_DETAIL_PTW_LIST,
  PROJECT_DETAIL_QRCODES,
  PROJECT_DETAIL_SKIP_APPROVALS,
  PROJECT_DETAIL_STATUS_BOARD,
  PROJECT_DETAIL_TEAMS,
  PROJECT_DETAIL_WORK_ASSIGNMENT,
  PROJECT_DETAIL_WORK_OBSERVATION_ASSIGNMENT,
  PROJECT_DETAILS_ASSET_STATUS,
  PROJECT_DETAIL_CREATE_LOG_SHEETS,
  PROJECT_DETAIL_CAPTURE_LOG_SHEETS,
  PROJECT_DETAIL_CAPTURE_LOG_SHEETS_EXCEL,
  PROJECT_DETAIL_LOGSHEET_REPORT,
  PROJECT_DETAIL_LOGSHEET_LIST,
  PROJECT_DETAIL_HISTORY_READINGS,
  PROJECT_DETAIL_PERFORMANCE_DATA,
  PROJECT_DAILY_PERFORMANCE_DATA,
  PROJECT_DETAIL_AOH,
  PROJECT_DETAIL_MOH

} from "Configration/Utilities/Constants/Constants";
import classNames from "classnames";
import Dashboard from "../Dashboard/Dashboard";
import ProjectDetailLogic from "./Logic/ProjectDetailLogic";
import BizClass from "./ProjectDetail.module.scss";
import StatusBoard from "../StatusBoard/StatusBoard";
import AssetHistory from "../AssetHistory/AssetHistory";
import Assets from "../Assets/Assets";
import DefectList from "../DefectList/DefectList";
import PlannedMaintenance from "../PlannedMaintenance/PlannedMaintenance";
import PMList from "../PMList/PMList";
import PTWList from "../PTW/ptw"
import Observations from "../Observations/Observations";
import Teams from "../Teams/Teams";
import AlertsEvents from "../AlertsEvents/AlertsEvents";
import WorkAssignment from "../WorkAssignment/WorkAssignment";
import SkipApprovals from "../SkipApprovals/SkipApprovals";
import QRCodes from "../QRCodes/QRCodes";
import Grievances from "../Grievances/Grievances";
import WorkObservationAssignment from "../WorkObservationAssignment/WorkObservationAssignment";
import AssetStatus from "../AssetStatus/AssetStatus";
import LogSheets from "../LogSheets/LogSheets";
import CreateLogSheets from "../CaptureLogSheets/CaptureLogSheets";
import LogSheetReport from "../LogSheetReport/LogSheetReport";
import LogSheetList from "../LogSheetList/LogSheetList";
import HistoryReading from "../HistoryReadings/HistoryReadings";
import PerformanceData from "../PerformanceData/PerformanceData";
import DailyPerformanceData from "../DailyPerformanceData/DailyPerformanceData";
import CaptureLogsheetExcel from "../CaptureLogSheetsExcel/CaptureLogSheetsExcel";
import Aoh from "../AOH/aoh";
import Moh from "../MOH/moh";
import { getSessionStorage } from "Service/auth/auth";


function ProjectDetail({ toggleDetailModal, selectedProjectData }) {
  const { activeProjectDetailsTab, setActiveProjectDetailsTab } = ProjectDetailLogic();
  const user = getSessionStorage("userData");
  useEffect(() => {
    document.title =
      selectedProjectData && selectedProjectData.PROJECTNAME && selectedProjectData.PROJECTNAME.length > 0
        ? `${selectedProjectData.PROJECTNAME.split(" ")[0]} | Karnataka Power Corporation Limited`
        : "Karnataka Power Corporation Limited";
  }, [selectedProjectData]);

  return (
    <div className={BizClass.MainSection}>
      <div className={BizClass.Header}>
        <button type="button" onClick={() => toggleDetailModal(false, null)}>
          <MdArrowBack />
          Back to Projects
        </button>
        <h4>
          <p>
            {selectedProjectData && selectedProjectData.PROJECTNAME.split(" ")[0].toUpperCase().slice(0, 2)}
          </p>
          <span>
            {selectedProjectData && selectedProjectData.PROJECTNAME.split(" ")[0].toUpperCase()}
          </span>
          {" / "}
          {selectedProjectData && selectedProjectData.PROJECTNAME} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

          <div className="d-flex justify-content-end">
            <div className="d-flex justify-content-end">
              Employee Name: {user.EmployeeName} &nbsp;&nbsp;&nbsp;&nbsp;
              Employee Code: {user.UserName} &nbsp;&nbsp;&nbsp;&nbsp;
              Employee Division: {user.DIVISION_NAME}
            </div>
          </div>

        </h4>


      </div>
      <div className={`${BizClass.Body} custom-align-items-center`}>
        <div className={BizClass.NavTab}>
          <button
            type="button"
            className={classNames(BizClass.NavTabCard, activeProjectDetailsTab === PROJECT_DETAIL_DASHBOARD ? BizClass.Active : null)}
            onClick={() => setActiveProjectDetailsTab(PROJECT_DETAIL_DASHBOARD)}
          >
            <TbLayoutDashboard />
            <p className="m-0">Dashboard</p>
          </button>
          {/* <button
            type="button"
            className={classNames(BizClass.NavTabCard, activeProjectDetailsTab === PROJECT_DETAIL_STATUS_BOARD ? BizClass.Active : null)}
            onClick={() => setActiveProjectDetailsTab(PROJECT_DETAIL_STATUS_BOARD)}
          >
            <TbLayoutDashboard />
            <p>Status Board</p>
          </button> */}
          <button
            type="button"
            className={classNames(BizClass.NavTabCard, activeProjectDetailsTab === PROJECT_DETAIL_ASSET_HISTORY ? BizClass.Active : null)}
            onClick={() => setActiveProjectDetailsTab(PROJECT_DETAIL_ASSET_HISTORY)}
          >
            <TbLayoutDashboard />
            <p className="m-0">Asset History</p>
          </button>
          <button
            type="button"
            className={classNames(BizClass.NavTabCard, activeProjectDetailsTab === PROJECT_DETAIL_ASSETS ? BizClass.Active : null)}
            onClick={() => setActiveProjectDetailsTab(PROJECT_DETAIL_ASSETS)}
          >
            <TbLayoutDashboard />
            <p className="m-0">Assets</p>
          </button>
          <button
            type="button"
            className={classNames(BizClass.NavTabCard, activeProjectDetailsTab === PROJECT_DETAILS_ASSET_STATUS ? BizClass.Active : null)}
            onClick={() => setActiveProjectDetailsTab(PROJECT_DETAILS_ASSET_STATUS)}
          >
            <TbLayoutDashboard />
            <p className="m-0">Asset Status</p>
          </button>
          <button
            type="button"
            className={classNames(BizClass.NavTabCard, activeProjectDetailsTab === PROJECT_DETAIL_DEFECT_LIST ? BizClass.Active : null)}
            onClick={() => setActiveProjectDetailsTab(PROJECT_DETAIL_DEFECT_LIST)}
          >
            <TbLayoutDashboard />
            <p className="m-0">Defect List</p>
          </button>
          {/* <button
            type="button"
            className={classNames(BizClass.NavTabCard, activeProjectDetailsTab === PROJECT_DETAIL_PLANNED_MAINTENANCE ? BizClass.Active : null)}
            onClick={() => setActiveProjectDetailsTab(PROJECT_DETAIL_PLANNED_MAINTENANCE)}
          >
            <TbLayoutDashboard />
            <p>Planned Maintenance</p>
          </button> */}
          <button
            type="button"
            className={classNames(BizClass.NavTabCard, activeProjectDetailsTab === PROJECT_DETAIL_PM_LIST ? BizClass.Active : null)}
            onClick={() => setActiveProjectDetailsTab(PROJECT_DETAIL_PM_LIST)}
          >
            <TbLayoutDashboard />
            <p className="m-0">Planned Maintenance</p>
          </button>


          <button
            type="button"
            className={classNames(BizClass.NavTabCard, activeProjectDetailsTab === PROJECT_DETAIL_AOH ? BizClass.Active : null)}
            onClick={() => setActiveProjectDetailsTab(PROJECT_DETAIL_AOH)}
          >
            <TbLayoutDashboard />
            <p className="m-0">AOH List</p>
          </button>

          <button
            type="button"
            className={classNames(BizClass.NavTabCard, activeProjectDetailsTab === PROJECT_DETAIL_MOH ? BizClass.Active : null)}
            onClick={() => setActiveProjectDetailsTab(PROJECT_DETAIL_MOH)}
          >
            <TbLayoutDashboard />
            <p className="m-0">MOH List</p>
          </button>


          <button
            type="button"
            className={classNames(BizClass.NavTabCard, activeProjectDetailsTab === PROJECT_DETAIL_PTW_LIST ? BizClass.Active : null)}
            onClick={() => setActiveProjectDetailsTab(PROJECT_DETAIL_PTW_LIST)}
          >
            <TbLayoutDashboard />
            <p className="m-0">PTW List</p>
          </button>
          <button
            type="button"
            className={classNames(BizClass.NavTabCard, activeProjectDetailsTab === PROJECT_DETAIL_OBSERVATIONS ? BizClass.Active : null)}
            onClick={() => setActiveProjectDetailsTab(PROJECT_DETAIL_OBSERVATIONS)}
          >
            <TbLayoutDashboard />
            <p className="m-0">Observations</p>
          </button>
          <button
            type="button"
            className={classNames(BizClass.NavTabCard, activeProjectDetailsTab === PROJECT_DETAIL_TEAMS ? BizClass.Active : null)}
            onClick={() => setActiveProjectDetailsTab(PROJECT_DETAIL_TEAMS)}
          >
            <TbLayoutDashboard />
            <p className="m-0">Teams</p>
          </button>
          <button
            type="button"
            className={classNames(BizClass.NavTabCard, activeProjectDetailsTab === PROJECT_DETAIL_ALERTSEVENTS ? BizClass.Active : null)}
            onClick={() => setActiveProjectDetailsTab(PROJECT_DETAIL_ALERTSEVENTS)}
          >
            <TbLayoutDashboard />
            <p className="m-0">Alerts/Events</p>
          </button>
          <button
            type="button"
            className={classNames(BizClass.NavTabCard, activeProjectDetailsTab === PROJECT_DETAIL_WORK_ASSIGNMENT ? BizClass.Active : null)}
            onClick={() => setActiveProjectDetailsTab(PROJECT_DETAIL_WORK_ASSIGNMENT)}
          >
            <TbLayoutDashboard />
            <p className="m-0">Work Asset Assignment</p>
          </button>
          <button
            type="button"
            className={classNames(BizClass.NavTabCard, activeProjectDetailsTab === PROJECT_DETAIL_SKIP_APPROVALS ? BizClass.Active : null)}
            onClick={() => setActiveProjectDetailsTab(PROJECT_DETAIL_SKIP_APPROVALS)}
          >
            <TbLayoutDashboard />
            <p className="m-0">Skip Approvals</p>
          </button>
          <button
            type="button"
            className={classNames(BizClass.NavTabCard, activeProjectDetailsTab === PROJECT_DETAIL_QRCODES ? BizClass.Active : null)}
            onClick={() => setActiveProjectDetailsTab(PROJECT_DETAIL_QRCODES)}
          >
            <TbLayoutDashboard />
            <p className="m-0">QRCodes</p>
          </button>
          <button
            type="button"
            className={classNames(BizClass.NavTabCard, activeProjectDetailsTab === PROJECT_DETAIL_GRIEVANCES ? BizClass.Active : null)}
            onClick={() => setActiveProjectDetailsTab(PROJECT_DETAIL_GRIEVANCES)}
          >
            <TbLayoutDashboard />
            <p className="m-0">Grievances</p>
          </button>
          <button
            type="button"
            className={classNames(BizClass.NavTabCard, activeProjectDetailsTab === PROJECT_DETAIL_WORK_OBSERVATION_ASSIGNMENT ? BizClass.Active : null)}
            onClick={() => setActiveProjectDetailsTab(PROJECT_DETAIL_WORK_OBSERVATION_ASSIGNMENT)}
          >
            <TbLayoutDashboard />
            <p className="m-0">Work Observation Assignment</p>
          </button>
          <button
            type="button"
            className={classNames(BizClass.NavTabCard, activeProjectDetailsTab === PROJECT_DETAIL_CREATE_LOG_SHEETS ? BizClass.Active : null)}
            onClick={() => setActiveProjectDetailsTab(PROJECT_DETAIL_CREATE_LOG_SHEETS)}
          >
            <TbLayoutDashboard />
            <p className="m-0">Create Log Sheets</p>
          </button>

          <button
            type="button"
            className={classNames(BizClass.NavTabCard, activeProjectDetailsTab === PROJECT_DETAIL_CAPTURE_LOG_SHEETS_EXCEL ? BizClass.Active : null)}
            onClick={() => setActiveProjectDetailsTab(PROJECT_DETAIL_CAPTURE_LOG_SHEETS_EXCEL)}
          >
            <TbLayoutDashboard />
            <p className="m-0">Capture Log Sheets Excel</p>
          </button>
          

          <button
            type="button"
            className={classNames(BizClass.NavTabCard, activeProjectDetailsTab === PROJECT_DETAIL_LOGSHEET_LIST ? BizClass.Active : null)}
            onClick={() => setActiveProjectDetailsTab(PROJECT_DETAIL_LOGSHEET_LIST)}
          >
            <TbLayoutDashboard />
            <p className="m-0">LogSheet List</p>
          </button>
          <button
            type="button"
            className={classNames(BizClass.NavTabCard, activeProjectDetailsTab === PROJECT_DETAIL_LOGSHEET_REPORT ? BizClass.Active : null)}
            onClick={() => setActiveProjectDetailsTab(PROJECT_DETAIL_LOGSHEET_REPORT)}
          >
            <TbLayoutDashboard />
            <p className="m-0">LogSheet Report</p>
          </button>
          <button
            type="button"
            className={classNames(BizClass.NavTabCard, activeProjectDetailsTab === PROJECT_DETAIL_HISTORY_READINGS ? BizClass.Active : null)}
            onClick={() => setActiveProjectDetailsTab(PROJECT_DETAIL_HISTORY_READINGS)}
          >
            <TbLayoutDashboard />
            <p className="m-0">History and Readings</p>
          </button>
          {/* <button
            type="button"
            className={classNames(BizClass.NavTabCard, activeProjectDetailsTab === PROJECT_DETAIL_PERFORMANCE_DATA ? BizClass.Active : null)}
            onClick={() => setActiveProjectDetailsTab(PROJECT_DETAIL_PERFORMANCE_DATA)}
          >
            <TbLayoutDashboard />
            <p className="m-0">Performance Data</p>
          </button>
          <button
            type="button"
            className={classNames(BizClass.NavTabCard, activeProjectDetailsTab === PROJECT_DAILY_PERFORMANCE_DATA ? BizClass.Active : null)}
            onClick={() => setActiveProjectDetailsTab(PROJECT_DAILY_PERFORMANCE_DATA)}
          >
            <TbLayoutDashboard />
            <p className="m-0">Daily Performance Data</p>
          </button> */}
        </div>

        <div className={BizClass.Content}>
          {activeProjectDetailsTab === PROJECT_DETAIL_DASHBOARD && <Dashboard />}
          {activeProjectDetailsTab === PROJECT_DETAIL_STATUS_BOARD && <StatusBoard />}
          {activeProjectDetailsTab === PROJECT_DETAIL_ASSET_HISTORY && <AssetHistory selectedProjectData={selectedProjectData} />}
          {activeProjectDetailsTab === PROJECT_DETAIL_ASSETS && <Assets selectedProjectData={selectedProjectData} />}
          {activeProjectDetailsTab === PROJECT_DETAILS_ASSET_STATUS && <AssetStatus selectedProjectData={selectedProjectData} />}
          {activeProjectDetailsTab === PROJECT_DETAIL_DEFECT_LIST && <DefectList />}
          {activeProjectDetailsTab === PROJECT_DETAIL_PLANNED_MAINTENANCE && <PlannedMaintenance />}
          {activeProjectDetailsTab === PROJECT_DETAIL_PM_LIST && <PMList />}
          {activeProjectDetailsTab === PROJECT_DETAIL_PTW_LIST && <PTWList />}
          {activeProjectDetailsTab === PROJECT_DETAIL_OBSERVATIONS && <Observations selectedProjectData={selectedProjectData} />}
          {activeProjectDetailsTab === PROJECT_DETAIL_TEAMS && <Teams selectedProjectData={selectedProjectData} />}
          {activeProjectDetailsTab === PROJECT_DETAIL_ALERTSEVENTS && <AlertsEvents selectedProjectData={selectedProjectData} />}
          {activeProjectDetailsTab === PROJECT_DETAIL_WORK_ASSIGNMENT && <WorkAssignment selectedProjectData={selectedProjectData} />}
          {activeProjectDetailsTab === PROJECT_DETAIL_SKIP_APPROVALS && <SkipApprovals selectedProjectData={selectedProjectData} />}
          {activeProjectDetailsTab === PROJECT_DETAIL_QRCODES && <QRCodes selectedProjectData={selectedProjectData} />}
          {activeProjectDetailsTab === PROJECT_DETAIL_GRIEVANCES && <Grievances />}
          {activeProjectDetailsTab === PROJECT_DETAIL_WORK_OBSERVATION_ASSIGNMENT && <WorkObservationAssignment selectedProjectData={selectedProjectData} />}
          {activeProjectDetailsTab === PROJECT_DETAIL_CREATE_LOG_SHEETS && <LogSheets selectedProjectData={selectedProjectData} />}
          {activeProjectDetailsTab === PROJECT_DETAIL_CAPTURE_LOG_SHEETS && <CreateLogSheets selectedProjectData={selectedProjectData} />}
          {activeProjectDetailsTab === PROJECT_DETAIL_CAPTURE_LOG_SHEETS_EXCEL && <CaptureLogsheetExcel selectedProjectData={selectedProjectData} />}

          
          {activeProjectDetailsTab === PROJECT_DETAIL_LOGSHEET_REPORT && <LogSheetReport selectedProjectData={selectedProjectData} />}
          {activeProjectDetailsTab === PROJECT_DETAIL_LOGSHEET_LIST && <LogSheetList selectedProjectData={selectedProjectData} />}
          {activeProjectDetailsTab === PROJECT_DETAIL_HISTORY_READINGS && <HistoryReading selectedProjectData={selectedProjectData} />}
          {activeProjectDetailsTab === PROJECT_DETAIL_AOH && <Aoh selectedProjectData={selectedProjectData} />}
          {activeProjectDetailsTab === PROJECT_DETAIL_MOH && <Moh selectedProjectData={selectedProjectData} />}

          {/* {activeProjectDetailsTab === PROJECT_DETAIL_PERFORMANCE_DATA && <PerformanceData selectedProjectData={selectedProjectData} />} */}
          {/* {activeProjectDetailsTab === PROJECT_DAILY_PERFORMANCE_DATA && <DailyPerformanceData selectedProjectData={selectedProjectData} />} */}
        </div>
      </div>
    </div>
  );
}

export default ProjectDetail;

ProjectDetail.propTypes = {
  toggleDetailModal: PropTypes.func.isRequired,
};
