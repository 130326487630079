import { DataGrid } from "Framework/Components/Layout";
import Form from "Framework/Components/Layout/FormGroup/FormGroup";
import Modal from "Framework/Components/Layout/Modal/Modal";
import { Button, Loader } from "Framework/Components/Widgets";
import React, { useEffect } from "react";
import BizClass from "../../WorkAssignment.module.scss";

function AddWorkAssignment(props) {
  const {
    toggleAddWorkAssignmentpopup,
    updateFormState,
    formValues,
    divisionList,
    isLoadingDivisionList,
    getDivisionDetailsList,
    employeeList,
    toEmployeeList,
    isLoadingEmployeeList,
    employeeAssetList,
    isLoadingEmployeeAssetList,
    employeeAssetColumDef,
    onEmpAssetGridReady,
    SaveWorkAssignment,
    formValidationError,
    isLoadingAddEmployeeAssign,
    clearForm,
  } = props;

  useEffect(() => {
    getDivisionDetailsList();
    clearForm();
  }, []);
  useEffect(() => {
    console.log(toEmployeeList, "toEmployeeListtoEmployeeList");
  }, [toEmployeeList]);
  return (
    <Modal varient="center" title="Work Assignment" show={toggleAddWorkAssignmentpopup}>
      <Modal.Body>
        <div className={BizClass.AddWorkAssignmentPopup}>
          <div className={BizClass.AddWorkAssignmentForm}>
            <Form>
              <h4>User Selecion</h4>
              <Form.Group column={2} controlwidth="240px">
                <Form.InputGroup label="Division : " req="true" errorMsg={formValidationError["txtDivision"]}>
                  <Form.InputControl
                    control="select"
                    name="txtDivision"
                    options={divisionList}
                    isLoading={isLoadingDivisionList}
                    getOptionLabel={(option) => `${option.DIVISIONNAME}`}
                    getOptionValue={(option) => `${option}`}
                    onChange={(e) => {
                      updateFormState(e, "txtDivision");
                    }}
                    value={formValues.txtDivision}
                  />
                </Form.InputGroup>
              </Form.Group>
              <Form.Group column={2} controlwidth="240px">
                <Form.InputGroup label="From Emp : " req="true" errorMsg={formValidationError["txtFromEmp"]}>
                  <Form.InputControl
                    control="select"
                    name="txtFromEmp"
                    options={employeeList}
                    isLoading={isLoadingEmployeeList}
                    getOptionLabel={(option) => `${option.EMPLOYEENAME}`}
                    getOptionValue={(option) => `${option}`}
                    onChange={(e) => {
                      updateFormState(e, "txtFromEmp");
                    }}
                    value={formValues.txtFromEmp}
                    isDisabled={!formValues.txtDivision}
                  />
                </Form.InputGroup>
                <Form.InputGroup label="To Emp : " req="true" errorMsg={formValidationError["txtToEmp"]}>
                  <Form.InputControl
                    control="select"
                    name="txtToEmp"
                    options={toEmployeeList}
                    isLoading={isLoadingEmployeeList}
                    getOptionLabel={(option) => `${option.EMPLOYEENAME}`}
                    getOptionValue={(option) => `${option}`}
                    onChange={(e) => {
                      updateFormState(e, "txtToEmp");
                    }}
                    value={formValues.txtToEmp}
                    isDisabled={!formValues.txtFromEmp}
                  />
                </Form.InputGroup>
              </Form.Group>
              <h4>Deputiton Type</h4>
              <Form.Group column={2} controlwidth="240px">
                <Form.InputGroup label="Temporary">
                  <Form.InputControl
                    control="radio"
                    name="Temporary"
                    onChange={(e) => {
                      updateFormState(e.target.name, "txtDeputionType");
                    }}
                    checked={formValues.txtDeputionType === "Temporary"}
                  />
                </Form.InputGroup>
                <Form.InputGroup label="Permanent">
                  <Form.InputControl
                    control="radio"
                    name="Permanent"
                    onChange={(e) => {
                      updateFormState(e.target.name, "txtDeputionType");
                    }}
                    checked={formValues.txtDeputionType === "Permanent"}
                  />
                </Form.InputGroup>
              </Form.Group>
              <h4>Deputiton Type</h4>
              <Form.Group column={2} controlwidth="240px">
                <Form.InputGroup
                  label="From Date :"
                  req={formValues.txtDeputionType === "Temporary" ? "true" : ""}
                  errorMsg={formValidationError["txtFromDate"]}
                >
                  <Form.InputControl
                    control="input"
                    type="Date"
                    name="txtFromDate"
                    onChange={(e) => {
                      updateFormState(e.target.value, "txtFromDate");
                    }}
                    value={formValues.txtFromDate}
                    disabled={formValues.txtDeputionType ? formValues.txtDeputionType === "Permanent" : true}
                  />
                </Form.InputGroup>
                <Form.InputGroup label="To Date : " req={formValues.txtDeputionType === "Temporary" ? "true" : ""} errorMsg={formValidationError["txtToDate"]}>
                  <Form.InputControl
                    control="input"
                    type="Date"
                    name="txtToDate"
                    min={formValues.txtFromDate}
                    onChange={(e) => {
                      updateFormState(e.target.value, "txtToDate");
                    }}
                    value={formValues.txtToDate}
                    disabled={formValues.txtDeputionType ? formValues.txtDeputionType === "Permanent" : true}
                  />
                </Form.InputGroup>
              </Form.Group>
              <h4>Deputiton Type</h4>
              <Form.Group column={2}>
                <Form.InputGroup label="Remark : " columnstart={1} column={3} row={6} req="true" errorMsg={formValidationError["txtReamark"]}>
                  <Form.InputControl
                    control="textarea"
                    name="txtReamark"
                    row={6}
                    onChange={(e) => {
                      updateFormState(e.target.value, "txtReamark");
                    }}
                    value={formValues.txtReamark}
                  />
                </Form.InputGroup>
              </Form.Group>
            </Form>
          </div>
          <div className={BizClass.AddWorkAssignmentGrid}>
            <DataGrid
              rowData={employeeAssetList}
              columnDefs={employeeAssetColumDef}
              loader={isLoadingEmployeeAssetList ? <Loader /> : null}
              onGridReady={onEmpAssetGridReady}
              rowSelection="multiple"
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          varient="secondary"
          trigger={isLoadingAddEmployeeAssign ? "true" : ""}
          onClick={(e) => {
            SaveWorkAssignment(e);
          }}
        >
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default AddWorkAssignment;
