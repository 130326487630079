import React, { useEffect } from "react";
import { DataGrid, PageBar } from "Framework/Components/Layout";
import { Loader } from "Framework/Components/Widgets";
import AlertsEventLogic from "./Logic/AlertsEventLogic";
import BizClass from "./AlertsEvents.module.scss";

function AlertsEvents() {
  const { getAlertsEventList, alertsEventList, isLoadingAlertsEventList, ListViewColumDef, onChangeSearchText, searchText, onGridReady } = AlertsEventLogic();

  useEffect(() => {
    getAlertsEventList();
  }, []);

  const exportToCSV = () => {
    if (alertsEventList && alertsEventList.length !== 0) {
        const headers = Object.keys(alertsEventList[0]);

        const csvContent = "data:text/csv;charset=utf-8," +
            headers.join(",") + "\n" +
            alertsEventList.map(row => headers.map(header => row[header]).join(",")).join("\n");
        const encodedUri = encodeURI(csvContent);
        const link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "Alerts/Events-List.csv");
        document.body.appendChild(link);
        link.click();
    }
    else {
        alert('No Data to Export!')
    }

};
  return (
    <div className={BizClass.AlertsEvents}>
      <PageBar>
        <PageBar.Search onChange={(e) => onChangeSearchText(e.target.value)} value={searchText} onClick={() => getAlertsEventList()} />
        <PageBar.Button varient="primary" onClick={exportToCSV} style={{margin:'5px'}}>
                            Export to CSV
                        </PageBar.Button>
      </PageBar>
      <DataGrid rowData={alertsEventList} columnDefs={ListViewColumDef} loader={isLoadingAlertsEventList ? <Loader /> : null} onGridReady={onGridReady} />
    </div>
  );
}

export default AlertsEvents;
