import React, { useEffect, useState, useRef } from "react";
import { Form, FormGroup, FormControl, Dropdown } from 'react-bootstrap';
import axios from "../../../../Configration/axios/axios";
import { PageBar } from "Framework/Components/Layout";
import { project } from "Service/Endpoints/endpoints";
import { getSessionStorage } from "Service/auth/auth";
import BizClass from "./LogSheets.module.scss";
import { Loader } from "Framework/Components/Widgets";
import 'bootstrap/dist/css/bootstrap.min.css';
import TeamsLogic from "./Logic/CreateLogSheetLogic"
import { DataGrid } from "Framework/Components/Layout";
import { Buffer } from "buffer";
import Multiselect from 'multiselect-react-dropdown';
import Modal from "Framework/Components/Layout/Modal/Modal";
import { MdArrowBack } from "react-icons/md";


const pako = require("pako");

const LogSheetForm = () => {

    const [questions, setQuestions] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [divisionType, setDivisionType] = useState([]);
    const [selectedDivisionType, setSelectedDivisionType] = useState('');
    const [unitInfo, setUnitInfo] = useState([]);
    const [selectedValues, setSelectedValues] = useState([]);
    const [deisgnationselectedValues, setDesignationSelectedValues] = useState([]);
    const [selectedDivisionValues, setselectedDivisionValues] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [logSheetName, setLogSheetName] = useState({});
    const [logSheetData, setLogSheetData] = useState([]);
    const [selectedLogSheet, setSelectedLogSheet] = useState('');
    const [editLogSheetModal, setEditLogSheetModal] = useState(false);
    const [questionId, setQuestionId] = useState({});
    const formRef = useRef();
    const editFormRef = useRef();


    const handleLogSheetChange = (event) => {
        setSelectedLogSheet(event.target.value);
        setEditLogSheetModal(true)
        // openLogSheet(event.target.value)
        getQuestionId(event.target.value)
    };

    const openLogSheet = async (logsheet) => {
        const logSheetsData = {
            sheetId: logsheet
        };

        try {
            const response = await axios.post(
                `${project.projectList.GetLogSheetDetailBySheetID}`,
                logSheetsData
            );

            const data = response.data.responseDynamic;
            const jsonData = JSON.parse(data);
            console.log("jsonData", jsonData)
            const questionIds = jsonData.Table.map(item => item.QUESTION_ID);
            const readingsJson = JSON.parse(jsonData.Table[0].LOG_JSON || jsonData.Table[0].READINGS_JSON);
            if (jsonData.Table[0].LOG_JSON) {
                console.log("readingsJson", readingsJson);
                setLogSheetName(readingsJson)
                setLogSheetData(readingsJson.questions)

            }
            else {
                console.log("readingsJson", readingsJson);
                setLogSheetName(readingsJson)
                setLogSheetData(readingsJson)
            }
        } catch (error) {
            console.error('Error fetching log sheet data:', error);
        }
    };

    const getQuestionId = async (logsheet) => {
        const logSheetsData = {
            sheetId: logsheet
        };

        try {
            const response = await axios.post(
                `${project.projectList.GetLogSheetRecordBySheetID}`,
                logSheetsData
            );

            const data = response.data.responseDynamic;
            const jsonData = JSON.parse(data);
            console.log("jsonData:", jsonData.Table.map(data => ({ QUESTION_ID: data.QUESTION_ID, QUESTION_TEXT: data.QUESTION_TEXT })));
            const question = jsonData.Table.map(data => ({ QUESTION_ID: data.QUESTION_ID, QUESTION_TEXT: data.QUESTION_TEXT }))
            setQuestionId(question)

            const readingsJson = JSON.parse(jsonData.Table[0].LOG_JSON || jsonData.Table[0].READINGS_JSON);
            if (jsonData.Table[0].LOG_JSON) {
                console.log("readingsJson", readingsJson);
                setLogSheetName(readingsJson)
                setLogSheetData(readingsJson.questions)

            }
            else {
                console.log("readingsJson", readingsJson);
                setLogSheetName(readingsJson)
                setLogSheetData(readingsJson)
            }
        } catch (error) {
            console.error('Error fetching log sheet data:', error);
        }
    };


    console.log("logSheetName", logSheetName)



    const designationOptions = [
        {
            value: 1,
            lable: "JE"
        },
        {
            value: 2,
            lable: "AE"
        },
        {
            value: 3,
            lable: "AEE"
        },
        {
            value: 4,
            lable: "EE"
        },
        {
            value: 5,
            lable: "SE"
        },
        {
            value: 6,
            lable: "CE"
        },
        {
            value: 7,
            lable: "ED"
        },
        {
            value: 8,
            lable: "TD"
        },
        {
            value: 9,
            lable: "MD"
        },
        {
            value: 10,
            lable: "DHR"
        },
    ]

    const onDesignationSelect = (selectedList, selectedItem) => {
        setDesignationSelectedValues(selectedList);
    };

    const onDesignationRemove = (selectedList, removedItem) => {
        setDesignationSelectedValues(selectedList);
    };

    const selectedProjectData = getSessionStorage("selctedProject");
    console.log("selectedProjectData", selectedProjectData)

    const onSelect = (selectedList, selectedItem) => {
        setSelectedValues(selectedList);
    };

    const onRemove = (selectedList, removedItem) => {
        setSelectedValues(selectedList);
    };

    const options = unitInfo.map((info) => ({
        value: info.unitID,
        label: info.unitName,
    }));

    console.log("selectedValues", selectedValues, "deisgnationselectedValues", deisgnationselectedValues);

    useEffect(() => {
        if (selectedValues.length !== 0 && deisgnationselectedValues.length !== 0) {
            const formData = {
                divisionID: 0,
                unitID: selectedValues.map(val => parseInt(val.value)).join(','),
                projectID: parseInt(selectedProjectData.PROJECTID, 10),
                employeeLevel: deisgnationselectedValues.map(val => parseInt(val.value)).join(','),
            };


            console.log("formData", formData);

            axios.post(`${project.projectList.GetLogSheetDetail}`, formData)
                .then((response) => {
                    console.log("response", response.data);
                    const dynamicData = JSON.parse(response.data.responseDynamic);
                    setTableData(dynamicData.Table);
                })
                .catch((error) => {
                    console.error("Error", error);
                });
        } else {
            console.error("Invalid unit or designation");
        }
    }, [selectedValues, deisgnationselectedValues, selectedProjectData]);





    const onDivisionsSelect = (selectedList, selectedItem) => {
        setselectedDivisionValues(selectedList);
    };

    const onDivisionRemove = (selectedList, removedItem) => {
        setselectedDivisionValues(selectedList);
    };







    const handleAddQuestion = (e) => {
        e.preventDefault();
        setQuestions((prevQuestions) => [
            ...prevQuestions,
            {
                id: generateUniqueId(),
                question: '',
                readings: 0,
                options: Array.from({ length: prevQuestions[0]?.readings || 0 }).map((_, optionIndex) => ({
                    id: generateUniqueId(),
                    optionText: `Option ${optionIndex + 1}`,
                    validationRequired: 'yes',
                    minValue: '',
                    maxValue: '',
                    suboptionsRequired: 'no',
                    suboptionCount: 0,
                    suboptions: [],
                })),
                suboptions: Array.from({ length: prevQuestions[0]?.readings || 0 }).map((_, optionIndex) => ({
                    id: generateUniqueId(),
                    suboptionText: `Suboption ${optionIndex + 1}`,
                    validationRequired: 'yes',
                    minValue: '',
                    maxValue: '',
                    suboptionsRequired: 'no',
                    suboptionCount: 0,
                    suboptions: [],
                })),
            },
        ]);
    };
    const generateUniqueId = () => {
        return '_' + Math.random().toString(36).substr(2, 9);
    };
    // const handleDeleteQuestion = (e, questionIndex) => {
    //     e.preventDefault();
    //     console.log('Deleting question at index:', questionIndex);
    //     console.log('Current questions:', questions);
    //     setQuestions((prevQuestions) => prevQuestions.filter((_, index) => index !== questionIndex));
    // };
    const handleDeleteQuestion = (e, questionId) => {
        e.preventDefault();
        console.log('Before deletion:', questions);
        setQuestions((prevQuestions) => prevQuestions.filter((question) => question.id !== questionId));
        console.log('After deletion:', questions);
    };
    const user = getSessionStorage("userData");
    console.log("user", user)








    const handleReadingInputChange = (event, questionIndex) => {
        const readings = parseInt(event.target.value, 10);
        const updatedQuestions = [...questions];
        updatedQuestions[questionIndex] = {
            ...updatedQuestions[questionIndex],
            readings: readings,
            options: Array.from({ length: readings }).map((_, optionIndex) => ({
                label: `Option ${optionIndex + 1}`,
                id: `option${questionIndex}_${optionIndex + 1}`,
                validationRequired: 'no',
                minValue: '',
                maxValue: '',
                suboptionsRequired: 'no',
                suboptionCount: 0,
                suboptions: [],
            })),

        };

        setQuestions(updatedQuestions);
    };

    const handleSuboptionsInputChange = (event, questionIndex, optionIndex) => {
        const suboptionsRequired = event.target.value;
        console.log('Suboptions Change:', { questionIndex, optionIndex, suboptionsRequired });
        const updatedQuestions = [...questions];
        updatedQuestions[questionIndex].options[optionIndex].suboptionsRequired = suboptionsRequired;
        if (suboptionsRequired === 'yes') {
            updatedQuestions[questionIndex].options[optionIndex].suboptionCount = 0;
            updatedQuestions[questionIndex].options[optionIndex].suboptions = [];
        }

        setQuestions(updatedQuestions);
    };

    const handleSuboptionCountChange = (event, questionIndex, optionIndex) => {
        const suboptionCount = parseInt(event.target.value, 10);
        console.log('Suboption Count Change:', { questionIndex, optionIndex, suboptionCount });
        const updatedQuestions = [...questions];
        updatedQuestions[questionIndex].options[optionIndex].suboptionCount = suboptionCount;

        if (suboptionCount > 0) {
            updatedQuestions[questionIndex].options[optionIndex].suboptions = Array.from({ length: suboptionCount }).map((_, suboptionIndex) => ({
                suboptionText: '',
                suboptionValidation: 'no',
            }));
        }

        setQuestions(updatedQuestions);
    };


    const handleValidationChange = (event, questionIndex, optionIndex) => {
        const validationRequired = event.target.value;
        console.log('Validation Change:', { questionIndex, optionIndex, validationRequired });
        const updatedQuestions = [...questions];
        updatedQuestions[questionIndex].options[optionIndex].validationRequired = validationRequired;
        setQuestions(updatedQuestions);
    };


    const handleSuboptionValidationChange = (event, questionIndex, optionIndex, suboptionIndex) => {
        const suboptionValidation = event.target.value;
        console.log('Suboption Validation Change:', { questionIndex, optionIndex, suboptionIndex, suboptionValidation });
        const updatedQuestions = [...questions];
        updatedQuestions[questionIndex].options[optionIndex].suboptions[suboptionIndex].suboptionValidation = suboptionValidation;
        setQuestions(updatedQuestions);
    };


    const handleDivisionTypeChange = (event) => {
        // Access the selected value using event.target.value

        const selectedValue = event.target.value;
        setSelectedDivisionType(selectedValue);

        // Call your function with the selected value
        // For example:
        divisionTypeApi(selectedValue);
    };


    const divisionTypeApi = (value) => {
        const formData = {
            ProjectID: selectedProjectData.PROJECTID,
            DivisionType: value,
        };
        console.log("formData", formData);

        axios.get(`${project.projectList.Get_All_Divisions_By_ProjectID}`, { params: formData })
            .then(async (response) => {
                console.log("Response", response);
                if (response && response.status === 200) {
                    const result = await response.data;
                    if (result.responseCode.toString() === "1") {
                        const buff = Buffer.from(result.responseDynamic ? result.responseDynamic : "", "base64");
                        if (buff.length !== 0) {
                            const data = JSON.parse(pako.inflate(buff, { to: "string" }));
                            console.log("Decoded JSON Data:", data.Table);
                            setDivisionType(data.Table)
                            return { responseCode: 1, responseData: data, responseMessage: result.responseMessage };
                        }
                        return { responseCode: 1, responseData: [], responseMessage: result.responseMessage };
                    }
                    return { responseCode: Number(result.responseCode), responseData: null, responseMessage: result.responseMessage };
                }
                return { responseCode: 0, responseData: null, responseMessage: "" };
            })
            .catch((error) => {
                console.error("Error", error);
            });
    };

    useEffect(() => {
        // Fetch data only when selectedDivisionType changes
        if (selectedDivisionType !== '') {
            divisionTypeApi(selectedDivisionType);
        }
    }, [selectedDivisionType]);

    console.log("divisionType", divisionType.TABLE)

    useEffect(() => {
        const unitInfo = async () => {
            try {
                const formData = {
                    projectID: selectedProjectData.PROJECTID
                };

                const response = await axios.post(`${project.projectList.GetUnittInfo}`, formData);
                console.log("Unit response", response.data.responseDynamic);
                setUnitInfo(response.data.responseDynamic);
            } catch (error) {
                console.error("Error fetching unit information:", error);
                // Handle error or set an error state
            }
        };

        unitInfo();

        // Cleanup function to cancel the Axios request if the component is unmounted
        return () => {
            // Cancel the request or perform any necessary cleanup
        };
    }, [selectedProjectData.PROJECTID]);  // Add any dependencies if needed


    console.log("unit", unitInfo)

    const closeEditModal = () => {
        setEditLogSheetModal(false);
    };

    const handleQuestionTextChange = (event, index) => {
        const updatedLogSheetData = [...logSheetData];
        updatedLogSheetData[index] = { ...updatedLogSheetData[index], questionText: event.target.value };
        setLogSheetData(updatedLogSheetData);
    };

    const handleMinValueChange = (event, questionIndex, optionIndex) => {
        const { value } = event.target;
        setLogSheetData(prevLogSheetData => {
            const updatedLogSheetData = [...prevLogSheetData];
            updatedLogSheetData[questionIndex].options[optionIndex].minValue = value;
            return updatedLogSheetData;
        });
    };

    const handleMaxValueChange = (event, questionIndex, optionIndex) => {
        const { value } = event.target;
        setLogSheetData(prevLogSheetData => {
            const updatedLogSheetData = [...prevLogSheetData];
            updatedLogSheetData[questionIndex].options[optionIndex].maxValue = value;
            return updatedLogSheetData;
        });
    };






    // useEffect(()=>{
    // const formData={ ProjectID : user.UserProfileID, DivisionType:}
    // axios.get(`${project.projectList.Get_All_Divisions_By_ProjectID}`,formData).then((response)=>{ console.log("response",response)})    

    // })

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (selectedValues.length === 0) {
            alert('Please select at least one value in Unit');
            return;
        }

        if (deisgnationselectedValues.length === 0) {
            alert('Please select at least one value in Designation');
            return;
        }

        if (divisionType.length === 0) {
            alert('Please Select Division Type')
            return;
        }


        if (selectedDivisionValues.length === 0) {
            alert('Please Select Division ID');
            return;
        }

        const logSheetName = document.getElementById('logSheetName').value.trim();
        if (logSheetName.length === 0) {
            alert('Please Enter LogSheet Name');
            return;
        }


        try {
            setIsLoading(true);
            const formData = new FormData(e.target);
            console.log("formData", formData);
            // const questionsData = questions.map((question, questionIndex) => {
            //     const optionsData = question.options.map((option, optionIndex) => {
            //         const suboptionsData = option.suboptions.map((suboption, suboptionIndex) => {
            //             const suboptionValidation = formData.get(`suboptions[${questionIndex}][${optionIndex}][${suboptionIndex}][suboptionValidation]`);
            //             return {
            //                 suboptionText: formData.get(`suboptions[${questionIndex}][${optionIndex}][${suboptionIndex}][suboptionText]`) || '',
            //                 suboptionValidation: suboptionValidation,
            //                 suboptionMinValue: String(formData.get(`minValues[${questionIndex}][${suboptionIndex + 1}]`)) || '0',
            //                 suboptionMaxValue: String(formData.get(`maxValues[${questionIndex}][${suboptionIndex + 1}]`)) || '0',
            //                 suboptionValue:0
            //             };
            //         });
            //         return {
            //             optionText: formData.get(`option_counts[${questionIndex}][${optionIndex}]`) || "",
            //             validationRequired: formData.get(`validationRequired[${questionIndex}][${optionIndex + 1}]`).charAt(0).toUpperCase() + formData.get(`validationRequired[${questionIndex}][${optionIndex + 1}]`).slice(1) || '',
            //             minValue: String(Number(formData.get(`minValues[${questionIndex}][${optionIndex + 1}]`)) || '0'),
            //             maxValue: String(Number(formData.get(`maxValues[${questionIndex}][${optionIndex + 1}]`)) || '0'),
            //             optionValue:0,
            //             suboptionsRequired: suboptionsData.some(suboption => suboption.suboptionValidation) ? 'Yes' : 'No',
            //             suboptionCount: parseInt(formData.get(`suboption_counts[${questionIndex}][${optionIndex}]`), 10) || 0,
            //             suboptions: suboptionsData,
            //         };
            //     });

            //     return {
            //         questionText: formData.get(`questions[${questionIndex}]`),
            //         readingsCount: parseInt(formData.get(`reading_counts[${questionIndex}]`), 10),
            //         options: optionsData,
            //     };
            // });
            const questionsData = questions.map((question, questionIndex) => {
                const optionsData = question.options.map((option, optionIndex) => {
                    const suboptionsData = option.suboptions.map((suboption, suboptionIndex) => {
                        const suboptionValidation = formData.get(`suboptions[${questionIndex}][${optionIndex}][${suboptionIndex}][suboptionValidation]`);
                        return {
                            suboptionText: formData.get(`suboptions[${questionIndex}][${optionIndex}][${suboptionIndex}][suboptionText]`) || '',
                            suboptionValidation: suboptionValidation || '',
                            suboptionMinValue: String(formData.get(`minValues[${questionIndex}][${suboptionIndex + 1}]`)) || '0',
                            suboptionMaxValue: String(formData.get(`maxValues[${questionIndex}][${suboptionIndex + 1}]`)) || '0',
                            suboptionValue: 0
                        };
                    });

                    // If there are no suboptions, provide a default suboption
                    const defaultSuboption = {
                        suboptionText: "",
                        suboptionValidation: "",
                        suboptionMinValue: "",
                        suboptionMaxValue: ""
                    };

                    console.log("suboptionsData", suboptionsData);

                    return {
                        optionText: formData.get(`option_counts[${questionIndex}][${optionIndex}]`) || "",
                        validationRequired: formData.get(`validationRequired[${questionIndex}][${optionIndex + 1}]`).charAt(0).toUpperCase() + formData.get(`validationRequired[${questionIndex}][${optionIndex + 1}]`).slice(1) || '',
                        minValue: String(Number(formData.get(`minValues[${questionIndex}][${optionIndex + 1}]`)) || '0'),
                        maxValue: String(Number(formData.get(`maxValues[${questionIndex}][${optionIndex + 1}]`)) || '0'),
                        // optionValue: 0,
                        suboptionsRequired: suboptionsData.some(suboption => suboption.suboptionValidation) ? 'Yes' : 'No',
                        suboptionCount: parseInt(formData.get(`suboption_counts[${questionIndex}][${optionIndex}]`), 10) || 0,
                        suboptions: suboptionsData.length > 0 ? suboptionsData : [defaultSuboption],
                    };
                });

                return {
                    questionText: formData.get(`questions[${questionIndex}]`),
                    readingsCount: parseInt(formData.get(`reading_counts[${questionIndex}]`), 10),
                    options: optionsData,
                };
            });


            try {
                const apiresponse = await axios.post(`${project.projectList.InsertLogSheetDetail}`, {
                    // projectName: formData.get('projectName'),
                    unit: selectedValues.map((value) => value.value),
                    divisionId: selectedDivisionValues.map((value) => value.DIVISIONID),
                    projectID: [selectedProjectData.PROJECTID],
                    employeeLevel: deisgnationselectedValues.map((value) => value.value),
                    logSheetName: formData.get('logSheetName'),
                    jsonLog: "test",
                    questions: questionsData,
                }, {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });

                if (apiresponse.data.responseMessage === "Successfull!") {
                    alert('Log sheet of Api data saved successfully');
                } else {
                    alert('Failed to save log sheet data Api');
                }
            } catch (error) {
                console.error('Error in API request:', error);
                alert('Failed to save log sheet data Api');
            }
            finally {
                setIsLoading(false);
            }

        } catch (error) {
            console.error('Error while submitting the form', error);
            setIsLoading(false);
        }
    };

    const handleEditSubmit = (e) => {
        e.preventDefault();
        try {
            setIsLoading(true);
            if (editFormRef.current) {
                const formData = new FormData(editFormRef.current);
                console.log("Edit data form data:", formData);


                // const data = logSheetData.flatMap((logSheet, index) => {
                //     let previousQuestionText = null; // Variable to store the previous question text
                //     let previousQuestionID = null; // Variable to store the previous question ID

                //     return logSheet.options.flatMap(option => {
                //         return option.subOptions.flatMap(subOption => {
                //             return questionId
                //                 .filter(ques => ques.QUESTION_TEXT === logSheet.questionText) // Filter questionId array
                //                 .flatMap(ques => {
                //                     let questionID = null; // Initialize question ID to null
                //                     if (ques.QUESTION_TEXT !== previousQuestionText) { // If the current question text is different from the previous one
                //                         questionID = ques.QUESTION_ID; // Set the question ID
                //                         previousQuestionText = ques.QUESTION_TEXT; // Update previous question text
                //                         previousQuestionID = ques.QUESTION_ID; // Update previous question ID
                //                     }
                //                     // Return the object with or without questionID based on condition
                //                     return {
                //                         questionText: logSheet.questionText,
                //                         questionID: questionID,
                //                         optMinVal: option.minValue,
                //                         optMaxVal: option.maxValue,
                //                         suboptMinVal: subOption.minValue,
                //                         suboptMaxVal: subOption.maxValue
                //                     };
                //                 });
                //         });
                //     });
                // });
                // const processedQuestionText = new Set(); // Set to store processed questionText values


                // const data = logSheetData.flatMap((logSheet, index) => {
                //     let previousQuestionText = null; // Variable to store the previous question text
                //     let previousQuestionID = null; // Variable to store the previous question ID

                //     return logSheet.options.flatMap(option => {
                //         return option.subOptions.flatMap(subOption => {
                //             return questionId
                //                 .filter(ques => ques.QUESTION_TEXT === logSheet.questionText) // Filter questionId array
                //                 .flatMap(ques => {
                //                     let questionID = null; // Initialize question ID to null
                //                     if (ques.QUESTION_TEXT !== previousQuestionText) { // If the current question text is different from the previous one
                //                         if (!processedQuestionText.has(logSheet.questionText)) { // Check if questionText has not been processed before
                //                             questionID = ques.QUESTION_ID; // Set the question ID
                //                             processedQuestionText.add(logSheet.questionText); // Add questionText to the set
                //                         }
                //                         previousQuestionText = ques.QUESTION_TEXT; // Update previous question text
                //                         previousQuestionID = ques.QUESTION_ID; // Update previous question ID
                //                     }
                //                     // Return the object with or without questionID based on condition
                //                     return {
                //                         questionText: logSheet.questionText,
                //                         questionID: questionID,
                //                         optMinVal: option.minValue,
                //                         optMaxVal: option.maxValue,
                //                         suboptMinVal: subOption.minValue,
                //                         suboptMaxVal: subOption.maxValue
                //                     };
                //                 });
                //         });
                //     }).filter((item, index, self) =>
                //         index === self.findIndex((t) => (
                //             t.questionText === item.questionText
                //         ))
                //     );
                // });
                const processedQuestionText = new Set();

                var data = logSheetData.flatMap((logSheet, index) => {
                    let previousQuestionText = null;
                    let previousQuestionID = null;
                    return logSheet.options.flatMap(option => {
                        return option.subOptions.flatMap(subOption => {
                            return questionId
                                .filter(ques => ques.QUESTION_TEXT === logSheet.questionText)
                                .flatMap(ques => {
                                    let questionID = null;
                                    if (ques.QUESTION_TEXT !== previousQuestionText) {
                                        if (!processedQuestionText.has(logSheet.questionText)) {
                                            questionID = ques.QUESTION_ID;
                                            processedQuestionText.add(logSheet.questionText);
                                        }
                                        previousQuestionText = ques.QUESTION_TEXT;
                                        previousQuestionID = ques.QUESTION_ID;
                                    }

                                    let editedQuestionText = logSheet.questionText;
                                    console.log("editedQuestionText", editedQuestionText)



                                    return {
                                        questionText: [editedQuestionText],
                                        questionID: [questionID],
                                        optMinVal: [option.minValue || '0'],
                                        optMaxVal: [option.maxValue || '0'],
                                        suboptMinVal: [subOption.minValue || '0'],
                                        suboptMaxVal: [subOption.maxValue || '0']
                                    };
                                });
                        });
                    }).filter((item, index, self) =>
                        index === self.findIndex((t) => (
                            t.questionText[0] === item.questionText[0]
                        ))
                    );
                });


               



                axios.post(`${project.projectList.Update_Questions}`, data).then((response) => {
                    if (response.data.responseCode === '1') {
                        alert("Questions have been Edited SuccessFully");
                        setIsLoading(false);
                    }
                    else {
                        alert("Failed to Save the questions. Please Submit Again");
                        setIsLoading(false);
                    }
                })
            } else {
                console.error('Form reference is not valid');
            }
        } catch (error) {
            console.error('Error while submitting the form', error);
            setIsLoading(false);
        }
    }



    return (
        <div className={BizClass.PageStart}>
            <div className={BizClass.Card}>
                <PageBar className={BizClass.CustomPageBar}>
                    <span>Create Log Sheets</span>
                </PageBar>
                <div className="container py-4">
                    {isLoading === true ? <Loader /> : null}

                    <Form id="log-sheet-form" onSubmit={handleSubmit}>
                        <FormGroup>
                            <Form.Label htmlFor="projectName" className='py-2'>Project Name:</Form.Label>
                            <FormControl type="text" id="projectName" name="projectName" value={selectedProjectData.PROJECTNAME} />
                        </FormGroup>
                        <FormGroup>
                            <Form.Label htmlFor="unit" className='py-2' required>Unit ID:</Form.Label>
                            <Multiselect
                                options={options}
                                selectedValues={selectedValues}
                                onSelect={onSelect}
                                onRemove={onRemove}
                                displayValue="label"
                            />
                        </FormGroup>


                        <FormGroup>
                            <Form.Label htmlFor="logSheetName" className='py-2'>LogSheet Name:</Form.Label>
                            <FormControl type="text" id="logSheetName" name="logSheetName" placeholder="Enter Logsheet Name" />
                        </FormGroup>

                        <FormGroup>
                            <Form.Label htmlFor="divisionType" className='py-2' required>Division Type:</Form.Label>
                            <select id="divisionType" name="divisionType" className="form-control" onChange={handleDivisionTypeChange}>
                                <option value="">Select Division Type</option>
                                <option value="Y">Maintenance</option>
                                <option value="N">Operation</option>
                            </select>
                        </FormGroup>



                        <FormGroup>
                            <Form.Label htmlFor="divisionName" className='py-2' required>Division ID:</Form.Label>
                            {/* <select
                                id="divisionName"
                                name="divisionName"
                                className="form-control"
                                onChange={handleDivisionChange}
                                value={selectedDivision}
                            >
                                <option value="">Select Division ID</option>
                                {divisionType.map((type) => (
                                    <option key={type.DIVISIONID} value={type.DIVISIONID}>
                                        {type.DIVISIONNAME}
                                    </option>
                                ))}
                            </select> */}

                            <Multiselect
                                options={divisionType}
                                selectedValues={selectedDivisionValues}
                                onSelect={onDivisionsSelect}
                                onRemove={onDivisionRemove}
                                displayValue="DIVISIONNAME"
                            />
                        </FormGroup>


                        <FormGroup>
                            <Form.Label htmlFor="logSheetName" className='py-2' required>Designation:</Form.Label>
                            <Multiselect options={designationOptions} selectedValues={deisgnationselectedValues}
                                onSelect={onDesignationSelect}
                                onRemove={onDesignationRemove}
                                displayValue="lable" />
                        </FormGroup>


                        {tableData.length !== 0 && (
                            <FormGroup>
                                <Form.Label htmlFor="logSheetID" className='py-2' required>Logsheet:</Form.Label>
                                <select
                                    id="logsheet"
                                    name="divisionType"
                                    className="form-control"
                                    value={selectedLogSheet}
                                    onChange={handleLogSheetChange}
                                >
                                    <option value="">Select LogSheet</option>
                                    {tableData.map((data) => (
                                        <option key={data.LOG_SHEET_ID} value={data.LOG_SHEET_ID}>{data.LOG_SHEET_NAME}</option>
                                    ))}
                                </select>
                            </FormGroup>
                        )}


                        <div id="form-builder" className="py-3">
                            <div>
                                {questions.map((question, questionIndex) => (
                                    <div key={questionIndex} className="well well-sm question-container m-lg-3 py-3 px-3" style={{ backgroundColor: '#f5f5f5', borderRadius: '5px', marginBottom: '20px' }}>
                                        <button
                                            style={{ float: 'right' }}
                                            className="btn btn-danger delete-question"
                                            data-question={question.questionNumber}
                                            onClick={(e) => handleDeleteQuestion(e, question.id)}
                                        >
                                            Delete Question
                                        </button>


                                        <div className="question-section">
                                            <label style={{ color: 'red', fontSize: '18px' }} htmlFor={`question${questionIndex}`} className="py-2">
                                                Question {questionIndex + 1}:
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control question-input"
                                                id={`question${questionIndex}`}
                                                name={`questions[${questionIndex}]`}
                                                placeholder="Please Enter Question"
                                                required
                                            />
                                        </div>
                                        <div className="reading-section inline-group" id={`readings${questionIndex}`}>
                                            <label style={{ minWidth: '20%' }} htmlFor={`reading${questionIndex}`} className="py-2">
                                                Number of readings:
                                            </label>
                                            <input
                                                type="number"
                                                className="form-control reading-input"
                                                name={`reading_counts[${questionIndex}]`}
                                                id={`reading${questionIndex}`}
                                                onChange={(e) => handleReadingInputChange(e, questionIndex)}
                                                placeholder="Please Enter Readings"
                                                required
                                            />
                                        </div>
                                        <div className="options-container inline-group d-flex flex-wrap" id={`options${questionIndex}`} style={{ width: '100% !important' }}>
                                            {question.options.map((option, optionIndex) => (
                                                <div key={optionIndex} className="form-group d-flex flex-column flex-wrap py-2 mx-4">
                                                    <label htmlFor={`options${questionIndex}`} className="py-2">
                                                        Option {optionIndex + 1}:
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control reading-input"
                                                        name={`option_counts[${questionIndex}][${optionIndex}]`}
                                                        id={`options${questionIndex}_${optionIndex}`}
                                                        placeholder="Please Enter Option"
                                                    />
                                                    <div>
                                                        <label className="checkbox-inline py-2">Option Validation Required:</label>
                                                        <div>
                                                            <input
                                                                type="radio"
                                                                id={`validationNo_${questionIndex}_${optionIndex}`}
                                                                name={`validationRequired[${questionIndex}][${optionIndex + 1}]`}
                                                                value="no"
                                                                checked={option.validationRequired === 'no'}
                                                                onChange={(e) => handleValidationChange(e, questionIndex, optionIndex)}
                                                                className="m-1"
                                                            />
                                                            <label htmlFor={`validationNo_${questionIndex}_${optionIndex}`}>No</label>

                                                            <input
                                                                type="radio"
                                                                id={`validationYes_${questionIndex}_${optionIndex}`}
                                                                name={`validationRequired[${questionIndex}][${optionIndex + 1}]`}
                                                                value="yes"
                                                                checked={option.validationRequired === 'yes'}
                                                                onChange={(e) => handleValidationChange(e, questionIndex, optionIndex)}
                                                                className="m-1"
                                                            />
                                                            <label htmlFor={`validationYes_${questionIndex}_${optionIndex}`}>Yes</label>
                                                        </div>
                                                    </div>

                                                    {option.validationRequired === 'yes' && (
                                                        <div className="additional-validation py-2">
                                                            <div className="min-max-container">
                                                                <label className="checkbox-inline">Min Value:</label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control my-3"
                                                                    name={`minValues[${questionIndex}][${optionIndex + 1}]`}
                                                                    placeholder="Please Enter Min Value"
                                                                />
                                                                <label className="checkbox-inline">Max Value:</label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control my-3"
                                                                    name={`maxValues[${questionIndex}][${optionIndex + 1}]`}
                                                                    placeholder="Please Enter Max Value"
                                                                />
                                                            </div>
                                                        </div>
                                                    )}


                                                    <label className="checkbox-inline py-2">Suboptions Required:</label>
                                                    <div>
                                                        <input
                                                            type="radio"
                                                            id={`SubOptionNo_${questionIndex}_${optionIndex}`}
                                                            name={`suboptionvalidationRequired[${questionIndex}][${optionIndex + 1}]`}
                                                            value="no"
                                                            checked={option && option.suboptionsRequired === 'no'}
                                                            onChange={(e) => handleSuboptionsInputChange(e, questionIndex, optionIndex)}
                                                            className="m-1"
                                                        />
                                                        <label htmlFor={`suboptionvalidationNo_${questionIndex}_${optionIndex}`}>No</label>

                                                        <input
                                                            type="radio"
                                                            id={`suboptionvalidationYes_${questionIndex}_${optionIndex}`}
                                                            name={`suboptionvalidationRequired[${questionIndex}][${optionIndex + 1}]`}
                                                            value="yes"
                                                            checked={option && option.suboptionsRequired === 'yes'}
                                                            onChange={(e) => handleSuboptionsInputChange(e, questionIndex, optionIndex)}
                                                            className="m-1"
                                                        />
                                                        <label htmlFor={`suboptionvalidationYes_${questionIndex}_${optionIndex}`}>Yes</label>
                                                    </div>

                                                    {option && option.suboptionsRequired === 'yes' && (
                                                        <div>
                                                            <label style={{ minWidth: '20%' }} htmlFor={`suboption_counts_${questionIndex}_${optionIndex}`} className="py-2">
                                                                Number of SubOptions:
                                                            </label>
                                                            <input
                                                                type="number"
                                                                className="form-control reading-input"
                                                                name={`suboption_counts[${questionIndex}][${optionIndex}]`}
                                                                id={`suboption_counts_${questionIndex}_${optionIndex}`}
                                                                onChange={(e) => handleSuboptionCountChange(e, questionIndex, optionIndex)}
                                                                placeholder="Please Enter SubOptions"
                                                            />
                                                            {option.suboptions.map((suboption, suboptionIndex) => (
                                                                <div key={suboptionIndex} className="form-group d-flex flex-column py-2">
                                                                    <label htmlFor={`suboptionText_${questionIndex}_${optionIndex}_${suboptionIndex}`} className='py-2'>Suboption {suboptionIndex + 1} Text:</label>
                                                                    <input
                                                                        type="text"
                                                                        className="form-control reading-input"
                                                                        name={`suboptions[${questionIndex}][${optionIndex}][${suboptionIndex}][suboptionText]`}
                                                                        id={`suboptionText_${questionIndex}_${optionIndex}_${suboptionIndex}`}
                                                                        placeholder="Please Enter Suboption"
                                                                    />

                                                                    <label className='py-2'>Suboption {suboptionIndex + 1} Validation:</label>
                                                                    <div>
                                                                        <input
                                                                            type="radio"
                                                                            id={`suboptionValidationYes_${questionIndex}_${optionIndex}_${suboptionIndex}`}
                                                                            name={`suboptions[${questionIndex}][${optionIndex}][${suboptionIndex}][suboptionValidation]`}
                                                                            value="yes"
                                                                            checked={suboption.suboptionValidation === 'yes'}
                                                                            onChange={(e) => handleSuboptionValidationChange(e, questionIndex, optionIndex, suboptionIndex)}
                                                                            className="m-2"
                                                                        />
                                                                        <label htmlFor={`suboptionValidationYes_${questionIndex}_${optionIndex}_${suboptionIndex}`}>Yes</label>

                                                                        <input
                                                                            type="radio"
                                                                            id={`suboptionValidationNo_${questionIndex}_${optionIndex}_${suboptionIndex}`}
                                                                            name={`suboptions[${questionIndex}][${optionIndex}][${suboptionIndex}][suboptionValidation]`}
                                                                            value="no"
                                                                            checked={suboption.suboptionValidation === 'no'}
                                                                            onChange={(e) => handleSuboptionValidationChange(e, questionIndex, optionIndex, suboptionIndex)}
                                                                            className="m-2"
                                                                        />
                                                                        <label htmlFor={`suboptionValidationNo_${questionIndex}_${optionIndex}_${suboptionIndex}`}>No</label>
                                                                    </div>

                                                                    {suboption.suboptionValidation === 'yes' && (
                                                                        <div className="additional-validation py-2">
                                                                            <div className="min-max-container">
                                                                                <label className="checkbox-inline">Min Value:</label>
                                                                                <input
                                                                                    type="text"
                                                                                    className="form-control my-3"
                                                                                    name={`minValues[${questionIndex}][${suboptionIndex + 1}]`}
                                                                                    placeholder="Please Enter Min Value"
                                                                                />
                                                                                <label className="checkbox-inline">Max Value:</label>
                                                                                <input
                                                                                    type="text"
                                                                                    className="form-control my-3"
                                                                                    name={`maxValues[${questionIndex}][${suboptionIndex + 1}]`}
                                                                                    placeholder="Please Enter Max Value"
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            ))}
                                                        </div>
                                                    )}
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <button className="btn btn-primary" type="button" onClick={handleAddQuestion} style={{ margin: '3px' }}>
                                Add Question
                            </button>

                            {questions.length > 0 && (
                                <button type="submit" className="btn btn-success" style={{ width: '90px' }}>
                                    Submit
                                </button>
                            )
                            }

                        </div>
                    </Form>
                </div>

            </div>

            {editLogSheetModal && (
                <Modal varient="center"
                    title="Edit LogSheets"
                    index={2}
                    show={closeEditModal}
                    width="100%"
                    height="100%">
                    {isLoading === true ? <Loader /> : null}

                    <Modal.Body>
                        <PageBar style={{ display: 'flex', justifyContent: 'space-between' }}>
                            {/* <div>  <span>LogSheet Name - {logSheetName.LogSheetName}</span></div> */}

                            <div> <MdArrowBack />
                                <a onClick={closeEditModal} style={{ cursor: 'pointer' }}>Back</a></div>

                        </PageBar>
                        {isLoading === true ? <Loader /> : null}
                        <form ref={editFormRef} onSubmit={handleEditSubmit} className="my-4 mx-4">
                            {logSheetData.map((logSheet, index) => {
                                return (
                                    <div key={index} className="my-3 px-3 py-3 container" style={{ backgroundColor: '#f5f5f5', borderRadius: '15px', marginBottom: '20px' }}>
                                        <div className="mb-3">
                                            <label style={{ fontWeight: 'bold' }}>Question {index + 1}</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={logSheet.questionText}
                                                onChange={(event) => handleQuestionTextChange(event, index)}
                                            />
                                        </div>

                                        <div className="mb-3 row">
                                            {logSheet.options.map((option, optionIndex) => (
                                                <div key={optionIndex} className="col">
                                                    {option.subOptions && option.subOptions.length > 0 && (
                                                        <>
                                                            <label className="form-label" style={{ fontWeight: 'bold', fontSize: 'larger' }}>{option.optionText}</label><br />
                                                            {option.subOptions.map((suboption, suboptionIndex) => (
                                                                <React.Fragment key={suboptionIndex}>
                                                                    <label className="form-label" style={{ fontSize: 'larger' }}><h2>{suboption.suboptionText}</h2></label>
                                                                </React.Fragment>
                                                            ))}
                                                        </>
                                                    )}
                                                    <label>Min</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        value={option.minValue}
                                                        onChange={(event) => handleMinValueChange(event, index, optionIndex)}
                                                    />
                                                    <label>Max</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        value={option.maxValue}
                                                        onChange={(event) => handleMaxValueChange(event, index, optionIndex)}
                                                    />
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                );
                            })}

                            <button type="submit" className="btn btn-primary" onClick={(event) => { event.preventDefault(); handleEditSubmit(event); }}>
                                Submit
                            </button>
                        </form>

                    </Modal.Body>
                </Modal>
            )}


        </div>

    );
};

export default LogSheetForm;
