import React from "react";
import { Loader, Splitter } from "Framework/Components/Widgets";
import { DataGrid, PageBar, Footer } from "Framework/Components/Layout";
import ProfileManagementLogic from "./Logic/ProfileManagementLogic";
import BizClass from "./ProfileManagement.module.scss";

function ProfileManagement() {
  const {
    ProfileGridColumnDefs,
    profileListData,
    isLoadingProfileData,
    profileItemSearch,
    getProfileListData,
    onGridReady,
    onChangeProfileList,
    ProfileMenuGridColumnDefs,
    // userProfileListData,
    treeMenuListData,
    isLoadingUserProfileData,
    userProfileItemSearch,
    onChangeUserProfileList,
    onUserGridReady,
    getUserProfileSerchListData,
  } = ProfileManagementLogic();

  return (
    <div className={BizClass.PageStart}>
      <Splitter varient="column" template="1fr 9px 1.2fr">
        <div className={BizClass.Card}>
          <PageBar>
            <PageBar.Search value={profileItemSearch} onChange={(e) => onChangeProfileList(e.target.value)} onClick={() => getProfileListData()} />
            <PageBar.Button>Add</PageBar.Button>
          </PageBar>
          <DataGrid
            onGridReady={onGridReady}
            columnDefs={ProfileGridColumnDefs}
            rowData={profileListData}
            loader={isLoadingProfileData ? <Loader /> : null}
            getRowStyle={(data) => {
              if (data.data.IsSelected) {
                return { background: "#ffc176" };
              }
              return { background: "" };
            }}
          />
        </div>
        <div className={BizClass.Card}>
          <PageBar>
            <PageBar.Search
              value={userProfileItemSearch}
              onChange={(e) => onChangeUserProfileList(e.target.value)}
              onClick={() => getUserProfileSerchListData()}
            />
          </PageBar>
          <DataGrid
            columnDefs={ProfileMenuGridColumnDefs}
            rowData={treeMenuListData}
            loader={isLoadingUserProfileData ? <Loader /> : null}
            autoGroupColumnDef={{
              headerName: "Menu Name",
              minWidth: 220,
              cellRendererParams: {
                innerRenderer: (params) => {
                  return params.data.MenuName;
                },
              },
            }}
            treeData="true"
            animateRows="true"
            groupDefaultExpanded={-1}
            onGridReady={onUserGridReady}
            getDataPath={(data) => {
              return data.orgHierarchy;
            }}
          />
        </div>
      </Splitter>
      <Footer />
    </div>
  );
}

export default ProfileManagement;
