import React, { useEffect } from "react";
import { DataGrid, PageBar } from "Framework/Components/Layout";
import Modal from "Framework/Components/Layout/Modal/Modal";
import { Button, Loader } from "Framework/Components/Widgets";
import BizClass from "../AddObservations/AddObservations.module.scss";

function AddAssignedToPopUp(props) {
  const {
    toggleAssignedTo,
    TeamMemberListViewColumDef,
    getTeamsListingData,
    divsionList,
    isLoadingDivsion,
    selectedProjectData,
    setSelectedDivision,
    selectedDivision,
    teamsListMembersData,
    isLoadingTeamsMemberData,
    onTeamMemberGridReady,
    SaveSelectedAssignedToData,
    updateFormState,
    formValues,
    setTeamsListMembersData,
  } = props;

  useEffect(() => {
    getTeamsListingData(selectedProjectData);
    updateFormState([], "txtAssignedTo");
    setTeamsListMembersData([]);
  }, []);

  return (
    <Modal varient="center" title="Change Who This Task Is Assined To" index={2} show={toggleAssignedTo} width="754px" height="400px">
      <Modal.Body>
        <div className={BizClass.AssignedToGrid}>
          <PageBar>
            <span>Division :</span>
            <PageBar.Select
              focus="true"
              name="txtDivision"
              label="Division"
              options={divsionList}
              isLoading={isLoadingDivsion}
              value={selectedDivision}
              getOptionLabel={(option) => `${option.DIVISIONNAME}`}
              getOptionValue={(option) => `${option}`}
              onChange={(e) => setSelectedDivision(e)}
            />
          </PageBar>
          <DataGrid
            rowData={teamsListMembersData}
            columnDefs={TeamMemberListViewColumDef}
            loader={isLoadingTeamsMemberData ? <Loader /> : null}
            onGridReady={onTeamMemberGridReady}
            rowSelection={formValues.txtShift ? "multiple" : "single"}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button varient="primary" onClick={() => SaveSelectedAssignedToData()}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default AddAssignedToPopUp;
