import React from "react";
import { RiPagesLine } from "react-icons/ri";
import PropTypes from "prop-types";
import BizClass from "./Header.module.scss";
import HeaderLogic from "./Logic/HeaderLogic";
import { getSessionStorage } from "Service/auth/auth";


function Header({ pagetitle }) {
  const { logout } = HeaderLogic();
  const user = getSessionStorage("userData");
  console.log("user", user)
  return (
    <div className={BizClass.Box}>
      <div className={BizClass.PageTitle}>
        {pagetitle ? (
          <>
            <RiPagesLine />
            <p>{pagetitle}</p>
          </>
        ) : null}
      </div>
      <div className="d-flex justify-content-end">
        <span style={{ fontWeight: 'bold' }}>Employee Name: {user.EmployeeName}</span> &nbsp;&nbsp;&nbsp;
        <br />
        <span style={{ fontWeight: 'bold' }}>Employee ID: {user.UserName}</span> &nbsp;&nbsp;&nbsp;
        <br />
        <span style={{ fontWeight: 'bold' }}>Division Name: {user.DIVISION_NAME}</span>
      </div>


      <button type="button" onClick={() => logout()} style={{ width: "80px", marginLeft: "auto" }}>
        Logout
      </button>
    </div>
  );
}

export default Header;

Header.propTypes = {
  pagetitle: PropTypes.string.isRequired,
};
