import React, { useEffect, useState } from "react";
import { FiPhoneCall } from "react-icons/fi";
import { GrMapLocation } from "react-icons/gr";
import PropTypes from "prop-types";
import BizClass from "./ProjectsBoardView.module.scss";
import { project } from "Service/Endpoints/endpoints";
import axios from "../../../../../../../Configration/axios/axios";
import { getSessionStorage } from "Service/auth/auth";
import { AlertMessage } from "Framework/Components/Widgets";
import GetApiData from "Configration/Utilities/getApiData/GetApiData";

function ProjectsBoardView({ toggleDetailModal, filteredProjectListData }) {

  const setAlertMessage = AlertMessage();
  const [defectCount, setDefectCount] = useState(0);
  const [ptwCount, setPtwCount] = useState(0);
  const [pmlist, setpmList] = useState([]);
  const [isLoadingpmList, setisLoadingpmList] = useState(false);
  const [pmlistContents, setPmListContents] = useState(0);
  const [pmListGridApi, setpmListGridApi] = useState();

  const fetchData = () => {
    const selectedProjectData = getSessionStorage("selctedProject");
    const user = getSessionStorage("userData");

    console.log("selectedProjectData", selectedProjectData);

    axios.get(`${project.projectList.GetDefectDetails}?DivisionID=${user.DIVISION_ID}`).then((response) => {
      // Check if response.data is an array and count its length
      const count = Array.isArray(response.data.responseDynamic) ? response.data.responseDynamic.length : 0;

      console.log("Defect Count:", count);
      setDefectCount(count)
    }).catch((error) => {
      console.error("Error fetching defects:", error);
    });



    axios
      .get(`${project.projectList.GetEmployeeIDDetails}?ProjectID=${user.PROJECT_ID}&DivisionID=${user.DIVISION_ID}`)
      .then((response) => {
        // Assuming the response contains employeeIDs
        console.log("response", response)
        const data = response.data.responseDynamic;
        console.log("data", data)
        setPtwCount(data);

      })

    const getpmListing = () => {
      // setSelectedProjectData(ProjectData);
      const formData = {
        main: {
          divisionid: (user && user.DIVISION_ID ? user.DIVISION_ID : "").toString(),
          profileid: user && user.UserProfileID ? user.UserProfileID : null,
          projectid: user && user.PROJECT_ID ? user.PROJECT_ID : 0,
        },
      };
      console.log("formData", formData)
      const request = {
        setDatalist: setpmList,
        setLoad: setisLoadingpmList,
        requestData: formData,
        apiPath: project.projectList.GetKPCLAssetSelect,
        setAlert: setAlertMessage,
        fun: null,
      };
      GetApiData(request);
    };

    getpmListing();

    const filterPmList = () => {
      const filteredList = pmlist.filter((list) => list.ASSET_STATUS === 'MAINTENANCE');
      console.log("filteredList", filteredList.length);
      const pmlistData = filteredList.length
      setPmListContents(pmlistData)
    };

    filterPmList();





  };



  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className={BizClass.MainSection}>
      {filteredProjectListData &&
        filteredProjectListData.map((data, i) => {
          return (
            <button type="button" key={i} className={BizClass.DataCard} onClick={() => toggleDetailModal(true, data)}>
              <h4>
                <span>{data && data.PROJECTNAME.split(" ")[0].toUpperCase()}</span> / {data && data.PROJECTNAME}
              </h4>
              <div className={BizClass.Details}>
                <p>
                  <FiPhoneCall />
                  +91 {data && data.PHEADMOBILENO}
                </p>
              </div>
              <h5>
                <GrMapLocation />
                {data && data.PADDRESS}
              </h5>
              <div className={BizClass.DataTabs} style={{ display: 'flex', justifyContent: 'center' }}>
                <div>
                  <span>{defectCount}</span>
                  <p>Defect List</p>
                </div>
                <div>
                  <span>{pmlistContents}</span>
                  <p>Planned Maintenance</p>
                </div>
                <div>
                  <span>{ptwCount ? ptwCount : 0}</span>
                  <p>PTW Requests</p>
                </div>
                {/* <div>
                  <span>0</span>
                  <p>Affected Assets</p>
                </div> */}
              </div>
            </button>
          );
        })}
    </div>
  );
}

export default ProjectsBoardView;

ProjectsBoardView.propTypes = {
  toggleDetailModal: PropTypes.func.isRequired,
};
