import GetApiData from "Configration/Utilities/getApiData/GetApiData";
import { AlertMessage } from "Framework/Components/Widgets";
import moment from "moment";
import { useEffect, useState } from "react";
import { getSessionStorage } from "Service/auth/auth";
import { project } from "Service/Endpoints/endpoints";
// import { apiCalling } from "Service/Utilities/apiCalling";

function WorkAssignmentLogic() {
  const setAlertMessage = AlertMessage();
  const [selectedProjectData, setSelectedProjectData] = useState(null);
  const user = getSessionStorage("userData");

  // toggle State
  const [isActiveWorkAssignmentPopUp, setIsActiveWorkAssignmentPopUp] = useState(false);

  // getWorkingAssignment State
  const [workAssignmentList, setWorkAssignmentList] = useState([]);
  const [isLoadingWorkAssignment, setIsLoadingWorkAssignment] = useState(false);

  // get Division Detail List state
  const [divisionList, setDivisionList] = useState([]);
  const [isLoadingDivisionList, setIsLoadingDivisionList] = useState(false);

  // get employee List state
  const [employeeList, setEmployeeList] = useState([]);
  const [toEmployeeList, setToEmployeeList] = useState([]);
  const [isLoadingEmployeeList, setIsLoadingEmployeeList] = useState(false);

  // get employeeAsset List state
  const [employeeAssetList, setEmployeeAssetList] = useState([]);
  const [isLoadingEmployeeAssetList, setIsLoadingEmployeeAssetList] = useState(false);

  // get newAddedEmployee state
  const [isLoadingAddEmployeeAssign, setIsLoadingAddEmployeeAssign] = useState(false);
  const [newAddedEmployeeAssign, setNewAddedEmployeeAssign] = useState([]);

  // get employee GRID API state
  const [empAssetGridApi, setEmpAssetGridApi] = useState([]);
  const [gridApi, setGridApi] = useState();
  const [searchText, setSearchText] = useState("");

  // form value State
  const [formValues, setFormValues] = useState({
    txtDivision: null,
    txtFromEmp: null,
    txtToEmp: null,
    txtDeputionType: "",
    txtFromDate: "",
    txtToDate: "",
    txtReamark: "",
  });

  // formValidationState
  const [formValidationError, setFormValidationError] = useState({});

  /* ************************************* Code For GridColumDef start ***************************** */
  const [AssingmentColumDef] = useState([
    { field: "#", headerName: "Sr No.", pinned: "left", valueGetter: "node.rowIndex + 1", width: 80 },
    {
      field: "FROMEMPLOYEE",
      headerName: "From Employee",
      width: 180,
      cellRenderer: (node) => {
        return node.value ? `${node.value} (${node.data.FROMEMPLOYEEID})` : "";
      },
    },
    {
      field: "TOEMPLOYEE",
      headerName: "To Employee ",
      width: 180,
      cellRenderer: (node) => {
        return node.value ? `${node.value} (${node.data.TOEMPLOYEEID})` : "";
      },
    },
    { field: "ASSIGNMENTTYPE", headerName: "Type Of Assignment", width: 180 },
    {
      field: "FROMDATE",
      headerName: "From Date",
      width: 180,
      cellRenderer: (node) => {
        return node.value ? moment(node.value).format("DD-MM-YYYY") : "";
      },
    },
    {
      field: "TODATE",
      headerName: "To Date",
      width: 180,
      cellRenderer: (node) => {
        return node.value ? moment(node.value).format("DD-MM-YYYY") : "";
      },
    },
    { field: "CREATEDBY", headerName: "Created By ", width: 180 },
    {
      field: "ACTIONDATE",
      headerName: "Created Date",
      width: 180,
      cellRenderer: (node) => {
        return node.value ? moment(node.value).format("DD-MM-YYYY") : "";
      },
    },
    // { field: "MASTERDISPLAYNAME", headerName: "Status", width: 180 },
  ]);

  const [employeeAssetColumDef] = useState([
    { field: "#", headerName: "Action", width: 80, checkboxSelection: true, showDisabledCheckboxes: true, headerCheckboxSelection: true },
    { field: "ASSETNAME", headerName: "Asset Name", width: 220 },
    { field: "ASSETCODE", headerName: "Asset Code", width: 120 },
  ]);
  /* ************************************* Code For GridColumDef end ***************************** */

  /* ************************************* Code For toggle popup start ***************************** */

  const toggleAddWorkAssignmentpopup = () => {
    setIsActiveWorkAssignmentPopUp(!isActiveWorkAssignmentPopUp);
  };

  /* ************************************* Code For toggle popup end ***************************** */

  /* ************************************* Code For OnGridRady Function Start ***************************** */
  const onEmpAssetGridReady = (params) => {
    setEmpAssetGridApi(params.api);
  };

  /* ************************************* Code For OnGridRady Function End ***************************** */

  /* ************************************* Code For clear form  start ***************************** */
  const clearForm = () => {
    setFormValues({
      txtDivision: null,
      txtFromEmp: null,
      txtToEmp: null,
      txtDeputionType: "",
      txtFromDate: "",
      txtToDate: "",
      txtReamark: "",
    });
    setEmployeeAssetList([]);
  };

  /* ************************************* Code For clear form  end ***************************** */

  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  const onChangeSearchText = (val) => {
    setSearchText(val);
    gridApi.setQuickFilter(val);
    gridApi.refreshCells();
  };

  const cleareSerchText = (data) => {
    if (data) {
      if (searchText && searchText.toLowerCase().includes("#")) {
        onChangeSearchText("");
      }
    }
  };

  /* ************************************* Code For Date Format  start ***************************** */
  const dateToSpecificFormat = (date, format) => {
    try {
      const d = new Date(date);
      const convertedDate = moment(d).format(format);
      return convertedDate;
    } catch {
      return null;
    }
  };

  /* ************************************* Code For Date Format  start ***************************** */

  /* ************************************* Code For Api Calling Start ***************************** */

  // getWorkingAssignMent List

  // const getWorkingAssignmentList = async (ProjectData) => {
  //   setSelectedProjectData(ProjectData);
  //   try {
  //     setIsLoadingWorkAssignment(true);
  //     const formData = {
  //       main: {
  //         divisionid:
  //           user && user.UserProfileID
  //             ? user.UserProfileID.toString() === "100301" || user.UserProfileID.toString() === "100302"
  //               ? ProjectData && ProjectData.DIVISIONIDs
  //                 ? ProjectData.DIVISIONIDs
  //                 : ""
  //               : ""
  //             : "",
  //         profileid: user && user.UserProfileID ? user.UserProfileID : null,
  //         projectid: ProjectData && ProjectData.PROJECTID ? ProjectData.PROJECTID : "",
  //       },
  //     };
  //     const result = await apiCalling(formData, project.projectList.GetAssetEmployeeAssignment);
  //     setIsLoadingWorkAssignment(false);
  //     console.log(result, project.projectList.GetAssetEmployeeAssignment);
  //     if (result && result.responseCode === 1) {
  //       if (result && result.responseData && result.responseData.Table) {
  //         setWorkAssignmentList(result.responseData.Table);
  //       }
  //     } else {
  //       setAlertMessage({
  //         type: "error",
  //         message: result.responseMessage,
  //       });
  //       setWorkAssignmentList([]);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //     setAlertMessage({
  //       type: "error",
  //       message: error,
  //     });
  //     setWorkAssignmentList([]);
  //   }
  // };

  const getWorkingAssignmentList = async (ProjectData) => {
    setSelectedProjectData(ProjectData);
    const formData = {
      main: {
        divisionid: ProjectData && ProjectData.DIVISIONIDs ? ProjectData.DIVISIONIDs : "",
        profileid: user && user.UserProfileID ? user.UserProfileID : null,
        projectid: ProjectData && ProjectData.PROJECTID ? ProjectData.PROJECTID : "",
      },
    };

    const request = {
      setDatalist: setWorkAssignmentList,
      setLoad: setIsLoadingWorkAssignment,
      requestData: formData,
      apiPath: project.projectList.GetAssetEmployeeAssignment,
      setAlert: setAlertMessage,
      fun: cleareSerchText,
    };
    GetApiData(request);
  };

  // get Divisionlist
  // const getDivisionDetailsList = async () => {
  //   try {
  //     setIsLoadingDivisionList(true);
  //     const formData = {
  //       main: {
  //         divisionid:
  //           user && user.UserProfileID
  //             ? user.UserProfileID.toString() === "100301" || user.UserProfileID.toString() === "100302"
  //               ? selectedProjectData && selectedProjectData.DIVISIONIDs
  //                 ? selectedProjectData.DIVISIONIDs
  //                 : ""
  //               : ""
  //             : "",
  //         profileid: user && user.UserProfileID ? user.UserProfileID : null,
  //         projectid: selectedProjectData && selectedProjectData.PROJECTID ? selectedProjectData.PROJECTID : "",
  //       },
  //     };
  //     const result = await apiCalling(formData, project.projectList.GetKPCLDivisionDetail);
  //     setIsLoadingDivisionList(false);
  //     console.log(result);
  //     if (result && result.responseCode === 1) {
  //       if (result && result.responseData && result.responseData.Table) {
  //         setDivisionList(result.responseData.Table);
  //       }
  //     } else {
  //       setAlertMessage({
  //         type: "error",
  //         message: result.responseMessage,
  //       });
  //       setDivisionList([]);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //     setAlertMessage({
  //       type: "error",
  //       message: error,
  //     });
  //     setDivisionList([]);
  //   }
  // };

  const getDivisionDetailsList = async () => {
    const formData = {
      main: {
        divisionid: selectedProjectData && selectedProjectData.DIVISIONIDs ? selectedProjectData.DIVISIONIDs : "",
        profileid: user && user.UserProfileID ? user.UserProfileID : null,
        projectid: selectedProjectData && selectedProjectData.PROJECTID ? selectedProjectData.PROJECTID : "",
      },
    };

    const request = {
      setDatalist: setDivisionList,
      setLoad: setIsLoadingDivisionList,
      requestData: formData,
      apiPath: project.projectList.GetKPCLDivisionDetail,
      setAlert: setAlertMessage,
      fun: null,
    };
    GetApiData(request);
  };

  // get EmployeeList
  // const getEmployeeList = async (selectedDivision) => {
  //   try {
  //     setIsLoadingEmployeeList(true);
  //     const formData = {
  //       main: {
  //         divisionid: selectedDivision && selectedDivision.DIVISIONID ? selectedDivision.DIVISIONID.toString() : "",
  //         profileid: user && user.UserProfileID ? user.UserProfileID : null,
  //         projectid: selectedDivision && selectedDivision.PROJECTID ? selectedDivision.PROJECTID : "",
  //       },
  //     };
  //     const result = await apiCalling(formData, project.projectList.GetKPCLTeam);
  //     setIsLoadingEmployeeList(false);
  //     console.log(result);
  //     if (result && result.responseCode === 1) {
  //       if (result && result.responseData && result.responseData.Table) {
  //         setEmployeeList(result.responseData.Table);
  //       }
  //     } else {
  //       setAlertMessage({
  //         type: "error",
  //         message: result.responseMessage,
  //       });
  //       setEmployeeList([]);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //     setAlertMessage({
  //       type: "error",
  //       message: error,
  //     });
  //     setEmployeeList([]);
  //   }
  // };

  const getEmployeeList = async (selectedDivision) => {
    const formData = {
      main: {
        divisionid: selectedDivision && selectedDivision.DIVISIONID ? selectedDivision.DIVISIONID.toString() : "",
        profileid: user && user.UserProfileID ? user.UserProfileID : null,
        projectid: selectedDivision && selectedDivision.PROJECTID ? selectedDivision.PROJECTID : "",
      },
    };

    const request = {
      setDatalist: setEmployeeList,
      setLoad: setIsLoadingEmployeeList,
      requestData: formData,
      apiPath: project.projectList.GetKPCLTeam,
      setAlert: setAlertMessage,
      fun: null,
    };
    GetApiData(request);
  };

  // get EmployeeAsset
  // const getEmployeeAsset = async (employee) => {
  //   try {
  //     setIsLoadingEmployeeAssetList(true);
  //     const formData = {
  //       main: {
  //         employeeid: employee && employee.EMPLOYEEID ? employee.EMPLOYEEID : 0,
  //       },
  //     };
  //     const result = await apiCalling(formData, project.projectList.GetEmployeeAssetSelect);
  //     setIsLoadingEmployeeAssetList(false);
  //     console.log(result, project.projectList.GetEmployeeAssetSelect);
  //     if (result && result.responseCode === 1) {
  //       if (result && result.responseData && result.responseData.Table) {
  //         setEmployeeAssetList(result.responseData.Table);
  //       }
  //     } else {
  //       setAlertMessage({
  //         type: "error",
  //         message: result.responseMessage,
  //       });
  //       setEmployeeAssetList([]);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //     setAlertMessage({
  //       type: "error",
  //       message: error,
  //     });
  //     setEmployeeAssetList([]);
  //   }
  // };

  const getEmployeeAsset = async (employee) => {
    setIsLoadingEmployeeAssetList(true);
    const formData = {
      main: {
        employeeid: employee && employee.EMPLOYEEID ? employee.EMPLOYEEID : 0,
      },
    };
    const request = {
      setDatalist: setEmployeeAssetList,
      setLoad: setIsLoadingEmployeeAssetList,
      requestData: formData,
      apiPath: project.projectList.GetEmployeeAssetSelect,
      setAlert: setAlertMessage,
      fun: null,
    };
    GetApiData(request);
  };

  // get AddAssetEmployeeAssign
  // const AddAssetEmployeeAssign = async (data) => {
  //   try {
  //     setIsLoadingAddEmployeeAssign(true);
  //     const getId = data.map((x) => {
  //       return x.ASSETCODE;
  //     });
  //     console.log(getId);
  //     const formData = {
  //       main: {
  //         assetemployeeassignmentid: 0,
  //         equipmentnumber: getId.toString(),
  //         lastemployee: formValues.txtFromEmp && formValues.txtFromEmp.EMPLOYEEID ? formValues.txtFromEmp.EMPLOYEEID : 0,
  //         employeeid: formValues.txtToEmp && formValues.txtToEmp.EMPLOYEEID ? formValues.txtToEmp.EMPLOYEEID : 0,
  //         startdate: formValues.txtFromDate ? dateToSpecificFormat(moment(formValues.txtFromDate), "DD-MM-YYYY") : "",
  //         enddate: formValues.txtToDate ? dateToSpecificFormat(moment(formValues.txtToDate), "DD-MM-YYYY") : "",
  //         isactive: 1,
  //         ispermanent: formValues.txtDeputionType === "Permanent" ? 1 : 0,
  //       },
  //     };
  //     const result = await apiCalling(formData, project.projectList.AddAssetEmployeeAssignment);
  //     setIsLoadingAddEmployeeAssign(false);
  //     console.log(result, project.projectList.AddAssetEmployeeAssignment);
  //     if (result && result.responseCode === 1) {
  //       setAlertMessage({
  //         type: "success",
  //         message: result.responseMessage,
  //       });
  //       clearForm();
  //       getWorkingAssignmentList(selectedProjectData);
  //       // if (result && result.responseData && result.responseData.Table) {
  //       //   setNewAddedEmployeeAssign(result.responseData.Table);
  //       // }
  //     } else {
  //       setAlertMessage({
  //         type: "error",
  //         message: result.responseMessage,
  //       });
  //       setNewAddedEmployeeAssign([]);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //     setAlertMessage({
  //       type: "error",
  //       message: error,
  //     });
  //     setNewAddedEmployeeAssign([]);
  //   }
  // };

  const AddAssetEmployeeAssign = (data) => {
    const getId = data.map((x) => {
      return x.ASSETCODE;
    });
    console.log(getId);
    const formData = {
      main: {
        assetemployeeassignmentid: 0,
        equipmentnumber: getId.toString(),
        lastemployee: formValues.txtFromEmp && formValues.txtFromEmp.EMPLOYEEID ? formValues.txtFromEmp.EMPLOYEEID : 0,
        employeeid: formValues.txtToEmp && formValues.txtToEmp.EMPLOYEEID ? formValues.txtToEmp.EMPLOYEEID : 0,
        startdate: formValues.txtFromDate ? dateToSpecificFormat(moment(formValues.txtFromDate), "DD-MM-YYYY") : "",
        enddate: formValues.txtToDate ? dateToSpecificFormat(moment(formValues.txtToDate), "DD-MM-YYYY") : "",
        isactive: 1,
        ispermanent: formValues.txtDeputionType === "Permanent" ? 1 : 0,
      },
    };

    const cleareFormAndgetlistFunction = () => {
      clearForm();
      getWorkingAssignmentList(selectedProjectData);
    };

    const request = {
      setDatalist: setNewAddedEmployeeAssign,
      setLoad: setIsLoadingAddEmployeeAssign,
      requestData: formData,
      apiPath: project.projectList.AddAssetEmployeeAssignment,
      setAlert: setAlertMessage,
      fun: cleareFormAndgetlistFunction,
      message: true,
    };
    GetApiData(request);
  };

  /* ************************************* Code For Api Calling end ***************************** */

  /* ************************************* Code For ValidateFild  && Update State Start ***************************** */

  const validateField = (name, value) => {
    let errorsMsg = "";
    if (name === "txtFromEmp") {
      if (formValues.txtDivision) {
        if (!value || typeof value === "undefined" || value === null) {
          errorsMsg = "Please Select From Employee ";
        }
      }
    } else if (name === "txtToEmp") {
      if (formValues.txtFromEmp) {
        if (!value || typeof value === "undefined" || value === null) {
          errorsMsg = "Please Select To Employee";
        }
      }
    } else if (name === "txtDivision") {
      if (!value || typeof value === "undefined" || value === null) {
        errorsMsg = "Please Select Division";
      }
    } else if (name === "txtReamark") {
      if (!value || typeof value === "undefined" || value === null) {
        errorsMsg = "can not be empty";
      }
    } else if (name === "txtFromDate") {
      if (formValues.txtDeputionType && formValues.txtDeputionType === "Temporary") {
        if (!value || typeof value === "undefined" || value === null) {
          errorsMsg = "Please Select From Date";
        }
      }
    } else if (name === "txtToDate") {
      if (formValues.txtDeputionType === "Temporary") {
        if (!value || typeof value === "undefined" || value === null) {
          errorsMsg = "Please Select To Date";
        }
      }
    }
    console.log(errorsMsg);
    return errorsMsg;
  };

  const updateFormState = (value, name) => {
    console.log(formValues);
    formValidationError[name] = validateField(name, value);
    setFormValues((values) => ({
      ...values,
      [name]: value,
    }));
    if (name === "txtDivision") {
      if (!value || typeof value === "undefined" || value === null) {
        setEmployeeList([]);
        setFormValues((values) => ({
          ...values,
          txtFromEmp: null,
          txtToEmp: null,
        }));
      } else {
        setFormValues((values) => ({
          ...values,
          txtFromEmp: null,
          txtToEmp: null,
        }));
        getEmployeeList(value);
      }
    }
    if (name === "txtFromEmp") {
      if (!value || typeof value === "undefined" || value === null) {
        setEmployeeAssetList([]);
        setFormValues((values) => ({
          ...values,
          txtToEmp: null,
        }));
      } else {
        setFormValues((values) => ({
          ...values,
          txtToEmp: null,
        }));
        const oldToEmployeeList = employeeList;
        const filterData = oldToEmployeeList.filter((x) => x.EMPLOYEEID !== value.EMPLOYEEID);
        setToEmployeeList(filterData);
        getEmployeeAsset(value);
      }
    }
    if (name === "txtDeputionType") {
      if (value || typeof value !== "undefined" || value !== null) {
        if (value === "Permanent") {
          setFormValues((values) => ({
            ...values,
            txtToDate: "",
          }));
        }
      }
    }
  };

  const handleValidation = () => {
    const errors = {};
    let formIsValid = true;
    errors["txtFromEmp"] = validateField("txtFromEmp", formValues.txtFromEmp);
    errors["txtToEmp"] = validateField("txtToEmp", formValues.txtToEmp);
    errors["txtDivision"] = validateField("txtDivision", formValues.txtDivision);
    errors["txtFromDate"] = validateField("txtFromDate", formValues.txtFromDate);
    errors["txtToDate"] = validateField("txtToDate", formValues.txtToDate);
    errors["txtReamark"] = validateField("txtReamark", formValues.txtReamark);

    if (Object.values(errors).join("").toString()) {
      formIsValid = false;
    }
    setFormValidationError(errors);
    console.log(errors);
    return formIsValid;
  };

  /* ************************************* Code For ValidateFild  && Update State End ***************************** */

  /* ************************************* Code For getSelectedRowData start ***************************** */

  // Code for Assets checking Checked data
  const getSelectedRowData = () => {
    const selectedNodes = empAssetGridApi.getSelectedNodes();
    const selectedData = selectedNodes.map((node) => node.data);
    return selectedData;
  };

  /* ************************************* Code For getSelectedRowData  End ***************************** */

  /* ************************************* Code For WorkAssignment  SaveHandaler Functions start ***************************** */

  const SaveWorkAssignment = (e) => {
    if (e) e.preventDefault();
    if (!handleValidation()) {
      return;
    }

    if (formValues.txtDeputionType) {
      if (empAssetGridApi) {
        const checkedItem = getSelectedRowData();
        if (checkedItem.length > 0) {
          AddAssetEmployeeAssign(checkedItem);
          console.log(checkedItem);
        } else {
          setAlertMessage({
            type: "warning",
            message: "Please Select Assets! ",
          });
        }
      }
    } else {
      setAlertMessage({
        type: "warning",
        message: "Please Select DeputionType!",
      });
    }
  };

  /* ************************************* Code For WorkAssignment SaveHandaler Functions start ***************************** */

  useEffect(() => {
    if (selectedProjectData && selectedProjectData.PROJECTID) {
      console.log(selectedProjectData);
    }
  }, [selectedProjectData]);

  return {
    AssingmentColumDef,
    setSelectedProjectData,
    selectedProjectData,
    workAssignmentList,
    isLoadingWorkAssignment,
    getWorkingAssignmentList,
    toggleAddWorkAssignmentpopup,
    isActiveWorkAssignmentPopUp,
    formValues,
    formValidationError,
    updateFormState,
    handleValidation,
    divisionList,
    isLoadingDivisionList,
    getDivisionDetailsList,
    employeeList,
    toEmployeeList,
    isLoadingEmployeeList,
    employeeAssetList,
    isLoadingEmployeeAssetList,
    employeeAssetColumDef,
    onEmpAssetGridReady,
    SaveWorkAssignment,
    isLoadingAddEmployeeAssign,
    newAddedEmployeeAssign,
    clearForm,
    onChangeSearchText,
    searchText,
    onGridReady,
  };
}

export default WorkAssignmentLogic;
