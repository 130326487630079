import React, { useEffect, useState } from "react";
import BizClass from "../Grievances/Grievances.module.scss";
import { DataGrid, PageBar } from "Framework/Components/Layout";
import Form from "Framework/Components/Layout/FormGroup/FormGroup";
import { Button } from "Framework/Components/Widgets";
import { project } from "Service/Endpoints/endpoints";
import axios from "../../../../Configration/axios/axios";
import { getSessionStorage } from "Service/auth/auth";
import { IoMdAdd } from "react-icons/io";
import GrievancesLogic from "./Logic/GrievancesLogic";
import { Loader } from "Framework/Components/Widgets";
import Modal from "Framework/Components/Layout/Modal/Modal";

function Grievances() {
  const {
    grievancesList,
    isLoadingGrievancesList,
    GrievancesColumDef,
    toggleAddObservationPopUp,
    isShowAddGrievancesPopUp,
    getGrievancesListing,
    onObservationGridReady,
    onChangeSearchText,
    searchText,
  } = GrievancesLogic();
  const user = getSessionStorage("userData");
  console.log("user", user);
  const [employeeID, setemployeeID] = useState("");
  const [divisionID, setdivisionID] = useState("");
  const [discription, setDiscription] = useState("");
  const [remarks, setRemarks] = useState("");

  useEffect(() => {
    setemployeeID(user.UserName);
    setdivisionID(user.PROJECTDIVISIONID);
  }, []);
  console.log("grievancesList", grievancesList);

  const handleInputChange = (e, field) => {
    const newValue = e.target.value;

    switch (field) {
      case "EmployeeID":
        setemployeeID(newValue);
        break;
      case "DivisionID":
        setdivisionID(newValue);
        break;
      case "Discription":
        setDiscription(newValue);
        break;
      case "Remarks":
        setRemarks(newValue);
        break;
      default:
        break;
    }
  };

  const saveChanges = async () => {
    console.log(employeeID, divisionID, discription, remarks);
    const formData = {
      emp_ID: employeeID,
      division_ID: divisionID,
      description: discription,
      remarks: remarks,
    };

    try {
      const response = await axios.post(
        `${project.projectList.EmployeeGrievances}`,
        formData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log("API Response:", response.data);
      if (response.status === 200) {
        alert("Grievances saved Successfully!");
        getGrievancesListing();
      } else {
        console.error("Unexpected response status:", response.status);
      }
    } catch (error) {
      console.error("Error in saveChanges:", error);
    }
  };


  const exportToCSV = () => {
    const headers = Object.keys(grievancesList[0]);

    const csvContent = "data:text/csv;charset=utf-8," +
      headers.join(",") + "\n" +
      grievancesList.map(row => headers.map(header => row[header]).join(",")).join("\n");
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "grievances.csv");
    document.body.appendChild(link);
    link.click();
  };


  return (
    <div className={BizClass.ObservationsGrid}>
      <PageBar className={BizClass.Span}>
        <div>
          {" "}
          <p>Grievances List</p>
        </div>

        <div className={BizClass.pageContents}>
          <div style={{ margin: "7px" }}>

            <PageBar.Search
              onChange={(e) => onChangeSearchText(e.target.value)}
              value={searchText}
              onClick={() => getGrievancesListing()}
            />
          </div>

          <PageBar.Button
            onClick={() => {
              toggleAddObservationPopUp();
            }}
            style={{ margin: "7px" }}
          >
            <IoMdAdd /> New Grievances
          </PageBar.Button>
          <PageBar.Button varient="primary" onClick={exportToCSV} style={{ margin: "7px" }}>
            Export to CSV
          </PageBar.Button>
        </div>

      </PageBar>
      <DataGrid
        rowData={grievancesList}
        columnDefs={GrievancesColumDef}
        loader={isLoadingGrievancesList ? <Loader /> : null}
        onGridReady={onObservationGridReady}
        className={BizClass.ObservationsGridContainer}
      />
      {isShowAddGrievancesPopUp && (
        <Modal
          varient="center"
          title="Add Grievances"
          index={2}
          show={toggleAddObservationPopUp}
          width="754px"
          height="400px"
        >
          <Modal.Body>
            <Form>
              <Form.Group controlwidth="240px">
                <Form.InputGroup label="Employee ID">
                  <Form.InputControl
                    control="input"
                    name="txtEmployeeID"
                    maxLength="200"
                    value={employeeID}
                    onChange={(e) => handleInputChange(e, "EmployeeID")}
                  />
                </Form.InputGroup>
                <Form.InputGroup label="Division ID">
                  <Form.InputControl
                    control="input"
                    name="txtDivisionID"
                    maxLength="200"
                    value={divisionID}
                    onChange={(e) => handleInputChange(e, "DivisionID")}
                  />
                </Form.InputGroup>
                <Form.InputGroup label="Discription">
                  <Form.InputControl
                    control="input"
                    name="txtDiscription"
                    maxLength="200"
                    value={discription}
                    onChange={(e) => handleInputChange(e, "Discription")}
                  />
                </Form.InputGroup>
                <Form.InputGroup label="Remarks">
                  <Form.InputControl
                    control="input"
                    name="txtRemarks"
                    maxLength="900"
                    value={remarks}
                    onChange={(e) => handleInputChange(e, "Remarks")}
                  />
                </Form.InputGroup>
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button varient="primary" onClick={() => saveChanges()}>
              Save
            </Button>
          </Modal.Footer>
        </Modal>
      )}


    </div>
  );
}

export default Grievances;
