import React, { useEffect } from "react";
import Modal from "Framework/Components/Layout/Modal/Modal";
import { DataGrid, PageBar } from "Framework/Components/Layout";
import { Loader } from "Framework/Components/Widgets";
import BizClass from "../../Dashboard.module.scss";

function ObservationReportDetails({ toggleObservetionDetailsePopUp, popUpData, ObserVationDetailsProps }) {
  const {
    reportDivisionList,
    isLoadingReportDivision,
    getObeservationReportDivsionDetailsList,
    ObservationDetailsListViewColumDef,
    updateDivisionReportStateValue,
    divsionReportFormValues,
    onChangeDivisionReportSearchText,
    searchDivisionReportText,
    onDivisionReportGridReady,
  } = ObserVationDetailsProps;

  useEffect(() => {
    getObeservationReportDivsionDetailsList();
    console.log(popUpData);
  }, []);
  return (
    <Modal
      title={`Observation Divition Status Report of ${popUpData && popUpData.DIVISIONNAME ? popUpData.DIVISIONNAME : ""} (${
        popUpData && popUpData.DIVISIONID ? popUpData.DIVISIONID : ""
      })`}
      varient="half"
      show={() => toggleObservetionDetailsePopUp()}
      right={0}
      width="82.5vw"
    >
      <Modal.Body>
        <div className={BizClass.DashboardPages}>
          <PageBar>
            <div className={BizClass.Input_Group_Fild}>
              <span>From Data :</span>
              <PageBar.Input
                type="date"
                name="txtFromDate"
                onChange={(e) => updateDivisionReportStateValue(e.target.name, e.target.value)}
                value={divsionReportFormValues.txtFromDate}
              />
            </div>
            <div className={BizClass.Input_Group_Fild}>
              <span>To Data :</span>
              <PageBar.Input
                type="date"
                name="txtToDate"
                onChange={(e) => updateDivisionReportStateValue(e.target.name, e.target.value)}
                value={divsionReportFormValues.txtToDate}
              />
            </div>
            <PageBar.Button onClick={() => getObeservationReportDivsionDetailsList()}>Refresh</PageBar.Button>
            <PageBar.Search onChange={(e) => onChangeDivisionReportSearchText(e.target.value)} value={searchDivisionReportText} />
          </PageBar>
          <DataGrid
            rowData={reportDivisionList}
            columnDefs={ObservationDetailsListViewColumDef}
            loader={isLoadingReportDivision ? <Loader /> : null}
            onGridReady={onDivisionReportGridReady}
          />
        </div>
      </Modal.Body>
      <Modal.Footer />
    </Modal>
  );
}

export default ObservationReportDetails;
