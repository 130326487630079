import React, { useEffect, useState, useRef } from "react";
import {
  MASTERDATA_STATUSTYPE,
  MASTERDATA_INPUTTYPE,
  MASTERDATA_REFERENCETYPE,
  MASTERDATA_SAFTYCHECKPONIT,
} from "Configration/Utilities/Constants/Constants";
import ObservationsDetailsLogic from "../ObservationsDetail/Logic/ObservationsDetailsLogic";
import { DataGrid, PageBar } from "Framework/Components/Layout";
import { Loader } from "Framework/Components/Widgets";
import { IoMdAdd, IoMdCreate } from "react-icons/io";
import AddObservationPopUp from "../ObservationsDetail/view/AddObservations/AddObservations";
import ObservationsLogic from "./Logic/ObservationsLogic";
import BizClass from "./Observations.module.scss";
import Modal from "Framework/Components/Layout/Modal/Modal";
import { Button } from "Framework/Components/Widgets";
import Form from "Framework/Components/Layout/FormGroup/FormGroup";
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { OutTable, ExcelRenderer } from 'react-excel-renderer';
import { AlertMessage } from "Framework/Components/Widgets";
import { project } from "Service/Endpoints/endpoints";
import GetApiData from "Configration/Utilities/getApiData/GetApiData";
import axios from "../../../../Configration/axios/axios";
import { getSessionStorage } from "Service/auth/auth";



function Observations({ selectedProjectData }) {
  const {
    observationsList,
    isLoadingObeservationList,
    ObservationColumDef,
    toggleAddObservationPopUp,
    isShowAddObservationPopUp,
    getObservationListing,
    onObservationGridReady,
    onChangeSearchText,
    searchText,
    ObservationGridApi
  } = ObservationsLogic();


  const {
    StatusType,
    frequencyType,
    inputType,
    group,
    isGroupDataLoad,
    referecneType,
    safetyCheckList,
    issafetyCheckListDataLoad,
    isStatusTypeDataLoad,
    isInputTypeDataLoad,
    isReferanceDataLoad,
    getMAsterObservationListing,
    formValues,
    GetBatchMaster,
    updateFormState

  } = ObservationsDetailsLogic();
  const user = getSessionStorage("userData");

  useEffect(() => {
    getMAsterObservationListing(MASTERDATA_STATUSTYPE);
    getMAsterObservationListing(MASTERDATA_INPUTTYPE);
    getMAsterObservationListing(MASTERDATA_REFERENCETYPE);
    getMAsterObservationListing(MASTERDATA_SAFTYCHECKPONIT);
    GetBatchMaster();
  }, []);
  const setAlertMessage = AlertMessage();

  const [editedRowData, setEditedRowData] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const frequencyOptions = ["Daily", "Weekly", "Monthly", "Yearly"];
  const [selectedAction, setSelectedAction] = useState('');
  const [selectedAssetDesc, setselectedAssetDesc] = useState('');
  const [selectedCheckPoint, setselectedCheckPoint] = useState('');
  const [selectedDayOfWeek, setselectedDayOfWeek] = useState('');
  const [selectedObservationLabel, setselectedObservationLabel] = useState('');
  const [selectedObservationTime, setselectedObservationTime] = useState('');
  const [observationsListWithDisabled, setObservationsListWithDisabled] = useState([]);
  const [disabledRows, setDisabledRows] = useState([]);
  const [openGroupIds, setOpenGroupIds] = useState([]);
  const [uploadedData, setUploadedData] = useState(null);
  const [NewAddedObservations, setNewAddedObservations] = useState([]);
  const [IsLoadingTeamsMemberData, setIsLoadingTeamsMemberData] = useState(false);
  const localEnabledRef = useRef(false);
  const [teamsListMembersData, setTeamsListMembersData] = useState([]);
  const [loading, setLoading] = useState(false);


  const getTeamsDetailListing = () => {
    const formData = {
      main: {
        divisionid: user && user.DIVISION_ID ? user.DIVISION_ID.toString() : "",
        profileid: user && user.UserProfileID ? user.UserProfileID : null,
        projectid: user && user.PROJECT_ID ? user.PROJECT_ID : "",
      },
    };

    const request = {
      setDatalist: setTeamsListMembersData,
      setLoad: setIsLoadingTeamsMemberData,
      requestData: formData,
      apiPath: project.projectList.GetKPCLTeam,
      setAlert: setAlertMessage,
      fun: null,
    };
    GetApiData(request);
  };
  console.log("teamsListMembersData in observations page", teamsListMembersData)

  useEffect(() => {
    if (editedRowData) {
      setSelectedAction(editedRowData.ACTION);
      setselectedAssetDesc(editedRowData.ASSETDESC);
      setselectedCheckPoint(editedRowData.CHECKPOINT);
      setselectedDayOfWeek(editedRowData.DAYOFWEEK);
      setselectedObservationLabel(editedRowData.OLABEL);
      setselectedObservationTime(editedRowData.OBSERVATIONTIME);
    }
  }, [editedRowData]);
  useEffect(() => {
    getTeamsDetailListing();
  }, [])


  const EditCellRenderer = (props) => {
    const isGroupOpen = openGroupIds.includes(props.node.parent.id);

    const handleEditClick = () => {
      if (props.data.enabled) {
        console.log("Edit clicked for row with data:", props.data);
        setEditedRowData(props.data);
        setIsEditModalOpen(true);
      } else {
        alert("Row is disabled. Cannot edit.");
        // Optionally, you can show a message to the user that the row is disabled.
      }
    };

    return (
      <div>
        {isGroupOpen || !props.node.group ? (
          <IoMdCreate
            onClick={handleEditClick}
            style={{ cursor: !props.data.enabled ? 'not-allowed' : 'pointer' }}
          />
        ) : null}
      </div>
    );
  };


  const downloadExcel = () => {
    const headerNames = [
      "shift",
      "employee",
      "equipmentnumber",
      "ogroup",
      "checkpoint",
      "description",
      "reftype",
      "refmin",
      "refmax",
      "olabel",
      "inputtype",
      "frequency",
      "months",
      "weekofmonth",
      "dayofweek",
      "repeattype",
      "repeatfrequency",
      "observaitontime",
      "duration",
      "weeklymaxcount",
      "monthlymaxcount",
      "inscada",
      "action",
      "statustype",
      "projectdivisionid",
      "safetychecklistid",
      "startdate",
      "enddate",
      "batchid",
      "empid",
      "activestatus"
    ];

    // Create a worksheet with only header row
    const ws = XLSX.utils.aoa_to_sheet([headerNames]);

    // Create a workbook
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Observations");

    // Generate binary string
    const binaryString = XLSX.write(wb, { bookType: "xlsx", mimeType: "application/octet-stream", type: "binary" });

    // Convert binary string to a blob
    const blob = new Blob([s2ab(binaryString)], { type: "application/octet-stream" });

    // Save the blob as a file
    saveAs(blob, 'observations.xlsx');
  };


  const s2ab = (s) => {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i !== s.length; ++i) {
      view[i] = s.charCodeAt(i) & 0xFF;
    }
    return buf;
  };


  const handleFileUpload = (e) => {
    setLoading(true);
    const file = e.target.files[0];
    ExcelRenderer(file, (err, resp) => {
      if (err) {
        console.error(err);
        setLoading(false);
      } else {
        const allRows = resp.rows;
        if (allRows.length >= 2) {
          const headerNames = allRows[0];
          const dataFromSecondRow = allRows.slice(1);

          console.log("Column Names:", headerNames);

          const observationDataArray = dataFromSecondRow.map((rowData) => {
            const rowObject = {};
            headerNames.forEach((columnName, index) => {
              rowObject[columnName] = rowData[index];
            });
            return rowObject;
          });

          console.log("Data as Array of Objects:", observationDataArray);

          // Convert Excel date to formatted date
          const convertExcelDateToFormattedDate = (excelDate) => {
            const jsDate = new Date((excelDate - 1) * 24 * 60 * 60 * 1000 + new Date(1900, 0, 1).getTime());
            const day = jsDate.getDate().toString().padStart(2, '0');
            const month = (jsDate.getMonth() + 1).toString().padStart(2, '0');
            const year = jsDate.getFullYear();
            return `${day}-${month}-${year}`;
          };

          // Map observations to an array of promises
          const observationPromises = observationDataArray.map((observationData, observationIndex) => {
            return new Promise((resolve, reject) => {
              const decimalTime = observationData.observaitontime;
              const hours = Math.floor(decimalTime * 24);
              const minutes = Math.round((decimalTime * 24 * 60) % 60);
              const formattedTime = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;

              // Create formData using the observationData
              const formData = [
                {
                  shift: observationData.shift,
                  employee: observationData.employee,
                  equipmentnumber: observationData.equipmentnumber,
                  ogroup: observationData.ogroup,
                  checkpoint: observationData.checkpoint,
                  description: observationData.description,
                  reftype: observationData.reftype,
                  refmin: observationData.refmin,
                  refmax: observationData.refmax,
                  olabel: observationData.olabel,
                  inputtype: observationData.inputtype,
                  frequency: observationData.frequency,
                  months: observationData.months,
                  weekofmonth: observationData.weekofmonth,
                  dayofweek: observationData.dayofweek,
                  repeattype: observationData.repeattype,
                  repeatfrequency: observationData.repeatfrequency,
                  observaitontime: formattedTime,
                  duration: observationData.duration,
                  weeklymaxcount: observationData.weeklymaxcount,
                  monthlymaxcount: observationData.monthlymaxcount,
                  inscada: observationData.inscada,
                  action: observationData.action,
                  statustype: observationData.statustype,
                  projectdivisionid: observationData.projectdivisionid,
                  safetychecklistid: observationData.safetychecklistid,
                  startdate: convertExcelDateToFormattedDate(observationData.startdate),
                  enddate: convertExcelDateToFormattedDate(observationData.enddate),
                  batchid: observationData.batchid,
                  empid: observationData.empid,
                  activestatus: observationData.activestatus,
                  flag: 0
                }
              ];

              console.log(`FormData (${observationIndex}):`, JSON.stringify(formData));

              // Send a separate request for each observation
              axios.post(`${project.projectList.AddBulkObservationDetail}`, JSON.stringify(formData), {
                headers: {
                  'Content-Type': 'application/json',
                },
              })
                .then((response) => {
                  console.log(`Response for Observation ${observationIndex}:`, response.data);
                  resolve(response.data);
                })
                .catch((error) => {
                  console.error(`Error for Observation ${observationIndex}:`, error);
                  reject(error);
                });
            });
          });

          // Use Promise.all to wait for all observations to complete
          Promise.all(observationPromises)
            .then((responses) => {
              const isSuccess = responses.every((response) => response.responseMessage === 'SUCCESS!');
              if (isSuccess) {
                alert("All Observations were Successfully added");
                fileInput.value = null;
              } else {
                alert("Error while adding the observations");
              }
            })
            .catch((error) => {
              console.error('Error in processing observations:', error);
            }).finally(() => {
              setLoading(false); // Set loading to false once all observations are processed
            });

        } else {
          console.warn("Not enough rows in the Excel file.");
          setLoading(false);
        }
      }
    });
  };
  console.log("Grid API:", ObservationGridApi);

  useEffect(() => {
    const observationsListWithEnabled = observationsList.map(item => ({
      ...item,
      enabled: true,
    }));

    setObservationsListWithDisabled(observationsListWithEnabled);
  }, [observationsList]);

  const DisableCellRenderer = (props) => {
    const [localEnabled, setLocalEnabled] = useState(props.data?.enabled || false);
    const [isGroupOpen, setIsGroupOpen] = useState(openGroupIds.includes(props.node.parent.id));
    const assetStatusChanged = async (changedId, status) => {
      try {
        const formData = {
          observationid: changedId,
          activestatus: status,
          flag: 1,
          empid: 0,
        };

        console.log("formData", formData)
        axios.post(`${project.projectList.EditObservation}`, JSON.stringify(formData), {
          headers: {
            'Content-Type': 'application/json',
          },
        })
          .then((response) => {
            console.log(`Response for Observation:`, response.data);
            alert(`${status === 0 ? 'Observation Disabled Successfully' : 'Observation Enabled Successfully'}`)
          })
          .catch((error) => {
            console.error(`Error for Observation:`, error);
          });


      } catch (error) {
        console.error('Error in assetStatusChanged:', error);
      }
    };

    // Update your handleRadioChange function to call assetStatusChanged
    const handleRadioChange = (value) => {
      if (props.data && props.data.OVSERVATIONHEADERID) {
        const changedId = props.data.OVSERVATIONHEADERID;

        // Set the 'enabled' property to false when 'Disable' radio button is clicked
        if (!value) {
          assetStatusChanged(changedId, 0); // Pass 1 for 'disabled'
          const updatedList = observationsListWithDisabled.map((item) => {
            if (item.OVSERVATIONHEADERID === changedId) {
              return {
                ...item,
                enabled: false,
              };
            }
            return item;
          });
          setObservationsListWithDisabled(updatedList);
        } else {
          // Set the 'enabled' property to true when 'Enable' radio button is clicked
          assetStatusChanged(changedId, 1); // Pass 0 for 'enabled'
          const updatedList = observationsListWithDisabled.map((item) => {
            if (item.OVSERVATIONHEADERID === changedId) {
              return {
                ...item,
                enabled: true,
              };
            }
            return item;
          });
          setObservationsListWithDisabled(updatedList);
        }

        // Update the localEnabled state
        setLocalEnabled(value);

        // If the group is open, update the local state
        const isGroupOpen = openGroupIds.includes(props.node.parent.id);
        if (isGroupOpen) {
          setOpenGroupIds((prevIds) => [...prevIds]);
        }

        if (props.params && props.params.api && props.params.api.getColumnDefs) {
          const colDef = props.params.api.getColumnDefs();
          colDef.forEach((col) =>
            props.params.api.forEachNode((node) => node.setDataValue(col.field, value))
          );
        }
      }
    };
    const updateRowsInOpenedGroup = (groupId, newValue) => {
      console.log("Before update: observationsListWithDisabled", observationsListWithDisabled);

      // Update the data source with the modified data
      const newData = observationsListWithDisabled.map((row) => {
        if (row.parentId === groupId) {
          return { ...row, enabled: newValue };
        }
        return row;
      });

      setObservationsListWithDisabled(newData);
      setLocalEnabled(newValue);
      console.log("After update: observationsListWithDisabled", newData);
    };

    const handleGroupOpened = (groupId) => {
      console.log("groupId", groupId);
      console.log("Before toggle: localEnabled", localEnabled);

      // Toggle the value of localEnabled
      const newValue = localEnabled;

      console.log("After toggle: localEnabled", newValue);

      // Update rows in the opened group based on the new value
      updateRowsInOpenedGroup(groupId, newValue);
      setLocalEnabled(newValue);
    };


    const handleEnableGroupOpened = (groupId) => {
      console.log("groupId", groupId);
      console.log("Before toggle: localEnabled", localEnabled);

      // Toggle the value of localEnabled
      const newValue = true;

      console.log("After toggle: localEnabled", newValue);

      // Update rows in the opened group based on the new value
      updateRowsInOpenedGroup(groupId, newValue);
      setLocalEnabled(newValue);
    };
    return (
      <div>
        {props.node.group && (
          <div>
            <button onClick={() => handleGroupOpened(props.node.parent?.groupId)} style={{



              margin: '5px', // Margin around the button
              cursor: 'pointer', // Cursor on hover
            }}>
              Disable
            </button>
            <button onClick={() => handleEnableGroupOpened(props.node.parent?.groupId)} style={{


              margin: '5px', // Margin around the button
              cursor: 'pointer', // Cursor on hover
            }}>
              Enable
            </button></div>


        )}



        {isGroupOpen || !props.node.group ? (
          <>
            <input
              type="radio"
              name={`enableDisableRadio_${props.data?.OVSERVATIONHEADERID}`}
              checked={localEnabled}
              onChange={() => handleRadioChange(true)}
              style={{ marginRight: '2px' }}
            />
            Enable
            &nbsp;|&nbsp;
            <input
              type="radio"
              name={`enableDisableRadio_${props.data?.OVSERVATIONHEADERID}`}
              checked={!localEnabled}
              onChange={() => handleRadioChange(false)}
              style={{ marginRight: '2px' }}
            />
            Disable

          </>
        ) : null}
      </div>
    );
  };


  const updatedColumnDefs = [
    {
      headerName: "Edit",
      cellRendererFramework: EditCellRenderer,
      minWidth: 100,
      maxWidth: 100,
      sortable: false,
      filter: false,
    },
    {
      headerName: "Enable/Disable",
      cellRendererFramework: DisableCellRenderer,
      minWidth: 100,
      maxWidth: 200,
      sortable: false,
      filter: false,
    },
    ...ObservationColumDef,
    
  ];

  console.log("selectedDayOfWeek", selectedDayOfWeek)
  const handleSaveButtonClick = () => {
    if (editedRowData) {
      const formData = {
        observationid: editedRowData.OVSERVATIONHEADERID,
        equipmentnumber: editedRowData.EQUIPMENTNUMBER,
        ogroup: formValues.txtGroup.BATCHID,
        checkpoint: selectedCheckPoint,
        description: selectedAssetDesc,
        reftype: formValues.txtReferenceType.MASTERVALUEID,
        refmin: 0,
        refmax: 0,
        olabel: selectedObservationLabel,
        inputtype: formValues.txtInputType.MASTERVALUEID,
        frequency: formValues.frequencyType.value,
        months: 0,
        weekofmonth: 0,
        dayofweek: 0,
        repeattype: 0,
        repeatfrequency: 0,
        observaitontime: selectedObservationTime,
        duration: 0,
        weeklymaxcount: 0,
        monthlymaxcount: 0,
        inscada: 0,
        action: selectedAction,
        statustype: formValues.txtStatusType.MASTERVALUEID,
        projectdivisionid: editedRowData.PROJECTDIVISIONID ? editedRowData.PROJECTDIVISIONID : 0,
        safetychecklistid: formValues.txtsafetyCheckList.MASTERVALUEID,
        startdate: editedRowData.STARTDATE ? editedRowData.STARTDATE : '',
        enddate: editedRowData.ENDDATE ? editedRowData.ENDDATE : '',
        batchid: 0,
        empid: formValues.txtEmployee.value,
        activestatus: 0,
        flag: 0
      }
      console.log("formData", formData)
      axios.post(`${project.projectList.EditObservation}`, JSON.stringify(formData), {
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then((response) => {
          console.log(`Response for Observation:`, response.data);
          alert("Observation Edited Successfully")
        })
        .catch((error) => {
          console.error(`Error for Observation:`, error);
        });

    }



  };


  const handleInputChange = (e, field) => {
    const newValue = e.target.value;

    switch (field) {
      case "ACTION":
        setSelectedAction(newValue);
        break;
      case "ASSETDESC":
        setselectedAssetDesc(newValue);
        break;
      case "CHECKPOINT":
        setselectedCheckPoint(newValue);
        break;
      case "DAYOFWEEK":
        setselectedDayOfWeek(newValue);
        break;
      case "OLABEL":
        setselectedObservationLabel(newValue);
        break;
      case "OBSERVATIONTIME":
        setselectedObservationTime(newValue);
        break;

      default:
        break;
    }
  };

  const openEditModal = (rowData) => {
    setEditedRowData(rowData);
    setIsEditModalOpen(true);

  };







  const closeEditModal = () => {
    setIsEditModalOpen(false);
  };


  return (
    <div className={BizClass.ObservationsGrid}>
      {isShowAddObservationPopUp && (
        <AddObservationPopUp
          toggleAddObservationPopUp={toggleAddObservationPopUp}
          selectedProjectData={selectedProjectData}
          getObservationListing={getObservationListing}
        />
      )}
      <PageBar>
        <PageBar.Search
          onChange={(e) => onChangeSearchText(e.target.value)}
          value={searchText}
          onClick={() => getObservationListing()}
        />
        <PageBar.Button
          onClick={() => {
            toggleAddObservationPopUp();
          }}
        >
          <IoMdAdd /> New Observations
        </PageBar.Button>

        <PageBar.Button onClick={downloadExcel}>
          Download Format
        </PageBar.Button>

        <PageBar.Button>
          {loading === 'true' ? <Loader /> : null}
          <input
            type="file"
            accept=".xlsx, .xls"
            id="fileInput"
            className={BizClass.customFileInput}
            onChange={handleFileUpload}
          />
        </PageBar.Button>

      </PageBar>
      <DataGrid
        rowData={observationsListWithDisabled}
        columnDefs={updatedColumnDefs}
        onGridReady={onObservationGridReady}
        loader={isLoadingObeservationList ? <Loader /> : null}
        animateRows={true}
        showOpenedGroup={true}
        autoGroupColumnDef={{
          headerName: "Asset Name",
          minWidth: 220,
          pinned: "left",
        }}
        frameworkComponents={{
          editCellRenderer: EditCellRenderer,
        }}
        suppressClickEdit={true} // Add this line to suppress click editing
        getRowStyle={(params) => {
          const isEnabled = params.data && params.data.enabled;
          return isEnabled !== undefined
            ? {
              backgroundColor: isEnabled ? '' : '#f0f0f0',
              opacity: isEnabled ? 1 : 0.7,
              cursor: isEnabled ? 'default' : 'not-allowed',
            }
            : {};
        }}

        onFirstDataRendered={(params) => {
          params.api.addEventListener('firstDataRendered', () => {
            params.api.forEachNode((node) => {
              if (node.group) {
                if (node.expanded) {
                  setOpenGroupIds((prevState) => [...prevState, node.id]);
                } else {
                  setOpenGroupIds((prevState) =>
                    prevState.filter((groupId) => groupId !== node.id)
                  );
                }
              }
            });
          });
        }}
      />

      {isEditModalOpen && (
        <Modal
          varient="center"
          title="Edit Observations"
          index={2}
          show={closeEditModal}
          width="754px"
          height="400px"
        >
          <Modal.Body>
            <Form>
              <Form.Group column={2} controlwidth="240px">
                <Form.InputGroup label="Action">
                  <Form.InputControl
                    control="input"
                    name="txtAction"
                    maxLength="200"
                    value={selectedAction}
                    onChange={(e) => handleInputChange(e, "ACTION")}
                  />
                </Form.InputGroup>

                <Form.InputGroup label="Asset Description">
                  <Form.InputControl
                    control="input"
                    name="txtDescription"
                    maxLength="200"
                    value={selectedAssetDesc}
                    onChange={(e) => handleInputChange(e, "ASSETDESC")}
                  />
                </Form.InputGroup>
                <Form.InputGroup label="Check Point">
                  <Form.InputControl
                    control="input"
                    name="txtCheckPoint"
                    maxLength="200"
                    value={selectedCheckPoint}
                    onChange={(e) => handleInputChange(e, "CHECKPOINT")}
                  />

                </Form.InputGroup>
                <Form.InputGroup label="Day of Week">
                  <Form.InputControl
                    control="input"
                    name="txtDayOfWeek"
                    maxLength="200"
                    value={selectedDayOfWeek}
                    onChange={(e) => handleInputChange(e, "DAYOFWEEK")}
                  />
                </Form.InputGroup>
                <Form.InputGroup label="Equipment Number">
                  <Form.InputControl
                    control="input"
                    name="txtEquipmentNumber"
                    value={editedRowData && editedRowData.EQUIPMENTNUMBER}
                    readOnly
                    onChange={(e) => {
                      updateFormState(e, "txtEquipmentNumber");
                    }}
                  />
                </Form.InputGroup>
                <Form.InputGroup label="Frequency Type">
                  <Form.InputControl
                    control="select"
                    name="frequencyType"
                    options={frequencyOptions.map((option, index) => ({
                      label: option,
                      value: index,
                    }))}
                    value={formValues.frequencyType}
                    onChange={(e) => {
                      updateFormState(e, "frequencyType");
                    }}
                  />
                </Form.InputGroup>
                <Form.InputGroup label="Group Name">
                  <Form.InputControl
                    control="creatableselect"
                    name="txtGroup"
                    options={group}
                    isLoading={isGroupDataLoad}
                    getOptionLabel={(option) => {
                      return `${option.BATCHDESC}`;
                    }}
                    getOptionValue={(option) => `${option}`}
                    getNewOptionData={(label) => ({ BATCHDESC: label, BATCHID: 0, isNew: true })}
                    onChange={(e) => {
                      updateFormState(e, "txtGroup");
                    }}
                    value={formValues.txtGroup}
                  />
                </Form.InputGroup>

                <Form.InputGroup label="Observation Label">
                  <Form.InputControl
                    control="input"
                    name="txtObservationLabel"
                    value={selectedObservationLabel}
                    onChange={(e) => handleInputChange(e, "OLABEL")}

                  />
                </Form.InputGroup>
                <Form.InputGroup label="Observation ID">
                  <Form.InputControl
                    control="input"
                    name="txtObservationID"
                    value={editedRowData && editedRowData.OVSERVATIONHEADERID}
                    readOnly
                    onChange={(e) =>
                      handleInputChange("OVSERVATIONHEADERID", e.target.value)
                    }
                  />
                </Form.InputGroup>
                <Form.InputGroup label="Observation Time">
                  <Form.InputControl
                    control="input"
                    name="groupName"
                    value={selectedObservationTime}
                    onChange={(e) => handleInputChange(e, "OBSERVATIONTIME")}
                  />
                </Form.InputGroup>
                <Form.InputGroup label="Assign To Employee">
                  <Form.InputControl
                    control="select"
                    name="txtEmployee"
                    options={teamsListMembersData.map((option) => ({
                      label: option.EMPLOYEENAME,
                      value: option.EMPLOYEEID,
                    }))}
                    value={formValues.txtEmployee}
                    onChange={(e) => {
                      updateFormState(e, "txtEmployee");
                    }}
                  />
                </Form.InputGroup>
                <Form.InputGroup label="Input Type">
                  <Form.InputControl
                    control="select"
                    name="txtInputType"
                    options={inputType}
                    isLoading={isInputTypeDataLoad}
                    getOptionLabel={(option) => `${option.MASTERDISPLAYNAME}`}
                    getOptionValue={(option) => `${option}`}
                    onChange={(e) => {
                      updateFormState(e, "txtInputType");
                    }}
                    value={formValues.txtInputType}
                  />
                </Form.InputGroup>

                <Form.InputGroup label="Status Type">
                  <Form.InputControl
                    control="select"
                    name="txtStatusType"
                    options={StatusType}
                    isLoading={isStatusTypeDataLoad}
                    getOptionLabel={(option) =>
                      `${option.MASTERDISPLAYNAME ? option.MASTERDISPLAYNAME : option.label}`
                    }
                    getOptionValue={(option) => `${option}`}
                    value={formValues.txtStatusType}
                    onChange={(e) => {
                      updateFormState(e, "txtStatusType");
                    }}
                  />
                </Form.InputGroup>
                <Form.InputGroup label="Reference Type">
                  <Form.InputControl
                    control="select"
                    name="txtReferenceType"
                    options={referecneType}
                    isLoading={isReferanceDataLoad}
                    getOptionLabel={(option) => `${option.MASTERDISPLAYNAME}`}
                    getOptionValue={(option) => `${option}`}
                    value={formValues.txtReferenceType}
                    onChange={(e) => {
                      updateFormState(e, "txtReferenceType");
                    }}
                  />
                </Form.InputGroup>
                <Form.InputGroup label="Safety Checklist">
                  <Form.InputControl
                    control="select"
                    name="txtsafetyCheckList"
                    options={safetyCheckList}
                    isLoading={issafetyCheckListDataLoad}
                    getOptionLabel={(option) => `${option.MASTERDISPLAYNAME}`}
                    getOptionValue={(option) => `${option}`}
                    value={formValues.txtsafetyCheckList}
                    onChange={(e) => {
                      updateFormState(e, "txtsafetyCheckList");
                    }}
                  />
                </Form.InputGroup>


              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button
              varient="primary"
              onClick={() => handleSaveButtonClick()}
            >
              Save
            </Button>

          </Modal.Footer>
        </Modal>
      )}


    </div>
  );
}

export default Observations;
