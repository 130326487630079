import { PageBar } from "Framework/Components/Layout";
import React, { useEffect, useState } from "react";
import { AiOutlineUnorderedList } from "react-icons/ai";
import { TbLayoutDashboard } from "react-icons/tb";
import { Loader } from "Framework/Components/Widgets";
import ProjectDetail from "../../../Projects/ProjectDetail/ProjectDetail";
import ProjectsLogic from "../../../Projects/ProjectsList/Logic/ProjectsListLogic";
// import BizClass from "../../../Projects/ProjectsList/ProjectsList.module.scss";
import BizClass from "./Dashboard.module.scss";
import { getSessionStorage } from "Service/auth/auth";
import { project } from "Service/Endpoints/endpoints";
import axios from "../../../../../Configration/axios/axios";

function ProjectsList() {
  const {
    activeViewID,
    setActiveViewID,
    detailModalActive,
    selectedProjectData,
    toggleDetailModal,
    getProjectListData,
    isLoading,
    projectItemSearch,
    onSearchList,
  } = ProjectsLogic();

  const user = getSessionStorage("userData");

  useEffect(() => {
    if (detailModalActive === false) {
      document.title = "Projects | Karnataka Power Corporation Limited";
    }
  }, [detailModalActive]);

  // return (
  //   <>
  //     {/* {detailModalActive && <ProjectDetail selectedProjectData={selectedProjectData} toggleDetailModal={toggleDetailModal} />} */}
  //     <div className={BizClass.MainSection}>
  //       {/* <PageBar className={BizClass.PageTitleBarBox}> */}
  //       {/* <div className={BizClass.PageTitleBar}> */}
  //       <div className="d-flex m-2">
  //         <button type="button" className="btn btn-primary m-2">
  //           Total Defects:
  //         </button>

  //         <button type="button" className="btn btn-primary m-2">
  //           Open Defects:
  //         </button>

  //         <button type="button" className="btn btn-primary m-2">
  //           Closed Defects:
  //         </button>

  //       </div>



  //       {/* </div> */}
  //       {/* <PageBar.Search value={projectItemSearch} onChange={(e) => onSearchList(e.target.value)} onClick={() => getProjectListData()} />
  //       </PageBar> */}
  //     </div>
  //   </>
  // );


  // return (
  //   <div className={BizClass.MainSection}>
  //       <p>Defect Details</p>
  //       <button type="button" className={BizClass.DataCard}>
  //         <h4>
  //           <span>{user.PROJECT_NAME}</span>
  //         </h4>
  //         <div className={BizClass.Details}>
  //           <h4>

  //             Defect List
  //           </h4>
  //         </div>
  //         <h5>
  //         </h5>
  //         <div className={BizClass.DataTabs} style={{ display: 'flex', justifyContent: 'center' }}>
  //           <div>
  //             <h3>0</h3>
  //             {/* <p></p> */}
  //           </div>
  //         </div>
  //       </button>

  //       <button type="button" className={BizClass.DataCard}>
  //         <h4>
  //           <span>{user.PROJECT_NAME}</span>
  //         </h4>
  //         <div className={BizClass.Details}>
  //           <h4>

  //             Open Defects
  //           </h4>
  //         </div>
  //         <h5>
  //         </h5>
  //         <div className={BizClass.DataTabs} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
  //           <div>
  //             <h3>0</h3>
  //             {/* <p></p> */}
  //           </div>
  //         </div>
  //       </button>

  //       <button type="button" className={BizClass.DataCard}>
  //         <h4>
  //           <span>{user.PROJECT_NAME}</span>
  //         </h4>
  //         <div className={BizClass.Details}>
  //           <h4>

  //             Closed Defects
  //           </h4>
  //         </div>
  //         <h5>
  //         </h5>
  //         <div className={BizClass.DataTabs} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
  //           <div>
  //             <h3>0</h3>
  //             {/* <p></p> */}
  //           </div>
  //         </div>
  //       </button>
  //   </div>
  // );

  const [defectCount, setDefectCount] = useState([]);
  const [ptwCount, setPTWCount] = useState([]);


  useEffect(() => {
    axios.get(`${project.projectList.GetDashBoardReport_ForWeb}?UserID=${user.UserName}&DivisionID=${user.DIVISION_ID}`).then((response) => {
      console.log("dashboard response", response);
      const data = JSON.parse(response.data.responseDynamic);
      setDefectCount(data)
    })
  }, [])

  useEffect(() => {
    const formData = {
      UserID: parseInt(user.UserName),
      DivisionID: user.DIVISION_ID
    }
    console.log("formData", formData)
    axios.post(`${project.projectList.GetPTWDashBoardReport_ForWeb}?UserID=${user.UserName}&DivisionID=${user.DIVISION_ID}`).then((response) => {
      console.log("ptw response", response);
      const data = JSON.parse(response.data.responseDynamic);
      setPTWCount(data)
    })
  }, [])


  return (
    <div>
      <PageBar className="d-flex justify-content-center"><h5>Dashboard</h5> </PageBar>
      <div className="container">
        <h2>Defect Count</h2>
        <div className={BizClass.dashboard}>
          <div className={BizClass.card}>
            <div className={BizClass.cardContent}>
              <button className={BizClass.defectbutton}>Defects List ({defectCount.TotalDefects})</button>
            </div>
          </div>

          <div className={BizClass.card}>
            <div className={BizClass.cardContent}>
              <button className={BizClass.defectbutton}>Open Defects ({defectCount.OpenDefects})</button>

            </div>
          </div>

          <div className={BizClass.card}>
            <div className={BizClass.cardContent}>
              <button className={BizClass.defectbutton}>Closed Defects ({defectCount.ClosedDefects})</button>

            </div>
          </div>
          <div className={BizClass.card}>
            <div className={BizClass.cardContent}>
              <button className={BizClass.defectbutton}>Assigned Defects ({defectCount.AssignedDefects})</button>
            </div>
          </div>
          <div className={BizClass.card}>
            <div className={BizClass.cardContent}>
              <button className={BizClass.defectbutton}>Attended Defects ({defectCount.AttendedDefects})</button>

            </div>
          </div>
          <div className={BizClass.card}>
            <div className={BizClass.cardContent}>
              <button className={BizClass.defectbutton}>Diverted Defects ({defectCount.DivertedDefects})</button>

            </div>
          </div>
          <div className={BizClass.card}>
            <div className={BizClass.cardContent}>
              <button className={BizClass.defectbutton}>Accepted Defects ({defectCount.AcceptedDefects})</button>

            </div>
          </div>
          <div className={BizClass.card}>
            <div className={BizClass.cardContent}>
              <button className={BizClass.defectbutton}>Rejected Defects ({defectCount.RejectedDefects})</button>

            </div>
          </div>
        </div>
        <h2>PTW Count</h2>
        <div className={BizClass.dashboard}>

          <div className={BizClass.card}>
            <div className={BizClass.cardContent}>
              <button className={BizClass.defectbutton}>PTW List ({ptwCount.TotalPTW})</button>
            </div>
          </div>
          <div className={BizClass.card}>
            <div className={BizClass.cardContent}>

              <button className={BizClass.defectbutton}>Initiated PTW ({ptwCount.Initiated_PTW})</button>



            </div>
          </div>

          <div className={BizClass.card}>
            <div className={BizClass.cardContent}>

              <button className={BizClass.defectbutton}>Initiated PTW ({ptwCount.Initiated_PTW})</button>



            </div>
          </div>
          <div className={BizClass.card}>
            <div className={BizClass.cardContent}>

              <button className={BizClass.defectbutton}>Transferred PTW ({ptwCount.Transferred_PTW})</button>

              {/* <button className={BizClass.defectbutton}>Issued PTW ({ptwCount.Issued_PTW})</button>
              <button className={BizClass.defectbutton}>Initiated PTW ({ptwCount.Initiated_PTW})</button>

              <button className={BizClass.defectbutton}>Transferred PTW ({ptwCount.Transferred_PTW})</button>

              <button className={BizClass.defectbutton}>Acknowledged PTW ({ptwCount.Acknowledged_PTW})</button>
              <button className={BizClass.defectbutton}>Returned PTW ({ptwCount.Returned_PTW})</button> */}



            </div>
          </div>

          <div className={BizClass.card}>
            <div className={BizClass.cardContent}>
              <button className={BizClass.defectbutton}>Acknowledged PTW ({ptwCount.Acknowledged_PTW})</button>
              {/* <button className={BizClass.defectbutton}>Issued PTW ({ptwCount.Issued_PTW})</button>
              <button className={BizClass.defectbutton}>Initiated PTW ({ptwCount.Initiated_PTW})</button>

              <button className={BizClass.defectbutton}>Transferred PTW ({ptwCount.Transferred_PTW})</button>

              <button className={BizClass.defectbutton}>Acknowledged PTW ({ptwCount.Acknowledged_PTW})</button>
              <button className={BizClass.defectbutton}>Returned PTW ({ptwCount.Returned_PTW})</button> */}



            </div>
          </div>
          <div className={BizClass.card}>
            <div className={BizClass.cardContent}>
              <button className={BizClass.defectbutton}>Returned PTW ({ptwCount.Returned_PTW})</button>
            </div>
          </div>
        </div>
      </div>
    </div>

  );
}

export default ProjectsList;
