import { DataGrid } from "Framework/Components/Layout";
import Modal from "Framework/Components/Layout/Modal/Modal";
import { Button, Loader } from "Framework/Components/Widgets";
import React, { useEffect } from "react";
import BizClass from "../AddObservations/AddObservations.module.scss";

function AddAssets(props) {
  const {
    toggleAddAsset,
    selectedProjectData,
    setSelectedProjectData,
    assetsListData,
    ListViewColumDef,
    isLoadingAssetsData,
    onAssetGridReady,
    SaveSelectadAssetsRowData,
  } = props;

  useEffect(() => {
    setSelectedProjectData(selectedProjectData);
  }, [selectedProjectData]);

  return (
    <Modal varient="center" title="Select Asset" index={2} show={toggleAddAsset} width="754px" height="400px">
      <Modal.Body>
        <div className={BizClass.AssetsGrid}>
          <DataGrid
            rowData={assetsListData}
            columnDefs={ListViewColumDef}
            loader={isLoadingAssetsData ? <Loader /> : null}
            onGridReady={onAssetGridReady}
            rowSelection="single"
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button varient="primary" onClick={() => SaveSelectadAssetsRowData(toggleAddAsset)}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default AddAssets;
