import { AlertMessage } from "Framework/Components/Widgets";
import { useNavigate } from "react-router-dom";
import { getSessionStorage } from "Service/auth/auth";
import { auth } from "Service/Endpoints/endpoints";
import { apiCalling } from "Service/Utilities/apiCalling";

function HeaderLogic() {
  const setAlertMessage = AlertMessage();
  const navigate = useNavigate();
  const user = getSessionStorage("userData");

  const logout = async () => {
    try {
      const formData = {
        main: {
          empid: user && user.AppAccessUID ? user.AppAccessUID : null,
        },
      };
      const result = await apiCalling(formData, auth.logout.SessionOut);
      if (result && result.responseCode === 1) {
        sessionStorage.clear();
        navigate("/login");
      } else {
        setAlertMessage({
          type: "error",
          message: result.responseMessage,
        });
      }
    } catch (error) {
      console.log(error);
      setAlertMessage({
        type: "error",
        message: error,
      });
    }
  };
  return { logout };
}

export default HeaderLogic;
