import React, { useEffect, useState } from "react";
import { AlertMessage } from "Framework/Components/Widgets";
// import { apiCalling } from "Service/Utilities/apiCalling";
import { project } from "Service/Endpoints/endpoints";
import { getSessionStorage, setSessionStorage } from "Service/auth/auth";
import { ImEye } from "react-icons/im";
import GetApiData from "Configration/Utilities/getApiData/GetApiData";

const cellTemplate = (params) => {
  const style = {
    display: "flex",
    fontSize: "16px",
    background: "transparent",
    border: "0px solid transparent",
    padding: "4px 0px 5px 0px",
  };

  const onDetailProjectClick = () => {
    if (params) {
      params.column.userProvidedColDef.cellRendererParams.toggleDetailModal(true, params.data);
    }
  };

  return (
    <div style={{ display: "flex", gap: "8px", justifyContent: "center" }}>
      <button type="button" style={style} title="View Detail" onClick={() => onDetailProjectClick()}>
        <ImEye style={{ color: "rgb(52, 73, 94)" }} />
      </button>
    </div>
  );
};

function ProjectsListLogic() {
  const setAlertMessage = AlertMessage();
  const user = getSessionStorage("userData");
  const [activeViewID, setActiveViewID] = useState(1);
  const [detailModalActive, setDetailModalActive] = useState(false);
  const [selectedProjectData, setSelectedProjectData] = useState(null);
  const [gridApi, setGridApi] = useState();
  const [projectItemSearch, setProjectItemSearch] = useState("");
  const [projectListData, setProjectListData] = useState([]);
  const [filteredProjectListData, setFilteredProjectListData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const toggleDetailModal = (modalState, selectedData) => {
    setSessionStorage("selctedProject", selectedData);
    setDetailModalActive(modalState);
    setSelectedProjectData(selectedData);
  };

  const [ListViewColumDef] = useState([
    {
      headerName: "Action",
      field: "#",
      width: 80,
      pinned: "left",
      cellRenderer: cellTemplate,
      cellRendererParams: {
        toggleDetailModal,
      },
    },
    { headerName: "Name", field: "PROJECTNAME", width: 400 },
    { headerName: "Mobile Number", field: "PHEADMOBILENO", width: 160 },
    { headerName: "Address", field: "PADDRESS", width: 680 },
  ]);

  // ************************** Code For Sign Up First Form Start *************************** //

  // const getProjectListData = async () => {
  //   try {
  //     setIsLoading(true);
  //     const formData = {
  //       main: {
  //         empid: user && user.AppAccessUID ? Number(user.AppAccessUID) : 0,
  //         businessrelation: user && user.BusinessRelationID ? Number(user.BusinessRelationID) : 0,
  //         profileid: user && user.UserProfileID ? Number(user.UserProfileID) : 0,
  //       },
  //     };
  //     const result = await apiCalling(formData, project.projectList.getProjectListData);
  //     setIsLoading(false);
  //     if (result && result.responseCode === 1) {
  //       if (result && result.responseData && result.responseData.Table && result.responseData.Table.length > 0) {
  //         const filteredProjectList = [];
  //         result.responseData.Table.forEach((data) => {
  //           let isExist = false;
  //           if (filteredProjectList.length > 0) {
  //             isExist = filteredProjectList.some((x) => x.PROJECTID.toString() === data.PROJECTID.toString());
  //           }
  //           if (!isExist) {
  //             const newView = {
  //               PROJECTID: data.PROJECTID,
  //               PROJECTNAME: data.PROJECTNAME,
  //               PHEADMOBILENO: data.PHEADMOBILENO,
  //               PADDRESS: data.PADDRESS,
  //               DIVISIONIDs: "",
  //             };
  //             const findData = result.responseData.Table.filter((x) => x.PROJECTID.toString() === data.PROJECTID.toString());
  //             const arrayDivisionIDs = [];
  //             if (findData) {
  //               findData.forEach((v) => {
  //                 arrayDivisionIDs.push(v.DIVISIONID);
  //               });
  //               newView.DIVISIONIDs = arrayDivisionIDs.join(",");
  //             }
  //             filteredProjectList.push(newView);
  //           }
  //         });
  //         setProjectListData(filteredProjectList);
  //         setFilteredProjectListData(filteredProjectList);
  //         console.log(result && result.responseData && result.responseData.Table, filteredProjectList, "Project Data Received");
  //       }
  //     } else {
  //       setAlertMessage({
  //         type: "error",
  //         message: result.responseMessage,
  //       });
  //       setProjectListData([]);
  //       setFilteredProjectListData([]);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //     setAlertMessage({
  //       type: "error",
  //       message: error,
  //     });
  //     setProjectListData([]);
  //     setFilteredProjectListData([]);
  //   }
  // };

  const filteredProjectListFun = (reponseData) => {
    if (reponseData && reponseData.length > 0) {
      const filteredProjectList = [];
      reponseData.forEach((data) => {
        let isExist = false;
        if (filteredProjectList.length > 0) {
          isExist = filteredProjectList.some((x) => x.PROJECTID.toString() === data.PROJECTID.toString());
        }
        if (!isExist) {
          const newView = {
            PROJECTID: data.PROJECTID,
            PROJECTNAME: data.PROJECTNAME,
            PHEADMOBILENO: data.PHEADMOBILENO,
            PADDRESS: data.PADDRESS,
            DIVISIONIDs: "",
          };
          const findData = reponseData.filter((x) => x.PROJECTID.toString() === data.PROJECTID.toString());
          const arrayDivisionIDs = [];
          if (findData) {
            findData.forEach((v) => {
              arrayDivisionIDs.push(v.DIVISIONID);
            });
            newView.DIVISIONIDs = arrayDivisionIDs.join(",");
          }
          filteredProjectList.push(newView);
        }
      });
      setProjectListData(filteredProjectList);
      setFilteredProjectListData(filteredProjectList);
      console.log(reponseData, "Project Data Received");
    } else {
      setProjectListData([]);
      setFilteredProjectListData([]);
    }
  };

  const getProjectListData = async () => {
    const formData = {
      main: {
        empid: user && user.AppAccessUID ? Number(user.AppAccessUID) : 0,
        businessrelation: user && user.BusinessRelationID ? Number(user.BusinessRelationID) : 0,
        profileid: user && user.UserProfileID ? Number(user.UserProfileID) : 0,
      },
    };

    const request = {
      setDatalist: filteredProjectListFun,
      setLoad: setIsLoading,
      requestData: formData,
      apiPath: project.projectList.getProjectListData,
      setAlert: setAlertMessage,
      fun: null,
    };
    GetApiData(request);
  };

  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  const onSearchList = (val) => {
    const searchValue = val;
    setProjectItemSearch(val);
    if (searchValue !== "") {
      const filteredProjectListData = projectListData.filter((data) => {
        return Object.values(data).join(" ").toLowerCase().includes(val.toLowerCase());
      });
      setFilteredProjectListData(filteredProjectListData);
    } else {
      setFilteredProjectListData(projectListData);
    }
  };

  useEffect(() => {
    getProjectListData();
  }, []);

  // ************************** Code For Sign Up First Form End ***************************** //

  return {
    activeViewID,
    setActiveViewID,
    ListViewColumDef,
    detailModalActive,
    selectedProjectData,
    toggleDetailModal,
    getProjectListData,
    isLoading,
    onGridReady,
    gridApi,
    projectItemSearch,
    filteredProjectListData,
    onSearchList,
  };
}

export default ProjectsListLogic;
