import React from "react";
import Login from "Components/Modules/Common/Login/Login";
import Page from "Configration/PageRouter/Page/Page";
import { checkAuthExist } from "Service/auth/auth";

function PageAuthenticator() {
  debugger;
  if (checkAuthExist()) {
    return <Page component="" title="Welcome to KBA" />;
  }

  return <Login />;
}

export default PageAuthenticator;
