import React, { useEffect, useState, useRef } from 'react';
import { Form, FormGroup, FormControl, Dropdown } from 'react-bootstrap';
import axios from "../../../../Configration/axios/axios";
import { PageBar } from "Framework/Components/Layout";
import { project } from "Service/Endpoints/endpoints";
import { getSessionStorage } from "Service/auth/auth";
import BizClass from "./CaptureLogSheets.module.scss";
import { Loader } from "Framework/Components/Widgets";
import 'bootstrap/dist/css/bootstrap.min.css';
import Multiselect from 'multiselect-react-dropdown';


const LogSheetForm = () => {
    const [logSheetData, setLogSheetData] = useState([]);
    const [formData, setFormData] = useState({});
    const [readingUnit, setReadingUnit] = useState('yes');
    const [uniqueQuestions, setUniqueQuestions] = useState(new Set());
    const [displayedQuestions, setDisplayedQuestions] = useState([]);
    const displayedQuestionsRef = useRef(new Set());
    const [loading, setisloading] = useState(false);
    const [logSheetName, setLogSheetName] = useState({});

    const [unit, setUnit] = useState("");
    const [designation, setDesignation] = useState("");

    const selectedProjectData = getSessionStorage("selctedProject");
    console.log("selectedProjectData", selectedProjectData)
    const user = getSessionStorage("userData");
    console.log("user", user)

    const [unitInfo, setUnitInfo] = useState([]);
    const [selectedValues, setSelectedValues] = useState([]);
    const [deisgnationselectedValues, setDesignationSelectedValues] = useState([]);

    const designationOptions = [
        {
            value: 1,
            lable: "JE"
        },
        {
            value: 2,
            lable: "AE"
        },
        {
            value: 3,
            lable: "AEE"
        },
        {
            value: 4,
            lable: "EE"
        },
        {
            value: 5,
            lable: "SE"
        },
        {
            value: 6,
            lable: "CE"
        },
        {
            value: 7,
            lable: "ED"
        },
        {
            value: 8,
            lable: "TD"
        },
        {
            value: 9,
            lable: "MD"
        },
        {
            value: 10,
            lable: "DHR"
        },
    ]

    const onSelect = (selectedList, selectedItem) => {
        setSelectedValues(selectedList);
    };

    const onRemove = (selectedList, removedItem) => {
        setSelectedValues(selectedList);
    };

    // Map your unitInfo to the format expected by the Multiselect component
    const options = unitInfo.map((info) => ({
        value: info.unitID,
        label: info.unitName,
    }));

    const onDesignationSelect = (selectedList, selectedItem) => {
        setDesignationSelectedValues(selectedList);
    };

    const onDesignationRemove = (selectedList, removedItem) => {
        setDesignationSelectedValues(selectedList);
    };

    useEffect(() => {
        const unitInfo = async () => {
            try {
                const formData = {
                    projectID: selectedProjectData.PROJECTID
                };

                const response = await axios.post(`${project.projectList.GetUnittInfo}`, formData);
                console.log("Unit response", response.data.responseDynamic);
                setUnitInfo(response.data.responseDynamic);
            } catch (error) {
                console.error("Error fetching unit information:", error);
                // Handle error or set an error state
            }
        };

        unitInfo();

        // Cleanup function to cancel the Axios request if the component is unmounted
        return () => {
            // Cancel the request or perform any necessary cleanup
        };
    }, [selectedProjectData.PROJECTID]);  // Add any dependencies if needed





    let questionCounter = 0;
    useEffect(() => {
        setDisplayedQuestions([]);
        displayedQuestionsRef.current.clear();
    }, [logSheetData]);

    const handleQuestionDisplay = (questionId) => {
        if (!displayedQuestionsRef.current.has(questionId)) {
            displayedQuestionsRef.current.add(questionId);
            setDisplayedQuestions((prevDisplayedQuestions) => [...prevDisplayedQuestions, questionId]);
            return true;
        }
        return false;
    };

    let previousId = null;

    useEffect(() => {
        const fetchData = async () => {
            const logSheetsData = {
                sheetId: 85
            };
    
            try {
                const response = await axios.post(
                    `${project.projectList.GetLogSheetDetailBySheetID}`,
                    logSheetsData
                );
    
                const data = response.data.responseDynamic;
                const jsonData = JSON.parse(data);
                const readingsJson = JSON.parse(jsonData.Table[0].LOG_JSON);
    
                console.log("readingsJson", readingsJson);
                setLogSheetName(readingsJson)
                setLogSheetData(readingsJson.questions)
            } catch (error) {
                console.error('Error fetching log sheet data:', error);
            }
        };

        fetchData();
    }, []);


    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value,
        }));
    };

    console.log("logSheetData", logSheetData)
    const handleReadingUnitChange = (event) => {
        setReadingUnit(event.target.value);
    };

    // const handleSubmit = (event) => {
    //     event.preventDefault();

    //     const formElements = event.target.elements;
    //     let isFormValid = true;

    //     for (let i = 0; i < formElements.length; i++) {
    //         const element = formElements[i];

    //         if (element.tagName === 'INPUT' && element.type !== 'submit') {
    //             const enteredValue = parseFloat(element.value);
    //             const minValue = parseFloat(element.min);
    //             const maxValue = parseFloat(element.max);

    //             const errorMessageContainer = element.nextElementSibling;

    //             if (isNaN(enteredValue) || enteredValue < minValue || enteredValue > maxValue || element.value.trim() === '') {
    //                 element.style.borderColor = 'red';
    //                 element.style.borderWidth = '2px';
    //                 errorMessageContainer.innerText = enteredValue === '' ? 'Please enter a value' : 'Entered value is not within the range';
    //                 errorMessageContainer.style.color = 'red';
    //                 isFormValid = false;
    //             } else {
    //                 element.style.borderColor = '';
    //                 element.style.borderWidth = '';
    //                 errorMessageContainer.innerText = '';
    //                 errorMessageContainer.style.color = '';
    //             }
    //         }
    //     }

    //     if (isFormValid) {
    //         const formData = new FormData(event.target);
    //         const enteredValues = {};
    //         enteredValues['hr_id'] = 1;
    //         enteredValues['unit_id'] = 1;
    //         enteredValues['fk_user_id'] = 1;
    //         enteredValues['fk_logsheet_id'] = 112;
    //         if (readingUnit === 'yes') {
    //             enteredValues['readings_avlb'] = 'yes';
    //             enteredValues['readings_json'] = logSheetData.map(logSheet => ({
    //                 log_sheet_id: logSheet.log_sheet_id || 0,
    //                 unit: logSheet.unit || 0,
    //                 log_sheet_name: logSheet.log_sheet_name || '',
    //                 question_id: logSheet.question_id || 0,
    //                 question_number: logSheet.question_number || 0,
    //                 question_text: logSheet.question_text || '',
    //                 readings_count: logSheet.readings_count || 0,
    //                 option_id: logSheet.option_id || '',
    //                 option_number: logSheet.option_number || 0,
    //                 option_text: logSheet.option_text || '',
    //                 option_validation_required: logSheet.option_validation_required || '',
    //                 min_value: logSheet.min_value || 0,
    //                 max_value: logSheet.max_value || 0,
    //                 // optionValue: formData.get(`question_${logSheet.question_id}`),
    //                 suboptionsRequired: logSheet.suboptionsRequired || '',
    //                 suboption_id: logSheet.suboption_id || '',
    //                 suboption_number: logSheet.suboption_number || '',
    //                 suboption_text: logSheet.suboption_text || '',
    //                 suboption_validation_required: logSheet.suboption_validation_required || '',
    //                 suboption_min_value: logSheet.suboption_min_value || '',
    //                 suboption_max_value: logSheet.suboption_max_value || '',
    //             }));
    //             enteredValues['readings_remarks'] = formData.get('readings_remarks');
    //         } else if (readingUnit === 'no') {
    //             enteredValues.readings_avlb = 'no';
    //             enteredValues['readings_json'] = logSheetData.map(logSheet => ({
    //                 log_sheet_id: logSheet.log_sheet_id || 0,
    //                 unit: logSheet.unit || 0,
    //                 log_sheet_name: logSheet.log_sheet_name || '',
    //                 question_id: logSheet.question_id || 0,
    //                 question_number: logSheet.question_number || 0,
    //                 question_text: logSheet.question_text || '',
    //                 readings_count: logSheet.readings_count || 0,
    //                 option_id: logSheet.option_id || '',
    //                 option_number: logSheet.option_number || 0,
    //                 option_text: logSheet.option_text || '',
    //                 option_validation_required: logSheet.option_validation_required || '',
    //                 min_value: logSheet.min_value || 0,
    //                 max_value: logSheet.max_value || 0,
    //                 // optionValue: 0,
    //                 suboptionsRequired: logSheet.suboptionsRequired || '',
    //                 suboption_id: logSheet.suboption_id || '',
    //                 suboption_number: logSheet.suboption_number || '',
    //                 suboption_text: logSheet.suboption_text || '',
    //                 suboption_validation_required: logSheet.suboption_validation_required || '',
    //                 suboption_min_value: logSheet.suboption_min_value || '',
    //                 suboption_max_value: logSheet.suboption_max_value || '',
    //             }));
    //             enteredValues.readings_remarks = formData.get('no_reading_remarks');
    //         }
    //         console.log("enteredValues", enteredValues);
    //         axios.post(`${project.projectList.InsertLogSheetReadingDetail}`, enteredValues)
    //             .then(response => {
    //                 if (response.status === 200) {
    //                     alert("Readings have been successfully submitted");
    //                 }
    //             })
    //             .catch(error => {
    //                 console.error('Error submitting form:', error);
    //                 alert('Failed to submit form. Please try again.');
    //             });

    //     } else {
    //         alert('Please fix the validation errors before submitting the form.');
    //         return;
    //     }
    // }

    const handleSubmit = (event) => {
        event.preventDefault();
        const formElements = event.target.elements;
        let isFormValid = true;

        // for (let i = 0; i < formElements.length; i++) {
        //     const element = formElements[i];

        //     if (element.tagName === 'INPUT' && element.type !== 'submit') {
        //         const enteredValue = parseFloat(element.value);
        //         const minValue = parseFloat(element.min);
        //         const maxValue = parseFloat(element.max);

        //         const errorMessageContainer = element.nextElementSibling;

        //         if (errorMessageContainer) {
        //             if (isNaN(enteredValue) || enteredValue < minValue || enteredValue > maxValue || element.value.trim() === '') {
        //                 element.style.borderColor = 'red';
        //                 element.style.borderWidth = '2px';
        //                 errorMessageContainer.innerText = enteredValue === '' ? 'Please enter a value' : 'Entered value is not within the range';
        //                 errorMessageContainer.style.color = 'red';
        //                 isFormValid = false;
        //             } else {
        //                 element.style.borderColor = '';
        //                 element.style.borderWidth = '';
        //                 errorMessageContainer.innerText = '';
        //                 errorMessageContainer.style.color = '';
        //             }
        //         }
        //     }
        // }

        if (readingUnit === 'no') {
            const formData = new FormData(event.target);
            const enteredValues = {};
            const currentDate = new Date();
            currentDate.setHours(currentDate.getHours() + 1);
            enteredValues['hr_id'] = currentDate.getHours() || 0;
            enteredValues['unit_id'] = logSheetName.Unit || 0;
            enteredValues['fk_user_id'] = user.UserName || 0;
            enteredValues['fk_logsheet_id'] = 63;
            enteredValues.readings_avlb = 'no';
            enteredValues['readings_json'] = logSheetData.flatMap((logSheet, logSheetIndex) => (
                logSheet.options.map((option, optionIndex) => ({
                    log_sheet_id: logSheetName.log_sheet_id || 0,
                    unit: String(logSheetName.Unit) || '',
                    log_sheet_name: logSheetName.LogSheetName || '',
                    question_id: logSheet.question_id || 0,
                    question_number: logSheet.question_number || 0,
                    question_text: logSheet.questionText || '',
                    readings_count: logSheet.readingsCount || 0,
                    option_id: option.optionId || '',
                    option_number: optionIndex || 0,
                    option_text: option.optionText || '',
                    option_validation_required: option.validationRequired || '',
                    min_value: option.minValue || 0,
                    max_value: option.maxValue || 0,
                    optionValue: '0',
                    suboptionsRequired: option.suboptionsRequired || '',
                    suboption_id: option.suboptionId || '',
                    suboption_number: option.suboptionNumber || '',
                    suboption_text: option.suboptionText || '',
                    suboption_validation_required: option.suboptionValidationRequired || '',
                    suboption_min_value: option.suboptionMinValue || '',
                    suboption_max_value: option.suboptionMaxValue || '',
                }))
            ));
            enteredValues.readings_remarks = formData.get('no_reading_remarks');

            console.log("enteredValues", enteredValues);
            axios.post(`${project.projectList.InsertLogSheetReadingDetail}`, enteredValues)
                .then(response => {
                    if (response.status === 200) {
                        alert("Readings have been successfully submitted");
                    }
                })
                .catch(error => {
                    console.error('Error submitting form:', error);
                    alert('Failed to submit form. Please try again.');
                });
        } else if (isFormValid) {
            const formData = new FormData(event.target);
            const enteredValues = {};
            const currentDate = new Date();
            currentDate.setHours(currentDate.getHours() + 1);

            enteredValues['hr_id'] = currentDate.getHours() || 0;
            enteredValues['unit_id'] = logSheetName.Unit[0] || 0;
            enteredValues['fk_user_id'] = user.UserName || 0;
            enteredValues['fk_logsheet_id'] = 85;
            enteredValues['readings_avlb'] = 'yes';
            // enteredValues['readings_json'] = logSheetData.flatMap((logSheet, logSheetIndex) => (
            //     logSheet.options.flatMap((option, optionIndex) => (
            //         option.subOptions.map((suboption, suboptionIndex) => (
            //             {
            //                 log_sheet_id: logSheetName.log_sheet_id || 0,
            //                 unit: String(logSheetName.Unit) || '',
            //                 log_sheet_name: logSheetName.LogSheetName || '',
            //                 question_id: logSheet.question_id || 0,
            //                 question_number: logSheet.question_number || 0,
            //                 question_text: logSheet.questionText || '',
            //                 readings_count: logSheet.readingsCount || 0,
            //                 option_id: option.optionId || '',
            //                 option_number: optionIndex || 0,
            //                 option_text: option.optionText || '',
            //                 option_validation_required: option.validationRequired || '',
            //                 min_value: option.minValue || 0,
            //                 max_value: option.maxValue || 0,
            //                 optionValue: formData.get(`question_${logSheetIndex}_${optionIndex}`) || '0',
            //                 suboptionsRequired: option.suboptionsRequired || '',
            //                 suboption_id: option.suboptionId || '',
            //                 suboption_number: option.suboptionCount || '',
            //                 suboption_text: suboption.suboptionText || '',
            //                 suboption_validation_required: suboption.suboptionValidation || '',
            //                 suboption_min_value: suboption.suboptionMinValue || '',
            //                 suboption_max_value: suboption.suboptionMaxValue || '',
            //                 suboptionValue: formData.get(`question_${logSheetIndex}_${optionIndex}_sub_${suboptionIndex}`) || 0
            //             }
            //         ))
            //     ))
            // ));

            const transformedData = logSheetData.map((logSheet, logsheetIndex) => {
                return {
                    log_sheet_id: logSheetName.LOG_SHEET_ID || 0,
                    unit: String(logSheetName.Unit) || '',
                    log_sheet_name: logSheetName.LogSheetName || '',
                    questionText: logSheet.questionText || '',
                    readingsCount: logSheet.readingsCount || 0,
                    options: logSheet.options.map((option, optionIndex) => {
                        return {
                            optionText: option.optionText || '',
                            validationRequired: option.validationRequired || '',
                            minValue: option.minValue || '',
                            maxValue: option.maxValue || '',
                            optionValue: formData.get(`question_${logsheetIndex}_${optionIndex}`) || '0', // You may need to set an appropriate default value
                            suboptionsRequired: option.suboptionsRequired || '',
                            suboptionCount: option.suboptionCount, // You may need to set an appropriate default value
                            subOptions: option.subOptions.map((suboption, suboptionIndex) => {
                                return {
                                    suboptionText: suboption.suboptionText || '',
                                    suboptionValidation: suboption.suboptionValidation || '',
                                    suboptionMinValue: suboption.suboptionMinValue || '',
                                    suboptionMaxValue: suboption.suboptionMaxValue || '',
                                    suboptionValue: formData.get(`question_${logsheetIndex}_${optionIndex}_sub_${suboptionIndex}`) || 0, // You may need to set an appropriate default value
                                };
                            }),
                        };
                    }),
                };
            });
            enteredValues['readings_json'] = transformedData;


            enteredValues['readings_remarks'] = formData.get('readings_remarks');
            console.log("enteredValues", enteredValues);
            axios.post(`${project.projectList.InsertLogSheetReadingDetail}`, enteredValues)
                .then(response => {
                    if (response.status === 200) {
                        alert("Readings have been successfully submitted");
                    }
                })
                .catch(error => {
                    console.error('Error submitting form:', error);
                    alert('Failed to submit form. Please try again.');
                });
        } else {
            alert('Please fix the validation errors before submitting the form.');
            return;
        }
    }

    // useEffect(() => {
    //     if (selectedValues.length !== 0 && deisgnationselectedValues.length !== 0) {
    //         const formData = {
    //             divisionID: selectedProjectData.DIVISIONIDs,
    //             unitID: selectedValues.map((value)=> value[0]),
    //             projectID: selectedProjectData.PROJECTID,
    //             employeeLevel: deisgnationselectedValues.map((value)=> value[0])
    //         }
    //         axios.post(`${project.projectList.GetLogSheetDetail}`, formData).then((response)=>{
    //             console.log("response",response)
    //         })
    //     }
    // })
    // useEffect(() => {
    //     if (unit.length !== 0 && designation.length !== 0) {
    //         const formData = {
    //             divisionID: 0,
    //             unitID: parseInt(unit, 10),
    //             projectID: parseInt(selectedProjectData.PROJECTID, 10),
    //             employeeLevel: parseInt(designation, 10)
    //         };

    //         console.log("formData", formData);

    //         axios.post(`${project.projectList.GetLogSheetDetail}`, formData)
    //             .then((response) => {
    //                 console.log("response", response);
    //             })
    //             .catch((error) => {
    //                 console.error("Error", error);
    //             });
    //     } else {
    //         console.error("Invalid unit or designation");
    //     }
    // }, [unit, designation, selectedProjectData]);
    useEffect(() => {
        // if (unit.length !== 0 && designation.length !== 0) {
            const formData = {
                divisionID: 0,
                unitID: parseInt(unit, 10),
                projectID: parseInt(selectedProjectData.PROJECTID, 10),
                employeeLevel: parseInt(designation, 10)
            };
    
            console.log("formData", formData);
    
            axios.post(`${project.projectList.GetLogSheetDetail}`, formData)
                .then((response) => {
                    console.log("response", response);
                })
                .catch((error) => {
                    console.error("Error", error);
                });
        // } else {
        //     console.error("Invalid unit or designation");
        // }
    }, []); // Empty dependency array to run the effect only once
    





    const validation = (event) => {
        const enteredValue = parseFloat(event.target.value);
        const minValue = parseFloat(event.target.min);
        const maxValue = parseFloat(event.target.max);

        const inputField = event.target;
        const errorMessageContainer = inputField.nextElementSibling || inputField.nextSibling;

        if (enteredValue < minValue || enteredValue > maxValue) {
            inputField.style.borderColor = 'red';
            inputField.style.borderWidth = '2px';
            if (errorMessageContainer) {
                errorMessageContainer.innerText = 'Entered value is not within the range';
                errorMessageContainer.style.color = 'red';
            }
        } else if (event.target.value.trim() === '') {
            inputField.style.borderColor = 'red';
            inputField.style.borderWidth = '2px';
            if (errorMessageContainer) {
                errorMessageContainer.innerText = 'Please enter a value';
                errorMessageContainer.style.color = 'red';
            }
        } else {
            inputField.style.borderColor = '';
            inputField.style.borderWidth = '';
            if (errorMessageContainer) {
                errorMessageContainer.innerText = '';
                errorMessageContainer.style.color = '';
            }
        }
    };
    const handleUnitChange = (event) => {
        setUnit(event.target.value)
    }

    const handleDesignationChange = (event) => {
        setDesignation(event.target.value)
    }
    return (
        <div className={BizClass.PageStart}>
            <div className={BizClass.Card}>
                <PageBar className={BizClass.CustomPageBar}>
                    <span>Capture Log Sheets</span>
                </PageBar>
                <div className="container">


                    <label>Readings Unit:</label>
                    <div>
                        <input
                            type="radio"
                            id="reading_unit_no"
                            name="readingUnit"
                            value="no"
                            checked={readingUnit === 'no'}
                            onChange={handleReadingUnitChange}
                            className="m-1"
                        />
                        <label htmlFor="reading_unit_no">No</label>

                        <input
                            type="radio"
                            id="reading_unit_yes"
                            name="readingUnit"
                            value="yes"
                            checked={readingUnit === 'yes'}
                            onChange={handleReadingUnitChange}
                            className="m-1"
                        />
                        <label htmlFor="reading_unit_yes">Yes</label>
                    </div>

                    <form onSubmit={handleSubmit} className="my-4">


                        {readingUnit === 'yes' && logSheetData && (
                            <>
                                {logSheetData.map((logSheet, index) => {
                                    return (
                                        <div key={index} className="mb-3">
                                            <label className="form-label" style={{ fontWeight: 'bold' }}>
                                                Question {index + 1}:
                                            </label>
                                            {logSheet.options.length && (
                                                <div className="mb-3">
                                                    <p style={{ fontWeight: 'bold' }}>
                                                        {logSheet.questionText} (Min {logSheet.options[0].minValue} - Max {logSheet.options[0].maxValue})
                                                    </p>
                                                </div>
                                            )}
                                            {/* {logSheet.readingsCount && (
                                                <div className="mb-3 row">
                                                    {logSheet.options.map((option, optionIndex) => (
                                                        <div key={optionIndex} className="col">
                                                            <label className="form-label">{option.optionText}</label>
                                                            <input
                                                                type="number"
                                                                name={`question_${index}_${optionIndex}`}
                                                                className="form-control"
                                                                min={option.minValue}
                                                                max={option.maxValue}
                                                                required
                                                                onBlur={validation}
                                                            />
                                                        </div>
                                                    ))}
                                                </div>
                                            )} */}
                                            {logSheet.readingsCount && (
                                                <div className="mb-3 row">
                                                    {logSheet.options.map((option, optionIndex) => (
                                                        <React.Fragment key={optionIndex}>
                                                            {option.suboptionsRequired === "No" && (<div className="col">
                                                                <label className="form-label">{option.optionText}</label>
                                                                <input
                                                                    type="number"
                                                                    name={`question_${index}_${optionIndex}`}
                                                                    className="form-control"
                                                                    min={option.minValue}
                                                                    max={option.maxValue}
                                                                    required
                                                                    onBlur={validation}
                                                                />
                                                            </div>)}

                                                            {option.suboptionsRequired === "Yes" && option.subOptions && (
                                                                <div className="col">
                                                                    <label className='form-label' style={{ fontWeight: 'bold' }}>{option.optionText}</label><br />
                                                                    {option.subOptions.map((suboption, suboptionIndex) => (
                                                                        <React.Fragment key={suboptionIndex}>

                                                                            <label className="form-label">{suboption.suboptionText}</label>
                                                                            <input
                                                                                type="number"
                                                                                name={`question_${index}_${optionIndex}_sub_${suboptionIndex}`}
                                                                                className="form-control"
                                                                                min={suboption.suboptionMinValue}
                                                                                max={suboption.suboptionMaxValue}
                                                                                required
                                                                                onBlur={validation}
                                                                            />
                                                                        </React.Fragment>
                                                                    ))}
                                                                </div>
                                                            )}
                                                        </React.Fragment>
                                                    ))}
                                                </div>
                                            )}


                                            <div className='errorMessageContainer'></div>

                                        </div>
                                    );
                                })}
                                <div className="d-flex flex-column col-12 col-lg-6 pb-3">
                                    <label htmlFor="readings_remarks" className='py-2'>Reading Remarks</label>
                                    <textarea id="readings_remarks" name="readings_remarks" rows="4" cols="50"></textarea>
                                </div>
                            </>
                        )}




                        {readingUnit === 'no' && logSheetData && (
                            <>
                                {logSheetData.map((logSheet, index) => (
                                    <div key={index} className="mb-3" hidden>
                                        <label className="form-label">{`Question ${logSheet.question_number}: ${logSheet.question_text}`}</label>
                                        {logSheet.options && (
                                            <div className="mb-3">
                                                <label className="form-label">Options:</label>
                                                <select
                                                    name={`question_${logSheet.question_id}`}
                                                    className="form-control"
                                                >
                                                    {logSheet.options.map((option, optionIndex) => (
                                                        <option key={optionIndex} value={option.option_text}>{option.option_text}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        )}
                                        {!logSheet.options && (
                                            <div className="mb-3">
                                                <label className="form-label">{logSheet.option_text}</label>
                                                <input
                                                    type="number"
                                                    name={`question_${logSheet.question_id}`}
                                                    className="form-control"
                                                    min={logSheet.min_value}
                                                    max={logSheet.max_value}
                                                    placeholder='Please Enter Values'
                                                />
                                                <div className="errorMessageContainer"></div>
                                            </div>
                                        )}
                                    </div>
                                ))}
                                <div className="d-flex flex-column col-12 col-lg-6 pb-3">
                                    <label htmlFor="no_reading_remarks">Reading Remarks</label>
                                    <textarea id="no_reading_remarks" name="no_reading_remarks" rows="4" cols="50"></textarea>
                                </div>
                            </>
                        )}

                        <button type="submit" className="btn btn-primary">
                            Submit
                        </button>
                    </form>

                </div>
            </div>
        </div>
    );

};

export default LogSheetForm;
